import { AdditionalContacts } from '../models/additional-contacts';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';


@Injectable({providedIn: 'root'})
export class ContactRestService {
    contactsRestName = restEndPoint + 'contacts';

    constructor(private http: CacheableHttpClient) {
    }

    getAdditionalContacts(): Observable<AdditionalContacts[]> {
        return this.http.get<AdditionalContacts[]>(this.contactsRestName);
    }

}
