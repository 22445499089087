<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate" [disableKeyboardClose]="true"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'ACCEPT_TERMS_AND_CONDITIONS_TITLE'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      @if (loaded) {
        <div class="modal-dialog__body padding-left-0 padding-right-0" id="modal1_desc">
          <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
          @if (!showSpinner) {
            <div class="terms-and-conditions">
              <p class="terms-and-conditions__introtext">
                <span [translate]="'ACCEPT_TERMS_AND_CONDITIONS_TEXT'"></span><br>
                <a [href]="'NUTZUNGSBEDINGUNGEN_LINK' | translate | safeDom: 'resourceUrl'" class="terms-and-conditions__link icon-arrow"
                  [translate]="'NUTZUNGSBEDINGUNGEN'" target="_blank" rel="noopener noreferrer"></a>
              </p>
              @if (isUrl('NUTZUNGSBEDINGUNGEN_LINK_PRINT' | translate)) {
                <iframe #iframe [src]="'NUTZUNGSBEDINGUNGEN_LINK_PRINT' | translate | safeDom: 'resourceUrl'"
                  class="terms-and-conditions__content-container" title="terms and conditions"></iframe>
              }
            </div>
          }
        </div>
      }
      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button" (click)="hide()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
          <button class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_ACCEPT'"></button>
        </div>
      }
    </div>
  </div>
</ng-template>
