import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-common-modal-structure',
  templateUrl: './common-modal-structure.component.html',
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonModalStructureComponent {
  title = input<string | null>(null);
  wide = input<boolean>(false);
  footerVisible = input<boolean>(true);
  hideButtonVisible = input<boolean>(true);
  hideButtonClicked = output();
}
