import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { green, red, yellow } from 'app/core/core-constants.service';
import { SelectOption } from 'app/core/models/select-option';
import { ActivitiesRestService } from 'app/core/rest-services/activities-rest.service';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { EquipmentUtilService } from 'app/core/services/equipment/equipment-util.service';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';
import { EquipmentViewModel } from 'app/core/view-models/equipment-view-model';
import { SpaceValidator } from 'app/shared/validators/space.validator';
import { find, forEach, parseInt as lodashParseInt } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-schedule-training-modal',
  templateUrl: './schedule-training-modal.component.html'
})
export class ScheduleTrainingModalComponent extends BaseModalPopup implements OnInit, OnChanges {

  @Input()
  activitiesItem: ActivitiesViewModel;
  selectedEquipment: EquipmentViewModel;
  equipmentVMList: EquipmentViewModel[];
  scheduleTrainingForm: UntypedFormGroup;
  equipmentStatusColorMap = [];
  educationTypes: SelectOption[] = [];
  isFormSubmitted = false;
  loadingSelectedEquipment = true;

  constructor(renderer: Renderer2,
              private fb: UntypedFormBuilder,
              private configService: CountryConfigRestService,
              private toasterService: ToasterService,
              private activitiesRestService: ActivitiesRestService,
              private equipmentUtilService: EquipmentUtilService) {
    super(renderer);
  }

  ngOnInit() {
    this.isFormSubmitted = false;
    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(config => {
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_GREEN] = green;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_RED] = red;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_YELLOW] = yellow;
      this.setEducationTypes(config.SCHEDULE_TRAININGS_EDUCATION_TYPES);
      this.createForm(config);
    });
    this.loadViewModel();
  }

  loadViewModel(): void {
    this.loadingSelectedEquipment = true;
    this.equipmentUtilService
      .getEquipmentViewModelList()
      .subscribe(equipmentVMResponse => {
        this.equipmentVMList = equipmentVMResponse;
        this.selectedEquipment = find(this.equipmentVMList, e => e.key === this.activitiesItem.equipmentKey);
        this.loadingSelectedEquipment = false;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedEquipment = find(this.equipmentVMList, e => e.key === this.activitiesItem.equipmentKey);
  }

  createForm(config) {
    // Email Validation
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = lodashParseInt(config.EMAIL_VALIDATION_LENGTH);

    // phone validation
    const phoneRegEx = new RegExp(config.PHONE_VALIDATION_REGEX);
    const phoneLength = lodashParseInt(config.PHONE_VALIDATION_LENGTH);

    this.scheduleTrainingForm = this.fb.group({
      educationType: ['', Validators.required],
      detailedDescription: ['', Validators.required],
      modality: [this.activitiesItem.modalityTranslation],
      contact: this.fb.group({
        contactEmail: [
          '',
          [
            Validators.required,
            Validators.maxLength(emailLength),
            Validators.pattern(emailRegEx)
          ]
        ],
        contactFirstName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactPhone: [
          '',
          [
            Validators.required,
            Validators.maxLength(phoneLength),
            Validators.pattern(phoneRegEx),
            SpaceValidator.noWhiteSpace
          ]
        ],
        contactSalutation: [''],
        contactTitle: ['']
      }),
    });
  }

  setEducationTypes(types: string) {
    const educationTypes = types ? types.split(',') : [];
    forEach(educationTypes, type => {
      this.educationTypes.push({title: type, value: type});
    });
  }

  hide() {
    this.educationTypes = [];
    this.ngOnInit();
    super.hide();
  }

  ok() {
    this.isFormSubmitted = true;
    if (this.scheduleTrainingForm.valid) {
      this.showSpinner = true;
      const output = {...this.scheduleTrainingForm.value,
        equipmentKey: this.selectedEquipment.key,
        ticketNumber: this.activitiesItem.ticketNumber,
        customerName: this.selectedEquipment.customerName};

      this.activitiesRestService
        .scheduleTraining(output)
        .pipe(finalize(() => this.hide()))
        .subscribe(() => this.toasterService.showTranslatedSuccessToaster('LABEL_SCHEDULE_TRAINING_SUCCESS'));
      this.hide();
    }
  }

}
