import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { VirtualAgentInfo } from '../models/virtual-agent-model';

@Injectable({providedIn: 'root'})
export class VirtualAgentRestService {
  private virtualAgentInfoRestName = restEndPoint + 'virtual-agent';

  constructor(private http: CacheableHttpClient) {
  }

  public getVirtualAgentInfo(): Observable<VirtualAgentInfo> {
    return this.http.get<VirtualAgentInfo>(this.virtualAgentInfoRestName);
  }
}
