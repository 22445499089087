@if (featureToggle && viewLayout === 'inline') {
  <hl-list [additionalCollectionClass]="'overview-list--equipment'" [isLoaded]="initialized" [items]="viewModelList"
    empty="LABEL_NO_AFFECTED_EQUIPMENTS">
    <ng-template let-item>
      <div (click)="navigateToEquipment(item)" class="collection-item list-item list-item--equipment border-left-none" hlScrollTop>
        <div class="collection__content">
          <div class="media-object">
            <div class="media-object__image list-item__image">
              <hl-equipment-image [equipment]="item" [large]="false"></hl-equipment-image>
            </div>
            <div class="media-object__body">
              <p class="list-item__title margin-bottom-0">{{ item.productName }}
                <span class="label evaluation" [ngClass]="item.label | labelColorStatus">{{ item.label | translate }}</span>
              </p>
              <p class="list-item__subtitle">{{ item.siemensId }}</p>
              <ul class="bare-list info-list">
                @if (item.myEquipmentName) {
                  <li class="info-list__item">
                    <i class="icon-equipment-name" hlTooltip [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i> {{ item.myEquipmentName }}
                  </li>
                }
                @if (item.department) {
                  <li class="info-list__item">
                    <i class="icon-department" hlTooltip [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i> {{ item.department }}
                  </li>
                }
                @if (item.city) {
                  <li class="info-list__item">
                    <i class="icon-location-pin" hlTooltip [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ item.city }}
                  </li>
                }<br>
                @if (item.cvss) {
                  <li class="info-list__item list-item__title--break-all">
                    <i class="icon-favorite" hlTooltip
                      [title]="'SECURITY_CVSS_INFO' | translate"></i>{{ 'SECURITY_CVSS_OVERALL' | translate }} {{ item.cvss }}
                  </li>
                }
              </ul>
              @if (item.evaluationResult) {
                <hl-read-more [beforeText]="(('SECURITY_EVALUATION_RESULT' | translate)+': ')" [maxLength]="60"
                  [textTranslation]="item.evaluationResult"></hl-read-more>
              }
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </hl-list>
}

@if (featureToggle && viewLayout === 'mobile') {
  <hl-list [additionalCollectionClass]="'overview-list--equipment'" [isLoaded]="initialized" [items]="viewModelList"
    empty="LABEL_NO_AFFECTED_EQUIPMENTS">
    <ng-template let-item>
      <div (click)="navigateToEquipment(item)" class="collection-item list-item list-item--equipment border-left-none" hlScrollTop>
        <div class="collection__content">
          <div class="media-object">
            <div class="media-object__image list-item__image">
              <hl-equipment-image [equipment]="item" [large]="false"></hl-equipment-image>
            </div>
          </div>
          <div>
            <p class="list-item__title margin-bottom-0 margin-top-tiny-1">{{ item.productName }}
              <span class="label evaluation" [ngClass]="item.label | labelColorStatus">{{ item.label | translate }}</span>
            </p>
            <p class="list-item__subtitle">{{ item.siemensId }}</p>
            <ul class="bare-list info-list">
              @if (item.myEquipmentName) {
                <li class="info-list__item">
                  <i [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate" class="icon-equipment-name" hlTooltip></i> {{ item.myEquipmentName }}
                </li>
              }
              @if (item.department) {
                <li class="info-list__item">
                  <i [title]="'GENERIC_LABEL_DEPARTMENT' | translate" class="icon-department" hlTooltip></i> {{ item.department }}
                </li>
              }
              @if (item.city) {
                <li class="info-list__item">
                  <i [title]="'GENERIC_LABEL_CITY' | translate" class="icon-location-pin" hlTooltip></i> {{ item.city }}
                </li>
              }
              <br>
              @if (item.cvss) {
                <li class="info-list__item list-item__title--break-all">
                  <i [title]="'SECURITY_CVSS_INFO' | translate" class="icon-favorite"
                    hlTooltip></i>{{ 'SECURITY_CVSS_OVERALL' | translate }} {{ item.cvss }}
                </li>
              }
            </ul>
            @if (item.evaluationResult) {
              <hl-read-more [beforeText]="(('SECURITY_EVALUATION_RESULT' | translate)+': ')" [maxLength]="60"
                [textTranslation]="item.evaluationResult"></hl-read-more>
            }
          </div>
        </div>
      </div>
    </ng-template>
  </hl-list>
}
@if (!featureToggle) {
  <hl-progress-indicator [loading]="!initialized"></hl-progress-indicator>
}

@if (initialized && !featureToggle) {
  <dl hlCollapsable class="accordion accordion--word-break-all" role="presentation">
    @for (option of overlayAccordionData; track option) {
      <dt aria-level="3" class="accordion__header">
        <button class="accordion__title icon-arrow-down-large" attr.aria-expanded="{{option.isExpanded}}">
          {{ option.name | translate }}<span class="label">{{ option.equipments.length }}</span>
        </button>
      </dt>
      <dd class="accordion__panel" role="region" attr.aria-hidden="{{!option.isExpanded}}">
        @if (option.equipments.length > 0) {
          <div class="collection margin-bottom-1 bg-color-white">
            @for (equipment of option.equipments; track equipment) {
              <div class="collection-item list-item" (click)="navigateToEquipment(equipment)">
                <div class="media-object width-100">
                  <div class="media-object__image list-item__image">
                    <hl-equipment-image [equipmentKey]="equipment.key" [large]="false"></hl-equipment-image>
                  </div>
                  <div class="media-object__body">
                    @if (equipment.productName) {
                      <p class="list-item__title margin-bottom-0">{{ equipment.productName }}</p>
                    }
                    <ul class="bare-list info-list margin-bottom-0">
                      <li class="info-list__item label-status-{{equipmentStatusColorMap[equipment.status]}}">{{ equipment.siemensId }}</li>
                      @if (equipment.myEquipmentName) {
                        <li class="info-list__item">
                          <i class="icon-equipment-name" hlTooltip [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i>
                          {{ equipment.myEquipmentName }}
                        </li>
                      }
                      @if (equipment.department) {
                        <li class="info-list__item">
                          <i class="icon-department" hlTooltip [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i> {{ equipment.department }}
                        </li>
                      }
                      @if (equipment.publication) {
                        <li class="info-list__item">
                          <i class="icon-calendar" hlTooltip [title]="'SECURITY_PUBLICATION_DATE' | translate"></i>{{
                            equipment.publication |
                              datePipeWrapper:datePattern
                          }}
                        </li>
                      }
                      <br>
                      @if (equipment.city) {
                        <li class="info-list__item">
                          <i [title]="'SECURITY_CVSS_INFO' | translate" class="icon-favorite" hlTooltip></i>{{ 'SECURITY_CVSS_OVERALL' | translate }}
                          {{ equipment.cvss }}
                        </li>
                      }
                    </ul>
                    @if (equipment.evaluationResult) {
                      <hl-read-more [beforeText]="(('SECURITY_EVALUATION_RESULT' | translate)+': ')" [maxLength]="60"
                        [textTranslation]="equipment.evaluationResult"></hl-read-more>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
        @if (option.equipments.length === 0) {
          <p [translate]="'LABEL_NO_AFFECTED_EQUIPMENTS'"></p>
        }
      </dd>
    }
  </dl>
}
