@if (equipment) {
  <div class="collection margin-bottom-1">
    <div class="collection-item list-item">
      <div class="collection__content">
        <div class="media-object">
          <div class="media-object__image list-item__image">
            <hl-equipment-image [equipment]="equipment" [large]="false"></hl-equipment-image>
          </div>
          <div class="media-object__body">
            <p class="list-item__title margin-bottom-0">{{ equipment.productName }}</p>
            <p class="list-item__subtitle"
              [ngClass]="equipment.key ? 'label-status-' + statusColorMap[equipment.key | equipmentStatus | async] : ''">{{ equipment.siemensId }}</p>
            <ul class="bare-list info-list">
              <li class="info-list__item">
                <p class="margin-bottom-0 color-black-60 font-size-xsmall" [translate]="'LABEL_CUSTOMER_NUMBER'"></p>
                <p>{{ equipment.customerId | numberFromIdPipe }}</p>
              </li>
              <li class="info-list__item">
                <p class="margin-bottom-0 color-black-60 font-size-xsmall" [translate]="'GENERIC_LABEL_SERIAL_NUMBER'"></p>
                <p>{{ equipment.serialNumber }}</p>
              </li>
              <li class="info-list__item">
                <p class="margin-bottom-0 color-black-60 font-size-xsmall" [translate]="'LABEL_MATERIAL_NUMBER'"></p>
                <p>{{ equipment.materialNumber }}</p>
              </li>
              <li class="info-list__item">
                <p class="margin-bottom-0 color-black-60 font-size-xsmall" [translate]="'SOFTWARE_VERSION'"></p>
                <p>{{ equipment.softwareVersion }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
}
<div [formGroup]="productForm">
  <h5 class="margin-top-0" [translate]="'LABEL_SAO_PRODUCT_INFORMATION'"></h5>
  <div class="row" hlHelperTextForms>
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_IP_ADDRESS" name="ipAddress" [formControl]="productForm.controls['ipAddress']" ngDefaultControl
        [isInvalid]="isFormSubmitted && !productForm.controls['ipAddress'].valid"
        [isRequired]="productForm.controls['ipAddress'].errors && productForm.controls['ipAddress'].errors['required']"></hl-textfield>
      @if (isFormSubmitted && !productForm.controls['ipAddress'].valid) {
        <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
      }
    </div>
    <div class="col-12 col-lg-4">
      <div class="form__helper-container">
        <p class="form__helper-text" [innerHtml]="'TEXT_SAO_IP_ADDRESS' | translate"></p>
      </div>
    </div>
  </div>

  <div class="row" hlHelperTextForms [selector]="'textarea__area'">
    <div class="col-12 col-lg-8">
      <hl-textarea [parentForm]="productForm" [name]="'ipAddressFirewall'" [labelText]="'LABEL_IP_ADDRESS_FIREWALL'"
        [isInvalid]="isFormSubmitted && !productForm.controls['ipAddressFirewall'].valid" [isLarge]="true"
        [isRequired]="productForm.controls['ipAddressFirewall'].errors && productForm.controls['ipAddressFirewall'].errors['required']"></hl-textarea>
      @if (isFormSubmitted && !productForm.controls['ipAddressFirewall'].valid) {
        <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
      }
    </div>
    <div class="col-12 col-lg-4">
      <div class="form__helper-container">
        <p class="form__helper-text" [innerHtml]="'TEXT_SAO_IP_ADDRESS_FIREWALL' | translate"></p>
      </div>
    </div>
  </div>

  <div class="row" hlHelperTextForms [selector]="'textarea__area'">
    <div class="col-12 col-lg-8">
      <hl-textarea [parentForm]="productForm" [name]="'srsConnection'" [labelText]="'LABEL_SRS_CONNECTION'"
        [isInvalid]="isFormSubmitted && !productForm.controls['srsConnection'].valid" [isLarge]="true"
        [isRequired]="productForm.controls['srsConnection'].errors && productForm.controls['srsConnection'].errors['required']"></hl-textarea>
      @if (isFormSubmitted && !productForm.controls['srsConnection'].valid) {
        <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
      }
    </div>
    <div class="col-12 col-lg-4">
      <div class="form__helper-container">
        <p class="form__helper-text" [innerHtml]="'TEXT_SAO_SRS_CONNECTION' | translate"></p>
      </div>
    </div>
  </div>

  <div class="row" hlHelperTextForms [selector]="'textarea__area'">
    <div class="col-12 col-lg-8">
      <hl-textarea [parentForm]="productForm" [name]="'targetPorts'" [rows]="7" [labelText]="'LABEL_TARGET_PORTS'"
        [isInvalid]="isFormSubmitted && !productForm.controls['targetPorts'].valid" [isLarge]="true"
        [isRequired]="productForm.controls['targetPorts'].errors && productForm.controls['targetPorts'].errors['required']"></hl-textarea>
      @if (isFormSubmitted && !productForm.controls['targetPorts'].valid) {
        <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
      }
    </div>
    <div class="col-12 col-lg-4">
      <div class="form__helper-container">
        <p class="form__helper-text" [innerHtml]="'TEXT_SAO_TARGET_PORTS' | translate"></p>
      </div>
    </div>
  </div>
</div>
