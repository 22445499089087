import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PipesModule } from '../../../../core/pipes.module';
import { DotTimeSlot } from '../../models/dot-appointment-slots';
import { toSignal } from '@angular/core/rxjs-interop';
import { DatePipeWrapperPipe } from '../../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';

@Component({
  selector: 'hl-dot-time-slot-label',
  template: '{{formattedTimeSlot()}}',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    PipesModule
  ],
})
export class DotTimeSlotLabelComponent {

  private datePipe = inject(DatePipeWrapperPipe);

  timeSlot = input.required<DotTimeSlot>();
  config = input.required<Record<string, string>>();

  translations: Signal<Record<string, string>> = toSignal(
    inject(TranslateService).get(['DOT_TIME_SLOT_FORMAT', 'DAYS']));

  formattedTimeSlot = computed(() => {
    const [config, translations] = [this.config(), this.translations()];
    const { start, finish } = this.timeSlot();

    const datePattern = config.GENERIC_DATE_PATTERN;
    const timePattern = config.GENERIC_TIME_PATTERN;
    const slotFormat = translations.DOT_TIME_SLOT_FORMAT;
    const days = translations.DAYS.split(',');

    return slotFormat
      .replace('{{day}}', days[new Date(start).getDay()])
      .replace('{{date}}', this.datePipe.transform(start, datePattern))
      .replace('{{timeFrom}}', this.datePipe.transform(start, timePattern))
      .replace('{{timeTo}}', this.datePipe.transform(finish, timePattern));
  });

}
