import { CustomerGroupViewModel } from '../../view-models/customer-group-view-model';
import { CustomerGroup } from '../../models/customer/customer-group';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomerRestService } from '../../rest-services/customer-rest.service';
import { CustomerViewModel } from '../../view-models/customer-view-model';
import { EventEmitter, Injectable } from '@angular/core';
import { cloneDeep, forEach, isEqual, orderBy, startsWith } from 'lodash-es';
import { roles } from '../../core-constants.service';
import { translationCountryPrefix } from '../../utils/impersonation-utils.service';
import { UserUtilService } from '../user/user-util.service';
import { ImpersonationCacheService } from '../cache/impersonation-cache.service';
import { map } from 'rxjs/operators';
import { CustomerData } from '../../models/customer/customer-name-options';

@Injectable({providedIn: 'root'})
export class CustomerUtilService {
  showCreateGroupModalEmitter = new EventEmitter<any>();
  showDeleteGroupModalEmitter = new EventEmitter<any>();

  customerEdited = new EventEmitter<any>();
  customerToEdit: CustomerViewModel;

  constructor(
    private userUtilService: UserUtilService,
    private translateService: TranslateService,
    private customerRestService: CustomerRestService,
    private impersonationCacheService: ImpersonationCacheService
  ) {
  }

  showCreateGroupModal(countryId: string, groupId: number) {
    this.showCreateGroupModalEmitter.emit({countryId, groupId});
  }

  showDeleteGroupModal(group: any) {
    this.showDeleteGroupModalEmitter.emit({group});
  }

  checkCustomerAdministrationRoles() {
    const rolesToCheck: any = {
      checkCountryAdminRole: roles.countryAdminRole,
      checkMasterAdminRole: roles.masterAdminRole,
      checkCustomerAdminRole: roles.customerAdminRole,
      checkAsuUploadManagerRole: roles.asuUploadManagerRole
    };

    return this.userUtilService.checkUserRoles(rolesToCheck);
  }

  /**
   * @param customerKey the customer Id
   * @returns {boolean}
   */
  isLdCustomer(customerKey) {
    return startsWith(customerKey, 'X');
  }

  getCustomerGroupsByCountry(
    country: string
  ): Observable<CustomerGroupViewModel[]> {
    return this.impersonationCacheService
      .getGroupListByCountry(country)
      .pipe(map(customerGroupResponse => {
        return this.mapCustomerGroupsToViewModel(customerGroupResponse);
      }));
  }

  getCustomerGroupByGroupId(
    id: string,
    country: string
  ): Observable<CustomerGroupViewModel> {
    return this.getCustomerGroupsByCountry(country).pipe(map(
      customerGroupResponse => {
        return customerGroupResponse.filter(group =>
          isEqual(group.groupId.toString(), id.toString())
        )[0];
      }
    ));
  }

  /**
   * mapping the countryLabel into the group, adding two flags for the view
   * and ordering the customer-sub-list
   * @param {CustomerGroup[]} groupList
   * @returns {CustomerGroupViewModel[]}
   */
  mapCustomerGroupsToViewModel(
    groupList: CustomerGroup[]
  ): CustomerGroupViewModel[] {
    const mappedList: CustomerGroupViewModel[] = [];

    forEach(groupList, (group: CustomerGroup) => {
      const groupView: CustomerGroupViewModel = {
        country: group.country,
        groupId: group.groupId,
        name: group.name,
        creatorGid: group.creatorGid,
        type: group.type,
        // tslint:disable-next-line:ban
        countryLabel: this.translateService.instant(
          translationCountryPrefix + group.country
        ),
        editable: false,
        toggleStatus: false, // should be closed in the beginning
        showCity: false,
        showState: false,
        customers: cloneDeep(group.customers)
      };

      groupView.customers = orderBy(
        groupView.customers,
        ['customerName'],
        ['asc']
      );

      mappedList.push(groupView);
    });

    return mappedList;
  }

  getIdenticalCustomer(customer: CustomerViewModel, customers: CustomerViewModel[]) {
    let identicalCustomer: CustomerViewModel;
    if (this.isLdCustomer(customer.customerId)) {
      identicalCustomer = customers.find(x => x.customerId === customer.customerId.slice(1));
    } else {
      identicalCustomer = customers.find(x => x.customerId === 'X'.concat(customer.customerId));
    }
    return identicalCustomer;
  }

  getCustomerData(customerId: string): Observable<CustomerData> {
    return this.customerRestService.getCustomerData(customerId);
  }

  postCustomerChange(customerId: string, customerData): Observable<any> {
    return this.customerRestService.postCustomerChange(customerId, customerData);
  }

  clearCustomerDataCache(customerId: string) {
    this.customerRestService.clearCustomerDataCache(customerId);
  }
}
