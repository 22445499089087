<div class="collection-item list-item" [class.list-item--actionless]="isListWithoutAction()"
     (click)="openDetailOverlay(item())" [attr.data-cy]="'activity-' + (index() + 1)">
  <div class="collection__content">
    <p class="list-item__title">
      {{item().activityTitle}}
      @if (isActivityClosed() && !showAppointments()) {
        <span class="label" [translate]="'TICKET_STATE_CLOSED'"></span>
      } @else if (showAppointments() && !showMainListProperties()) {
        <span class="label" [translate]="'LABEL_ACTIVITY_APPOINTMENT_STATUS_' + item().filterStatus"></span>
      }
    </p>

    <hl-activity-property-list
      [item]="item()"
      [index]="index()"
      [visibleProperties]="displayedProperties()"
      [hasAction]="!isListWithoutAction()"
      [customerNameLength]="customerNameLength()"
      [datePattern]="datePattern()"
      [dateTimePattern]="dateTimePattern()"
    ></hl-activity-property-list>
  </div>
</div>
