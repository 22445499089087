import { Component, Input } from '@angular/core';

@Component({
  selector: 'hl-intro-slider-gallery',
  templateUrl: './intro-slider-gallery.component.html'
})
export class IntroSliderGalleryComponent {
  @Input() data: any;
  @Input() currentIndex: number;
}
