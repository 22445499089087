import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export const startBeforeEndDate = (startDateControlName: string, endDateControlName: string): ValidatorFn => {
  return (control: UntypedFormControl) => {
    const value = control.value;
    const startDate: Date = value[startDateControlName];
    const endDate: Date = value[endDateControlName];

    if (startDate === null || endDate === null) {
      return null;
    }

    return startDate.getTime() > endDate.getTime() ? { dateInterval: true } : null;
  };
}
