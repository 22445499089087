import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SecurityNotificationViewModel } from '../../../core/models/securityNotifications/security-notifications';
import { SecurityNotificationsService, statusColorTranslation } from '../../../core/services/security-notifications/security-notifications.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Subject, Subscription } from 'rxjs';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';

@Component({
  selector: 'hl-security-detail',
  templateUrl: './security-detail.component.html'
})
export class SecurityDetailComponent implements OnInit, OnDestroy {
  @Input()
  currentNotification: SecurityNotificationViewModel;
  @Input()
  loading = false;
  myEquipmentChecked = false;
  overlaySearchInput = '';

  numberOfEquipmentsForOverlay: number;
  myEquipmentsList = [];
  datePattern = '';

  private readonly unsubscribe$ = new Subject<void>();
  updateSecurityDetailStatusSubscription: Subscription;
  status: string;
  multiSelectEvaluationOptions: DropdownOptions[];
  selectedDropDownList = {
    evaluation: []
  };
  showReadMore = false;

  @Input() selectedSeverities: string[];
  evaluationResult: string [] = [];

  statusLabel = statusColorTranslation;

  constructor(
    private myFiltersService: MyFiltersAdapterService,
    private equipmentUtilService: EquipmentUtilService,
    private securityNotificationsService: SecurityNotificationsService,
    private configService: CountryConfigRestService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.registerEventListeners();
    this.loadMyEquipmentData();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.datePattern = configResponse.GENERIC_DATE_PATTERN;
    });
    this.updateSecurityDetailStatusSubscription = this.equipmentUtilService.updateSecurityDetailStatusEmitter.subscribe(status => {
      this.status = status;
      if (this.currentNotification) {
        this.showReadMore = this.currentNotification.cves && this.currentNotification.cves.length > 0;
      }
      this.changeDetector.detectChanges();
    });
  }

  loadMyEquipmentData() {
    this.multiSelectEvaluationOptions = this.securityNotificationsService.getMultiSelectEvaluationOptions();
    if (this.selectedSeverities) {
      this.selectedDropDownList.evaluation.push(...this.selectedSeverities);
    }
  }

  registerEventListeners() {
    this.myFiltersService.status$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      ({overallSwitch, equipment}) => {
        this.myEquipmentChecked = overallSwitch;
        this.myEquipmentsList = equipment.map(e => e.key);
      }
    );
  }

  setNumberOfEquipmentsForOverlay(numberOfAffectedEquipments: number) {
    this.numberOfEquipmentsForOverlay = numberOfAffectedEquipments;
  }

  isMyEquipmentChecked(): boolean {
    return this.myEquipmentChecked;
  }

  getMyEquipmentProfileList(): string[] {
    return this.myEquipmentsList;
  }

  capitalizeFirstLetter(string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.updateSecurityDetailStatusSubscription) {
      this.updateSecurityDetailStatusSubscription.unsubscribe();
    }
  }

  onFilterChange(event: string[]) {
    this.evaluationResult = event ? event : [];
    const search = this.overlaySearchInput;
    this.overlaySearchInput = null;
    this.changeDetector.detectChanges();
    this.overlaySearchInput = search;
    this.changeDetector.detectChanges();
  }
}
