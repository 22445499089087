import { ChangeDetectionStrategy, Component, computed, ContentChild, forwardRef, inject, Injector, input, OnInit, Signal, signal, TemplateRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'hl-sequential-item-picker',
  templateUrl: './sequential-item-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    NgTemplateOutlet
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SequentialItemPickerComponent),
    multi: true
  }]
})
export class SequentialItemPickerComponent<T = unknown> implements ControlValueAccessor, OnInit {
  items = input.required<T[]>();

  // using ContentChild decorator here instead of contentChild signal because of a ngMocks issue
  // https://github.com/help-me-mom/ng-mocks/issues/8634
  // https://github.com/help-me-mom/ng-mocks/issues/8895
  // this can be safely replaced with signal when this issue is fixed
  @ContentChild(TemplateRef) itemTemplate?: TemplateRef<{ $implicit: T }>;

  currentItemIndex = signal<number>(0);
  currentItem: Signal<T> = computed(() => this.items()[this.currentItemIndex()]);
  disabled = signal(false);

  private onChange: (value: T) => void = () => {};
  private onTouched = () => {};
  private injector = inject(Injector);

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null, { optional: true, self: true });
    if (ngControl?.control?.value === null) {
      ngControl?.control?.setValue(this.currentItem(), { emitEvent: true });
    }
  }

  selectPreviousItem(): false {
    this.updateCurrentItemByIndex(this.currentItemIndex() - 1);
    this.onTouched();
    return false; // prevent trigger of default action - invocation of href attribute
  }

  selectNextItem(): false {
    this.updateCurrentItemByIndex(this.currentItemIndex() + 1);
    this.onTouched();
    return false; // prevent trigger of default action - invocation of href attribute
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  writeValue(obj: T): void {
    const index = this.items().findIndex(item => item === obj);
    if (index !== -1) {
      this.currentItemIndex.set(index);
    }
  }

  private updateCurrentItemByIndex(index: number): void {
    this.currentItemIndex.set(index);
    this.onChange(this.currentItem());
  }
}
