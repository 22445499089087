<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="headerMessage ? headerMessage : 'GENERIC_LABEL_CANCEL'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc">
        @if (translateDescription) {
          <p [translate]="descriptionMessage"></p>
        } @else {
          <p>{{ descriptionMessage }}</p>
        }
      </div>
      <div class="modal-dialog__footer">
        <button class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_YES'" data-cy="cancel-modal-yes-btn"></button>
        <button class="button" (click)="close()" [translate]="'GENERIC_LABEL_NO'"></button>
      </div>
    </div>
  </div>
</ng-template>
