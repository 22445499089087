import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { Equipment } from '../../../core/models/equipment/equipment';
import { MdmService } from '../../../core/services/mdm.service';
import { BusinessLine, MdmData, UserGroup } from '../../../core/models/mdm';
import { finalize } from 'rxjs/operators';
import { MdmRegisterData } from '../../../core/models/mdm-register';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { Observable, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-mdm-manage-table-modal',
  templateUrl: './mdm-manage-tablet-modal.component.html'
})
export class MdmManageTabletModalComponent extends BaseModalPopup implements OnInit {

  @Input()
  equipment: Equipment = null;

  mdmForm: UntypedFormGroup;
  dropdownActions: DropdownOptions[];
  selectedAction = '1';
  businessLines: DropdownOptions[];
  swVersions: DropdownOptions[];
  assignedDevicesSerialNumbers: DropdownOptions[] = [];
  isFormSubmitted = false;
  mdmData: MdmData;
  options: UserGroup[] = [];
  addedFormOptions: string[] = [];
  selectedOptions: UserGroup[] = [];

  @Output()
  close = new EventEmitter();
  selectedMaterialNumber: DropdownOptions;
  whitelistedMaterialNumbers$: Observable<DropdownOptions[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private mdmService: MdmService,
    private toasterService: ToasterService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.initProperties();
    this.createForm();
    if (this.equipment) {
      this.prefilledForm();
    }
  }

  initProperties() {
    this.mdmService.getMdmData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
      this.mdmData = res;
      this.businessLines = [];
      if (this.mdmData) {
        for (const key of Object.keys(this.mdmData)) {
          this.businessLines.push({title: key + ' - ' + this.mdmData[key].name, value: key});
        }
      }
    });
    this.dropdownActions = [
      {
        title: 'LABEL_MDM_REGISTER_TABLET',
        value: '0'
      },
      {
        title: 'LABEL_MDM_REPLACE_TABLET',
        value: '1'
      }
    ];
  }

  onSelectBusinessLine() {
    if (this.mdmData) {
      const businessLine: BusinessLine = this.mdmData[this.mdmForm.controls['businessLine'].value];
      if (businessLine) {
        this.setSwVersions(businessLine);
        this.setOptions(businessLine);
        this.selectedOptions = [];
        if (this.equipment === null) {
          this.setMaterialNumbers(this.mdmForm.controls['businessLine'].value);
        }
      }
    }
  }

  replaceOrRegisterTablet() {
    this.showSpinner = true;
    const formData = Object.assign({}, this.mdmForm.value);
    const mdmRegister: MdmRegisterData = {
      serialNumber: formData.systemSerialNumber,
      materialNumber: formData.systemMaterialNumber,
      removedSerialNumber: formData.removedTabletSerialNumber,
      newSerialNumber: formData.newTabletSerialNumber,
      businessLine: formData.businessLine,
      businessLineId: this.mdmData[formData.businessLine].id,
      version: this.mdmData[formData.businessLine].versions.find(version => version.id.toString() === formData.swPlatformVersion),
      options: this.selectedOptions
    } as MdmRegisterData;
    this.mdmService.registerOrReplaceTablet(mdmRegister).pipe(
      finalize(() => {
        this.mdmService.clearCache(mdmRegister.materialNumber, mdmRegister.serialNumber, mdmRegister.businessLineId);
        this.resetAndHide();
      })
    ).subscribe({
      next: () => this.toasterService.showTranslatedSuccessToaster(this.selectedAction === '0' ?
        'LABEL_MDM_TABLET_REGISTERED_SUCCESSFULLY' : 'LABEL_MDM_TABLET_REPLACED_SUCCESSFULLY'),
      error: (error) => this.toasterService.showTranslatedErrorToaster(error && error.error && error.error.code === 112 ?
        'LABEL_MDM_DEVICE_SERIAL_NUMBER_ERROR' : 'FRONTEND_GENERIC_ERROR_MESSAGE')
    });
  }

  show(event?: Event) {
    if (this.equipment) {
      this.prefilledForm();
    }
    super.show(event);
  }

  ok() {
    // intentionally left empty
  }

  resetAndHide() {
    this.isFormSubmitted = false;
    this.selectedAction = '1';
    this.selectedMaterialNumber = undefined;
    this.whitelistedMaterialNumbers$ = of([]);
    this.mdmForm.reset();
    this.mdmForm.markAsUntouched();
    this.createForm();
    this.hide();
  }

  onChangeAction() {
    this.mdmForm.reset();
    this.mdmForm.markAsUntouched();
    this.selectedAction === '0' ? this.mdmForm.controls['removedTabletSerialNumber'].clearValidators() :
      this.mdmForm.controls['removedTabletSerialNumber'].setValidators(Validators.required);
    this.mdmForm.controls['removedTabletSerialNumber'].updateValueAndValidity();
    this.swVersions = [];
    this.options = [];
    this.assignedDevicesSerialNumbers = [];
    this.selectedMaterialNumber = undefined;
    this.mdmForm.controls['systemMaterialNumber'].updateValueAndValidity();
  }

  toggleOptionTrue(option: UserGroup) {
    this.selectedOptions.push(option);
    this.patchOptionValue(option, true);
  }

  toggleOptionFalse(option: UserGroup) {
    const index = this.selectedOptions.indexOf(option);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    }
    this.patchOptionValue(option, false);
  }

  private createForm() {
    this.mdmForm = this.fb.group({
      businessLine: ['', [Validators.required]],
      systemSerialNumber: ['', [Validators.required]],
      systemMaterialNumber: ['', [Validators.required]],
      swPlatformVersion: ['', [Validators.required]],
      removedTabletSerialNumber: ['', [Validators.required]],
      newTabletSerialNumber: ['', [Validators.required]]
    });
  }

  private prefilledForm() {
    this.mdmService.getMdmPrefilledValues(this.equipment).subscribe(values => {
      if (values.assignedSerialNumbers) {
        this.assignedDevicesSerialNumbers = values.assignedSerialNumbers.map(sn => {
          return {
            title: sn,
            value: sn
          };
        });
      }
      this.mdmForm.patchValue(values);
    });
  }

  private patchOptionValue(option: UserGroup, value) {
    this.mdmForm.get('option' + option.id).patchValue(value);
  }

  private setSwVersions(businessLine: BusinessLine) {
    this.swVersions = [];
    for (const version of businessLine.versions) {
      this.swVersions.push({title: version.name, value: version.id + ''});
    }
  }

  private setOptions(businessLine: BusinessLine) {
    for (const prevOption of this.addedFormOptions) {
      this.mdmForm.removeControl(prevOption);
    }
    for (const option of businessLine.options) {
      this.addedFormOptions.push('option' + option.id);
      this.mdmForm.addControl('option' + option.id, new UntypedFormControl(''));
    }
    this.options = businessLine.options;
  }

  onMaterialNumberChange() {
    this.mdmForm.get('systemMaterialNumber').patchValue(this.selectedMaterialNumber.value);
  }

  private setMaterialNumbers(businessLineKey: string) {
    this.whitelistedMaterialNumbers$ = this.mdmService.getWhitelistedMaterialNumbersDropdownOptions(businessLineKey);
    this.mdmForm.get('systemMaterialNumber').patchValue(undefined);
    this.selectedMaterialNumber = undefined;
  }
}
