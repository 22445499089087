import { Component, ViewChild } from '@angular/core';
import { InfoModalComponent } from 'app/shared/modal-popup/info-modal/info-modal.component';

@Component({
  selector: 'hl-create-ticket-disclaimer-popup',
  templateUrl: './create-ticket-disclaimer-popup.component.html'
})
export class CreateTicketDisclaimerPopupComponent {

  @ViewChild('infoModal') infoModal: InfoModalComponent;

  show() {
    this.infoModal.show();
  }

}
