import { Component, Input, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { TicketViewModel } from '../../../core/view-models/ticket-view-model';

@Component({
  selector: 'hl-ticket-survey-modal',
  templateUrl: './ticket-survey-modal.component.html'
})
export class TicketSurveyModalComponent extends BaseModalPopup {
  surveyLink$: Observable<string>;

  @Input() set ticketItem({ ticketNumber }: TicketViewModel) {
    this.surveyLink$ = this.ticketsRestService.getTicketSurveyLink(ticketNumber).pipe(map(s => s.surveyLink));
  }

  constructor(renderer: Renderer2, private ticketsRestService: TicketsRestService) {
    super(renderer);
  }

  ok() {
    // intentionally left empty
  }

}
