import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { BaseEducationBalanceResponse } from '../models/reporting/base-education-balance-response';
import { BaseReportRequest } from '../models/reporting/base-report-request';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import { BaseReportRequestUptime } from '../models/reporting/base-report-request-uptime';
import { BaseUptimeResponse } from '../models/reporting/base-uptime-response';
import { BasePerformanceSummaryResponse } from '../models/reporting/base-performance-summary-response';
import { BaseReportRequestPerformanceSummary } from '../models/reporting/base-report-request-performance-summary';
import { BaseRemoteActivityResponse } from '../models/reporting/base-remote-activity-response';
import { BaseReportRequestRemoteActivity } from '../models/reporting/base-report-request-remote-activity';
import { DocumentFile } from '../models/equipment/documents/document-file';

const educationReportRestName = restEndPoint + 'reports/education';
const uptimeReportRestName = restEndPoint + 'reports/uptime';
const performanceSummaryReportRestName = restEndPoint + 'reports/servicePerformance';
const remoteActivityReportRestName = restEndPoint + 'reports/remoteActivity';
const nyuReportRestName = restEndPoint + 'reports/nyuReport';
const nyuReportRestNameAllowed = nyuReportRestName + '/allowed';

@Injectable({providedIn: 'root'})
export class GenerateReportsRestService {
  exportXLSRestName = '/excel';
  exportPDFRestName = '/pdf';

  constructor(private http: CacheableHttpClient) {
  }

  getEducationReport(request: BaseReportRequest): Observable<BaseEducationBalanceResponse> {
    const body = {
      isMyEquipment: request.isMyEquipment
    };
    return this.http.post<BaseEducationBalanceResponse>(educationReportRestName, body);
  }

  getUptimeReport(request: BaseReportRequestUptime): Observable<BaseUptimeResponse[]> {
    const body = {
      isMyEquipment: request.isMyEquipment,
      modalityCodes: request.modalities
    };
    return this.http.post<BaseUptimeResponse[]>(uptimeReportRestName, body);
  }

  getPerformanceSummaryReport(request: BaseReportRequestPerformanceSummary): Observable<BasePerformanceSummaryResponse> {
    const body = {
      isMyEquipment: request.isMyEquipment,
      startDate: request.startDate,
      endDate: request.endDate,
      topRecords: request.topRecords
    };
    return this.http.post<BasePerformanceSummaryResponse>(performanceSummaryReportRestName, body);
  }

  getRemoteActivityReport(request: BaseReportRequestRemoteActivity): Observable<BaseRemoteActivityResponse[]> {
    const body = {
      isMyEquipment: request.isMyEquipment,
      startDate: request.startDate,
      endDate: request.endDate,
      modalityCodes: request.modalities
    };
    return this.http.post<BaseRemoteActivityResponse[]>(remoteActivityReportRestName, body);
  }

  exportFiles(postRequest, reportType: string, fileType: string) {
    const restName = this.getRestNameByReportType(reportType);
    const exportRestName = this.getRestNameByFileType(fileType);
    return this.http.post(restName + exportRestName, postRequest, {responseType: 'arraybuffer'});
  }

  private getRestNameByReportType(reportType: string) {
    switch (reportType) {
      case 'UPTIME':
        return uptimeReportRestName;
      case 'EDUCATION_BALANCE':
        return educationReportRestName;
      case 'REMOTE_ACTIVITY':
        return remoteActivityReportRestName;
      case 'PERFORMANCE_SUMMARY':
        return performanceSummaryReportRestName;
    }
  }

  private getRestNameByFileType(fileType: string) {
    switch (fileType) {
      case 'xls':
        return this.exportXLSRestName;
      case 'pdf':
        return this.exportPDFRestName;
    }
  }

  getNyuReport(): Observable<DocumentFile[]> {
    return this.http.get<DocumentFile[]>(nyuReportRestName);
  }

  isNyuReportAllowed(): Observable<boolean> {
    return this.http.get<boolean>(nyuReportRestNameAllowed, {cache: false });
  }
}
