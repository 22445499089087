import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectOption } from '../../models/select-option';

@Injectable({providedIn: 'root'})
export class CreatePsrTemplateEventService {

  // Observable createTicket changed ticket type sources
  private changedPsrTemplateSource = new Subject<SelectOption>();

  // Observable createTicket streams
  changedPsrTemplateSource$ = this.changedPsrTemplateSource.asObservable();

  // Service createTicket -> (ticket create)
  emitPSRTemplateSelected(psrTemplate: SelectOption) {
    this.changedPsrTemplateSource.next(psrTemplate);
  }
}
