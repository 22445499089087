import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { NotifStatus, restEndPoint } from 'app/core/core-constants.service';
import { SelectOption } from 'app/core/models/select-option';
import { TicketTypes } from 'app/core/models/tickets/ticket-types';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { TicketsRestService } from 'app/core/rest-services/tickets-rest.service';
import { EquipmentUtilService } from 'app/core/services/equipment/equipment-util.service';
import { TicketsUtilService } from 'app/core/services/tickets/tickets-util.service';
import { AttachmentUtilService } from 'app/core/utils/attachment-util.service';
import { TicketAttachmentViewModel } from 'app/core/view-models/ticket-attachment-view-model';
import { TicketViewModel } from 'app/core/view-models/ticket-view-model';
import { SpaceValidator } from 'app/shared/validators/space.validator';
import { ActivitiesRestService } from 'app/core/rest-services/activities-rest.service';
import { AddOmnitureAndRouterStateNameDirective } from '../../directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { MedalliaUtilService } from 'app/core/utils/medallia-util.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';

@Component({
  selector: 'hl-ticket-update-modal',
  templateUrl: './ticket-update-modal.component.html'
})
export class TicketUpdateModalComponent extends BaseModalPopup
  implements OnInit, OnChanges {
  @Input()
  ticketItem: TicketViewModel | ActivitiesViewModel;
  @Input()
  ticketAction: string;

  viewModelTicket: TicketViewModel;

  datePattern = '';
  dateTimePattern = '';
  headerLabel = '';

  isLoaded: boolean;
  showValidationMessage: boolean;
  isFormSubmitted: boolean;
  showRequestArea: boolean;
  showDangerForPatients: boolean;
  showTicketOwnIncidentNumber: boolean;
  showTicketAttachmentByEquipment: boolean;
  showTicketAttachmentByTicketType: boolean;
  showConfidentialDataConfirmation = false;
  showPhoneNumberExtension = false;

  downloadAttachmentList: TicketAttachmentViewModel[];

  translationErrorMessage =
    'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  // Label text for spinner
  ticketLabelInProgress = 'TICKET_UPDATE_IN_PROGRESS';

  updateTicketForm: UntypedFormGroup;
  ticketUpdateAllowed: boolean;
  ticketTypes: TicketTypes[];
  ticketTypesOptions: SelectOption[];
  sapSystem: string;

  constructor(
    private configService: CountryConfigRestService,
    private fb: UntypedFormBuilder,
    private ticketsUtilService: TicketsUtilService,
    private attachmentUtilService: AttachmentUtilService,
    private toasterService: ToasterService,
    private ticketsRestService: TicketsRestService,
    private activitiesRestService: ActivitiesRestService,
    renderer: Renderer2,
    private equipmentUtilService: EquipmentUtilService,
    private medalliaUtilService: MedalliaUtilService
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['ticketItem'] && !changes['ticketItem'].firstChange) {
      this.init();
    }
  }

  init() {
    this.initProperties();
    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(configResponse => {
      this.createForm(configResponse);
      if (isEqual(this.ticketAction, 'activity')) {
        this.prepareActivityUpdate();
      } else {
        this.loadViewModelTicket(configResponse.SAP_BACKEND_SYSTEM);
      }
    });
  }

  initProperties() {
    this.viewModelTicket = null;
    this.downloadAttachmentList = [];

    this.isLoaded = false;
    this.showValidationMessage = false;
    this.isFormSubmitted = false;
    this.showRequestArea = false;
    this.showDangerForPatients = false;
    this.showTicketOwnIncidentNumber = false;
    this.showSpinner = true;
    this.showTicketAttachmentByEquipment = false;
    this.showTicketAttachmentByTicketType = false;

    if (isEqual(this.ticketAction, 'update')) {
      this.headerLabel = 'UPDATE_TICKET_LABEL';
    } else if (isEqual(this.ticketAction, 'convert')) {
      this.headerLabel = 'CONVERT_TICKET_LABEL';
    } else if (isEqual(this.ticketAction, 'activity')) {
      this.headerLabel = 'UPDATE_ACTIVITY_LABEL';
    }

    this.ticketUpdateAllowed = false;
    this.ticketTypes = [];
    this.ticketTypesOptions = [];
  }

  createForm(config) {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = parseInt(config.EMAIL_VALIDATION_LENGTH);
    this.showConfidentialDataConfirmation =
      isEqual(config.SHOW_CONFIDENTIAL_PATIENT_DATA_SECTION, 'true');
    this.showPhoneNumberExtension = isEqual(config.FEATURE_TOGGLE_PHONE_EXTENSION, 'true');

    this.updateTicketForm = this.fb.group({
      ticketKey: [this.ticketItem?.ticketKey ? this.ticketItem?.ticketKey : ''],
      action: [String(this.ticketAction).toUpperCase()],
      ticketNumber: [''],
      typeID: [''],
      customerId: [''],
      attachments: [[]],
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactPhone: [''],
        contactPhoneExt: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      poNumber: [''],
      feedBack: [isEqual(config[this.getFeedbackConfig()], 'true') ? 'email' : ''],
      longText: ['', [Validators.required, Validators.maxLength(2000), SpaceValidator.noWhiteSpace]],
      confirmNoPatientData: this.fb.group({
        confirmed: [
          !this.showConfidentialDataConfirmation,
          [Validators.requiredTrue]
        ]
      })
    });

    this.setConfigProperties(config);
  }

  getFeedbackConfig() {
    return this.ticketAction === 'update' ? 'ACTIVITY_SHOW_RESPONSE_AREA' : 'TICKET_SHOW_RESPONSE_AREA';
  }

  setConfigProperties(config) {
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
    this.sapSystem = config.SAP_BACKEND_SYSTEM;
    this.isTicketTypeUpdateAllowed(config);

    // feedback requested
    if (isEqual(config.FEEDBACK_REQUESTED_EMAIL, 'false')) {
      this.updateTicketForm.patchValue({feedback: 'phone'});
    }

    if (isEqual(config.TICKET_DANGER_FOR_PATIENT_RENDER, 'true')) {
      this.showDangerForPatients = true;
    }

    // check for render ticket own incident number
    if (isEqual(config.TICKET_SHOW_OWN_INCIDENT_NUMBER_CREATE, 'true')) {
      this.showTicketOwnIncidentNumber = true;
    }

    // check for render ticket own incident number
    if (!this.equipmentUtilService.checkIsLdEquipment(this.ticketItem?.ticketKey)) {
      this.showRequestArea = isEqual(config.TICKET_SHOW_OWN_INCIDENT_NUMBER_UPDATE, 'true');
    }
  }

  isTicketTypeUpdateAllowed(config) {
    this.ticketsUtilService
      .getAllowedTicketTypesUpdateByEquipmentKey(this.ticketItem, config)
      .subscribe(response => {
        this.ticketTypes = response;
        if (this.ticketTypes.length > 1) {
          this.ticketUpdateAllowed = true;
          this.updateTicketForm
            .get('typeID')
            .setValidators([Validators.required]);
        } else if (this.ticketTypes.length === 1) {
          this.updateTicketForm
            .get('typeID')
            .setValue(this.ticketTypes[0].typeId);
        } else {
          if (this.ticketItem) {
            this.updateTicketForm.get('typeID').setValue(this.ticketItem['typeID'] || this.ticketItem['type']);
          }
        }
        this.ticketTypesOptions = this.ticketsUtilService.convertTicketTypesToSelectOptions(
          this.ticketTypes
        );
      });
  }

  prepareActivityUpdate() {

    // Add the ticket number and customer number for the form
    this.updateTicketForm.patchValue({
      ticketNumber: this.ticketItem?.ticketNumber,
      customerId: this.ticketItem?.customerId
    });

    // check for ld equipment and country configurable upload button show
    this.attachmentUtilService
      .checkActivityAttachmentRenderByEquipment(
        this.ticketItem?.equipmentKey
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.showTicketAttachmentByEquipment = res;
      });


    if (this.ticketItem) {
      this.attachmentUtilService
        .checkActivityTypeAttachmentRender(this.ticketItem['typeID'] || this.ticketItem['type'])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(res => {
          this.showTicketAttachmentByTicketType = res;
        });
    }

    this.ticketUpdateAllowed = false;
    this.showRequestArea = false;

    this.showSpinner = false;
    this.isLoaded = true;
  }

  loadViewModelTicket(sapSystem: string) {
    // this method is used in update ticket, which is only possible for open tickets
    this.ticketsUtilService
      .getTicketViewModel(this.ticketItem?.ticketKey, NotifStatus.OPEN).pipe(
      finalize(() => {
        this.showSpinner = false;
        this.isLoaded = true;
      }))
      .subscribe(ticketResponse => {
        this.viewModelTicket = ticketResponse;
        this.downloadAttachmentList = this.attachmentUtilService.generateDownloadAttachment(
          this.viewModelTicket.attachments, sapSystem
        );

        this.updateTicketForm.patchValue({
          ticketNumber: this.viewModelTicket.ticketNumber,
          customerId: this.viewModelTicket.customerId
        });

        // check for ld equipment and country configurable upload button show
        this.attachmentUtilService
          .checkTicketAttachmentRenderByEquipment(
            this.viewModelTicket.equipmentKey
          )
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(res => {
            this.showTicketAttachmentByEquipment = res;
          });

        this.attachmentUtilService
          .checkTicketTypeAttachmentRender(this.viewModelTicket.typeID)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(res => {
            this.showTicketAttachmentByTicketType = res;
          });
      });
  }

  ok() {
    this.isFormSubmitted = true;

    if (this.updateTicketForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;
      if (this.ticketAction === 'activity') {
        this.updateActivity();
        return;
      }
      // Note:- Here our cache service, only cache the url portion without url params.
      const cacheClearUrl =
        restEndPoint + 'tickets/' + this.ticketItem.ticketKey;

      let longTextWithContact = this.updateTicketForm.controls['longText'].value + '\n' +
        this.updateTicketForm.get(['contact', 'contactFirstName']).value + ' ' +
        this.updateTicketForm.get(['contact', 'contactLastName']).value;
      const email = this.updateTicketForm.get(['contact', 'contactEmail']).value;
      longTextWithContact = email ? longTextWithContact + '\n' + email : longTextWithContact;
      const number = this.updateTicketForm.get(['contact', 'contactPhone']).value;
      longTextWithContact = number ? longTextWithContact + '\n' + number : longTextWithContact;
      const phoneExt = this.updateTicketForm.get(['contact', 'contactPhoneExt']).value;
      longTextWithContact = phoneExt ? longTextWithContact + ' ' + phoneExt : longTextWithContact;

      const poNumber = this.updateTicketForm.controls['poNumber'].value;
      longTextWithContact = poNumber && this.sapSystem !== 'P40' ? longTextWithContact + '\n' + 'PO number: ' + poNumber
        : longTextWithContact;

      this.updateTicketForm.patchValue({longText: longTextWithContact});

      AddOmnitureAndRouterStateNameDirective.trackGeneral(
        AddOmnitureAndRouterStateNameDirective.formStates.FORM_SENT,
        AddOmnitureAndRouterStateNameDirective.formTypes.TICKET_UPDATE_TYPE,
        this.updateTicketForm.controls['attachments'] &&
        this.updateTicketForm.controls['attachments'].value &&
        this.updateTicketForm.controls['attachments'].value.length > 0
      );

      this.ticketsRestService.updateTicket(this.updateTicketForm.value, cacheClearUrl).pipe(
        finalize(() => {
          this.hide();
          // emit that ticket has updated so listeners can react
          this.ticketsUtilService.onSuccessfulTicketUpdatedOrClosed();
        })
      )
      .subscribe(() => this.toasterService.showTranslatedSuccessToaster('TICKET_UPDATE_SUCCESSFUL'));
    } else {
      this.showValidationMessage = true;
    }
  }

  updateActivity() {
    this.activitiesRestService.update(this.updateTicketForm.value).pipe(
      finalize(() => {
        this.hide();
      }))
      .subscribe(() => this.toasterService.showTranslatedSuccessToaster('ACTIVITY_UPDATE_SUCCESSFUL'));
  }

  show() {
    this.medalliaUtilService.setPopupsAllowed(false);
    super.show();
    AddOmnitureAndRouterStateNameDirective.trackGeneral(
      AddOmnitureAndRouterStateNameDirective.formStates.FORM_OPEN,
      AddOmnitureAndRouterStateNameDirective.formTypes.TICKET_UPDATE_TYPE);
  }

  hide() {
    super.hide();
    this.medalliaUtilService.setPopupsAllowed(true);
  }
}
