<hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>

<!-- List of Attachments -->
@if (isLoaded && downloadAttachmentList.length > 0) {
  <div class="collection collection--compact margin-bottom-tiny-1">
    @for (item of downloadAttachmentList; track item; let i = $index) {
      <div class="collection-item collection-item--padding-sides">
        <div class="collection__content overflow-hidden">
          <p class="list-item__title list-item__title--break-word">{{ item.attachmentName | attachmentName | async }}</p>
          <ul class="bare-list info-list">
            @if (showSize) {
              <li class="info-list__item color-black-60">
                {{ item.size | fileSizeFormat }}
              </li>
            }
            @if (item.status !== null) {
              <li class="info-list__item">
                <i class="icon-status"></i> {{ item.status }}
              </li>
            }
          </ul>
        </div>
        <div class="collection__additional-content">
          <hl-button-with-spinner buttonClasses="button button--control" class="loading-animation--export--no-label" [disabled]="item.disableLink"
            (buttonClicked)="downloadAttachment(item.attachmentName, item.downLoadLink, i)" [showSpinner]="isLoadingIndex['attachments'].includes(i)">
            <i class="icon-download" aria-hidden="true"></i>
          </hl-button-with-spinner>
        </div>
      </div>
    }
  </div>
}

@if (downloadGeneratedAttachmentList.length > 0) {
  <h5 [translate]="'TITLE_FSR_ATTACHMENTS'" class="margin-top-0"></h5>
  <div class="collection collection--compact margin-bottom-tiny-1">
    @for (item of downloadGeneratedAttachmentList; track item.attachmentName; let i = $index) {
      <div class="collection-item collection-item--padding-sides">
        <div class="collection__content overflow-hidden">
          <p class="list-item__title list-item__title--break-word">{{ item.attachmentName }}</p>
          <ul class="bare-list info-list">
            <li class="info-list__item">
              <i class="icon-calendar"></i> {{ item.creationDate | datePipeWrapper: datePattern }}
            </li>
            @if (item.size > 0) {
              <li class="info-list__item color-black-60">
                {{ item.size | fileSizeFormat }}
              </li>
            }
          </ul>
        </div>
        <div class="collection__additional-content">
          <hl-button-with-spinner buttonClasses="button button--control" class="loading-animation--export--no-label" [disabled]="item.disableLink"
            (buttonClicked)="downloadGeneratedAttachment(item.attachmentName, item.downLoadLink, i)"
            [showSpinner]="isLoadingIndex['generatedAttachments'].includes(i)">
            <i class="icon-download" aria-hidden="true"></i>
          </hl-button-with-spinner>
        </div>
      </div>
    }
  </div>
}

@if (isLoaded && docboxDocumentList.length > 0) {
  <div class="collection collection--compact margin-bottom-tiny-1">
    @for (item of docboxDocumentList; track item; let i = $index) {
      <div class="collection-item collection-item--padding-sides">
        <div class="collection__content overflow-hidden" [attr.data-cy]="'docbox-document-' + (i+1)">
          <p class="list-item__title list-item__title--break-word">{{ item.name | attachmentName | async }}</p>
          <p class="list-item__subtitle">{{ item.docTypeName }}</p>
          <ul class="bare-list info-list">
            @if (item.fileExtension) {
              <li class="info-list__item">
                <i class="icon-document" hlTooltip [title]="'TYPE' | translate" aria-hidden="true"></i>
                {{ item.fileExtension }}
              </li>
            }
          </ul>
        </div>
        <div class="collection__additional-content">
          <hl-button-with-spinner buttonClasses="button button--control" class="loading-animation--export--no-label"
            (buttonClicked)="downloadDocBoxDocument(i, item)" [showSpinner]="isLoadingIndex['docboxDocuments'].includes(i)"
            [attr.data-cy]="'doc-box-download-btn-' + (i+1)">
            <i class="icon-download" aria-hidden="true"></i>
          </hl-button-with-spinner>
        </div>
      </div>
    }
  </div>
}

@if (isLoaded && serviceReportList.length > 0) {
  @if (serviceReportList.length > 1) {
    <h5 [translate]="'TITLE_TICKET_DOCUMENTS'" class="margin-top-0"></h5>
  }
  <div class="collection collection--compact margin-bottom-tiny-1">
    @for (serviceReport of serviceReportList; track serviceReport; let i = $index) {
      <div class="collection-item collection-item--padding-sides">
        <div class="collection__content">
          <p class="list-item__title">{{ serviceReport.fileName }}</p>
        </div>
        <div class="collection__additional-content">
          <hl-button-with-spinner buttonClasses="button button--control" class="loading-animation--export--no-label"
            (buttonClicked)="downloadServiceReport(serviceReport.fileName, serviceReport.reportLink, i)"
            [showSpinner]="isLoadingIndex['serviceReports'].includes(i)">
            <i class="icon-download" aria-hidden="true"></i>
          </hl-button-with-spinner>
        </div>
      </div>
    }
  </div>
}

@if (isLoaded && solutionText.length > 0 && isActivity()) {
  <dl hlCollapsable class="accordion" role="presentation">
    <dt aria-level="3" class="accordion__header">
      <button id="panel1-button" class="accordion__title icon-arrow-down-large" aria-controls="panel1" aria-expanded="false">
        <span [translate]="'GENERIC_LABEL_SOLUTION_TEXT'"></span>
      </button>
    </dt>
    <dd id="panel1" class="accordion__panel" aria-labelledby="panel1-button" role="region" aria-hidden="true">
      @for (item of solutionText; track item) {
        <p class="margin-0">
          {{ item }} </p>
      }
      <p></p>
    </dd>
  </dl>
}
