<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'PATIENT_SITUATION'"></h3>
      </div>
      <div class="modal-dialog__body" id="modal1_desc">
        <div class="alert alert--orange">
          <div class="alert__body" [innerHTML]="'TEXT_DANGER_PATIENT_INFORMATION' | translate"></div>
        </div>
      </div>
      <div class="modal-dialog__footer">
        <button class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_OK'"></button>
      </div>
    </div>
  </div>
</ng-template>
