<form [formGroup]="form" class="form" novalidate>
  <div class="row">
    <div class="col-12 col-lg-10">
      <hl-textfield label="SYSTEM_UPDATES_UPDATE_NUMBER" name="updateNumber" [formControl]="form.controls['updateNumber']"
        [isRequired]="form.controls['updateNumber'].errors && form.controls['updateNumber'].errors['required']" [isInvalid]="(isFormSubmitted && form.controls['updateNumber'].invalid) ||
        (form.controls['updateNumber'].invalid && form.controls['updateNumber'].touched)" maxlength="10" [isDisabled]="isEditUpdate"
        invalidLabel="SYSTEM_UPDATES_UI_NUMBER_NOT_VALID" ngDefaultControl></hl-textfield>
      <hl-textfield label="SYSTEM_UPDATES_UPDATE_TITLE" name="title" [formControl]="form.controls['title']"
        [isRequired]="form.controls['title'].errors && form.controls['title'].errors['required']" [isInvalid]="(isFormSubmitted && form.controls['title'].invalid) ||
        (form.controls['title'].invalid && form.controls['title'].touched)" maxlength="50" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        ngDefaultControl></hl-textfield>
      <hl-date-picker labelText="LABEL_DUE_DATE" [formControl]="form.controls['dueDate']"
        [isRequired]="form.controls['dueDate'].errors && form.controls['dueDate'].errors['required']" [isInvalid]="(isFormSubmitted && form.controls['dueDate'].invalid) ||
        (form.controls['dueDate'].invalid && form.controls['dueDate'].touched)" [convertModelValue]="true"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" ngDefaultControl></hl-date-picker>
    </div>
    <div class="col-4 col-lg-4">
      <span [translate]="'LABEL_SYSTEM_UPDATES_CYBERSECURITY_FLAG'"></span>
      <ul class="list list--inline bare-list">
        <li class="list__item">
          <input type="radio" class="radio radio--inline" id="yes_rg" [value]="true" formControlName="cyberSecurity"
            (click)="updateCyberSecuritySelection(true)"/>
          <label for="yes_rg" [translate]="'GENERIC_LABEL_YES'"></label>
        </li>
        <li class="list__item">
          <input type="radio" class="radio radion--inline" id="no_rg" [value]="false" formControlName="cyberSecurity"
            (click)="updateCyberSecuritySelection(false)"/>
          <label for="no_rg" [translate]="'GENERIC_LABEL_NO'"></label>
        </li>
      </ul>
    </div>
    @if (asuStatusOptions?.length) {
      <div class="col-8 col-lg-6">
        <hl-selectbox [formControl]="form.controls['asuStatus']" [options]="asuStatusOptions" label="LABEL_ASU_STATUS"
          ngDefaultControl></hl-selectbox>
      </div>
    }
  </div>
</form>
