<div class="col-lg-6">
  <hl-textfield
    [name]="'contact-name'"
    [type]="'text'"
    [label]="'DOT_CONTACT_NAME'"
    [formControl]="formGroup().controls.name"
    [additionalClass]="'margin-bottom-tiny-4'"
  ></hl-textfield>

  <hl-textfield
    [name]="'contact-email'"
    [type]="'text'"
    [label]="'GENERIC_LABEL_EMAIL'"
    [formControl]="formGroup().controls.email"
    [additionalClass]="'margin-bottom-tiny-4'"
  ></hl-textfield>

  <hl-textfield
    [name]="'contact-phone'"
    [type]="'text'"
    [label]="'GENERIC_LABEL_PHONE'"
    [formControl]="formGroup().controls.phoneNumber"
    [isRequired]="formGroup().controls.phoneNumber.errors?.required"
    [isInvalid]="showErrors() && formGroup().controls.phoneNumber.invalid"
    [invalidLabel]="'GENERIC_LABEL_NOT_VALID_PHONE'"
    [additionalClass]="'margin-bottom-tiny-4'"
  ></hl-textfield>
</div>
