import { Pipe, PipeTransform, TemplateRef } from '@angular/core';

export interface EquipmentItemListInput {
  type: string;
  key: TemplateRef<any>;
  serialNumber: TemplateRef<any>;
  street: TemplateRef<any>;
  city: TemplateRef<any>;
}

@Pipe({
  name: 'equipmentListItemTemplateMap'
})
export class EquipmentListItemTemplateMapPipe implements PipeTransform {

  transform(input: EquipmentItemListInput): TemplateRef<any> {
    switch (input.type) {
      case 'key':
        return input.key;
      case 'serialNumber':
        return input.serialNumber;
      case 'street':
        return input.street;
      case 'city':
        return input.city;
      default:
        return null;
    }
  }

}
