import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { psrPreApprovalStatusOther, PsrTypeEnum } from 'app/core/core-constants.service';
import { PsrTemplateByModality } from 'app/core/models/psr/psr-template-by-modality';
import { SelectOption } from 'app/core/models/select-option';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { PsrRestService } from 'app/core/rest-services/psr-rest.service';
import { EquipmentUtilService } from 'app/core/services/equipment/equipment-util.service';
import { LifeNetUtilService } from 'app/core/utils/life-net-util.service';
import { PsrUtilService } from 'app/core/utils/psr-util.service';
import { filter, includes, isEmpty, isEqual, omit, parseInt } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { SpaceValidator } from '../../validators/space.validator';
import { combineLatest } from 'rxjs';
import { CreatePsrTemplatesComponent } from '../../form-group/create-psr-templates/create-psr-templates.component';
import { AttachmentComponent } from '../../form-group/attachment/attachment.component';
import { CreatePsrProductAreaComponent } from '../../form-group/create-psr-product-area/create-psr-product-area.component';
import { MedalliaUtilService } from '../../../core/utils/medallia-util.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-create-psr-modal',
  templateUrl: './create-psr-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePsrModalComponent extends BaseModalPopup
  implements OnInit, AfterViewInit {

  @Input()
  equipmentId: string;
  psrViewTemplates: boolean;
  filteredModalities: SelectOption[];
  templatesLoading = true;
  createPsrForm: UntypedFormGroup;
  showValidationMessage = false;
  isFormSubmitted = false;
  preApprovalStatuses: SelectOption[];

  psrLabelInProgress = 'PSR_CREATION_IN_PROGRESS';
  psrTemplates: PsrTemplateByModality[];
  allowedPSRModalities: string[];
  @Output()
  close = new EventEmitter();
  private labelPsrSuccess: string;

  @ViewChild('psrTemplatesComponent')
  psrTemplatesComponent: CreatePsrTemplatesComponent;

  @ViewChild('attachmentComponent')
  attachmentComponent: AttachmentComponent;

  @ViewChild('productArea', {static: false})
  productArea: CreatePsrProductAreaComponent;

  constructor(
    private configService: CountryConfigRestService,
    private fb: UntypedFormBuilder,
    private psrRestService: PsrRestService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private medalliaUtilService: MedalliaUtilService,
    private psrUtilService: PsrUtilService,
    private lifenetUtilService: LifeNetUtilService,
    private equipmentUtilService: EquipmentUtilService,
    private cd: ChangeDetectorRef,
    private filterUtilService: FilterUtilService,
    renderer: Renderer2
  ) {
    super(renderer);
    this.bodyId = 'create-psr-modal-body';
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  show(event?: Event) {
    this.medalliaUtilService.setPopupsAllowed(false);
    super.show(event);
    if (this.productArea) {
      this.productArea.setPropertiesFromQueryParams();
    }
    this.cd.detectChanges();
  }

  init() {
    this.initFormControl();
    this.initFromQueryParams();

    this.psrUtilService
      .convertPsrTypesToSelectOptions(PsrTypeEnum.APPR)
      .subscribe(preApprovalResponse => {
        this.preApprovalStatuses = preApprovalResponse;
      });
    this.loadPsrTemplates();
  }

  ok() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.createForm(this.allowedPSRModalities);
    this.createPsrForm.markAsPristine();
    this.createPsrForm.markAsUntouched();
    this.showSpinner = false;
    this.hide();
    this.close.emit();
    this.medalliaUtilService.setPopupsAllowed(true);
  }

  cancelCreatePsr() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.psrTemplatesComponent?.setSelected(null);
    this.attachmentComponent?.clearAttachments();
    this.createForm(this.allowedPSRModalities);
    this.createPsrForm?.markAsPristine();
    this.createPsrForm?.markAsUntouched();
    this.showSpinner = false;
    this.hide();
    this.close.emit();
    this.medalliaUtilService.setPopupsAllowed(true);
  }

  private initFormControl() {
    const config$ = this.configService.getConfig();
    const translate$ = this.translateService.get('LABEL_PSR_SUCCESS');
    combineLatest([config$, translate$]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([config, labelPsrSuccess]) => {
        this.allowedPSRModalities = config.MODALITY_PSR_ALLOWED.split(',');
        this.createForm(config);
        this.labelPsrSuccess = labelPsrSuccess;
      });
  }

  private createForm(config) {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = parseInt(config.EMAIL_VALIDATION_LENGTH, 10);

    this.createPsrForm = this.fb.group({
      product: this.fb.group({
        modality: ['', [Validators.required]],
        equipmentKey: [this.equipmentId ? this.equipmentId : '', [Validators.required]],
        selectProduct: ['true'],
        multipleProducts: [''],
        deliveryMethod: ['', [Validators.required]],
        softwareVersion: ['']
      }),
      summary: this.fb.group({
        requestDescription: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        expectedOutcome: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        // additionalDescription aka additionalInformation
        additionalDescription: [''],
        priority: [''],
        siteImpact: [''],
        purchaseOrderNumber: ['']
      }),
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactPhone: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      acceptance: this.fb.group({
        preApprovalStatus: ['', [Validators.required]],
        preApprovalStatusOther: [''],
        projectManager: [''],
        siemensContact: [''],
        submitterTitle: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        confirmed: ['', [Validators.requiredTrue]]
      }, {
        validators: this.psrApprovalStatusOtherValidator()
      }),
      attachments: []
    });
  }

  psrApprovalStatusOtherValidator(): ValidatorFn {
    return (control: UntypedFormGroup): ValidationErrors | null => {
      if (!control) {
        return null;
      }
      const preApprovalStatus = control.value['preApprovalStatus'];
      const preApprovalStatusOther = control.value['preApprovalStatusOther'];

      if (!preApprovalStatus || !isEqual(preApprovalStatus, '20')) {
        return null;
      }
      if (!preApprovalStatusOther) {
        return {statusOtherRequired: 'The other status is required.'};
      }

      return (preApprovalStatusOther.trim().length > 0) ? null : {statusOtherRequired: 'The other status is required.'};
    };
  }

  initFromQueryParams() {
    if (this.equipmentId && this.createPsrForm) {
      this.createPsrForm.patchValue({
        product: {equipmentKey: this.equipmentId}
      });
    }
  }

  createPsr() {
    this.isFormSubmitted = true;

    // If the form is valid, then make a REST (post call) to create a PSR
    if (this.createPsrForm.valid) {
      // Hide the message
      this.showValidationMessage = false;
      this.showSpinner = true;

      let formValue = omit(this.createPsrForm.value, [
        'acceptance.confirmed'
      ]);
      const selectedApproval = formValue['acceptance']['preApprovalStatus'];
      if (isEqual(selectedApproval, psrPreApprovalStatusOther)) {
        formValue['acceptance']['preApprovalStatus'] =
          formValue['acceptance']['preApprovalStatusOther'];
      } else {
        // submit the title instead of the value
        formValue['acceptance'][
          'preApprovalStatus'
          ] = this.lifenetUtilService.pickPropertyFromObject(
          this.preApprovalStatuses,
          {value: selectedApproval},
          'title'
        );
      }

      formValue = omit(formValue, ['acceptance.preApprovalStatusOther']);

      this.psrRestService
        .createPsr(formValue).pipe(
        finalize(() => {
          this.ok();
        }))
        .subscribe(response => {
          this.toasterService.showNotTranslatedSuccessToaster(this.labelPsrSuccess + '<br/>' + response['psrNumber']);
          this.psrUtilService.emitPsrCreated();
        });
    } else {
      // show validation message set to true
      this.showValidationMessage = true;
    }
  }

  loadPsrTemplates() {
    this.equipmentUtilService
      .getEquipmentViewModelList()
      .subscribe(responses => {
        this.filterUtilService
          .getFilteredModalities(responses)
          .subscribe(modalities => {
            this.filteredModalities = filter(modalities, modality => {
              return includes(this.allowedPSRModalities, modality.value);
            });
            this.psrUtilService
              .filterPsrTemplates(this.filteredModalities)
              .subscribe(filteredPsrTemplates => {
                this.psrTemplates = filteredPsrTemplates;
                this.psrViewTemplates = !isEmpty(this.psrTemplates);
                this.templatesLoading = false;
                this.cd.detectChanges();
              });
          });
      });
  }
}
