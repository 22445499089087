<div class="pagination pagination__calendar">
  <a
    class="button button--control button--small pagination__item pagination__control"
    [attr.href]="currentItemIndex() > 0 ? '#': null"
    [title]="'PREVIOUS' | translate"
    (click)="selectPreviousItem()"
  >
    <i class="icon-arrow-left-large" aria-hidden="true"></i>
  </a>
  <span class="pagination__item">
    @if (currentItem() !== null) {
      <ng-container
        *ngTemplateOutlet="itemTemplate || defaultItemTemplate; context: { $implicit: currentItem() }"
      ></ng-container>
    }
  </span>
  <a
    class="button button--control button--small pagination__item pagination__control"
    [attr.href]="currentItemIndex() < items().length - 1 ? '#': null"
    [title]="'NEXT' | translate"
    (click)="selectNextItem()"
  >
    <i class="icon-arrow-right-large" aria-hidden="true"></i>
  </a>
</div>

<ng-template #defaultItemTemplate let-item>{{item}}</ng-template>
