@if (isVisible()) {
  <div class="dispatch-of-tomorrow__button__container">
    @if (isLoading()) {
      <div class="loading-animation dispatch-of-tomorrow__button__loading-animation"></div>
    }
    <button
      hlTooltip
      class="button button--primary button--small icon-calendar dispatch-of-tomorrow__button"
      data-cy="dot-reschedule-button"
      [class.invisible]="isLoading()"
      [translate]="isReschedule() ? 'BUTTON_RESCHEDULE_ACTIVITY' : 'BUTTON_SCHEDULE_ACTIVITY'"
      [disabled]="!bookableStatus()?.isBookable"
      [isActive]="!bookableStatus()?.isBookable"
      [title]="bookableStatus()?.message | translate"
      [documentOverlay]="true"
      (click)="scheduleModal()?.show()"
    ></button>
  </div>

  @if (!isLoading()) {
    <hl-dot-schedule-modal [currentAppointment]="currentAppointment()"></hl-dot-schedule-modal>
  }
}
