import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal } from '@angular/core';
import { AlertComponent } from '../../../../shared/components/alert/alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { DotTimeframe } from '../../models/dot-timeframe';
import { SequentialItemPickerComponent } from '../../../../shared/components/sequential-item-picker/sequential-item-picker.component';
import { DotDateService } from '../../services/dot-date.service';
import { BasicComponentsSharedModule } from '../../../../core/basic-components-shared.module';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateRange } from '../../../../core/models/date-range';
import { DotAppointmentIdentifier } from '../../models/dot-appointment-identifier';
import { DotTimeSlot } from '../../models/dot-appointment-slots';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, merge, of, switchMap } from 'rxjs';
import { DotService } from '../../services/dot.service';
import { tap } from 'rxjs/operators';
import { DotTimeSlotLabelComponent } from '../dot-time-slot-label/dot-time-slot-label.component';
import { DotLegacyRescheduleNavDirective } from '../dot-legacy-reschedule-nav-directive/dot-legacy-reschedule-nav.directive';

@Component({
  selector: 'hl-dot-schedule-appointment-selection',
  templateUrl: './dot-schedule-appointment-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AlertComponent,
    SequentialItemPickerComponent,
    DotTimeSlotLabelComponent,
    DotLegacyRescheduleNavDirective,
    TranslateModule,
    BasicComponentsSharedModule,
    ReactiveFormsModule
  ]
})
export class DotScheduleAppointmentSelectionComponent {

  private dotService = inject(DotService);
  private dotDateService = inject(DotDateService);

  identifier = input.required<DotAppointmentIdentifier>();
  megaTaskId = input<string | null>(null);
  timeframe = input.required<DotTimeframe>();
  control = input.required<FormControl<DotTimeSlot | null>>();
  config = input.required<Record<string, string>>();
  showErrors = input<boolean>(false);

  datePattern = computed(() => this.config().GENERIC_DATE_PATTERN);
  availableIntervals = computed(() =>
    this.dotDateService.splitTimeframeIntoIntervals(this.timeframe(), +this.config().DOT_TIME_SLOT_INTERVAL_LENGTH_IN_DAYS));
  selectedInterval = new FormControl<DateRange>(null);

  timeSlots = signal<DotTimeSlot[] | null>(null);
  isControlInvalid = signal(false);

  constructor() {
    this.refreshTimeSlotsWhenUserChangesSelectedInterval();
    this.updateTimeSlotSelectionValidityWhenStatusChanges();

    // to prevent select time slot from getting stale and ensuring consistency
    // we reset selected time slot when new time slots are being loaded
    effect(() => {
      this.timeSlots(); // this forces effect to trigger when time slots change
      this.control().reset();
    });
  }

  private refreshTimeSlotsWhenUserChangesSelectedInterval(): void {
    combineLatest([
      toObservable(this.identifier),
      toObservable(this.megaTaskId),
      this.selectedInterval.valueChanges
    ]).pipe(
      tap(() => this.timeSlots.set(null)),
      switchMap(([identifier, megaTaskId, range]) => {
        const timeSlotIdentifier = megaTaskId ? { megaTaskId } : identifier;
        return this.dotService.getTimeSlots(timeSlotIdentifier, range);
      }),
      takeUntilDestroyed()
    ).subscribe(timeSlots => {
      this.timeSlots.set(timeSlots);
    });
  }

  private updateTimeSlotSelectionValidityWhenStatusChanges(): void {
    toObservable(this.control).pipe(
      switchMap(control => merge(of(control.status), control.statusChanges)),
      takeUntilDestroyed()
    ).subscribe(status => {
      this.isControlInvalid.set(status === 'INVALID');
    });
  }

}
