<div class="bg-color-black-5 padding-tiny-2">
  @if (list) {
    <ul class="collection collection--report collection--compact">
      @for (item of list; track item) {
        <li class="collection-item collection-item--padding-sides">
          <div class="collection__content">
            <div class="row">
              <div class="col-12">
                <h5 class="margin-top-0">{{ item.name }}</h5>
              </div>
              <ng-container *ngTemplateOutlet="column; context: {$implicit: item.firstColumn}"></ng-container>
              <ng-container *ngTemplateOutlet="column; context: {$implicit: item.secondColumn}"></ng-container>
            </div>
          </div>
        </li>
      }
    </ul>
  }
</div>

<ng-template #column let-column>
  <div class="col-12 col-lg-6">
    <div class="grid-layout grid-layout-template-columns--2-col grid-layout__align--center">
      @for (columnItem of column; track columnItem) {
        <ng-container class="grid-layout__item">
          <p class="font-size-xsmall color-black-60" [translate]=columnItem.label [attr.data-cy]="columnItem.dataCyValue + '-name'"></p>
          @if (valueIntroTemplateRef) {
            <ng-container [ngTemplateOutlet]="valueIntroTemplateRef" [ngTemplateOutletContext]="{$implicit:columnItem}"></ng-container>
          }
        </ng-container>
      }
    </div>
  </div>
</ng-template>
