<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [innerHTML]="'RESCHEDULE_PLANNED_ACTIVITY_TITLE' | translate"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      @if (activitiesItem) {
        <div class="modal-dialog__body" id="modal1_desc">
          <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
          @if (!showSpinner) {
            <form #modalForm="ngForm" class="form" novalidate [formGroup]="form">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <hl-textarea [labelText]="'TEXT_RESCHEDULE_ADDITIONAL_INFORMATION'" [parentForm]="form" name="rescheduleEmailText"
                      [isRequired]="true" [noWhiteSpace]="true" [isLarge]="true"
                      [isInvalid]="isFormSubmitted && form.get('rescheduleEmailText').invalid"></hl-textarea>
                    @if (isFormSubmitted && form.get('rescheduleEmailText').invalid) {
                      <small class="textfield__hint textfield__hint--validation"
                        [translate]="'RESCHEDULE_PLANNED_ACTIVITY_EMAIL_ERROR_NO_TEXT'"></small>
                    }
                  </div>
                </div>
              </div>
            </form>
          }
        </div>
      }
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'btn btn-sm btn-highlight'"
          [isFormValid]="form.valid" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
          [label]="'RESCHEDULE_PLANNED_ACTIVITY_LABEL_SEND'" ngDefaultControl name="isFormSubmitted"></hl-simulator-check>
      }
    </div>
  </div>

  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
