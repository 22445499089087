import { DropdownOptions } from '../../../core/models/dropdown-options';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '../../../core/models/select-option';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { Equipment } from '../../../core/models/equipment/equipment';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { combineLatest, Subject } from 'rxjs';
import { filter, find, includes, isEqual } from 'lodash-es';
import { PsrUtilService } from '../../../core/utils/psr-util.service';
import { PsrTypeEnum } from '../../../core/core-constants.service';
import { CreatePsrTemplateEventService } from '../../../core/component-communication-services/create-psr-template/create-psr-template-event.service';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-create-psr-product-area',
  templateUrl: './create-psr-product-area.component.html'
})
export class CreatePsrProductAreaComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  productForm: UntypedFormGroup;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  equipmentId: string;

  modalities: SelectOption[];
  deliveryMethods: SelectOption[];
  equipments: Equipment[];
  filteredEquipments: Equipment[];
  selectedEquipment: Equipment;
  defaultSelectedEquipment: Equipment;
  defaultModality: string;
  productDropdownoptions: DropdownOptions[] = [];
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private configService: CountryConfigRestService,
    private equipmentUtilService: EquipmentUtilService,
    private lifenetUtilService: LifeNetUtilService,
    private psrUtilService: PsrUtilService,
    private filterUtilService: FilterUtilService,
    private createPsrTemplateEventService: CreatePsrTemplateEventService
  ) {
  }

  ngOnInit() {
    this.init();
    this.registerEventListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.equipmentId && !changes.equipmentId.firstChange && this.equipmentId) {
      this.setPropertiesFromQueryParams();
    }
  }

  /**
   * Requests for the list of equipments, configs and filters the modalities based upon the
   * allowed modalities in config and modalities contained in equipment list.
   *
   * Also sets the default equipment and modality based upon the equipment in query params.
   */
  init() {
    this.initProperties();
    const modelList$ = this.equipmentUtilService.getEquipmentViewModelList();
    const config$ = this.configService.getConfig();

    combineLatest([modelList$, config$]).pipe(takeUntil(this.unsubscribe$)).subscribe(responses => {
      this.equipments = responses[0];
      const allowedModalities = responses[1].MODALITY_PSR_ALLOWED.split(',');
      this.filterUtilService
        .getFilteredModalities(this.equipments)
        .subscribe(modalities => {
          this.modalities = filter(modalities, modality => {
            return includes(allowedModalities, modality.value);
          });
        });
      this.setPropertiesFromQueryParams();
    });

    this.psrUtilService
      .convertPsrTypesToSelectOptions(PsrTypeEnum.DLVY)
      .subscribe(deliveryMethodsResponse => {
        this.deliveryMethods = deliveryMethodsResponse;
      });
  }

  initProperties() {
    this.modalities = [];
    this.deliveryMethods = [];
    this.equipments = [];
    this.filteredEquipments = [];
  }

  handleSelectProductChange() {
    const selected = this.productForm.get('selectProduct').value;

    if (isEqual(selected, 'true')) {
      this.productForm.get('equipmentKey').enable();
      // to set something in the equipmentKey dropdown, so that it can never be empty and
      // we don't need a validator for that
      const selectedModality = this.productForm.get('modality').value;
      this.filterAndPreselectEquipmentByModality(selectedModality);
    } else {
      this.productForm.get('equipmentKey').disable();
      this.productForm.patchValue({
        equipmentKey: ''
      });
    }
  }

  isProductSelectable() {
    return this.productForm.get('equipmentKey').enabled;
  }

  handleProductAreaChange(modality) {
    this.productForm.patchValue({modality});
    this.filterAndPreselectEquipmentByModality(modality);
  }

  filterAndPreselectEquipmentByModality(modality) {
    this.filterEquipmentsByModality(modality);
    this.preselectEquipment(modality);
  }

  preselectEquipment(selectedModality: string) {
    if (!this.isProductSelectable()) {
      this.productForm.patchValue({
        equipmentKey: ''
      });
      return;
    }
    let equipment: Equipment;
    if (isEqual(selectedModality, this.defaultModality)) {
      equipment = this.defaultSelectedEquipment;
    } else if (this.filteredEquipments && this.filteredEquipments.length > 0) {
      equipment = this.filteredEquipments[0];
    }
    if (equipment) {
      this.productForm.patchValue({
        equipmentKey: equipment.key
      });
    }
  }

  filterEquipmentsByModality(selected: string) {
    this.filteredEquipments = filter(this.equipments, equipment => {
      return isEqual(equipment.modality, selected);
    });
    this.loadProductDropdownOptions();
  }

  loadProductDropdownOptions() {
    this.productDropdownoptions = [];
    this.filteredEquipments.forEach((item: Equipment) => {
      this.productDropdownoptions.push({
        value: item.key,
        title: `[${item.siemensId}] ${item.productName}`
      });
    });
  }

  setPropertiesFromQueryParams() {
    if (this.equipmentId) {
      this.preselectProductForEquipment();
    } else {
      this.productForm.patchValue({
        modality: ''
      });
    }
  }

  private preselectProductForEquipment() {
    this.selectedEquipment = find(this.equipments, equipment => equipment.key === this.equipmentId);
    if (this.selectedEquipment) {
      this.defaultSelectedEquipment = this.selectedEquipment;
      this.defaultModality = this.selectedEquipment.modality;
      this.productForm.patchValue({
        modality: this.selectedEquipment.modality
      });
    }

    this.filterEquipmentsByModality(this.defaultModality);
    this.preselectEquipment(this.defaultModality);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private registerEventListeners() {
    this.createPsrTemplateEventService.changedPsrTemplateSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      psrTemplate => {
        if (psrTemplate && psrTemplate.value) {
          this.productForm.patchValue({
            modality: psrTemplate.title
          });
        } else {
          this.productForm.patchValue({
            modality: ''
          });
        }
        this.handleSelectProductChange();
      }
    );
  }

  toggleSelectProduct(value: string) {
    this.productForm.get('selectProduct').patchValue(value);
    this.handleSelectProductChange();
  }
}
