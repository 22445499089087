import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service to enable listening in create ticket component, for changes of ticket
 * types in a child form group. Used to toggle attachments rendering.
 */
@Injectable({providedIn: 'root'})
export class CreateTicketEventService {

  // Observable createTicket changed ticket type sources
  private changedTicketTypeSource = new Subject<any>();

  // Observable createTicket streams
  changeTicketTypeSource$ = this.changedTicketTypeSource.asObservable();

  // Service createTicket -> (ticket create)
  emitTicketTypeChanged(ticketType: string) {
    this.changedTicketTypeSource.next(ticketType);
  }

}
