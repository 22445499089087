@if (ticketDetailsRender) {
  <hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>
  @if (isLoaded && viewModelTicket) {
    <section class="datalist overflow-hidden" data-cy="tickets-history">
      @if (showTicketHistory || showSolutionText) {
        <dl hlCollapsable [title]="'.collapsable__title'" class="accordion" role="presentation">
          @if (showTicketHistory) {
            <dt aria-level="3" class="accordion__header">
              <button type="button" class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1" aria-expanded="false"
                [translate]="'TICKET_HISTORY'"></button>
            </dt>
            <dd class="accordion__panel" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <p style="white-space: pre-line" [innerHTML]="longText.join('\n')"></p>
            </dd>
          }
          @if (showSolutionText) {
            <dt aria-level="3" class="accordion__header">
              <button type="button" class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1" aria-expanded="false"
                [translate]="'GENERIC_LABEL_SOLUTION_TEXT'"></button>
            </dt>
            <dd class="accordion__panel" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <p style="white-space: pre-line" [innerHTML]="solutionText.join('\n')"></p>
            </dd>
          }
        </dl>
      }
    </section>
  }
}
