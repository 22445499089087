import { Injectable } from '@angular/core';
import { DeliveryMilestones } from '../models/tickets/delivery-milestones';
import { Observable } from 'rxjs';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';

@Injectable({providedIn: 'root'})
export class LogisticRestService {

  private deliveryMilestone = restEndPoint + 'logistic/deliveryMilestones/:ticketNumber';

  constructor(private http: CacheableHttpClient) {
  }

  getDeliveryMilestones(ticketNumber: string): Observable<DeliveryMilestones[]> {
    return this.http.get<DeliveryMilestones[]>(this.updateRestName(ticketNumber));
  }

  clearCache(ticketNumber: string) {
    const restName = this.updateRestName(ticketNumber);
    this.http.clearCache(restName);
  }

  private updateRestName(ticketNumber: string): string {
    return this.deliveryMilestone.replace(/:ticketNumber/, ticketNumber);
  }
}
