<hl-progress-indicator [loading]="!chart"></hl-progress-indicator>
@if (chart) {
  <div class="row margin-bottom-1">
    <div class="col"><h4 translate="EQUIPMENT_USAGE_HEADLINE" class="margin-top-0"></h4></div>
    <div class="col-sm-5">
      <div class="button-group button-group--large">
        <button [disabled]="!chartData.current"
          class="button toggle-button" [class.is-active]="year === 'current'"
          (click)="setYear('current')" translate="EQUIPMENT_USAGE_CURRENT_YEAR"></button>
        <button [disabled]="!chartData.past"
          class="button toggle-button" [class.is-active]="year === 'past'"
          (click)="setYear('past')" translate="EQUIPMENT_USAGE_PAST_YEAR"></button>
      </div>
    </div>
  </div>
  <div class="diagram diagram--combo margin-right-1">
    <hl-diagram-combo class="diagram__canvas"
      [results]="chart.bar" [resultsName]="'EQUIPMENT_USAGE_MONTHLY_LABEL' | translate"
      [scheme]="{ selectable: true, group: 'linear', domain: ['#ec6602']}"
      [lineChart]="chart.line"
      [colorSchemeLine]="{ selectable: true, group: 'Ordinal', domain: chart.domain }"
      [legend]="false" [xAxis]="true" [yAxis]="true" [gradient]="false"
      [showXAxisLabel]="true" [xAxisLabel]="'EQUIPMENT_USAGE_X_LABEL' | translate"
      [showYAxisLabel]="true" [yAxisLabel]="'EQUIPMENT_USAGE_Y_LABEL' | translate"
      [yLeftAxisScaleFactor]="chart.scale" [yRightAxisScaleFactor]="chart.scale"
      [animations]="true">
    </hl-diagram-combo>
  </div>
  <div class="row">
    <div class="col">
      <ul class="legend legend--combo legend--combo-bottom">
        <li class="legend__item">
          <span class="legend__value" style="background-color: #009999;"> </span>
          <span class="legend__label"><small translate="EQUIPMENT_USAGE_ALLOWANCE_LABEL"></small></span>
        </li>
        <li class="legend__item">
          <span class="legend__value" style="background-color: #7a162d;"> </span>
          <span class="legend__label"><small translate="EQUIPMENT_USAGE_CUMULATIVE_LABEL"></small></span>
        </li>
        <li class="legend__item">
          <span class="legend__value" style="background-color: #ec6602;"> </span>
          <span class="legend__label"><small translate="EQUIPMENT_USAGE_MONTHLY_LABEL"></small></span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-1"></div>
    <div class="col padding-top-1">
      <small [innerHTML]="'EQUIPMENT_USAGE_FOOTNOTES' | translate: { startDate: chart.contractStartDay }"></small>
    </div>
    <div class="col-1"></div>
  </div>
}
