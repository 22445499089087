import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Favorite } from '../models/favorite';
import { EquipmentUtilService } from '../services/equipment/equipment-util.service';
import { UserUtilService } from '../services/user/user-util.service';
import { roles } from '../core-constants.service';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { clone, indexOf, isUndefined, parseInt } from 'lodash-es';
import { DateRange } from '../models/date-range';
import { StateService } from '../services/state.service';
import { LogService } from '../services/log/log.service';
import { ActivatedRoute, Params, PRIMARY_OUTLET, Router } from '@angular/router';
import { Directive, ElementRef, ViewChild } from '@angular/core';
import { SortSkeleton } from '../../shared/sorting/sort-skeleton';
import { BrowserStateService } from '../services/browser-state.service';
import { FilterUtilService } from '../utils/filter-util.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { InfiniteScrollerService } from '../services/infinite-scroller.service';

@Directive()
export abstract class BaseListView<T> {
  @ViewChild('panelList')
  panelList: ElementRef;

  @ViewChild(InfiniteScrollDirective)
  infiniteScroll: InfiniteScrollDirective;

  searchInput: string;
  sortSkeleton: SortSkeleton;

  // for nav links
  currentSelectedIndex: number;

  selectedItem: T;
  rawList: T[]; // original list received from BE or view model list
  listWithoutPagination: T[]; // filtered list without pagination
  viewModelList: T[]; // view model list with all filtering (including pagination)

  dateRange: DateRange = {
    fromDate: null,
    toDate: null
  };

  datePattern = '';
  timePattern = '';
  dateTimePattern = '';

  // set a default value, but later defined from country
  numberPagination: number;
  paginationItems: number;

  // my equipment profile variables
  myEquipmentProfileList = [];
  myEquipmentChecked = false;

  isLoaded: boolean;
  isConfigLoaded: boolean;
  isAdvancedFilterCollapsed: boolean;
  isItemClickable: boolean;

  // view port toggle between list and detail view
  listMode: boolean;
  initialListMode: boolean;

  filteredLengthWithoutPagination = 0;

  // subscription
  viewModelSubscription: Subscription;
  routerStateSubscription: Subscription;
  protected readonly unsubscribe$ = new Subject<void>();

  // for activating child routes (routerLinkActive is not always setting tab active)
  // Note:- use only if there are child routes/tabs
  currentStateName: string;

  constructor(
    public configService: CountryConfigRestService,
    public equipmentUtilService: EquipmentUtilService,
    public userUtilService: UserUtilService,
    public stateService: StateService,
    public logService: LogService,
    public router: Router,
    public browserStateService: BrowserStateService,
    public filterUtilService: FilterUtilService
  ) {
  }

  /**
   * you can set default values for your properties in this method
   */
  abstract afterInitProperties(): void;

  /**
   * you can initialize values read from the config in this method
   * @param config
   */
  abstract afterConfigProperties(config: any): void;

  /**
   * this method is called, after the list is loaded from the
   * viewModel-service or from the RestService - if you have any additional
   * initializations based up on the list entries, you should do it in this
   * method (it is guaranteed, that the rawList is filled, when this method
   * is called)
   *
   * ATTENTION: at the end of this method you need to call:
   * <code>
   *    this.onAdvancedFilterChange();
   * </code>
   */
  abstract afterInitViewModelList(): void;

  /**
   * here you should trigger the load of the raw list from the
   * RestService or from the viewModel-Service
   * @returns {Observable<T[]>}
   */
  abstract loadViewModelList(): Observable<T[]>;

  /**
   * this method is called, after the list is loaded from the
   * viewModel-service or from the RestService - here you should
   * setup the dropdown filters, whose values are based on the
   * values of the list items
   * (it is guaranteed, that the rawList is filled, when this method
   *  is called)
   */
  abstract initAdvancedFilterDropDownList(): void;

  /**
   * should generate the filter object, that is used to filter the
   * list (by advanced filter and search)
   * @returns {any}
   */
  abstract getFilterObject(): any;

  /**
   * Called when the selectedItem in the list changes, to reflect
   * the change in the detail view.
   * If you don't navigate on selection, then you should consider
   * overriding <code>shouldSelectActive()</code> and return false.
   */
  abstract navigate(): void;

  /**
   * Set properties (filter properties) that needs to be set from
   * the selected favorite.
   * @param favorite
   */
  abstract setDerivedBoundPropertiesFromFavorite(favorite: any): void;

  /**
   * set properties from router query params
   * Note:- Some query params setting depends on config file, hence we need to pass it
   */
  abstract setPropertiesFromQueryParams(config: any);

  /**
   * should return e.g. "/tickets"
   */
  abstract getEmptyListUrl(): string;

  /**
   * Optional method for override which is called if there is needed post-processing
   * after initViewModelList was finalized
   */
  afterInitViewModelListIsFinalized() {
    // intentionally left empty
  }

  /**
   * @description Initialize of call stack
   */
  init() {
    this.initProperties();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.setConfigProperties(configResponse);
      this.initLoadViewModelList();
      this.isConfigLoaded = true;
    });

    this.setStateName();
  }

  /**
   * @description destroys the subscription
   */
  destroy() {
    this.unsubscribeAll();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  unsubscribeAll() {
    if (this.viewModelSubscription) {
      this.viewModelSubscription.unsubscribe();
    }

    if (this.routerStateSubscription) {
      this.routerStateSubscription.unsubscribe();
    }
  }

  /**
   * @description Sets the current state name from router config for child state tabs to be active (used instead of routerLinkActive)
   */
  setStateName() {
    this.routerStateSubscription = this.stateService
      .getActiveStateName()
      .subscribe(stateName => {
          this.currentStateName = stateName || this.currentStateName;
        }
      );
  }

  /**
   * @description Initialize view properties
   */
  initProperties() {
    // please check description of this method
    this.currentStateName = this.stateService.getStateNameFromWindowLocation();

    this.datePattern = 'DDMMYYYY';
    this.timePattern = 'HH:mm';
    this.dateTimePattern = 'DDMMYYYY HH:mm';

    this.selectedItem = null;

    this.numberPagination = 20;
    this.paginationItems = 20;

    this.isLoaded = false;
    this.isConfigLoaded = false;
    this.isAdvancedFilterCollapsed = true;
    this.isItemClickable = true;

    this.rawList = [];
    this.viewModelList = [];
    this.listWithoutPagination = [];

    this.currentSelectedIndex = 0;

    /*
     * default value for initial view port is set to true (i.e show list mode)
     * but this is changed based on if we are coming via some router params
     */
    this.initialListMode = true;

    // additional properties for the derived class
    this.afterInitProperties();
  }

  /**
   * @description Set the config properties for view and component
   */
  setConfigProperties(config: any) {
    // set the pagination size
    this.numberPagination = parseInt(this.getPageSizeConfig(config), 10);
    this.paginationItems = this.numberPagination;

    // get the locale date pattern
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.timePattern = config.GENERIC_TIME_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;

    // additional config properties for derived class
    this.afterConfigProperties(config);

    // set filters based on query params
    this.setPropertiesFromQueryParams(config);
  }

  /**
   * may be overwritten, if you need another page size than defined as default
   * (which is EQUIPMENT_LIST_PAGE_SIZE)
   * @param config
   * @returns {string}
   */
  getPageSizeConfig(config: any): string {
    return config.EQUIPMENT_LIST_PAGE_SIZE;
  }

  /**
   * @description Get and initialize view model for equipment list by merging equipment and equipment status
   */
  initLoadViewModelList() {
    this.isLoaded = false;
    this.viewModelSubscription = this.loadViewModelList().pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => {
        // irrespective of success/complete or error
        this.isLoaded = true;
        this.afterInitViewModelListIsFinalized();
      }))
      .subscribe(viewModelResponse => {
        // clone deep not to change original
        this.rawList = clone(viewModelResponse);
        this.initAdvancedFilterDropDownList();

        // additional setting for derived class
        this.afterInitViewModelList();
      });
  }

  /**
   * Check if user has role to view equipment to call my siemens equipment profile
   * Note:- This method should only be used when my siemens profile filtering is
   * required
   */
  checkUserHasViewEquipmentRole() {
    return this.userUtilService.checkUserRoles({
      checkViewEquipmentRole: roles.viewEquipmentRole
    });
  }

  /**
   * @description Filtering of view model list with different filters
   */
  onAdvancedFilterChange(noPaginationReset = false, shouldScrollTop = true) {
    if (this.panelList && shouldScrollTop) {
      this.panelList.nativeElement.scrollTop = 0;
    }

    if (!noPaginationReset) {
      this.paginationItems = this.numberPagination;
    }

    this.onFilterChangeImplementation();
    InfiniteScrollerService.resetInfiniteScroller(this.infiniteScroll);
  }

  private onFilterChangeImplementation() {
    const filterObject = this.getFilterObject();

    // initial value for filtered result without pagination applied
    this.listWithoutPagination = this.filterUtilService.getListAfterApplyingFilterPipes(
      this.rawList,
      filterObject
    );

    this.filteredLengthWithoutPagination = this.listWithoutPagination.length;

    // view model list including all filter, pagination as well
    this.viewModelList = this.filterUtilService.applyIndividualFilter(
      this.listWithoutPagination,
      this.paginationItems,
      'limitTo'
    );

    if (this.shouldSelectActive()) {
      this.setDisplayAndSelectedActive();
    }
  }

  onLoadMore() {
    this.onFilterChangeImplementation();
  }

  loadMore(extraItems?: number) {
    this.paginationItems += extraItems ? extraItems : this.numberPagination;
    this.onLoadMore();
  }

  /**
   * if you don't override the <code>navigate()</code> method, then you should consider
   * overriding this and return false.
   * @returns {boolean}
   */
  shouldSelectActive(): boolean {
    return this.isItemClickable;
  }

  setDisplayAndSelectedActive() {
    if (this.viewModelList.length > 0) {
      // during initial load, nothing is selected (so this.selectedEquipment is null)
      const i = indexOf(this.viewModelList, this.selectedItem);
      if (i > -1) {
        this.currentSelectedIndex = i;
      }
    } else {
      // Case when filtered result is empty
      this.selectedItem = null;
    }
  }

  /**
   *
   * @description
   * handle clickUpdateSelection by selection change from outside
   * (e.g. click on element-nav for prev/next)
   */
  onClickUpdateSelectionByIndex(selectedIndex: number, listMode?: boolean) {
    this.onClickUpdateSelectedItem(this.viewModelList[selectedIndex], listMode);
  }

  /**
   *
   * @description
   * On clicking an equipment the corresponding detail information should also be
   * updated. Data is shared by usage of a shared service
   */
  onClickUpdateSelectedItem(selectedItem: T, listMode?: boolean) {
    // get the current selected equipment on click
    const changed = this.isSelectedItemChanged(this.selectedItem, selectedItem);
    this.selectedItem = selectedItem;

    // NOTE: updating currentSelectedIndex for element-nav
    const i = indexOf(this.viewModelList, this.selectedItem);

    this.currentSelectedIndex = i > -1 ? i : 0;
    if (changed && this.selectedItem) {
      // overridden for derived class
      this.navigate();
    }

    /**
     *
     * Note: updating selection will trigger the viewport in case
     * of view port is relevant for current display, it will switch between
     * displaying list mode or detail mode
     * A explicit flag can be set for showing pre-defined  list or detail by following scenario's
     * 1) - click from html: listMode is undefined, depends on showing details
     * if current selected index is valid.
     * 2) - triggered by select the first element by default: listMode is true
     * 3) - triggered by equipment given by state transition: listMode is false,
     * show the details
     */
    this.listMode = isUndefined(listMode)
      ? this.currentSelectedIndex === -1
      : listMode;
  }

  onSortChange(event: SortSkeleton) {
    this.sortSkeleton = event;
    this.onAdvancedFilterChange();
  }

  /**
   * @ngdoc method
   * @name applyFavorite
   *
   * @description
   * Apply the selected favorite to filter inputs.
   * Note:- Properties which are bound to derived class are set in derived classes.
   * This method is applicable only for equipments, tickets, activities and contracts
   */
  applyFavorite(favorite: Favorite) {
    const filterToBeApplied = JSON.parse(favorite.filter);
    if (filterToBeApplied) {
      if (filterToBeApplied.search) {
        this.searchInput = filterToBeApplied.search.searchValue;
      }

      if (
        filterToBeApplied.dateRange &&
        filterToBeApplied.dateRange.rangeOfDate
      ) {
        this.dateRange = filterToBeApplied.dateRange.rangeOfDate;
        if (filterToBeApplied.dateRange.rangeOfDate.fromDate) {
          this.dateRange.fromDate = new Date(
            filterToBeApplied.dateRange.rangeOfDate.fromDate
          );
        }
        if (filterToBeApplied.dateRange.rangeOfDate.toDate) {
          this.dateRange.toDate = new Date(
            filterToBeApplied.dateRange.rangeOfDate.toDate
          );
        }
      }

      if (filterToBeApplied.orderBy) {
        this.sortSkeleton.sortObject = filterToBeApplied.orderBy;
      }

      if (filterToBeApplied.myEquipment) {
        this.myEquipmentChecked =
          filterToBeApplied.myEquipment.isMyEquipmentChecked;
      }
      // We need to set the properties only applicable for derived list class
      this.setDerivedBoundPropertiesFromFavorite(filterToBeApplied);
    }

    this.onAdvancedFilterChange();
  }

  onBrowserBackSelect(item: T) {
    this.onClickUpdateSelectedItem(item, undefined);
  }

  getListWithoutPagination = () => {
    return this.listWithoutPagination;
  }

  getViewModelList = () => {
    return this.viewModelList;
  }

  protected getRouteParams(
    route: ActivatedRoute,
    outlet: string = PRIMARY_OUTLET
  ): Observable<Params> {
    return this.getAllRouteParams(route.params, route, outlet).pipe(filter(
      params => params && params.id
    ));
  }

  /**
   * This method fixex the issue described here:
   * https://github.com/angular/angular/issues/11023
   */
  private getAllRouteParams(
    params: Observable<Params>,
    route: ActivatedRoute,
    outlet: string
  ): Observable<Params> {
    const children: ActivatedRoute[] = route.children.filter(
      (c: ActivatedRoute) => {
        return c.outlet === outlet;
      }
    );

    if (children.length) {
      return this.getAllRouteParams(
        merge(params, children[0].params),
        children[0],
        outlet
      );
    } else {
      return params;
    }
  }

  private isSelectedItemChanged(selectedItem: T, selectedItem2: T): boolean {
    return !(!!selectedItem) && !!selectedItem2 ||
      !!selectedItem && !(!!selectedItem2) ||
      !this.isSame(selectedItem, selectedItem2);
  }

  isSame(item1: T, item2: T): boolean {
    return item1 === item2;
  }
}
