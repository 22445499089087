import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { SubprocessorFileInfo } from '../models/subprocessor-file-info';

@Injectable({providedIn: 'root'})
export class SubprocessorListRestService {

  private uploadSubprocessorListRestName = restEndPoint + 'subprocessor';
  private uploadSubprocessorListHQRestName = restEndPoint + 'subprocessor/hq';
  private getSubprocessorLinkRestName = restEndPoint + 'subprocessor/:type/link';
  private getFilesListRestName = restEndPoint + 'subprocessor/:type/metadata';

  constructor(private http: CacheableHttpClient) {
  }

  uploadSubprocessorList(body: {
    attachment: string
  }, type: 'hq' | 'country'): Observable<SubprocessorFileInfo> {
    const endpoint = type === 'hq' ? this.uploadSubprocessorListHQRestName : this.uploadSubprocessorListRestName;
    return this.http.post<SubprocessorFileInfo>(endpoint, body);
  }

  getSubprocessorLink(type: 'hq' | 'country'): Observable<string> {
    return this.http.get<string>(this.getSubprocessorLinkRestName.replace(':type', type), {
      responseType: 'text',
      cache: false
    });
  }

  getFileNameAndLastUpdated(type: 'hq' | 'country'): Observable<SubprocessorFileInfo> {
    return this.http.get(this.getFilesListRestName.replace(':type', type), {cache: false});
  }
}
