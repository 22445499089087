import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { ActivatedRoute } from '@angular/router';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TicketsCacheService } from '../../../core/services/cache/tickets-cache.service';
import { LogService } from '../../../core/services/log/log.service';
import { StringUtilService } from '../../../core/utils/string-util.service';
import { BaseTicketDetailView } from '../../../core/base-class/base-ticket-detail-view';
import { Ticket } from '../../../core/models/tickets/ticket';
import { ticketsIndividualRestName } from '../../../core/rest-services/ticket-rest-constants.service';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hl-tickets-event-logs',
  templateUrl: './tickets-event-logs.component.html'
})
export class TicketsEventLogsComponent extends BaseTicketDetailView implements OnInit, OnChanges, OnDestroy {

  // Note:- This is not mandatory and is required only for showing closed ticket in modal/popup
  @Input() closedTicketInput: Ticket;

  ticketsRestService: TicketsRestService;
  refreshItemEventSubscription: Subscription;
  ticketKey: string;

  constructor(configService: CountryConfigRestService,
    ticketsUtilService: TicketsUtilService,
    activatedRoute: ActivatedRoute,
    lifeNetUtilService: LifeNetUtilService,
    ticketsCacheService: TicketsCacheService,
    logService: LogService,
    stringUtilService: StringUtilService,
    ticketsRestService: TicketsRestService,
    private refreshItemEventService: RefreshItemEventService) {
    super(configService, ticketsUtilService, activatedRoute, lifeNetUtilService, ticketsCacheService,
      logService, stringUtilService);
    this.ticketsRestService = ticketsRestService;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.refreshItemEventSubscription = this.refreshItemEventService.refreshItemSource$.subscribe(() => {
      this.refreshItem();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
    super.init();
  }

  ngOnDestroy() {
    if (this.refreshItemEventSubscription) {
      this.refreshItemEventSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['closedTicketInput'] && !changes['closedTicketInput'].firstChange) {
      super.init();
    }
  }

  getClosedTicketInput(): Ticket {
    return this.closedTicketInput;
  }

  setViewModelItems(config: any, id: string) {
    this.ticketKey = id;
    super.setViewModelItems(config, id);
  }

  /**
   *
   * @description
   * Refresh activity ticket logs after clearing cache
   */
  refreshItem() {
    if (this.refreshItemEventSubscription) {
      this.refreshItemEventSubscription.unsubscribe();
    }
    this.isLoaded = false;
    const url = ticketsIndividualRestName.replace(/:id/, this.ticketKey);
    this.ticketsRestService.clearCache(url);
    this.init();
  }
}
