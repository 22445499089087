<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="templateRef" (onKeyHideEmit)="closeOverlayAfterSave ? saveAndHide(null) : hide(null)"></ng-container>
<ng-template #templateRef>
  <div #ddcOverlay class="ddc-overlay detail-overlay" (click)="close($event)" [class.is-open]="isShown" tabindex="-1" role="dialog" aria-modal="true">
    <div class="ddc-overlay__container detail-overlay__container" [class.wide-view]="wideView">
      <button data-cy="overlay-close-icon" type="button" class="ddc-overlay__close" aria-label=""
        (click)="closeOverlayAfterSave ? saveAndHide($event) : hide($event)">
        <i aria-hidden="true" class="icon-close"></i>
      </button>
      <div class="ddc-overlay__body detail-overlay__body" data-cy="overlay-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>

