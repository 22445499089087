<div>
  <hl-search-field searchFieldClasses="textfield--large margin-bottom-0"
    [(searchInput)]="searchInput" (searchInputChange)="onAdvancedFilterChange()"></hl-search-field>

  <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollContainer]="'.update-scroll-container'"
    [fromRoot]="true" [scrollWindow]="false" [infiniteScrollThrottle]="10" (scrolled)="loadMore()" #panelList>
    <hl-list [additionalCollectionClass]="'overview-list--equipment'" [items]="viewModelList"
      dataCyInput="equipments" [filteredLengthWithoutPagination]="filteredLengthWithoutPagination"
      (onSortOptionChange)="onSortChange($event)" [isLoaded]="isLoaded" [label]="filteredLengthWithoutPagination | plural : 'EQUIPMENT_ADDED_TO_UPDATE_SINGULAR' : 'EQUIPMENT_ADDED_TO_UPDATE' | async" [sortConfig]="sortSkeleton"
      empty="NO_EQUIPMENT_ADDED_TO_UPDATE">
      <ng-template let-item let-index="index">
        <hl-new-system-update-equipment-list-item [item]="item" [showPlusButton]="false" (onEquipmentClicked)="removeEquipmentFromList(item)">
        </hl-new-system-update-equipment-list-item>
      </ng-template>
    </hl-list>
  </div>
</div>
