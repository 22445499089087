import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Attachment } from '../../../core/models/attachment';
import { isEqual, remove } from 'lodash-es';
import { StringUtilService } from '../../../core/utils/string-util.service';

@Component({
  selector: 'hl-upload',
  templateUrl: './upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadComponent {
  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  parentFormFileControlKey: string;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  isRequired: boolean;
  @Input()
  isInvalid: boolean;
  @Input()
  disableAttachmentCount: number;
  @Input()
  checkAttachmentDisable: boolean;
  @Input()
  genericLabelName: string;
  @Input()
  disableCancel: boolean;
  @Input()
  disableUploadButton: boolean;
  @ViewChild('uploadBtn')
  uploadBtn: ElementRef;
  @Output()
  resetProgressBar: EventEmitter<any> = new EventEmitter();

  fileObjects: Attachment[] = [];
  isDisabled = false;

  constructor(
    private stringUtilService: StringUtilService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0];

    if (file) {
      const fileObject: { [k: string]: any } = {};
      fileObject.filetype = file.type;
      fileObject.filename = file.name;
      fileObject.filesize = file.size;
      fileObject.file = file;
      fileObject.base64 = window.btoa(file);
      fileObject.languageCode = file.languageCode;

      this.fileObjects.push(fileObject as Attachment);
      this.parentForm.get(this.parentFormFileControlKey).patchValue(this.fileObjects);
      this.cdRef.markForCheck();

      this.checkAttachmentToBeDisabled();

      this.clearInputField();

      this.isInvalid = false;
    }
  }

  /**
   * Check if the input field needs to be disabled
   */
  checkAttachmentToBeDisabled() {
    if (
      this.checkAttachmentDisable &&
      isEqual(this.fileObjects.length, this.disableAttachmentCount)
    ) {
      this.isDisabled = true;
    }
  }

  removeFile(uploadFile: Attachment) {
    this.isDisabled = false;
    remove(this.fileObjects, uploadFile);
    this.checkAttachmentToBeDisabled();

    this.parentForm.get(this.parentFormFileControlKey).patchValue(this.fileObjects);
    this.isInvalid = true;
    this.resetProgressBar.emit();
  }

  removeFiles() {
    [...this.fileObjects].forEach(file => {
      this.removeFile(file);
    });
    this.cdRef.detectChanges();
  }

  private clearInputField() {
    this.uploadBtn.nativeElement.value = '';
  }
}
