import { Component, OnInit, Renderer2 } from '@angular/core';
import { OptionsGetQuoteModalComponent } from '../options-get-quote-modal/options-get-quote-modal.component';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { EquipmentConstantsService } from '../../../core/services/equipment/equipment-constants.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-options-get-trial-modal',
  templateUrl: './options-get-trial-modal.component.html'
})
export class OptionsGetTrialModalComponent extends OptionsGetQuoteModalComponent implements OnInit {

  termsAndConditionsLink = '';
  datePattern = '';
  minDate: Date;

  constructor(
    fb: UntypedFormBuilder,
    userRestService: UserRestService,
    equipmentRestService: EquipmentRestService,
    equipmentConstantsService: EquipmentConstantsService,
    toasterService: ToasterService,
    renderer: Renderer2,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    protected datePipeWrapperPipe: DatePipeWrapperPipe) {
    super(
      fb,
      userRestService,
      equipmentRestService,
      equipmentConstantsService,
      toasterService,
      renderer,
      equipmentUtilService,
      configService
    );
  }

  ngOnInit() {
    super.init();
    const date = new Date();
    date.setDate((new Date()).getDate() + 5);
    this.minDate = date;

    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
    });
  }

  setConfigProperties(config) {
    this.termsAndConditionsLink = config.TERMS_AND_CONDITIONS_EXTERNAL_LINK;
    this.datePattern = config.GENERIC_DATE_PATTERN;
  }

  postFormData() {
    this.resolveObservable(this.equipmentRestService.postGetTrial(this.getPreparedPostBody()));
  }

  createForm(childContactForm: UntypedFormGroup): UntypedFormGroup {
    const form = super.createForm(childContactForm);
    form.addControl('testStartDate', this.fb.control('', Validators.required));
    form.addControl('remoteInstallation', this.fb.control(false));
    form.addControl('contactMe', this.fb.control(false));
    form.addControl('termsAndCondition', this.fb.control(false, Validators.requiredTrue));
    return form;
  }

  getPreparedPostBody(): object {
    const formData = { ...this.getOptionsForm.value };

    formData.testStartDate = this.datePipeWrapperPipe.transform(formData.testStartDate, this.datePattern);
    formData.equipmentKeys = [this.equipmentItem.key];
    delete formData['termsAndCondition'];

    return formData;
  }

  resolveObservable(observable: Observable<any>) {
    observable.pipe(finalize(() => {
      this.isFormSubmitted = false;
      this.hide();
      this.ngOnInit();
    })).subscribe(() => {
      this.toasterService.showTranslatedSuccessToaster('REQUEST_TRIAL_EMAIL_SUCCESS');
    });
  }
}
