import { Injectable } from '@angular/core';
import { InvoicesRestService } from '../rest-services/invoices-rest.service';
import { Observable, ReplaySubject } from 'rxjs';
import { InvoiceViewModel } from '../view-models/invoice-view-model';
import { LifeNetUtilService } from '../utils/life-net-util.service';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { ColorStatusBinding } from '../models/color-status-map';
import { InvoiceGroup, invoiceStatusColor, InvoiceTypes, NotifStatus } from '../core-constants.service';
import { TranslateService } from '@ngx-translate/core';
import { TicketsRestService } from '../rest-services/tickets-rest.service';
import { UserRestService } from '../rest-services/user-rest.service';
import { map } from 'rxjs/operators';
import { cloneDeep, filter, forEach, isEmpty } from 'lodash-es';
import { SelectOption } from '../models/select-option';
import { EquipmentViewModel } from '../view-models/equipment-view-model';

@Injectable({providedIn: 'root'})
export class InvoicesUtilService {

  changedInvoice$ = new ReplaySubject<InvoiceViewModel>(1);

  constructor(private lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private ticketRestService: TicketsRestService,
    private invoicesRestService: InvoicesRestService,
    private translateService: TranslateService,
    private userRestService: UserRestService) {
  }

  /**
   * @description returns single invoice
   *
   * @param {string} key - invoice id
   * @param selectedInvoiceGroup
   * @param {InvoiceTypes} selectedInvoice - selected invoice
   * @param {boolean} cache - flag if to use cache
   * @returns {Observable<InvoiceViewModel>}
   */
  getSingleInvoice(key: string, selectedInvoiceGroup: InvoiceGroup, selectedInvoice: InvoiceTypes, cache: boolean = true): Observable<InvoiceViewModel> {
    return this.getInvoicesViewModelList(selectedInvoiceGroup, selectedInvoice, cache)
      .pipe(map(invoicesViewModelResponse =>
        invoicesViewModelResponse.find(invoice => {
          return invoice.invoiceKey === key;
        })));
  }

  /**
   * @description retrieve list of invoices of selected type (e.g. Contract or PSR or Service).
   * If Contract invoice it merges contractTypeDescription from view contract list.
   *
   * @returns {Observable<InvoiceViewModel[]>}
   */
  getInvoicesViewModelList(selectedInvoiceGroup: InvoiceGroup, selectedInvoice: InvoiceTypes, cache = true): Observable<InvoiceViewModel[]> {
    let invoiceViewModelList = this.getInvoiceListWithMergedTicketAndEquipAttributes(selectedInvoiceGroup, selectedInvoice, cache);
    invoiceViewModelList = this.mergeCustomerAttributesToInvoiceVMList(invoiceViewModelList);
    return invoiceViewModelList;
  }

  getEquipmentInvoices(equipmentKey: string): Observable<InvoiceViewModel[]> {
    let invoiceViewModel = this.getEquipmentInvoiceVMListWithMergedTicketAttributes(equipmentKey);
    invoiceViewModel = this.mergeEquipmentAttributesToInvoiceVMList(invoiceViewModel);
    invoiceViewModel = this.mergeCustomerAttributesToInvoiceVMList(invoiceViewModel);
    return invoiceViewModel;
  }

  /**
   * creates invoice view model list by merging properties from ticketlist and equipment list.
   * @param selectedInvoiceGroup
   * @param {InvoiceTypes} selectedInvoice
   * @param cache
   * @returns {Observable<InvoiceViewModel[]>}
   */
  getInvoiceListWithMergedTicketAndEquipAttributes(selectedInvoiceGroup: InvoiceGroup, selectedInvoice: InvoiceTypes, cache = true): Observable<InvoiceViewModel[]> {
    let invoiceViewModel = this.getInvoiceVMListWithMergedTicketAttributes(selectedInvoiceGroup, selectedInvoice, cache);
    invoiceViewModel = this.mergeEquipmentAttributesToInvoiceVMList(invoiceViewModel);
    return invoiceViewModel;
  }

  /**
   * Merge Equipment attributes to provided invoice view model list.
   * @param {Observable<InvoiceViewModel>} invoiceViewModel
   */
  mergeEquipmentAttributesToInvoiceVMList(invoiceViewModel: Observable<InvoiceViewModel[]>): Observable<InvoiceViewModel[]> {
    const findObjectEquipment = {
      findKey: 'equipmentKey',
      findValue: 'equipmentKey',
      propertiesToMerge: [
        'myEquipmentName',
        'siemensId',
        'productName',
        'key' // which is actually equipment key
      ]
    };

    return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
      invoiceViewModel, findObjectEquipment);
  }

  /**
   * Merge Customer attributes to provided invoice view model list.
   * @param {Observable<InvoiceViewModel>} viewModelList
   */
  mergeCustomerAttributesToInvoiceVMList(viewModelList: Observable<InvoiceViewModel[]>): Observable<InvoiceViewModel[]> {
    const findObjectEquipment = {
      findKey: 'customerId',
      findValue: 'customerId',
      propertiesToMerge: [
        'customerName'
      ]
    };

    return this.lifeNetUtilService.createViewModels(this.userRestService.getAssignedCustomers(),
      viewModelList, findObjectEquipment);
  }

  /**
   * get invoice list (based upon invoice type) and equipment list from rest service and merge
   * equipment attributes to invoice vm list.
   * @param selectedInvoiceGroup
   * @param {InvoiceTypes} selectedInvoice
   * @param {cache} cache
   * @returns {Observable<InvoiceViewModel[]>}
   */
  getInvoiceVMListWithMergedTicketAttributes(selectedInvoiceGroup: InvoiceGroup, selectedInvoice: InvoiceTypes, cache: boolean = true): Observable<InvoiceViewModel[]> {
    return this.lifeNetUtilService.createViewModels(this.ticketRestService.getTickets(NotifStatus.CLOSED),
      this.invoicesRestService.getInvoices(selectedInvoiceGroup, selectedInvoice, cache), this.getFindObjectTicket());
  }

  getEquipmentInvoiceVMListWithMergedTicketAttributes(equipmentKey: string): Observable<InvoiceViewModel[]> {
    return this.lifeNetUtilService.createViewModels(this.ticketRestService.getTickets(NotifStatus.CLOSED),
      this.invoicesRestService.getEquipmentInvoices(equipmentKey), this.getFindObjectTicket());
  }
  getFindObjectTicket(): any {
    return {
      findKey: 'ticketNumber',
      findValue: 'ticketNumber',
      propertiesToMerge: [
        'description'
      ]
    };
  }

  /**
   * @description returns the invoices status color mapping
   *
   * @param {InvoiceViewModel} item
   * @returns {ColorStatusBinding}
   */
  getInvoicesStatusColorBinding(item: InvoiceViewModel): ColorStatusBinding {

    const statusMap: { [k: string]: any } = {};

    // if item is defined
    if (item && item.invoiceStatus) {
      // tslint:disable-next-line:ban
      statusMap.label = this.translateService.instant('LABEL_INVOICE_STATUS_' + item.invoiceStatus);
      if (item.invoiceStatus === '3') {
        // tslint:disable-next-line:ban
        statusMap.label = this.translateService.instant('GENERIC_LABEL_STATUS_OVERDUE');
      }
      statusMap.colorClass = invoiceStatusColor[item.invoiceStatus];
    }
    return statusMap as ColorStatusBinding;
  }

  getExportList = (listViewModel: InvoiceViewModel[], modalitiesList: SelectOption[], equipmentList: EquipmentViewModel[]) => {
    if (listViewModel && modalitiesList) {
      const exportListViewModel = cloneDeep(listViewModel);
      forEach(exportListViewModel, item => {
        const currentEquipment = filter(equipmentList, {
          key: item.equipmentKey
        });
        if (!isEmpty(currentEquipment)) {
          const propertiesToMerge = ['department', 'customerDescription'];
          item = this.lifeNetUtilService.mergeObjects(
            currentEquipment[0],
            item,
            propertiesToMerge
          );

          const modalityDescription = filter(modalitiesList, {
            value: currentEquipment[0].modality
          });
          if (!isEmpty(modalityDescription)) {
            item['modality'] = modalityDescription[0].title;
          }
        }
      });
      return exportListViewModel;
    }
    return [];
  }
}
