import { AsuUploadRestService } from '../../../core/rest-services/asu-upload-rest.service';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs/operators';

export class UploadUpdatePackageHowtoValidator {

  /**
   * Validates how_to file against the xsd schema on the server side
   * @returns null if validation pass, otherwise returns {validationError: <error message>>}
   */
  static howToFileValidator(uploadService: AsuUploadRestService, translate: TranslateService) {
    return (control: AbstractControl) => {
      const file = control.value;

      if (file && Object.keys(file).length > 0) {
        const howTo = Object(file)[0]['file'];
        const dataFile = new FormData();
        dataFile.append('file', howTo);

        return uploadService.validateHowToFile(dataFile).pipe(
          switchMap(result => {
            if (!result.valid) {
              if (result.line !== -1 && result.column !== -1) {
                return translate.get(result.message).pipe(
                  map(value => {
                    const res = value.replace(/#LINE#/g, result.line)
                      .replace(/#COLUMN#/g, result.column);
                    return {validationError: res};
                  }));
              } else {
                return translate.get(result.message).pipe(
                  map(value => {
                    return {validationError: value};
                  }));
              }
            } else {
              return [null];
            }
          })
        );
      }
      return null;
    };
  }
}
