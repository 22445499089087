import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hl-read-more',
  templateUrl: './read-more.component.html'
})
export class ReadMoreComponent implements OnChanges, OnInit, OnDestroy {
  text: string;
  @Input()
  textTranslation: string;
  @Input()
  maxLength = 0;
  @Input()
  maxWords = 0;
  currentText: string;
  hideToggle = true;
  @Input()
  beforeText = '';
  @ViewChild('toggle')
  toggleButton: ElementRef;

  public isCollapsed = true;
  private translationSubscription?: Subscription;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.subscribeToCurrentTextTranslation();
  }

  toggleView(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.blur();
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    const words = this.text.split(' ');
    if (this.text.length <= this.maxLength || words.length <= this.maxWords) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed) {
      if (this.maxWords) {
        this.currentText = '';
        for (let i = 0; i < this.maxWords; i++) {
          this.currentText += words[i] + ' ';
        }
        this.currentText += '...';
      } else {
        this.currentText = this.text.substring(0, this.maxLength) + '...';
      }
    } else {
      this.currentText = this.text;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.text) {
      this.determineView();
    }
    if (changes.textTranslation) {
      this.subscribeToCurrentTextTranslation();
    }
  }

  private subscribeToCurrentTextTranslation() {
    this.translationSubscription?.unsubscribe();
    this.translationSubscription = this.translateService.get(this.textTranslation).subscribe((translated: string) => {
      this.text = translated;
      this.isCollapsed = true;
      this.determineView();
    });
  }

  blur() {
    if (this.toggleButton) {
      this.toggleButton.nativeElement.blur();
    }
  }

  ngOnDestroy(): void {
    this.translationSubscription?.unsubscribe();
  }
}
