import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { PsrUtilService } from '../../../core/utils/psr-util.service';
import { SelectOption } from '../../../core/models/select-option';
import { PsrTypeEnum } from '../../../core/core-constants.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'hl-create-psr-acceptance-authorization',
  templateUrl: './create-psr-acceptance-authorization.component.html'
})
export class CreatePsrAcceptanceAuthorizationComponent implements OnInit {
  @Input()
  productForm: UntypedFormGroup;
  @Input()
  isFormSubmitted: boolean;

  preApprovalStatuses$: Observable<SelectOption[]>;
  psrTermsLink$: Observable<string>;

  constructor(
    private configService: CountryConfigRestService,
    private psrUtilService: PsrUtilService
  ) {
  }

  ngOnInit() {
    this.preApprovalStatuses$ = this.psrUtilService.convertPsrTypesToSelectOptions(PsrTypeEnum.APPR);
    this.psrTermsLink$ = this.configService.getConfig().pipe(
      map(config => config.PSR_TERMS_LINK)
    );
  }
}
