import { Directive, Inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[hlDomPortal]',
  exportAs: 'domPortal'
})
export class DomPortalDirective implements OnInit {
  @Input()
  hostSelector: string;
  @Input()
  templateToRender: TemplateRef<any>;

  private portalOutlet: DomPortalOutlet;
  private templatePortal: TemplatePortal;

  constructor(
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.portalOutlet = new DomPortalOutlet(this.document.querySelector(this.hostSelector));
    this.templatePortal = new TemplatePortal(this.templateToRender, this.viewContainerRef);
    this.portalOutlet.attach(this.templatePortal);
  }
}
