<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">

      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'GET_RECOMMENDATION'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>

      <div class="modal-dialog__body" id="modal1_desc">

        @if (showValidationMessage && !showSpinner) {
          <div class="alert">
            <div class="alert__body">
              {{ translationErrorMessage | translate }}
            </div>
          </div>
        }


        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

        <form class="form" #modalForm="ngForm" [formGroup]="getOptionsForm">
          @if (!showSpinner) {
            <div>
              <p>
                <span [translate]="'GET_RECOMMENDATION_TIME_SPECIFICATION'"></span>&shy; <span [translate]="'GENERIC_LABEL_RESPONSE_TIME'"></span>
              </p>
              <fieldset>
                <div class="col-12">
                  <strong>
                    <span [translate]="'LABEL_RECOMMENDATION_APPLY'"></span>
                  </strong>
                </div>
                <div class="collection">
                  <div class="collection-item list-item">
                    <div class="collection__content" hlScrollTop>
                      <div class="media-object">
                        <div class="media-object__image">
                          <hl-equipment-image [equipmentKey]="equipmentItem.key" class="widget-item-visual hidden-xs"
                            [large]="false"></hl-equipment-image>
                        </div>
                        <div class="media-object__body">
                          <div class="display-flex align-items-center">
                            <p class="list-item__title margin-bottom-0">{{ equipmentItem.productName }}</p>
                            <hl-is-my-equipment [key]="equipmentItem.key" class="list-item__star"></hl-is-my-equipment>
                          </div>
                          <ul class="bare-list info-list">
                            <li
                              class="info-list__item label-status-{{statusColorMap[equipmentItem.key | equipmentStatus | async]}}">{{ equipmentItem.siemensId }}
                            </li>
                            @if (equipmentItem.myEquipmentName) {
                              <li class="info-list__item">
                                <i class="icon-equipment-name" hlTooltip [documentOverlay]="true"
                                  [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i> {{ equipmentItem.myEquipmentName }}
                              </li>
                            }
                            @if (equipmentItem.myEquipmentName) {
                              <li class="info-list__item">
                                <i class="icon-department" hlTooltip [documentOverlay]="true"
                                  [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i> {{ equipmentItem.department }}
                              </li>
                            }
                            <li class="info-list__item" *hlItemShowHideHandler="'role';role:'viewPlannedActivityRole'">
                              <i class="icon-calendar" hlTooltip [documentOverlay]="true" [title]="'LABEL_ALL_PLANNED_ACTIVITIES' | translate"></i>
                              <hl-equipment-actv-count [equipment]="equipmentItem"></hl-equipment-actv-count>
                            </li>
                            @if (equipmentItem.myEquipmentName) {
                              <li class="info-list__item">
                                <i class="icon-location-pin" hlTooltip [documentOverlay]="true"
                                  [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ equipmentItem.city }}
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
              </fieldset>
              <fieldset>
                <span [translate]="'GET_RECOMMENDATION_ADDITIONAL_QUESTIONS'"></span>
                <hl-multi-selectbox id="clinicalFieldsSB" ngDefaultControl [options]="clinicalSpecialities"
                  [formControl]="this.getOptionsForm.controls['selectedClinicalFields']" name="clinicalFields"
                  [isRequired]="isFormSubmitted && getOptionsForm.get('selectedClinicalFields').value.length === 0"
                  (valueChange)="validateSelectedClinicalFields()" [label]="'GET_RECOMMENDATION_WHICH_CLINICAL_FIELDS'"
                  [allChecked]="true"></hl-multi-selectbox>
                @if (isFormSubmitted && getOptionsForm.get('selectedClinicalFields').value.length === 0) {
                  <small class="selectbox__hint multi-selectbox__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
                }
              </fieldset>
              <fieldset>
                <span [translate]="'GET_RECOMMENDATION_MORE_QUESTION'"></span>
                <hl-textarea ngDefaultControl class="form-control" #additionalInformation [name]="'additionalInformation'"
                  [labelText]="'ADDITIONAL_INFORMATION'" [isLarge]="true" [maxlength]="2000" [isDisabled]="equipmentItem === null"
                  [parentForm]="getOptionsForm"></hl-textarea>
              </fieldset>
              <!-- contact -->
              <fieldset>
                <hl-contact-area #contactArea ngDefaultControl [useSalutationTitleValue]="true" [showContactTitles]="false"
                  [showAdditionalContacts]="false" [labelContact]="''" [alwaysFullWidth]="true" [isDisabled]="equipmentItem === null"
                  [contactForm]="getOptionsForm.controls['contact']" [isFormSubmitted]="isFormSubmitted"></hl-contact-area>
              </fieldset>
            </div>
          }
        </form>

      </div>
      @if (!showSpinner) {
        <hl-simulator-check ngDefaultControl [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [isFormValid]="modalForm.valid"
          [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true" label="SEND_LABEL"
          name="isFormSubmitted"></hl-simulator-check>
      }

    </div>
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
