<div [formGroup]="orderForm">
  <div class="row">
    <div class="col-12 col-lg-8">
      <p class="color-black-60 font-size-xsmall margin-bottom-0" [translate]="'LABEL_SAO_ADDITIONAL_OPTION'"></p>
      <p [translate]="'LABEL_SAO_MANAGED_SEC_SERVICE'"></p>
      
      <ul class="list list--inline bare-list">
        <li class="list__item">
          <input class="radio radio--inline" id="ao_yes" type="radio" formControlName="additionalOption" value="true" />
          <label for="ao_yes" [translate]="'GENERIC_LABEL_YES'"></label>
        </li>
        <li class="list__item">
          <input class="radio radio--inline" id="ao_no" type="radio" formControlName="additionalOption" value="false" />
          <label for="ao_no" [translate]="'GENERIC_LABEL_NO'"></label>
        </li>
      </ul>
    </div>
  </div>
</div>
