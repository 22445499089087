import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-sao-contact-information',
  templateUrl: './create-sao-contact-information.component.html'
})
export class CreateSaoContactInformationComponent {

  @Input() contactITForm: UntypedFormGroup;
  @Input() isFormSubmitted: boolean;
}
