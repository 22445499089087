<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
    <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
      <div class="modal-dialog__container" role="document">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title" [translate]="title" [attr.data-cy]="dataCyHeader"></h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div id="modal2_desc" class="modal-dialog__body">
          <ng-content></ng-content>
        </div>

        <div class="modal-dialog__footer">
          <button type="button" class="button" data-dismiss="modal-dialog" (click)="hide()" [translate]="closeLabel"></button>
        </div>
      </div>
    </div>
</ng-template>
