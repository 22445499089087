import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { clone, isEqual } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { UpdateEquipmentEventService } from '../../../core/component-communication-services/update-equipment-event/update-equipment-event.service';
import { Equipment } from '../../../core/models/equipment/equipment';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-edit-equipment-info-modal',
  templateUrl: './edit-equipment-info-modal.component.html'
})
export class EditEquipmentInfoModalComponent extends BaseFormModalPopup implements OnInit {

  @Input() equipmentItem: Equipment;
  @Input() addressFields: any;

  equipmentEditItem: Equipment;
  isFormSubmitted: boolean;
  updateCustomerDescription: boolean;
  addressFieldsDisabled: boolean;

  constructor(renderer: Renderer2,
    private equipmentRestService: EquipmentRestService,
    private equipmentUtilService: EquipmentUtilService,
    private updateEquipmentEventService: UpdateEquipmentEventService,
    private configService: CountryConfigRestService) {
    super(renderer);
  }

  ngOnInit() {
    this.equipmentEditItem = clone(this.equipmentItem);
    this.isFormSubmitted = false;
    this.updateCustomerDescription = false;
    this.addressFieldsDisabled = false;
    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((configResponse) => {
      if (isEqual(configResponse.EQUIPMENT_CUSTOMER_DESCRIPTION_EDIT, 'true')) {
        this.updateCustomerDescription = true;
      }
      this.addressFieldsDisabled = !isEqual(configResponse.EQUIPMENT_ADDRESS_EDIT, 'true');
    });
  }

  postFormData() {
    this.showSpinner = true;
    this.isFormSubmitted = true;
    this.equipmentRestService.postEditEquipment(this.equipmentEditItem.key, this.equipmentEditItem)
      .pipe(
        finalize(() => {
          this.hide();
        })
      )
      .subscribe(() => {
        this.equipmentUtilService.updateEquipmentData(this.equipmentEditItem);
        // update list view and advanced drop down options in filter
        this.updateEquipmentEventService.emitEquipmentUpdated();
      });
  }
}
