import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '../../../../core/models/select-option';

@Component({
  selector: 'hl-system-update-information-form',
  templateUrl: './system-update-information-form.component.html'
})
export class SystemUpdateInformationFormComponent {
  @Input()
  form: UntypedFormGroup;

  @Input()
  isFormSubmitted: boolean;

  @Input()
  isEditUpdate: boolean;

  @Input()
  asuStatusOptions: SelectOption[];

  updateCyberSecuritySelection(value: boolean) {
    this.form.get('cyberSecurity').patchValue(value);
  }
}
