import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'translateProperty'
})
export class TranslatePropertyPipe implements PipeTransform {
  transform(value: string, ...args: any[]): unknown {
    let result = value;
    String(args[0]).split(",").forEach(pair => {
      const [key, translation] = pair.split(':');
      if(value === key.trim()) {
        result = translation.trim();
      }
    });
    return result;
  }
}

