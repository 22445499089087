import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { omit } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { DateUtilService } from '../../../core/utils/date-util.service';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { SpaceValidator } from '../../validators/space.validator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-schedule-activity-modal',
  templateUrl: './schedule-activity-modal.component.html'
})
export class ScheduleActivityModalComponent extends BaseModalPopup
  implements OnInit, OnChanges {

  @Input()
  activitiesItem: ActivitiesViewModel;

  datePattern = '';
  dateTimePattern = '';

  showValidationMessage: boolean;
  isFormSubmitted: boolean;
  scheduleActivityDateText: string;
  labelScheduleActivitySuccess: string;
  showMeridian: boolean;

  translationErrorMessage =
    'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';

  modalForm: UntypedFormGroup;

  constructor(
    private configService: CountryConfigRestService,
    private fb: UntypedFormBuilder,
    private toasterService: ToasterService,
    private activitiesRestService: ActivitiesRestService,
    private translateService: TranslateService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private dateUtilService: DateUtilService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['activitiesItem'] && !changes['activitiesItem'].firstChange) {
      this.init();
    }
  }

  init() {
    if (this.activitiesItem) {
      this.initProperties();
      this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(configResponse => {
        this.createForm(configResponse);
      });
    }
  }

  initProperties() {
    this.showValidationMessage = false;
    this.isFormSubmitted = false;
    this.showMeridian = true;
    this.scheduleActivityDateText = '';
    this.modalForm = null;
  }

  setConfigProperties(config) {
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;

    const dueDate = this.datePipeWrapperPipe.transform(
      this.activitiesItem.dueDate,
      this.datePattern
    );
    const actualDate = this.datePipeWrapperPipe.transform(
      new Date(),
      this.datePattern
    );

    const dueDateCalc = new Date(this.activitiesItem.dueDate);
    // 7 is hard coded
    const lastDateCalc = dueDateCalc.setDate(dueDateCalc.getDate() - 7);
    const lastDate = this.datePipeWrapperPipe.transform(
      new Date(lastDateCalc),
      this.datePattern
    );

    this.translateService
      .get(['TEXT_SCHEDULE_ACTIVITY_VALID_DATES', 'LABEL_SCHEDULE_ACTIVITY_SUCCESS'])
      .subscribe((translate: { [key: string]: string }) => {
        this.scheduleActivityDateText = translate.TEXT_SCHEDULE_ACTIVITY_VALID_DATES
          .replace(/(#DUE_DATE#)/g, dueDate)
          .replace(/(#ACTUAL_DATE#)/g, actualDate)
          .replace(/(#LAST_DATE#)/g, lastDate);
        this.labelScheduleActivitySuccess = translate.LABEL_SCHEDULE_ACTIVITY_SUCCESS;
      });

    this.showMeridian = config['SHOW_AM_PM_INDICATOR'];
  }

  ok() {
    this.isFormSubmitted = true;

    if (this.modalForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;
      // modify the form value like BE requires
      const formValue = this.adaptModalFormValue();

      this.activitiesRestService
        .pmSchedule(this.activitiesItem.ticketKey, formValue)
        .pipe(
          finalize(() => {
            this.hide();
          })
        )
        .subscribe(() =>
          this.toasterService.showNotTranslatedSuccessToaster(this.labelScheduleActivitySuccess + '<br/>' + this.activitiesItem.ticketNumber));
    } else {
      this.showValidationMessage = true;
    }
  }

  hide() {
    this.init();
    super.hide();
  }

  private createForm(config) {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = parseInt(config.EMAIL_VALIDATION_LENGTH);

    const time = new Date();
    // default time is set to 9
    const defaultTime = time.setHours(9, 0, 0, 0);

    this.modalForm = this.fb.group({
      preferredCommunication: ['phone'],
      customerId: [this.activitiesItem.customerId],
      firstDate: [null, [Validators.required]],
      firstTime: [new Date(defaultTime), [Validators.required]], // would be removed when send to BE
      secondDate: [null, [Validators.required]],
      secondTime: [new Date(defaultTime), [Validators.required]], // would be removed when send to BE
      poNumber: [''],
      overTimeAuthorization: [false],
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactPhone: [''],
        contactSalutation: [''],
        contactTitle: ['']
      })
    });
    this.setConfigProperties(config);
  }

  /**
   * @description Modifies the form value according to BE input
   * @returns {{}}
   */
  private adaptModalFormValue() {
    const firstDateValue = this.dateUtilService.convertDateTimeToStringWithTimezone(
      this.modalForm.get('firstDate').value,
      this.modalForm.get('firstTime').value
    );

    const secondDateValue = this.dateUtilService.convertDateTimeToStringWithTimezone(
      this.modalForm.get('secondDate').value,
      this.modalForm.get('secondTime').value
    );

    this.modalForm.patchValue({
      firstDate: firstDateValue,
      secondDate: secondDateValue
    });

    return omit(this.modalForm.value, ['firstTime', 'secondTime']);
  }

  togglePreferredCommunication(value: string) {
    this.modalForm.get('preferredCommunication').patchValue(value);
  }
}
