<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="psr-modal">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'LABEL_PSR_CREATE_PSR'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="cancelCreatePsr()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="create-psr-modal-body">
        <hl-progress-indicator [loading]="templatesLoading"></hl-progress-indicator>
        <hl-progress-indicator [loading]="showSpinner" [showLabel]="true" [label]="psrLabelInProgress | translate"></hl-progress-indicator>

        @if (!templatesLoading && !showSpinner) {
          <form [formGroup]="createPsrForm">
            <h5 class="margin-top-0" [translate]="'LABEL_PSR_TEMPLATES'"></h5>
            <hl-create-psr-templates [psrTemplates]="psrTemplates" #psrTemplatesComponent></hl-create-psr-templates>
            <h5 class="margin-top-0" [translate]="'LABEL_PSR_PRODUCT_INFORMATION'"></h5>
            <hl-create-psr-product-area #productArea [productForm]="createPsrForm.controls['product']" [equipmentId]="equipmentId"
              [isFormSubmitted]="isFormSubmitted"></hl-create-psr-product-area>
            <h5 class="margin-top-0" [translate]="'LABEL_PSR_SERVICE_SUMMARY'"></h5>
            <hl-create-psr-service-summary [productForm]="createPsrForm.controls['summary']"
              [isFormSubmitted]="isFormSubmitted"></hl-create-psr-service-summary>
            <hl-attachment [parentForm]="createPsrForm" [isFormSubmitted]="isFormSubmitted" #attachmentComponent></hl-attachment>
            <hl-contact-area [contactForm]="createPsrForm.controls['contact']" [isFormSubmitted]="isFormSubmitted"></hl-contact-area>
            <h5 class="margin-top-0" [translate]="'LABEL_PSR_AUTHORIZATION'"></h5>
            <hl-create-psr-acceptance-authorization [productForm]="createPsrForm.controls['acceptance']"
              [isFormSubmitted]="isFormSubmitted"></hl-create-psr-acceptance-authorization>
          </form>
        }
      </div>

      @if (!showSpinner && !templatesLoading) {
        <div class="modal-dialog__footer">
          <button class="button" (click)="cancelCreatePsr()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
          <button class="button button--primary" (click)="createPsr()" [translate]="'BUTTON_PSR_CREATE'" data-cy="psr-create-button"></button>
        </div>
      }

    </div>
  </div>
</ng-template>
