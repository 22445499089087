import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Activities } from '../../../core/models/activities/activities';
import { GetActivityStatusColorPipe } from '../../pipes/get-activity-status-color/get-activity-status-color.pipe';
import { ActivitiesStatusDescriptionTranslationService } from '../../../core/services/activities/activities-status-description-translation-service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-activities-status-map',
  templateUrl: './activities-status-map.component.html',
  imports: [
    TranslateModule
  ],
  standalone: true
})
export class ActivitiesStatusMapComponent implements OnInit, OnChanges {

  label: string;
  color: string;
  @Input() item: Activities | ActivitiesViewModel;
  pipe: GetActivityStatusColorPipe;

  constructor(private descriptionTranslationService: ActivitiesStatusDescriptionTranslationService) {
    this.pipe = new GetActivityStatusColorPipe(descriptionTranslationService);
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] && !changes['item'].firstChange) {
      this.init();
    }
  }

  init() {
    if (this.item) {
      // seems bug in spelling getPlannedAct_i_vStatusMap
      const statusMap = this.pipe.transform(this.item);
      this.label = statusMap.label;
      this.color = statusMap.colorClass;
    }
  }
}
