<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="missing-equipment-modal">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [innerHTML]="'REPORT_MISSING_EQUIPMENT' | translate"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc">

        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

        @if (!showSpinner) {
          <form [formGroup]="uploadForm" #modalForm="ngForm" class="form" novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <hl-textarea [name]="'emailText'" [labelText]="'MISSING_EQUIPMENT_TEXT_EMAIL_LABEL'" [(textareaContent)]="emailText"
                    [isRequired]="true" [noWhiteSpace]="true" [isLarge]="true" [isInvalid]="isFormSubmitted && !emailText"></hl-textarea>
                  @if (isFormSubmitted && !emailText) {
                    <small class="textfield__hint textfield__hint--validation" [translate]="'MISSING_EQUIPMENT_EMAIL_ERROR_NO_TEXT'"></small>
                  }
                </div>
              </div>
            </div>
            <fieldset>
              <div class="row margin-bottom-2" hlHelperTextForms [selector]="'file-input__button'">
                <div class="col-12 col-lg-8">
                  <hl-attachment [parentForm]="uploadForm" [isFormSubmitted]="isFormSubmitted"></hl-attachment>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form__helper-container">
                    <p class="form__helper-text" [innerHTML]="'TEXT_VALIDATION_CHECK_ATTACHMENT' | translate"></p>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        }

      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'btn btn-sm btn-highlight'"
          [isFormValid]="isNotEmpty(emailText)" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
          [label]="'SEND_LABEL'" name="isFormSubmitted"></hl-simulator-check>
      }
    </div>
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
