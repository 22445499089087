<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="reschedule-appointment-modal">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [innerHTML]="'REQUEST_RESCHEDULING_P58_TITLE' | translate"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      @if (activitiesItem) {
        <div class="modal-dialog__body" id="modal1_desc">
          <p>{{ 'REQUEST_RESCHEDULING_MODAL_SPECIFICATION_TEXT' | translate }}</p>
          <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
          @if (!showSpinner) {
            <form #modalForm="ngForm" class="form" novalidate [formGroup]="form">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="collection__content">
                      <ul class="bare-list info-list">
                        @for (appointment of this.form.get('rescheduleAppointments').value; track appointment; let index = $index) {
                          <li class="margin-top-0 margin-bottom-0">
                            <div class="row">
                              <div class="col-sm-1"><input id="appointment-{{appointment.appointmentNumber}}" type="checkbox"
                                class="checkbox margin-top-1" [checked]="appointment.checked" (change)="onCheck(appointment.appointmentNumber)">
                                <label for="appointment-{{appointment.appointmentNumber}}">
                                </label>
                              </div>
                              <div class="col-lg-9">
                                <div class="info-list__item">
                                  <p class="font-weight-bold margin-bottom-0">{{ appointment.title }}</p>
                                </div>
                                <div>
                                  <p class="margin-bottom-0 margin-top-tiny-1 label-{{(appointment | getActivityStatusColor).colorClass}}"
                                  [class.label-status-no-cursor]="true">{{ appointment.appointmentNumber }}</p>
                                  @if (getAppointmentPlannedStart(appointment)) {
                                    <div class="info-list__item">
                                      <i class="icon-time" aria-hidden="true" hlTooltip [title]="'PLANNED_ACTIVITIES_START_DATETIME' | translate"></i>
                                      <span> {{ getAppointmentPlannedStart(appointment) | datePipeWrapper : dateTimePatternSignal() }}</span>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                            <hr class="margin-0">
                          </li>
                        }
                      </ul>
                    </div>
                    @if (isFormSubmitted && form.get('rescheduleAppointments').invalid) {
                      <small class="textfield__hint textfield__hint--validation margin-top-tiny-1"
                      [translate]="'RESCHEDULE_PLANNED_ACTIVITY_EMAIL_ERROR_NO_APPOINTMENT'"></small>
                    }
                    <div class="form-group margin-top-1">
                      <hl-textarea [labelText]="'TEXT_RESCHEDULE_ADDITIONAL_INFORMATION'" [parentForm]="form" name="longText" [isRequired]="true"
                      [isLarge]="true" [isInvalid]="isFormSubmitted && form.get('longText').invalid"></hl-textarea>
                      @if (isFormSubmitted && form.get('longText').invalid) {
                        <small class="textfield__hint textfield__hint--validation"
                        [translate]="'REQUEST_RESCHEDULING_P58_EMPTY_TEXT_AREA_WARNING'"></small>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <hl-contact-area #contactArea [hidden]="true" [contactForm]="form.controls['contact']"
              [isFormSubmitted]="isFormSubmitted"></hl-contact-area>
            </form>
          }
        </div>
      }
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'btn btn-sm btn-highlight'"
          [isFormValid]="form.valid" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
          [label]="'RESCHEDULE_PLANNED_ACTIVITY_LABEL_SEND'" ngDefaultControl name="isFormSubmitted"
        [dataCySaveButton]="'reschedule-appointment'"></hl-simulator-check>
      }
    </div>
  </div>

  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
