import { Component, ContentChild, TemplateRef } from '@angular/core';
import { MarketingConsentService } from '../../../core/services/marketing-consent.service';
import { of, switchMap } from 'rxjs';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'hl-marketing-consent-required',
  templateUrl: './marketing-consent-required.component.html'
})
export class MarketingConsentRequiredComponent {

  marketingConsentGiven$ = this.configService.getConfig()
    .pipe(
      switchMap(config =>
        isEqual(config.FEATURE_TOGGLE_MARKETING_CONSENT, 'true') ? this.marketingConsentService.getMarketingConsent() : of(true)),
    );

  @ContentChild('noConsentTemplate') noConsentTemplate: TemplateRef<any>;

  constructor(private marketingConsentService: MarketingConsentService,
    private configService: CountryConfigRestService) {
  }
}
