<hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>

@if (isLoaded) {
  <div>
    @if (toggleMarketingConsent) {
      <div class="margin-bottom-tiny-5 border-none">
        <p class="lead margin-bottom-0 margin-top-0">{{ 'LABEL_MY_PRIVACY_POLICY_HEADLINE'| translate }}</p>
      </div><p class="margin-bottom-0">{{ 'LABEL_MY_PRIVACY_POLICY_DESCRIPTION' | translate }}</p>
      <div>
        <section class="dataview overflow-hidden">
          <dl>
            <dt class="accordion margin-bottom-0">
              <input id="privacy-policy-checkbox" type="checkbox" name="privacy-policy-checkbox" class="checkbox checkbox-aligned"
                [(ngModel)]="userSettings.marketingConsent" (change)="setChanged()" [disabled]="isMarketingConsentDiasbled">
              <label for="privacy-policy-checkbox" class="border-bottom-none">
                <button type="button" class="accordion__title collapsable__title display-inline" aria-controls="panel1" aria-expanded="false"
                  [innerHtml]="'LABEL_MY_PRIVACY_POLICY_CHECKBOX' | translate"></button>
              </label>
            </dt>
          </dl>
        </section>
      </div>
    }
    <div class="margin-bottom-tiny-5 border-none">
      <p class="lead margin-bottom-0 margin-top-0">{{ 'LABEL_MY_NOTIFICATION_SETTINGS_HEADLINE'| translate }}</p>
    </div>
    <p class="margin-bottom-0">{{ 'LABEL_MY_NOTIFICATION_SETTINGS_DESCRIPTION' | translate }}</p>
    <div>
      <section class="dataview overflow-hidden">
        <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
          <dt aria-level="3" class="accordion__header margin-bottom-0">
            <input id="notification-page-checkbox-1-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-1-inst-{{instanceId}}"
              class="checkbox checkbox-aligned" [(ngModel)]="userSettings.subprocessorNotificationSubscribed" (change)="setChanged()">
            <label for="notification-page-checkbox-1-inst-{{instanceId}}"
              [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
              <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                aria-expanded="false">
                {{ 'LABEL_MY_NOTIFICATION_SETTINGS_SUBPROCESSOR_CHECKBOX' | translate }}
              </button>
            </label>
          </dt>
          <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
            <span>
              {{ 'LABEL_MY_NOTIFICATION_SETTINGS_SUBPROCESSOR_DESCRIPTION' | translate }}
            </span>
          </dd>
        </dl>
      </section>
    </div>
    @if (showPreliminaryInvoice) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-00-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-00-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [(ngModel)]="userSettings.preliminaryInvoiceEmailSubscribed"
                (change)="preliminaryInvoiceEmailChanged($event)">
              <label for="notification-page-checkbox-00-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_PRELIMINARY_INVOICE_MAIL' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2 padding-bottom-tiny-3" aria-labelledby="panel1-button" role="region"
              aria-hidden="true">
              <input type="radio" class="radio" [value]="emailFrequency.DAILY" [id]="'preliminary-invoice-daily'"
                [disabled]="!userSettings.preliminaryInvoiceEmailSubscribed" [checked]="true"
                [(ngModel)]="this.userSettings.preliminaryInvoiceEmailFrequency" (change)="changePreliminaryInvoiceEmailFrequency()"><label
              [for]="'preliminary-invoice-daily'" [translate]="'LABEL_PRELIMINARY_INVOICE_MAIL_DAILY'"></label>
              <input type="radio" class="radio" [value]="emailFrequency.WEEKLY" id="preliminary-invoice-weekly"
                [disabled]="!userSettings.preliminaryInvoiceEmailSubscribed" [checked]="true"
                [(ngModel)]="this.userSettings.preliminaryInvoiceEmailFrequency" (change)="changePreliminaryInvoiceEmailFrequency()"><label
              for="preliminary-invoice-weekly" [translate]="'LABEL_PRELIMINARY_INVOICE_MAIL_WEEKLY'"></label>
            </dd>
          </dl>
        </section>
      </div>
    }
    @if (showAsuWeeklyEmail$ | async) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-2-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-2-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [checked]="isAsuWeeklyEmailChecked()" (change)="asuWeeklyEmailChanged()">
              <label for="notification-page-checkbox-2-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_MY_NOTIFICATION_SETTINGS_ASU_REPORT_MAIL' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <span>
                {{ 'LABEL_MY_NOTIFICATION_SETTINGS_ASU_REPORT_MAIL_DESCRIPTION' | translate }}
              </span>
            </dd>
          </dl>
        </section>
      </div>
    }
    @if (shouldShowChecksheetSubscription$ | async) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-8-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-8-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [(ngModel)]="userSettings.checkSheetEmailSubscribed" (change)="setChanged()">
              <label for="notification-page-checkbox-8-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_SET_CHECKSHEET_NOTIFICATIONS_CHECKBOX' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <span>
                {{ 'LABEL_SET_CHECKSHEET_NOTIFICATIONS_DESCRIPTION' | translate }}
              </span>
            </dd>
          </dl>
        </section>
      </div>
    }
    @if (showSubscribeSecurityAdvisories) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-3-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-3-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [(ngModel)]="userSettings.securityAdvisoriesSubscribed" (change)="setChanged()">
              <label for="notification-page-checkbox-3-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_MY_NOTIFICATION_SETTINGS_SECURITY_ADVISORIES_CHECKBOX' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <span>
                {{ 'LABEL_MY_NOTIFICATION_SETTINGS_SECURITY_ADVISORIES_DESCRIPTION' | translate }}
              </span>
            </dd>
          </dl>
        </section>
      </div>
    }
    @if (showSprReport) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-7-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-7-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [checked]="isSprReportChecked()" (change)="sprReportChanged()" #sprReport>
              <label for="notification-page-checkbox-7-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_SPR_SUBSCRIPTION_MONTHLY_REPORT_CHECKBOX' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <span>
                {{ 'LABEL_SPR_SUBSCRIPTION_MONTHLY_REPORT_DESCRIPTION' | translate }}
              </span>
              <div class="margin-top-tiny-1">
                <input id="spr-my-eq" name="spr-my-eq" type="checkbox" class="checkbox" [checked]="isSprReportFavOnlyChecked()"
                  (change)="sprReportChanged()" [disabled]="!sprReport.checked" #sprReportFavOnly>
                <label for="spr-my-eq">
                  {{ 'LABEL_SPR_SUBSCRIPTION_USE_MY_FAV_EQUIPMENT' | translate }}
                </label>
              </div>
            </dd>
          </dl>
        </section>
      </div>
    }
    @if (showSecurityAdvisoriesRole) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-4-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-4-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [(ngModel)]="userSettings.securityAdvisoriesViewRole" (change)="setChanged()"
                [disabled]="isSecurityAdvisoriesViewRoleEditingDisabled">
              <label for="notification-page-checkbox-4-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_SET_VIEW_SECURITY_ADVISORIES_ROLE_CHECKBOX' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <span>
                {{ 'LABEL_SET_VIEW_SECURITY_ADVISORIES_ROLE_DESCRIPTION' | translate }}
              </span>
            </dd>
          </dl>
        </section>
      </div>
    }
    @if (showSecurityAssessmentEmails) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-5-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-5-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [(ngModel)]="userSettings.securityAssessmentEmailsSubscribed" (change)="setChanged()">
              <label for="notification-page-checkbox-5-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_MY_NOTIFICATION_SETTINGS_SECURITY_ASSESSMENT_EMAILS_CHECKBOX' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <span>
                {{ 'LABEL_MY_NOTIFICATION_SETTINGS_SECURITY_ASSESSMENT_EMAILS_DESCRIPTION' | translate }}
              </span>
            </dd>
          </dl>
        </section>
      </div>
    }
    @if (showPlannedActivities) {
      <div>
        <section class="dataview overflow-hidden">
          <dl hlCollapsable [title]="'.collapsable__title'" class="accordion margin-bottom-0" role="presentation">
            <dt aria-level="3" class="accordion__header">
              <input id="notification-page-checkbox-6-inst-{{instanceId}}" type="checkbox" name="notification-page-checkbox-6-inst-{{instanceId}}"
                class="checkbox checkbox-aligned" [(ngModel)]="isPlannedActivitiesSubscribed" (change)="changePlannedActivities($event)">
              <label for="notification-page-checkbox-6-inst-{{instanceId}}"
                [class.border-bottom-none]="buttonDesc.getAttribute('aria-expanded') === 'true'">
                <button type="button" #buttonDesc class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                  aria-expanded="false">
                  {{ 'LABEL_SET_PLANNED_ACTIVITIES_NOTIFICATIONS_CHECKBOX' | translate }}
                </button>
              </label>
            </dt>
            <dd class="accordion__panel  collapsable__title padding-left-2" aria-labelledby="panel1-button" role="region" aria-hidden="true">
              <span>
                {{ 'LABEL_SET_PLANNED_ACTIVITIES_NOTIFICATIONS_DESCRIPTION' | translate }}
              </span>
              <div class="row margin-top-1">
                <div class="col-12 col-lg-8">
                  <p class="form__label" [translate]="'LABEL_DURATION'"></p>
                  <div class="button-group">
                    <button class="button toggle-button" [class.is-active]="userSettings.plannedActivitiesEmailFrequency === emailFrequency.WEEKLY"
                      (click)="userSettings.plannedActivitiesEmailFrequency = emailFrequency.WEEKLY;setChanged()"
                      [disabled]="isPlannedActivitiesSubscribed === false" [translate]="'SYSTEM_UPDATES_EMAIL_PERIOD_WEEKLY'"></button>
                    <button class="button toggle-button" [class.is-active]="userSettings.plannedActivitiesEmailFrequency === emailFrequency.MONTHLY"
                      (click)="userSettings.plannedActivitiesEmailFrequency = emailFrequency.MONTHLY;setChanged()"
                      [disabled]="isPlannedActivitiesSubscribed === false" [translate]="'SYSTEM_UPDATES_EMAIL_PERIOD_MONTHLY'"></button>
                  </div>
                  @if (isPlannedActivitiesSubscribed && userSettings.plannedActivitiesEmailFrequency === emailFrequency.NEVER) {
                    <div>
                      <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
                    </div>
                  }
                </div>
              </div>
            </dd>
          </dl>
        </section>
      </div>
    }
  </div>
}
