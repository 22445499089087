import { ChangeDetectionStrategy, Component, computed, effect, inject, input, Renderer2, signal, untracked } from '@angular/core';
import { BaseModalPopup } from '../../../../core/base-class/base-modal-popup';
import { PortalsModule } from '../../../../shared-modules/portals.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModalStructureComponent } from '../../../../shared/modal-popup/common-modal-structure/common-modal-structure.component';
import { CheckoutStepsComponent } from '../../../../shared/components/checkout-steps/checkout-steps.component';
import { CheckoutStep } from '../../../../shared/components/checkout-steps/checkout-step';
import { DotScheduleTimeframeSpecificationComponent } from '../dot-schedule-timeframe-specification/dot-schedule-timeframe-specification.component';
import { DotActivityData, DotBookableStatus } from '../../models/dot-bookable-status';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { startBeforeEndDate } from '../../../../shared/validators/start-before-end-date.validator';
import { DotScheduleAppointmentSelectionComponent } from '../dot-schedule-appointment-selection/dot-schedule-appointment-selection.component';
import { DotTimeframe } from '../../models/dot-timeframe';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { CountryConfigRestService } from '../../../../core/rest-services/country-config-rest.service';
import { DotService } from '../../services/dot.service';
import { DotTimeSlot } from '../../models/dot-appointment-slots';
import { DotScheduleContactDetailsComponent } from '../dot-schedule-contact-details/dot-schedule-contact-details.component';
import { DotScheduleAppointmentBookingComponent } from '../dot-schedule-appointment-booking/dot-schedule-appointment-booking.component';
import { UserRestService } from '../../../../core/rest-services/user-rest.service';
import { User } from '../../../../core/models/user';
import { DotContactDetails } from 'app/appointments/dot/models/dot-contact';
import { DotScheduleResultComponent } from 'app/appointments/dot/components/dot-schedule-result/dot-schedule-result.component';
import { DotBookAppointmentResultStatus } from 'app/appointments/dot/models/dot-book-appointment';
import { ProgressIndicatorComponent } from 'app/shared/components/progress-indicator/progress-indicator.component';
import { DotBookableStateService } from 'app/appointments/dot/services/dot-bookable-state.service';
import { switchMap } from 'rxjs';
import { DotStepHeaderPipe } from 'app/appointments/dot/pipes/dot-step-header.pipe';
import { CountdownTimerComponent } from 'app/shared/components/countdown-timer/countdown-timer.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'hl-dot-schedule-modal',
  templateUrl: './dot-schedule-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModalStructureComponent,
    CheckoutStepsComponent,
    DotScheduleTimeframeSpecificationComponent,
    DotScheduleAppointmentSelectionComponent,
    DotScheduleContactDetailsComponent,
    DotScheduleAppointmentBookingComponent,
    DotScheduleResultComponent,
    ProgressIndicatorComponent,
    CountdownTimerComponent,
    DotStepHeaderPipe,
    PortalsModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class DotScheduleModalComponent extends BaseModalPopup {

  readonly STEPS: CheckoutStep[] = [
    {
      displayNumber: 1,
      title: 'DOT_SCHEDULE_TIMEFRAME_SPECIFICATION_LABEL',
      validationName: 'timeframe-specification'
    },
    {
      displayNumber: 2,
      title: 'DOT_SCHEDULE_APPOINTMENT_SELECTION_LABEL',
      validationName: 'appointment-selection'
    },
    {
      displayNumber: 3,
      title: 'DOT_SCHEDULE_CONTRACT_DETAILS_LABEL',
      validationName: 'contact-details'
    },
    {
      displayNumber: 4,
      title: 'DOT_SCHEDULE_APPOINTMENT_BOOKING_LABEL',
      validationName: 'appointment-booking'
    }
  ];

  private dotService = inject(DotService);
  private dotBookableStateService = inject(DotBookableStateService);

  currentAppointment = input.required<ActivitiesViewModel>();
  bookableStatus = toSignal(toObservable(this.currentAppointment)
    .pipe(switchMap(appointment => this.dotBookableStateService.getAppointmentStatus(appointment))));

  currentStepIndex = signal(0);
  currentStep = computed(() => this.STEPS[this.currentStepIndex()].validationName);
  showErrors = signal(false);

  modalStatus = signal<'form' | 'schedule' | 'update' | 'timeout' | 'not-bookable'>('form');
  scheduleResult = signal<DotBookAppointmentResultStatus | null>(null);

  config = toSignal(inject(CountryConfigRestService).getConfig());
  allActivities = toSignal(this.dotService.getAllActivities(), {initialValue: []});
  user = toSignal(inject(UserRestService).getUser());

  affectedActivities = computed(() => this.bookableStatus().activitiesStatus
    ? this.dotService.getDotAvailableActivities(this.bookableStatus().activitiesStatus, this.allActivities()) : []);

  dayOffsetForMsTickets = computed(() => +this.config().DOT_ADDITIONAL_DAYS_MS_TICKET);

  effectiveBookableStatus = computed(() =>
    this.dotService.getEffectiveBookableStatus(this.bookableStatus(), this.allActivities(), this.dayOffsetForMsTickets()));

  currentAppointmentStatus = computed(() =>
    this.findStatusForAppointment(this.currentAppointment(), this.effectiveBookableStatus()));

  scheduleForm = new FormGroup({
    timeframe: new FormGroup({
      earliestDate: new FormControl<Date | null>(null, [Validators.required]),
      latestDate: new FormControl<Date | null>(null, [Validators.required])
    }, [startBeforeEndDate('earliestDate', 'latestDate')]),
    timeSlot: new FormControl<DotTimeSlot | null>(null, [Validators.required]),
    contact: new FormGroup({
      name: new FormControl<string>({value: '', disabled: true}),
      email: new FormControl<string>({value: '', disabled: true}),
      phoneNumber: new FormControl<string | null>(null, [Validators.required])
    })
  });

  constructor(renderer: Renderer2) {
    super(renderer);

    this.dotService.openLegacyRescheduleModalEvent$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.close());

    effect(() => {
      const user = this.user();
      untracked(() => this.prefillContactDetails(user));
    });
  }

  bookAppointment(): void {
    if (!this.scheduleForm.valid) {
      throw new Error('This should not happen. The form should be valid at this point.');
    }

    // we need to use original bookable status here as Sense requires us to send the original due date
    const appointment: DotActivityData = this.findStatusForAppointment(this.currentAppointment(), this.bookableStatus());

    const timeSlot = this.scheduleForm.controls.timeSlot.value;
    const customerDetails: DotContactDetails = this.scheduleForm.controls.contact.getRawValue();
    const needsReview = this.effectiveBookableStatus().isReviewNeeded;

    this.modalStatus.set('schedule');

    this.dotService.bookAppointment({appointment, timeSlot, customerDetails}, needsReview)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(status => this.scheduleResult.set(status));
  }

  retry(): void {
    this.modalStatus.set('update');
    this.scheduleResult.set(null);

    this.dotBookableStateService.getAndForceUpdateAppointmentStatus(this.currentAppointment())
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(status => {
        if (status.isBookable) {
          this.reset();
        } else {
          this.modalStatus.set('not-bookable');
        }
      });
  }

  timeout(): void {
    this.modalStatus.set('timeout');
  }

  nextStep(): void {
    if (this.isFormGroupValidFor(this.currentStep())) {
      this.currentStepIndex.update(index => index + 1);
      this.showErrors.set(false);
    } else {
      this.showErrors.set(true);
    }
  }

  previousStep(): void {
    this.currentStepIndex.update(index => index - 1);
    this.showErrors.set(false);
  }

  private isFormGroupValidFor(step: string): boolean {
    const {timeframe, timeSlot, contact} = this.scheduleForm.controls;

    if (step === 'timeframe-specification') {
      return timeframe.valid;
    } else if (step === 'appointment-selection') {
      return timeSlot.valid;
    } else if (step === 'contact-details') {
      return contact.valid;
    } else if (step === 'appointment-booking') {
      return true;
    } else {
      throw new Error('Invalid step of DoT modal!');
    }
  }

  private findStatusForAppointment(appointment: ActivitiesViewModel, status: DotBookableStatus): DotActivityData {
    return status.activitiesStatus?.find(activityData =>
      this.dotService.isDotActivityDataForAppointment(activityData, appointment));
  }

  close(): void {
    this.reset();
    this.hide();
  }

  private reset(): void {
    this.modalStatus.set('form');
    this.scheduleResult.set(null);
    this.currentStepIndex.set(0);
    this.showErrors.set(false);
    this.scheduleForm.reset();
    this.prefillContactDetails(this.user());
  }

  private prefillContactDetails(user?: User): void {
    this.scheduleForm.controls.contact.patchValue({
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      phoneNumber: user?.mobile || user?.phone
    });
  }

  override ok(): void {
    // this method was left purposefully empty
  }

  get timeframeValue(): DotTimeframe {
    const timeframe = this.scheduleForm.controls.timeframe.value;

    if (!timeframe.earliestDate || !timeframe.latestDate) {
      throw new Error('Timeframe is not specified! This should not happen.');
    }

    return timeframe as DotTimeframe;
  }
}
