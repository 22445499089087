import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CustomerContacts } from '../../../core/models/customer/customer-contacts';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { User } from '../../../core/models/user';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { SpaceValidator } from '../../validators/space.validator';

let instance = 0;

@Component({
  selector: 'hl-contact-area',
  templateUrl: './contact-area.component.html'
})
export class ContactAreaComponent implements OnInit, OnChanges, OnDestroy {
  // we will pass in detail from Create component
  @Input()
  contactForm: UntypedFormGroup;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  isDisabled: boolean;
  @Input()
  labelContact = 'TICKET_LABEL_CONTACT';
  @Input()
  showAdditionalContacts = true;
  @Input()
  showContactTitles = true;
  @Input()
  unselectSalutation = false;
  @Input()
  unselectContactTitle = false;
  @Input()
  loadUserInfo = true;
  @Input()
  descriptionLabel: string;
  @Input()
  alwaysFullWidth = false;
  @Input()
  showPhoneNumberExtension = false;
  @Input()
  useSalutationTitleValue = false;
  @Input()
  mandatoryFields = "email,phone";

  @Input()
  dataCy;

  id: number;
  // initially selected additional contact is null
  selectedContactInfo: string = null;

  salutationLabel = '';
  titleLabel = '';

  ticketSalutations: DropdownOptions[] = [];
  ticketTitles: DropdownOptions[] = [];
  ticketAdditionalContacts: DropdownOptions[] = [];
  CONTACT_EMAIL_FIELD = 'contactEmail';
  CONTACT_PHONE_FIELD = 'contactPhone';
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private ticketsRestService: TicketsRestService,
    private ticketsUtilService: TicketsUtilService,
    private userRestService: UserRestService,
    private cd: ChangeDetectorRef
  ) {
    // have different ids when the component is used more times in one place
    this.id = ++instance;
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contactForm'] && !changes['contactForm'].firstChange) {
      this.init();
      this.cd.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.loadUserInfoAndMapSalutationTitle();
  }

  changeAdditionalContact() {
    // selectedContactInfo will be full object
    // https://stackoverflow.com/a/35945293
    if (!isEmpty(this.selectedContactInfo)) {
      this.setFormControlValues(JSON.parse(this.selectedContactInfo));
    }
  }

  /**
   *
   * @description
   * Get the user information.
   * Note:- map the corresponding id for salutation/title to be used in form data.
   * This is required for SAP PI-> BE.
   */
  loadUserInfoAndMapSalutationTitle() {
    this.selectedContactInfo = null;
    const user$ = this.userRestService.getUser();
    const salutations$ = this.ticketsRestService
      .getContactsSalutation().pipe(
        map(ticketSalutations => {
          return ticketSalutations.map(ticketSalutation => ({
            value: this.useSalutationTitleValue ? ticketSalutation.salutationDescription : ticketSalutation.salutationId,
            title: ticketSalutation.salutationDescription
          }));
        }
      ));
    const titles$ = this.ticketsRestService
      .getContactsTitle().pipe(
        map(ticketTitles => ticketTitles.map(ticketTitle => ({
          value: ticketTitle.titleId,
          title: ticketTitle.titleDescription
        })))
      );
    const additionalContact$ = this.ticketsUtilService.getMappedAdditionalContacts();

    forkJoin(
      user$,
      salutations$,
      titles$,
      additionalContact$
    ).subscribe(responses => {
      const user = responses[0];

      this.ticketSalutations = responses[1];

      this.ticketTitles = responses[2];

      if (this.unselectSalutation) {
        this.ticketSalutations.unshift({value: '', title: ''});
      }
      if (this.unselectContactTitle) {
        this.ticketTitles.unshift({value: '', title: ''});
      }

      this.ticketAdditionalContacts = responses[3];

      this.adaptValidators();

      // Note:- Call at the end so that ticket titles and ticket salutation are set, before use inside
      // setFormControlValues
      if (this.loadUserInfo) {
        this.setFormControlValues(user);
      }
      this.cd.detectChanges();
    });
  }

  /**
   * @description Set the form control name values
   * @param contact | this can be user info during initial load otherwise the customer contacts when
   * changing additional contacts
   */
  setFormControlValues(contact: User | CustomerContacts) {
    this.salutationLabel = contact.salutation;
    this.titleLabel = contact.title;

    // do the mapping for salutation as required for SAP PI
    const salutationMappedVal = this.ticketsUtilService.getMappedPropertyIdDataset(
      this.ticketSalutations,
      'salutationDescription',
      contact.salutation,
      'salutationId'
    );

    // do the mapping for title as required for SAP PI
    const titleMappedVal = this.ticketsUtilService.getMappedPropertyIdDataset(
      this.ticketTitles,
      'titleDescription',
      contact.title,
      'titleId'
    );

    // set the form control name values
    this.contactForm.patchValue({
      contactFirstName: contact.firstName,
      contactLastName: contact.lastName,
      contactEmail: contact.email,
      contactPhone: contact.phone,
      contactPhoneExt: contact.phoneExt,
      contactSalutation: salutationMappedVal,
      contactTitle: titleMappedVal
    });
  }

  isMobile() {
    const width = window.innerWidth;
    return width < 768;
  }

  adaptValidators() {
    if(this.mandatoryFields !== undefined) {
      switch(true) {
        case this.mandatoryFields === 'email':
          this.removeValidators(this.CONTACT_PHONE_FIELD);
          break;
        case this.mandatoryFields === 'phone':
          this.removeValidators(this.CONTACT_EMAIL_FIELD);
          break;
        case (this.mandatoryFields === 'email|phone' || this.mandatoryFields === 'phone|email'):
          this.updateValidators();
          this.registerFormChanges(this.CONTACT_EMAIL_FIELD);
          this.registerFormChanges(this.CONTACT_PHONE_FIELD);
          break;
        case this.mandatoryFields === '':
          this.removeValidators(this.CONTACT_EMAIL_FIELD);
          this.removeValidators(this.CONTACT_PHONE_FIELD);
          break;
      }
    }
  }
  registerFormChanges(field: string) {
    this.contactForm.get(field).valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateValidators();
    });
  }
  updateValidators() {
    if(!!this.contactForm.get(this.CONTACT_PHONE_FIELD).value &&
    !!!this.contactForm.get(this.CONTACT_EMAIL_FIELD).value) {
      this.addValidators(this.CONTACT_PHONE_FIELD);
      this.removeValidators(this.CONTACT_EMAIL_FIELD);
    } else {
      this.addValidators(this.CONTACT_EMAIL_FIELD);
      this.removeValidators(this.CONTACT_PHONE_FIELD);
    }
  }
  addValidators(field: string) {
    this.contactForm.get(field).addValidators([Validators.required,SpaceValidator.noWhiteSpace]);
    this.contactForm.get(field).updateValueAndValidity({onlySelf: true, emitEvent: false});
  }
  removeValidators(field: string) {
    this.contactForm.get(field).removeValidators([Validators.required,SpaceValidator.noWhiteSpace]);
    this.contactForm.get(field).updateValueAndValidity({onlySelf: true, emitEvent: false});
  }
}
