import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DotContactDetailsFormGroup } from '../../models/dot-contact';
import { BasicComponentsSharedModule } from '../../../../core/basic-components-shared.module';

@Component({
  selector: 'hl-dot-schedule-contact-details',
  templateUrl: './dot-schedule-contact-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    BasicComponentsSharedModule
  ]
})
export class DotScheduleContactDetailsComponent {
  formGroup = input.required<DotContactDetailsFormGroup>();
  showErrors = input<boolean>(false);
}
