<div class="form__group">
  <label class="form__label" for="file-input" [translate]="'GENERIC_LABEL_ATTACH_FILE'"></label>
  <div class="file-input" [ngClass]="{'invalid': !isAttachmentValid}">
    <button class="button button--small file-input__button" (click)="inputBtn.click()" [translate]="'LABEL_SELECT_FILES'"
      [disabled]="isDisabled"></button>
    <input hidden type="file" id="file-input" class="file-input__input" accept="{{allowedFileTypes}}" #inputBtn (change)="handleFileSelect($event)"/>
    @if (!isAttachmentValid) {
      <small class="textfield__hint textfield__hint--validation"> {{ attachmentValidationMessage | translate }}</small>
    }
    @if (isRequired && isFormSubmitted && isInvalid) {
      <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_ATTACHMENT_VALIDATION_ERROR'"></small>
    }
  </div>

  @if (fileObjects.length > 0) {
    <div>
      <div class="collection file-list">
        @for (atchmntItem of fileObjects; track atchmntItem) {
          <div class="collection-item file-list__item">
            <div class="collection__content">
              <div class="file-list__file">
                <div title="{{atchmntItem.filename}}" hlTooltip class="file-list__file-name">{{ atchmntItem.filename }}</div>
                <div class="file-list__file-size">{{ atchmntItem.filesize | fileSizeFormat }}</div>
              </div>
            </div>
            <div class="collection__additional-content">
              <button class="button button--link" (click)="removeAttachment(atchmntItem)">
                <i class="icon-close"></i>
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
