import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { InvoiceViewModel } from '../../../core/view-models/invoice-view-model';
import { InvoicesRestService } from '../../../core/rest-services/invoices-rest.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { SubTypeOfPreliminaryInvoices } from '../../../invoices/type-of-invoices';
import { InvoicesUtilService } from '../../../core/services/invoices-util.service';
import { PreliminaryInvoicesCacheService } from "../../../core/services/invoices/prelim-invoices/preliminary-invoices-cache.service";

@Component({
  selector: 'hl-submit-po-number-popup',
  templateUrl: './submit-po-number-popup.component.html'
})
export class SubmitPoNumberPopupComponent extends BaseModalPopup implements OnInit {
  @Input() viewModelInvoice: InvoiceViewModel;
  @Output() onChangeComponent = new EventEmitter<any>();
  isFormSubmitted = false;
  submitPoNumberForm: UntypedFormGroup;

  constructor(
    renderer: Renderer2,
    private invoicesRestService: InvoicesRestService,
    private invoicesUtilService: InvoicesUtilService,
    private fb: UntypedFormBuilder,
    private toasterService: ToasterService,
    private preliminaryInvoicesCacheService: PreliminaryInvoicesCacheService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.createForm();
  }

  ok() {
    this.isFormSubmitted = true;
    if (this.submitPoNumberForm.valid) {
      this.showSpinner = true;
      this.isFormSubmitted = true;
      this.invoicesRestService.submitPoNumber({
        invoiceKey: this.viewModelInvoice.invoiceKey,
        poNumber: this.submitPoNumberForm.value['poNumber']
      }).subscribe(
        {
          next: () => {
            this.toasterService.showTranslatedSuccessToaster('SUBMIT_PO_MESSAGE_SUCCESS');
            this.viewModelInvoice.poNumber = this.submitPoNumberForm.value['poNumber'];
            this.viewModelInvoice.status = SubTypeOfPreliminaryInvoices.SUBMITTED;
            this.onChangeComponent.emit();
            this.invoicesUtilService.changedInvoice$.next(this.viewModelInvoice);
            this.preliminaryInvoicesCacheService.setUseCache(false);
            this.hide();
          },
          error: () => {
            this.toasterService.showTranslatedErrorToaster('SUBMIT_PO_MESSAGE_ERROR');
            this.hide();
          }
        });
    }
  }

  hide() {
    this.createForm();
    this.isFormSubmitted = false;
    super.hide();
  }

  private createForm() {
    this.submitPoNumberForm = this.fb.group({poNumber: ['', [Validators.required]]});
  }
}
