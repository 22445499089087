<div class="form-horizontal" [formGroup]="productForm">

  <div class="form-group">
    <hl-selectbox [isRequired]="productForm.controls['preApprovalStatus'].errors && productForm.controls['preApprovalStatus'].errors['required']"
      [formControl]="productForm.controls['preApprovalStatus']"
      [class.is-invalid]="isFormSubmitted && !productForm.controls['preApprovalStatus'].valid" [options]="preApprovalStatuses$ | async"
      label="LABEL_PSR_PRE_APPROVAL" ngDefaultControl></hl-selectbox>
    @if (isFormSubmitted && !productForm.controls['preApprovalStatus'].valid) {
      <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
    }
  </div>

  <div class="form-group">
    <hl-textfield [isRequired]="productForm.errors && productForm.errors['statusOtherRequired']" label="LABEL_PSR_PRE_APPROVAL_OTHER"
      name="preApprovalStatusOther" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
      [isDisabled]="!productForm.controls['preApprovalStatusOther'].enabled"
      [isInvalid]="isFormSubmitted && productForm.errors && productForm.errors['statusOtherRequired']"
      [formControl]="productForm.controls['preApprovalStatusOther']" [maxlength]="50" ngDefaultControl></hl-textfield>
  </div>

  <div class="form-group">
    <hl-textfield label="LABEL_PSR_PROJECT_MANAGER" name="projectManager" [formControl]="productForm.controls['projectManager']" [maxlength]="200"
      placeholder="LABEL_OPTIONAL" ngDefaultControl></hl-textfield>
  </div>

  <div class="form-group">
    <hl-textfield label="LABEL_PSR_CONSULTANT" name="siemensContact" [formControl]="productForm.controls['siemensContact']" [maxlength]="200"
      placeholder="LABEL_OPTIONAL" ngDefaultControl></hl-textfield>
  </div>

  <div class="form-control">
    <hl-textfield [isRequired]="productForm.controls['submitterTitle'].errors && productForm.controls['submitterTitle'].errors['required']"
      label="LABEL_PSR_SUBMITTER_TITLE" name="submitterTitle" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
      [isInvalid]="isFormSubmitted && !productForm.controls['submitterTitle'].valid" [formControl]="productForm.controls['submitterTitle']"
      [maxlength]="100" ngDefaultControl></hl-textfield>
  </div>

  <div class="form-group">
    <input type="checkbox" class="checkbox" [class.is-invalid]="isFormSubmitted && !productForm.controls['confirmed'].valid"
      formControlName="confirmed" id="confirmed">
    <label for="confirmed">{{ 'LABEL_PSR_ACCEPT_AGREEMENT' | translate }} <a [href]="psrTermsLink$ | async" target="_blank" rel="noopener noreferrer"><i
      class="icon-arrow"></i></a></label>

    <div [ngClass]="{'invalid': isFormSubmitted && !productForm.controls['confirmed'].valid}">
      @if (isFormSubmitted && !productForm.controls['confirmed'].valid) {
        <small class="checkbox__hint" [translate]="'ERROR_AGREE_TERMS_PSR_AGREEMENT'"></small>
      }
    </div>

  </div>
</div>
