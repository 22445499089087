import { EmailFrequency, EquipmentSelection, UserSettings } from '../../../core/models/UserSettings';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { OverlayCommunicationService } from '../../../core/component-communication-services/overlay-close/overlay-communication.service';
import { CollapsableDirective } from '../../directives/collapsable/collapsable.directive';
import { roles } from '../../../core/core-constants.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { combineLatest, Observable } from 'rxjs';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { map, tap } from 'rxjs/operators';
import { MessagingCenterService } from '../../../core/services/messaging-center/messaging-center.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { EquipmentChecksheetFeatureService } from '../../../equipment/equipment-detail/equipment-checksheet/services/equipment-checksheet-feature.service';
import { User } from '../../../core/models/user';
import { MarketingConsentService } from '../../../core/services/marketing-consent.service';

@Component({
  selector: 'hl-create-user-notifications-page',
  templateUrl: './create-user-notifications-page.component.html'
})
export class CreateUserNotificationsPageComponent implements OnInit {
  isLoaded = false;
  isChanged = false;
  isRoleChanged = false;
  isPlannedActivitiesSubscribed = false;
  isMarketingConsentDiasbled = false;
  userSettingsOrig: UserSettings;
  userSettings: UserSettings;
  showAsuWeeklyEmail$: Observable<boolean>;
  isSecurityAdvisoriesViewRoleEditingDisabled = false;
  showSubscribeSecurityAdvisories = false;
  showSecurityAdvisoriesRole = false;
  showSecurityAssessmentEmails = false;
  showSprReport = false;
  showPlannedActivities = false;
  showPreliminaryInvoice = false;
  toggleMarketingConsent = true;

  shouldShowChecksheetSubscription$: Observable<boolean>;

  @ViewChild(CollapsableDirective) collapsable: CollapsableDirective;

  @ViewChild('favEquipment', {static: false}) favEquipment: ElementRef;
  @ViewChild('sprReport', {static: false}) sprReport: ElementRef;
  @ViewChild('sprReportFavOnly', {static: false}) sprReportFavOnly: ElementRef;

  @Input()
  instanceId = 1;

  constructor(private httpUserService: UserRestService,
    private userUtilService: UserUtilService,
    private configService: CountryConfigRestService,
    private overlayCommunicationService: OverlayCommunicationService,
    private toasterService: ToasterService,
    private messagingCenterService: MessagingCenterService,
    public equipmentChecksheetFeatureService: EquipmentChecksheetFeatureService,
    private marketingConsentService: MarketingConsentService) {
    this.userSettings = {
      'subprocessorNotificationSubscribed': false,
      'asuEmailFrequency': EmailFrequency.NEVER,
      'checkSheetEmailSubscribed': false,
      'securityAdvisoriesSubscribed': false,
      'sprReportEquipmentSelection': EquipmentSelection.NONE,
      'securityAdvisoriesViewRole': false,
      'securityAssessmentEmailsSubscribed': false,
      'plannedActivitiesEmailFrequency': EmailFrequency.NEVER,
      'preliminaryInvoiceEmailSubscribed': false,
      'preliminaryInvoiceEmailFrequency': EmailFrequency.WEEKLY,
      'marketingConsent': false
    };
  }

  ngOnInit(): void {
    this.init();
  }

  private init() {
    const rolesToCheck = {
      itAdminRole: roles.itAdminRole,
      countryAdminRole: roles.countryAdminRole,
      masterAdminRole: roles.masterAdminRole,
      securityAdvisoryViewRole: roles.securityAdvisoryViewRole,
      securityAdvisoryViewXiaRole: roles.securityAdvisoryViewXiaRole,
      cybersecurityDashboardRole: roles.cybersecurityDashboardRole,
      securityAdvisoryAuthorRole: roles.securityAdvisoryAuthorRole,
      viewPlannedActivityRole: roles.viewPlannedActivityRole,
      viewPlannedTrainingRole: roles.viewPlannedTrainingRole,
      viewEPSReports: roles.viewEPSReports,
      impersonateUserRole: roles.impersonateUserRole,
      viewInvoicesRole: roles.viewInvoicesRole
    };
    this.shouldShowChecksheetSubscription$ = this.equipmentChecksheetFeatureService.shouldShowChecksheetSubscriptionInMySettings();
    this.showAsuWeeklyEmail$ = combineLatest([this.userUtilService.checkUserRoles(rolesToCheck),
      this.configService.getConfig(), this.userUtilService.getUser()
    ])
      .pipe(
        tap(([userRoles, config, user]) => {
          this.setSecurityAdvisoriesParams(userRoles, config);
          this.setPlannedActivitiesParams(userRoles, config);
          this.setSprReportVisibility(userRoles, config, user.email);
          this.showPreliminaryInvoicePanel(config, userRoles);
          this.setMarketingConsent(user, config);
        }),
        map(([userRoles]) => userRoles && userRoles.itAdminRole));

    this.httpUserService.getUserSettings().subscribe(
      result => {
        this.userSettingsOrig = result;
        if (this.userSettingsOrig.plannedActivitiesEmailFrequency !== EmailFrequency.NEVER) {
          this.isPlannedActivitiesSubscribed = true;
        }
        this.userSettings = cloneDeep(this.userSettingsOrig);
        this.isLoaded = true;
      }
    );
  }

  private setSecurityAdvisoriesParams(userRoles, config) {

    const toggleSecurityAdvisories = isEqual(config.TOGGLE_SECURITY_ADVISORIES_MESSAGING_CENTER, 'true');
    const toggleSecurityAdvisoriesRole = isEqual(config.FEATURE_TOGGLE_ADVISORY_SUBSCRIPTION, 'true');
    const toggleSecurityAdvisoriesXiaRole = isEqual(config.FEATURE_TOGGLE_XIA_VIEW_ADVISORIES, 'true');
    const toggleSecurityAssessmentEmails = isEqual(config.FEATURE_TOGGLE_CSMS_EMAIL_NOTIFICATION, 'true');

    if (toggleSecurityAssessmentEmails && userRoles && userRoles.cybersecurityDashboardRole) {
      this.showSecurityAssessmentEmails = true;
    }

    if (toggleSecurityAdvisories && toggleSecurityAdvisoriesXiaRole && userRoles && userRoles.securityAdvisoryViewXiaRole) {
      this.showSubscribeSecurityAdvisories = true;
      return;
    }

    if (toggleSecurityAdvisories && toggleSecurityAdvisoriesRole) {
      this.showSubscribeSecurityAdvisories = userRoles && userRoles.securityAdvisoryViewRole;
      this.showSecurityAdvisoriesRole = true;
      this.isSecurityAdvisoriesViewRoleEditingDisabled = userRoles &&
        (userRoles.itAdminRole || userRoles.cybersecurityDashboardRole || userRoles.securityAdvisoryAuthorRole) &&
        userRoles.securityAdvisoryViewRole;
      return;
    }

    if (toggleSecurityAdvisories) {
      this.showSubscribeSecurityAdvisories = true;
    }

  }

  private setPlannedActivitiesParams(userRoles, config) {
    const togglePlannedActivities = isEqual(config.TOGGLE_PLANNED_ACTIVITIES_SUBSCRIPTION, 'true');
    if (togglePlannedActivities && !userRoles?.impersonateUserRole
      && (userRoles?.viewPlannedActivityRole || userRoles?.viewPlannedTrainingRole)) {
      this.showPlannedActivities = true;
    }
  }

  private setSprReportVisibility(userRoles, config, email: string) {
    const isSprReportToggleEnabled = isEqual(config.FEATURE_TOGGLE_SPR_SUBSCRIBE_MONTHLY_REPORT, 'true');
    const hasShowSprReportRoles = userRoles.viewEPSReports || userRoles.masterAdminRole || userRoles.countryAdminRole;
    const hasEmail = !isEmpty(email);
    this.showSprReport = isSprReportToggleEnabled && hasShowSprReportRoles && hasEmail;
  }

  private setMarketingConsent(user: User, config: Record<string, string>) {
    this.isMarketingConsentDiasbled = !!user.isInternalUser;
    this.toggleMarketingConsent = isEqual(config.FEATURE_TOGGLE_MARKETING_CONSENT, 'true');
  }

  private collapseAll() {
    this.collapsable.toggleAllPanels(false);
  }

  resetElements(): void {
    this.init();
    this.collapseAll();
  }

  setChanged() {
    if (this.showSecurityAdvisoriesRole && !this.userSettings.securityAdvisoriesViewRole) {
      this.userSettings.securityAdvisoriesSubscribed = false;
    }
    this.isChanged = this.validateSettings() && !isEqual(this.userSettingsOrig, this.userSettings);
    this.isRoleChanged = !isEqual(this.userSettingsOrig.securityAdvisoriesViewRole, this.userSettings.securityAdvisoriesViewRole);
  }

  validateSettings(): boolean {
    return this.isPlannedActivitiesSubscribed ? this.userSettings.plannedActivitiesEmailFrequency !== EmailFrequency.NEVER : true;
  }

  save() {
    this.isLoaded = false;
    this.httpUserService.postUserSettings(this.userSettings).subscribe(
      () => {
        this.isLoaded = true;
        this.isChanged = false;
        this.notifyUserReLogin();
        this.userSettingsOrig = cloneDeep(this.userSettings);
        this.overlayCommunicationService.emitCloseOverlayAfterSave(null);
        this.messagingCenterService.emitRefreshMessagingCenter();
        this.marketingConsentService.reloadMarketingConsent();
      }
    );
  }

  notifyUserReLogin() {
    if (this.isRoleChanged) {
      this.toasterService.showTranslatedSuccessToaster('TEXT_ROLE_CHANGED_LOGOUT_AND_LOGIN_AGAIN');
    }
  }

  close() {
    if (this.isChanged) {
      this.userSettings = cloneDeep(this.userSettingsOrig);
      this.isChanged = false;
    }
    this.overlayCommunicationService.emitCloseOverlayAfterSave(null);
  }

  isAsuWeeklyEmailChecked() {
    return this.userSettings.asuEmailFrequency === EmailFrequency.WEEKLY;
  }

  asuWeeklyEmailChanged() {
    this.userSettings.asuEmailFrequency = this.isAsuWeeklyEmailChecked() ? EmailFrequency.NEVER : EmailFrequency.WEEKLY;
    this.setChanged();
  }

  isSprReportChecked() {
    return this.userSettings.sprReportEquipmentSelection !== EquipmentSelection.NONE;
  }

  isSprReportFavOnlyChecked() {
    return this.userSettings.sprReportEquipmentSelection === EquipmentSelection.FAV;
  }

  sprReportChanged() {
    if (this.sprReport.nativeElement.checked) {
      this.userSettings.sprReportEquipmentSelection = this.sprReportFavOnly.nativeElement.checked ? EquipmentSelection.FAV : EquipmentSelection.ALL;
    } else {
      this.userSettings.sprReportEquipmentSelection = EquipmentSelection.NONE;
    }
    this.setChanged();
  }

  preliminaryInvoiceEmailChanged(event: any) {
    this.userSettings.preliminaryInvoiceEmailFrequency =
      this.userSettings.preliminaryInvoiceEmailSubscribed ? EmailFrequency.WEEKLY : EmailFrequency.NEVER;
    this.setChanged();
  }

  changePlannedActivities(event) {
    if (this.isPlannedActivitiesSubscribed) {
      const accordionItem = event.currentTarget.parentNode.querySelector('button');
      if (accordionItem?.getAttribute('aria-expanded') === 'false') {
        accordionItem?.click();
      }
    } else {
      this.userSettings.plannedActivitiesEmailFrequency = EmailFrequency.NEVER;
    }
    this.setChanged();
  }

  get emailFrequency(): typeof EmailFrequency {
    return EmailFrequency;
  }

  changePreliminaryInvoiceEmailFrequency() {
    this.isChanged = true;
    this.setChanged();
  }

  private showPreliminaryInvoicePanel(config: any, userRoles: any) {
    this.showPreliminaryInvoice = isEqual(config.FEATURE_TOGGLE_PRELIMINARY_INVOICE, 'true') && userRoles.viewInvoicesRole;
  }
}
