import { ContractsDetail } from '../models/contracts/contracts-detail';
import { Contracts } from '../models/contracts/contracts';
import { HttpParams } from '@angular/common/http';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { ContractsEquipmentUsage } from '../models/contracts/contracts-equipment-usage';

export const contractsRestName = restEndPoint + 'contracts';
export const contractsIndividualRestName = restEndPoint + 'contracts/:id';
export const contractsEquipmentUsageRestName = contractsIndividualRestName + '/usageData';

@Injectable({providedIn: 'root'})
export class ContractsRestService {

  constructor(private http: CacheableHttpClient) {
  }

  /**
   * @description get list of contracts from BE (/rest/v1/contracts?statusFilter=1,2,3)
   */
  getContracts(requestParams): Observable<Contracts[]> {
    const httpParams = new HttpParams()
      .set('statusFilter', requestParams);
    return this.http.get<Contracts[]>(contractsRestName, {params: httpParams});
  }

  /**
   * @description get contract details
   */
  getContractsDetails(contractNumber: string, equipmentKey: string): Observable<ContractsDetail[]> {
    const url = contractsIndividualRestName.replace(/:id/, contractNumber);
    const httpParams = new HttpParams()
      .set('equipmentKey', equipmentKey);

    return this.http.get<ContractsDetail[]>(url, {params: httpParams});
  }

  getContractsEquipmentUsage(contractNumber: string): Observable<ContractsEquipmentUsage[]> {
    const url = contractsEquipmentUsageRestName.replace(/:id/, contractNumber);
    return this.http.get<ContractsEquipmentUsage[]>(url, {cache: false});
  }

}
