import { ItemDetailViewModel } from '../../../core/view-models/item-detail-view-model';
import { ActivatedRoute } from '@angular/router';
import { ContractsUtilService } from '../../../core/services/contracts/contracts-util.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Subject } from 'rxjs';
import { ContractsOverviewViewModel } from '../../../core/view-models/contracts-view-model';
import { isEqual } from 'lodash-es';
import { WindowService } from '../../../core/window.service';
import { ContractDetailGroup, SubInformationItem } from '../../../core/models/contracts/contracts-detail';

@Component({
  selector: 'hl-contracts-overview',
  templateUrl: './contracts-overview.component.html'
})
export class ContractsOverviewComponent implements OnInit, OnDestroy {

  isLoaded: boolean;
  contractDetailList: ContractsOverviewViewModel[];
  datePattern = '';
  contractDetailFields: ItemDetailViewModel[];
  config: any;
  p58Contracts = false;
  maxItems = 2;
  viewLayout = 'inline';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private contractsUtilService: ContractsUtilService,
    private configService: CountryConfigRestService,
    private activatedRoute: ActivatedRoute,
    private windowService: WindowService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setViewLayout();
  }

  ngOnInit() {
    this.init();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.p58Contracts = isEqual(res.FEATURE_TOGGLE_P58_CONTRACTS, 'true');
    });
    this.setViewLayout();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
    });
    this.loadContractDetails();
  }

  setConfigProperties(config: Record<string, string>) {
    this.config = config;
    this.datePattern = config.GENERIC_DATE_PATTERN;
  }

  loadContractDetails() {
    this.activatedRoute.allParams.subscribe(
      (params: any) => {
        this.isLoaded = false;
        this.contractDetailList = [];
        this.contractsUtilService.getContractsDetails(params['contractId'], params['id']).pipe(
          finalize(() => this.isLoaded = true)
        ).subscribe((contractDetailsResponse) => {
          this.contractDetailList = contractDetailsResponse;
        });

        if (this.contractDetailFields) {
          this.contractsUtilService.reloadContractDetailFields(this.contractDetailFields);
        } else {
          this.contractDetailFields = this.contractsUtilService.getContractDetailFields(this.config);
        }
      }
    );
  }

  handleContractExport = () => {
    return this.contractsUtilService.generateContractDetailsExport(this.contractDetailFields, this.contractDetailList);
  }

  getExportListCount(): number {
    return this.contractDetailFields.length + this.contractDetailList.length;
  }

  splitInformationArray(inputArray: SubInformationItem[]) {
    if (inputArray.length === 0) {
      return [];
    }

    let splitArray = [];
    if (this.viewLayout == 'inline') {
      this.maxItems = 3;
    } else {
      this.maxItems = inputArray.some(value => value.value.length > 35) ? 2 : 3;
    }
    for (let i = 0; i < inputArray.length; i += this.maxItems) {
      splitArray.push(inputArray.slice(i, i + this.maxItems));
    }
    return splitArray;
  }

  setViewLayout() {
    const viewWidth = this.windowService.nativeWindow.innerWidth;
    if (viewWidth < 700) {
      this.viewLayout = 'mobile';
    } else if (viewWidth < 1500) {
      this.viewLayout = 'tablet';
    } else if (viewWidth < 1701) {
      this.viewLayout = 'wrapped';
    } else {
      this.viewLayout = 'inline';
    }
  }

  setColumnSizeForMainSection(group: ContractDetailGroup): string {
    return group.subInformationItems.length > 3 || group.subInformationItems.length == 0 ? 'col-6' : 'col-3'
  }
}
