import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { UserRestService } from '../rest-services/user-rest.service';
import { delay, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MarketingConsentService implements OnDestroy {

  private reloadMarkingConsentSource = new Subject<void>();
  private marketingConsentSource = new ReplaySubject<boolean>(1);
  private unsubscribe$ = new Subject<void>();

  constructor(private userService: UserRestService) {
  }

  getMarketingConsent(): Observable<boolean> {
    return this.marketingConsentSource.asObservable();
  }

  getMarketingConsentDelayed(): Observable<boolean> {
    return this.marketingConsentSource.asObservable().pipe(delay(100));
  }

  initMarketingConsent() {
    this.reloadMarkingConsentSource.pipe(
      startWith(null),
      switchMap(() => this.userService.getUser()),
      map(({marketingConsent}) => marketingConsent),
      takeUntil(this.unsubscribe$)
    ).subscribe(consent => {
      this.marketingConsentSource.next(consent);
    })
  }

  reloadMarketingConsent() {
    this.userService.clearUserCache();
    this.reloadMarkingConsentSource.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
