import { Observable } from 'rxjs';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { TicketAttachment } from '../models/tickets/ticket-attachment';
import { TicketAttachmentViewModel } from '../view-models/ticket-attachment-view-model';
import { TranslateService } from '@ngx-translate/core';
import { restEndPoint } from '../core-constants.service';
import { Injectable } from '@angular/core';

import { forEach, includes, isEqual, split } from 'lodash-es';
import { EquipmentUtilService } from '../services/equipment/equipment-util.service';
import { map } from 'rxjs/operators';
import { DownloadUtilService } from './download-util.service';

@Injectable({providedIn: 'root'})
export class AttachmentUtilService {
  attachmentStatusFail = 'Fail';
  attachmentStatusProcessing = 'Processing';
  // Ticket attachment types
  TicketAttachmentTypes = {
    CUSTOMER_VIEW: ['', 'USR', 'SAT'],
    CUSTOMER_DOWNLOAD: ['', 'USR']
  };

  constructor(private translateService: TranslateService,
    private configService: CountryConfigRestService,
    private equipmentUtilService: EquipmentUtilService,
    private downloadUtilService: DownloadUtilService) {
  }

  /**
   *
   * @param {object} attachments An array of attachment objects
   * @param {string} sapSystem from config, e.g. P40
   * @param {string} ticketKey
   * @return {Array} download attachment list
   *
   * @description
   * Generate the download link
   *
   * Note:- Example response from Back End
   * [{"customerNumber":"0034358465","status":null,"attachmentName":"file1.jpg", "ticketNumber": "49867693"},
   * {"customerNumber":"0034358465","status":"fail","attachmentName":"file2.png", "ticketNumber": "49867693"}
   */
  generateDownloadAttachment(attachments: TicketAttachment[], sapSystem: string,
    ticketKey?: string): TicketAttachmentViewModel[] {

    const downloadAttachmentList: TicketAttachmentViewModel[] = [];

    if (attachments && attachments.length > 0) {

      forEach(attachments, ((attachment) => {
        let attachStatus = null;

        let disable = false;

        // check if the status is failed or not
        if (isEqual(attachment.status, this.attachmentStatusFail)) {
          disable = true;
          // tslint:disable-next-line:ban
          attachStatus = this.translateService.instant('ATTACHMENT_STATUS_FAILED');
        }

        // check if the status is processing or not
        if (isEqual(attachment.status, this.attachmentStatusProcessing)) {
          disable = true;
          // tslint:disable-next-line:ban
          attachStatus = this.translateService.instant('ATTACHMENT_STATUS_PROCESSING');
        }

        downloadAttachmentList.push({
          status: attachStatus,
          disableLink: disable,
          attachmentName: attachment.attachmentName,
          size: attachment.attachmentSize,
          downLoadLink: this.generateDownloadLink(attachment, sapSystem, ticketKey),
          attachmentType: attachment.attachmentType,
          creationDate: attachment.creationDate
        });

      }));
    }
    return downloadAttachmentList;
  }

  private generateDownloadLink(attachment, sapSystem: string, ticketKey: string): string {
    let link;
    // depending on attachment type the download link is computed differently
    if (this.isTicketCustomerDownloadAttachmentType(attachment.attachmentType)) {
      /*
      * for US users only, we need to send the downloadLink instead of attachmentName.
      * BE will decrypt download link, and extract attachmentID.
      */
      if (isEqual(sapSystem, 'P40')) {
        link = restEndPoint + 'tickets/' + attachment.ticketNumber + '/attachments/' +
          encodeURIComponent(attachment.attachmentName) + '?customerId=' +
          attachment.customerId + '&ticketKey=' + ticketKey +
          '&u=' + encodeURIComponent(attachment.downloadLink);
      } else {
        link = restEndPoint + 'tickets/' + attachment.ticketNumber + '/attachments/' +
          encodeURIComponent(attachment.attachmentName) + '?customerId=' +
          attachment.customerId + '&ticketKey=' + ticketKey;
      }
    } else {
      link = this.downloadUtilService.generateDownloadLink(
        'tickets', {
          id: attachment.customerId,
          name: attachment.attachmentName,
          payload: attachment.downloadLink,
          available: attachment.isAccessible
        });
    }
    return link;
  }

  /**
   * @description check attachment for type.
   * return true if type of customer attachment (null, '', 'USR'), false in all other cases
   *
   * @param {string} attachmentType
   * @returns {boolean}
   */
  isTicketCustomerViewAttachmentType(attachmentType: string): boolean {
    return includes(this.TicketAttachmentTypes.CUSTOMER_VIEW, attachmentType);
  }

  isTicketCustomerDownloadAttachmentType(attachmentType: string): boolean {
    return includes(this.TicketAttachmentTypes.CUSTOMER_DOWNLOAD, attachmentType);
  }

  /**
   *
   * @param equipmentKey
   * @returns {promise}
   *
   * @description
   * Check for rendering of tickets based on ld equipment and country configuration
   *
   * Input from Bastian via Mail.
   * IF (LD Equipment and TICKET_ATTACHMENT_SIEBEL_RENDER == true) {
   *  Show upload
   *	} elseif  (NOT LD Equipment and TICKET_ATTACHMENT_RENDER == true) {
   *	Show upload
   *	}
   */
  checkTicketAttachmentRenderByEquipment(equipmentKey: string): Observable<boolean> {

    return this.configService.getConfig().pipe(
      map(configResponse => {
        let renderTicketAttachment = false;
        if (this.equipmentUtilService.checkIsLdEquipment(equipmentKey)
          && isEqual(configResponse.TICKET_ATTACHMENT_SIEBEL_RENDER, 'true')) {
          renderTicketAttachment = true;
        } else if (!this.equipmentUtilService.checkIsLdEquipment(equipmentKey)
          && isEqual(configResponse.TICKET_ATTACHMENT_RENDER, 'true')) {
          renderTicketAttachment = true;
        }
        return renderTicketAttachment;
      })
    );
  }

  checkActivityAttachmentRenderByEquipment(equipmentKey: string): Observable<boolean> {
    return this.configService.getConfig().pipe(
      map(configResponse => {
        let renderTicketAttachment = false;
        if (this.equipmentUtilService.checkIsLdEquipment(equipmentKey)
          && isEqual(configResponse.ACTIVITY_ATTACHMENT_SIEBEL_RENDER, 'true')) {
          renderTicketAttachment = true;
        } else if (!this.equipmentUtilService.checkIsLdEquipment(equipmentKey)
          && isEqual(configResponse.ACTIVITY_ATTACHMENT_RENDER, 'true')) {
          renderTicketAttachment = true;
        }
        return renderTicketAttachment;
      })
    );
  }

  /**
   * decide, whether the attachment should be shown based on ticketType
   * @param {string} ticketType
   * @returns {boolean}
   */
  checkTicketTypeAttachmentRender(ticketType: string): Observable<boolean> {
    return this.configService.getConfig().pipe(
      map((configResponse) => {
        const allowedTypes = split(configResponse.TICKET_ATTACHMENT_ALLOWED_TYPES, ',');
        return includes(allowedTypes, ticketType);
      })
    );
  }

  checkActivityTypeAttachmentRender(ticketType: string): Observable<boolean> {
    return this.configService.getConfig().pipe(
      map((configResponse) => {
        const allowedTypes = split(configResponse.ACTIVITY_ATTACHMENT_ALLOWED_TYPES, ',');
        return includes(allowedTypes, ticketType);
      })
    );
  }
}
