import { Injectable } from '@angular/core';
import { EquipmentInformation } from '../../../../core/models/equipment/equipment-information';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { Equipment } from '../../../../core/models/equipment/equipment';
import { CountryConfigRestService } from '../../../../core/rest-services/country-config-rest.service';
import { EquipmentChecksheetRestService } from './equipment-checksheet-rest.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentChecksheetFeatureService {

  constructor(
    private configService: CountryConfigRestService,
    private checksheetRestService: EquipmentChecksheetRestService) {
  }

  isChecksheetTabVisibleForEquipment(equipment: Equipment, countryConfig: Record<string, string>): Observable<boolean> {
    if (!this.isChecksheetTabEnabled(countryConfig)) {
      return of(false);
    }
    return this.isEquipmentWhitelisted(equipment, countryConfig);
  }

  private isEquipmentWhitelisted(equipment: Equipment, countryConfig: Record<string, string>) {
    const isCustomerWhitelisted$ = this.isWhitelisted(equipment.customerId, countryConfig);
    const isMaterialWhitelisted$ = this.checksheetRestService.checkEquipmentWhitelistedForChecksheets(equipment.materialNumber);
    const isModalityWhitelisted = this.areChecksheetsVisibleForModality(this.getEquipmentModality(equipment), countryConfig);
    return combineLatest([isCustomerWhitelisted$, isMaterialWhitelisted$]).pipe(
      map(([isCustomerWhitelisted, isMaterialWhitelisted]) =>
        isCustomerWhitelisted && (isMaterialWhitelisted || isModalityWhitelisted)));
  }

  isChecksheetWidgetVisibleForEquipment(equipment: Equipment, countryConfig: Record<string, string>): Observable<boolean> {
    if (!this.isChecksheetWidgetEnabled(countryConfig)) {
      return of(false);
    }
    return this.isEquipmentWhitelisted(equipment, countryConfig);
  }

  private isWhitelisted(customerId: string, countryConfig: Record<string, string>): Observable<boolean> {
    return countryConfig['CHECK_SHEET_CUSTOMER_MODE'] === 'PILOT'
      ? this.isCustomerWhitelistedForChecksheet(customerId)
      : of(true);
  }

  shouldShowChecksheetSubscriptionInMySettings(): Observable<boolean> {
    return this.configService.getConfig().pipe(
      switchMap(config => this.isChecksheetSubscriptionEnabled(config) ?
        this.checksheetRestService.isChecksheetEnabledForUserModalities() : of(false))
    );
  }

  isCustomerWhitelistedForChecksheet(customerId: string) {
    return this.checksheetRestService.checkCustomerWhitelistedForChecksheets(customerId);
  }

  private isChecksheetTabEnabled(countryConfig: Record<string, string>): boolean {
    return countryConfig['FEATURE_TOGGLE_DAILY_CHECK_SHEET_TAB'] === 'true';
  }

  private isChecksheetWidgetEnabled(countryConfig: Record<string, string>): boolean {
    return countryConfig['FEATURE_TOGGLE_EQUIP_WIDGET_CHECK_SHEET'] === 'true';
  }

  private isChecksheetSubscriptionEnabled(countryConfig: Record<string, string>): boolean {
    return countryConfig['FEATURE_TOGGLE_CHECK_SHEET_SUBSCRIPTION'] === 'true';
  }

  private areChecksheetsVisibleForModality(modality: string, countryConfig: Record<string, string>): boolean {
    const modalities = countryConfig['CHECKSHEET_MODALITY_WHITELIST'].split(',');
    return modalities.includes(modality);
  }

  private getEquipmentModality(equipment: EquipmentInformation): string {
    return equipment.cmdbEquipment?.modalityCode ?? equipment.modality;
  }
}
