<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
    <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
      <div class="modal-dialog__container">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title" [translate]="'LABEL_TICKET_STATE_DOWN'"></h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="modal1_desc">
          <div class="message message-warn">
            <span [translate]="'TEXT_TICKET_STATE_DOWN_INFORMATION'"></span>
          </div>
        </div>
        <div class="modal-dialog__footer">
          <button class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_OK'"></button>
        </div>
      </div>
    </div>
</ng-template>
