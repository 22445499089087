export interface Message {
  groupId: string;
  title: string;
  category: string;
  creationDate: string;
  dueDate: string;
  viewDate: string;
  content: string;
  status: string;
  messageType: string;
  identifier: string;
  cvssScore: string;
  advisoryId: string;
  lastUpdated: string;
  description: string;
}

export const SystemUpdateMessageType = 'SYSTEM_UPDATE';
export const SubprocessorNotificationMessageType = 'SUBPROCESSOR_NOTIFICATION';
export const SubprocessorNotificationHQMessageType = 'SUBPROCESSOR_NOTIFICATION_HQ';
export const SecurityAdvisoryNotificationMessageType = 'SECURITY_ADVISORY_NOTIFICATION';
export const AdvisoryCybersecurityNotificationMessageType = 'ADVISORY_CYBERSECURITY_NOTIFICATION';
export const SurveyNotificationMessageType = 'SURVEY_NOTIFICATION';
export const PreliminaryInvoicesNotificationMessageType = 'PRELIMINARY_INVOICE_NOTIFICATION';
export const DocumentNotificationMessageType = 'DOCUMENT_UPDATED';
