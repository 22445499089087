<div class="form-group">
  <div class="selectbox selectbox-grouped" (click)="dropdownToggle($event)" [class.is-open]="isDropdownOpen" [class.has-focus]="isDropdownOpen"
    [class.is-active]="isActive">
    <select id="psr-templates" class="selectbox__select" [(ngModel)]="value" (ngModelChange)="onTemplateSelected()" style="pointer-events: none">
      <option [value]="null" disabled selected></option>
      @for (psrTemplatesByModality of psrTemplates; track psrTemplatesByModality) {
        @for (psrTemplatesByCategory of psrTemplatesByModality.groupByCategory; track psrTemplatesByCategory) {
          <optgroup [label]="psrTemplatesByCategory.category">
            @for (templateOption of psrTemplatesByCategory.templates; track templateOption) {
              <option [value]="templateOption.title">{{ templateOption.title }}</option>
            }
          </optgroup>
        }
      }
    </select>
    <label class="selectbox__label" for="psr-templates" [translate]="'LABEL_PSR_OFFERINGS'"></label>
    @for (psrTemplatesByModality of psrTemplates; track psrTemplatesByModality) {
      <div class="menu selectbox__option-list" style="max-width: 100%;">
        @for (psrTemplatesByCategory of psrTemplatesByModality.groupByCategory; track psrTemplatesByCategory) {
          <ul class="selectbox-grouped__list selectbox-grouped__list--first">
            <li class="selectbox__option selectbox-grouped__optgroup" disabled="true">{{ psrTemplatesByCategory.category }}</li>
            <ul class="selectbox-grouped__list">
              @for (templateOption of psrTemplatesByCategory.templates; track templateOption) {
                <li class="selectbox__option selectbox-grouped__option"
                  (click)="setSelected({title: psrTemplatesByModality.modality, value: templateOption})">{{ templateOption.title }}
                </li>
              }
            </ul>
          </ul>
        }
      </div>
    }

  </div>
</div>
