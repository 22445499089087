import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { PsrUtilService } from '../../../core/utils/psr-util.service';
import { SelectOption } from '../../../core/models/select-option';
import { PsrTypeEnum } from '../../../core/core-constants.service';
import { CreatePsrTemplateEventService } from '../../../core/component-communication-services/create-psr-template/create-psr-template-event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hl-create-psr-service-summary',
  templateUrl: './create-psr-service-summary.component.html'
})
export class CreatePsrServiceSummaryComponent implements OnInit, OnDestroy {
  @Input()
  productForm: UntypedFormGroup;
  @Input()
  isFormSubmitted: boolean;

  priorities: SelectOption[];
  psrTemplateEventSubscription: Subscription;

  constructor(
    private configService: CountryConfigRestService,
    private psrUtilService: PsrUtilService,
    private createPsrTemplateEventService: CreatePsrTemplateEventService
  ) {}

  ngOnInit() {
    this.init();
    this.registerEventListeners();
  }

  init() {
    this.psrUtilService
      .convertPsrTypesToSelectOptions(PsrTypeEnum.PRIO)
      .subscribe(prioritiesResponse => {
        this.priorities = prioritiesResponse;
      });
  }

  ngOnDestroy(): void {
    if (this.psrTemplateEventSubscription) {
      this.psrTemplateEventSubscription.unsubscribe();
    }
  }

  private registerEventListeners() {
    this.psrTemplateEventSubscription = this.createPsrTemplateEventService.changedPsrTemplateSource$.subscribe(
      psrTemplate => {
        if (psrTemplate && psrTemplate.value) {
          this.productForm.patchValue({
            requestDescription: psrTemplate.value.defaultInvoiceDescription,
            expectedOutcome: psrTemplate.value.defaultDetailDescription
          });
        } else {
          this.productForm.patchValue({
            requestDescription: '',
            expectedOutcome: ''
          });
        }
      }
    );
  }
}
