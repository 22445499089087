import { Component, Input, Renderer2 } from '@angular/core';
import { SelectOption } from '../../../core/models/select-option';
import { localeSplitter } from '../../../core/core-constants.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { ImpersonationCommunicationService } from '../../../core/component-communication-services/impersonation/impersonation-communication.service';
import { Router } from '@angular/router';
import { ImpersonationUtilsService } from '../../../core/utils/impersonation-utils.service';
import { User } from '../../../core/models/user';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { WalkMeUtilService } from '../../../core/utils/walk-me-util.service';
import { ClearCacheUtilService } from 'app/core/utils/clear-cache-util.service';
import { DomUtilService } from '../../../core/utils/dom-util.service';
import { MedalliaUtilService } from '../../../core/utils/medallia-util.service';

@Component({
  selector: 'hl-switch-language-modal',
  templateUrl: './switch-language-modal.component.html'
})
export class SwitchLanguageModalComponent extends BaseModalPopup {
  @Input() selectedCountry: string;
  @Input() selectedLocale: string;
  @Input() countries: SelectOption[];
  @Input() languages: SelectOption[];
  @Input() user: User;
  isCountryLangSwitchCollapsed = false;

  constructor(renderer: Renderer2,
    private userRestService: UserRestService,
    private lifeNetUtilService: LifeNetUtilService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private walkMeUtilService: WalkMeUtilService,
    private medalliaUtilService: MedalliaUtilService,
    private router: Router,
    private impersonationUtilService: ImpersonationUtilsService,
    private clearCacheUtilService: ClearCacheUtilService) {
    super(renderer);
  }

  /**
   *
   * @description
   * When user change the country and language, then all cache's are cleared and then a
   * notification is broadcasted, so that localization of data also takes place.
   *
   */
  ok() {
    this.showSpinner = true;
    // call for updating the data of respective countries.
    this.sendAppReLoadNotification();

    // this is required, to close when auto-close is set to outsideClick.
    this.isCountryLangSwitchCollapsed = true;
  }

  /**
   *
   * @description
   * Notify the application to reload by broadcasting an event so that corresponding locale
   * data is freshly loaded from BE.
   */
  sendAppReLoadNotification() {
    const locale = {
      country: this.selectedCountry, 	// Here I should set from selectedCountry and not from selectedLocale
      language: this.selectedLocale.toString().split(localeSplitter)[0]
    };

    this.walkMeUtilService.setLanguageAndCountry(window, locale.language, this.selectedLocale.toString().split(localeSplitter)[1]);
    this.medalliaUtilService.update();
    DomUtilService.replaceUnsupportedFont(locale.language);

    this.userRestService.putLocaleUpdate(locale).subscribe(() => {
      this.lifeNetUtilService.setTranslationFile(this.selectedLocale);
      this.lifeNetUtilService.setConfigurationFile(this.selectedCountry);

      this.clearCacheUtilService.clear();

      this.router.navigate(['dashboard']);

      // notify country language change, so only listeners are updated
      this.impersonationCommunicationService.emitCountryLanguageChange();
      this.hide();
    });
  }

  updateLanguageOnCountryChange() {
    this.languages =
      this.impersonationUtilService.generateLanguageTranslationMappingAndAddDefaultLang(this.user.countries,
        this.selectedCountry);

    // pick the default language for the selected country if selectedLocale is empty or is not in the languages
    const isLanguageInList = this.selectedLocale
      && !!this.languages.find(option => option.value === this.selectedLocale);

    if (!this.selectedLocale || !isLanguageInList) {
      const selectedLocale =
        this.impersonationUtilService.getDefaultLanguageForCountry(this.user.countries, this.selectedCountry);

      if (selectedLocale) {
        this.selectedLocale = selectedLocale;
      }
    }
  }
}
