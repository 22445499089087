import { forkJoin, Observable } from 'rxjs';
import { ItemDetailViewModel } from '../../view-models/item-detail-view-model';
import { ContractsDetail } from '../../models/contracts/contracts-detail';
import { ContractsRestService } from '../../rest-services/contracts-rest.service';
import { EquipmentRestService } from '../../rest-services/equipment-rest.service';
import { LifeNetUtilService } from '../../utils/life-net-util.service';
import { ContractsOverviewViewModel, ContractsViewModel } from '../../view-models/contracts-view-model';
import { Injectable } from '@angular/core';
import { clone, filter, forEach, isEqual } from 'lodash-es';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { Contracts } from '../../models/contracts/contracts';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ContractsUtilService {

  nonHistoricRequestParam = '1,2,3';
  historicRequestParam = '9';
  selectedItem: ContractsViewModel;

  /**
   * This is a translation map for contracts dynamic generation of fields.
   * PLEASE USE A 'DATE' keyword in case a field is a date type
   * Example: startDate: 'LABEL_CONTRACT_START_DATE'
   */
  contractsLabelTranslationMap = {
    productName: 'GENERIC_LABEL_PRODUCT_NAME',
    siemensId: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
    myEquipmentName: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
    contractGroup: 'CONTRACT_GROUP_NUMBER',
    contractTypeDescription: 'CONTRACT_TYPE',
    contractStatusDescription: 'GENERIC_LABEL_STATUS',
    startDate: 'LABEL_CONTRACT_START_DATE',
    expirationDate: 'CONTRACT_EXPIRATION_DATE',
    serialNumber: 'GENERIC_LABEL_SERIAL_NUMBER',
    contractName: 'CONTRACT_NAME',
    contractNumber: 'EQUIPMENT_CONTRACT_NUMBER',
    contractStatusId: 'CONTRACT_STATUS',
    modality: 'LABEL_MODALITY',
    modalityTranslation: 'LABEL_MODALITY',
    customerName: 'GENERIC_LABEL_CUSTOMER_NAME'
  };

  constructor(private lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private contractsRestService: ContractsRestService,
    private translateService: TranslateService,
    private datePipeWrapperPipe: DatePipeWrapperPipe) {
  }

  getBaseExportData() {
    return ['contractNumber', 'productName', 'siemensId', 'contractGroup', 'startDate', 'expirationDate'];
  }

  /**
   * @description Returns the view model for contracts by merging equipments to
   * /contracts
   */
  getContractsViewModelList(getHistoric: boolean): Observable<ContractsViewModel[]> {
    const findObject = {
      findKey: 'key',
      findValue: 'equipmentKey',
      propertiesToMerge: [
        'productName',
        'siemensId',
        'myEquipmentName',
        'modality',
        'modalityTranslation',
        'customerName',
        'cmdbEquipment',
        'serialNumber'
      ]
    };

    const contractList = this.getContractList(getHistoric);
    return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
      contractList, findObject);
  }

  /**
   * Get all the historic and non-historic contracts when parameter getHistoric is true, otherwise
   * returns only the non-historic contracts (when false).
   *
   * @param {boolean} getHistoric when true also returns the historic contracts.
   * @returns {Observable<Contracts[]>}
   */
  getContractList(getHistoric: boolean): Observable<Contracts[]> {
    let $contractsAll = this.contractsRestService.getContracts(this.nonHistoricRequestParam);
    if (getHistoric) {
      const $historicContracts = this.contractsRestService.getContracts(this.historicRequestParam);
      $contractsAll = forkJoin([$contractsAll, $historicContracts]).pipe(map(([contractsAll, historicContracts]) => {
        return [...contractsAll, ...historicContracts];
      }));
    }
    return $contractsAll;
  }

  /**
   *
   * @param contractNumber
   * @param equipmentKey
   * @description Get the detail of a contract as contracts view model type
   */
  getContractsDetails(contractNumber: string, equipmentKey: string): Observable<ContractsOverviewViewModel[]> {
    return this.contractsRestService.getContractsDetails(contractNumber, equipmentKey)
      .pipe(map((contractDetailResponse: ContractsDetail[]) => {
        const tempContractsDetailViewModel: ContractsOverviewViewModel[] = [];
        forEach(clone(contractDetailResponse), (item) => {
          const obj: { [k: string]: any } = {};
          obj.value = item.value.join(', ');
          obj.label = item.label;
          obj.groups = item.groups;
          tempContractsDetailViewModel.push(obj as ContractsOverviewViewModel);
        });
        return tempContractsDetailViewModel;
      }));
  }

  /**
   *
   * @description
   * Generate the export array format of contract details.
   *
   * Note:- It is for equipmentName + contract details.
   */
  generateContractDetailsExport(contractDetailFields: ItemDetailViewModel[], contractsDetails: ItemDetailViewModel[]) {
    const exp = [];
    const dateTimePattern = 'DD/MM/YYYY, HH:mm';

    forEach(contractDetailFields, (field) => {
      exp.push(this.getContractDetailViewItem(field.label, field.dateType ?
        this.datePipeWrapperPipe.transform(field.value, dateTimePattern) : field.value));
    });

    forEach(contractsDetails, contractData => {
      exp.push(this.getContractDetailViewItem(contractData.label, contractData.value));
    });

    return exp;
  }

  getEquipmentContracts(equipmentKey: string, getHistoric: boolean): Observable<ContractsViewModel[]> {
    return this.getContractsViewModelList(getHistoric).pipe(map((contractsList) => {
      contractsList.forEach(contract => {
        contract.modality = contract.cmdbEquipment ? contract.cmdbEquipment.modalityTranslation :
          // tslint:disable-next-line:ban
          this.translateService.instant('LABEL_EQUIPMENT_MODALITY_MISSING');
        return contract;
      });
      return filter(contractsList, contract => contract.equipmentKey === equipmentKey);
    }));
  }

  getContractDetailViewItem(label: string, value: string, dateType?: boolean, fieldName?: string): ItemDetailViewModel {
    const obj: { [k: string]: any } = {};
    obj.label = label;
    obj.value = value;
    obj.dateType = dateType;
    obj.fieldName = fieldName;
    return obj as ItemDetailViewModel;
  }

  getContractDetailFields(config: Record<string, string>): ItemDetailViewModel[] {
    const contractDetailFields: ItemDetailViewModel[] = [];
    const configFields = config.CONTRACT_DETAILS_ATTRIBUTES;

    if (!configFields) {
      return contractDetailFields;
    }

    const contractFields = configFields.split(',');
    forEach(contractFields, (field) => {
      if (this.filterContractField(field, config)) {
        return;
      }

      const fieldTranslationKey = this.contractsLabelTranslationMap[field];
      // tslint:disable-next-line:ban
      const fieldTranslation = fieldTranslationKey ? this.translateService.instant(fieldTranslationKey) : undefined;
      if (fieldTranslation && this.selectedItem[field]) {
        contractDetailFields.push(this.getContractDetailViewItem(fieldTranslation, this.selectedItem[field],
          this.checkFieldIsDate(field, fieldTranslationKey), field));
      }
    });

    return contractDetailFields;
  }

  reloadContractDetailFields(contractDetailFields: ItemDetailViewModel[]) {
    forEach(contractDetailFields, (field) => {
      field.value = this.selectedItem[field.fieldName];
    });
  }

  checkFieldIsDate(field: string, fieldTranslationKey: string): boolean {
    return (fieldTranslationKey && fieldTranslationKey.toLowerCase().includes('_date') || field.toLowerCase().includes('date'));
  }

  filterContractField(field: string, config: Record<string, string>): boolean {
    return field === 'contractGroup' &&
      (!this.selectedItem.contractGroup ||
        this.selectedItem.contractGroup === '*' ||
        config && isEqual(config.CONTRACT_GROUP_NUMBER_RENDER, 'false'));
  }
}
