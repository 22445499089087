import { inject, Injectable } from '@angular/core';
import { DotService } from 'app/appointments/dot/services/dot.service';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';
import { defer, mergeMap, Observable, ReplaySubject } from 'rxjs';
import { DotBookableStatus } from 'app/appointments/dot/models/dot-bookable-status';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DotBookableStateService {

  private dotService = inject(DotService);
  private appointmentKeyToStatusSubject: Record<string, ReplaySubject<DotBookableStatus>> = {};

  getAppointmentStatus(appointment: ActivitiesViewModel): Observable<DotBookableStatus> {
    return defer(() => {
      const { appointmentKey } = appointment;

      if (appointmentKey in this.appointmentKeyToStatusSubject) {
        return this.appointmentKeyToStatusSubject[appointmentKey].asObservable();
      }

      return this.getAndForceUpdateAppointmentStatus(appointment);
    });
  }

  getAndForceUpdateAppointmentStatus(appointment: ActivitiesViewModel): Observable<DotBookableStatus> {
    return defer(() => {
      const status$ = this.getStatusSubject(appointment.appointmentKey);

      return this.dotService.getAppointmentStatus(appointment).pipe(
        tap(status => status$.next(status)),
        mergeMap(() => status$)
      );
    });
  }

  private getStatusSubject(appointmentKey: string): ReplaySubject<DotBookableStatus> {
    if (appointmentKey in this.appointmentKeyToStatusSubject) {
      return this.appointmentKeyToStatusSubject[appointmentKey];
    }

    const status$ = new ReplaySubject<DotBookableStatus>(1);
    this.appointmentKeyToStatusSubject[appointmentKey] = status$;
    return status$;
  }

}
