import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotStepHeader',
  standalone: true,
  pure: true
})
export class DotStepHeaderPipe implements PipeTransform {

  transform(step: string): string {
    switch (step) {
      case 'timeframe-specification': return 'DOT_SCHEDULE_TIMEFRAME_SPECIFICATION_HEADER';
      case 'appointment-selection': return 'DOT_SCHEDULE_APPOINTMENT_SELECTION_HEADER';
      case 'contact-details': return 'DOT_SCHEDULE_CONTACT_DETAILS_HEADER';
      case 'appointment-booking': return 'DOT_SCHEDULE_APPOINTMENT_BOOKING_HEADER';
      default: throw new Error('Invalid step!');
    }
  }

}
