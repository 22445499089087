<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  @if (uploadForm) {
    <div class="modal-dialog modal-dialog--form is-open" tabindex="-1" role="dialog" aria-modal="true">
      <div class="modal-dialog__container" role="document">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title" [innerHTML]="'SYSTEM_UPDATES_UPLOAD_PACKAGE' | translate"></h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close"
            (click)="isAllUploaded() ? hide() : cancelUploadUpdateModal.show()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="create-upload-modal-body">
          @if (showValidationMessage) {
            <p class="message message-error">
              <span>{{ 'GENERIC_LABEL_FILL_INFORMATION' | translate }}</span>
            </p>
          }
          <hl-progress-indicator [loading]=isProgressIndicator()></hl-progress-indicator>
          @if (!showSpinner) {
            <form #modalForm="ngForm" class="form" novalidate>
              <div class="row" hlHelperTextForms>
                <div class="col-12 col-lg-8">
                  <hl-textfield label="SYSTEM_UPDATES_UPDATE_NUMBER" name="updateNumber" [isDisabled]="updateNumberDisabled"
                    [isRequired]="uploadForm.controls['updateNumber'].errors && uploadForm.controls['updateNumber'].errors['required']"
                    [isInvalid]="(uploadForm.controls['updateNumber'].invalid && uploadForm.controls['updateNumber'].touched)"
                    [formControl]="uploadForm.controls['updateNumber']" [maxlength]="10" invalidLabel="SYSTEM_UPDATES_UI_NUMBER_NOT_VALID"
                    ngDefaultControl></hl-textfield>
                </div>
                <button [disabled]="true" [hidden]="true" (click)="doNothing()"></button>
                <div class="col-12 col-lg-4">
                  <div class="form__helper-container">
                    <p class="form__helper-text" [innerHtml]="'SYSTEM_UPDATES_UPLOAD_DEFAULT_TEXT_UPDATE_NUMBER' | translate"></p>
                  </div>
                </div>
              </div>
              <div class="row" hlHelperTextForms [selector]="'file-input__button'">
                <div class="col-12 col-lg-8">
                  <hl-upload #filePackageForm [parentForm]="uploadForm" [isFormSubmitted]="isFormSubmitted" [checkAttachmentDisable]="true"
                    [disableAttachmentCount]="1" [parentFormFileControlKey]="FILE_PACKAGE" [disableCancel]="disableCancelPackageUpload"
                    genericLabelName="SYSTEM_UPDATES_UPLOAD_PACKAGE_FILE"
                    (resetProgressBar)="resetProgressBarsToDefaultAndClearConsole()"></hl-upload>
                  @if (uploadForm.controls['packageFile'].errors && uploadForm.controls['packageFile'].errors['invalidName']) {
                    <small class="textfield__hint textfield__hint--validation"
                      [translate]="'SYSTEM_UPDATES_UPLOAD_PACKAGE_FILE_NAME_NOT_VALID'"></small>
                  }
                  @if (packageProgress) {
                    <div class="progress">
                      <div class="progress__bar" [style.width]="packageUploadProgress + '%'" [attr.aria-valuenow]="packageUploadProgress"
                        aria-valuemin="0" aria-valuemax="100" [attr.aria-invalid]="packageUploadFail || packageUploadCancelled">
                        {{ getPackageUploadProgress() }}
                      </div>
                    </div>
                  }
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form__helper-container">
                    <p class="form__helper-text" [innerHTML]="'TEXT_VALIDATION_FILE_PACKAGE' | translate"></p>
                  </div>
                </div>
              </div>
              <div class="row" hlHelperTextForms [selector]="'file-input__button'">
                <div class="col-12 col-lg-8">
                  <hl-upload #filePackageInfoForm [parentForm]="uploadForm" [isFormSubmitted]="isFormSubmitted" [checkAttachmentDisable]="true"
                    [disableAttachmentCount]="1" [parentFormFileControlKey]="FILE_PACKAGE_INFO" [disableCancel]="disableCancelPackageInfoUpload"
                    genericLabelName="SYSTEM_UPDATES_UPLOAD_PACKAGE_INFO"
                    (resetProgressBar)="resetProgressBarsToDefaultAndClearConsole()"></hl-upload>
                  @if (isPackageInfoFileInvalid()) {
                    <small class="textfield__hint textfield__hint--validation" [translate]="getPackageInfoErrorMessage()"></small>
                  }
                  @if (packageInfoProgress) {
                    <div class="progress">
                      <div class="progress__bar" [style.width]="packageInfoUploadProgress + '%'" [attr.aria-valuenow]="packageInfoUploadProgress"
                        aria-valuemin="0" aria-valuemax="100" [attr.aria-invalid]="packageInfoUploadFail  || packageInfoUploadCancelled">
                        {{ getPackageInfoUploadProgress() }}
                      </div>
                    </div>
                  }
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form__helper-container">
                    <p class="form__helper-text" [innerHTML]="'TEXT_VALIDATION_FILE_PACKAGE_INFO' | translate"></p>
                  </div>
                </div>
              </div>
              <div class="row" hlHelperTextForms [selector]="'file-input__button'">
                <div class="col-12 col-lg-8">
                  <hl-upload #fileHowToForm [parentForm]="uploadForm" [isFormSubmitted]="isFormSubmitted" [checkAttachmentDisable]="true"
                    [disableAttachmentCount]="1" [parentFormFileControlKey]="FILE_HOW_TO" [disableCancel]="disableCancelHowToUpload"
                    genericLabelName="SYSTEM_UPDATES_UPLOAD_HOW_TO_XML" (resetProgressBar)="resetProgressBarsToDefaultAndClearConsole()"></hl-upload>
                  @if (isHowToFileInvalid()) {
                    <small class="textfield__hint textfield__hint--validation" [translate]="getHowToValidationErrorMessage()"></small>
                  }
                  @if (howToProgress) {
                    <div class="progress">
                      <div class="progress__bar" [style.width]="howToUploadProgress + '%'" [attr.aria-valuenow]="howToUploadProgress"
                        aria-valuemin="0" aria-valuemax="100" [attr.aria-invalid]="howToUploadFail || howToUploadCancelled">
                        {{ getHowToUploadProgress() }}
                      </div>
                    </div>
                  }
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form__helper-container">
                    <p class="form__helper-text" [innerHTML]="'TEXT_VALIDATION_FILE_HOW_TO' | translate"></p>
                  </div>
                </div>
              </div>
              <div class="row" hlHelperTextForms [selector]="'file-input__button'">
                <div class="col-12 col-lg-8">
                  <hl-upload #fileChecksumForm [parentForm]="uploadForm" [isFormSubmitted]="isFormSubmitted" [checkAttachmentDisable]="true"
                    [disableAttachmentCount]="1" [parentFormFileControlKey]="FILE_CHECKSUM" [disableCancel]="disableCancelCheckSumUpload"
                    genericLabelName="SYSTEM_UPDATES_UPLOAD_FILE_CHECKSUM"
                    (resetProgressBar)="resetProgressBarsToDefaultAndClearConsole()"></hl-upload>
                  @if (isChecksumFileInvalid()) {
                    <small class="textfield__hint textfield__hint--validation" [translate]="getChecksumErrorMessage()"></small>
                  }
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form__helper-container">
                    <p class="form__helper-text" [innerHTML]="'SYSTEM_UPDATES_UPLOAD_CHECKSUM_TEXT_VALIDATION' | translate"></p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-8">
                @if (showConsoleLog) {
                  <hl-textarea #ConsoleLog [name]="'Console log'" [labelText]="'Log output'" [isLarge]="true" [isDisabled]="true" [isReadonly]="true"
                    [rows]="6"></hl-textarea>
                }
              </div>
            </form>
          }
        </div>
        @if (!showSpinner) {
          <div class="modal-dialog__footer">
            @if (!isAllUploaded()) {
              <button type="button" class="button" [disabled]="isCommitProcess || isRollbackProcess" (click)="cancelUploadUpdateModal.show()"
                [translate]="'GENERIC_LABEL_CANCEL'"></button>
              <button class="button button--primary" [disabled]="isSubmitFormDisabled()" (click)="postFormData()"
                [translate]="'GENERIC_LABEL_UPLOAD'"></button>
            } @else {
              <button class="button button--primary" (click)="hide()" [disabled]="uploadSuccessSpinner" [translate]="'GENERIC_LABEL_CLOSE'"></button>
            }
          </div>
        }
      </div>
    </div>
  }

  <hl-cancel-modal (yesClick)="hide()" #cancelUploadUpdateModal [descriptionMessage]="'SYSTEM_UPDATES_UPLOAD_CONFIRM_CANCELLATION'"></hl-cancel-modal>

  <hl-locked-upload-update-modal (close)="hide()" (rollbackAndClean)="rollbackFailAndClean($event)" (rollbackAndUpload)="rollbackAndUpload($event)"
    (uploadSuccessSpinner)="setUploadSuccessSpinner($event)" (disableFileSelection)="disableCancelForSelectedFiles($event)"
    (isUpdateLockedByOtherUser)="setUpdateLockedByOtherUser($event)" #lockedUploadUpdateModal></hl-locked-upload-update-modal>

  <hl-exists-upload-update-modal (uploadUpdateExists)="startUpload($event)" (rollbackAndClean)="rollbackCancelAndClean($event)"
    #existsUploadUpdateModal></hl-exists-upload-update-modal>
</ng-template>
