import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { Observable } from 'rxjs';
import { UserAuditTrail } from '../models/auditTrail/user-audit-trail';

@Injectable({providedIn: 'root'})
export class UserAuditTrailRestService {
    userAuditTrailNotificationsShowRestName = restEndPoint + 'users/self/audit';
    userAuditTrailNotificationsClosedRestName = restEndPoint + 'users/self';
  constructor(private http: CacheableHttpClient) {
  }

  showNotificationsSubscription(): Observable<UserAuditTrail> {
      return this.http.get<any>(this.userAuditTrailNotificationsShowRestName);
  }

  postNotificationSubscription(userAuditTrail: UserAuditTrail): Observable<any> {
      return this.http.post(this.userAuditTrailNotificationsClosedRestName, userAuditTrail);
  }
}
