<div hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></div>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="edit-equipment-modal">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" translate="GENERIC_LABEL_EDIT"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc" data-cy="edit-dialog-modal">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          <form #modalForm="ngForm">
            <hl-textfield label="GENERIC_LABEL_MY_EQUIPMENT_NAME" name="myEquipmentName" isActive="equipmentEditItem.myEquipmentName ? true : null"
              [(ngModel)]="equipmentEditItem.myEquipmentName" [maxlength]="30" ngDefaultControl data-cy="myequipment-name"></hl-textfield>
            <hl-textfield label="GENERIC_LABEL_DEPARTMENT" name="department" isActive="equipmentEditItem.department ? true : null"
              [(ngModel)]="equipmentEditItem.department" [maxlength]="40" ngDefaultControl></hl-textfield>
            @if (updateCustomerDescription) {
              <hl-textfield [maxlength]="30" name="customerDescription" isActive="equipmentEditItem.customerDescription ? true : null"
                [(ngModel)]="equipmentEditItem.customerDescription" ngDefaultControl></hl-textfield>
            }
            @for (row of addressFields; track row; let i = $index) {
              <hl-textfield [label]="row.label" [name]="row.name" isActive="equipmentEditItem[row.attribute] ? true : null"
                [isDisabled]="addressFieldsDisabled" [(ngModel)]="equipmentEditItem[row.attribute]" [ngModelOptions]="{standalone: true}"
                ngDefaultControl></hl-textfield>
            }
          </form>
        }
      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="''" [modalRef]="simulatorModal"
          [isFormValidationRequired]="false" [isModalType]="true" [label]="'GENERIC_LABEL_SAVE'" name="isFormSubmitted" ngDefaultControl
          dataCyCancelButton="edit-equipment" dataCySaveButton="save-edit-equipment"></hl-simulator-check>
      }
    </div>
  </div>

  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
