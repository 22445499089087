export enum ConfigurationAggregateType {
  SUM = 'sum',
  SUBTRACTION = 'subtraction',
  MEAN = 'mean',
  PERCENTAGE = 'percentage',
  RATIO = 'ratio',
  REVERT_SUM = 'revert_sum',
  REVERT_MEAN = 'revert_mean',
  REVERT_RATIO = 'revert_ratio',
  REVERT_PERCENTAGE = 'revert_percentage'
}

export enum ConfigurationAggregateTypeTranslations {
  REVERT_SUM = '1 - sum(selection in KPI list)',
  REVERT_MEAN = '1 - mean(selection in KPI list)',
  REVERT_RATIO = '1 - ratio in percentage(selection in KPI list)',
  REVERT_PERCENTAGE = '1 - (single KPI from KPI list)',
  RATIO_PERCENTAGE = 'ratio in percentage',
  REVERT_GROUP = 'revert percentage'
}
