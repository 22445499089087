<div [formGroup]="productForm">
  <hl-selectbox (valueChange)="handleProductAreaChange($event)" [formControl]="productForm.controls['modality']" [options]="modalities"
    [isRequired]="productForm.controls['modality'].errors && productForm.controls['modality'].errors['required']" label="LABEL_PSR_PRODUCT_AREA"
    ngDefaultControl></hl-selectbox>
  @if (isFormSubmitted && !productForm.controls['modality'].valid) {
    <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
  }
  <ul class="list list--inline bare-list">
    <li class="list__item">
      <input class="radio radio--inline" id="psrsystem" type="radio" formControlName="selectProduct" value="true"
        (change)="handleSelectProductChange()"/>
      <label for="psrsystem" [translate]="'LABEL_PSR_SELECT_SYSTEM'" (click)="toggleSelectProduct('true')"></label>
    </li>
    <li class="list__item">
      <input class="radio radio--inline" id="psrnosystem" type="radio" formControlName="selectProduct" value="false"
        (change)="handleSelectProductChange()"/>
      <label for="psrnosystem" [translate]="'LABEL_PSR_SELECT_NO_SYSTEM'" (click)="toggleSelectProduct('false')"></label>
    </li>
  </ul>
  <hl-selectbox [formControl]="productForm.controls['equipmentKey']" [options]="productDropdownoptions" label="LABEL_PSR_PRODUCT"
    [isRequired]="productForm.controls['selectProduct'] && productForm.controls['equipmentKey'].errors && productForm.controls['equipmentKey'].errors['required']"
    [isDisabled]="!productForm.controls['selectProduct']" ngDefaultControl></hl-selectbox>
  @if (isFormSubmitted && !productForm.controls['equipmentKey'].valid) {
    <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
  }
  <p>
    <input type="checkbox" class="checkbox" [formControl]="productForm.controls['multipleProducts']" id="multipleProducts">
    <label for="multipleProducts" [translate]="'LABEL_PSR_MULTIPLE_SYSTEMS'"></label>
  </p>
  <hl-selectbox [formControl]="productForm.controls['deliveryMethod']" [options]="deliveryMethods" label="LABEL_PSR_DELIVERY_METHOD" ngDefaultControl
    [isRequired]="productForm.controls['deliveryMethod'].errors && productForm.controls['deliveryMethod'].errors['required']"></hl-selectbox>
  @if (isFormSubmitted && !productForm.controls['deliveryMethod'].valid) {
    <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
  }
  <hl-textfield label="LABEL_PSR_RELEASE" name="softwareVersion" [formControl]="productForm.controls['softwareVersion']" [maxlength]="10"
    placeholder="LABEL_OPTIONAL" ngDefaultControl></hl-textfield>
</div>
