import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { WhiteListAdminRestService } from 'app/core/rest-services/white-list-admin-rest.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'hl-asu-add-whitelist-entry-modal',
  templateUrl: './asu-add-whitelist-entry-modal.component.html'
})
export class AsuAddWhitelistEntryModalComponent extends BaseModalPopup implements OnInit {

  addEntryForm: UntypedFormGroup;
  isFormSubmitted = false;

  constructor(
    private toasterService: ToasterService,
    private whiteListAdminRestService: WhiteListAdminRestService,
    private fb: UntypedFormBuilder,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.isFormSubmitted = false;
    this.addEntryForm = this.fb.group({
      materialNumber: ['', Validators.required],
      serialNumber: ['', Validators.required]
    });
  }

  ok() {
    this.isFormSubmitted = true;
    if (this.addEntryForm.valid) {
      this.showSpinner = true;
      this.whiteListAdminRestService
        .addAsuWhitelistEntries([
          {
            material: this.addEntryForm.get('materialNumber').value,
            serial: this.addEntryForm.get('serialNumber').value
          }])
        .pipe(finalize(() => this.hide()))
        .subscribe(() => this.toasterService.showTranslatedSuccessToaster('LABEL_ADD_ENTRY_SUCCESS'));
      this.hide();
    }
  }

  hide() {
    this.ngOnInit();
    super.hide();
  }

}
