<section class="datalist overflow-hidden">
  @if (collapsibleEventLogs.length) {
    @if (showCollapseAll) {
      <button class="button button--small" (click)="collapseAll()" [translate]="'LABEL_CALENDAR_CLOSE_ALL'"></button>
    }
    @if (showExpandAll) {
      <button class="button button--small" (click)="expandAll()" [translate]="'LABEL_CALENDAR_OPEN_ALL'"></button>
    }
  }

  <dl hlCollapsable [title]="'.collapsable__title'" class="accordion" role="presentation">
    @for (eventLog of eventLogs; track eventLog) {
      @if (eventLog.serviceEngineer || eventLog.longText) {
        <dt aria-level="3" class="accordion__header">
          @if (eventLog.endDateTime && isTaskTypeSupported(eventLog.taskID)) {
            <button type="button" class="accordion__title collapsable__title icon-arrow-down-large" (click)="toggleStatus(eventLog)"
              aria-controls="panel1" aria-expanded="true">
              {{ getServiceEventLogTimestamp(eventLog) | datePipeWrapper : dateTimePattern }}
              @if (getServiceEventLogTimestamp(eventLog) && eventLog.taskDescription) {
                &ndash;
              }
              @if (eventLog.taskDescription) {
                {{ eventLog.taskDescription }}
              }
            </button>
          } @else {
            <button type="button" class="accordion__title collapsable__title icon-arrow-down-large" (click)="toggleStatus(eventLog)"
              aria-controls="panel1" aria-expanded="false">
              {{ getServiceEventLogTimestamp(eventLog) | datePipeWrapper : dateTimePattern }}
              @if (getServiceEventLogTimestamp(eventLog) && eventLog.taskDescription) {
                &ndash;
              }
              @if (eventLog.taskDescription) {
                {{ eventLog.taskDescription }}
              }
            </button>
          }
        </dt>
      } @else {
        <dt aria-level="3" class="accordion__header">
          <button type="button" class="accordion__title accordion__title--no-icon" (click)="toggleStatus(eventLog)" aria-controls="panel1"
            aria-expanded="false">
            {{ getServiceEventLogTimestamp(eventLog) | datePipeWrapper : dateTimePattern }}
            @if (getServiceEventLogTimestamp(eventLog) && eventLog.taskDescription) {
              &ndash;
            }
            @if (eventLog.taskDescription) {
              {{ eventLog.taskDescription }}
            }&nbsp;
          </button>
        </dt>
      }
      @if (eventLog.endDateTime && (eventLog.serviceEngineer || eventLog.longText) && isTaskTypeSupported(eventLog.taskID)) {
        <dd class="accordion__panel" aria-labelledby="panel1-button" role="region" aria-hidden="false">
          @if (eventLog.serviceEngineer || eventLog.endDateTime) {
            <p>
              @if (eventLog.serviceEngineer) {
                <span>{{ 'LABEL_TICKET_TASK_ENGINEER' | translate }}:&nbsp;{{ eventLog.serviceEngineer }}</span>
              }
              @if (eventLog.endDateTime) {
                <span>
                    @if (eventLog.serviceEngineer) {
                      <br/>
                    }
                  {{ 'LABEL_EXPECTED_STARTTIME' | translate }}:&nbsp;{{ eventLog.endDateTime | datePipeWrapper : dateTimePattern }} </span>
              }
            </p>
          }
          @if (eventLog.longText) {
            <p>{{ eventLog.longText }} </p>
          }
        </dd>
      } @else {
        <dd class="accordion__panel" aria-labelledby="panel1-button" role="region" aria-hidden="true">
          @if (eventLog.serviceEngineer) {
            <p>
              {{ 'LABEL_TICKET_TASK_ENGINEER' | translate }}:&nbsp;{{ eventLog.serviceEngineer }} </p>
          }
          @if (eventLog.longText) {
            <p>
              {{ eventLog.longText }} </p>
          }
        </dd>
      }
    }
  </dl>
</section>
