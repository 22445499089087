@if (steps().length) {
  <div class="checkout-steps margin-bottom-tiny-5" [class.is-disabled]="disabled()">
    @for (step of steps(); track step; let i = $index) {
      <div class="checkout-steps__item" [class.is-completed]="isCompleted(i)" [class.is-current]="isActive(i)">
        <div class="checkout-steps__number">{{step.displayNumber}}</div>
        <div class="checkout-steps__title" [class.hidden-md-down]="!isActive(i)">{{step.title | translate}}</div>
      </div>
    }
  </div>
}
