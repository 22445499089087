import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { localeSplitter } from '../../../core/core-constants.service';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { User } from '../../../core/models/user';
import { ImpersonationRestService } from '../../../core/rest-services/impersonation-rest.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { ImpersonationUtilsService } from '../../../core/utils/impersonation-utils.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { WalkMeUtilService } from '../../../core/utils/walk-me-util.service';
import { CustomerGroupViewModel } from '../../../core/view-models/customer-group-view-model';
import { CustomerViewModel } from '../../../core/view-models/customer-view-model';
import { includes, uniqBy } from 'lodash-es';
import { ImpersonationInput } from '../../../core/models/impersonation/impersonation-input';
import { DomUtilService } from '../../../core/utils/dom-util.service';
import { MedalliaUtilService } from '../../../core/utils/medallia-util.service';

@Component({
  selector: 'hl-multi-country-impersonation-modal',
  templateUrl: './multi-country-impersonation-modal.component.html'
})
export class MultiCountryImpersonationModalComponent extends BaseFormModalPopup
  implements OnInit {

  @Input()
  impersonatedCustomers: CustomerViewModel[];
  @Input()
  impersonatedCustomersGroups: CustomerGroupViewModel[];

  user: User = null;
  selectedCountry: string;
  selectedLocale: string;
  countries: DropdownOptions[] = [];
  languages: DropdownOptions[] = [];

  constructor(
    private lifeNetUtilService: LifeNetUtilService,
    private userUtilService: UserUtilService,
    private impersonationUtilService: ImpersonationUtilsService,
    private impersonationRestService: ImpersonationRestService,
    private filterUtilService: FilterUtilService,
    private walkMeUtilService: WalkMeUtilService,
    private medalliaUtilService: MedalliaUtilService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.setPropertiesFromUser();
  }

  setPropertiesFromUser() {
    this.userUtilService.getUser().subscribe((user: User) => {
      // assignment of public variable
      this.user = user;
    });
  }

  postFormData() {
    this.showSpinner = true;

   // list of customer ids
    const customerIds = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      this.impersonatedCustomers,
      'customerId'
    );

    // list of group ids
    const groupIds = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      this.impersonatedCustomersGroups,
      'groupId'
    );

    const localeSplit = this.selectedLocale.split(localeSplitter);

    const impersonationInput: ImpersonationInput = {
      language: localeSplit[0],
      country: this.selectedCountry, // cannot use the value of locale
      customerIds,
      groupIds
    };

    this.walkMeUtilService.setLanguageAndCountry(window, impersonationInput.language, impersonationInput.country);
    this.medalliaUtilService.update();
    DomUtilService.replaceUnsupportedFont(impersonationInput.language);

    this.impersonationRestService
      .postStartImpersonation(impersonationInput).pipe(
      finalize(() => {
        this.hide();
      }))
      .subscribe(() => {
        // after successful
        this.lifeNetUtilService.setTranslationFile(this.selectedLocale);
        this.lifeNetUtilService.setConfigurationFile(this.selectedCountry);

        this.impersonationUtilService.onSuccessfulImpersonation();
      });
  }

  show() {
    this.setPredefinedCountryAndLocale();

    super.show();
  }

  setPredefinedCountryAndLocale() {
    const distinctListOfCustomerCountries = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      this.impersonatedCustomers,
      'country'
    );

    const distinctListOfCustomerGroupsCountries = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      this.impersonatedCustomersGroups,
      'country'
    );

    /**
     * Check if the users country in the list of impersonated customers.
     * If not select the first from the list as pre-selected.
     */
    if (
      includes(distinctListOfCustomerCountries, this.user.country) ||
      includes(distinctListOfCustomerGroupsCountries, this.user.country)
    ) {
      this.selectedCountry = this.user.country;
      this.selectedLocale = this.impersonationUtilService.getLocaleWithFallbackCheck(
        this.user.language,
        this.user.country,
        this.user.countries
      );
    } else {
      // select first country in list
      // if no element in customer countries then select first from
      // customer groups country list
      if (distinctListOfCustomerCountries.length > 0) {
        this.selectedCountry = distinctListOfCustomerCountries[0];
      } else {
        this.selectedCountry = distinctListOfCustomerGroupsCountries[0];
      }

      const selectedLocale = this.impersonationUtilService.getDefaultLanguageForCountry(
        this.user.countries,
        this.selectedCountry
      );

      if (selectedLocale) {
        this.selectedLocale = selectedLocale;
      }
    }

    // list of countries
    const countriesToBeTrans = [];
    Array.prototype.push.apply(
      countriesToBeTrans,
      this.impersonationUtilService.generateCountryTranslationMapping(
        this.impersonatedCustomers,
        true
      )
    );

    Array.prototype.push.apply(
      countriesToBeTrans,
      this.impersonationUtilService.generateCountryTranslationMapping(
        this.impersonatedCustomersGroups,
        true
      )
    );

    this.countries = uniqBy(countriesToBeTrans, 'title');

    // list of corresponding locales
    this.languages = this.impersonationUtilService.generateLanguageTranslationMappingAndAddDefaultLang(
      this.user.countries,
      this.selectedCountry
    );
  }

  /**
   * @description
   * Handles the event when country has changed so that the corresponding
   * language is also adapted and pre-selected default language in the drop down.
   */
  updateLanguageOnCountryChange() {
    this.languages = this.impersonationUtilService.generateLanguageTranslationMappingAndAddDefaultLang(
      this.user.countries,
      this.selectedCountry
    );

    // pick the default language for the selected country if selectedLocale is empty or is not in the languages
    const isLanguageInList = this.selectedLocale
      && !!this.languages.find(option => option.value === this.selectedLocale);

    if (!this.selectedLocale || !isLanguageInList) {
      const selectedLocale = this.impersonationUtilService.getDefaultLanguageForCountry(
        this.user.countries,
        this.selectedCountry
      );

      if (selectedLocale) {
        this.selectedLocale = selectedLocale;
      }
    }
  }
}
