import { psrRestName, psrRestTypes, psrRwfRestName, psrTemplateRestName, rwfAvailableTimeSlots, rwfBookingRequestSlots, rwfCancelBookedSlot, rwfContactRestName, rwfContactsRestName } from './psr-rest-constants.service';
import { PsrTypeEnum } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfessionalServiceRequest } from '../models/psr/professional-service-request';
import { HttpParams } from '@angular/common/http';
import { PsrType } from 'app/core/models/psr/psr-status-type';
import { PsrTemplateByModality } from '../models/psr/psr-template-by-modality';
import { RemoteWorkForceRequest } from '../models/psr/remote-work-force-request';
import { DateUtilService } from '../utils/date-util.service';
import { RwfTimeSlotRequest } from '../models/psr/rwf-time-slot-request';
import { RwfAppointmentStateViewModel, RwfTimeSlots } from '../models/psr/rwf-time-slots';
import { RwfBookingStatus } from '../models/psr/rwf-booking-status';
import { map } from 'rxjs/operators';
import { RwfSlotCancellationStatus } from '../models/psr/rwf-slot-cancellation-status';
import { WeScanContact } from '../../psr/wescan/wescan-contact-model/we-scan-contact';

@Injectable({providedIn: 'root'})
export class PsrRestService {

  constructor(private http: CacheableHttpClient,
    private dateUtilService: DateUtilService) {
  }

  /**
   * Makes http post request to create a psr
   */
  createPsr(body) {
    this.http.clearCache(psrRestName);
    return this.http.post(psrRestName, body);
  }

  /**
   * Makes http request and returns an Professional Service request list
   *
   * @returns {Observable<ProfessionalServiceRequest[]>}
   */
  getPsrList(): Observable<ProfessionalServiceRequest[]> {
    return this.http.get<ProfessionalServiceRequest[]>(psrRestName);
  }

  /**
   * Makes http request and returns an Professional Service request list
   *
   * @returns {Observable<ProfessionalServiceRequest[]>}
   */
  getPsrTemplates(): Observable<PsrTemplateByModality[]> {
    return this.http.get<PsrTemplateByModality[]>(psrTemplateRestName);
  }

  /**
   * Makes http request and returns an observable of  PsrType array
   *
   * @returns {PsrType[]}
   */
  getPsrTypeCode(type: PsrTypeEnum): Observable<PsrType[]> {
    const httpParams = new HttpParams().set('type', type);
    return this.http.get(psrRestTypes, {params: httpParams});
  }

  postPsrActions(url: string, body: any) {

    return this.http.post(url, body);
  }

  getRemoteWorkForceRequests(): Observable<RemoteWorkForceRequest[]> {
    return this.http.get<RemoteWorkForceRequest[]>(psrRwfRestName);
  }

  getAvailableTimeSlots(body: RwfTimeSlotRequest): Observable<RwfTimeSlots> {
    return this.http.post<{ result: [{ startDateTime: string, endDateTime: string }] }>(rwfAvailableTimeSlots, body)
      .pipe(map(r => ({
        result: r.result.map(slot => ({
          startDateTime: this.dateUtilService.convertUtcToLocal(slot.startDateTime),
          endDateTime: this.dateUtilService.convertUtcToLocal(slot.endDateTime)
        }))
      })));
  }

  getBookingRequestTimeSlots(id: string): Observable<RwfAppointmentStateViewModel[]> {
    return this.http.get(rwfBookingRequestSlots, {
      params: new HttpParams().set('id', id)
    }).pipe(map((r: { result: any[] }) => r.result.map((slot) => ({
        id: slot.id,
        state: slot.state,
        actualStartTime: this.dateUtilService.convertUtcToLocal(slot.actualStartDateTime),
        actualEndTime: this.dateUtilService.convertUtcToLocal(slot.actualEndDateTime),
        startDateTime: this.dateUtilService.convertUtcToLocal(slot.startDateTime),
        endDateTime: this.dateUtilService.convertUtcToLocal(slot.endDateTime)
      }))
    ));
  }

  createRwf(body): Observable<RwfBookingStatus> {
    this.http.clearCache(psrRwfRestName);
    return this.http.post<RwfBookingStatus>(psrRwfRestName, body);
  }

  cancelBookedTimeSlot(slotId: string): Observable<RwfSlotCancellationStatus> {
    return this.http.post<RwfSlotCancellationStatus>(rwfCancelBookedSlot, {slotId});
  }

  clearBookingRequestSlotsByIdCache(id: string) {
    this.http.clearCache(rwfBookingRequestSlots, {
      params: new HttpParams().set('id', id)
    });
  }

  createContact(body: WeScanContact): Observable<any> {
    return this.http.post<WeScanContact>(rwfContactRestName, body);
  }

  deleteContact(id: number): Observable<any> {
    return this.http.delete(rwfContactRestName, {params: new HttpParams().set('id', id)});
  }

  getContactsList(): Observable<WeScanContact[]> {
    return this.http.get<WeScanContact[]>(rwfContactsRestName, {cache: false});
  }
}
