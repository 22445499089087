<div hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></div>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true" (keydown.escape)="hide()">
    <div class="modal-dialog__container" role="document" data-cy="submit-po-number-modal">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" translate="PROVIDE_PO_HEADLINE_LABEL"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Cancel" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

      @if (!showSpinner) {
        <div class="col-12 col-md-12">
          <div class="multi-column multi-column-sm-2 multi-column-lg-2">
            <div class="multi-column__item" data-cy="submit-po-number-functional-location">
              <p class="font-size-xsmall margin-bottom-0 color-black-60" translate="SIEMENS_EQUIPMENT_ID"></p>
              <p>{{ viewModelInvoice.siemensId || '-' }}</p>
            </div>
            <div class="multi-column__item">
              <p class="font-size-xsmall margin-bottom-0 color-black-60" translate="GENERIC_LABEL_CUSTOMER_NAME"></p>
              <p>{{ viewModelInvoice.customerName || '-' }}</p>
            </div>
            <div class="multi-column__item">
              <p class="font-size-xsmall margin-bottom-0 color-black-60" translate="GENERIC_LABEL_TICKET_NUMBER"></p>
              <p>{{ viewModelInvoice.ticketNumber || '-' }}</p>
            </div>
            <div class="multi-column__item">
              <p class="font-size-xsmall margin-bottom-0 color-black-60" translate="SHORT_DESCRIPTION"></p>
              <p>{{ viewModelInvoice.description || '-' }}</p>
            </div>
          </div>
          <form [formGroup]="submitPoNumberForm">
            <div>
              <hl-textarea [parentForm]="submitPoNumberForm" [name]="'poNumber'" [labelText]="'LABEL_SUBMIT_PO_NUMBER_PLACEHOLDER'" [isLarge]="true"
                [maxlength]="35" [rows]="2" [isInvalid]="isFormSubmitted && !submitPoNumberForm.controls['poNumber'].valid"
                [isRequired]="submitPoNumberForm.controls['poNumber'].errors && submitPoNumberForm.controls['poNumber'].errors['required']"
                data-cy="submit-po-number-poNumber"></hl-textarea>
              @if (isFormSubmitted && !submitPoNumberForm.controls['poNumber'].valid) {
                <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
              }
            </div>
          </form>
        </div>
      }

      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button" translate="GENERIC_LABEL_CANCEL" (click)="hide()"></button>
          <button class="button button--primary" (click)="ok()" [disabled]="false" translate="LABEL_PRELIMINARY_BUTTON_SUBMIT_PO_NUMBER"
            data-cy="submit-po-number"></button>
        </div>
      }
    </div>
  </div>
</ng-template>
