<ul class="bare-list info-list">
  @if (propertiesToDisplay().includes('appointmentNumber') && item().appointmentNumber) {
    <li class="info-list__item label-{{(item() | getActivityStatusColor).colorClass}}"
        [class.label-status-no-cursor]="!hasAction()">{{item().appointmentNumber}}</li>
  } @else if (propertiesToDisplay().includes('ticketNumber') && item().ticketNumber) {
    <li class="info-list__item label-{{(item() | getActivityStatusColor).colorClass}}"
        [class.label-status-no-cursor]="!hasAction()">{{item().ticketNumber}}</li>
  }

  @if (propertiesToDisplay().includes('productName') && item().productName) {
    <li class="info-list__item">
      <i class="icon-label" hlTooltip [documentOverlay]="true" aria-hidden="true" [title]="'GENERIC_LABEL_PRODUCT_NAME' | translate"
         [attr.data-cy]="'activity-' + (index() + 1) + '-product-name-icon'"></i>
      {{item().productName}}
    </li>
  }

  @if (propertiesToDisplay().includes('siemensId') && item().siemensId) {
    <li class="info-list__item">
      <i class="icon-id-card" hlTooltip [documentOverlay]="true" aria-hidden="true" [title]="'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID' | translate"
         [attr.data-cy]="'activity-' + (index() + 1) + '-equipment-id-icon'"></i>
      {{item().siemensId}}
    </li>
  }

  @if (propertiesToDisplay().includes('myEquipmentName') && item().myEquipmentName) {
    <li class="info-list__item">
      <i class="icon-equipment-name" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i>
      {{item().myEquipmentName}}
    </li>
  }

  @if (propertiesToDisplay().includes('customerName') && item().customerName) {
    <li class="info-list__item">
      <i class="icon-user" hlTooltip [documentOverlay]="true" aria-hidden="true" [title]="'GENERIC_LABEL_CUSTOMER_NAME' | translate"
         [attr.data-cy]="'activity-' + (index() + 1) + '-customer-name-icon'"></i>
      <span hlTooltip [documentOverlay]="true" [title]="item().customerName" [isActive]="item().customerName.length > this.customerNameLength()">
        {{item().customerName | truncate:[this.customerNameLength()]}}
      </span>
    </li>
  }

  @if (propertiesToDisplay().includes('plannedStart') && item().plannedStart) {
    <li class="info-list__item">
      <i class="icon-time" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'PLANNED_ACTIVITIES_START_DATETIME' | translate"></i>
      <span> {{item().plannedStart | datePipeWrapper: dateTimePattern()}}</span>
    </li>
  }

  @if (propertiesToDisplay().includes('plannedEnd') && item().plannedEnd) {
    <li class="info-list__item">
      <i class="icon-time" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'PLANNED_ACTIVITIES_END_DATETIME' | translate"></i>
      <span> {{item().plannedEnd | datePipeWrapper: dateTimePattern()}}</span>
    </li>
  }

  @if (propertiesToDisplay().includes('dueDate') && item().dueDate) {
    <li class="info-list__item">
      <i class="icon-time" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'LABEL_ACTIVITY_DUE_DATE' | translate"
         [attr.data-cy]="'activity-' + (index() + 1) + '-due-date-icon'"></i>
      <span> {{item().dueDate | datePipeWrapper: datePattern()}}</span>
    </li>
  }

  @if (propertiesToDisplay().includes('completedDate') && item().completedDate) {
    <li class="info-list__item">
      <i class="icon-time" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'LABEL_COMPLETION_DATE' | translate"></i>
      <span> {{item().completedDate | datePipeWrapper: datePattern()}}</span>
    </li>
  }

  @if (propertiesToDisplay().includes('status')) {
    <li class="info-list__item">
      <i class="icon-information" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_STATUS' | translate"
         [attr.data-cy]="'activity-' + (index() + 1) + '-status-icon'"></i>{{(item() | getActivityStatusColor).label | translate}}
    </li>
  }

  @if (propertiesToDisplay().includes('duration') && item().duration) {
    <li class="info-list__item">
      <i class="icon-duration" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'LABEL_DURATION' | translate"></i>
      {{item().duration / 3600 | number: '1.0-1'}} {{'LABEL_DURATION_HOURS' | translate}}
    </li>
  }
</ul>
