<form class="form" novalidate>
  <div class="row">
    <div class="col-12 col-lg-10">
      <hl-textfield label="GENERIC_LABEL_PRODUCT_NAME" name="productName"
        [isRequired]="newEquipmentForm.controls['productName'].errors && newEquipmentForm.controls['productName'].errors['required']"
        [isInvalid]="isFormSubmitted && newEquipmentForm.controls['productName'].invalid"
        [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"
        [formControl]="newEquipmentForm.controls['productName']" [maxlength]="50"
        ngDefaultControl></hl-textfield>
      <hl-textfield label="LABEL_MATERIAL_NUMBER" name="materialNumber"
        [isRequired]="newEquipmentForm.controls['materialNumber'].errors && newEquipmentForm.controls['materialNumber'].errors['required']"
        [isInvalid]="isFormSubmitted && newEquipmentForm.controls['materialNumber'].invalid"
        [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"
        [formControl]="newEquipmentForm.controls['materialNumber']" [maxlength]="18"
        ngDefaultControl></hl-textfield>
      <hl-textfield label="GENERIC_LABEL_SERIAL_NUMBER" name="serialNumber"
        [isRequired]="newEquipmentForm.controls['serialNumber'].errors && newEquipmentForm.controls['serialNumber'].errors['required']"
        [isInvalid]="isFormSubmitted && newEquipmentForm.controls['serialNumber'].invalid"
        [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"
        [formControl]="newEquipmentForm.controls['serialNumber']" [maxlength]="18"
        ngDefaultControl></hl-textfield>
      <hl-textfield label="GENERIC_LABEL_SECRET_KEY" name="secretKey"
        [formControl]="newEquipmentForm.controls['secretKey']" [maxlength]="30"
        ngDefaultControl></hl-textfield>
      @if (isFormSubmitted && matAndSerialNumberConflict) {
        <small class="textfield__hint textfield__hint--validation"
          [translate]="'LABEL_EQUIPMENT_ALREADY_EXISTS'"></small>
      }
    </div>
    <div class="col-12 col-lg-2">
      <button class="button button--control button--small margin-bottom-1"
        (click)="createNewSimulatorEquipment()">
        <i class="icon-plus"></i>
      </button>
    </div>
  </div>
</form>
