import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { delay, repeat, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[hlLongClick]'
})
export class LongClickDirective implements AfterViewInit, OnDestroy {

  @Input() clickDelayMs = 1000;
  @Output() longClick = new EventEmitter<MouseEvent | TouchEvent>();

  private destroy$ = new Subject<void>();

  constructor(private hostElement: ElementRef) {
  }

  ngAfterViewInit(): void {
    const el = this.hostElement.nativeElement;
    const mouseDown$ = fromEvent(el, 'mousedown');
    const mouseUp$ = fromEvent(el, 'mouseup');
    const touchStart$ = fromEvent(el, 'touchstart');
    const touchEnd$ = fromEvent(el, 'touchend');

    merge(mouseDown$, touchStart$).pipe(
      delay(this.clickDelayMs),
      takeUntil(merge(mouseUp$, touchEnd$)),
      repeat(),
      takeUntil(this.destroy$)
    ).subscribe(this.longClick);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
