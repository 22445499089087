import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { find, includes, isEmpty, isEqual, omit, split, uniq } from 'lodash-es';
import { BehaviorSubject, combineLatest, EMPTY, Observable, ReplaySubject, shareReplay, Subject } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../core/base-class/base-form-modal-popup';
import { CreateTicketEventService } from '../../core/component-communication-services/create-ticket-event/create-ticket-event.service';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { ToasterService } from '../../core/component-communication-services/toaster/toaster.service';
import { contractTypes, DangerForPatients, green, mgRequestUndefined, red, yellow } from '../../core/core-constants.service';
import { Ticket } from '../../core/models/tickets/ticket';
import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import { TicketsRestService } from '../../core/rest-services/tickets-rest.service';
import { TicketsCacheService } from '../../core/services/cache/tickets-cache.service';
import { ContractsUtilService } from '../../core/services/contracts/contracts-util.service';
import { EquipmentUtilService } from '../../core/services/equipment/equipment-util.service';
import { TicketsUtilService } from '../../core/services/tickets/tickets-util.service';
import { AttachmentUtilService } from '../../core/utils/attachment-util.service';
import { DateUtilService } from '../../core/utils/date-util.service';
import { FilterUtilService } from '../../core/utils/filter-util.service';
import { ContractsViewModel } from '../../core/view-models/contracts-view-model';
import { EquipmentViewModel } from '../../core/view-models/equipment-view-model';
import { TicketViewModel } from '../../core/view-models/ticket-view-model';
import { WindowService } from '../../core/window.service';
import { SearchFieldComponent } from '../../shared/components/search-field/search-field.component';
import { SpaceValidator } from '../../shared/validators/space.validator';
import { CreateTicketAvailabilityAreaComponent } from '../../shared/form-group/create-ticket-availability-area/create-ticket-availability-area.component';
import { EquipmentDetails } from '../../core/models/equipment/equipment-details';
import { CENTRICARE_EQUIPMENT_STATUS_CRITICAL } from '../../core/services/equipment/equipment-constants.service';
import { EquipmentStatus } from '../../core/models/equipment/equipment-status';
import { Equipment } from '../../core/models/equipment/equipment';
import { CreateTicketDisclaimerPopupComponent } from '../create-ticket-disclaimer-popup/create-ticket-disclaimer-popup.component';
import { BlacklistRestService } from 'app/core/rest-services/blacklist-rest.service';
import { AddOmnitureAndRouterStateNameDirective } from '../../shared/directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { EnvironmentConfigRestService } from '../../core/rest-services/environment-config-rest.service';
import { RemoveLeadingZeroPipe } from '../../shared/pipes/removeLeadingZero/remove-leading-zero.pipe';
import { UserRestService } from '../../core/rest-services/user-rest.service';
import { AssignedCustomer } from '../../core/models/assigned-customer';
import { MedalliaUtilService } from '../../core/utils/medallia-util.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DropdownOptions } from '../../core/models/dropdown-options';

const translateKeys = {
  TICKET_CREATE_SUCCESSFUL: 'TICKET_CREATE_SUCCESSFUL',
  TICKET_CREATE_SUCCESSFUL_DOWN_STATE: 'TICKET_CREATE_SUCCESSFUL_DOWN_STATE',
  TICKET_CREATE_SUCCESSFUL_DOWN_STATE_CCC: 'TICKET_CREATE_SUCCESSFUL_DOWN_STATE_CCC',
  LABEL_OVERTIME_AUTHORIZATION_YES: 'LABEL_OVERTIME_AUTHORIZATION_YES',
  LABEL_SYSTEM_AVAILBALE: 'LABEL_SYSTEM_AVAILBALE',
  LABEL_DANGER_FOR_PATIENT_QUESTION_ONE: 'LABEL_DANGER_FOR_PATIENT_QUESTION_ONE',
  LABEL_DANGER_FOR_PATIENT_QUESTION_TWO: 'LABEL_DANGER_FOR_PATIENT_QUESTION_TWO',
  LABEL_DANGER_FOR_PATIENT_QUESTION_THREE: 'LABEL_DANGER_FOR_PATIENT_QUESTION_THREE',
  GENERIC_LABEL_YES: 'GENERIC_LABEL_YES',
  GENERIC_LABEL_NO: 'GENERIC_LABEL_NO'
};

@Component({
  selector: 'hl-create-ticket',
  templateUrl: './create-ticket.component.html'
})
export class CreateTicketComponent extends BaseFormModalPopup
  implements OnInit, OnDestroy {
  @Input() equipmentToSelect: string;
  showTicketCreateSystemAvailabilityString = '';
  systemAvailabilityAllowedTypesString = '';
  modalityCodesSyngoString = '';
  defaultTimezoneCode = '';
  defaultTimezoneOffset = '';
  dateTimePattern = 'DD-MM-YYYY, HH:mm';
  maxLengthForLongText: number;
  equipmentVMList: EquipmentViewModel[];
  states: ReplaySubject<EquipmentViewModel[]> = new ReplaySubject<EquipmentViewModel[]>(1);
  showConfidentialDataConfirmation = false;
  showTicketAttachmentByEquipment = false;
  showTicketAttachmentByType = false;
  showRequestArea = false;
  isFormSubmitted = false;
  showValidationMessage = false;
  showEmailToMeOption = false;
  furtherContactsForEmail$: Observable<DropdownOptions[]>;
  createTicketForm: UntypedFormGroup;
  private createTicketFormCreated = new BehaviorSubject<boolean>(false);
  selectedEquipment: EquipmentViewModel = null;
  searchInput: any;
  window: Window;
  equipmentContractsList: ContractsViewModel[];
  equipmentStatusColorMap = {};
  equipmentTicketsList: TicketViewModel[] | Ticket[] = [];
  datePattern = 'DDMMYYYY';
  equipmentDetails: EquipmentDetails;
  loadingEquipmentDetails = false;

  translationErrorMessage =
    'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  ticketLabelInProgress = 'TICKET_CREATION_IN_PROGRESS';

  contractGroupNumberRender: boolean;
  sapSystem = '';
  @ViewChild(SearchFieldComponent) searchField: SearchFieldComponent;
  @ViewChild(CreateTicketAvailabilityAreaComponent) availabilityArea: CreateTicketAvailabilityAreaComponent;
  @ViewChild('disclaimerPopup') disclaimerPopup: CreateTicketDisclaimerPopupComponent;

  translate: { [key: string]: string } = {};

  isCentriCareToggleEnabled = false;
  isCentriCareActive = false;
  isSiebelSubcomponentToggle = false;
  isP58SubcomponentToggle = false;
  haveBillableContract = false;
  showPhoneNumberExtension = false;
  isPOFieldMandatoryToggle = false;
  isPOFieldMandatory = false;
  isPONumberBlackListed = false;
  poFieldContractType: string;
  teamplayEquipmentMaterialNumber: string;
  isDfpYesAllowed = false;
  commaSeparatedAllowedPoNumberTicketTypes: string[] = [];
  mandatoryContactFields: string;
  isContractsLoaded = false;
  isContractExpired = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private equipmentUtilService: EquipmentUtilService,
    private ticketsRestService: TicketsRestService,
    private attachmentUtilService: AttachmentUtilService,
    private configService: CountryConfigRestService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private windowService: WindowService,
    private createTicketEventService: CreateTicketEventService,
    private ticketUtilService: TicketsUtilService,
    private ticketCacheService: TicketsCacheService,
    private contractsUtilService: ContractsUtilService,
    private changeDetector: ChangeDetectorRef,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private dateUtilsService: DateUtilService,
    private blackListRestService: BlacklistRestService,
    private filterUtilService: FilterUtilService,
    private environmentConfigService: EnvironmentConfigRestService,
    private removeLeadingZeroPipe: RemoveLeadingZeroPipe,
    renderer: Renderer2,
    private userRestService: UserRestService,
    private medalliaUtilService: MedalliaUtilService
  ) {
    super(renderer);
    this.window = this.windowService.nativeWindow;
    this.bodyId = 'create-ticket-modal-body';
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Initialize the call stack
   */
  init() {
    this.emitUnsubscribe();
    this.initFormControl();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  postFormData() {
    throw new Error('Method not implemented.');
  }

  /**
   * Initialize reactive form for create ticket
   */
  initFormControl() {
    const translate$ = this.translateService.get(Object.keys(translateKeys));
    const config$ = this.configService.getConfig().pipe(shareReplay(1));
    const envConfig$ = this.environmentConfigService.getEnvironmentConfig();
    this.loadFurtherContactsForEmail(config$);
    combineLatest([config$, translate$, envConfig$]).pipe(takeUntil(this.unsubscribe$)).subscribe(([config, translate, envConfig]) => {
      this.showTicketCreateSystemAvailabilityString =
        config['SHOW_TICKET_CREATE_SYSTEM_AVAILABILITY'];
      this.isPOFieldMandatoryToggle = isEqual(config['FEATURE_TOGGLE_PO_FIELD_MANDATORY'], 'true');
      this.poFieldContractType = config['PO_FIELD_MANDATORY_CONTRACT_TYPE'];
      this.systemAvailabilityAllowedTypesString =
        config['SYSTEM_AVAILABILITY_ALLOWED_TYPES'];
      this.modalityCodesSyngoString = config['MODALITY_CODE_SYNGO'];
      this.defaultTimezoneCode = config['COUNTRY_DEFAULT_TIMEZONE'];
      this.defaultTimezoneOffset =
        config['COUNTRY_DEFAULT_TIMEZONE_OFFSET'];
      this.maxLengthForLongText = parseInt(config['MAX_LENGTH_TICKET_DETAIL_DESCRIPTION'], 10);
      this.showPhoneNumberExtension = isEqual(config['FEATURE_TOGGLE_PHONE_EXTENSION'], 'true');
      this.dateTimePattern = config['GENERIC_DATE_TIME_PATTERN'];
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_GREEN] = green;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_RED] = red;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_YELLOW] = yellow;
      this.mandatoryContactFields = config['MANDATORY_CONTACT_FIELDS'];
      this.showEmailToMeOption = isEqual(config.FEATURE_TOGGLE_EMAIL_TICKET_CREATE_TO_ME, 'true');

      this.showConfidentialDataConfirmation = isEqual(
        config.SHOW_CONFIDENTIAL_PATIENT_DATA_SECTION,
        'true'
      );

      this.datePattern = config.GENERIC_DATE_PATTERN;

      this.translate = translate;

      this.isCentriCareToggleEnabled = isEqual(
        config.TOGGLE_CENTRICARE,
        'true'
      );

      this.isSiebelSubcomponentToggle = isEqual(
        config.TOGGLE_SIEBEL_SUBCOMPONENT_TICKET_CREATION,
        'true'
      );

      this.isP58SubcomponentToggle = isEqual(
        config.FEATURE_TOGGLE_SUBCOMPONENT_TICKET_CREATION,
        'true'
      );

      if (this.isCentriCareToggleEnabled) {
        this.equipmentStatusColorMap[CENTRICARE_EQUIPMENT_STATUS_CRITICAL] = red;
      }

      this.teamplayEquipmentMaterialNumber = envConfig.TEAMPLAY_TENANT_MATERIAL_NUMBER;

      this.isDfpYesAllowed = isEqual(config.TICKET_CREATION_DANGER_FOR_PATIENT_QUESTIONS_RENDER, 'true');

      this.commaSeparatedAllowedPoNumberTicketTypes = split(config.PO_NUMBER_ALLOWED_TICKET_TYPES, ',');

      this.sapSystem = config.SAP_BACKEND_SYSTEM;
      this.createForm(config);
      this.afterConfigProperties(config);
      this.changeDetector.detectChanges();
    });
  }

  private loadFurtherContactsForEmail(config$: Observable<Record<string, string>>) {
    this.furtherContactsForEmail$ = combineLatest([config$, this.createTicketFormCreated.asObservable()])
      .pipe(
        filter(([config, createTicketFormCreated]) =>
          createTicketFormCreated && isEqual(config.FEATURE_TOGGLE_EMAIL_TICKET_CREATE_TO_OTHERS, 'true')),
        switchMap(() => this.ticketUtilService.getMappedAdditionalContacts()),
        tap(contacts => {
          if (this.createTicketForm && contacts.length === 0) {
            this.createTicketForm.get('emailFurtherContacts').disable();
          }
        }),
        takeUntil(this.unsubscribe$)
      );
  }

  showSelectedEquipment() {
    this.selectEquipmentByEquipmentKey(this.equipmentToSelect);
  }

  preselectEquipmentInDeeplink(equipmentKey: string) {
    this.states.pipe(take(1)).subscribe(() => {
      this.selectEquipmentByEquipmentKey(equipmentKey);
    });
  }

  private selectEquipmentByEquipmentKey(equipmentKey: string) {
    if (equipmentKey) {
      this.selectedEquipment = find(this.equipmentVMList, {
        key: equipmentKey
      });
      this.onSelect(this.selectedEquipment);
    } else if (this.searchField) {
      this.searchField.setFocusOnInput();
    }
  }

  hide() {
    this.selectedEquipment = null;
    this.searchInput = null;
    this.isPOFieldMandatory = false;
    if (this.searchField) {
      this.searchField.resetAutocomplete();
    }
    this.initFormControl();
    super.hide();
    this.medalliaUtilService.setPopupsAllowed(true);
  }

  showModalAndSetFields(equipmentKey) {
    this.show();
    this.setPrefilledFields();
    this.selectedEquipment = find(this.equipmentVMList, e => e.key === equipmentKey);
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.createTicketEventService.changeTicketTypeSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      ticketTypeResponse => {
        this.handleViewPropertiesBasedOnTicketType(ticketTypeResponse);
        this.changeDetector.detectChanges();
      }
    );
    this.impersonationCommunicationService.onCountryLanguageChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.init();
      }
    );
  }

  createForm(config) {
    // Email Validation
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = parseInt(config.EMAIL_VALIDATION_LENGTH, 10);

    // phone validation
    const phoneRegEx = new RegExp(config.PHONE_VALIDATION_REGEX);
    const phoneLength = parseInt(config.PHONE_VALIDATION_LENGTH, 10);

    this.createTicketForm = this.fb.group({
      equipmentKey: [''],
      attachments: [[]],
      details: this.fb.group({
        teamplayApplication: [''],
        typeID: [null, Validators.required],
        dangerForPatientGroup: this.fb.group({
          dangerForPatient: DangerForPatients.FALSE,
          dangerForPatientQuestion1: [{disabled: true}, Validators.required],
          dangerForPatientQuestion2: [{disabled: true}, Validators.required],
          dangerForPatientQuestion3: ''
        }),
        description: ['', [Validators.required, Validators.maxLength(100), SpaceValidator.noWhiteSpace]],
        problemSeverityID: [null, Validators.required],
        mgRequest: [null, Validators.required],
        longText: [
          '',
          [Validators.required, Validators.maxLength(this.maxLengthForLongText), SpaceValidator.noWhiteSpace]
        ],
        optionalAuthorizeOvertime: [''],
        protectedCareHours: [''],
        componentID: [null]
      }),
      contact: this.fb.group({
        contactEmail: [
          '',
          [
            Validators.required,
            Validators.maxLength(emailLength),
            Validators.pattern(emailRegEx)
          ]
        ],
        contactFirstName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactPhone: [
          '',
          [
            Validators.required,
            Validators.maxLength(phoneLength),
            Validators.pattern(phoneRegEx),
            SpaceValidator.noWhiteSpace
          ]
        ],
        contactPhoneExt: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      // if a field is mandatory, then the validator has to be set later (see: CreateTicketAvailabilityAreaComponent)
      availability: this.fb.group({
        cbAvailabilityForService: [''],
        date: [null],
        time: [{value: null, disabled: true}]
      }),
      request: this.fb.group({
        feedBack: ['email'],
        feedBackOtherText: [''],
        ownIncidentNumber: ['']
      }),
      emailMe: [false],
      emailFurtherContacts: [false],
      furtherContacts: [[]],
      confirmNoPatientData: this.fb.group({
        confirmed: [
          !this.showConfidentialDataConfirmation,
          [Validators.requiredTrue]
        ]
      }),
      mobileAddress: this.fb.group({
        type: ['existing'],
        addressId: [null],
        locationName: [''],
        street: [''],
        city: [''],
        state: [''],
        zip: [''],
        tzCode: [''],
        tzOffset: ['']
      })
    });
    this.createTicketFormCreated.next(!!this.createTicketForm);

    // abstract method
    this.loadViewModel();
  }

  /**
   *
   * @param token
   * @description Typeahead search for matching list items.
   */
  private getMatchingResult(token: string): EquipmentViewModel[] {
    const search = {
      searchValue: token,
      searchColumns: [
        'productName',
        'myEquipmentName',
        'siemensId',
        'department',
        'street',
        'city'
      ]
    };
    return this.filterUtilService.applyIndividualFilter(
      this.equipmentVMList,
      search,
      'search'
    );
  }

  /**
   * @description toggle render of attachments, based on ticket type.
   *
   * @param {string} ticketType - emitted by CreateTicketEventService
   */
  handleViewPropertiesBasedOnTicketType(ticketType: string) {
    this.attachmentUtilService
      .checkTicketTypeAttachmentRender(ticketType)
      .subscribe((showAttachment: boolean) => {
        this.showTicketAttachmentByType = showAttachment;
      });

    this.setPOField();
  }

  private setPOField() {
    this.isPOFieldMandatory = false;
    if (this.isContractsLoaded && this.isPOFieldMandatoryToggle && !this.isPONumberBlackListed
      && (this.haveBillableContract || this.isContractExpired || !this.equipmentContractsList?.length)
      && includes(this.commaSeparatedAllowedPoNumberTicketTypes, this.getSelectedNotifType())) {
      this.isPOFieldMandatory = true;
      this.createTicketForm.controls['request'].get('ownIncidentNumber')
        .setValidators([Validators.required, SpaceValidator.noWhiteSpace]);
      this.disclaimerPopup.show();
    } else {
      this.createTicketForm.controls['request'].get('ownIncidentNumber').clearValidators();
    }
  }

  public onSelect(item: any): void {
    this.selectedEquipment = item;
    const poFieldContractType = this.poFieldContractType?.split(',');
    const poBlacklist$ = this.blackListRestService.getPoNumberBlackList();
    const contracts$ = this.contractsUtilService.getEquipmentContracts(this.selectedEquipment.key, false);
    combineLatest([contracts$, poBlacklist$]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([equipmentContracts, poBlacklist]) => {
        this.equipmentContractsList = equipmentContracts;
        this.removeContractGroupNumberIfNotRender();
        const activeContract = equipmentContracts.find(c => isEqual(c.contractStatusId, '1'));
        this.haveBillableContract = !!equipmentContracts.filter(c => poFieldContractType?.includes(c.contractTypeId)).length;
        this.isContractExpired = equipmentContracts.some(contract =>
          contract?.expirationDate && new Date(contract.expirationDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
        );
        this.isPONumberBlackListed = poBlacklist.includes(item.modality);

        this.isCentriCareActive =
          this.isCentriCareToggleEnabled && activeContract && isEqual(activeContract.contractTypeId, contractTypes.CENTRICARE);
        this.isContractsLoaded = true;
        this.setPOField();
        this.changeDetector.detectChanges();
      });
    this.ticketUtilService
      .getTicketsByEquipmentKey(this.selectedEquipment.key)
      .subscribe((ticketsResponse) => {
        this.equipmentTicketsList = ticketsResponse;
        this.changeDetector.detectChanges();
      });
    this.onSelectedEquipmentChanged();
    this.loadEquipmentDetails(this.selectedEquipment.key);
  }

  loadEquipmentDetails(key, clearCache?) {
    if (this.isSiebelOrP58Country(key)) {
      this.loadingEquipmentDetails = true;
      this.equipmentUtilService.getDetailsById(key)
        .subscribe(detailsResponse => {
          if (!detailsResponse) {
            if (!clearCache) {
              this.equipmentUtilService.clearEquipmentDetailsCache(key);
              this.loadEquipmentDetails(key, true);
            }
          } else {
            this.equipmentDetails = this.componentEqualsEquipment(detailsResponse) ? null : detailsResponse;
            this.loadingEquipmentDetails = false;
            this.changeDetector.detectChanges();
          }
        });
    } else {
      this.equipmentDetails = null;
      this.loadingEquipmentDetails = false;
      this.changeDetector.detectChanges();
    }
  }

  private isSiebelOrP58Country(key) {
    return (this.isSiebelSubcomponentToggle && key && key.toUpperCase().startsWith('X')) || this.isP58SubcomponentToggle;
  }

  componentEqualsEquipment(equipmentDetails: EquipmentDetails): boolean {
    if (equipmentDetails && equipmentDetails.components && equipmentDetails.components.length === 1) {
      return this.selectedEquipment.key === equipmentDetails.components[0].equipmentKey;
    }
    return false;
  }

  /** needs to be called, when the user (or by route) the selected equipment changed */
  onSelectedEquipmentChanged() {
    this.createTicketForm.patchValue({
      equipmentKey: this.selectedEquipment.key
    });
    this.handleViewPropertiesBasedOnEquipmentType(this.selectedEquipment.key);
  }

  /**
   * @description
   * Show/Hide view properties based on equipment type
   * i.e. LdEquipment
   */
  handleViewPropertiesBasedOnEquipmentType(equipmentKey) {
    const isLdEquipment = this.equipmentUtilService.checkIsLdEquipment(
      equipmentKey
    );
    this.showRequestArea = !isLdEquipment;

    this.attachmentUtilService
      .checkTicketAttachmentRenderByEquipment(equipmentKey)
      .subscribe((showAttachment: boolean) => {
        this.showTicketAttachmentByEquipment = showAttachment;
      });
  }

  /**
   * disable the submit button, if no equipment is selected or if dangerForPatient is checked
   * @returns {boolean}
   */
  isSubmitFormDisabled(): boolean {
    return (
      !this.selectedEquipment || this.loadingEquipmentDetails ||
      (isEqual(
        this.createTicketForm.controls.details.get('dangerForPatientGroup').get('dangerForPatient').value,
        DangerForPatients.TRUE
      ) && !this.isDfpYesAllowed)
    );
  }

  /**
   * @description
   * set the state params when navigating to create ticket screen from another state
   */
  initFromQueryParams() {
    this.route.queryParams.subscribe(params => {
      // get siemens equipment id
      if (params['equipmentSiemensId']) {
        // find the equipment by siemens Id
        this.selectedEquipment = find(this.equipmentVMList, eq => eq.siemensId === params['equipmentSiemensId']);

        this.onSelectedEquipmentChanged();
        this.changeDetector.detectChanges();
      }
    });
  }

  /**
   * Clear the input/search term field for Typeahead
   */
  clearInput() {
    this.selectedEquipment = null;
    this.searchInput = '';
    this.createTicketForm.patchValue({
      equipmentKey: '',
      emailFurtherContacts: false,
      furtherContacts: [],
      emailMe: false
    });
    this.equipmentDetails = null;
    this.loadingEquipmentDetails = false;
    this.changeDetector.detectChanges();
  }

  loadViewModel(): void {
    this.equipmentUtilService.getEquipmentRest().pipe(
      switchMap((equipmentList: Equipment[]) => {
        this.equipmentVMList = this.mapEquipmentListToViewModelList(equipmentList);
        this.states.next(this.getMatchingResult(this.searchInput));
        this.initFromQueryParams();

        return this.equipmentUtilService.getNativeEquipmentStatus();
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((statuses: EquipmentStatus[]) => {
      this.equipmentUtilService.setEquipmentStatusesToViewModelList(this.equipmentVMList, this.selectedEquipment, statuses);
      this.changeDetector.detectChanges();
    });
  }

  private mapEquipmentListToViewModelList(equipmentList: Equipment[]): EquipmentViewModel[] {
    return equipmentList.map(equipment => ({
      ...equipment,
      status: undefined,
      statusOrder: undefined,
      contractAvailability: undefined
    }));
  }

  afterConfigProperties(config: any): void {
    this.setPrefilledFields();
    if (isEqual(config.CONTRACT_GROUP_NUMBER_RENDER, 'true')) {
      this.contractGroupNumberRender = true;
    }
  }

  setPrefilledFields() {
    const prefilledFields = this.ticketCacheService.getPrefilledFields();
    if (prefilledFields) {
      setTimeout(() => {
        this.createTicketForm.patchValue(prefilledFields);
        this.ticketCacheService.resetPrefilledFields();
      }, 250);
    }
  }

  postSuccessData(response: any): void {
    // show create ticket success message.
    let tktCreationMessage;

    if (
      this.equipmentUtilService.checkIsLdEquipment(this.selectedEquipment.key) &&
      isEqual(
        this.createTicketForm.controls.details.get('problemSeverityID').value,
        '1'
      )
    ) {
      tktCreationMessage =
        this.translate[translateKeys.TICKET_CREATE_SUCCESSFUL_DOWN_STATE] +
        '<br/>' +
        response['ticketNumber'] +
        '<br/>' +
        this.translate[translateKeys.TICKET_CREATE_SUCCESSFUL_DOWN_STATE_CCC];
    } else {
      tktCreationMessage =
        this.translate[translateKeys.TICKET_CREATE_SUCCESSFUL] +
        '<br/>' +
        response['ticketNumber'];
    }
    this.toasterService.showNotTranslatedSuccessToaster(tktCreationMessage);
  }

  postErrorData(): void {
    // no special treatment (the error would be displayed anyway in toaster and then onFinally
    //  triggers the route back to the calling page)
  }

  /** called, when a post to the server returned (sucessfull or not) */
  onFinally(): void {
    this.hide();
    this.isFormSubmitted = false;
  }

  /* adding additional fields to the long text and cutting it, if too long */
  getComposedLongText(): string {
    let longText = this.createTicketForm.get('details.longText').value;

    if (this.showTeamplayEquipmentDescription()) {
      longText = 'teamplay application: ' + this.createTicketForm.get('details.teamplayApplication').value + '\n' + longText;
    }

    if (
      this.ticketUtilService.shouldRenderSystemAvailabilityInTicketCreate(
        this.showTicketCreateSystemAvailabilityString,
        this.systemAvailabilityAllowedTypesString,
        this.modalityCodesSyngoString,
        this.getSelectedModality(),
        this.getSelectedNotifType()
      )
    ) {
      longText = longText + '..' + this.translate[translateKeys.LABEL_SYSTEM_AVAILBALE] + ' ' + this.getTransformedDateTimeValues();
    }

    if (this.createTicketForm.get('details.optionalAuthorizeOvertime').value) {
      longText = longText + '..' + this.translate[translateKeys.LABEL_OVERTIME_AUTHORIZATION_YES];
    }

    if (this.createTicketForm.get('details.componentID').value) {
      longText += '\nComponent: ' + this.createTicketForm.controls['details']['componentText'];
    }

    longText = this.attachDangerForPatientInformation(longText);
    longText = this.attachPoNumber(longText);

    // cut, if too long
    longText =
      longText.length <= this.maxLengthForLongText
        ? longText
        : longText.substr(0, this.maxLengthForLongText);

    return longText;
  }

  attachDangerForPatientInformation(longText: string): string {
    if ((isEqual(
      this.createTicketForm.controls.details.get('dangerForPatientGroup').get('dangerForPatient').value,
      DangerForPatients.TRUE
    ) && this.isDfpYesAllowed)) {
      longText += '\n\n' + this.translate[translateKeys.LABEL_DANGER_FOR_PATIENT_QUESTION_ONE];
      longText += '\n' + this.translate[
        this.getYesNoTranslationConfigKey(this.createTicketForm.controls.details.get('dangerForPatientGroup').get('dangerForPatientQuestion1').value)];

      longText += '\n\n' + this.translate[translateKeys.LABEL_DANGER_FOR_PATIENT_QUESTION_TWO];
      longText += '\n' + this.translate[
        this.getYesNoTranslationConfigKey(this.createTicketForm.controls.details.get('dangerForPatientGroup').get('dangerForPatientQuestion2').value)];

      longText += '\n\n' + this.translate[translateKeys.LABEL_DANGER_FOR_PATIENT_QUESTION_THREE];
      longText += '\n' + this.createTicketForm.controls.details.get('dangerForPatientGroup').get('dangerForPatientQuestion3').value;
    }

    return longText;
  }

  attachPoNumber(longText: string): string {
    if (this.sapSystem !== 'P40') {
      const poNumber = this.createTicketForm.controls.request.get('ownIncidentNumber').value;
      if (poNumber) {
        return longText + '\n\n' + 'PO number: ' + poNumber;
      }
    }

    return longText;
  }

  getYesNoTranslationConfigKey(key: string): string {
    return key === 'Y' ? translateKeys.GENERIC_LABEL_YES : translateKeys.GENERIC_LABEL_NO;
  }

  /** @return the selected ticketType or '' */
  getSelectedNotifType(): string {
    if (this.createTicketForm.get('details.typeID')) {
      return this.createTicketForm.get('details.typeID').value;
    }
    return '';
  }

  /** @return the modality of the selected equipment or '' */
  getSelectedModality(): string {
    if (this.selectedEquipment) {
      return this.selectedEquipment.modality;
    }
    return '';
  }

  /**
   * Create tickets when form is valid.
   */
  createTicket() {
    this.isFormSubmitted = true;

    // If the form is valid, then make a REST (post call) to create a ticket
    if (this.createTicketForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;

      // Form value being stripped for BE request input
      let strippedFormValue = omit(this.createTicketForm.value, [
        'confirmNoPatientData',
        'availability',
        'details.optionalAuthorizeOvertime',
        'details.dangerForPatientGroup',
        'emailFurtherContacts'
      ]);

      strippedFormValue['furtherContacts'] = this.getDistinctGids();

      // Detail Area long text composed with availability area
      strippedFormValue['details']['longText'] = this.getComposedLongText();
      strippedFormValue['details']['dangerForPatient'] = this.createTicketForm.controls.details.get('dangerForPatientGroup').get('dangerForPatient').value;

      // Detail Area short text (description) with mg
      strippedFormValue = this.appendAndOmitMgRequest(strippedFormValue);

      AddOmnitureAndRouterStateNameDirective.trackGeneral(
        AddOmnitureAndRouterStateNameDirective.formStates.FORM_SENT,
        AddOmnitureAndRouterStateNameDirective.formTypes.TICKET_CREATE_TYPE,
        strippedFormValue['attachments'] && strippedFormValue['attachments'].length > 0);

      // Mobile Area handling
      if (this.selectedEquipment.mobile) {
        this.setFormValueWithMobileAddress(strippedFormValue);
      } else {
        this.postCreateTicket(strippedFormValue);
      }
    } else {
      this.showValidationMessage = true;
    }
  }

  private getDistinctGids(): string[] {
    const furtherContacts: string[] = this.createTicketForm.get('furtherContacts').value;
    return this.createTicketForm.get('emailFurtherContacts').value ? uniq(furtherContacts.map(c => JSON.parse(c).gid)) : []
  }

  postCreateTicket(formValue: object) {
    this.ticketsRestService
      .postCreateTicket(formValue)
      .pipe(
        finalize(() => this.onFinally()),
        catchError(() => {
          this.postErrorData();
          return EMPTY;
        }),
        tap(response => this.postSuccessData(response)),
        switchMap(() => {
          return this.userRestService.getAssignedCustomers();
        })
      )
      .subscribe((assignedCustomers: AssignedCustomer[]) => {
        const assignedCustomer = assignedCustomers.find(res => res.customerId === this.selectedEquipment.customerId);
        if (assignedCustomer) {
          this.priorityRefreshTicketStatus(assignedCustomer.customerId);
          this.equipmentUtilService.priorityRefreshEquipmentStatus(assignedCustomer.customerId);
        }
      });
  }

  /**
   * Generate the timezone if new address and add to form request to BE
   * @param formValue
   */
  setFormValueWithMobileAddress(formValue) {
    // if only new address then we make call to timeZone API
    if (isEqual(formValue['mobileAddress']['type'], 'new')) {
      const city = formValue['mobileAddress']['city'];
      const state = formValue['mobileAddress']['state'];

      this.ticketUtilService.getTimezone(city, state).subscribe(
        response => {
          if (response) {
            if (isEqual(response.meta.code, '200')) {
              if (
                response.data.addresses &&
                response.data.addresses.length >= 1
              ) {
                const tzCode = response.data.addresses[0].datetime.offset_tzab;
                const tzOffset =
                  response.data.addresses[0].datetime.offset_hours;
                this.appendTimeZoneAndPostCreate(
                  true,
                  formValue,
                  tzCode,
                  tzOffset
                );
              } else {
                /**
                 * Here the API return empty value when it fails but with 200 status code
                 */
                this.appendTimeZoneAndPostCreate(true, formValue, '', '');
              }
            } else {
              this.appendTimeZoneAndPostCreate(true, formValue, '', '');
            }
          } else {
            this.appendTimeZoneAndPostCreate(true, formValue, '', '');
          }
        },
        () => {
          this.appendTimeZoneAndPostCreate(true, formValue, '', '');
        }
      );
    } else {
      this.appendTimeZoneAndPostCreate(false, formValue);
    }
  }

  /**
   * Append timezone and make BE request
   * @param {boolean} useDefault
   * @param {string} tzCode
   * @param {string} tzOffset
   * @param formValue
   */
  appendTimeZoneAndPostCreate(
    useDefault: boolean,
    formValue,
    tzCode?: string,
    tzOffset?: string
  ) {
    // additional check if default values added if TimeZone API fails
    // else time zone value in request would be empty string
    if (useDefault) {
      if (isEmpty(tzCode) || isEmpty(tzOffset)) {
        formValue['mobileAddress']['tzCode'] = this.defaultTimezoneCode;
        formValue['mobileAddress']['tzOffset'] = this.defaultTimezoneOffset;
      } else {
        formValue['mobileAddress']['tzCode'] = tzCode;
        formValue['mobileAddress']['tzOffset'] = tzOffset;
      }
    }
    this.postCreateTicket(formValue);
  }

  /**
   * Append the mqRequest to the short text and omit it from the form.
   * @param {{}} formValue : base form to be send in request.
   * @returns {{}} form without mgRequest field.
   */
  appendAndOmitMgRequest(formValue: {}) {
    const mgRequest = this.createTicketForm.get('details.mgRequest').value;
    if (!isEqual(mgRequest, mgRequestUndefined)) {
      formValue['details']['description'] =
        mgRequest +
        ' ' +
        this.createTicketForm.get('details.description').value;
    }
    return omit(formValue, ['details.mgRequest']);
  }

  private getTransformedDateTimeValues() {
    let transformedDateTimeValues;

    if (
      this.createTicketForm.get('availability.cbAvailabilityForService')
        .value === 'immediately'
    ) {
      transformedDateTimeValues = this.dateUtilsService.toStringWithPattern(
        this.dateTimePattern,
        new Date()
      );
    } else if (
      this.createTicketForm.get('availability.cbAvailabilityForService')
        .value === 'onDate'
    ) {
      transformedDateTimeValues = this.dateUtilsService.toStringWithPattern(
        this.dateTimePattern,
        this.createTicketForm.get('availability.date').value,
        this.createTicketForm.get('availability.time').value
      );
    }
    return transformedDateTimeValues;
  }

  /**
   * Remove contractGroupNumber property
   * form contract list items if it should not be rendered
   */
  removeContractGroupNumberIfNotRender() {
    for (const contract of this.equipmentContractsList) {
      if (!this.contractGroupNumberRender) {
        delete contract.contractGroup;
      }
    }
  }

  onSearchInputChange(searchString: string) {
    this.states.next(this.getMatchingResult(searchString));
  }

  onOperationalStateChange() {
    if (this.availabilityArea) {
      this.availabilityArea.recheckAvailability();
    }
  }

  show() {
    this.medalliaUtilService.setPopupsAllowed(false);
    super.show();
    AddOmnitureAndRouterStateNameDirective.trackGeneral(
      AddOmnitureAndRouterStateNameDirective.formStates.FORM_OPEN,
      AddOmnitureAndRouterStateNameDirective.formTypes.TICKET_CREATE_TYPE);
  }

  private emitUnsubscribe() {
    if (this.unsubscribe$.observed) {
      this.unsubscribe$.next();
    }
  }

  showTeamplayEquipmentDescription(): boolean {
    return !!this.selectedEquipment &&
      this.removeLeadingZeroPipe.transform(this.selectedEquipment.materialNumber) === this.teamplayEquipmentMaterialNumber;
  }

  private priorityRefreshTicketStatus(customerId: string) {
    this.ticketsRestService.priorityRefreshTickets({customerIds: [customerId]});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    super.ngOnDestroy();
  }
}
