import { Component, inject, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseFormModalPopup } from 'app/core/base-class/base-form-modal-popup';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { finalize, map } from 'rxjs/operators';
import { AppointmentsFilterStatus } from 'app/activities-common/appointments-constants';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { ActivitiesRestService } from 'app/core/rest-services/activities-rest.service';

@Component({
  selector: 'hl-dot-request-rescheduling-modal',
  templateUrl: './dot-request-rescheduling-modal.component.html'
})
export class DotRequestReschedulingModalComponent extends BaseFormModalPopup {

  _activityAppointments: ActivitiesViewModel[];
  private appointmentNumberToPlannedStartMap: Map<string, string>;
  form: UntypedFormGroup;
  fb = inject(UntypedFormBuilder);
  configService: CountryConfigRestService = inject(CountryConfigRestService);
  activitiesRestService: ActivitiesRestService = inject(ActivitiesRestService);
  toasterService = inject(ToasterService);
  dateTimePatternSignal = toSignal(this.configService.getConfig().pipe(
    takeUntilDestroyed(),
    map(configResponse => configResponse.GENERIC_DATE_TIME_PATTERN)));

  @ViewChild('contactArea') contactArea;

  @Input() activitiesItem: ActivitiesViewModel;

  @Input()
  set activityAppointments(activityAppointments: ActivitiesViewModel[]) {
    this._activityAppointments = activityAppointments;
    if (activityAppointments) {
      this.appointmentNumberToPlannedStartMap = new Map<string, string>(
        activityAppointments.map(appointment => ([appointment.appointmentNumber, appointment.plannedStart])));
    }
  }

  ngOnInit() {
    this.createForm();
  }

  postFormData(): void {
    let longTextWithContact = ''
    this.form.get('rescheduleAppointments').value.forEach(appointment => {
      if (appointment.checked) {
        longTextWithContact += 'Task: ' + appointment.appointmentNumber.substring(appointment.appointmentNumber.indexOf('-') + 1) + ' - Original Appointment - ';
        let plannedStart = this._activityAppointments.filter(app => app.appointmentNumber === appointment.appointmentNumber)[0]?.plannedStart;
        let plannedEnd = this._activityAppointments.filter(app => app.appointmentNumber === appointment.appointmentNumber)[0]?.plannedEnd;
        longTextWithContact += plannedStart ? 'Start: ' + plannedStart + ' ' : '';
        longTextWithContact += plannedStart && plannedEnd ? ' | ' : '';
        longTextWithContact += plannedEnd ? 'End: ' + plannedEnd + ' ' : '';
        longTextWithContact += '\n';
      }
    });

    longTextWithContact += '\n Request Text: \n'
    longTextWithContact += this.form.controls['longText'].value + '\n\n';

    longTextWithContact +=
      this.form.get(['contact', 'contactFirstName']).value + ' ' +
      this.form.get(['contact', 'contactLastName']).value + '\n';
    const email = this.form.get(['contact', 'contactEmail']).value;
    longTextWithContact += email ? email + '\n' : '';
    const number = this.form.get(['contact', 'contactPhone']).value;
    longTextWithContact += number ? number + '\n' : '';
    const phoneExt = this.form.get(['contact', 'contactPhoneExt']).value;
    longTextWithContact += phoneExt ? phoneExt : '';

    this.form.patchValue({longText: longTextWithContact});

    this.activitiesRestService.update({ ...this.form.value, sendEmail: false}).pipe(
      finalize(() => {
        this.hide();
      })
    ).subscribe(() => this.toasterService.showTranslatedSuccessToaster('RESCHEDULING_REQUEST_SUBMITTED'));
  }

  createForm() {
    this.form = this.fb.group({
      rescheduleAppointments: [[], [this.rescheduleAppointmentsValidator()]],
      ticketKey: [this.activitiesItem?.ticketKey],
      action: ['ACTIVITY'],
      ticketNumber: [this.activitiesItem?.ticketNumber],
      typeID: [this.activitiesItem?.type],
      customerId: [this.activitiesItem?.customerId],
      attachments: [[]],
      contact: this.fb.group({
        contactEmail: [''],
        contactFirstName: [''],
        contactLastName: [''],
        contactPhone: [''],
        contactPhoneExt: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      feedBack: [''],
      longText: ['', [Validators.required, Validators.maxLength(2000)]],
      confirmNoPatientData: this.fb.group({
        confirmed: [true]
      })
    });
  }

  getAppointmentPlannedStart(appointment: { appointmentNumber?: string }): string {
    return this.appointmentNumberToPlannedStartMap.get(appointment.appointmentNumber);
  }

  onCheck(appointmentNumber: string) {
    const appointment = this.form.get('rescheduleAppointments').value.find(app => app.appointmentNumber === appointmentNumber);

    if (appointment) {
      appointment.checked = !appointment.checked;
      this.form.get('rescheduleAppointments').updateValueAndValidity();
    }
  }

  openRequestReschedulingModal() {
    this.createForm();
    this.form.get('rescheduleAppointments').patchValue(this._activityAppointments
      .filter(appointment => appointment.filterStatus !== AppointmentsFilterStatus.CLOSED)
      .map(appointment => ({
        appointmentNumber: appointment.appointmentNumber,
        checked: appointment.appointmentKey === this.activitiesItem?.appointmentKey,
        pmStatus: appointment.pmStatus,
        colorStatus: appointment.colorStatus,
        sapSystem: appointment.sapSystem,
        title: appointment.activityTitle
      })));

    this.showSpinner = false;
    this.show();
  }

  rescheduleAppointmentsValidator(): ValidatorFn {
    return rescheduleAppointmentsFormControl => {
      if (!rescheduleAppointmentsFormControl) {
        return {status: 'appointments form control does not exist'};
      }

      const isAppointmentsSelectionValid = rescheduleAppointmentsFormControl.value.some(appointment => appointment.checked);
      return !isAppointmentsSelectionValid ? {status: 'appointments selection is not valid'} : null;
    };
  }
}
