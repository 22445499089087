import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { SystemUpdatesRestService } from '../../rest-services/system-updates-rest.service';
import { map } from 'rxjs/operators';
import { find, forEach } from 'lodash-es';
import { EquipmentUtilService } from '../equipment/equipment-util.service';
import { RemoveLeadingZeroPipe } from '../../../shared/pipes/removeLeadingZero/remove-leading-zero.pipe';
import { SystemUpdatesEquipmentViewModel } from '../../view-models/system-updates-equipment-view-model';

@Injectable({providedIn: 'root'})
export class CreateSystemUpdatesService {

  constructor(
    private systemUpdatesRestService: SystemUpdatesRestService,
    private equipmentUtilService: EquipmentUtilService,
    private removeLeadingZeroPipe: RemoveLeadingZeroPipe) {
  }

  getAllEquipmentForNewSystemUpdateCreation(): Observable<(SystemUpdatesEquipmentViewModel)[]> {
    return combineLatest([this.equipmentUtilService.getEquipmentViewModelList(), this.systemUpdatesRestService.getSecretKeysForEquipment()])
      .pipe(map(([equipment, secretKeys]) => {
        forEach(secretKeys, sk => {
          sk.materialNumber = this.removeLeadingZeroPipe.transform(sk.materialNumber);
          sk.serialNumber = this.removeLeadingZeroPipe.transform(sk.serialNumber);
        });
        return equipment.map(e => {
          const eqSecretKeyDTO = find(secretKeys, {
            'materialNumber': this.removeLeadingZeroPipe.transform(e.materialNumber),
            'serialNumber': this.removeLeadingZeroPipe.transform(e.serialNumber)
          });
          return eqSecretKeyDTO ? {...e, secretKey: eqSecretKeyDTO.secretKey} : e;
        });
      }));
  }
}
