import { NgModule } from '@angular/core';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { OverlayDirective } from '../shared/directives/overlay-popup/overlay.directive';
import { DomPortalDirective } from '../shared/directives/dom-portal/dom-portal.directive';

@NgModule(
  {
    declarations: [
      OverlayDirective,
      DomPortalDirective
    ],
    imports: [
      CdkConnectedOverlay,
      CdkOverlayOrigin
    ],
    exports: [
      OverlayDirective,
      DomPortalDirective
    ]
  }
)
export class PortalsModule {
}
