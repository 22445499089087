<div class="form-horizontal availability-area" [formGroup]="availabilityForm">
  <div class="form-group">

    <label class="additional-text" [translate]="'LABEL_SYSTEM_AVAILABILITY'"></label>

    <div class="col-lg-9 col-md-8">
      <input id="immediately" type="radio" class="radio radio--inline" formControlName="cbAvailabilityForService" value="immediately"
        (click)="setValidators($event)">
      <label for="immediately" [translate]="'LABEL_SYSTEM_AVAILABLE_IMMEDIATELY'" (click)="selectCbAvailabilityForService('immediately')"></label>
    </div>

    <!-- fixes mobile issue SHLNSP-917 -->
    <div class="col-lg-9 col-md-8 radio-button-seperator"></div>

  </div>
  <div class="form-group">

    <div class="col-12">
      <input id="onDate" type="radio" class="radio radio--inline" formControlName="cbAvailabilityForService" value="onDate"
        (click)="setValidators($event)">
      <label for="onDate" [translate]="'LABEL_SYSTEM_AVAILABLE_ONTIME'" (click)="selectCbAvailabilityForService('onDate')"></label>
    </div>
    <div id="rb-validation-error" class="col-lg-9 col-lg-offset-3 col-md-8 col-md-offset-4 col-sm-12 col-12"
      [ngClass]="{'invalid': isFormSubmitted && !availabilityForm.controls.cbAvailabilityForService.valid}">
      @if (isFormSubmitted && !availabilityForm.controls['cbAvailabilityForService'].valid) {
        <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
      }
    </div>
  </div>

  <div class="form-group">

    @if (showDateTimePicker) {
      <div [ngClass]="{'rb-active': !onRBActive}" class="col-12">
        <p id="date-picker-validation-error" [ngClass]="{'invalid': isFormSubmitted && !availabilityForm.controls['date'].valid}">
          <hl-date-picker [labelText]="'GENERIC_LABEL_DATE'" [(ngModel)]="availabilityForm.controls['date'].value"
            [isRequired]="availabilityForm.controls['date'].errors && availabilityForm.controls['date'].errors['required']"
            [formControl]="availabilityForm.controls['date']" [convertModelValue]="true" ngDefaultControl
            [isInvalid]="isFormSubmitted && !availabilityForm.controls['date'].valid"
            [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"></hl-date-picker>
        </p>
      </div>
    }
    @if (showDateTimePicker) {
      <div class="col-12">
        <p id="time-picker-validation-error" [ngClass]="{'invalid': isFormSubmitted && !availabilityForm.controls.time.valid}">
          <hl-time-picker [labelText]="'GENERIC_LABEL_TIME'" [(ngModel)]="availabilityForm.controls['time'].value"
            [isRequired]="availabilityForm.controls['time'].errors && availabilityForm.controls['time'].errors['required']"
            [formControl]="availabilityForm.controls['time']" [convertModelValue]="true" ngDefaultControl></hl-time-picker>
        </p>
      </div>
    }

  </div>

  <div class="form-group" [ngClass]="{'without-date-picker': !showDateTimePicker}"></div>
</div>
