import { Directive, HostListener, inject } from '@angular/core';
import { DotService } from '../../services/dot.service';

@Directive({
  selector: '[hlDotLegacyRescheduleNav]',
  standalone: true
})
export class DotLegacyRescheduleNavDirective {

  private dotService = inject(DotService);

  @HostListener('click', ['$event'])
  handleClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if ((e.target as HTMLElement).tagName === 'A') {
      this.dotService.emitOpenLegacyModal();
    }
  }
}
