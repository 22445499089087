import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { AddOmnitureAndRouterStateNameDirective } from '../../directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MedalliaUtilService } from '../../../core/utils/medallia-util.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-reschedule-activity-modal',
  templateUrl: './reschedule-activity-modal.component.html'
})
export class RescheduleActivityModalComponent extends BaseFormModalPopup implements OnInit {

  @Input() activitiesItem: ActivitiesViewModel;

  // store form data
  form: UntypedFormGroup;
  dateTimePattern = '';

  time = new Date();

  // add validation message
  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';

  constructor(protected fb: UntypedFormBuilder,
    private configService: CountryConfigRestService,
    private activitiesRestService: ActivitiesRestService,
    private toasterService: ToasterService,
    public medalliaUtilService: MedalliaUtilService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.createForm();
    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((configResponse) => {
      this.dateTimePattern = configResponse.GENERIC_DATE_TIME_PATTERN;
    });
  }

  createForm() {
    this.form = this.fb.group({
      rescheduleEmailText: ['', [Validators.required]]
    });
  }

  postFormData() {
    AddOmnitureAndRouterStateNameDirective.trackGeneral(
      AddOmnitureAndRouterStateNameDirective.formStates.FORM_SENT,
      AddOmnitureAndRouterStateNameDirective.formTypes.RESCHEDULE_ACTIVITY_TYPE);
    this.activitiesRestService.reschedule(this.activitiesItem.ticketKey, this.form.value).pipe(
      finalize(() => {
        this.isFormSubmitted = false;

        // reset form
        this.createForm();

        // close modal
        this.hide();
      }))
      .subscribe(() => this.toasterService.showTranslatedSuccessToaster('RESCHEDULE_PLANNED_ACTIVITY_EMAIL_SUCCESS'));
  }

  show() {
    super.show();
    AddOmnitureAndRouterStateNameDirective.trackGeneral(
      AddOmnitureAndRouterStateNameDirective.formStates.FORM_OPEN,
      AddOmnitureAndRouterStateNameDirective.formTypes.RESCHEDULE_ACTIVITY_TYPE);
  }

  hide() {
    super.hide();
    this.medalliaUtilService.setPopupsAllowed(true);
  }
}
