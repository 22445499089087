import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipModule } from 'app/tooltip/tooltip.module';

@Component({
  selector: 'hl-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    TooltipModule
  ]
})
export class CountdownTimerComponent {

  durationInSeconds = input.required<number>();
  iconTooltipText = input<string | null>(null);
  timerExpired = output();

  formattedRemainingTime = signal<string>('');

  constructor() {
    const startTime = Date.now();

    const subscription = timer(0, 1000).pipe(
      map(() => Math.floor((Date.now() - startTime) / 1000)),
      map(seconds => Math.max(this.durationInSeconds() - seconds, 0)),
      takeUntilDestroyed()
    ).subscribe(remainingSeconds => {
      this.formattedRemainingTime.set(this.formatTime(remainingSeconds));

      if (remainingSeconds == 0) {
        this.timerExpired.emit();
        subscription.unsubscribe();
      }
    });
  }

  private formatTime(timeInSeconds: number): string {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${this.withLeadingZeroes(minutes)}:${this.withLeadingZeroes(seconds)}`;
  }

  private withLeadingZeroes(number: number): string {
    return number < 10 ? `0${number}` : `${number}`;
  }

}
