import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: 'root'
})
export class PreliminaryInvoicesCacheService {

  private useCache$: BehaviorSubject<boolean>;

  constructor() {
    this.useCache$ = new BehaviorSubject<boolean>(false);
  }

  getUseCache() {
    return this.useCache$.asObservable();
  }

  setUseCache(useCache: boolean) {
    this.useCache$.next(useCache);
  }
}
