<ul class="bare-list info-list">
  <ng-template #ticketNumber>
    @if (item.ticketNumber) {
      <li class="info-list__item label-status-{{item.problemSeverityID | getTicketProblemSeverityColor}}"
        [attr.data-cy]="'ticket-' + (index + 1) +'-number'">
        {{ item.ticketNumber }}
      </li>
    }
  </ng-template>
  <ng-template #productName>
    @if (item.productName || item.componentId) {
      <li class="info-list__item" [attr.data-cy]="'ticket-' + (index + 1) +'-product-name'">
        <i class="icon-label" aria-hidden="true" hlTooltip [title]="(item.componentId ? 'COMPONENT_ID' : 'GENERIC_LABEL_PRODUCT_NAME') | translate"
          [attr.data-cy]="'ticket-' + (index + 1) +'-product-name-icon'"></i>
        {{ item.componentId ? item.componentId : item.productName }}
      </li>
    }
  </ng-template>
  <ng-template #siemensEquipmentId>
    @if (item.siemensEquipmentId || item.customerComponentId) {
      <li class="info-list__item">
        <i class="icon-id-card" aria-hidden="true" hlTooltip
          [title]="(item.customerComponentId ? 'LABEL_CUSTOMER_COMPONENT_ID' : 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID') | translate"
          [attr.data-cy]="'ticket-' + (index + 1) +'-equipment-id-icon'">
        </i>
        {{ item.customerComponentId ? item.customerComponentId : item.siemensEquipmentId }}
      </li>
    }
  </ng-template>
  <ng-template #customerName>
    @if (item.customerName && toggleCustomerName) {
      <li class="info-list__item">
        <i class="icon-user" aria-hidden="true" hlTooltip [title]="'GENERIC_LABEL_CUSTOMER_NAME' | translate"
          [attr.data-cy]="'ticket-' + (index + 1) +'-customer-name-icon'"></i>
        <span hlTooltip [title]="item.customerName" [isActive]="item.customerName.length > this.customerNameLength">
        {{ item.customerName | truncate:[this.customerNameLength] }}</span>
      </li>
    }
  </ng-template>
  <ng-template #ticketCreation>
    @if (item.ticketCreationTimestamp) {
      <li class="info-list__item">
        <i class="icon-time" aria-hidden="true" hlTooltip [title]="'CREATION_TIME' | translate"
          [attr.data-cy]="'ticket-' + (index + 1) +'-creation-date-icon'"></i>
        {{ item.ticketCreationTimestamp | datePipeWrapper : dateTimePattern }}
      </li>
    }
  </ng-template>
  <ng-template #severityDescription>
    @if (item.problemSeverityDescription) {
      <li class="info-list__item" data-cy="open-tickets-widget-operation-state">
        <i class="icon-information" aria-hidden="true" hlTooltip [title]="'GENERIC_LABEL_OPERATIONAL_STATE' | translate"
          [attr.data-cy]="'ticket-' + (index + 1) +'-operational-state-icon'"></i>
        {{ item.problemSeverityDescription }}
      </li>
    }
  </ng-template>
  <ng-template #originalEffectCodeDescription>
    @if (item.originalEffectCodeDescription && showOriginalEffectCode) {
      <li class="info-list__item" data-cy="open-tickets-widget-original-effect-code">
        <i class="icon-information" aria-hidden="true" hlTooltip [title]="'LABEL_ORIGINAL_EFFECT_CODE' | translate"
          [attr.data-cy]="'ticket-' + (index + 1) +'-original-effect-code-icon'"></i>
        {{ item.originalEffectCodeDescription }}
      </li>
    }
  </ng-template>
  <ng-template #city>
    @if (item.city) {
      <li class="info-list__item" data-cy="open-tickets-widget-city">
        <i class="icon-department" aria-hidden="true" hlTooltip [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ item.city }}
      </li>
    }
  </ng-template>
  <ng-template #serialNumber>
    @if (item.serialNumber) {
      <li class="info-list__item" data-cy="open-tickets-widget-serial-number">
        <i class="icon-id-card" aria-hidden="true" hlTooltip [title]="'GENERIC_LABEL_SERIAL_NUMBER' | translate"></i> {{ item.serialNumber }}
      </li>
    }
  </ng-template>
  @for (itemName of ticketListItemOrder; track itemName) {
    <ng-container *ngTemplateOutlet="{
    type: itemName,
    ticketNumber: ticketNumber,
    productName: productName,
    siemensEquipmentId: siemensEquipmentId,
    ticketCreationTimestamp: ticketCreation,
    problemSeverityDescription: severityDescription,
    originalEffectCodeDescription: originalEffectCodeDescription,
    customerName: customerName,
    city: city,
    serialNumber: serialNumber
    } | ticketListItemTemplateMapPipe"></ng-container>
  }
</ul>
