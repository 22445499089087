import { Component, Input, ViewChild } from '@angular/core';
import { CreateSaModalComponent } from 'app/shared/modal-popup/create-sa-modal/create-sa-modal.component';

@Component({
  selector: 'hl-create-sao-entry-page',
  templateUrl: './create-sao-entry-page.component.html',
  styles: []
})
export class CreateSaoEntryPageComponent {

  @ViewChild('createSaModal')
  createSaModal: CreateSaModalComponent;
  @Input()
  equipmentKey: string;
}
