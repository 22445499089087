import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import { MdmData } from '../models/mdm';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';

@Injectable({providedIn: 'root'})
export class MdmRestService {

  private mdmRestName = restEndPoint + 'mdm';

  constructor(private http: CacheableHttpClient) {
  }

  public getMdmData(): Observable<MdmData> {
    return this.http.get<MdmData>(this.mdmRestName + '/spaces', {cache: true});
  }

  registerOrReplaceTablet(body): Observable<any> {
    return this.http.post(this.mdmRestName + '/register', body);
  }

  getAssignedSerialNumbers(materialNumber: string, serialNumber: string, partitionId: number): Observable<string[]> {
    return this.http.get<string[]>(this.getAssignedSerialNumbersUrl(materialNumber, serialNumber, partitionId), {cache: true});
  }

  clearCacheAssignedSerialNumbers(materialNumber: string, serialNumber: string, partitionId: number) {
    this.http.clearCache(this.getAssignedSerialNumbersUrl(materialNumber, serialNumber, partitionId));
  }

  getAssignedSerialNumbersUrl(materialNumber: string, serialNumber: string, partitionId: number) {
    return this.mdmRestName + `/devices?materialNumber=${materialNumber}&serialNumber=${serialNumber}&dmPartitionId=${partitionId}`;
  }
  getWhitelistedMaterialNumbers(businessLine: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.mdmRestName}/allowedMaterialNumbersInMDM?businessLine=${businessLine}`, {cache: true});
  }
}
