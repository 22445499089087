<div class="form-group">
  <label class="form__label" for="file_upload" [translate]="genericLabelName"></label>
  <div class="file-input">
    <div [class.button-disabled]="isDisabled || disableUploadButton" class="button button--small button-def-cursor file-input__button"
      (click)="uploadBtn.click()" [translate]="'LABEL_SELECT_FILES'"></div>
    <input class="file-input__input" hidden type="file" id="file_upload" #uploadBtn (change)="handleFileSelect($event)"/>
    @if (isRequired && isInvalid) {
      <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
    }
  </div>

  @if (fileObjects.length > 0) {
    <div>
      <div class="collection file-list">
        @for (uploadFile of fileObjects; track uploadFile) {
          <div class="collection-item file-list__item">
            <div class="collection__content">
              <div class="file-list__file">
                <div title="{{uploadFile.filename}}" hlTooltip class="file-list__file-name">{{ uploadFile.filename }}
                  @if (uploadFile.languageCode) {
                    <span class="label">{{ uploadFile.languageCode }}</span>
                  }
                </div>
                <div class="file-list__file-size">{{ uploadFile.filesize | fileSizeFormat }}</div>
              </div>
            </div>
            <div class="collection__additional-content">
              <button class="button button--link" [disabled]="disableCancel" (click)="removeFile(uploadFile)">
                <i class="icon-close"></i>
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
