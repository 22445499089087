import { Component, computed, input, output } from '@angular/core';
import { ActivitiesViewModel } from '../../core/view-models/activities-view-model';
import { ActivitiesUtilService } from '../../core/services/activities/activities-util.service';
import { TooltipModule } from '../../tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { ActivityPropertyKey, ActivityPropertyListComponent } from '../activity-property-list/activity-property-list.component';

@Component({
  selector: 'hl-activity-list',
  templateUrl: './activity-list.component.html',
  imports: [
    TooltipModule,
    TranslateModule,
    ActivityPropertyListComponent
  ],
  standalone: true
})
export class ActivityListComponent {
  item = input.required<ActivitiesViewModel>();
  index = input.required<number>();

  whitelistedProperties = input<ActivityPropertyKey[] | null>(null); // null = all are allowed
  isActivityClosed = input<boolean>(false);

  showAppointments = input<boolean>(false);
  showMainListProperties = input<boolean>(false);
  showActivityStatus = input<boolean>(false);
  toggleCustomerName = input<boolean>(false);

  customerNameLength = input<number | null>(null);
  datePattern = input<string | null>(null);
  dateTimePattern = input<string | null>(null);

  isListWithoutAction = input(false);
  openDetailOverlayAction = output<ActivitiesViewModel>();

  displayedProperties = computed(() => this.calculatePropertiesToDisplay());

  constructor(private activitiesUtilService: ActivitiesUtilService) {
  }

  calculatePropertiesToDisplay(): ActivityPropertyKey[] {
    const item = this.item();
    const properties: ActivityPropertyKey[] = [
      this.showAppointments() ? 'appointmentNumber' : 'ticketNumber',
      'productName',
      'siemensId',
      'duration'
    ];

    if (this.showMainListProperties()) {
      this.pushMainListProperties(properties);
    }

    if (!this.hasNotScheduledStatusForP58Country(item)) {
      if (this.showAppointments()) {
        properties.push('plannedStart');
        properties.push('plannedEnd');
      } else if (this.showPlannedActivityStartDate(item) && (item.scheduled || (!item.completedDate && !item.dueDate))) {
        properties.push('plannedStart');
      }
    }

    if (this.showPlannedActivityStartDate(item) && !item.scheduled) {
      properties.push(item.completedDate ? 'completedDate' : 'dueDate');
    }

    if (this.showActivityStatus()) {
      properties.push('status');
    }

    const whitelistedProperties = this.whitelistedProperties();
    return whitelistedProperties ? properties.filter(property => whitelistedProperties.includes(property)) : properties;
  }

  private pushMainListProperties(properties: ActivityPropertyKey[]): void {
    properties.push('myEquipmentName');

    if (this.toggleCustomerName()) {
      properties.push('customerName');
    }
  }

  hasNotScheduledStatusForP58Country(item: ActivitiesViewModel): boolean {
    return this.activitiesUtilService.isNotScheduledForP58Country(item);
  }

  showPlannedActivityStartDate(item: ActivitiesViewModel): boolean {
    return !!this.activitiesUtilService.showPlannedActivityStartDate(item);
  }

  openDetailOverlay(item: ActivitiesViewModel): void {
    this.openDetailOverlayAction.emit(item);
  }
}
