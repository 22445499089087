<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    @if (equipmentItem) {
      <div class="modal-dialog__container" role="document">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title">{{ equipmentItem.contractType }} -
            {{ equipmentItem.contractNumber }}</h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="modal1_desc">
          <hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>
          @if (isLoaded && contractDetailList.length === 0) {
            <div class="alert alert-warning" [translate]="'EQUIPMENT_NO_CONTRACTS_FOUND'"></div>
          }
          @if (isLoaded && contractDetailList.length > 0) {
            <section class="dataview dataview-lined">
              @if (contractDetailList.length > 0) {
                <div class="dl">
                  @for (item of contractDetailList; track item) {
                    <p>
                      <span>{{ item.label }}</span>
                      <span>{{ item.value }}</span>
                    </p>
                  }
                </div>
              }
            </section>
          }
        </div>
        <div class="modal-dialog__footer">
          <button class="button button--primary" (click)="hide()" [translate]="'GENERIC_LABEL_CLOSE'"></button>
        </div>
      </div>
    }
  </div>
</ng-template>
