<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
    <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
      <div class="modal-dialog__container" role="document">

        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title" [translate]="'GET_A_QUOTE'"></h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>

        <div class="modal-dialog__body" id="modal1_desc">

          @if (showValidationMessage && !showSpinner) {
            <div class="alert">
              <div class="alert__body">
                {{ translationErrorMessage | translate}}
              </div>
            </div>
          }

          <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

          <form class="form" #modalForm="ngForm" [formGroup]="getOptionsForm">
            @if (!showSpinner) {
              <div>
                <p>
                  <span [translate]="'GET_QUOTE_TIME_SPECIFICATION'"></span>&shy; <span [translate]="'GET_QUOTE_RESPONSE_TIME'"></span>
                </p>

                <fieldset>
                  <span [translate]="'CALL_FOR_QUOTE_LABEL'"></span>
                  @if (options?.length > 0) {
                    <hl-multi-selectbox id="equipmentUpgradesOptions" [formControl]="this.getOptionsForm.controls['application']" [options]="options"
                      [isRequired]="getOptionsForm.controls['application'].value.length < 1" [allChecked]="options.length === 1" ngDefaultControl
                      (valueChange)="this.getOptionsForm.controls['application'].updateValueAndValidity()"></hl-multi-selectbox>
                  }
                  @if (isFormSubmitted && getOptionsForm.controls['application'].value.length < 1) {
                    <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
                  }
                </fieldset>

                <!-- contact -->
                <fieldset>
                <hl-contact-area
                  #contactArea
                  [useSalutationTitleValue]="true"
                  [showContactTitles]="false"
                  [showAdditionalContacts]="false"
                  [labelContact]="''"
                  [alwaysFullWidth]="true"
                  [isDisabled]="equipmentItem === null"
                  [contactForm]="getOptionsForm.controls['contact']"
                  [isFormSubmitted]="isFormSubmitted">
                </hl-contact-area>
                </fieldset>

                <fieldset>

                  <span [translate]="'ADDITIONAL_INFORMATION'"></span>
                  <span [translate]="''"></span>
                  <span [translate]="'ADDITIONAL_INFO_LABEL'"></span>

                  <div class="form-group">
                  <hl-textarea
                    [name]="'additionalInformation'"
                    [labelText]="'ADDITIONAL_INFORMATION'"
                    [isLarge]="true"
                    [maxlength]="2000"
                    [isDisabled]="getOptionsForm.controls['application'].value.length === 0"
                    [parentForm]="getOptionsForm">
                  </hl-textarea>
                  </div>

                </fieldset>
              </div>
            }
          </form>
        </div>
        @if (!showSpinner) {
          <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [isFormValid]="modalForm.valid"
            [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true" label="SEND_LABEL" name="isFormSubmitted"
            ngDefaultControl></hl-simulator-check>
        }

      </div>
    </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
