import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessagesViewModel } from '../../view-models/messages-view-model';
import { TicketsUtilService } from '../tickets/tickets-util.service';
import { NotifStatus } from '../../core-constants.service';
import { SurveyNotificationMessageType } from '../../models/message';
import { CacheableHttpClient } from '../cache/cacheable-http-client';
import { preliminaryInvoicesRestName } from '../../rest-services/invoices-rest.service';
import { PreliminaryInvoicesCacheService } from "../invoices/prelim-invoices/preliminary-invoices-cache.service";

@Injectable({providedIn: 'root'})
export class MessagingCenterService {

  private refreshMessagingCenter = new Subject<void>();
  private messages: MessagesViewModel;

  refreshMessagingCenter$ = this.refreshMessagingCenter.asObservable();
  updateMessagesEmitter = new EventEmitter<MessagesViewModel>();

  private reloadPreliminaryInvoices$: Subject<void> = new Subject();

  constructor(
    private ticketsUtilService: TicketsUtilService,
    private http: CacheableHttpClient,
    private preliminaryInvoicesCacheService: PreliminaryInvoicesCacheService
  ) {
  }

  emitRefreshMessagingCenter() {
    this.refreshMessagingCenter.next();
  }

  updateMessages(messages: MessagesViewModel) {
    if (this.hasSurveyNotifications(messages)) {
      // preload closed tickets
      this.ticketsUtilService.getTicketsViewModelList(NotifStatus.CLOSED).subscribe(list => {});
    }
    this.messages = messages;
    this.updateMessagesEmitter.emit(messages);
  }

  getMessages() {
    return this.messages;
  }

  private hasSurveyNotifications(messages: MessagesViewModel): boolean {
    return (messages.unseenMessages.concat(messages.seenMessages)).some(m => m.messageType === SurveyNotificationMessageType);
  }

  reloadPreliminaryInvoices() {
    this.http.clearCache(preliminaryInvoicesRestName);
    this.reloadPreliminaryInvoices$.next();
    this.preliminaryInvoicesCacheService.setUseCache(false);
  }

  getReloadPreliminaryInvoices(): Subject<void> {
    return this.reloadPreliminaryInvoices$;
  }
}
