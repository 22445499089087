<hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>

@if (isLoaded) {
  <section class="dataview overflow-hidden">
    @if (viewModelActivity(); as viewModelActivity) {
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-6 col-md-12">
              <div class="aspect-ratio-4x3 margin-bottom-1">
                <hl-equipment-image [equipmentKey]="viewModelActivity.equipmentKey" [large]="true"></hl-equipment-image>
              </div>
            </div>
            <div class="col-6 col-md-12">
              @if (isDotEnabled() && viewModelActivity) {
                <div *hlItemShowHideHandler="'role'; role: 'viewEquipmentRole'">
                  <div *hlItemShowHideHandler="'rescheduleActivity'; activityItem: viewModelActivity">
                    <hl-dot-schedule-button [currentAppointment]="viewModelActivity"></hl-dot-schedule-button>
                  </div>
                </div>
              } @else {
                <div *hlItemShowHideHandler="'role';role:'viewEquipmentRole'">
                  <button class="button button--small" (click)="schedule()"
                    *hlItemShowHideHandler="'rescheduleActivity';activityItem:viewModelActivity" [translate]="'PLANNED_ACTIVITIES_MOVE_APPOINTMENT'"
                    data-cy="move-appointment"></button>
                </div>
              }
              <div *hlItemShowHideHandler="'role';role:'viewEquipmentRole'">
                <button class="button button--small" (click)="scheduleModal.show()"
                  *hlItemShowHideHandler="'pmSchedule';activityItem:viewModelActivity" [translate]="'BUTTON_SCHEDULE_ACTIVITY'"></button>
              </div>
              <div *hlItemShowHideHandler="'role';role:'viewEquipmentRole'">
                <button class="button button--small" (click)="scheduleTrainingModal.show()"
                  *hlItemShowHideHandler="'trainingSchedule';activityItem:viewModelActivity" [translate]="'BUTTON_SCHEDULE_ACTIVITY'"></button>
              </div>
              <div *hlItemShowHideHandler="'role';role:'viewEquipmentRole'">
                <button class="button button--small" (click)="update()" *hlItemShowHideHandler="'updateActivity';activityItem:viewModelActivity"
                  [translate]="'GENERIC_LABEL_UPDATE'"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="multi-column multi-column-sm-2 multi-column-lg-3">
            @if (viewModelActivity.productName) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'GENERIC_LABEL_PRODUCT_NAME'"></p>
                <p data-cy="activity-overlay-product-name">{{ viewModelActivity.productName }}</p>
              </div>
            }
            @if (viewModelActivity.siemensId) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID'"></p>
                <p data-cy="activity-overlay-equipment-id">{{ viewModelActivity.siemensId }}</p>
              </div>
            }
            @if (viewModelActivity.myEquipmentName) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'GENERIC_LABEL_MY_EQUIPMENT_NAME'"></p>
                <p>{{ viewModelActivity.myEquipmentName }}</p>
              </div>
            }
            @if (showActivityStatus(viewModelActivity)) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'STATUS'"></p>
                <p>
                  <hl-activities-status-map [item]="viewModelActivity"></hl-activities-status-map>
                </p>
              </div>
            }
            @if (viewModelActivity.dueDate) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'LABEL_ACTIVITY_DUE_DATE'"></p>
                <p>{{ viewModelActivity.dueDate | datePipeWrapper: datePattern }}</p>
              </div>
            }
            @if (viewModelActivity.plannedStart && !notScheduledForP58Country()) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'PLANNED_ACTIVITIES_START_DATETIME'"></p>
                <p>{{ viewModelActivity.plannedStart | datePipeWrapper: dateTimePattern }}</p>
              </div>
            }
            @if (viewModelActivity.plannedEnd && !notScheduledForP58Country()) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'PLANNED_ACTIVITIES_END_DATETIME'"
                  *hlItemShowHideHandler="'config';config:'PLANNED_ACTIVITY_END_TIME_RENDER'"></p>
                <p *hlItemShowHideHandler="'config';config:'PLANNED_ACTIVITY_END_TIME_RENDER'">{{
                    viewModelActivity.plannedEnd
                      | datePipeWrapper: dateTimePattern
                  }}</p>
              </div>
            }
            @if (viewModelActivity.performedDate) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'LABEL_PERFORMED_DATE'"></p>
                <p>{{ viewModelActivity.performedDate | datePipeWrapper: datePattern }}</p>
              </div>
            }
            @if (viewModelActivity.completedDate) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'LABEL_COMPLETION_DATE'"></p>
                <p>{{ viewModelActivity.completedDate | datePipeWrapper: datePattern }}</p>
              </div>
            }
            @if (viewModelActivity.department) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'GENERIC_LABEL_DEPARTMENT'"></p>
                <p>{{ viewModelActivity.department }}</p>
              </div>
            }
            @for (row of addressFields; track row; let i = $index) {
              @if (row.label && row.value) {
                <div class="multi-column__item">
                  <p class="font-size-xsmall margin-bottom-0 color-black-60">{{ row.label }}</p>
                  <p>{{ row.value }}</p>
                </div>
              }
            }
            @if (viewModelActivity.qmtext) {
              <div class="multi-column__item">
                <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'PLANNED_ACTIVITY_DETAILS'"></p>
                <p>{{ viewModelActivity.qmtext }}</p>
              </div>
            }
          </div>
          @if (viewModelActivity.ruhSystemUpdate && this.showSystemUpdatesRuh) {
            <div class="multi-column multi-column-sm-2 multi-column-lg-3">
              @if (viewModelActivity.ruhSystemUpdate.updateNumber) {
                <div class="multi-column__item">
                  <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'SYSTEM_UPDATES_UPDATE_NUMBER'"></p>
                  <p class="cursor-pointer" data-cy="activity-overlay-product-name" (click)="redirectToSystemUpdateOverview()">
                    <i class="icon-arrow-right-medium"> </i>
                    {{ viewModelActivity.ruhSystemUpdate?.updateNumber }} </p>
                </div>
              }
              @if (viewModelActivity.ruhSystemUpdate.updateType) {
                <div class="multi-column__item">
                  <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'SYSTEM_UPDATES_RUH_DISTRIBUTION_WAY'"></p>
                  <p data-cy="activity-overlay-equipment-id">{{ viewModelActivity.ruhSystemUpdate?.updateType | translate }}</p>
                </div>
              }
              @if (viewModelActivity.ruhSystemUpdate.displayStatus) {
                <div class="multi-column__item">
                  <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'GENERIC_LABEL_UPDATE_STATUS'"></p>
                  <p data-cy="activity-overlay-product-name">{{ viewModelActivity.ruhSystemUpdate?.displayStatus | translate }}</p>
                </div>
              }
              @if (viewModelActivity.ruhSystemUpdate.version) {
                <div class="multi-column__item">
                  <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'SYSTEM_UPDATES_RUH_TARGET_SOFTWARE_VERSION'"></p>
                  <p>{{ viewModelActivity.ruhSystemUpdate?.version }}</p>
                </div>
              }
              @if (viewModelActivity.ruhSystemUpdate.category) {
                <div class="multi-column__item">
                  <p class="font-size-xsmall margin-bottom-0 color-black-60" [translate]="'CATEGORY'"></p>
                  <p>{{ getRuhSystemUpdateCategory(viewModelActivity.ruhSystemUpdate?.category) | translate }}</p>
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          @if (viewModelActivity !== null) {
            <hl-notification-attachments [viewModel]="viewModelActivity" [attachmentType]="'activity'"></hl-notification-attachments>
          }
        </div>
      </div>
      @if (this.showSystemUpdatesRuh) {
        <div class="row">
          <div class="col-lg-12">
            <hl-progress-indicator [loading]="!isLoadedSystemUpdatesRuhDetails"></hl-progress-indicator>
            @if (isLoadedSystemUpdatesRuhDetails && viewModelActivity.systemUpdatesRuhDetails && datePattern) {
              <dl hlCollapsable [title]="'.collapsable__title'" class="accordion" role="presentation">
                <div data-cy="system-updates-detail-history">
                  <dt aria-level="3" class="accordion__header">
                    <button type="button" class="accordion__title collapsable__title icon-arrow-down-large" aria-controls="panel1"
                      aria-expanded="false" [translate]="'LABEL_STATUS_DETAILS'"></button>
                  </dt>
                  <dd class="accordion__panel" aria-labelledby="panel1-button" role="region" aria-hidden="true">
                    <table class="table table--small-paddings table--no-borders" aria-hidden="true">
                      @for (item of viewModelActivity.systemUpdatesRuhDetails; track item) {
                        <tr>
                          <td class="col-3">{{ item.dateTime | datePipeWrapper: datePattern }}</td>
                          <td class="col-9">
                            <div class="row font-weight-bold">{{ item.displayedStatus | translate }}</div>
                            <div class="row">{{ item.expectedCustomerActivity | translate }}</div>
                          </td>
                        </tr>
                      }
                    </table>
                  </dd>
                </div>
              </dl>
            }
          </div>
        </div>
      }
    }
  </section>
}

<hl-reschedule-activity-modal [activitiesItem]="viewModelActivity()" #rescheduleModal></hl-reschedule-activity-modal>
<hl-reschedule-appointment-modal [activitiesItem]="viewModelActivity()"
  [activityAppointments]="appointmentsOverview"></hl-reschedule-appointment-modal>
<hl-schedule-activity-modal [activitiesItem]="viewModelActivity()" #scheduleModal></hl-schedule-activity-modal>
<hl-schedule-training-modal [activitiesItem]="viewModelActivity()" #scheduleTrainingModal></hl-schedule-training-modal>
<hl-ticket-update-modal [ticketAction]="'activity'" [ticketItem]="updateActivity" #updateModal></hl-ticket-update-modal>
<hl-dot-request-rescheduling-modal [activitiesItem]="viewModelActivity()"
  [activityAppointments]="appointmentsOverview"></hl-dot-request-rescheduling-modal>
