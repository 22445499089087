import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BrowserStateService } from '../../../core/services/browser-state.service';
import { SystemUpdatesService } from '../../../core/services/system-updates/system-updates-service';
import { Subscription } from 'rxjs';
import { OverlayCommunicationService } from '../../../core/component-communication-services/overlay-close/overlay-communication.service';
import { OverlayDirective } from '../../directives/overlay-popup/overlay.directive';

const classNames = {
  OPEN: 'is-open',
  OVERFLOW: 'overflow-hidden',
  CONTAINER: 'ddc-overlay'
};

const routerUrls = [
  // '\\/equipment\\/.*\\/info\\?equipmentIdentifier=.*',
  '\\/equipment\\/.*\\/update\\?equipmentIdentifier=.*',
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/overview?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/activity?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/update?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/security?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/info?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/documents?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/contractModel?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/ticketHistory?$/gm,
  /^\/equipment\/([a-zA-Z0-9-_\|:?;&]+)\/optionsUpgrades?$/gm
];

@Component({
  selector: 'hl-overlay',
  templateUrl: './overlay.component.html'
})
export class OverlayComponent implements OnInit, OnDestroy {
  @Input() isShown = false;
  @Input() closeUrl: string[];
  @Input() relativeTo: ActivatedRoute;
  @Input() closeOverlayAfterSave = false;
  @Input() wideView = false;
  @Input() preventHideOverlayAfterClose = false;

  @Output() save = new EventEmitter<Event>();
  @Output() afterHide = new EventEmitter<void>();
  @Output() beforeHide = new EventEmitter<Event>();

  @ViewChild('overlayPopup') overlayDirective: OverlayDirective;
  @ViewChild('ddcOverlay') ddcOverlay: ElementRef;

  closeOverlayAfterSaveSubject: Subscription;

  private readonly overlayClass = '.ddc-overlay';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private browserStateService: BrowserStateService,
    private systemUpdateService: SystemUpdatesService,
    private overlayCommunicationService: OverlayCommunicationService
  ) {
    if (this.router.events) {
      router.events.subscribe(value => {
        if (value instanceof NavigationEnd && value.url) {
          for (const routerUrl of routerUrls) {
            if (value.url.match(routerUrl) && value.url.match('\\/equipment\\/.*\\/update\\?equipmentIdentifier=.*')) {
              systemUpdateService.onEquipmentLinkClickEmit();
            }
          }
        }
      });
    }
  }

  show() {
    this.overlayDirective.show();
    this.renderer.addClass(document.body, classNames.OVERFLOW);
    setTimeout(() => {
      if (this.ddcOverlay) {
        this.ddcOverlay.nativeElement.focus();
      }
    }, 100);

    this.isShown = true;
  }

  close(e: Event) {
    const ele: Element = (e.target || e.srcElement) as Element;

    if (ele.className.indexOf && ele.className.indexOf(this.overlayClass.substring(1)) !== -1) {
      !this.closeOverlayAfterSave ? this.hide(e) : this.save.emit(e);
    }
    return;
  }

  saveAndHide(e: Event) {
    this.save.emit(e);
  }

  hide(e: Event) {
    if (this.preventHideOverlayAfterClose) {
      this.beforeHide.emit();
      return;
    }
    if (e) {
      e.preventDefault();
    }
    this.renderer.removeClass(document.body, classNames.OVERFLOW);
    this.isShown = false;

    setTimeout(() => {
      this.overlayDirective.hide();
    }, 300);

    this.afterHide.emit();

    if (!this.closeUrl) {
      return;
    }

    this.browserStateService.setUserNavigation();
    this.navigateToCloseUrl().then(() => {
      this.browserStateService.resetUserNavigation();
    });
    return false;
  }

  navigateToCloseUrl(): Promise<boolean> {
    if (this.closeUrl) {
      const extras = this.relativeTo && {relativeTo: this.relativeTo};
      return this.router.navigate(this.closeUrl, extras);
    }
  }

  ngOnDestroy(): void {
    if (this.isShown) {
      this.renderer.removeClass(document.body, classNames.OVERFLOW);
    }
    this.closeOverlayAfterSaveSubject.unsubscribe();
  }

  ngOnInit(): void {
    this.closeOverlayAfterSaveSubject = this.overlayCommunicationService.onOverlayChange$.subscribe(
      event => {
        this.forceHide(event);
      }
    );
  }

  forceHide(event) {
    const preventHideOverlayAfterCloseInitValue = this.preventHideOverlayAfterClose;
    this.preventHideOverlayAfterClose = false;
    this.hide(event);
    this.preventHideOverlayAfterClose = preventHideOverlayAfterCloseInitValue;
  }
}
