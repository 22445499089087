import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, input, inject, signal, effect, untracked } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivityListComponent } from '../../../../activities-common/activity-list/activity-list.component';
import { BasicComponentsSharedModule } from 'app/core/basic-components-shared.module';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { DotDateService } from '../../services/dot-date.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DotTimeframeFieldErrors, DotTimeframeFormGroup } from '../../models/dot-timeframe';
import { AlertComponent } from '../../../../shared/components/alert/alert.component';
import { ActivitiesViewModel } from '../../../../core/view-models/activities-view-model';

@Component({
  selector: 'hl-dot-schedule-timeframe-specification',
  templateUrl: './dot-schedule-timeframe-specification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AlertComponent,
    ActivityListComponent,
    TranslateModule,
    BasicComponentsSharedModule,
    ReactiveFormsModule
  ]
})
export class DotScheduleTimeframeSpecificationComponent {

  private dotDateService = inject(DotDateService);
  private cdRef = inject(ChangeDetectorRef);

  dueDate = input.required<string>();
  affectedActivities = input.required<ActivitiesViewModel[]>();
  formGroup = input.required<DotTimeframeFormGroup>();
  config = input.required<Record<string, string>>();
  showErrors = input<boolean>(false);

  minDate = signal<Date>(new Date());
  maxDate = signal<Date>(new Date());

  earliestDateError = signal<string | null>(null);
  latestDateError = signal<string | null>(null);

  weekendDays = computed(() => this.dotDateService.parseWeekendDaysFromConfig(this.config().DOT_WEEKEND_DAYS));
  disableDays = computed(() => {
    const weekendDays = this.weekendDays();
    return [(date: Date): boolean => this.dotDateService.isDateWeekend(date, weekendDays)];
  });

  constructor() {
    effect(() => {
      const dueDate = this.dueDate();
      const weekendDays = this.weekendDays();
      const formGroup = this.formGroup();

      untracked(() => {
        const { minDate, maxDate } = this.dotDateService.calculateTimeframeDates(new Date(dueDate), weekendDays);
        this.minDate.set(minDate);
        this.maxDate.set(maxDate);

        // HACK: Date pickers reset values with reactive forms when they're created, so we always set the values after
        //       a timeout. setTimeout & current earliest/latest date setting can be removed when this is fixed.
        const { earliestDate, latestDate } = formGroup.value;
        const [newEarliestDate, newLatestDate] = earliestDate === null && latestDate === null
          ? [minDate, maxDate]
          : [earliestDate, latestDate];

        setTimeout(() => {
          formGroup.setValue({
            earliestDate: new Date(newEarliestDate),
            latestDate: new Date(newLatestDate)
          });
          this.cdRef.markForCheck();
        });
      });
    });

    toObservable(this.formGroup).pipe(
      switchMap(formGroup => formGroup.statusChanges.pipe(
        map(() => formGroup)
      )),
      map(formGroup => this.getErrorLabelsFrom(formGroup)),
      takeUntilDestroyed()
    ).subscribe(({ earliestDateError, latestDateError }) => {
      this.earliestDateError.set(earliestDateError);
      this.latestDateError.set(latestDateError);
    });
  }

  showActivityStatus(activity: ActivitiesViewModel): boolean {
    return activity.tpFstatus !== '-1';
  }

  private getErrorLabelsFrom(formGroup: DotTimeframeFormGroup): DotTimeframeFieldErrors {
    const { earliestDate, latestDate } = formGroup.controls;

    if (!earliestDate.errors && !latestDate.errors) {
      const earliestDateError = formGroup.errors?.dateInterval
        ? 'DOT_SCHEDULE_TIMEFRAME_EARLIEST_BEFORE_LATEST_ERROR'
        : null;

      return { earliestDateError, latestDateError: null };
    }

    return {
      earliestDateError: earliestDate.errors?.required ? 'GENERIC_LABEL_FILL_INFORMATION' : null,
      latestDateError: latestDate.errors?.required ? 'GENERIC_LABEL_FILL_INFORMATION' : null
    };
  }
}
