import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';

export type AlertIndicatorType = 'success' | 'error' | 'warning' | 'info';

@Component({
  selector: 'hl-alert',
  templateUrl: './alert.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  title = input<string | null>(null);
  indicator = input<AlertIndicatorType | null>(null);
  hideButtonVisible = input<boolean>(true);
  hideButtonClicked = output();

  open = signal<boolean>(true);

  hide(): void {
    this.open.set(false);
    this.hideButtonClicked.emit();
  }
}
