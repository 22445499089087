<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__body" id="modal1_desc">
        @if (showNotificationsOverlay) {
          <hl-create-user-notifications-page #notificationsOverlay></hl-create-user-notifications-page>
        }
      </div>
      <div class="modal-dialog__footer">
        <button class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_SAVE'"></button>
      </div>
    </div>
  </div>
</ng-template>
