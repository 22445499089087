import { SimulatorModalComponent } from '../../modal-popup/simulator-modal/simulator-modal.component';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';

const SIMULATOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SimulatorCheckComponent),
  multi: true
};

@Component({
  selector: 'hl-simulator-check',
  templateUrl: './simulator-check.component.html',
  providers: [SIMULATOR_VALUE_ACCESSOR]
})
export class SimulatorCheckComponent implements ControlValueAccessor {
  @Input()
  isFormValid: boolean;
  @Input()
  isModalType: boolean;
  @Input()
  btnCSS: string;
  @Input()
  isFormValidationRequired: boolean;
  @Input()
  modalRef: SimulatorModalComponent;
  @Input()
  label: string;
  @Input()
  isDisabled: boolean;
  @Input()
  parentHasCancelModal = false;
  @Input()
  dataCyCancelButton: string;
  @Input()
  dataCySaveButton: string;

  @Output()
  onClickOk = new EventEmitter<any>();
  @Output()
  onClickClose = new EventEmitter<any>();
  @Output()
  hideParent = new EventEmitter<any>();

  model: boolean;
  isRouteBackRequired = false;

  constructor(private userRestService: UserRestService) {
  }

  onModelChange: Function = (_: any) => {
  };
  onModelTouched: Function = () => {
  };

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this.model = value;
    } else {
      this.model = false;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // intentionally left empty
  }

  ok() {
    this.userRestService.getUser().subscribe(userResponse => {
      if (userResponse.simulator) {
        if (this.isFormValidationRequired) {
          this.checkSimulatorPopUpBasedOnValidation();
        } else {
          this.checkSimulatorPopUpWithoutValidation();
        }
      } else {
        if (this.isFormValidationRequired) {
          if (!this.isFormValid) {
            this.setModelChange();
            return;
          }
        }
        this.onClickOk.emit();
      }
    });
  }

  hide() {
    this.onClickClose.emit();
  }

  /**
   *
   * @description
   * open the simulator modal pop up by checking the form validation.
   * if not validated not open it.
   * For e.g. ticket update, close, handover, reschedule, deactivate.
   */
  checkSimulatorPopUpBasedOnValidation() {
    // required for setting form submitted to show validation on parent controller
    this.setModelChange();
    const formValid = this.isFormValid;

    // Close the parent modal if there exist one
    if (this.isModalType && this.isFormValid) {
      if (this.parentHasCancelModal) {
        this.hideParent.emit();
      } else {
        this.onClickClose.emit();
      }
    }

    if (formValid) {
      this.modalRef.show();
    }
  }

  /**
   *
   * @description
   * open the simulator modal pop up without checking form
   * For e.g:- equipment edit and component edit.
   */
  checkSimulatorPopUpWithoutValidation() {
    // Close the parent modal if there exist one
    if (this.isModalType) {
      if (this.parentHasCancelModal) {
        this.hideParent.emit();
      } else {
        this.onClickClose.emit();
      }
    }
    this.modalRef.show();
  }

  setModelChange() {
    this.model = true;
    this.onModelChange(this.model);
    this.onModelTouched(this.model);
  }
}
