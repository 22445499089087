import { Injectable } from '@angular/core';
import { DropdownOptions } from '../../models/dropdown-options';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';

@Injectable({providedIn: 'root'})
export class CustomerConstantsService {

  getCustomerGroupImpersonationSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'name',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'LABEL_GROUP_NAME',
          value: 'name'
        },
        {
          title: 'LABEL_GROUP_VISIBILITY',
          value: 'type'
        },
        {
          title: 'LABEL_COUNTRY',
          value: 'countryLabel'
        }
      ]
    };
  }

  getCustomerImpersonationSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'customerName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_DESC_NAME',
          value: 'customerName'
        },
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_NUMBER',
          value: 'customerId'
        },
        {
          title: 'GENERIC_LABEL_CITY',
          value: 'city'
        }
      ]
    };
  }

  getGroupTypeListSkeleton(): DropdownOptions[] {
    return [
      {
        title: 'LABEL_GROUP_VISIBILITY_PUBLIC',
        value: 'PUBLIC'
      },
      {
        title: 'LABEL_GROUP_VISIBILITY_PRIVATE',
        value: 'PRIVATE'
      }
    ];
  }

  getImpersonatedCustomersSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'customerName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_DESC_NAME',
          value: 'customerName'
        },
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_NUMBER',
          value: 'customerId'
        },
        {
          title: 'GENERIC_LABEL_CITY',
          value: 'city'
        },
        {
          title: 'LABEL_COUNTRY',
          value: 'countryLabel'
        }
      ]
    };
  }

  getImpersonatedGroupCustomersSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'customerName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_DESC_NAME',
          value: 'customerName'
        },
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_NUMBER',
          value: 'customerId'
        },
        {
          title: 'GENERIC_LABEL_CITY',
          value: 'city'
        }
      ]
    };
  }

  getMyCustomersWithoutStateSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'customerName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_DESC_NAME',
          value: 'customerName'
        },
        {
          title: 'GENERIC_LABEL_STREET',
          value: 'street'
        },
        {
          title: 'GENERIC_LABEL_CITY',
          value: 'city'
        },
        {
          title: 'GENERIC_LABEL_POSTAL_CODE',
          value: 'postalCode'
        },
        {
          title: 'GENERIC_LABEL_ACTIVE',
          value: 'myCustomer'
        }
      ]
    };
  }

  getMyCustomersWithStateSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'customerName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'USER_IMPERSONATION_SORTING_CUSTOMER_DESC_NAME',
          value: 'customerName'
        },
        {
          title: 'GENERIC_LABEL_STREET',
          value: 'street'
        },
        {
          title: 'GENERIC_LABEL_CITY',
          value: 'city'
        },
        {
          title: 'GENERIC_LABEL_POSTAL_CODE',
          value: 'postalCode'
        },
        {
          title: 'GENERIC_LABEL_STATE',
          value: 'state'
        },
        {
          title: 'GENERIC_LABEL_ACTIVE',
          value: 'myCustomer'
        }
      ]
    };
  }
}
