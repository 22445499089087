<div class="datalist item-status">

  <hl-progress-indicator class="spinner-margin-top" [loading]="!isLoaded"></hl-progress-indicator>

  @if (isLoaded && viewModelList.length === 0) {
    <div class="alert">
      <div class="alert__body" [translate]="'GENERIC_LABEL_TICKET_NO_DATA'"></div>
    </div>
  }
  @if (isLoaded && viewModelList.length > 0) {
    <div class="collection bg-color-white">
      @for (item of viewModelList; track item.ticketKey) {
        <div class="collection-item list-item">
          <div class="collection__content">
            <p class="list-item__title">{{ item.description }}</p>
            <ul class="bare-list info-list">
              @if (item.ticketNumber) {
                <li class="info-list__item label-status-{{item.problemSeverityID | getTicketProblemSeverityColor}}">{{ item.ticketNumber }}</li>
              }
              @if (item.productName) {
                <li class="info-list__item">
                  <i class="icon-label" hlTooltip [documentOverlay]="true"
                    [title]="'GENERIC_LABEL_PRODUCT_NAME' | translate"></i> {{ item.productName }}
                </li>
              }
              @if (item.siemensEquipmentId) {
                <li class="info-list__item">
                  <i class="icon-id-card" hlTooltip [documentOverlay]="true"
                    [title]="'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID' | translate"></i> {{ item.siemensEquipmentId }}
                </li>
              }
              @if (item.ticketCreationTimestamp) {
                <li class="info-list__item">
                  <i class="icon-time" hlTooltip [documentOverlay]="true"
                    [title]="'CREATION_TIME' | translate"></i> {{ item.ticketCreationTimestamp | datePipeWrapper : dateTimePattern }}
                </li>
              }
              @if (item.problemSeverityDescription) {
                <li class="info-list__item">
                  <i class="icon-information" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_OPERATIONAL_STATE' | translate"></i>
                  {{ item.problemSeverityDescription }}
                </li>
              }
            </ul>
          </div>
        </div>
      }
    </div>
  }

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-right">
      @if (listWithoutPagination.length > paginationItems) {
        <button class="btn btn-sm btn-primary" (click)="loadMore()" [translate]="'LOAD_MORE'"></button>
      }
    </div>
  </div>

</div>
