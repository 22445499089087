<div class="modal-dialog__footer hidden-xs-down">
  <button class="button" (click)="hide()" attr.data-cy="{{dataCyCancelButton}}-cancel-btn"
          [translate]="'GENERIC_LABEL_CANCEL'"></button>
  <button [disabled]="isDisabled" class="button button--primary ln-text-overflow-ellipsis" (click)="ok()"
          attr.data-cy="{{dataCySaveButton}}-save-btn" [translate]="label"></button>
</div>
<div class="hidden-sm-up margin-top-1">
  <div class="col-12 text-align-right">
    <button class="button" (click)="hide()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
  </div>
  <div class="col-12 text-align-right">
    <button [disabled]="isDisabled" class="button button--primary ln-text-overflow-ellipsis" (click)="ok()"
            [translate]="label"></button>
  </div>
</div>

<hl-simulator-modal #simulatorModal [isRouteBackRequired]="isRouteBackRequired"></hl-simulator-modal>
