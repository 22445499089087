<p data-cy="read-more">
  @if (beforeText.length > 0) {
    <span>{{ beforeText }}</span>
  }
  <span [innerHTML]="currentText"></span>
  @if (maxWords > 0) {
    <br>
  }
  <button #toggle class="button button--text button--small margin-bottom-0" [class.icon-plus]="isCollapsed" [class.icon-minus]="!isCollapsed"
    [class.hidden]="hideToggle" (click)="toggleView($event)" [translate]="isCollapsed ? 'GENERIC_LABEL_MORE' : 'GENERIC_LABEL_LESS'"></button>
</p>
