import { Component, Input, OnInit } from '@angular/core';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';

@Component({
  selector: 'hl-equipment-actv-count',
  templateUrl: './equipment-actv-count.component.html'
})
export class EquipmentActvCountComponent implements OnInit {

  @Input() equipment: { key: string };

  numberOfActv = 0;

  constructor(private activitiesRestService: ActivitiesRestService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.activitiesRestService.getOpenActivitiesCount(this.equipment.key).subscribe((count) => {
      this.numberOfActv = count;
    });
  }
}
