import { Directive, EventEmitter, Input, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: '[hlOverlay]',
  exportAs: 'overlay'
})
export class OverlayDirective implements OnInit {
  @Input()
  overlayTemplate: TemplateRef<any>;
  @Input()
  disableKeyboardClose = false;

  @Output()
  onKeyHideEmit = new EventEmitter<void>();

  overlayRef: OverlayRef = null;
  overlayContentPortal: TemplatePortal;
  private overlayConfig: OverlayConfig;

  constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {

    this.overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: 'backdrop',
      panelClass: 'panel',
      positionStrategy: this.getPositionStrategy()
    });

    this.overlayContentPortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
  }

  show() {
    if (this.overlayRef) {
      return;
    }
    this.overlayRef = this.overlay.create(this.overlayConfig);
    this.overlayRef.attach(this.overlayContentPortal);

    const overlayElement = this.overlayRef.overlayElement.firstElementChild as HTMLElement;
    if (!this.disableKeyboardClose) {
      overlayElement.addEventListener('keydown', this.onKeyDown.bind(this));
    }
    overlayElement.focus();
  }

  hide() {
    if (this.overlayRef == null) {
      return;
    }
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.overlayRef = null;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.code === 'Escape') {
      if (!this.overlayRef) {
        return;
      }

      event.preventDefault();
      this.onKeyHideEmit.emit();
    }
  }

  getPositionStrategy() {
    return this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }

}
