import { SystemUpdateRuhBase } from '../models/systemUpdates/system-update-ruh-base';
import { SystemUpdateRuhStatusModel } from '../models/systemUpdates/system-update-ruh-status-model';
import { PackageInfoViewModel } from './package-info-view-model';
import { LinkItem } from '../../shared/components/read-more/read-more-arrays/read-more-arrays.component';

export class SystemUpdateRuhBaseViewModel {
  public title: string;
  public updateNumber: string;
  private category: string;
  public softwareVersion: string;
  public statusModel: SystemUpdateRuhStatusModel;
  public packageInfo?: PackageInfoViewModel;
  public cves?: LinkItem[]

  constructor(model: SystemUpdateRuhBase) {
    this.title = model.title;
    this.updateNumber = model.number;
    this.category = model.type;
    this.softwareVersion = model.softwareVersion;
    this.statusModel = model.statusModel;
    if (model.packageInfo) {
      this.packageInfo = new PackageInfoViewModel(model.packageInfo);
    }
    if (model.cves) {
      this.cves = model.cves;
    }
  }

  get categoryKey(): string|null {
    if (this.category) {
      return `LABEL_EVENT_CATEGORY_${this.category}`;
    }
    return null;
  }
}
