<div [formGroup]="contactITForm">
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_IT_ADMIN" name="itAdministratorName"
        [isRequired]="contactITForm.controls['itAdministratorName'].errors && contactITForm.controls['itAdministratorName'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !contactITForm.controls['itAdministratorName'].valid"
        [formControl]="contactITForm.controls['itAdministratorName']" ngDefaultControl></hl-textfield>

      <hl-textfield label="LABEL_SAO_IT_ADMIN_PHONE" name="itAdministratorPhone"
        [isRequired]="contactITForm.controls['itAdministratorPhone'].errors && contactITForm.controls['itAdministratorPhone'].errors['required']"
        invalidLabel="GENERIC_LABEL_NOT_VALID_PHONE" [isInvalid]="isFormSubmitted && !contactITForm.controls['itAdministratorPhone'].valid"
        [formControl]="contactITForm.controls['itAdministratorPhone']" ngDefaultControl></hl-textfield>

      <hl-textfield label="LABEL_SAO_IT_ADMIN_EMAIL" name="itAdministratorEmail"
        [isRequired]="contactITForm.controls['itAdministratorEmail'].errors && contactITForm.controls['itAdministratorEmail'].errors['required']"
        invalidLabel="GENERIC_LABEL_NOT_VALID_EMAIL" [isInvalid]="isFormSubmitted && !contactITForm.controls['itAdministratorEmail'].valid"
        [formControl]="contactITForm.controls['itAdministratorEmail']" ngDefaultControl></hl-textfield>
    </div>
  </div>
</div>
