import { NgModule } from '@angular/core';
import { UploadComponent } from '../shared/form-group/upload/upload.component';
import { AdvisoryUploadFilesModalComponent } from '../shared/modal-popup/advisory-upload-files-modal/advisory-upload-files-modal.component';
import { UploadUpdatePackageModalComponent } from '../shared/modal-popup/upload-update-package-modal/upload-update-package-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicComponentsSharedModule } from '../core/basic-components-shared.module';
import { CancelModalComponent } from '../shared/modal-popup/cancel-modal/cancel-modal.component';
import { LockedUploadUpdateModalComponent } from '../shared/modal-popup/locked-upload-update-modal/locked-upload-update-modal.component';
import { ExistsUploadUpdateModalComponent } from '../shared/modal-popup/exist-upload-update-modal/exists-upload-update-modal.component';
import { UploadSubprocessorFilesModalComponent } from 'app/shared/modal-popup/upload-subprocessor-files-modal/upload-subprocessor-files-modal.component';
import { AttachmentComponent } from 'app/shared/form-group/attachment/attachment.component';
import { PortalsModule } from './portals.module';

@NgModule({
  declarations: [
    UploadComponent,
    AttachmentComponent,
    AdvisoryUploadFilesModalComponent,
    UploadSubprocessorFilesModalComponent,
    UploadUpdatePackageModalComponent,
    LockedUploadUpdateModalComponent,
    ExistsUploadUpdateModalComponent,
    CancelModalComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BasicComponentsSharedModule,
    PortalsModule
  ],
  exports: [
    UploadComponent,
    AttachmentComponent,
    AdvisoryUploadFilesModalComponent,
    UploadSubprocessorFilesModalComponent,
    UploadUpdatePackageModalComponent,
    LockedUploadUpdateModalComponent,
    ExistsUploadUpdateModalComponent,
    CancelModalComponent
  ]
})
export class UploadFilesSharedModule {
}
