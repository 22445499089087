import { Injectable } from '@angular/core';
import { DeliveryMilestones } from '../../models/tickets/delivery-milestones';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { LogisticRestService } from '../../rest-services/logistic-rest.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LogisticMilestonesUtilService {
  configService: CountryConfigRestService;
  servicePartRestService: LogisticRestService;
  private allowedTicketTypes: string[] = [];

  constructor(configService: CountryConfigRestService, servicePartRestService: LogisticRestService) {
    this.configService = configService;
    this.servicePartRestService = servicePartRestService;
    this.setAllowedTicketTypes();
  }

  setAllowedTicketTypes(): void {
    this.configService.getConfig().subscribe(configResponse => {
      const allowed = configResponse.LOGISTIC_MILESTONES_ALLOWED_TICKET_TYPES;
      if (allowed) {
        this.allowedTicketTypes = allowed.trim().split(',');
      }
    });
  }

  isTicketTypeAllowed(ticketType: string): boolean {
    return this.allowedTicketTypes.includes(ticketType);
  }

  getDeliveryMilestones(ticketNumber: string): Observable<DeliveryMilestones[]> {
    return this.servicePartRestService.getDeliveryMilestones(ticketNumber);
  }

  isDeliveryMilestonesAvailable(ticketType: string, ticketNumber: string): Observable<boolean> {
    if (!this.isTicketTypeAllowed(ticketType)) {
      return of(false);
    }
    return this.getDeliveryMilestones(ticketNumber).pipe(
      map(response => response.length > 0)
    );
  }

  clearDeliveryMilestonesCache(ticketNumber: string): void {
    this.servicePartRestService.clearCache(ticketNumber);
  }
}
