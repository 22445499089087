import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseUptimeResponse } from '../../models/reporting/base-uptime-response';
import { BaseEducationBalanceResponse } from '../../models/reporting/base-education-balance-response';
import { BasePerformanceSummaryResponse } from '../../models/reporting/base-performance-summary-response';

@Injectable({providedIn: 'root'})
export class ReportsCacheService {
  private reportType: string;
  private responseBody: BaseUptimeResponse[] | BaseEducationBalanceResponse | BasePerformanceSummaryResponse;
  private responseBodySubject =
    new BehaviorSubject<BaseUptimeResponse[] | BaseEducationBalanceResponse | BasePerformanceSummaryResponse>(null);
  private selectedMyEquipment: boolean;
  private topRecords: number;
  private startDate: Date;
  private endDate: Date;
  private headerText: string;
  private footerText: string;
  private modalities: string[];
  private reportDataLoaded: boolean;


  setReportType(report) {
    this.reportType = report;
  }

  setResponseBody(response) {
    this.responseBody = response;
  }

  setIsSelectMyEquipment(select: boolean) {
    this.selectedMyEquipment = select;
  }

  setTopRecords(records: number) {
    this.topRecords = records;
  }

  setStartDate(startDate: Date) {
    this.startDate = startDate;
  }

  setEndDate(endDate: Date) {
    this.endDate = endDate;
  }

  setHeaderText(header: string) {
    this.headerText = header;
  }

  setFooterText(footer: string) {
    this.footerText = footer;
  }

  setModalities(modalities: string[]) {
    this.modalities = modalities;
  }

  setReportDataLoaded(loaded: boolean) {
    this.reportDataLoaded = loaded;
  }

  /*  getters   */

  isSelectMyEquipment(): boolean {
    return this.selectedMyEquipment;
  }

  getResponseBody(): Observable<any> {
    return this.responseBodySubject.asObservable();
  }

  emitResponse(response) {
    this.responseBodySubject.next(response);
  }

  getReportType(): string {
    return this.reportType;
  }

  getTopRecords() {
    return this.topRecords;
  }

  getStartDate() {
    return this.startDate;
  }

  getEndDate() {
    return this.endDate;
  }

  getHeaderText() {
    return this.headerText;
  }

  getFooterText() {
    return this.footerText;
  }

  getModalities() {
    return this.modalities;
  }

  getReportDataLoaded() {
    return this.reportDataLoaded;
  }

}
