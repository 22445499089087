import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { ActivatedRoute } from '@angular/router';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { isEqual } from 'lodash-es';
import { TicketsCacheService } from '../../../core/services/cache/tickets-cache.service';
import { LogService } from '../../../core/services/log/log.service';
import { StringUtilService } from '../../../core/utils/string-util.service';
import { Ticket } from '../../../core/models/tickets/ticket';
import { BaseTicketDetailView } from '../../../core/base-class/base-ticket-detail-view';

@Component({
  selector: 'hl-tickets-history',
  templateUrl: './tickets-history.component.html'
})
export class TicketsHistoryComponent extends BaseTicketDetailView
  implements OnInit, OnChanges {

  // Note:- This is not mandatory and is required only for showing closed ticket in modal/popup
  @Input()
  closedTicketInput: Ticket;

  ticketsUtilService: TicketsUtilService;
  ticketsCacheService: TicketsCacheService;

  solutionTextAvailable: boolean;
  solutionFlagAvailable: boolean;
  ticketDetailsRender = false;

  solutionText: any;
  showTicketHistory = false;
  showSolutionText = false;

  constructor(
    configService: CountryConfigRestService,
    ticketsUtilService: TicketsUtilService,
    activatedRoute: ActivatedRoute,
    lifeNetUtilService: LifeNetUtilService,
    ticketsCacheService: TicketsCacheService,
    logService: LogService,
    stringUtilService: StringUtilService
  ) {
    super(
      configService,
      ticketsUtilService,
      activatedRoute,
      lifeNetUtilService,
      ticketsCacheService,
      logService,
      stringUtilService
    );
  }

  ngOnInit() {
    this.init();
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties(): void {
    this.viewModelTicket = null;
  }

  /**
   * @description Additional config properties to be initialized for derived class
   */
  afterConfigProperties(config): void {
    // check if ticket detail is shown or not
    this.ticketDetailsRender = isEqual(config.TICKET_DETAILS_RENDER, 'true');
  }

  /**
   * @description Additional properties to be initialized after view model is loaded for derived class
   */
  afterLoadViewModelItem(config): void {
    // since afterLoadViewModelTicket is also invoked on route change
    this.solutionTextAvailable = false;
    this.solutionFlagAvailable = false;
    this.solutionText = [];

    // set and parse solutionText for line breaks
    if (this.viewModelTicket.solutionText) {
      this.solutionText = this.stringUtilService.parseAndSplitInputStringToLines(
        this.viewModelTicket.solutionText
      );
    }

    // set conditions to handle error message cases
    this.solutionTextAvailable = this.ticketDetailsRender && !!(this.viewModelTicket.solutionText);
    this.solutionFlagAvailable = this.ticketDetailsRender &&
      !(isEqual(this.longText.length, 0)) &&
      this.viewModelTicket.solutionExists;
    if (this.viewModelTicket.longText) {
      this.showTicketHistory = this.viewModelTicket.longText.length > 0;
    }
    this.showSolutionText = this.solutionText.length > 0;
  }

  /**
   * @abstract
   */
  getClosedTicketInput(): Ticket {
    return this.closedTicketInput;
  }
}
