import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { SystemUpdateDetail } from '../models/systemUpdates/system-update-detail';
import { DownloadUrl } from '../models/download-url';
import { SystemUpdateBase } from '../models/systemUpdates/system-update-base';
import { SystemUpdateInput } from '../models/systemUpdates/system-update-input';
import { EquipmentSecretKeyDTO } from '../models/systemUpdates/equipment-secret-key';
import { SystemUpdateRuhBase } from '../models/systemUpdates/system-update-ruh-base';
import { SystemUpdateRuhDetail } from '../models/systemUpdates/system-update-ruh-detail';
import { RemoveLeadingZeroPipe } from '../../shared/pipes/removeLeadingZero/remove-leading-zero.pipe';
import { SystemUpdatesCves } from '../models/systemUpdates/system-update-cves';

// system updates
export const systemUpdateDetailRestName = restEndPoint + 'system-updates';
export const systemUpdateAffectedEquipmentKeysRestName = restEndPoint + 'system-updates/affectedEquipmentKeys';
export const systemUpdatesRestName = restEndPoint + 'system-updates/all';
export const systemUpdatesDownloadUrlRestName = restEndPoint + 'system-updates/download-url';
export const systemUpdatesReleaseNotesRestName = restEndPoint + 'system-updates/cfrnID';
export const systemUpdatesByEquipmentKey = restEndPoint + 'system-updates/equipment/';
export const systemUpdatesRuhByEquipmentKey = restEndPoint + 'system-updates/ruh/equipment/';
export const systemUpdatesRuhDetails = restEndPoint + 'system-updates/ruh/details';
export const systemUpdatesRuhLatest = restEndPoint + 'system-updates/ruh/latest';
export const systemUpdatesCreateNew = restEndPoint + 'system-updates/create-update';
export const systemUpdatesEditNew = restEndPoint + 'system-updates/edit-update';
export const equipmentSecretKeys = restEndPoint + 'system-updates/secret-keys';
export const systemUpdatesRuhCves = restEndPoint + 'system-updates/cves';
@Injectable({providedIn: 'root'})
export class SystemUpdatesRestService {

  constructor(private http: CacheableHttpClient, private removeLeadingZeroPipe: RemoveLeadingZeroPipe) {
  }

  clearCache(restName: string) {
    this.http.clearCache(restName);
  }

  public getUpdates(): Observable<SystemUpdateBase[]> {
    return this.http.get<SystemUpdateBase[]>(systemUpdatesRestName, {cache: false});
  }

  public getSystemUpdateDetail(updateNumber: string): Observable<SystemUpdateDetail> {
    const httpParams = new HttpParams().set('updateNumber', updateNumber);
    return this.http.get<SystemUpdateDetail>(systemUpdateDetailRestName, {
      cache: false,
      params: httpParams
    });
  }

  getSystemUpdateCves(updateNumber: string): Observable<SystemUpdatesCves> {
    const httpParams = new HttpParams().set('updateNumber', updateNumber);
    return this.http.get<SystemUpdatesCves>(systemUpdatesRuhCves, {
      cache: false,
      params: httpParams
    });
  }

  public getSystemUpdateAffectedEquipmentKeys(updateNumber: string): Observable<string[]> {
    const httpParams = new HttpParams().set('updateNumber', updateNumber);
    return this.http.get<string[]>(systemUpdateAffectedEquipmentKeysRestName, {
      cache: false,
      params: httpParams
    });
  }

  public getDownloadUrl(updateNumber: string): Observable<DownloadUrl> {
    const httpParams = new HttpParams().set('updateNumber', updateNumber);
    return this.http.get<DownloadUrl>(systemUpdatesDownloadUrlRestName, {
      cache: false,
      params: httpParams
    });
  }

  getReleaseNotesId(updateNumber: string): Observable<string> {
    const httpParams = new HttpParams().set('updateNumber', updateNumber);
    return this.http.get<string>(systemUpdatesReleaseNotesRestName, {
      cache: false,
      params: httpParams});
  }

  public getSystemUpdatesForEquipment(equipmentKey: string): Observable<SystemUpdateBase[]> {
    return this.http.get<SystemUpdateBase[]>(systemUpdatesByEquipmentKey + equipmentKey, {
      cache: false
    });
  }

  public getSystemUpdatesRuhForEquipment(equipmentKey: string): Observable<SystemUpdateRuhBase[]> {
    return this.http.get<SystemUpdateRuhBase[]>(systemUpdatesRuhByEquipmentKey + equipmentKey, {
      cache: false
    });
  }

  public getRuhDetails(materialNumber: string, serialNumber: string, updateNumber: string): Observable<SystemUpdateRuhDetail[]> {
    return this.http.get<SystemUpdateRuhDetail[]>(systemUpdatesRuhDetails, {
      params: new HttpParams()
        .set('materialNumber', this.removeLeadingZeroPipe.transform(materialNumber))
        .set('serialNumber', this.removeLeadingZeroPipe.transform(serialNumber))
        .set('updateNumber', updateNumber)
    });
  }

  public getLatestSystemUpdatesRuh(equipmentKey: string): Observable<SystemUpdateRuhBase> {
    return this.http.get<SystemUpdateRuhBase>(systemUpdatesRuhLatest, {
      params: new HttpParams().set('equipmentKey', equipmentKey)
    });
  }

  public createOrEditSystemUpdate(systemUpdateInput: SystemUpdateInput, isEdit: boolean): Observable<any> {
    return isEdit ? this.http.post(systemUpdatesEditNew, systemUpdateInput) : this.http.post(systemUpdatesCreateNew, systemUpdateInput);
  }

  public getSecretKeysForEquipment(): Observable<EquipmentSecretKeyDTO[]> {
    return this.http.get<EquipmentSecretKeyDTO[]>(equipmentSecretKeys);
  }

  public clearGetSecretKeysForEquipment() {
    this.clearCache(equipmentSecretKeys);
  }
}
