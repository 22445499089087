import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

export interface LinkItem {
  text: string;
  link?: string;
}

@Component({
  selector: 'hl-read-more-arrays',
  templateUrl: './read-more-arrays.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadMoreArraysComponent implements OnChanges {

  @Input({required: true}) array: LinkItem[] = [];
  @Input() maxItems = 3;
  @Input() isInternalLink = false;
  @ViewChild('toggle') toggleButton?: ElementRef;

  currentArray: LinkItem[] = [];
  isCollapsed = true;

  get showToggleButton(): boolean {
    return this.array.length > this.maxItems;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('array' in changes || 'maxItems' in changes) {
      this.refreshView();
    }
  }

  refreshView() {
    this.isCollapsed = this.array.length > this.maxItems;
    this.updateDisplayedItems();
  }

  toggleView($event: Event) {
    $event.stopPropagation();
    this.blur();

    this.isCollapsed = !this.isCollapsed;
    this.updateDisplayedItems();
  }

  private updateDisplayedItems() {
    this.currentArray = this.isCollapsed
      ? this.array.slice(0, this.maxItems)
      : this.array;
  }

  private blur() {
    if (this.toggleButton) {
      this.toggleButton.nativeElement.blur();
    }
  }
}
