import { DropdownOptions, ExtendedNestedDropdownOption } from '../../core/models/dropdown-options';
import { ConfigurationAggregateType, ConfigurationAggregateTypeTranslations } from '../../core/models/spr/basic-input-configuration/configuration-aggregate-type';

export const countWhatOptionNotifications = 'notifications';
export const countWhatOptionTasks = 'tasks';

export const basedOnOptionNotification = 'notification';
export const basedOnOptionTask = 'task';
export const countryDefaultValueForApplicableFor = 'Country';

export const optionDowntime = 'Downtime';
export const optionUptime = 'Uptime';
export const optionAllCustomers: DropdownOptions = {title: 'All customers', value: 'allCustomers'};

export const startEndOutOfOptionNotification = 'notification';
export const startEndTimestampOptionStart = 'start';
export const startEndTimestampOptionEnd = 'end';

export const durationStartWithWhichOptionEach = 'each';

export const oppositeKPIConfigurationValue = 'opposite';

export const kpiID = 'ID: ';

export const comparisonOptions: DropdownOptions[] = [
  {title: '>', value: '>'},
  {title: '>=', value: '>='},
  {title: '<', value: '<'},
  {title: '<=', value: '<='},
  {title: '=', value: '='},
  {title: '!=', value: '!='}
];

export const comparisonOptionsWithEmptyOption: DropdownOptions[] = [
  {title: undefined, value: undefined},
  {title: '>', value: '>'},
  {title: '>=', value: '>='},
  {title: '<', value: '<'},
  {title: '<=', value: '<='},
  {title: '=', value: '='},
  {title: '!=', value: '!='}
];

export const notificationsOptions: DropdownOptions[] = [
  {title: 'MS-TD', value: '100', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MS_TD'},
  {title: 'MS-PD', value: '110', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MS_PD'},
  {title: 'MS-RS', value: '120', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MS_RS'},
  {title: 'MS-AP', value: '160', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MS_AP'},
  {title: 'MM', value: '200', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MM'},
  {title: 'MU', value: '300', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MU'},
  {title: 'MU-APPS', value: '360', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MU_APPS'},
  {title: 'MI', value: '400', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MI'},
  {title: 'MI-AP', value: '460', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MI_AP'},
  {title: 'MA', value: '500', tooltip: 'REPORTING_TOOLTIP_NOTI_CATEGORY_MA'}
];

export const chartTypeOptions: DropdownOptions[] = [
  {title: 'LABEL_CHART_TYPE_LINE', value: 'line', icon: 'extended-icon icon-line-graph'},
  {title: 'LABEL_CHART_TYPE_DONUT', value: 'donut', icon: 'extended-icon icon-donut-graph'},
  {title: 'LABEL_CHART_TYPE_NUMBERS', value: 'number', icon: 'extended-icon icon-number-graph'},
  {title: 'LABEL_CHART_TYPE_BAR', value: 'bar', icon: 'extended-icon icon-bar-graph'}
];

export const chartColors = ['#009999', '#EC6602', '#000000'];
export const stackedChartColors = ['#2ecc71', '#e7001d'];
export const groupedChartColors = ['#ec6602', '#009999'];

export const chartDateFormat = 'YYYY.MM';

export const mainAxisValueOptions: DropdownOptions[] = [
  {title: 'LABEL_MONTHS', value: 'months'},
  {title: 'LABEL_MODALITIES', value: 'modalities'}
];

export const graphConfigurationTypes = {
  MAIN_AXIS_TYPE: 'mainAxisType',
  GRAPH_CALCULATOR: 'graphCalculator',
  ALLOWED_CUSTOMERS: 'allowedCustomers',
  INTERNAL_ONLY: 'internalOnly'
};

export enum CalculatorTypes {
  LINE_CHART_AVERAGE_MONTHS = 'LINE_CHART_AVERAGE_MONTHS',
  BAR_CHART_AVERAGE_MONTHS = 'BAR_CHART_AVERAGE_MONTHS',
  BAR_CHART_AVERAGE_MONTHS_PER_MODALITY = 'BAR_CHART_AVERAGE_MONTHS_PER_MODALITY',
  NUMBER_CHART_AVERAGE_MONTHS = 'NUMBER_CHART_AVERAGE_MONTHS',
  DONUT_CHART_DASHBOARD = 'DONUT_CHART_DASHBOARD'
}

export enum ChartTypes {
  LINE = 'line',
  DONUT = 'donut',
  NUMBER = 'number',
  BAR = 'bar'
}

export enum KpiTypes {
  DURATION = 'duration',
  QUANTITY = 'quantity',
  VIRTUAL = 'virtual'
}

export const aggregateTypeOptionsDuration: DropdownOptions[] = [
  {title: ConfigurationAggregateType.SUM, value: ConfigurationAggregateType.SUM},
  {title: ConfigurationAggregateType.MEAN, value: ConfigurationAggregateType.MEAN},
  {
    title: ConfigurationAggregateType.PERCENTAGE,
    value: ConfigurationAggregateType.PERCENTAGE,
    tooltip: 'REPORTING_TOOLTIP_AGGREGATE_TYPE_PERCENTAGE'
  }
];

export const aggregateTypeOptionsQuantity: DropdownOptions[] = [
  {title: ConfigurationAggregateType.SUM, value: ConfigurationAggregateType.SUM},
  {title: ConfigurationAggregateType.MEAN, value: ConfigurationAggregateType.MEAN}
];

export const aggregateTypeOptionsVirtualKpi: ExtendedNestedDropdownOption[] = [
  {
    title: ConfigurationAggregateType.SUM,
    value: ConfigurationAggregateType.SUM,
    isSelected: false
  },
  {
    title: ConfigurationAggregateType.SUBTRACTION,
    value: ConfigurationAggregateType.SUBTRACTION,
    isSelected: false
  },
  {
    title: ConfigurationAggregateType.MEAN,
    value: ConfigurationAggregateType.MEAN,
    isSelected: false
  },
  {
    title: ConfigurationAggregateType.RATIO,
    value: ConfigurationAggregateType.RATIO,
    isSelected: false
  },
  {
    title: ConfigurationAggregateTypeTranslations.RATIO_PERCENTAGE,
    value: ConfigurationAggregateType.PERCENTAGE,
    isSelected: false
  },
  {
    title: ConfigurationAggregateTypeTranslations.REVERT_GROUP,
    value: ConfigurationAggregateTypeTranslations.REVERT_GROUP,
    isSelected: false,
    disabledSelect: true,
    extendedChildren: [
      {
        title: ConfigurationAggregateTypeTranslations.REVERT_PERCENTAGE,
        value: ConfigurationAggregateType.REVERT_PERCENTAGE,
        isSelected: false
      },
      {
        title: ConfigurationAggregateTypeTranslations.REVERT_SUM,
        value: ConfigurationAggregateType.REVERT_SUM,
        isSelected: false
      },
      {
        title: ConfigurationAggregateTypeTranslations.REVERT_MEAN,
        value: ConfigurationAggregateType.REVERT_MEAN,
        isSelected: false
      },
      {
        title: ConfigurationAggregateTypeTranslations.REVERT_RATIO,
        value: ConfigurationAggregateType.REVERT_RATIO,
        isSelected: false
      }
    ]
  }
];

export const durationStartWithWhichOptions: DropdownOptions[] = [
  {title: 'first', value: 'first'},
  {title: 'last', value: 'last'},
  {title: durationStartWithWhichOptionEach, value: durationStartWithWhichOptionEach}
];

export const durationEndWithWhichOptions: DropdownOptions[] = [
  {title: 'first', value: 'first'},
  {title: 'last', value: 'last'},
  {title: 'next', value: 'next'}
];

export const durationEndWithTaskOptions: DropdownOptions[] = [
  {title: 'APPS', value: 'APPS'},
  {title: 'BGRP', value: 'BGRP'},
  {title: 'CBCI', value: 'CBCI'}
];
