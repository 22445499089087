<div [formGroup]="confirmNoPatientData">
  <div class="row">
    <div class="col-12 col-lg-8">
      <input type="checkbox" class="checkbox" id="patientData" formControlName="confirmed">
      <label for="patientData" [translate]="'TEXT_CONFIDENTIAL_PATIENT_DATA'"></label>

      <div [ngClass]="{'invalid': isFormSubmitted && !confirmNoPatientData.controls.confirmed.valid}">
        @if (isFormSubmitted && !confirmNoPatientData.controls.confirmed.valid) {
          <small class="checkbox__hint" [translate]="'ERROR_CONFIDENTIAL_PATIENT_DATA'"></small>
        }
      </div>

    </div>
  </div>
</div>
