<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'LABEL_ADVISORY_UPLOAD_ATTACHMENT'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close"
          (click)="cancelAdvisoryUploadFilesModal.show()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div [formGroup]="advisoryUploadFilesForm" class="modal-dialog__body" id="modal1_desc">
        @if (!showSpinner) {
          <hl-selectbox formControlName="language" [options]="languageOptions$ | async" label="LABEL_LANGUAGE" ngDefaultControl></hl-selectbox>
          <hl-upload #filePackageInfoForm [parentForm]="advisoryUploadFilesForm" [isFormSubmitted]="isFormSubmitted"
            [parentFormFileControlKey]="FILE_PDF" [disableUploadButton]="disablePDFButton" genericLabelName="LABEL_PDF_FILE"></hl-upload>
          @if (advisoryUploadFilesForm.controls['pdfFiles'].errors && advisoryUploadFilesForm.controls['pdfFiles'].errors['invalidName']) {
            <small class="textfield__hint textfield__hint--validation" [translate]="'SYSTEM_UPDATES_UPLOAD_PACKAGE_FILE_NAME_NOT_VALID'"></small>
          }
          <hl-upload #filePackageInfoForm2 [parentForm]="advisoryUploadFilesForm" [isFormSubmitted]="isFormSubmitted"
            [parentFormFileControlKey]="FILE_TXT" [disableUploadButton]="disableTXTButton" genericLabelName="LABEL_TXT_FILE"></hl-upload>
          @if (advisoryUploadFilesForm.controls['txtFiles'].errors && advisoryUploadFilesForm.controls['txtFiles'].errors['invalidName']) {
            <small class="textfield__hint textfield__hint--validation" [translate]="'SYSTEM_UPDATES_UPLOAD_PACKAGE_FILE_NAME_NOT_VALID'"></small>
          }
        } @else {
          <hl-progress-indicator></hl-progress-indicator>
        }
      </div>

      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button" (click)="cancelAdvisoryUploadFilesModal.show()" translate="GENERIC_LABEL_CANCEL"></button>
          <button class="button button--primary" (click)="ok()" [disabled]="isSubmitFormDisabled()" translate="GENERIC_LABEL_UPLOAD"></button>
        </div>
      }

    </div>
  </div>

  <hl-cancel-modal (yesClick)="resetAndHide()" [descriptionMessage]="'SYSTEM_UPDATES_UPLOAD_CONFIRM_CANCELLATION'"
    #cancelAdvisoryUploadFilesModal></hl-cancel-modal>


</ng-template>
