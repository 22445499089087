<p class="color-black-60">{{'DOT_SCHEDULE_APPOINTMENT_BOOKING_DESCRIPTION' | translate}}</p>

@if (status().isReviewNeeded) {
  <hl-alert [indicator]="'info'" [hideButtonVisible]="false">
    {{'DOT_SCHEDULE_APPOINTMENT_BOOKING_NEEDS_REVIEW' | translate}}
  </hl-alert>
} @else if (affectedActivities().length > 1) {
  <hl-alert [indicator]="'info'" [hideButtonVisible]="false">
    {{'DOT_SCHEDULE_APPOINTMENT_BOOKING_MULTIPLE_APPOINTMENTS' | translate}}
  </hl-alert>
}

<h5>{{'DOT_SCHEDULE_APPOINTMENT_BOOKING_HEADER_GENERAL_INFO' | translate}}</h5>
<div>
  <hl-activity-property-list
    [item]="summary().generalInformation"
    [index]="0"
    [visibleProperties]="PROPERTIES_GENERAL_INFORMATION"
    [customerNameLength]="+config().LENGTH_CUSTOMER_NAME_DISPLAY"
  ></hl-activity-property-list>
</div>

<h5>{{'DOT_SCHEDULE_APPOINTMENT_BOOKING_HEADER_OLD_DETAILS' | translate}}</h5>
<div>
  @for (activity of summary().oldAppointments; track activity; let index = $index) {
    <hl-activity-list
      [item]="activity"
      [index]="index"
      [showAppointments]="true"
      [showActivityStatus]="true"
      [showMainListProperties]="true"
      [datePattern]="config().GENERIC_DATE_PATTERN"
      [dateTimePattern]="config().GENERIC_DATE_TIME_PATTERN"
      [isListWithoutAction]="true"
      [whitelistedProperties]="PROPERTIES_DETAILS"
    ></hl-activity-list>
  }
</div>

<h5>{{'DOT_SCHEDULE_APPOINTMENT_BOOKING_HEADER_NEW_DETAILS' | translate}}</h5>
<div>
  @for (activity of summary().newAppointments; track activity; let index = $index) {
    <hl-activity-list
      [item]="activity"
      [index]="index"
      [showAppointments]="true"
      [showActivityStatus]="true"
      [showMainListProperties]="true"
      [datePattern]="config().GENERIC_DATE_PATTERN"
      [dateTimePattern]="config().GENERIC_DATE_TIME_PATTERN"
      [isListWithoutAction]="true"
      [whitelistedProperties]="PROPERTIES_DETAILS"
    ></hl-activity-list>
  }
</div>
