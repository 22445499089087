import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ASUWhitelistViewModel } from '../view-models/asu-whitelist-view-model';
import { Whitelist } from '../models/whitelist';

@Injectable({providedIn: 'root'})
export class WhiteListAdminRestService {
  private whiteListRestName = restEndPoint + 'white-list/admin/items';
  private cmdbRestName = restEndPoint + 'white-list/admin/cmdb';
  private addAsuEntriesRestName = restEndPoint + 'white-list/admin/add-asu-entries';
  private deleteAsuEntriesRestName = restEndPoint + 'white-list/admin/delete-asu-entries';

  constructor(private http: CacheableHttpClient) {
  }

  getWhiteList(materialNumber: string, serialNumber: string): Observable<ASUWhitelistViewModel[]> {
    return this.http.get<ASUWhitelistViewModel[]>(this.whiteListRestName, {
      params: new HttpParams()
        .set('mat', materialNumber ? materialNumber : '*')
        .set('sn', serialNumber ? serialNumber : '*')
    });
  }

  getCmdbList(materialNumber: string, serialNumber: string, softwareVersion: string): Observable<ASUWhitelistViewModel[]> {
    return this.http.get<ASUWhitelistViewModel[]>(this.cmdbRestName, {
      params: new HttpParams()
        .set('mat', materialNumber ? materialNumber : '*')
        .set('sn', serialNumber ? serialNumber : '*')
        .set('sw', softwareVersion ? softwareVersion : '*')
    });
  }

  addAsuWhitelistEntries(entries: Whitelist[]): Observable<any> {
    return this.http.post(this.addAsuEntriesRestName, entries);
  }

  deleteAsuWhitelistEntries(entries: Whitelist[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: entries
    };
    return this.http.delete(this.deleteAsuEntriesRestName, options);
  }
}
