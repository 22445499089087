<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--form is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'TITLE_SAO_CREATE'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc">
        <hl-progress-indicator [loading]="showSpinner" [showLabel]="true" [label]="'TEXT_SAO_SEND_ORDER' | translate"></hl-progress-indicator>

        <form [formGroup]="createSaoForm" [hidden]="showSpinner">
          <h5 class="margin-top-0" [translate]="'GENERIC_LABEL_EQUIPMENT'"></h5>
          <hl-create-sao-product-information [statusColorMap]="statusColorMap" [productForm]="createSaoForm.controls['product']"
            [isFormSubmitted]="isFormSubmitted"></hl-create-sao-product-information>

          <hl-create-sao-order-summary [orderForm]="createSaoForm.controls['order']"></hl-create-sao-order-summary>

          <hl-contact-area [contactForm]="createSaoForm.controls['contact']" [isFormSubmitted]="isFormSubmitted"></hl-contact-area>

          <h5 class="margin-top-0" [translate]="'LABEL_SAO_CONTACT_INFORMATION'"></h5>

          <hl-create-sao-contact-information [contactITForm]="createSaoForm.controls['contactIT']"
            [isFormSubmitted]="isFormSubmitted"></hl-create-sao-contact-information>

          <h5 class="margin-top-0" [translate]="'LABEL_SAO_SHIPPING_ADDRESS'"></h5>
          <hl-create-sao-shipping-address [shippingForm]="createSaoForm.controls['shipping']"
            [isFormSubmitted]="isFormSubmitted"></hl-create-sao-shipping-address>

          <h5 class="margin-top-0" [translate]="'LABEL_SAO_BILLING_ADDRESS'"></h5>
          <hl-create-sao-billing-address [shippingForm]="createSaoForm.controls['shipping']" [billingForm]="createSaoForm.controls['billing']"
            [isFormSubmitted]="isFormSubmitted"></hl-create-sao-billing-address>
        </form>

      </div>
      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button button--primary" (click)="createSao()" [translate]="'BUTTON_SAO_CREATE'"></button>
          <button class="button" (click)="cancelCreateSao()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
        </div>
      }

    </div>
  </div>
</ng-template>
