import { Component, computed, input } from '@angular/core';
import { TooltipModule } from '../../tooltip/tooltip.module';
import { ActivitiesViewModel } from '../../core/view-models/activities-view-model';
import { PipesModule } from '../../core/pipes.module';
import { DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

type DirectActivityPropertyKey = keyof Pick<
  ActivitiesViewModel,
  'ticketNumber' | 'appointmentNumber' | 'productName' | 'siemensId' | 'myEquipmentName' | 'customerName' | 'plannedStart' |
    'plannedEnd' | 'dueDate' | 'completedDate' | 'duration'
>;

export type ActivityPropertyKey = DirectActivityPropertyKey | 'status';

const ALL_ACTIVITY_PROPERTY_KEYS: ActivityPropertyKey[] = [
  'ticketNumber',
  'appointmentNumber',
  'productName',
  'siemensId',
  'myEquipmentName',
  'customerName',
  'plannedStart',
  'plannedEnd',
  'dueDate',
  'completedDate',
  'duration',
  'status'
];

@Component({
  selector: 'hl-activity-property-list',
  templateUrl: './activity-property-list.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    TooltipModule,
    PipesModule,
    DecimalPipe
  ]
})
export class ActivityPropertyListComponent {
  item = input.required<ActivitiesViewModel>();
  index = input.required<number>();
  visibleProperties = input.required<ActivityPropertyKey[] | null>(); // null = all are visible

  hasAction = input<boolean>(false);
  customerNameLength = input<number | null>(null); // needed for customer name
  datePattern = input<string | null>(null); // needed for due date / completed date
  dateTimePattern = input<string | null>(null); // needed for planned start / planned end

  propertiesToDisplay = computed(() =>
    (this.visibleProperties() ?? ALL_ACTIVITY_PROPERTY_KEYS).filter(key => this.propertyHasAllRequiredInputsProvided(key)));

  propertyHasAllRequiredInputsProvided(key: ActivityPropertyKey): boolean {
    switch (key) {
      case 'customerName':
        return this.customerNameLength() !== null;

      case 'plannedStart':
      case 'plannedEnd':
        return this.dateTimePattern() !== null;

      case 'dueDate':
      case 'completedDate':
        return this.datePattern() !== null;

      default:
        return true;
    }
  }
}
