import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { SubprocessorListRestService } from 'app/core/rest-services/subprocessor-list-rest.service';
import { AttachmentComponent } from 'app/shared/form-group/attachment/attachment.component';
import { finalize } from 'rxjs/operators';
import { SubprocessorFileInfo } from '../../../core/models/subprocessor-file-info';
import { MessagingCenterService } from '../../../core/services/messaging-center/messaging-center.service';

@Component({
  selector: 'hl-upload-subprocessor-files-modal',
  templateUrl: './upload-subprocessor-files-modal.component.html'
})
export class UploadSubprocessorFilesModalComponent extends BaseModalPopup implements OnInit {

  @ViewChild('filePackageInfoForm')
  pdfUpload: AttachmentComponent;

  @Input()
  type: 'hq' | 'country';

  @Output()
  fileUploaded = new EventEmitter<SubprocessorFileInfo>();

  subprocessorUploadForm: UntypedFormGroup;
  showSpinner = false;
  isFormSubmitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    private subprocessorListRestService: SubprocessorListRestService,
    private messagingCenterService: MessagingCenterService,
    private toasterService: ToasterService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.subprocessorUploadForm = this.fb.group({
      attachments: [[]]
    });
  }

  ok() {
    this.isFormSubmitted = true;
    if (this.subprocessorUploadForm.valid) {
      // after we upload attachment we need to reset the AttachmentComponent in order to remove
      // attached files, however during the file upload the AttachmentComponent is hidden, change
      // detection gets ran and angular removes the AttachmentComponent from the pdfUpload field
      // workaround => capture pdfUpload before we begin the upload process
      const pdfUpload = this.pdfUpload;

      this.showSpinner = true;
      const attachment: {
        attachment: string
      } = {attachment: this.subprocessorUploadForm.get('attachments').value[0]};
      this.subprocessorListRestService.uploadSubprocessorList(attachment, this.type).pipe(
        finalize(() => this.resetAndHide(pdfUpload))
      ).subscribe({
        next: (response) => {
          this.fileUploaded.next(response);
          this.messagingCenterService.emitRefreshMessagingCenter();
          this.toasterService.showTranslatedSuccessToaster('SUBPROCESSOR_ATTACHMENT_UPLOAD_SUCCESS');
        },
        error: () => this.toasterService.emitFrontendGenericErrorMessage()
      });
    }
  }

  resetAndHide(pdfUpload: AttachmentComponent) {
    pdfUpload.removeFiles();
    this.isFormSubmitted = false;
    this.showSpinner = false;
    this.hide();
  }

  isSubmitFormDisabled() {
    return !this.subprocessorUploadForm.get('attachments').value.length;
  }
}
