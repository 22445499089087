import { Attachment } from '../../../core/models/attachment';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { isEqual, parseInt, remove } from 'lodash-es';
import { StringUtilService } from '../../../core/utils/string-util.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';
import { DeviceDetectionService } from 'app/core/services/device-detection.service';

@Component({
  selector: 'hl-attachment',
  templateUrl: './attachment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentComponent implements OnInit, OnDestroy {
  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  isRequired: boolean;
  @Input()
  checkAttachmentDisable: boolean;
  @Input()
  disableAttachmentCount: number;
  @Input()
  isInvalid = false;
  @Input()
  ignoreFileLengthConfig = false;

  @ViewChild('inputBtn')
  inputBtn: ElementRef;

  fileObjects: Attachment[] = [];
  file: File = null;

  // file attachment validations
  maxSize: number;
  maxOverallSize: number;
  fileNameLength: number;
  allowedFileTypes: '';

  // temp storage for overall size
  totalFileSize: number;

  // set default to true so that initial in view error message is not shown
  isAttachmentValid = true;
  isDisabled = false;
  attachmentValidationMessage = '';
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private configService: CountryConfigRestService,
    private environmentConfig: EnvironmentConfigRestService,
    private cdRef: ChangeDetectorRef,
    private stringUtilService: StringUtilService,
    private deviceDetectionService: DeviceDetectionService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.totalFileSize = 0;
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.maxSize = parseInt(configResponse.TICKET_ATTACHMENT_SIZE_LIMIT);
      this.maxOverallSize = parseInt(
        configResponse.TICKET_ATTACHMENT_OVERALL_LIMIT
      );
      this.fileNameLength = parseInt(
        configResponse.TICKET_ATTACHMENT_FILENAME_LENGTH
      );
    });
    this.environmentConfig.getEnvironmentConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      if (!this.deviceDetectionService.isIOS()) {
        this.allowedFileTypes = response.TICKET_ATTACHMENT_FILE_EXTENSIONS;
      }
    });
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0]; // since we don't have multiple

    if (file) {
      this.isAttachmentValid = this.canBeUploadedAfterValidation(file);
      // check for validations, only then we need to read the file content into memory
      if (this.isAttachmentValid) {
        this.file = file;
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsArrayBuffer(file);
      }

      // clear the input field
      this.inputBtn.nativeElement.value = '';
    }
  }

  _handleReaderLoaded(readerEvt) {
    const buffer = readerEvt.target.result;
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    const fileObject: { [k: string]: any } = {};
    fileObject.filetype = this.file.type;
    fileObject.filename = this.getAttachmentFilename();
    fileObject.filesize = this.file.size;
    fileObject.base64 = window.btoa(binary);
    this.fileObjects.push(fileObject as Attachment);
    this.parentForm.patchValue({attachments: this.fileObjects});
    this.file = null;
    this.cdRef.markForCheck();
    this.checkAttachmentToBeDisabled();
  }

  /**
   *
   * @param file | File -> event.target.files[0]
   *
   * Note:-
   * Here we have an order in which each file should be validated.
   * maxSize -> maxOverallSize-> fileNameLength
   */
  canBeUploadedAfterValidation(file: File): boolean {
    if (this.maxSize) {
      let valid: boolean;
      valid = file.size <= this.maxSize;

      if (valid) {
        this.totalFileSize += file.size;
      } else {
        this.attachmentValidationMessage =
          'TICKET_ATTACHMENT_SIZE_LIMIT_EXCEEDED_MESSAGE';
        return valid;
      }
    }

    if (this.maxOverallSize) {
      let valid: boolean;
      valid = this.totalFileSize <= this.maxOverallSize;

      if (!valid) {
        this.totalFileSize -= file.size;
        this.attachmentValidationMessage =
          'TICKET_ATTACHMENT_OVERALL_LIMIT_EXCEEDED_MESSAGE';
        return valid;
      }
    }
    return true;
  }

  /**
   * Remove the attachment on click trash icon
   */
  removeAttachment(deleteItem) {
    // default set to false
    this.isDisabled = false;
    remove(this.fileObjects, deleteItem);
    this.isAttachmentValid = true;
    this.totalFileSize = 0;
    for (const fileObject of this.fileObjects) {
      if (!this.canBeUploadedAfterValidation(fileObject.file)) {
        this.isAttachmentValid = false;
      }
    }
    this.checkAttachmentToBeDisabled();
    this.parentForm.patchValue({attachments: this.fileObjects});
  }

  removeFiles() {
    [...this.fileObjects].forEach(file => {
      this.removeAttachment(file);
    });
    this.cdRef.detectChanges();
  }

  clearAttachments() {
    this.fileObjects = [];
  }

  /**
   * Check if the input field needs to be disabled
   */
  checkAttachmentToBeDisabled() {
    // disable the upload if attachment number is reached
    if (
      this.checkAttachmentDisable &&
      isEqual(this.fileObjects.length, this.disableAttachmentCount)
    ) {
      this.isDisabled = true;
    }
  }

  getAttachmentFilename(): string {
    if (this.ignoreFileLengthConfig) {
      return this.file.name;
    }
    if (this.fileNameLength && this.file.name.length > this.fileNameLength) {
      // now we truncate the filename
      return this.stringUtilService.truncateFilename(
        this.file.name,
        this.fileNameLength
      );
    }
    return this.file.name;
  }
}
