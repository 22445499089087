import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SortSkeleton } from '../../../sorting/sort-skeleton';
import { EquipmentViewModel } from '../../../../core/view-models/equipment-view-model';
import { EquipmentUtilService } from '../../../../core/services/equipment/equipment-util.service';
import { EquipmentConstantsService } from '../../../../core/services/equipment/equipment-constants.service';
import { CountryConfigRestService } from '../../../../core/rest-services/country-config-rest.service';
import { BaseListView } from '../../../../core/base-class/base-list-view';
import { Router } from '@angular/router';
import { UserUtilService } from '../../../../core/services/user/user-util.service';
import { StateService } from '../../../../core/services/state.service';
import { LogService } from '../../../../core/services/log/log.service';
import { BrowserStateService } from '../../../../core/services/browser-state.service';
import { FilterUtilService } from '../../../../core/utils/filter-util.service';
import { Observable } from 'rxjs';
import { without } from 'lodash-es';
import { SystemUpdatesEquipmentViewModel } from '../../../../core/view-models/system-updates-equipment-view-model';
import { CreateSystemUpdatesService } from '../../../../core/services/system-updates/create-system-updates-service';

@Component({
  selector: 'hl-equipment-selection-for-new-system-update',
  templateUrl: './equipment-selection-for-new-system-update.component.html'
})
export class EquipmentSelectionForNewSystemUpdateComponent extends BaseListView<SystemUpdatesEquipmentViewModel>
  implements OnInit, OnDestroy {
  @Output()
  equipmentAddedToTheUpdate = new EventEmitter<SystemUpdatesEquipmentViewModel>();
  searchInput: string;
  sortSkeleton: SortSkeleton;
  isLoaded: boolean;
  filteredLengthWithoutPagination = 0;

  constructor(
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    router: Router,
    userUtilService: UserUtilService,
    stateService: StateService,
    logService: LogService,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService,
    private equipmentConstantsService: EquipmentConstantsService,
    private createSystemUpdatesService: CreateSystemUpdatesService) {
    super(
      configService,
      equipmentUtilService,
      userUtilService,
      stateService,
      logService,
      router,
      browserStateService,
      filterUtilService
    );
  }

  ngOnInit() {
    super.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  trackByFn(index, item: EquipmentViewModel) {
    return item['key'];
  }

  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'key',
          'customerNumber',
          'productName',
          'myEquipmentName',
          'siemensId',
          'department',
          'street',
          'city',
          'serialNumber',
          'materialNumber',
          'customerName',
          'customerDescription'
        ]
      },
      orderBy: this.sortSkeleton.sortObject
    };
  }

  afterConfigProperties(config: any): void {
    // intentionally left empty
  }

  afterInitProperties(): void {
    this.sortSkeleton = this.equipmentConstantsService.getSortSkeletonForNewSystemUpdateEquipments();
  }

  afterInitViewModelList(): void {
    this.onAdvancedFilterChange();
    this.isLoaded = true;
  }

  getEmptyListUrl(): string {
    return '';
  }

  initAdvancedFilterDropDownList(): void {
    // intentionally left empty
  }

  loadViewModelList(): Observable<(SystemUpdatesEquipmentViewModel)[]> {
    return this.createSystemUpdatesService.getAllEquipmentForNewSystemUpdateCreation();
  }

  navigate(): void {
    // intentionally left empty
  }

  setDerivedBoundPropertiesFromFavorite(favorite: any): void {
    // intentionally left empty
  }

  setPropertiesFromQueryParams(config: any) {
    // intentionally left empty
  }

  public addEquipmentToList(equipment: SystemUpdatesEquipmentViewModel) {
    this.rawList.push(equipment);
    this.onAdvancedFilterChange();
  }

  removeEquipmentFromList(equipment: SystemUpdatesEquipmentViewModel) {
    this.equipmentAddedToTheUpdate.emit(equipment);
    this.rawList = without(this.rawList, equipment);
    this.viewModelList = without(this.viewModelList, equipment);
    this.listWithoutPagination = without(this.listWithoutPagination, equipment);
    if (this.viewModelList.length < this.numberPagination / 2) {
      this.loadMore(this.numberPagination);
    }
    this.onAdvancedFilterChange();
  }

  setEquipmentSelection(equipmentList: (SystemUpdatesEquipmentViewModel)[]) {
    this.rawList = equipmentList;
    this.onAdvancedFilterChange();
  }

  resetForm() {
    this.searchInput = '';
    this.initLoadViewModelList();
  }
}
