<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'SYSTEM_UPDATES_UPLOAD_LOCKED_GENERAL_LABEL'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hidePanel()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc">
        <p [translate]="updateLockMessage | async"></p>
      </div>
      <div class="modal-dialog__footer">
        @if (!unlockEnabled) {
          <button class="button button--primary" (click)="hidePanel()" [translate]="'GENERIC_LABEL_OK'"></button>
        } @else {
          <button class="button button" (click)="rollbackClean()" [translate]="'SYSTEM_UPDATES_UPLOAD_ROLLBACK_AND_CLEAN'"></button>
          <button class="button button--primary" (click)="rollbackUpload()" [translate]="'SYSTEM_UPDATES_UPLOAD_ROLLBACK_AND_UPLOAD'"></button>
        }
      </div>
    </div>
  </div>
</ng-template>
