import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-sao-shipping-address',
  templateUrl: './create-sao-shipping-address.component.html'
})
export class CreateSaoShippingAddressComponent {
  @Input() shippingForm: UntypedFormGroup;
  @Input() isFormSubmitted: boolean;
}
