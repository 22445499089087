import { inject, Injectable } from '@angular/core';
import { CacheableHttpClient } from '../../../core/services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { restEndPoint } from '../../../core/core-constants.service';
import { DotBookableStatus } from '../models/dot-bookable-status';
import { DotAppointmentSlots } from '../models/dot-appointment-slots';
import { DotBookAppointmentRequest } from 'app/appointments/dot/models/dot-book-appointment';

export const dotEndpoint = restEndPoint + 'dot';
export const dotStatusRestEndpoint = dotEndpoint + '/status';
export const dotAppointmentSlotsEndpoint = dotEndpoint + '/appointments';
export const dotBookAppointmentRestEndpoint = dotEndpoint + '/book-appointment';

@Injectable({
  providedIn: 'root'
})
export class DotRestService {
  private httpClient = inject(CacheableHttpClient);

  getAppointmentStatus(notificationNumber: string, taskNumber: number): Observable<DotBookableStatus> {
    const params = new HttpParams()
      .set('notificationNumber', notificationNumber)
      .set('taskNumber', taskNumber);

    return this.httpClient.get<DotBookableStatus>(dotStatusRestEndpoint, {params, cache: false});
  }

  getAppointmentSlotsForNotification(startDate: string, endDate: string, notificationNumber: string, taskNumber: number): Observable<DotAppointmentSlots> {
    const params = new HttpParams()
      .set('notificationNumber', notificationNumber)
      .set('taskNumber', taskNumber)
      .set('startDate', startDate)
      .set('endDate', endDate);

    return this.httpClient.get<DotAppointmentSlots>(dotAppointmentSlotsEndpoint, {params, cache: false});
  }

  getAppointmentSlotsForMegaTask(startDate: string, endDate: string, megaTask: string): Observable<DotAppointmentSlots> {
    const params = new HttpParams()
      .set('megaTask', megaTask)
      .set('startDate', startDate)
      .set('endDate', endDate);

    return this.httpClient.get<DotAppointmentSlots>(dotAppointmentSlotsEndpoint, {params, cache: false});
  }

  bookAppointment(body: DotBookAppointmentRequest): Observable<unknown> {
    return this.httpClient.post(dotBookAppointmentRestEndpoint, body);
  }
}
