import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { HttpParams } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const medalliaSurveyTrackingRestName = restEndPoint + 'medallia/surveyTracking';
export const medalliaSurveyInQuarantineRestName = restEndPoint + 'medallia/isSurveyInQuarantine';
export const medalliaUpdateNumber = 'updateNumber';
export const medalliaEvent = 'event';

@Injectable({providedIn: 'root'})
export class MedalliaRestService {
  constructor(private http: CacheableHttpClient) {
  }

  postMedalliaSurveyTracking(updateNumber: any, event: string) {
    this.http.post(medalliaSurveyTrackingRestName, null, {
      params: new HttpParams()
        .set(medalliaUpdateNumber, updateNumber)
        .set(medalliaEvent, event)
    }).pipe(take(1)).subscribe(() => {
      this.http.updateCache(medalliaSurveyInQuarantineRestName, null, true);
    })
  }

  isSurveyInQuarantine(): Observable<boolean> {
    return this.http.get<boolean>(medalliaSurveyInQuarantineRestName)
  }
}
