import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { of, Subject } from 'rxjs';
import { exhaustMap, finalize, take, takeUntil } from 'rxjs/operators';
import { NotifStatus } from '../../../core/core-constants.service';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { AttachmentUtilService } from '../../../core/utils/attachment-util.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { StringUtilService } from '../../../core/utils/string-util.service';
import { TicketAttachmentViewModel } from '../../../core/view-models/ticket-attachment-view-model';
import { AddOmnitureAndRouterStateNameDirective } from '../../directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { ServiceReport } from '../../../core/models/tickets/ServiceReport';
import { DownloadUtilService } from '../../../core/utils/download-util.service';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { LogService } from '../../../core/services/log/log.service';
import { forEach, includes, isEqual, without } from 'lodash-es';
import { DocumentSourceRestService } from '../../../core/rest-services/document-source-rest.service';
import { DocumentFile } from '../../../core/models/equipment/documents/document-file';
import { EquipmentDetailService } from '../../../equipment/equipment-detail/equipment-detail.service';

@Component({
  selector: 'hl-notification-attachments',
  templateUrl: './notification-attachments.component.html'
})
export class NotificationAttachmentsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public viewModel: any; // Activities | Ticket;

  isLoaded: boolean;
  showServiceReport: boolean;
  showDocBoxDocuments: boolean;
  datePattern = 'DDMMYYYY';
  dateTimePattern = 'DDMMYYYY HH:mm';
  solutionText: any;
  downloadAttachmentList: TicketAttachmentViewModel[];
  downloadGeneratedAttachmentList: TicketAttachmentViewModel[];
  showSize: boolean;
  showStatus: boolean;
  attachmentNameColumnWidth: number;
  attachmentDetailsColumnWidth: number;
  closedTicketStatusList: string[];
  serviceReportList: ServiceReport[];
  docboxDocumentList: DocumentFile[];
  isLoadingIndex: { [key: string]: number[] } = {
    'attachments': [],
    'generatedAttachments': [],
    'serviceReports': [],
    'docboxDocuments': []
  };

  isAttachmentsLoaded: boolean;
  isServiceReportsLoaded: boolean;
  isDocBoxListLoaded: boolean;

  @Input() attachmentType: string;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private configService: CountryConfigRestService,
    private attachmentUtilService: AttachmentUtilService,
    private ticketsUtilService: TicketsUtilService,
    private lifeNetUtilService: LifeNetUtilService,
    private activitiesRestService: ActivitiesRestService,
    private equipmentRestService: EquipmentRestService,
    private userUtilService: UserUtilService,
    private stringUtilService: StringUtilService,
    private toasterService: ToasterService,
    private downloadUtilService: DownloadUtilService,
    private sourceRestService: DocumentSourceRestService,
    private refreshItemEventService: RefreshItemEventService,
    private equipmentDetailService: EquipmentDetailService,
    private logService: LogService) {
  }

  private static mapAttachmentType(type: string): number {
    switch (type) {
      case 'USR':
        return 2;
      case 'SAT':
        return 1;
      default:
        return 0;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.initProperties();
    this.configProperties();
  }

  configProperties() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.init(config);
    });
  }

  getNotifStatus(ticketStatus: string) {
    return includes(this.closedTicketStatusList, ticketStatus) ? NotifStatus.CLOSED : NotifStatus.OPEN;
  }

  /**
   * if the activity changes, this means that the activity should already have changed.
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewModel'] && !changes['viewModel'].firstChange) {
      this.ngOnInit();
    }
  }

  init(config) {
    this.closedTicketStatusList = config.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS.split(',');
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.showDocBoxDocuments = isEqual(config.FEATURE_TOGGLE_DOCBOX_DOCUMENTS_FOR_TICKETS, 'true');
    const sapSystem = config.SAP_BACKEND_SYSTEM;

    let viewModelDetail$ = of(this.viewModel);

    if (isEqual(this.attachmentType, 'activity')) {
      viewModelDetail$ = this.activitiesRestService.getActivityDetails(this.viewModel.ticketKey, this.viewModel.customerId);
    } else if (!this.viewModel['attachments']) {
      viewModelDetail$ = this.ticketsUtilService.getTicketViewModel(
        this.viewModel.ticketKey, this.getNotifStatus(this.viewModel.ticketStatus));
    }

    viewModelDetail$
      .pipe(finalize(() => {
        this.isAttachmentsLoaded = true;
        this.setIsLoaded();
      }))
      .subscribe((response) => {
        if (response) {
          this.getAttachmentLists(response, sapSystem);

          this.calculateColumnWidth();

          // set and parse solutionText for line breaks
          if (response.solutionText) {

            this.solutionText =
              this.stringUtilService.parseAndSplitInputStringToLines(response.solutionText);
          }
          this.getServiceReportData(config);
        }
      });
    this.loadDocboxDocuments();

    this.refreshItemEventService.refreshItemSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.refreshViewModel();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
  }

  initProperties() {
    this.isLoaded = false;
    this.isAttachmentsLoaded = false;
    this.isServiceReportsLoaded = false;
    this.isDocBoxListLoaded = false;
    this.solutionText = [];
    this.downloadAttachmentList = [];
    this.downloadGeneratedAttachmentList = [];
    this.showServiceReport = false;
    this.showSize = false;
    this.showStatus = false;
    this.attachmentNameColumnWidth = 8;
    this.attachmentDetailsColumnWidth = 2;
    this.closedTicketStatusList = [];
    this.serviceReportList = [];
    this.docboxDocumentList = [];
  }

  private calculateColumnWidth() {
    forEach(this.downloadAttachmentList, item => {
      if (item.size > 0) {
        this.showSize = true;
      }
      if (item.status) {
        this.showStatus = true;
      }
      if (this.showSize && this.showStatus) {
        return;
      }
    });
    if ((this.showSize && !this.showStatus) || (!this.showSize && this.showStatus)) {
      this.attachmentNameColumnWidth = 10;
      this.attachmentDetailsColumnWidth = 2;
    } else if (!this.showSize && !this.showStatus) {
      this.attachmentNameColumnWidth = 12;
      this.attachmentDetailsColumnWidth = 0;
    } else {
      this.attachmentNameColumnWidth = 8;
      this.attachmentDetailsColumnWidth = 2;
    }
  }

  getServiceReportData(config) {
    this.showServiceReport =
      this.ticketsUtilService.isServiceReportRender(config.SERVICE_REPORT_RENDER_VIVO,
        config.SERVICE_REPORT_RENDER_VITRO, this.viewModel.equipmentKey);

    if (this.showServiceReport) {
      if (isEqual(config.SHOW_HPORTAL_ATTACHMENTS, 'true')) {
        this.ticketsUtilService.getAvailableServiceReportsForSpp(this.viewModel.ticketKey,
          this.viewModel.customerId,
          this.viewModel.ticketNumber
        ).subscribe(response => {
          this.serviceReportList = response;

          this.isServiceReportsLoaded = true;
          this.setIsLoaded();
        });
      }
    }
    this.isServiceReportsLoaded = true;
    this.setIsLoaded();
  }

  private getAttachmentLists(viewModelDetail: any, sapSystem: string) {
    const attachmentList = this.attachmentUtilService.generateDownloadAttachment(
      viewModelDetail.attachments, sapSystem, this.viewModel.ticketKey);
    forEach(attachmentList, attachment => {
      if (this.attachmentUtilService.isTicketCustomerViewAttachmentType(attachment.attachmentType)) {
        this.downloadAttachmentList.push(attachment);
      } else {
        this.downloadGeneratedAttachmentList.push(attachment);
      }
    });
    this.removeDocBoxDuplicates();
    this.downloadAttachmentList.sort((attachment1, attachment2) => {
      return NotificationAttachmentsComponent.mapAttachmentType(attachment2.attachmentType) -
        NotificationAttachmentsComponent.mapAttachmentType(attachment1.attachmentType);
    });
  }

  downloadAttachment(filename: string, url: string, index: number) {
    this.downloadFile(filename, url, 'attachments', index);
  }

  downloadGeneratedAttachment(filename: string, url: string, index: number) {
    this.downloadFile(filename, url, 'generatedAttachments', index);
  }

  downloadServiceReport(filename: string, url: string, index: number) {
    this.downloadFile(filename, url, 'serviceReports', index);
  }

  downloadFile(filename: string, url: string, attachmentType: string, index: number) {
    AddOmnitureAndRouterStateNameDirective
      .triggerDocumentsDownloadTracking(filename, url);

    this.isLoadingIndex[attachmentType].push(index);
    this.sourceRestService.downloadFromUrl(url)
      .pipe(
        finalize(() =>
          this.isLoadingIndex[attachmentType] = without(this.isLoadingIndex[attachmentType], index)),
        takeUntil(this.unsubscribe$))
      .subscribe(data =>
        this.downloadUtilService.generateBlobAndDownload(data, '', filename));
  }

  downloadDocBoxDocument(index: number, item: DocumentFile) {
    this.isLoadingIndex['docboxDocuments'].push(index);
    this.equipmentDetailService.getDocboxDownloadUrl(item.identifier).pipe(
      exhaustMap(url => this.sourceRestService.downloadFromUrl(url)),
      finalize(() => this.isLoadingIndex['docboxDocuments'] = without(this.isLoadingIndex['docboxDocuments'], index)),
      takeUntil(this.unsubscribe$)).subscribe(data =>
      this.downloadUtilService.generateBlobAndDownload(data, 'application/zip', item.name + '.' + item.fileExtension));
  }

  isActivity(): boolean {
    return isEqual(this.attachmentType, 'activity');
  }

  refreshViewModel() {
    this.unsubscribe$.next();

    if (this.isActivity()) {
      this.isLoaded = false;
      const url = this.activitiesRestService.activitiesDetailsRestName.replace(/:id/g, this.viewModel.ticketKey);
      this.activitiesRestService.clearCache(url);
    }
  }

  loadDocboxDocuments() {
    if (!this.showDocBoxDocuments) {
      return;
    }
    this.sourceRestService.getDocumentsFromSpecificSource(this.sourceRestService.docBoxDocumentRestName, this.viewModel.equipmentKey, `meta={"notification":"${this.viewModel.ticketNumber}"}`)
      .pipe(take(1)).subscribe((ticketDocuments) => {
      this.isLoaded = false;
      this.docboxDocumentList = ticketDocuments.documents[0]?.list;
      this.isDocBoxListLoaded = true;
      this.removeDocBoxDuplicates();
      this.setIsLoaded();
    });
  }

  setIsLoaded() {
    this.isLoaded = this.isAttachmentsLoaded && this.isServiceReportsLoaded && (this.isDocBoxListLoaded || !this.showDocBoxDocuments);
  }

  removeDocBoxDuplicates() {
    forEach(this.docboxDocumentList, docboxDocument => {
      const downloadAttachmentIndex = this.downloadAttachmentList.findIndex(downAtt => {
        return this.documentsEqual(downAtt, docboxDocument);
      })
      if (downloadAttachmentIndex > -1) {
        this.downloadAttachmentList.splice(downloadAttachmentIndex, 1);
      }
      const downloadGeneratedAttachmentIndex = this.downloadGeneratedAttachmentList.findIndex(downGenAtt => {
        return this.documentsEqual(downGenAtt, docboxDocument)
      })
      if (downloadGeneratedAttachmentIndex > -1) {
        this.downloadGeneratedAttachmentList.splice(downloadGeneratedAttachmentIndex, 1);
      }
    })
  }

  documentsEqual(sapDocument: TicketAttachmentViewModel, docboxDocument: DocumentFile): boolean {
    return sapDocument.attachmentName.toLowerCase() === docboxDocument.name.concat('.', docboxDocument.fileExtension).toLowerCase();
  }
}
