import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { WindowService } from '../../../core/window.service';

@Component({
  selector: 'hl-cancel-modal',
  templateUrl: './cancel-modal.component.html'
})
export class CancelModalComponent extends BaseModalPopup {

  @Output() noClick = new EventEmitter();
  @Output() yesClick = new EventEmitter();
  @Output() hideClick = new EventEmitter();
  @Input() headerMessage: string;
  @Input() descriptionMessage: string;
  @Input() translateDescription = true;

  window = this.windowService.nativeWindow;

  constructor(
    private windowService: WindowService,
    renderer: Renderer2) {
    super(renderer);
  }

  /**
   * Overriden method when clicking Ok to emit
   */
  ok() {
    super.hide();
    this.yesClick.emit();
  }

  close() {
    super.hide();
    this.noClick.emit();
  }

  hide() {
    super.hide();
    this.hideClick.emit();
  }
}
