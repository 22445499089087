import { ConfigurationAggregateType } from 'app/core/models/spr/basic-input-configuration/configuration-aggregate-type';
import { ConfigurationItem } from 'app/core/models/spr/basic-input-configuration/configuration-item';
import { BasicKpiType } from 'app/core/models/spr/basic-input-configuration/virtual-kpis/basic-kpi-type';
import { SimplifiedVirtualKpi } from 'app/core/models/spr/basic-input-configuration/virtual-kpis/simplified-virtual-kpi';
import { SprGraphKpiConfiguration, SprWidget } from 'app/core/models/spr/configuration-spr';
import { chartDateFormat, KpiTypes } from 'app/spr/configuration/spr-configuration-constants';
import { KPI_GROUP_TYPE } from 'app/spr/spr.service';
import moment from 'moment';

export const sprGraphFunctions = {
  orderArrayByProperty(array: any[], property: string, lastValue: string): any[] {
    return Object.assign([], array).sort((a, b) => {
      const firstEl = a[property].toLowerCase();
      const secondEl = b[property].toLowerCase();
      const lastEl = lastValue ? lastValue.toLowerCase() : lastValue;

      if (lastEl && firstEl === lastEl) {
        return 1;
      } else if (lastEl && secondEl === lastEl) {
        return -1;
      }
      if (firstEl < secondEl) {
        return -1;
      } else if (firstEl > secondEl) {
        return 1;
      }
      return 0;
    });
  },

  formatAxisValue(value: any): string {
    if (value instanceof Date) {
      return moment(value).format(chartDateFormat);
    }

    return (value as string).toString();
  },

  formatAxisValueToPercentage(value: any): string {
    return value + '%';
  },

  // tslint:disable-next-line:cognitive-complexity
  getKpisByType(kpis: SprGraphKpiConfiguration[]): {
    duration: SprGraphKpiConfiguration[],
    quantity: SprGraphKpiConfiguration[],
    percentage: SprGraphKpiConfiguration[],
    ratio: SprGraphKpiConfiguration[]
  } {
    const result = {
      duration: [],
      quantity: [],
      percentage: [],
      ratio: []
    };

    kpis?.forEach(kpi => {
      if ((kpi.duration && kpi.duration.aggregateType !== ConfigurationAggregateType.PERCENTAGE) ||
        (kpi.virtualKpi && kpi.virtualKpi.basicKpiType === BasicKpiType.DURATION &&
          !kpi.virtualKpi.basicPercentage &&
          (kpi.virtualKpi.aggregateType === ConfigurationAggregateType.SUM ||
            kpi.virtualKpi.aggregateType === ConfigurationAggregateType.MEAN ||
            kpi.virtualKpi.aggregateType === ConfigurationAggregateType.SUBTRACTION))) {
        result.duration.push(kpi);
      } else if (kpi.quantity ||
        (kpi.virtualKpi && kpi.virtualKpi.basicKpiType === BasicKpiType.QUANTITY &&
          (kpi.virtualKpi.aggregateType === ConfigurationAggregateType.SUM ||
            kpi.virtualKpi.aggregateType === ConfigurationAggregateType.MEAN ||
            kpi.virtualKpi.aggregateType === ConfigurationAggregateType.SUBTRACTION))) {
        result.quantity.push(kpi);
      } else if (kpi.duration && kpi.duration.aggregateType === ConfigurationAggregateType.PERCENTAGE ||
        this.isVKpiInPercent(kpi.virtualKpi)) {
        result.percentage.push(kpi);
      } else if (kpi.virtualKpi && kpi.virtualKpi.aggregateType === ConfigurationAggregateType.RATIO) {
        result.ratio.push(kpi);
      }
    });

    return result;
  },

  isVKpiInPercent(kpi: SimplifiedVirtualKpi): boolean {
    return kpi && (
      kpi.aggregateType === ConfigurationAggregateType.PERCENTAGE ||
      kpi.aggregateType === ConfigurationAggregateType.REVERT_SUM ||
      kpi.aggregateType === ConfigurationAggregateType.REVERT_MEAN ||
      kpi.aggregateType === ConfigurationAggregateType.REVERT_RATIO ||
      kpi.aggregateType === ConfigurationAggregateType.REVERT_PERCENTAGE ||
      kpi.basicPercentage);
  },

  getSecondAxisKpisBasedOnEvaluationOrder(graphKpiConfigurations: SprGraphKpiConfiguration[]): ConfigurationItem[] {
    const kpisByType = this.getKpisByType(graphKpiConfigurations);
    const kpiTypeKeys = Object.keys(KPI_GROUP_TYPE);

    return kpiTypeKeys.slice(kpiTypeKeys.length / 2)
      .sort((a, b) => KPI_GROUP_TYPE[a] - KPI_GROUP_TYPE[b])
      .map(key => key.toLowerCase())
      .map(type => kpisByType[type] as SprGraphKpiConfiguration[])
      .filter(configs => configs.length)
      .filter((_, index) => index !== 0)
      .reduce((kpis: ConfigurationItem[], configs) => kpis.concat(configs.map(c => c.duration || c.quantity || c.virtualKpi)), []);
  },

  setSecondAxisOnSeries(sprWidget: SprWidget): void {
    this.getSecondAxisKpisBasedOnEvaluationOrder(sprWidget.kpis).forEach(kpi => {
      sprWidget.calculatedData.forEach(series => {
        series.secondAxis = series.secondAxis || this.checkKpiName(kpi, series.name);
      });
    });
  },

  setSecondAxisOnSeriesData(sprWidget: SprWidget): void {
    this.getSecondAxisKpisBasedOnEvaluationOrder(sprWidget.kpis).forEach(kpi => {
      sprWidget.calculatedData.forEach(series => {
        series.series.forEach(seriesData => {
          seriesData.secondAxis = seriesData.secondAxis || this.checkKpiName(kpi, seriesData.name);
        });
      });
    });
  },

  checkKpiName(kpi: ConfigurationItem, name: string): boolean {
    if (kpi.aggregateType && kpi.name !== name) {
      return name === (kpi.name + ' (' + kpi.aggregateType + ')');
    }
    return kpi.name === name;
  },

  convertSeriesDatesToNames(sprWidget: SprWidget) {
    if (sprWidget.calculatedData) {
      sprWidget.calculatedData.forEach(data => data.name = moment(data.name).add(3, 'days').format(chartDateFormat));
    }
  },

  convertSeriesNamesToDates(sprWidget: SprWidget) {
    if (sprWidget.calculatedData) {
      sprWidget.calculatedData.forEach(data => data.name = moment(data.name, chartDateFormat).toDate());
    }
  },

  getKpiType(kpiConfiguration: SprGraphKpiConfiguration): KpiTypes {
    if (kpiConfiguration.duration) {
      return KpiTypes.DURATION;
    } else if (kpiConfiguration.quantity) {
      return KpiTypes.QUANTITY;
    } else if (kpiConfiguration.virtualKpi) {
      return KpiTypes.VIRTUAL;
    }
    return undefined;
  },

  getKpiName(config: SprGraphKpiConfiguration): string {
    return config.duration?.name || config.quantity?.name || config.virtualKpi?.name;
  }
};
