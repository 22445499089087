@if (currentNotification) {
  <div class="scrollable-content scrollable-content--with-pagination" data-cy="security-detail-overlay">
    <div class="margin-bottom-1 border-none">
      <div class="margin-bottom-1">
        <div class="row">
          <div class="col-12">
            <p class="lead margin-bottom-0 margin-top-0">{{ currentNotification?.name }}</p>
            <p class="margin-bottom-0 label-status--colorfully--status-{{status}}">
              <span>
                {{ statusLabel.get(status) | translate }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <hl-progress-indicator class="spinner-top-none" [loading]="loading"></hl-progress-indicator>
    @if (!loading) {
      <div class="multi-column multi-column-sm-4 multi-column-lg-2">
        <div class="bare-list">
          <div class="multi-column__item margin-bottom-1" data-cy="security-detail-publication-date">
            <div class="color-black-60 font-size-xsmall margin-bottom-0" [translate]="'SECURITY_PUBLICATION_DATE'"></div>
            @if (currentNotification.publication) {
              <div>{{ currentNotification.publication | datePipeWrapper:datePattern }}</div>
            }
          </div>
        </div>
        <div class="multi-column__item margin-bottom-1" data-cy="security-detail-cvss">
          <div class="color-black-60 font-size-xsmall margin-bottom-0" [translate]="'CVSS' + currentNotification.cvssversion.toLowerCase()"></div>
          <div>{{ ('SECURITY_CVSS_OVERALL' | translate) + ': ' }} {{ currentNotification.cvssoverallscore }} {{
              '(' +
              capitalizeFirstLetter('SECURITY_COMPONENT_ONLY' | translate) + ': ' + currentNotification.cvssbasescore
            }} {{ ' / ' }} {{ ('SECURITY_CVSS_TEMPORAL' | translate) + ': ' }}
            {{ currentNotification.cvsstemporalscore + ')' }}
          </div>
        </div>
      </div>
      @if (showReadMore) {
        <div class="margin-bottom-1" data-cy="security-detail-cves">
          <p class="color-black-60 font-size-xsmall margin-bottom-0" [translate]="'SECURITY_REFERENCE_LINKS'"></p>
          <hl-read-more-arrays [array]="currentNotification.cves" [maxItems]="5"></hl-read-more-arrays>
        </div>
      }
      <hl-read-more [maxLength]="150" [textTranslation]="currentNotification?.description | toHtml"></hl-read-more>
      <p>{{ numberOfEquipmentsForOverlay }} {{ ('LABEL_AFFECTED_EQUIPMENT' | translate) }}</p>
      <div class="row">
        <div class="col-8">
          <hl-search-field searchFieldClasses="textfield--large margin-bottom-0" [(searchInput)]="overlaySearchInput"></hl-search-field>
        </div>
        <div class="col-4">
          <hl-multi-selectbox [options]="multiSelectEvaluationOptions" [showLabelWithColorStatus]="true" [(value)]="selectedDropDownList.evaluation"
            name="evaluation" [label]="'SECURITY_EVALUATION_RESULT'" (valueChange)="onFilterChange($event)"
            [dataCy]="'security-detail-evaluation-result'"></hl-multi-selectbox>
        </div>
      </div>
      <hl-accordion-with-equipments (onNumberOfEquipmentLoaded)="setNumberOfEquipmentsForOverlay($event)" [data]="currentNotification"
        [evaluationResult]="evaluationResult" [search]="overlaySearchInput" [evaluations]="selectedDropDownList.evaluation"
        [myEquipmentChecked]="isMyEquipmentChecked()" [featureToggle]="true"
        [myEquipmentProfileList]="getMyEquipmentProfileList()"></hl-accordion-with-equipments>
    }
  </div>
}
