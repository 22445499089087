import { forkJoin, Observable } from 'rxjs';
import { configValueSplitter, NotifStatus, plannedActivity, plannedActivityAndTraining, plannedTraining } from '../../core-constants.service';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from '../../utils/date-util.service';
import { ActivitiesRestService } from '../../rest-services/activities-rest.service';
import { EquipmentRestService } from '../../rest-services/equipment-rest.service';
import { LifeNetUtilService } from '../../utils/life-net-util.service';
import { ActivitiesViewModel } from '../../view-models/activities-view-model';
import { Activities } from '../../models/activities/activities';
import { Injectable } from '@angular/core';
import { filter as lodashFilter, forEach, gte, includes, isEqual } from 'lodash-es';
import { StatusCountViewModel } from '../../models/status-count-view-model';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { SelectOption } from 'app/core/models/select-option';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { BrowserStateService } from '../browser-state.service';
import { Router } from '@angular/router';
import { GetActivityStatusColorPipe } from '../../../shared/pipes/get-activity-status-color/get-activity-status-color.pipe';
import { ActivitiesStatusDescriptionTranslationService } from './activities-status-description-translation-service';
import { AppointmentsFilterStatus } from '../../../activities-common/appointments-constants';

@Injectable({providedIn: 'root'})
export class ActivitiesUtilService {
  private _selectedActivity: ActivitiesViewModel = null;
  private statusColorPipe: GetActivityStatusColorPipe;

  private readonly APPOINTMENTS_STATUS_ORDER = [
    AppointmentsFilterStatus.NOT_SCHEDULED.toString(),
    AppointmentsFilterStatus.SCHEDULED.toString(),
    AppointmentsFilterStatus.OVERDUE.toString(),
    AppointmentsFilterStatus.REMOTE_INFORMED.toString(),
    AppointmentsFilterStatus.CLOSED.toString()
  ];

  constructor(private lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private activitiesRestService: ActivitiesRestService,
    private dateUtilService: DateUtilService,
    private translateService: TranslateService,
    private countryConfigRestService: CountryConfigRestService,
    private browserStateService: BrowserStateService,
    private descriptionTranslationService: ActivitiesStatusDescriptionTranslationService,
    private router: Router) {
    this.statusColorPipe = new GetActivityStatusColorPipe(descriptionTranslationService);
  }

  get selectedActivity(): ActivitiesViewModel {
    return this._selectedActivity;
  }

  set selectedActivity(selectedActivity: ActivitiesViewModel) {
    this._selectedActivity = selectedActivity;
  }

  /**
   * @description Returns the view model for tickets by merging properties from
   * /equipment to /tickets
   * @param {number} openCloseStatusFilter : 1 equals open , 2 equals closed
   * @returns {Observable<ActivitiesViewModel[]>}
   */
  getActivitiesViewModelList(openCloseStatusFilter: NotifStatus): Observable<ActivitiesViewModel[]> {
    return this.getActivitiesViewModelListFrom(this.activitiesRestService.getActivities(openCloseStatusFilter));
  }

  getActivitiesViewModelListFrom(activities: Observable<Activities[]>): Observable<ActivitiesViewModel[]> {

    const findObject = {
      findKey: 'key',
      findValue: 'equipmentKey',
      propertiesToMerge: [
        'productName',
        'myEquipmentName',
        'siemensId',
        'department',
        'street',
        'city',
        'zip',
        'modality',
        'modalityTranslation',
        'state',
        'cmdbEquipment',
        'customerName'
      ]
    };

    return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
      activities, findObject);
  }

  getSingleActivity(key: string, notifStatus: NotifStatus): Observable<ActivitiesViewModel> {
    return this.getActivitiesViewModelList(notifStatus).pipe(
      map(activityViewModelListResponse => activityViewModelListResponse.filter(activity => activity.ticketKey === key)[0])
    );
  }

  /**
   * @description Get the types for activities
   */
  getActivityTypes() {
    return this.activitiesRestService.getActivityTypes().pipe(
      map(typesResponse => {
        const activityLocalTypes = [];
        forEach(typesResponse, (type) => {
          const obj: { [k: string]: any } = {};
          obj.title = type.typeDescription;
          obj.value = type.typeId;
          activityLocalTypes.push(obj);
        });
        return activityLocalTypes;
      })
    );
  }

  /**
   *
   * @param {String} activityType
   * @param {Object} config
   * @param {boolean} showAppointments
   *
   * @description
   * Get the future dashboard planned activities filtered by activity type.
   * Note:-
   * Future means all activities from current time stamp.
   */
  getDashboardPlannedActv(activityType: string, config: Record<string, string>, showAppointments: boolean): Observable<ActivitiesViewModel[]> {
    return this.getActivitiesViewModelList(NotifStatus.OPEN).pipe(
      map(activitiesResponse => this.getFuturePlannedActvBasedOnType(
        this.getActivitiesForList(activitiesResponse, showAppointments), activityType, config, showAppointments)
      )
    );
  }

  getActivitiesForList(activities: ActivitiesViewModel[], showAppointments: boolean) {
    if (showAppointments === true) {
      return this.getOpenAppointmentsActivities(activities);
    }

    return activities;
  }

  /**
   *
   * @param {object} dataset | Merged dataset to be filtered
   * @param {string} type | Type to be filtered
   * @param {object} config | country config file   *
   * @param {boolean} showAppointments | toggle for validating appointments vs activities
   *
   * @description
   * Get the future planned activities based on type.
   * Note:- type here is 'activity' or 'training'
   */
  getFuturePlannedActvBasedOnType(dataset: ActivitiesViewModel[], type: string, config: Record<string, string>, showAppointments: boolean): ActivitiesViewModel[] {
    if (isEqual(type, plannedActivity)) {
      return this.filterByFutureActivity(dataset, config.PLANNED_ACTIVITY_IDENTIFIER, showAppointments);
    } else if (isEqual(type, plannedTraining)) {
      return this.filterByFutureActivity(dataset, config.TRAINING_IDENTIFIER, showAppointments);
    } else if (isEqual(type, plannedActivityAndTraining)) {
      const activitiesAndTraining =
        [config.PLANNED_ACTIVITY_IDENTIFIER, config.TRAINING_IDENTIFIER].filter(id => id !== '').join(configValueSplitter);
      return this.filterByFutureActivity(dataset, activitiesAndTraining, showAppointments);
    }
  }

  filterByFutureActivity(dataset: ActivitiesViewModel[], activityTypes: string, showAppointments: boolean): ActivitiesViewModel[] {
    // Get the current time stamp
    const currentDate = new Date();
    const currentDateTimeStamp = currentDate.getTime();
    const filteredDataSet = [];

    if (activityTypes) {

      const splitTypeArr = activityTypes.split(configValueSplitter);

      forEach(dataset, (item) => {

        if (includes(splitTypeArr, item.type)) {
          const plannedStartTimeStamp = this.dateUtilService.getUTCToUxTimeStamp(item.plannedStart);

          // Check if planned Start Time if future time or current date
          if (plannedStartTimeStamp && gte(plannedStartTimeStamp, currentDateTimeStamp)
            && ((item.scheduled && !showAppointments) || (item.tpFstatus === '2' && showAppointments))) {
            filteredDataSet.push(item);
          }
        }

      });
    }
    return filteredDataSet;
  }

  /**
   *
   * @param {String} key | equipment key
   * @param {boolean} showClosed
   * @param addTimeZone time zone only when P58 appointment toggle is true
   * @description
   * Get activities filtered for an equipment and if show closed activities concatenate with filtered activities.
   * Note:
   * Here response is <ActivitiesViewModel[]>
   */
  getPlannedActvWithClosedActvForEquipment(key: string, showClosed: boolean, addTimeZone: boolean): Observable<ActivitiesViewModel[]> {
    if (showClosed) {
      return this.getActivitiesViewModelListFrom(
        this.getAllActivitiesForEquipment(key).pipe(
          mergeMap(openActivitiesResponse => this.activitiesRestService.getClosedActivities(key, addTimeZone).pipe(
            map(closedActivitiesResponse => this.addClosedActivities(openActivitiesResponse, closedActivitiesResponse))
          ))
        )
      );
    } else {
      return this.getActivitiesViewModelListFrom(this.getAllActivitiesForEquipment(key));
    }
  }

  addClosedActivities(openActivities: Activities[], closedActivities: Activities[]): Activities[] {
    closedActivities.forEach(closed => {
      if (!openActivities.some(open => open.ticketNumber === closed.ticketNumber)) {
        openActivities.push(closed);
      }
    });
    return openActivities;
  }

  showPlannedActivityStartDate(activity: ActivitiesViewModel) {
    return (activity.plannedStart && activity.scheduled) || activity.completedDate || activity.dueDate
      || (activity.plannedStart && activity.sapSystem === 'D35');
  }

  loadFilteredActivityStatus(equipmentKeys: string[]): Observable<StatusCountViewModel[]> {
    const activities$ = this.activitiesRestService.getActivities(NotifStatus.OPEN);
    const config$ = this.countryConfigRestService.getConfig().pipe(take(1));

    return forkJoin([activities$, config$]).pipe(
      filter(([activities, config]) => !!activities && !!config),
      map(([activities, config]) => {
        const filteredActivities = (isEqual(config.FEATURE_TOGGLE_P58_APPOINTMENTS, 'true') ?
          this.getOpenAppointmentsActivities(activities as ActivitiesViewModel[]) : activities)
          .filter(a => equipmentKeys.indexOf(a.equipmentKey) !== -1);
        return this.getActivitiesStatusValues(filteredActivities, config);
      })
    );
  }

  /**
   * Creates a List of StatusCountViewModel, where,
   * the label(from country config), the css class type and the count of each status type
   * is mapped according to statusType to a ViewModel .
   *
   * @param {Activities[]} activities
   * @param config
   * @returns {StatusCountViewModel[]}
   */
  getActivitiesStatusValues(activities: Activities[], config: Record<string, string>): StatusCountViewModel[] {
    const statusResultList: StatusCountViewModel[] = [];
    const statusOrder = config.ORDER_ACTIVITY_STATUS.split(',');

    let countAll = 0;

    activities.forEach(activity => {
      if (statusOrder.includes(activity.pmStatus)) {
        const statusColorMap = this.statusColorPipe.transform(activity);
        const color = statusColorMap.colorClass ? statusColorMap.colorClass.split('-').pop() : null;
        const pmDescription = statusColorMap.label;

        if (pmDescription && color) {
          let status = statusResultList.find(statusCount => statusCount.title === pmDescription && statusCount.class === color);
          if (!status) {
            status = this.createStatusViewModel(pmDescription, color, 0,
              isEqual(config.FEATURE_TOGGLE_P58_APPOINTMENTS, 'true') ? activity.filterStatus : activity.pmStatus);
            statusResultList.push(status);
          }

          status.count++;
          countAll++;
        }
      }
    });
    statusResultList.push(this.createStatusViewModel('LABEL_ALL_PLANNED_ACTIVITIES', 'All', countAll, 'All'));

    return statusResultList;
  }

  /**
   * Creates Status ViewModel object
   * @param {string} title label from country config
   * @param {string} classType css class type for the status
   * @param {number} statusCount
   * @param {string} status
   * @returns {StatusCountViewModel}
   */
  createStatusViewModel(title: string, classType: string, statusCount: number, status: string): StatusCountViewModel {
    return {title, status, class: classType, count: statusCount};
  }

  /**
   * @description reads the country config for all available status and generate the dropdown options
   *
   * @returns {Observable<any[]>}
   */
  getActivityStatusDropDownOptions(notifStatus: NotifStatus): Observable<SelectOption[]> {

    const statusDropDownOption = [];

    return this.countryConfigRestService.getConfig().pipe(map((configResponse: any) => {
      let statusOrder: string[];

      switch (notifStatus) {
        case NotifStatus.OPEN:
          statusOrder = configResponse.ORDER_ACTIVITY_STATUS.split(',');
          break;
        case NotifStatus.CLOSED:
          statusOrder = configResponse.ORDER_ACTIVITY_STATUS_CLOSED.split(',');
          break;
        case NotifStatus.ALL:
          statusOrder = configResponse.ORDER_ACTIVITY_STATUS.split(',')
            .concat(configResponse.ORDER_ACTIVITY_STATUS_CLOSED.split(','));
          break;
      }

      // tslint:disable-next-line:ban
      let translate = this.translateService.instant('PLANNED_ACTIVITIES_NOT_SCHEDULED');
      statusDropDownOption.push({
        title: translate,
        value: translate
      });

      forEach(statusOrder, (status) => {
        if (status && status.length > 0) {
          // tslint:disable-next-line:ban
          translate = this.translateService.instant('LABEL_ACTIVITY_STATUS_' + status);
          if (!statusDropDownOption.find(item => item.title === translate)) {
            statusDropDownOption.push({
              title: translate,
              value: translate
            });
          }
        }
      });

      return statusDropDownOption;

    }));
  }

  getActivityDetails(key: string, customerId: string, appointmentId?: string) {
    return this.activitiesRestService.getActivityDetails(key, customerId, appointmentId);
  }

  showActivityHistoryTab(config: any) {
    return isEqual(config.ACTIVITY_HISTORY_TAB_FEATURE_TOGGLE, 'true');
  }

  navigateToTab(key: string, state: string, route: any, selectedItem: ActivitiesViewModel) {
    this.browserStateService.setUserNavigation();
    this.router
      .navigate([key, state], {
        relativeTo: route, replaceUrl: true, queryParams: state === 'delivery' ?
          {
            'ticketNumber': selectedItem.ticketNumber,
            'ticketType': selectedItem.type
          } : undefined
      })
      .then(() => {
        this.browserStateService.resetUserNavigation();
      });
  }

  private getAllActivitiesForEquipment(key: string): Observable<Activities[]> {

    return this.activitiesRestService.getActivities(NotifStatus.OPEN).pipe(map((activitiesResponse) => {
      // get the filtered equipment activity (contains all), no type check
      return lodashFilter(activitiesResponse, activity => activity.equipmentKey === key);
    }));
  }

  /**
   * Method returns intersection of unique statuses (pmDescriptions) allowed by filter and located in the dataset
   */
  getAvailableActivities(dataset: ActivitiesViewModel[], filterActivities: SelectOption[]): SelectOption[] {
    const activityStatusesInList = [];
    dataset.forEach(activity => {
      if (activityStatusesInList.indexOf(activity.pmDescription) === -1) {
        activityStatusesInList.push(activity.pmDescription);
      }
    });
    return filterActivities.filter(item => activityStatusesInList.includes(item.value));
  }

  getAvailableAppointmentsStatuses(activities: ActivitiesViewModel[]): SelectOption[] {
    return activities
      .map(activity => activity.filterStatus)
      .filter(n => n !== '-1')
      .filter((x, i, a) => a.indexOf(x) === i)
      .sort((a, b) => this.APPOINTMENTS_STATUS_ORDER.indexOf(a) - this.APPOINTMENTS_STATUS_ORDER.indexOf(b))
      .map(statusNumber => ({
        title: 'LABEL_ACTIVITY_APPOINTMENT_STATUS_' + statusNumber,
        value: statusNumber
      }));
  }

  /**
   * Method fills translated pmDescription for activities in the list.
   */
  fillActivityStatusDescriptions(activities: ActivitiesViewModel[]) {
    activities.forEach(activity => {
      this.descriptionTranslationService.getActivityStatusDescriptionTranslation(activity).subscribe(
        value => activity.pmDescription = value
      );
    });
  }

  addSortDateField(activities: ActivitiesViewModel[], usePlannedStartAlways = false) {
    activities.forEach(activity => {
      if (usePlannedStartAlways) {
        activity.sortDate = activity.plannedStart;
      } else if (activity.scheduled || (!activity.completedDate && !activity.dueDate && activity.plannedStart)) {
        activity.sortDate = activity.plannedStart;
      } else if (activity.dueDate && !activity.completedDate) {
        activity.sortDate = activity.dueDate;
      } else if (activity.completedDate) {
        activity.sortDate = activity.completedDate;
      }
    });
  }

  convertActivityToAppointments(activity: ActivitiesViewModel): ActivitiesViewModel[] {
    return activity.appointments?.map(appointment => ({
      ...activity,
      appointmentKey: appointment.appointmentKey,
      appointmentNumber: appointment.appointmentNumber,
      tpFstatus: appointment.tpFstatus,
      filterStatus: appointment.filterStatus,
      colorStatus: appointment.colorStatus,
      plannedStart: appointment.plannedStart,
      plannedEnd: appointment.plannedEnd,
      plannedStartDate: appointment.plannedStart?.split('T')[0],
      plannedEndDate: appointment.plannedEnd?.split('T')[0],
      appointments: null
    })) || [];
  }

  getOpenAppointmentsActivities(activities: ActivitiesViewModel[]): ActivitiesViewModel[] {
    return activities.map(a => this.convertActivityToAppointments(a))
      .reduce((list, appointments) => list.concat(appointments), [])
      .filter(appointment => appointment.filterStatus !== AppointmentsFilterStatus.CLOSED);
  }

  getTicketKeyFromAppointmentKey(appointmentKey: string): string {
    return appointmentKey.slice(0, appointmentKey.lastIndexOf('-'));
  }

  getAppointmentIdFromAppointmentKey(appointmentKey: string): string {
    return appointmentKey.slice(appointmentKey.lastIndexOf('-') + 1);
  }
  isNotScheduledForP58Country(activity: ActivitiesViewModel) {
    let status = activity.tpFstatus? activity.tpFstatus : activity.pmStatus

    return (activity.sapSystem === "P58") && (status === "1")
  }
}
