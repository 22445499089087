import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivitiesEventLogsComponent } from '../activities-event-logs/activities-event-logs.component';
import { finalize } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-appointments-event-logs',
  templateUrl: '../activities-event-logs/activities-event-logs.component.html'
})
export class AppointmentsEventLogsComponent extends ActivitiesEventLogsComponent implements OnInit, OnDestroy {

  setViewModelItemsForList(config: any) {
    this.activatedRoute.params
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params: any) => {
      const id = params['appointmentKey'] || params['id'];
      this.isLoaded = false;

      this.initProperties();
      this.setViewModelItems(config, id);
    });
  }

  setViewModelItems(config: any, id: string) {
    const ticketKey = this.activitiesUtilService.getTicketKeyFromAppointmentKey(id);
    const appointmentId = this.activitiesUtilService.getAppointmentIdFromAppointmentKey(id);
    this.activitiesUtilService.getSingleActivity(ticketKey, this.getNotifStatus()).subscribe((response) => {
      const customerId = response?.customerId || this.activitiesCacheService.getCustomerId();
      this.id = ticketKey;
      this.activitiesUtilService.getActivityDetails(ticketKey, customerId, appointmentId)
        .pipe(finalize(() => this.isLoaded = true))
        .subscribe((activityResponse) => {
          if (activityResponse) {
            this.activitiesDetail = activityResponse;
            this.setViewModelProperties(config, activityResponse);
          } else {
            this.logService.debug('no matching activity found for id: ' + id);
          }
        });
    });
  }
}
