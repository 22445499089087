import { UpdateStatus } from '../models/update-status';
import { SystemUpdateBase } from '../models/systemUpdates/system-update-base';

export class SystemUpdateBaseViewModel {
  public title: string;
  public updateStatus: UpdateStatus;
  public updateNumber: string;
  private category: string;
  public dueDate: string;
  public cyberSecurity: string;
  public asuStatus: string;

  constructor(model: SystemUpdateBase) {
    this.title = model.title;
    this.updateStatus = model.status;
    this.updateNumber = model.content;
    this.category = model.category;
    this.dueDate = model.dueDate;
    this.cyberSecurity = model.cyberSecurity;
    this.asuStatus = model.asuStatus;
  }

  get updateStatusColor(): UpdateStatusColor {
    switch (this.updateStatus) {
      case UpdateStatus.Pending:
        return UpdateStatusColor.Green;
      case UpdateStatus.Overdue:
        return UpdateStatusColor.Yellow;
      case UpdateStatus.LongOverdue:
        return UpdateStatusColor.Red;
      default:
        return UpdateStatusColor.Gray;
    }
  }

  get updateStatusRank(): number {
    switch (this.updateStatus) {
      case UpdateStatus.Pending:
        return 1;
      case UpdateStatus.Overdue:
        return 2;
      case UpdateStatus.LongOverdue:
        return 3;
      default:
        return 0;
    }
  }

  get updateStatusKey(): string {
    switch (this.updateStatus) {
      case UpdateStatus.Pending:
        return 'SYSTEM_UPDATES_STATUS_PENDING';
      case UpdateStatus.Overdue:
        return 'SYSTEM_UPDATES_STATUS_OVERDUE';
      case UpdateStatus.LongOverdue:
        return 'SYSTEM_UPDATES_STATUS_LONG_OVERDUE';
      case UpdateStatus.Completed:
        return 'SYSTEM_UPDATES_STATUS_COMPLETED';
      default:
        return `SYSTEM_UPDATES_EQ_STATUS_${this.updateStatus}`;
    }
  }

  get categoryKey(): string|null {
    if (this.category) {
      return `LABEL_EVENT_CATEGORY_${this.category}`;
    }
    return null;
  }
}

export enum UpdateStatusColor {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
  Gray = 'gray'
}
