<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate" [disableKeyboardClose]="true"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" data-cy="ic-code-dialog" tabindex="-1" role="dialog" aria-modal="true">
    @if (icCode) {
      <div class="modal-dialog__container" role="document">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title">
            @if (icCode.successful) {
              <span [translate]="'ICCODE_SUCCESS_HEADLINE'"></span>
            } @else {
              <span [translate]="'ICCODE_ERROR_HEADLINE'"></span>
            }
          </h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" data-cy="ic-code-modal-close" aria-label="Close"
            (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="modal1_desc">
          <hl-progress-indicator [loading]="showSpinner" showlabel="false"></hl-progress-indicator>
          @if (!showSpinner && icCode.successful) {
            <div class="row">
              <div class="col-12 text-align-center" data-cy="ic-code-success-message">
                <p>{{ equipmentInfo }}</p>
                <div class="icon-container icon-container--success margin-bottom-tiny-5">
                  <i class="icon-check icon font-size-super"></i>
                </div>
                <p>{{ 'ICCODE_CODE_LABEL' | translate }}</p>
                <p class="lead" data-cy="ic-code-value">{{ icCode.leadingZeros }}{{ icCode.value }}</p>
                <p>{{ 'ICCODE_SUCCESS_ADVICE' | translate }}</p>
                @if (showAsuError) {
                  <p class="color-red margin-bottom-0">
                    {{ asuErrorLabel | translate }} </p>
                }
              </div>
            </div>
          }
          @if (!showSpinner && !icCode.successful) {
            <div class="row">
              <div class="col-12 text-align-center">
                <p data-cy="ic-code-error-message">{{ icCode.getErrorMessageKey() | translate }}</p>
                <div class="icon-container icon-container--error margin-bottom-tiny-5">
                  <i class="icon-close icon font-size-super"></i>
                </div>
                @if (shouldShowIcCode()) {
                  <p>
                    {{ 'ICCODE_CODE_LABEL' | translate }} </p><p class="lead">
                    {{ icCode.leadingZeros }}{{ icCode.value }} </p>
                }
                @if (!icCode.shouldShowWsError()) {
                  @if (icCode.shouldShowTicketCreateLink() && !isWelcomePage) {
                    <p [innerHTML]="getErrorTicketText() | async" (click)="transitToCreateTicket($event)" id="ic-create-ticket"></p>
                  }
                  <p [innerHTML]="getContactText() | async"></p><p>
                    {{ 'ICCODE_ERROR_ADVICE' | translate }} </p>
                }
                @if (showAsuError) {
                  <p class="color-red margin-bottom-0">{{ asuErrorLabel | translate }} </p>
                }
                @if (icCode.shouldShowWsError()) {
                  <p>{{ 'ICCODE_ERROR_WS_SERVICE_CALL_DESC' | translate }} </p>
                }
              </div>
            </div>
          }
        </div>
        <div class="modal-dialog__footer justify-content-center padding-0">
          <div class="logo margin-0"></div>
        </div>
      </div>
    }
  </div>
</ng-template>
