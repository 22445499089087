import { Component, computed, inject, input, signal, viewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitiesViewModel } from '../../../../core/view-models/activities-view-model';
import { TooltipModule } from '../../../../tooltip/tooltip.module';
import { DotService } from '../../services/dot.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { DotBookableStatus } from '../../models/dot-bookable-status';
import { of, switchMap } from 'rxjs';
import { DotScheduleModalComponent } from '../dot-schedule-modal/dot-schedule-modal.component';
import { DotBookableStateService } from 'app/appointments/dot/services/dot-bookable-state.service';

@Component({
  selector: 'hl-dot-schedule-button',
  templateUrl: './dot-schedule-button.component.html',
  standalone: true,
  imports: [
    DotScheduleModalComponent,
    TranslateModule,
    TooltipModule
  ]
})
export class DotScheduleButtonComponent {

  scheduleModal = viewChild(DotScheduleModalComponent);
  private dotService = inject(DotService);
  private dotBookableStateService = inject(DotBookableStateService);

  currentAppointment = input.required<ActivitiesViewModel>();

  isLoading = signal(true);
  isVisible = computed(() => this.dotService.isAvailableFor(this.currentAppointment()));
  isReschedule = computed(() => this.dotService.isRescheduleFor(this.currentAppointment()));
  bookableStatus = signal<DotBookableStatus | null>(null);

  constructor() {
    toObservable(this.currentAppointment).pipe(
      switchMap(appointment => {
        if (!this.dotService.isAvailableFor(appointment)) {
          return of(null);
        }
        this.isLoading.set(true);
        return this.dotBookableStateService.getAndForceUpdateAppointmentStatus(appointment);
      }),
      takeUntilDestroyed()
    ).subscribe(bookableStatus => {
      this.bookableStatus.set(bookableStatus);
      this.isLoading.set(false);
    });
  }

}
