import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { Equipment } from 'app/core/models/equipment/equipment';
import { EquipmentRestService } from 'app/core/rest-services/equipment-rest.service';
import { green, red, yellow } from 'app/core/core-constants.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { translationCountryPrefix } from 'app/core/utils/impersonation-utils.service';
import { CustomerRestService } from 'app/core/rest-services/customer-rest.service';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { SyngoVoucherInput } from 'app/core/models/equipment/documents/syngo-voucher-input';
import { isEqual } from 'lodash-es';
import { UserUtilService } from 'app/core/services/user/user-util.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-syngo-voucher-request-modal',
  templateUrl: './syngo-voucher-request-modal.component.html'
})
export class SyngoVoucherRequestModalComponent extends BaseModalPopup implements OnInit {

  @Input()
  equipment: Equipment;
  isFormSubmitted = false;
  syngoVoucherForm: UntypedFormGroup;
  equipmentStatusColorMap = [];
  showValidationMessage = false;
  showExtendedFields = false;
  defaultFormValues;

  constructor(
    private fb: UntypedFormBuilder,
    private toasterService: ToasterService,
    private configService: CountryConfigRestService,
    private translateService: TranslateService,
    private customerRestService: CustomerRestService,
    private equipmentRestService: EquipmentRestService,
    private userUtilService: UserUtilService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.init();
  }

  hide() {
    this.reset();
    super.hide();
    this.syngoVoucherForm.reset(this.defaultFormValues);
  }

  reset() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
  }

  init() {
    this.showSpinner = true;
    this.reset();
    const countryTranslation$ = this.translateService.get(
      translationCountryPrefix + this.equipment.countryCode
    );
    const config$ = this.configService.getConfig();
    const user$ = this.userUtilService.getUser();
    const customerData$ = this.customerRestService.getCustomerNamesData(this.equipment.customerId);

    combineLatest([config$, countryTranslation$, customerData$, user$]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([config, countryLabel, customerData, user]) => {
        this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_GREEN] = green;
        this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_RED] = red;
        this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_YELLOW] = yellow;
        this.showExtendedFields = isEqual(config.FEATURE_TOGGLE_SYNGO_VOUCHER_EXTENDED, 'true');
        this.createForm(countryLabel, customerData.name1.trim().substring(0, 30), user.email);
        this.setDefaultFormValues(countryLabel, customerData.name1.trim().substring(0, 30), user.email);
        this.showSpinner = false;
      });
  }

  ok() {
    this.isFormSubmitted = true;

    if (this.syngoVoucherForm.valid) {
      this.showSpinner = true;
      this.showValidationMessage = false;
      // call the backend rest end point
      this.equipmentRestService.requestSyngoVoucher(this.getRequestBody())
        .pipe(finalize(() => this.hide()))
        .subscribe({
          next: () => this.toasterService.showTranslatedSuccessToaster('SYNGO_VOUCHER_REQUEST_SUCCESS'),
          error: () => this.toasterService.emitFrontendGenericErrorMessage()
        });
    } else {
      this.showValidationMessage = true;
    }
  }

  getRequestBody() {
    const body = {} as SyngoVoucherInput;
    body.functionalLocation = this.equipment.siemensId;
    body.productName = this.equipment.productName;
    body.eqSerialNumber = this.equipment.serialNumber;
    body.eqMaterialNumber = this.equipment.materialNumber;
    body.eqSoftwareVersion = this.equipment.softwareVersion;
    body.customerName1 = this.syngoVoucherForm.controls['institution'].value;
    body.customerName2 = '';
    body.street = this.syngoVoucherForm.controls['street'].value;
    body.houseNumber = this.syngoVoucherForm.controls['houseNumber'].value;
    body.zipCode = this.syngoVoucherForm.controls['zip'].value;
    body.city = this.syngoVoucherForm.controls['city'].value;
    body.country = this.equipment.countryCode;
    body.creationDate = this.datePipeWrapperPipe.transform(new Date(), 'YYYY-MM-DD HH:mm:ss');
    return this.showExtendedFields ? this.getExtendedRequestBody(body) : body;
  }

  private getExtendedRequestBody(body: SyngoVoucherInput) {
    body.nameOfShipto = this.syngoVoucherForm.controls['nameOfShipto'].value;
    body.phone = this.syngoVoucherForm.controls['phone'].value;
    body.department = this.syngoVoucherForm.controls['department'].value;
    body.additionalInformation = this.syngoVoucherForm.controls['additionalInformation'].value;
    body.email = this.syngoVoucherForm.controls['email'].value;
    return body;
  }

  private createForm(countryLabel: string, institution: string, email: string) {
    this.syngoVoucherForm = this.showExtendedFields ? this.fb.group({
        institution: [institution, [Validators.required]],
        nameOfShipto: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        department: ['', []],
        additionalInformation: ['', []],
        street: [this.equipment.street, [Validators.required]],
        houseNumber: ['', []],
        zip: [this.equipment.zip, [Validators.required]],
        city: [this.equipment.city, [Validators.required]],
        country: [countryLabel, [Validators.required]],
        email: [email, [Validators.required]]
      }
    ) : this.fb.group({
        institution: [institution, [Validators.required]],
        street: [this.equipment.street, [Validators.required]],
        houseNumber: ['', []],
        zip: [this.equipment.zip, [Validators.required]],
        city: [this.equipment.city, [Validators.required]],
        country: [countryLabel, [Validators.required]]
      }
    );
  }

  private setDefaultFormValues(countryLabel: string, institution: string, email: string) {
    this.defaultFormValues = this.showExtendedFields ? {
      institution,
      nameOfShipto: '',
      phone: '',
      department: '',
      additionalInformation: '',
      street: this.equipment.street,
      houseNumber: '',
      zip: this.equipment.zip,
      city: this.equipment.city,
      country: countryLabel,
      email
    } : {
      institution,
      street: this.equipment.street,
      houseNumber: '',
      zip: this.equipment.zip,
      city: this.equipment.city,
      country: countryLabel
    };
  }

}
