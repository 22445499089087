<hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>
@if (isLoaded) {
  <section class="dataview overflow-hidden">
    @if (!p58Contracts) {
      <hl-excel-export [exportGenerate]="handleContractExport" [rowCount]="getExportListCount()" data-cy="contract-overlay-export"
        [exportOrderConfig]="'EXPORT_COLUMN_ORDER_CONTRACTS_DETAILS'" [fileName]="'CONTRACT_DETAILS_EXPORT_FILENAME'"></hl-excel-export>
    }
    @if (contractDetailFields && contractDetailFields.length > 0) {
      <div class="row">
        <div class="col-12">
          <div class="block-grid block-grid-sm-2 block-grid-lg-3 margin-top-1">
            @for (field of contractDetailFields; track field) {
              @if (field.label && field.value) {
                <div class="block-grid__item margin-bottom-0">
                  <div>
                    <p class="font-size-small margin-bottom-0 color-black-60">{{ field.label }}</p>
                    <p>{{ field.dateType ? (field.value | datePipeWrapper: datePattern) : field.value }}</p>
                  </div>
                </div>
              }
            }
          </div>
        </div>
      </div>
    }
    @if (contractDetailList.length > 0 && !p58Contracts) {
      <div class="row">
        <div class="col-12">
          <div class="block-grid block-grid-sm-2 block-grid-lg-3 border-top-1 padding-top-1">
            @for (item of contractDetailList; track item) {
              <div class="block-grid__item margin-bottom-0">
                <div>
                  <p class="font-size-small margin-bottom-0 color-black-60">{{ item.label }}</p>
                  <p>{{ item.value }}</p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    }
    @if (contractDetailList.length > 0 && p58Contracts) {
      <div class="row">
        @if (viewLayout === 'inline') {
          <div class="col-12">
            @for (contractDetails of contractDetailList; track contractDetails) {
              <div>
                <dl class="accordion" data-cy="contracts-groups" hlCollapsable>
                  @for (group of contractDetails['groups']; track group; let i = $index) {
                    <dt [attr.data-cy]="'contract-group-'+i" [id]="i" aria-level="3" class="accordion__header">
                      <button [attr.aria-expanded]="true" class="accordion__title icon-arrow-down-large" type="button">
                        {{ group.groupTitle }}
                      </button>
                    </dt>
                    <dd [attr.aria-hidden]="false" [attr.data-cy]="'contracts-groups-'+i+'-content'" class="accordion__panel">
                      <div class="row">
                        <div [attr.data-cy]="'group-main-info'" [ngClass]="group.subInformationItems.length > 0 ? 'col-2': 'col-6'">
                          <ul class="bare-list info-list">
                            @for (main of group.mainInfoLabels; track main) {
                              <li>
                                <p class="list-item__title list-item__title--break-word">{{ main }}</p>
                              </li>
                            }
                          </ul>
                        </div>
                        <div [attr.data-cy]="'group-main-info-attribute'" [ngClass]="group.subInformationItems.length > 0 ? 'col-2': 'col-6'">
                          <ul class="bare-list info-list">
                            @for (attribute of group.attributeLabels; track attribute) {
                              <li>
                                <p class="list-item__title list-item__title--break-word">{{ attribute }}</p>
                              </li>
                            }
                          </ul>
                        </div>
                        <div class="col-8">
                          <div class="collection__content">
                            @for (subInformation of splitInformationArray(group.subInformationItems); track subInformation; let i = $index) {
                              <div class="row">
                                @for (sub of splitInformationArray(group.subInformationItems)[i]; track sub) {
                                  <div class="col-4">
                                    <ul class="bare-list info-list">
                                      <li attr.data-cy="contract-{{i+1}}-number">
                                        <p class="color-black-60 font-size-xsmall margin-bottom-0">{{ sub.label }}</p> @if (sub.label == '') {
                                        <br>
                                      }
                                        <p class="info-list__item list-item__title--break-word">{{ sub.value }}</p>
                                      </li>
                                    </ul>
                                  </div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </dd>
                  }
                </dl>
              </div>
            }
          </div>
        }
        @if (viewLayout === 'mobile') {
          <div class="col-12">
            @for (contractDetails of contractDetailList; track contractDetails) {
              <div>
                <dl class="accordion" data-cy="contracts-groups" hlCollapsable>
                  @for (group of contractDetails['groups']; track group; let i = $index) {
                    <dt [attr.data-cy]="'contract-group-'+i" [id]="i" aria-level="3" class="accordion__header">
                      <button [attr.aria-expanded]="true" class="accordion__title icon-arrow-down-large" type="button">
                        {{ group.groupTitle }}
                      </button>
                    </dt>
                    <dd [attr.aria-hidden]="false" [attr.data-cy]="'contracts-groups-'+i+'-content'" class="accordion__panel">
                      <div class="row">
                        @if (group.mainInfoLabels.length > 0) {
                          <div [attr.data-cy]="'group-main-info'" class="col-12">
                            <ul class="bare-list info-list">
                              @for (main of group.mainInfoLabels; track main) {
                                <li>
                                  <p class="list-item__title list-item__title--break-word">{{ main }}</p>
                                </li>
                              }
                            </ul>
                          </div>
                        }
                        @if (group.attributeLabels.length > 0) {
                          <div [attr.data-cy]="'group-main-info-attribute'" class="col-12">
                            <ul class="bare-list info-list">
                              @for (attribute of group.attributeLabels; track attribute) {
                                <li>
                                  <p class="list-item__title list-item__title--break-word">{{ attribute }}</p>
                                </li>
                              }
                            </ul>
                          </div>
                        }
                        @if (group.subInformationItems.length > 0) {
                          <div class="col-12">
                            <div class="collection__content">
                              @for (subInformation of splitInformationArray(group.subInformationItems); track subInformation; let i = $index) {
                                <div class="row">
                                  @for (sub of splitInformationArray(group.subInformationItems)[i]; track sub) {
                                    <div class="col-12">
                                      <ul class="bare-list info-list">
                                        <li attr.data-cy="contract-{{i+1}}-number">
                                          <p class="color-black-60 font-size-xsmall margin-bottom-0">{{ sub.label }}</p> @if (sub.label == '') {
                                          <br>
                                        }
                                          <p class="info-list__item list-item__title--break-all">{{ sub.value }}</p>
                                        </li>
                                      </ul>
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </dd>
                  }
                </dl>
              </div>
            }
          </div>
        }
        @if (viewLayout === 'wrapped' || viewLayout === 'tablet') {
          <div class="col-12">
            @for (contractDetails of contractDetailList; track contractDetails) {
              <div>
                <dl class="accordion" data-cy="contracts-groups" hlCollapsable>
                  @for (group of contractDetails['groups']; track group; let i = $index) {
                    <dt [attr.data-cy]="'contract-group-'+i" [id]="i" aria-level="3" class="accordion__header">
                      <button [attr.aria-expanded]="true" class="accordion__title icon-arrow-down-large" type="button">
                        {{ group.groupTitle }}
                      </button>
                    </dt>
                    <dd [attr.aria-hidden]="false" [attr.data-cy]="'contracts-groups-'+i+'-content'" class="accordion__panel">
                      <div class="row">
                        <div [attr.data-cy]="'group-main-info'" [ngClass]="setColumnSizeForMainSection(group)">
                          <ul class="bare-list info-list">
                            @for (main of group.mainInfoLabels; track main) {
                              <li>
                                <p class="list-item__title list-item__title--break-word">{{ main }}</p>
                              </li>
                            }
                          </ul>
                        </div>
                        <div [attr.data-cy]="'group-main-info-attribute'" [ngClass]="setColumnSizeForMainSection(group)">
                          <ul class="bare-list info-list">
                            @for (attribute of group.attributeLabels; track attribute) {
                              <li>
                                <p class="list-item__title list-item__title--break-word">{{ attribute }}</p>
                              </li>
                            }
                          </ul>
                        </div>
                        @if (group.subInformationItems.length > 0) {
                          <div [ngClass]="group.subInformationItems.length > 3 ? 'col-12':'col-6'">
                            <div class="collection__content">
                              @for (subInformation of splitInformationArray(group.subInformationItems); track subInformation; let i = $index) {
                                <div class="row">
                                  @for (sub of splitInformationArray(group.subInformationItems)[i]; track sub) {
                                    <div [ngClass]="this.maxItems == 3 ? group.subInformationItems.length < 3 ? 'col-6':'col-4':'col-6'">
                                      <ul class="bare-list info-list">
                                        <li attr.data-cy="contract-{{i+1}}-number">
                                          <p class="color-black-60 font-size-xsmall margin-bottom-0">{{ sub.label }}</p> @if (sub.label == '') {
                                          <br>
                                        }
                                          <p class="info-list__item list-item__title--break-all">{{ sub.value }}</p>
                                        </li>
                                      </ul>
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </dd>

                  }
                </dl>
              </div>
            }
          </div>
        }
      </div>
    }
  </section>
}
