<div class="countdown-timer">
  <i
    hlTooltip
    class="icon-time margin-right-tiny-2"
    [title]="iconTooltipText() | translate"
    [isActive]="iconTooltipText() !== null"
    [documentOverlay]="true"
  ></i>
  <span>{{formattedRemainingTime()}}</span> {{'LABEL_DURATION_MINUTES' | translate}}
</div>
