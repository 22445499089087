<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title"
          [translate]="isEditAdvisoryNotification ? 'LABEL_SECURITY_ADVISORY_NOTIFICATION_EDIT':'LABEL_SECURITY_ADVISORY_NOTIFICATION_CREATE'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close"
          (click)="cancelCreateAdvisoryNotifModal.show()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          <form [formGroup]="createAdvisoryNotificationForm">
            <hl-textfield label="GENERIC_LABEL_HEADLINE" name="advisoryTitle" [formControl]="headlineControl" [maxlength]="400"
              [isRequired]="headlineControl.errors && headlineControl.errors['required']"
              [isInvalid]="(headlineControl.touched || isFormSubmitted) && !headlineControl.valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
              ngDefaultControl></hl-textfield>
            <hl-textarea [parentForm]="createAdvisoryNotificationForm" [name]="'description'" [maxlength]="2500" [labelText]="'GENERIC_LABEL_DETAILS'"
              [isInvalid]="(descriptionControl.touched || isFormSubmitted) && !descriptionControl.valid"
              [isRequired]="descriptionControl.errors && descriptionControl.errors['required']" [isLarge]="true"></hl-textarea>
            @if ((descriptionControl.touched || isFormSubmitted) && !descriptionControl.valid) {
              <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
            }
            <div [ngClass]="{'invalid': isFormSubmitted && !lastUpdatedControl.valid}">
              <hl-date-picker id="lastUpdated" formControlName="lastUpdated"
                [isRequired]="lastUpdatedControl.errors && lastUpdatedControl.errors['required']" ngDefaultControl [labelText]="'LAST_UPDATED'"
                required
                [isInvalid]="(lastUpdatedControl.touched || isFormSubmitted) && (lastUpdatedControl.errors && lastUpdatedControl.errors['required'])"
                [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"></hl-date-picker>
              @if ((lastUpdatedControl.touched || isFormSubmitted) && (lastUpdatedControl.errors && lastUpdatedControl.errors['wrongDate'])) {
                <small class="textfield__hint textfield__hint--validation" [translate]="'LABEL_DATE_MUST_BE_TODAY_OR_EARLIER'"></small>
              }
            </div>
            <div [ngClass]="{'invalid': (isFormSubmitted || activationDateControl.touched) && !activationDateControl.valid}">
              <hl-date-picker formControlName="activationDate" [isRequired]="activationDateControl.errors && activationDateControl.errors['required']"
                ngDefaultControl [labelText]="'SECURITY_ADVISORY_ACTIVATION_DATE'" required
                [isInvalid]="(activationDateControl.touched || isFormSubmitted) && !activationDateControl.valid"
                [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"></hl-date-picker>
            </div>
          </form>
        }
      </div>
      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button" (click)="cancelCreateAdvisoryNotifModal.show()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
          <button class="button button--primary" (click)="ok()" [disabled]="isSaveDisabled$ | async"
            [translate]="isEditAdvisoryNotification ? 'GENERIC_LABEL_SAVE':'BUTTON_SECURITY_ADVISORY_NOTIFICATION_CREATE'"></button>
        </div>
      }
    </div>
  </div>

  <hl-cancel-modal (yesClick)="resetAndHide()"
    [descriptionMessage]="isEditAdvisoryNotification? 'ADVISORY_NOTIFICATION_EDIT_CONFIRM_CANCELLATION':'ADVISORY_NOTIFICATION_CREATE_CONFIRM_CANCELLATION'"
    #cancelCreateAdvisoryNotifModal></hl-cancel-modal>
</ng-template>
