import { inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { AppointmentsFilterStatus } from '../../../activities-common/appointments-constants';
import { DotRestService } from './dot-rest.service';
import { Observable, of, Subject } from 'rxjs';
import { DotActivityData, DotBookableStatus } from '../models/dot-bookable-status';
import moment from 'moment';
import { ActivitiesUtilService } from '../../../core/services/activities/activities-util.service';
import { ActivitiesCacheService } from '../../../core/services/cache/activities-cache.service';
import { ModalityUtilService } from '../../../core/utils/modality-util.service';
import { DotAppointmentIdentifier } from '../models/dot-appointment-identifier';
import { DateRange } from '../../../core/models/date-range';
import { DotTimeSlot } from '../models/dot-appointment-slots';
import { DotDateService } from './dot-date.service';
import { DotSummary } from '../models/dot-summary';
import { cloneDeep } from 'lodash-es';
import { DotBookAppointmentRequest, DotBookAppointmentResultStatus } from 'app/appointments/dot/models/dot-book-appointment';

@Injectable({
  providedIn: 'root'
})
export class DotService {

  private dotDateService = inject(DotDateService);
  private dotRestService = inject(DotRestService);
  private activitiesUtilService = inject(ActivitiesUtilService);
  private activitiesCacheService = inject(ActivitiesCacheService);
  private modalityUtil = inject(ModalityUtilService);

  private openLegacyRescheduleModalSubject = new Subject<boolean>();
  openLegacyRescheduleModalEvent$ = this.openLegacyRescheduleModalSubject.asObservable();

  isDotEnabledForActivity(config: Record<string, string>, activity: ActivitiesViewModel): boolean {
    return config['FEATURE_TOGGLE_DOT'] === 'true' &&
      this.isModalityEnabled(activity.modality, config['DOT_ALLOWED_MODALITIES']) &&
      this.isTypeEnabled(activity.type, config['DOT_ALLOWED_ACTIVITY_TYPES'])
  }

  isModalityEnabled(modality: string, allowedModalities: string): boolean {
    return allowedModalities?.split(',').includes(modality);
  }

  isTypeEnabled(type: string, allowedTypes: string): boolean {
    return allowedTypes?.split(',').includes(type);
  }

  getAllActivities() {
    return this.activitiesUtilService.getActivitiesViewModelList(this.activitiesCacheService.getSelectedOpenClosedStatus())
      .pipe(this.modalityUtil.translateModality(), map(activities => this.activitiesUtilService.getOpenAppointmentsActivities(activities)));
  }

  getDotAvailableActivities(activitiesStatus: DotActivityData[], activities: ActivitiesViewModel[]): ActivitiesViewModel[] {
    return activities.filter(activity =>
      activitiesStatus.some(statusActivity => this.isDotActivityDataForAppointment(statusActivity, activity))
    ).map(activity => {
      const dotActivity = activitiesStatus.find(statusActivity =>
        this.isDotActivityDataForAppointment(statusActivity, activity)
      );
      return {...activity, duration: dotActivity.duration};
    });
  }

  isDotActivityDataForAppointment(activityData: DotActivityData, appointment: ActivitiesViewModel): boolean {
    return activityData.notificationNumber === appointment.ticketNumber &&
      activityData.taskNumber === +this.getAppointmentIdFromAppointmentNumber(appointment.appointmentNumber);
  }

  getAppointmentStatus(appointment: ActivitiesViewModel): Observable<DotBookableStatus> {
    const appointmentId = this.getAppointmentIdFromAppointmentNumber(appointment.appointmentNumber);
    return this.dotRestService.getAppointmentStatus(appointment.ticketNumber, appointmentId).pipe(
      map(status => this.mapToStatusWithStatusMessage(appointment, status))
    );
  }

  getEffectiveBookableStatus(status: DotBookableStatus, appointments: ActivitiesViewModel[], dayOffsetForMsTickets: number): DotBookableStatus {
    if (!status.isBookable) {
      return status;
    }

    status = cloneDeep(status);
    status.activitiesStatus?.forEach(data => {
      const foundAppointment = appointments.find(appointment => this.isDotActivityDataForAppointment(data, appointment));
      if (foundAppointment?.type === 'MS') {
        const dueDateWithOffset = this.dotDateService.addDaysToDate(new Date(data.dueDate), dayOffsetForMsTickets);
        data.dueDate = this.dotDateService.formatDateInLocalTimeZoneWithoutTimeZoneInformation(dueDateWithOffset);
      }
    });

    return status;
  }

  getTimeSlots(identifier: DotAppointmentIdentifier | {
    megaTaskId: string
  }, range: DateRange): Observable<DotTimeSlot[]> {
    const start = this.dotDateService.formatDateInLocalTimeZoneWithoutTimeZoneInformation(range.fromDate);
    const end = this.dotDateService.formatDateInLocalTimeZoneWithoutTimeZoneInformation(range.toDate);

    const slots$ = 'megaTaskId' in identifier
      ? this.dotRestService.getAppointmentSlotsForMegaTask(start, end, identifier.megaTaskId)
      : this.dotRestService.getAppointmentSlotsForNotification(start, end, identifier.notificationNumber, identifier.taskNumber);

    return slots$.pipe(map(wrappedSlots => wrappedSlots.timeslots));
  }

  isAvailableFor(appointment: ActivitiesViewModel): boolean {
    return appointment.filterStatus && appointment.filterStatus !== AppointmentsFilterStatus.CLOSED;
  }

  isRescheduleFor(appointment: ActivitiesViewModel): boolean {
    return appointment.filterStatus === AppointmentsFilterStatus.SCHEDULED;
  }

  getAppointmentIdFromAppointmentNumber(appointmentNumber: string): number {
    return parseInt(appointmentNumber.slice(appointmentNumber.lastIndexOf('-') + 1));
  }

  emitOpenLegacyModal() {
    this.openLegacyRescheduleModalSubject.next(true);
  }

  generateSummary(appointments: ActivitiesViewModel[], status: DotBookableStatus, timeSlot: DotTimeSlot): DotSummary {
    const [STATUS_NOT_SCHEDULED, STATUS_SCHEDULED, STATUS_WAITING_FOR_CONFIRMATION] = ['1', '2', '10'];
    const [COLOR_SCHEDULED, COLOR_WAITING_FOR_CONFIRMATION] = ['2', '1'];

    const findDotDataFor = (appointment: ActivitiesViewModel): DotActivityData => {
      return status.activitiesStatus?.find(data => this.isDotActivityDataForAppointment(data, appointment)) ?? null;
    };

    const fullDuration = status.activitiesStatus?.reduce((acc, {duration}) => acc + duration, 0);
    const generalInformation: ActivitiesViewModel = {...appointments[0], duration: fullDuration};

    const oldAppointments: ActivitiesViewModel[] = appointments.map(appointment =>
      appointment.tpFstatus === STATUS_NOT_SCHEDULED ? {
        ...appointment,
        plannedStart: null,
        plannedEnd: null,
        scheduled: false,
        dueDate: findDotDataFor(appointment)?.dueDate
      } : appointment);

    const [tpFstatus, filterStatus, colorStatus] = status.isReviewNeeded
      ? [STATUS_WAITING_FOR_CONFIRMATION, AppointmentsFilterStatus.WAITING_FOR_CONFIRMATION, COLOR_WAITING_FOR_CONFIRMATION]
      : [STATUS_SCHEDULED, AppointmentsFilterStatus.SCHEDULED, COLOR_SCHEDULED];

    const newAppointments: ActivitiesViewModel[] = appointments.map(appointment => ({
      ...appointment,
      plannedStart: timeSlot.start,
      plannedEnd: timeSlot.finish,
      scheduled: true,
      tpFstatus,
      filterStatus,
      colorStatus
    }));

    return {oldAppointments, newAppointments, generalInformation};
  }

  bookAppointment(
    appointmentToBeBooked: DotBookAppointmentRequest,
    needsReview: boolean
  ): Observable<DotBookAppointmentResultStatus> {
    return this.dotRestService.bookAppointment(appointmentToBeBooked).pipe(
      map(() => needsReview ? 'waiting-for-confirmation' as const : 'success' as const),
      catchError(() => of('failure' as const))
    );
  }

  private mapToStatusWithStatusMessage(appointment: ActivitiesViewModel, status: DotBookableStatus): DotBookableStatus {
    if (status.isBookable) {
      return {...status, message: null};
    }

    if (appointment.filterStatus === AppointmentsFilterStatus.WAITING_FOR_CONFIRMATION) {
      return {...status, message: 'DOT_APPOINTMENT_NOT_BOOKABLE_WAITING_FOR_CONFIRMATION'};
    }

    const plannedStart = moment(appointment.plannedStartDate);
    const today = moment(0, 'HH');
    const tomorrow = today.clone().add(1, 'd');
    const isPlannedStartTodayOrTomorrow = plannedStart.isSame(today) || plannedStart.isSame(tomorrow);

    return {
      ...status,
      message: isPlannedStartTodayOrTomorrow
        ? 'DOT_APPOINTMENT_NOT_BOOKABLE_ON_SHORT_NOTICE'
        : 'DOT_APPOINTMENT_NOT_BOOKABLE'
    };
  }
}
