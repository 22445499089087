<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" data-cy="modal-edit-ownId-dialog" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'EDIT'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" data-cy="modal-edit-ownId-dialog-edit" id="modal1_desc">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          <form #modalForm="ngForm" novalidate>
            @if (component) {
              <hl-textfield label="COMPONENT_OWN_ID" name="myEquipmentName" [(ngModel)]="component.componentOwnID" ngDefaultControl></hl-textfield>
            }
          </form>
        }
      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="''" [modalRef]="simulatorModal"
          [isFormValidationRequired]="false" [isModalType]="true" [label]="'YES'" name="isFormSubmitted" dataCyCancelButton="edit-cancel-ownid"
          dataCySaveButton="save-edit-ownid" ngDefaultControl></hl-simulator-check>
      }

    </div>
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
