import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from '../../../../shared/components/alert/alert.component';
import { ActivitiesViewModel } from '../../../../core/view-models/activities-view-model';
import { ActivityPropertyKey, ActivityPropertyListComponent } from '../../../../activities-common/activity-property-list/activity-property-list.component';
import { ActivityListComponent } from '../../../../activities-common/activity-list/activity-list.component';
import { DotTimeSlot } from '../../models/dot-appointment-slots';
import { DotBookableStatus } from '../../models/dot-bookable-status';
import { DotService } from '../../services/dot.service';

@Component({
  selector: 'hl-dot-schedule-appointment-booking',
  templateUrl: './dot-schedule-appointment-booking.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    AlertComponent,
    ActivityPropertyListComponent,
    ActivityListComponent
  ]
})
export class DotScheduleAppointmentBookingComponent {
  readonly PROPERTIES_GENERAL_INFORMATION: ActivityPropertyKey[] = ['productName', 'siemensId', 'myEquipmentName', 'customerName', 'duration'];
  readonly PROPERTIES_DETAILS: ActivityPropertyKey[] = ['appointmentNumber', 'plannedStart', 'plannedEnd', 'dueDate', 'completedDate', 'status'];

  affectedActivities = input.required<ActivitiesViewModel[]>();
  status = input.required<DotBookableStatus>();
  timeSlot = input.required<DotTimeSlot>();
  config = input.required<Record<string, string>>();

  private dotService = inject(DotService);
  summary = computed(() => this.dotService.generateSummary(this.affectedActivities(), this.status(), this.timeSlot()));
}
