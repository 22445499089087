<div class="slider-container">
  @for (item of data; track item; let i = $index) {
    <div class="slide" [ngClass]="{'active': i===currentIndex}">
      <img [src]="item.imageUrl">
    </div>
  }
  <div class="slide-badges">
    @for (item of data; track item; let i = $index) {
      <div class="badge" [ngClass]="{'active': i===currentIndex}"></div>
    }
  </div>
  @for (item of data; track item; let i = $index) {
    <div class="slide-text" [ngClass]="{'active': i===currentIndex}">
      <p>{{ item.text }}</p>
    </div>
  }
</div>
