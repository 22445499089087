import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addEquipmentLinkParams'
})
export class AddEquipmentLinkParamsPipe implements PipeTransform {

  transform(value: string, queryParams: {equipmentIdentifier?: any, tab?: any, search?: any}): string {
    queryParams.tab = queryParams.tab ? queryParams.tab : 'ticketHistory';
    const searchParams = new URLSearchParams(queryParams);
    return `${value}?${searchParams.toString()}`;
  }
}
