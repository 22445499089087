<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="ticket-close-modal">

      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title">
          <span [translate]="'CLOSE_TICKET'"></span>
          <span>&nbsp;&ndash;&nbsp;{{ ticketItem.ticketNumber }}</span>
        </h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>

      <div class="modal-dialog__body" id="modal1_desc">

        @if (showValidationMessage) {
          <p class="message message-error">
            <span>{{ translationErrorMessage | translate }}</span>
          </p>
        }

        <hl-progress-indicator [loading]="showSpinner" [showLabel]="true" [label]="ticketLabelInProgress | translate"></hl-progress-indicator>

        @if (!showSpinner) {
          <form [formGroup]="closeTicketForm" class="form close-ticket-form">
            <fieldset class="row">
              <div class="col">
                <hl-textarea [parentForm]="closeTicketForm" [name]="'longText'" [labelText]="'CLOSE_TICKET_CONFIRM'"
                  [placeholderText]="'DEFAULT_TEXT_CLOSE_DESCRIPTION'" [isInvalid]="isFormSubmitted && !closeTicketForm.controls.longText.valid"
                  [isLarge]="true"></hl-textarea>
                @if (isFormSubmitted && !closeTicketForm.controls.longText.valid) {
                  <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
                }
              </div>
            </fieldset>
          </form>
        }

      </div>

      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="''"
          [isFormValid]="closeTicketForm.valid" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
          [label]="'SEND_LABEL'" name="isFormSubmitted"></hl-simulator-check>
      }
    </div>
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
