<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" data-cy="edit-customer-modal" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" translate="title"
          [translate]="showGeoLocation? 'EDIT_CUSTOMER_INFORMATION_TITLE':'EDIT_CUSTOMER_NAME_TITLE'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>

      <div class="modal-dialog__body padding-bottom-1">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          <form [formGroup]="form">
            <div class="row">
              <div class="col-12 col-lg-8">
                <h5 class="margin-top-0" translate="GENERIC_LABEL_CUSTOMER_NAME"></h5>
              </div>
            </div>
            @if (customerData.name1) {
              <div class="multiline-radio" [class.is-disabled]="type !== 'name1'">
                <input id="name-type-1" type="radio" formControlName="type" value="name1" class="radio" data-cy="customer-name1-radio-btn">
                <label for="name-type-1">
                  <div class="multiline-radio__small-label" translate="LABEL_NAME_1"></div>
                  <div class="multiline-radio__value-label" data-cy="customer-name1-value">{{ customerData.name1 }}</div>
                </label>
              </div>
            }
            @if (customerData.name2) {
              <div class="multiline-radio" [class.is-disabled]="type !== 'name2'">
                <input id="name-type-2" type="radio" formControlName="type" value="name2" class="radio">
                <label for="name-type-2">
                  <div class="multiline-radio__small-label" translate="LABEL_NAME_2"></div>
                  <div class="multiline-radio__value-label">{{ customerData.name2 }}</div>
                </label>
              </div>
            }
            @if (customerData.name2) {
              <div class="multiline-radio" [class.is-disabled]="type !== 'name12'">
                <input id="name-type-12" type="radio" formControlName="type" value="name12" class="radio">
                <label for="name-type-12">
                  <div class="multiline-radio__small-label" translate="LABEL_NAME_1_2"></div>
                  <div class="multiline-radio__value-label">{{ customerData.name12 }}</div>
                </label>
              </div>
            }
            <div class="multiline-radio multiline-radio--editor" [class.is-disabled]="type !== 'customName'">
              <input id="name-type-custom" type="radio" formControlName="type" value="customName" class="radio"
                data-cy="customer-custom-name-radio-btn">
              <label for="name-type-custom" (focusin)="setNameType('customName')">
                <hl-textfield label="LABEL_NAME_CUSTOM" formControlName="customName" dataCy="customer-custom-name-value"
                  [isInvalid]="isFormSubmitted && form.get('customName').invalid" [disableAutocomplete]="true"
                  [maxlength]="customNameLengthLimit"></hl-textfield>
              </label>
            </div>
            @if (showGeoLocation) {
              <div class="row">
                <div class="col-12 col-lg-8">
                  <h5 class="margin-top-0" translate="CUSTOMER_GEO_COORDINATES"></h5>
                </div>
              </div>
              <div class="row margin-left-tiny-10">
                <div class="col-12 padding-left-0">
                  <hl-textfield id="latitude" label="LABEL_LATITUDE" formControlName="latitude" dataCy="customer-latitude-value"
                    [isInvalid]="isFormSubmitted && form.get('latitude').invalid" [disableAutocomplete]="true" [min]="-90" [max]="90"></hl-textfield>
                </div>
              </div>
              <div class="row margin-left-tiny-10">
                <div class="col-12 padding-left-0">
                  <hl-textfield id="longitude" label="LABEL_LONGITUDE" formControlName="longitude" dataCy="customer-longitude-value"
                    [isInvalid]="isFormSubmitted && form.get('longitude').invalid" [disableAutocomplete]="true" [min]="-180"
                    [max]="80"></hl-textfield>
                </div>
                @if (isFormSubmitted && isLatitudeAndLongitudeInvalid) {
                  <small class="textfield__hint textfield__hint--validation" [translate]="'LABEL_WRONG_LATITUDE_AND_LONGITUDE'"></small>
                }
              </div>
            }
          </form>
        }
      </div>
      @if (form && !showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="save()" (onClickClose)="hide()" [modalRef]="simulatorModal"
          [isFormValidationRequired]="false" [isModalType]="true" label="GENERIC_LABEL_SAVE" name="isFormSubmitted" [isDisabled]="isSaveDisabled()"
          [isFormValid]="form.valid" ngDefaultControl dataCySaveButton="save-edit-customer"></hl-simulator-check>
      }
    </div>
  </div>

  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
