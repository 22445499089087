import { EventEmitter, Injectable } from '@angular/core';
import { WindowService } from '../../window.service';
import { SecurityAdvisoriesRestService } from '../../rest-services/security-advisories-rest.service';
import { from, Observable, Subject } from 'rxjs';
import { AdvisoryAttachment, AdvisoryAttachmentUploadRequest } from '../../models/securityAdvisories/advisory-attachment';
import { SecurityAdvisory } from '../../models/security-advisory';
import { BlobUploadCommonResponse, BlockBlobClient } from '@azure/storage-blob';
import { DocumentSourceRestService } from '../../rest-services/document-source-rest.service';
import { catchError, exhaustMap } from 'rxjs/operators';
import { ToasterService } from '../../component-communication-services/toaster/toaster.service';

@Injectable({providedIn: 'root'})
export class SecurityAdvisoriesService {

  advisoryUpdateSubject = new Subject<void>();

  showCreateAdvisoryModalEmitter = new EventEmitter<SecurityAdvisory>();
  showAdvisoryUploadFilesModalEmitter = new EventEmitter<SecurityAdvisory>();

  showAdminView = true;

  constructor(
    private windowService: WindowService,
    private securityAdvisoriesRestService: SecurityAdvisoriesRestService,
    private sourceRestService: DocumentSourceRestService,
    private toasterService: ToasterService) {
  }

  getAllSecurityAdvisories(): Observable<SecurityAdvisory[]> {
    return this.securityAdvisoriesRestService.getAllSecurityAdvisories();
  }

  getActiveSecurityAdvisories(): Observable<SecurityAdvisory[]> {
    return this.securityAdvisoriesRestService.getActiveSecurityAdvisories();
  }

  showCreateAdvisoryModal(advisory: SecurityAdvisory) {
    this.showCreateAdvisoryModalEmitter.emit(advisory);
  }

  showAdvisoryUploadFilesModal(advisory: SecurityAdvisory) {
    this.showAdvisoryUploadFilesModalEmitter.emit(advisory);
  }

  downloadDocument(id: number, documentType: string, languageCode: string): Observable<ArrayBuffer> {
    return this.securityAdvisoriesRestService.getDownloadUrl(id, documentType, languageCode)
      .pipe(
        catchError((err) => {
          this.toasterService.emitFrontendGenericErrorMessage();
          throw err;
        }),
        exhaustMap(({url}) => this.sourceRestService.downloadFromUrl(url)));
  }

  getAttachmentsList(id: number, languageCode: string): Observable<AdvisoryAttachment[]> {
    if (id) {
      return this.securityAdvisoriesRestService.getSecurityAdvisoryAttachments(id, languageCode);
    }
  }

  emitAdvisoryUpdated() {
    this.advisoryUpdateSubject.next();
  }

  isAdminView(): boolean {
    return this.showAdminView;
  }

  getUploadHeaders(body): Observable<AdvisoryAttachmentUploadRequest[]> {
    return this.securityAdvisoriesRestService.getUploadHeaders(body);
  }

  uploadAttachment(url: string, attachment: File): Observable<BlobUploadCommonResponse> {
      const client = new BlockBlobClient(url);
      return from(client.uploadData(attachment));
  }
}
