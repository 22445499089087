<div hlScrollTop class="collection-item list-item">
  <div class="collection__content">
    <div class="media-object">
      <div class="media-object__image list-item__image">
        <hl-equipment-image [equipment]="item" [large]="false"></hl-equipment-image>
      </div>
      <div class="media-object__body">
        <p class="list-item__title margin-bottom-0">{{ item.productName }}</p>

        <ul class="bare-list info-list">
          <li class="info-list__item label-status-{{statusColorMap[item.key | equipmentStatus | async]}}">{{ item.siemensId }}</li>
          @if (item.myEquipmentName) {
            <li class="info-list__item">
              <i class="icon-equipment-name" aria-hidden="true" hlTooltip [documentOverlay]="true"
                [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i> {{ item.myEquipmentName }}
            </li>
          }
          @if (item.department) {
            <li class="info-list__item">
              <i class="icon-department" aria-hidden="true" hlTooltip [documentOverlay]="true"
                [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i> {{ item.department }}
            </li>
          }
          <li class="info-list__item">
            <i class="icon-qr-bar-code" aria-hidden="true" hlTooltip [documentOverlay]="true"
              [title]="'LABEL_MATERIAL_NUMBER' | translate"></i> {{ item.materialNumber | removeLeadingZero }}
          </li>
          @if (item.city) {
            <li class="info-list__item">
              <i class="icon-location-pin" aria-hidden="true" hlTooltip [documentOverlay]="true"
                [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ item.city }}
            </li>
          }
          @if (item.customerName && toggleCustomerName) {
            <li class="info-list__item">
              <i class="icon-user" aria-hidden="true" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_CUSTOMER_NAME' | translate"></i>
              <span hlTooltip [title]="item.customerName" [documentOverlay]="true" [isActive]="item.customerName.length > this.customerNameLength">
              {{ item.customerName | truncate:[this.customerNameLength] }}</span>
            </li>
          }
          @if (item.secretKey) {
            <li class="info-list__item">
              <i class="icon-qr-bar-code" aria-hidden="true" hlTooltip [documentOverlay]="true"
                [title]="'GENERIC_LABEL_SECRET_KEY' | translate"></i> {{ item.secretKey }}
            </li>
          }
        </ul>
      </div>

      <button class="button button--control button--small margin-bottom-1" (click)="equipmentClicked(item)">
        <i [ngClass]="showPlusButton ? 'icon-plus' : 'icon-minus'" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
