<div [formGroup]="contactForm">

  <div class="row">
    <div class="col-12 display-flex" [class.col-lg-8]="!alwaysFullWidth">
      @if (labelContact) {
        <h5 class="margin-top-0 float-left" [class.width-100]="isMobile()" [translate]="labelContact"></h5>
      }
      @if (descriptionLabel) {
        <div class="float-left margin-left-tiny-3" hlTooltip [title]="descriptionLabel | translate" [documentOverlay]=true
          [showTooltipOnTouchScreen]="true">
          <button class="button button-no-pointer-change button--control button--small icon-information pointer-events-none"></button>
        </div>
      }
    </div>
  </div>

  @if (showAdditionalContacts) {
    <div class="row">
      <div class="col-12" [class.col-lg-8]="!alwaysFullWidth">
        <hl-selectbox [options]="ticketAdditionalContacts" [isDisabled]="isDisabled" label="GENERIC_LABEL_OTHER_CONTACTS"
          [(value)]="selectedContactInfo" (valueChange)="changeAdditionalContact()"></hl-selectbox>
      </div>
    </div>
  }

  <ng-content></ng-content>

  @if (ticketSalutations.length > 0) {
    <div class="row">
      <div class="col-12" [class.col-lg-8]="!alwaysFullWidth">
        <hl-selectbox [formControl]="contactForm.controls['contactSalutation']" [options]="ticketSalutations" [isDisabled]="isDisabled"
          label="GENERIC_LABEL_SALUTATION" ngDefaultControl></hl-selectbox>
      </div>
    </div>
  }

  @if (showContactTitles && ticketTitles.length > 0) {
    <div class="row">
      <div class="col-12" [class.col-lg-8]="!alwaysFullWidth">
        <hl-selectbox [formControl]="contactForm.controls['contactTitle']" [options]="ticketTitles" [isDisabled]="isDisabled"
          label="GENERIC_LABEL_TITLE" ngDefaultControl></hl-selectbox>
      </div>
    </div>
  }

  <div class="row">
    <div class="col-12" [class.col-lg-8]="!alwaysFullWidth">
      <hl-textfield label="GENERIC_LABEL_FIRSTNAME" name="firstName{{id}}"
        [isRequired]="contactForm.controls['contactFirstName'].errors && contactForm.controls['contactFirstName'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !contactForm.controls['contactFirstName'].valid"
        [isDisabled]="isDisabled" [formControl]="contactForm.controls['contactFirstName']" [maxlength]="35" ngDefaultControl
        [dataCy]="'first-name-' + dataCy"></hl-textfield>
    </div>
  </div>

  <div class="row">
    <div class="col-12" [class.col-lg-8]="!alwaysFullWidth">
      <hl-textfield label="GENERIC_LABEL_LASTNAME" name="lastName{{id}}"
        [isRequired]="contactForm.controls['contactLastName'].errors && contactForm.controls['contactLastName'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !contactForm.controls['contactLastName'].valid"
        [isDisabled]="isDisabled" [formControl]="contactForm.controls['contactLastName']" [maxlength]="35" ngDefaultControl
        [dataCy]="'last-name-' + dataCy"></hl-textfield>
    </div>
  </div>

  <div class="row">
    <div class="col-12" [class.col-lg-8]="!alwaysFullWidth">
      <hl-textfield label="GENERIC_LABEL_EMAIL" name="email{{id}}"
        [isRequired]="contactForm.controls['contactEmail'].errors && contactForm.controls['contactEmail'].errors['required']"
        invalidLabel="GENERIC_LABEL_NOT_VALID_EMAIL" [isInvalid]="isFormSubmitted && !contactForm.controls['contactEmail'].valid"
        [isDisabled]="isDisabled" [formControl]="contactForm.controls['contactEmail']" [maxlength]="64" ngDefaultControl
        [dataCy]="'email-' + dataCy"></hl-textfield>
    </div>
  </div>

  <div class="row">
    <div [ngClass]="showPhoneNumberExtension ? 'col-7' : 'col-12'" [class.col-lg-8]="!alwaysFullWidth && !showPhoneNumberExtension"
      [class.col-lg-5]="!alwaysFullWidth && showPhoneNumberExtension">
      <hl-textfield label="GENERIC_LABEL_PHONE" name="phone{{id}}"
        [isRequired]="contactForm.controls['contactPhone'].errors && contactForm.controls['contactPhone'].errors['required']"
        invalidLabel="GENERIC_LABEL_NOT_VALID_PHONE" [isInvalid]="isFormSubmitted && !contactForm.controls['contactPhone'].valid"
        [isDisabled]="isDisabled" [formControl]="contactForm.controls['contactPhone']" [maxlength]="24" ngDefaultControl
        [dataCy]="'phone-' + dataCy"></hl-textfield>
    </div>
    @if (showPhoneNumberExtension) {
      <div class="col-5" [class.col-lg-3]="!alwaysFullWidth">
        <hl-textfield label="GENERIC_LABEL_EXTENSION" name="phone-ext{{id}}" invalidLabel="GENERIC_LABEL_NOT_VALID_PHONE"
          [isInvalid]="isFormSubmitted && !contactForm.controls['contactPhoneExt'].valid" [isDisabled]="isDisabled"
          [formControl]="contactForm.controls['contactPhoneExt']" [maxlength]="8" ngDefaultControl [dataCy]="'phone-ext-' + dataCy"></hl-textfield>
      </div>
    }
  </div>
</div>
