import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Invoice } from '../models/invoices/invoices';
import { InvoiceGroup, InvoiceTypes, restEndPoint } from '../core-constants.service';
import { WebHttpUrlEncodingCodecService } from '../utils/web-http-url-encoding-codec.service';
import { HttpParams } from '@angular/common/http';
import { PreliminaryInvoices } from '../models/invoices/preliminaryInvoice';
import { InvoiceViewModel } from '../view-models/invoice-view-model';
import { map } from 'rxjs/operators';
import { PreliminaryInvoicePoNumber } from '../models/invoices/preliminaryInvoicePoNumber';

export const invoicesRestName = restEndPoint + 'invoices';
export const equipmentInvoicesRestName = restEndPoint + 'invoices/equipment';
export const preliminaryInvoicesRestName = restEndPoint + 'preliminary-invoices';

@Injectable({providedIn: 'root'})
export class InvoicesRestService {
  preliminaryInvoicesSubmitPoNumberRestName = preliminaryInvoicesRestName + '/submitPoNumber';

  constructor(private http: CacheableHttpClient) {
  }

  /**
   * @description retrieve invoices from BE (GET: /rest/v1/invoices)
   *
   * @returns {Observable<Invoice[]>}
   */
  getInvoices(selectedInvoiceGroup: InvoiceGroup, selectedInvoice: InvoiceTypes, cache = true): Observable<Invoice[]> {
    if (selectedInvoiceGroup === InvoiceGroup.PRELIM) {
      return this.getPreliminaryInvoices(cache);
    }
    const httpParams = new HttpParams({encoder: new WebHttpUrlEncodingCodecService()})
      .set('invoiceType', selectedInvoice);

    return this.http.get<Invoice[]>(invoicesRestName, {params: httpParams});
  }

  getEquipmentInvoices(equipmentKey: string) {
    const httpParams = new HttpParams({encoder: new WebHttpUrlEncodingCodecService()})
      .set('equipmentKey', equipmentKey);
    return this.http.get<Invoice[]>(equipmentInvoicesRestName, {params: httpParams});
  }

  getPreliminaryInvoices(cache = true): Observable<InvoiceViewModel[]> {
    if (!cache) {
      this.http.clearCache(preliminaryInvoicesRestName);
    }

    return this.http.get<PreliminaryInvoices>(preliminaryInvoicesRestName, {cache})
      .pipe(map((item: PreliminaryInvoices) => item.items));
  }

  getPreliminaryInvoiceAttachment(url: string): Observable<Blob> {
    this.http.clearCache(url);
    return this.http.get(url, {responseType: 'blob'});
  }

  submitPoNumber(body: PreliminaryInvoicePoNumber): Observable<Object> {
    return this.http.post(this.preliminaryInvoicesSubmitPoNumberRestName, body);
  }
}
