<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--form is-open" tabindex="-1" role="dialog" aria-modal="true">
    @if (activitiesItem) {
      <div class="modal-dialog__container">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title">
            {{ 'HEADLINE_SCHEDULE_ACTIVITY' | translate }}
            @if (activitiesItem) {
              <span>&nbsp;&ndash;&nbsp;{{ activitiesItem.ticketNumber }}</span>
            }
          </h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="modal1_desc">
          <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
          @if (!showSpinner) {
            <form [formGroup]="scheduleTrainingForm" class="form" novalidate>
              <div class="row">
                <div class="col-12">
                  <p class="margin-top-0" translate="LABEL_SCHEDULE_TRAINING_TITLE"></p>
                </div>
              </div>
              <hl-progress-indicator [loading]="loadingSelectedEquipment"></hl-progress-indicator>
              @if (!loadingSelectedEquipment && selectedEquipment) {
                <div class="collection margin-bottom-1">
                  <div class="collection-item list-item">
                    <div class="media-object width-100">
                      <div class="media-object__image list-item__image">
                        <hl-equipment-image [equipmentKey]="selectedEquipment.key" [large]="false"></hl-equipment-image>
                      </div>
                      <div class="media-object__body">
                        @if (selectedEquipment.productName) {
                          <p class="list-item__title margin-bottom-0">
                            {{ selectedEquipment.productName }}</p>
                        }
                        <ul class="bare-list info-list margin-bottom-0">
                          <li
                            class="info-list__item label-status-{{equipmentStatusColorMap[selectedEquipment.status]}}">{{ selectedEquipment.siemensId }}
                          </li>
                          @if (selectedEquipment.myEquipmentName) {
                            <li class="info-list__item">
                              <i aria-hidden="true" class="icon-equipment-name" hlTooltip
                                [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i> {{ selectedEquipment.myEquipmentName }}
                            </li>
                          }
                          @if (selectedEquipment.department) {
                            <li class="info-list__item">
                              <i aria-hidden="true" class="icon-department" hlTooltip [documentOverlay]="true"
                                [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i>
                              {{ selectedEquipment.department }}
                            </li>
                          }
                          @if (selectedEquipment.city) {
                            <li class="info-list__item">
                              <i aria-hidden="true" class="icon-location-pin" hlTooltip [documentOverlay]="true"
                                [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ selectedEquipment.city }}
                            </li>
                          }
                          <li class="info-list__item" *hlItemShowHideHandler="'config';config:'TOGGLE_CUSTOMER_NAME_EQUIPMENT_LIST'">
                            <i aria-hidden="true" class="icon-user" hlTooltip [documentOverlay]="true"
                              [title]="'GENERIC_LABEL_CUSTOMER_NAME' | translate"></i>
                            {{ selectedEquipment.customerName }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="select" [ngClass]="{'invalid': isFormSubmitted && !scheduleTrainingForm.controls['educationType'].valid}">
                    <hl-selectbox [formControl]="scheduleTrainingForm.controls['educationType']" [options]="educationTypes" name="selectbox"
                      [isRequired]="scheduleTrainingForm.controls['educationType'].errors && scheduleTrainingForm.controls['educationType'].errors['required']"
                      label="LABEL_EDUCATION_TYPE" ngDefaultControl></hl-selectbox>
                    @if (isFormSubmitted && !scheduleTrainingForm.controls['educationType'].valid) {
                      <small class="selectbox__hint selectbox__hint--validation" translate="GENERIC_LABEL_SELECT_FIELD"></small>
                    }
                  </div>
                </div>
              </div>
              <div class="row" hlHelperTextForms [selector]="'textarea__area'">
                <div class="col-12 col-lg-8">
                  <hl-textarea [parentForm]="scheduleTrainingForm" labelText="DETAILED_DESCRIPTION" name="detailedDescription"
                    [isInvalid]="isFormSubmitted && !scheduleTrainingForm.controls['detailedDescription'].valid"
                    [isRequired]="scheduleTrainingForm.controls['detailedDescription'].errors && scheduleTrainingForm.controls['detailedDescription'].errors['required']"
                    [isLarge]="true" [maxlength]=500></hl-textarea>
                  @if (isFormSubmitted && !scheduleTrainingForm.controls['detailedDescription'].valid) {
                    <small class="textfield__hint textfield__hint--validation" translate="GENERIC_LABEL_FILL_INFORMATION"></small>
                  }
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form__helper-container">
                    <p class="form__helper-text" [innerHtml]="'SCHEDULE_TRAININGS_DETAILED_DESCRIPTION' | translate"></p>
                  </div>
                </div>
              </div>
              <hl-contact-area [contactForm]="scheduleTrainingForm.controls['contact']" [isFormSubmitted]="isFormSubmitted"></hl-contact-area>
              <div class="row">
                <div class="col-12">
                  <p [innerHTML]="'SCHEDULE_TRAINING_DISCLAIMER_TEXT' | translate"></p>
                </div>
              </div>
            </form>
          }
        </div>
        @if (!showSpinner) {
          <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'btn btn-sm btn-highlight'"
            [isFormValid]="scheduleTrainingForm.valid" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
            [label]="'SEND_LABEL'" name="isFormSubmitted"></hl-simulator-check>
        }
      </div>
    }
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
