import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DotScheduleStatus } from 'app/appointments/dot/models/dot-schedule-status';
import { PipesModule } from 'app/core/pipes.module';

@Component({
  selector: 'hl-dot-schedule-result',
  templateUrl: './dot-schedule-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, PipesModule]
})
export class DotScheduleResultComponent {
  status = input.required<DotScheduleStatus>();
  retryButtonClicked = output();

  header = computed(() => this.mapToHeaderTranslationKey(this.status()));
  message = computed(() => this.mapToMessageTranslationKey(this.status()));
  iconClass = computed(() => this.mapToIconClass(this.status()));
  retryButtonText = computed(() => this.mapToRetryButtonText(this.status()));

  private mapToHeaderTranslationKey(status: DotScheduleStatus): string {
    switch (status) {
      case 'success': return 'DOT_SCHEDULE_RESULT_SUCCESS_HEADER';
      case 'failure': return 'DOT_SCHEDULE_RESULT_FAILURE_HEADER';
      case 'waiting-for-confirmation': return 'DOT_SCHEDULE_RESULT_WAITING_FOR_CONFIRMATION_HEADER';
      case 'timeout': return 'DOT_SCHEDULE_RESULT_TIMEOUT_HEADER';
      case 'not-bookable': return 'DOT_SCHEDULE_RESULT_NOT_BOOKABLE_HEADER';
    }
  }

  private mapToMessageTranslationKey(status: DotScheduleStatus): string {
    switch (status) {
      case 'success': return 'DOT_SCHEDULE_RESULT_SUCCESS_MESSAGE';
      case 'failure': return 'DOT_SCHEDULE_RESULT_FAILURE_MESSAGE';
      case 'waiting-for-confirmation': return 'DOT_SCHEDULE_RESULT_WAITING_FOR_CONFIRMATION_MESSAGE';
      case 'timeout': return 'DOT_SCHEDULE_RESULT_TIMEOUT_MESSAGE';
      case 'not-bookable': return 'DOT_SCHEDULE_RESULT_NOT_BOOKABLE_MESSAGE';
    }
  }

  private mapToIconClass(status: DotScheduleStatus): string {
    switch (status) {
      case 'success':
      case 'waiting-for-confirmation':
        return 'dispatch-of-tomorrow__icon-success';

      case 'failure':
      case 'not-bookable':
        return 'dispatch-of-tomorrow__icon-failure';

      case 'timeout':
        return 'dispatch-of-tomorrow__icon-timeout icon-time';
    }
  }

  private mapToRetryButtonText(status: DotScheduleStatus): string | null {
    switch (status) {
      case 'failure': return 'DOT_SCHEDULE_RESULT_FAILURE_RETRY';
      case 'timeout': return 'DOT_SCHEDULE_RESULT_TIMEOUT_RETRY';
      default: return null;
    }
  }
}
