<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <hl-common-modal-structure
    [title]="'DOT_SCHEDULE_MODAL_TITLE'"
    [wide]="true"
    [footerVisible]="modalStatus() === 'form'"
    [hideButtonVisible]="modalStatus() !== 'schedule' || scheduleResult() !== null"
    (hideButtonClicked)="close()"
  >
    @if (modalStatus() === 'form') {
      <hl-checkout-steps [steps]="STEPS" [currentIndex]="currentStepIndex()"></hl-checkout-steps>
      <div class="dispatch-of-tomorrow__header">
        <h4>{{currentStep() | dotStepHeader | translate}}</h4>

        @if (currentStep() !== 'timeframe-specification' && +config().DOT_TIMEOUT_IN_SECONDS > 0) {
          <hl-countdown-timer
            [durationInSeconds]="+config().DOT_TIMEOUT_IN_SECONDS"
            [iconTooltipText]="'DOT_SCHEDULE_TIMER_TOOLTIP'"
            (timerExpired)="timeout()"
          ></hl-countdown-timer>
        }
      </div>

      <form [formGroup]="scheduleForm">
        @switch (currentStep()) {
          @case ('timeframe-specification') {
            <hl-dot-schedule-timeframe-specification
              [dueDate]="currentAppointmentStatus().dueDate"
              [affectedActivities]="affectedActivities()"
              [formGroup]="scheduleForm.controls.timeframe"
              [config]="config()"
              [showErrors]="showErrors()"
            ></hl-dot-schedule-timeframe-specification>
          }

          @case ('appointment-selection') {
            <hl-dot-schedule-appointment-selection
              [identifier]="currentAppointmentStatus()"
              [megaTaskId]="effectiveBookableStatus().megaTaskId"
              [timeframe]="timeframeValue"
              [control]="scheduleForm.controls.timeSlot"
              [config]="config()"
              [showErrors]="showErrors()"
            ></hl-dot-schedule-appointment-selection>
          }

          @case ('contact-details') {
            <hl-dot-schedule-contact-details
              [formGroup]="scheduleForm.controls.contact"
              [showErrors]="showErrors()"
            ></hl-dot-schedule-contact-details>
          }

          @case ('appointment-booking') {
            <hl-dot-schedule-appointment-booking
              [affectedActivities]="affectedActivities()"
              [status]="effectiveBookableStatus()"
              [timeSlot]="scheduleForm.controls.timeSlot.value"
              [config]="config()"
            ></hl-dot-schedule-appointment-booking>
          }
        }
      </form>
    } @else if (modalStatus() === 'timeout') {
      <hl-checkout-steps [steps]="STEPS" [currentIndex]="currentStepIndex()" [disabled]="true"></hl-checkout-steps>
      <div class="dispatch-of-tomorrow__schedule-wrapper">
        <hl-dot-schedule-result [status]="'timeout'" (retryButtonClicked)="retry()"></hl-dot-schedule-result>
      </div>
    } @else {
      <div class="dispatch-of-tomorrow__schedule-wrapper">
        @if (modalStatus() === 'not-bookable') {
          <hl-dot-schedule-result [status]="'not-bookable'"></hl-dot-schedule-result>
        } @else if (scheduleResult() !== null) {
          <hl-dot-schedule-result [status]="scheduleResult()" (retryButtonClicked)="retry()"></hl-dot-schedule-result>
        } @else {
          <hl-progress-indicator></hl-progress-indicator>
        }
      </div>
    }

    <ng-container ngProjectAs="modal-footer">
      @if (this.currentStepIndex() === 0) {
        <button class="button" (click)="close()">{{'GENERIC_BUTTON_CANCEL' | translate}}</button>
      } @else {
        <button class="button" (click)="previousStep()">{{'BUTTON_BACK' | translate}}</button>
      }

      @if (this.currentStepIndex() === 3) {
        <button class="button button--primary" (click)="bookAppointment()">{{'GENERIC_LABEL_CONFIRM' | translate}}</button>
      } @else {
        <button class="button button--primary" (click)="nextStep()">{{'BUTTON_NEXT' | translate}}</button>
      }
    </ng-container>
  </hl-common-modal-structure>
</ng-template>
