@if (!newListView) {
  <div class="collection__content">
    <p class="list-item__title">
      @if (item.contractTypeDescription) {
        {{ item.contractTypeDescription }}:&nbsp;
      }
      {{ item.contractName }}</p>
    <div class="multi-column" [ngClass]="extendClass ? extendClass : 'multi-column-sm-2 multi-column-lg-3'">
      @if (item.contractNumber) {
        <div class="multi-column__item">
          <div class="display-flex align-items-center text-align-right">
            <span class="font-size-small color-black-60 flex-grow-1 text-align-left" [translate]="'CONTRACT_NUMBER'"></span>
            <span class="label-{{item | getContractStatusColor}}"> {{ item.contractNumber }}</span>
          </div>
        </div>
      }
      @if (item.productName) {
        <div class="multi-column__item">
          <div class="display-flex align-items-center text-align-right">
            <span class="font-size-small color-black-60 flex-grow-1 text-align-left" [translate]="'GENERIC_LABEL_PRODUCT_NAME'"></span>
            <span> {{ item.productName }}</span>
          </div>
        </div>
      }
      @if (item.siemensId) {
        <div class="multi-column__item">
          <div class="display-flex align-items-center text-align-right">
            <span class="font-size-small color-black-60 flex-grow-1 text-align-left" [translate]="'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID'"></span>
            <span> {{ item.siemensId }}</span>
          </div>
        </div>
      }
      @if (item.contractGroup) {
        <div class="multi-column__item">
          <div class="display-flex align-items-center text-align-right">
            <span class="font-size-small color-black-60 flex-grow-1 text-align-left" [translate]="'EQUIPMENT_CONTRACT_GROUP_NUMBER'"></span>
            <span> {{ item.contractGroup }}</span>
          </div>
        </div>
      }
      @if (item.startDate) {
        <div class="multi-column__item">
          <div class="display-flex align-items-center text-align-right">
            <span class="font-size-small color-black-60 flex-grow-1 text-align-left" [translate]="'LABEL_CONTRACT_START_DATE'"></span>
            <span> {{ item.startDate | datePipeWrapper: datePattern }}</span>
          </div>
        </div>
      }
      @if (item.expirationDate) {
        <div class="multi-column__item">
          <div class="display-flex align-items-center text-align-right">
            <span class="font-size-small color-black-60 flex-grow-1 text-align-left" [translate]="'CONTRACT_EXPIRATION_DATE'"></span>
            <span> {{ item.expirationDate | datePipeWrapper: datePattern }}</span>
          </div>
        </div>
      }
      @for (itemDetail of contractDetailList; track itemDetail) {
        <div class="multi-column__item">
          <div class="display-flex align-items-center text-align-right">
            <span class="font-size-small color-black-60 flex-grow-1 text-align-left">{{ itemDetail.label }}</span>
            <span>{{ itemDetail.value }}</span>
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="collection__content">
    <p attr.data-cy="equipment-contract-{{indexItem+1}}-title" class="list-item__title">
      @if (item.contractTypeDescription) {
        {{ item.contractTypeDescription }}:&nbsp;
      }
      {{ item.contractName }}
    </p>
    <ul class="bare-list info-list">
      @if (item.contractNumber || item.contractNumberConsolidated) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-number"
          class="info-list__item label-{{item | getContractStatusColor}}">{{ item.contractNumberConsolidated }}
        </li>
      }
      @if (item.productName) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-product-name" class="info-list__item">
          <i [title]="'GENERIC_LABEL_PRODUCT_NAME' | translate" aria-hidden="true" class="icon-label" hlTooltip [documentOverlay]="true"></i>
          {{ item.productName }}
        </li>
      }
      @if (item.siemensId) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-equipment-id" class="info-list__item">
          <i [title]="'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID' | translate" aria-hidden="true" class="icon-id-card" hlTooltip
            [documentOverlay]="true"></i>
          {{ item.siemensId }}
        </li>
      }
      @if (item.myEquipmentName) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-equipment-name" class="info-list__item"><i
          [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate" aria-hidden="true" class="icon-id-card" hlTooltip [documentOverlay]="true"></i>
          {{ item.myEquipmentName }}
        </li>
      }
      @if (item.startDate) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-start-date" class="info-list__item">
          <i [title]="'LABEL_CONTRACT_START_DATE' | translate" aria-hidden="true" class="icon-time" hlTooltip [documentOverlay]="true"></i>
          {{ item.startDate | datePipeWrapper: datePattern }}
        </li>
      }
      @if (item.expirationDate) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-expiration-date" class="info-list__item">
          <i [title]="'CONTRACT_EXPIRATION_DATE' | translate" aria-hidden="true" class="icon-time" hlTooltip [documentOverlay]="true"></i>
          {{ item.expirationDate | datePipeWrapper: datePattern }}
        </li>
      }
      @if (item.contractStatusDescription) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-status" class="info-list__item">
          <i [title]="'CONTRACT_STATUS' | translate" aria-hidden="true" class="icon-information" hlTooltip [documentOverlay]="true"></i>
          {{ item.contractStatusDescription }}
        </li>
      }
      @if (item.contractGroup) {
        <li attr.data-cy="equipment-contract-{{indexItem+1}}-group-number" class="info-list__item">
          <i [title]="'CONTRACT_GROUP_NUMBER' | translate" aria-hidden="true" class="icon-book" hlTooltip [documentOverlay]="true"></i>
          {{ item.contractGroup }}
        </li>
      }
    </ul>
  </div>
}

