import { Component, computed, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivitiesOverviewComponent } from '../activities-overview/activities-overview.component';
import { RescheduleAppointmentModalComponent } from '../../modal-popup/reschedule-appointment-modal/reschedule-appointment-modal.component';
import { DotService } from '../../../appointments/dot/services/dot.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DotRequestReschedulingModalComponent } from 'app/shared/modal-popup/dot-request-rescheduling-modal/dot-request-rescheduling-modal.component';

@Component({
  selector: 'hl-appointments-overview',
  templateUrl: '../activities-overview/activities-overview.component.html'
})
export class AppointmentsOverviewComponent extends ActivitiesOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(RescheduleAppointmentModalComponent) rescheduleAppointmentModal: RescheduleAppointmentModalComponent;
  @ViewChild(DotRequestReschedulingModalComponent) dotRequestReschedulingModal: DotRequestReschedulingModalComponent;
  private dotService = inject(DotService);
  private destroyRef = inject(DestroyRef);

  override isDotEnabled = computed(() => {
    const activity = this.viewModelActivity();
    const config = this.configSignal();

    return this.dotService.isDotEnabledForActivity(config, activity);
  });

  ngOnInit() {
    super.ngOnInit();
    this.dotService.openLegacyRescheduleModalEvent$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.schedule();
      });
  }

  getActivityViewModel(config) {
    this.updateActivity = null;
    this.activatedRoute.params.subscribe((params: any) => {
      const appointmentKey: string = params['appointmentKey'] || params['id'];
      const ticketKey = this.activitiesUtilService.getTicketKeyFromAppointmentKey(appointmentKey);
      if (this.activitiesUtilService.selectedActivity) {
        this.isLoaded = true;
        this.viewModelActivity.set(this.activitiesUtilService.selectedActivity);
        this.addressFields =
          this.lifeNetUtilService.getAddressFields(this.viewModelActivity, config.ADDRESS_FIELD_ORDER, false);
      } else {
        this.activitiesUtilService.getSingleActivity(ticketKey, this.activitiesCacheService.getSelectedOpenClosedStatus())
          .subscribe((activityVMResponse) => {
            this.isLoaded = true;
            if (activityVMResponse) {
              this.appointmentsOverview = this.activitiesUtilService.convertActivityToAppointments(activityVMResponse);
              this.viewModelActivity.set(this.appointmentsOverview.find(appointment => appointment.appointmentKey === appointmentKey));
              this.addressFields = this.lifeNetUtilService.getAddressFields(this.viewModelActivity, config.ADDRESS_FIELD_ORDER, false);
            }
          });
      }
      this.createUpdateActivityItem();
      this.loadSystemUpdatesRuhDetailsData();
    });
  }

  schedule() {
    this.requestRescheduleP58Enabled ? this.dotRequestReschedulingModal.openRequestReschedulingModal() :
      this.rescheduleAppointmentModal.openRescheduleModal();
  }
}
