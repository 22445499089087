import { Injectable, OnDestroy } from '@angular/core';
import { WindowService } from '../window.service';
import { NavigationEnd, Router } from '@angular/router';
import { concat, interval, merge, Observable, scan, Subject } from 'rxjs';
import { debounceTime, filter, startWith, take, takeUntil } from 'rxjs/operators';
import { SystemUpdateRuhBase } from '../models/systemUpdates/system-update-ruh-base';
import { Equipment } from '../models/equipment/equipment';
import { MedalliaRestService } from '../rest-services/medallia-rest.service';
import { TEST_BOT } from '../core-constants.service';
import { TicketViewModel } from '../view-models/ticket-view-model';
import { NumberFromIdPipe } from 'app/shared/pipes/number-from-id/number-from-id.pipe';

export enum medalliaSurveyAction {
  SUBMITTED = 'SUBMITTED',
  NOT_SUBMITTED = 'NOT_SUBMITTED'
}

type Metadata = {
  equipment?: Equipment,
  systemUpdate?: SystemUpdateRuhBase,
  ticket?: TicketViewModel,
  other?: Record<string, any>
};

const SUBMIT_FEEDBACK_EVENT = 'MDigital_Submit_Feedback';

const CLOSE_FEEDBACK_EVENT = 'MDigital_Form_Close_No_Submit';

@Injectable({providedIn: 'root'})
export class MedalliaUtilService implements OnDestroy {

  private readonly destroy$ = new Subject<void>();
  private readonly update$ = new Subject<void>();

  private readonly submittedKeysSource = new Subject<string>();

  get submittedKeys$() {
    return this.submittedKeysSource.asObservable().pipe(
      scan((set, key) => set.add(key), new Set<string>([])),
      startWith(new Set<string>([]))
    );
  }

  constructor(
    private windowService: WindowService,
    private router: Router,
    private medalliaRestService: MedalliaRestService,
    private numberFromIdPipe: NumberFromIdPipe
    ) {
    this.windowService.nativeWindow?.addEventListener?.(SUBMIT_FEEDBACK_EVENT, (mdEvent: any) => {
      const key = mdEvent?.detail?.Feedback_UUID;
      if (!key) {
        return;
      }
      this.submittedKeysSource.next(key);
    });
  }

  notifyMedallia() {
    const w = this.windowService.nativeWindow;
    if (!w || navigator.userAgent.includes(TEST_BOT)) {
      return;
    }

    const isMedalliaLoaded = () => w.KAMPYLE_ONSITE_SDK && typeof w.KAMPYLE_ONSITE_SDK.updatePageView === 'function';
    const isUserInfoLoaded = () => w.gid && w.country && w.language;

    const loaded$ = interval(1000).pipe(filter(isMedalliaLoaded), filter(isUserInfoLoaded), take(1));
    const navigated$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

    concat(loaded$, merge(navigated$, this.update$))
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        w.KAMPYLE_ONSITE_SDK.updatePageView();
      });
  }

  update() {
    this.update$.next();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setPopupsAllowed(allowed: boolean) {
    this.windowService.setPopupsAllowed(allowed);
    this.update();
  }

  showSurvey(formId: number, metadata: Metadata) {
    const w = this.windowService.nativeWindow;

    if (!w || navigator.userAgent.includes(TEST_BOT)) {
      return;
    }

    const status = w.KAMPYLE_ONSITE_SDK.loadForm(formId);
    if (status) {
      this.setFieldsForSurvey(metadata);
      this.registerSurveyListeners();
      setTimeout(() => {
        w.KAMPYLE_ONSITE_SDK.showForm(formId);
      }, 1000)
    }
  }

  private setFieldsForSurvey({equipment, systemUpdate, ticket, other}: Metadata) {
    const w = this.windowService.nativeWindow;
    w['modality_code'] = equipment?.modality;
    w['mobile_device'] = equipment?.mobile;
    w['product_name'] = equipment?.productName;
    w['equipment_number'] = this.numberFromIdPipe.transform(equipment?.key);
    w['material_number'] = equipment?.materialNumber;
    w['serial_number'] = equipment?.serialNumber;
    w['functional_location'] = equipment?.location;
    w['update_number'] = systemUpdate?.number;
    w['update_type'] = systemUpdate?.type;
    w['notification_id'] = ticket?.ticketKey;
    Object.entries(other ?? {}).forEach(([key, value]) => {
      w[key] = value;
    });
  }

  registerSurveyListeners() {
    this.windowService.nativeWindow.addEventListener(SUBMIT_FEEDBACK_EVENT, this.submitHandler);
    this.windowService.nativeWindow.addEventListener(CLOSE_FEEDBACK_EVENT, this.closeHandler);
  }

  submitHandler = () => {
    this.medalliaRestService.postMedalliaSurveyTracking(this.windowService.nativeWindow['update_number'], medalliaSurveyAction.SUBMITTED);
    this.windowService.nativeWindow.removeEventListener(SUBMIT_FEEDBACK_EVENT, this.submitHandler);
    this.windowService.nativeWindow.removeEventListener(CLOSE_FEEDBACK_EVENT, this.closeHandler);
  };

  closeHandler = () => {
    this.medalliaRestService.postMedalliaSurveyTracking(this.windowService.nativeWindow['update_number'], medalliaSurveyAction.NOT_SUBMITTED);
    this.windowService.nativeWindow.removeEventListener(SUBMIT_FEEDBACK_EVENT, this.submitHandler);
    this.windowService.nativeWindow.removeEventListener(CLOSE_FEEDBACK_EVENT, this.closeHandler);
  };

  isSurveyInQuarantine(): Observable<boolean> {
    return this.medalliaRestService.isSurveyInQuarantine();
  }
}
