<div
  class="alert"
  [class.is-open]="open()"
  [class.is-hidden]="!open()"
  [class.alert--success]="indicator() === 'success'"
  [class.alert--error]="indicator() === 'error'"
  [class.alert--warning]="indicator() === 'warning'"
  [class.alert--info]="indicator() === 'info'"
>
  <div class="row justify-content-between">
    <div class="col-8 col-sm-9 padding-right-0">
      <div class="align-items-start display-flex">
        @if (indicator()) {
          <div class="alert__indicator-container">
            <i class="icon alert__indicator"></i>
          </div>
        }
        <div class="display-block">
          @if (title()) {
            <div class="alert__header">
              <h5 class="alert__title">{{title()}}</h5>
            </div>
          }
          <div class="alert__body">
            <p class="alert__text">
              <ng-content></ng-content>
            </p>
          </div>
        </div>
      </div>
    </div>
    @if (hideButtonVisible()) {
      <div class="col-1 padding-left-0">
        <button type="button" class="alert__close" (click)="hide()">
          <i class="icon-close"></i>
        </button>
      </div>
    }
  </div>
</div>
