<div [formGroup]="requestForm">

  @if (showRequestArea) {
    <div class="row">
      <div class="col-12 col-lg-8">
        <label class="font-size-small color-black-60" [translate]="'TICKET_FEEDBACK_REQUESTED_VIA'"></label><br>
        <ul class="list list--inline bare-list">
          <li class="list__item">
            <input id="radioEmail" type="radio" class="radio radio--inline" formControlName="feedBack" value="email">
            <label for="radioEmail" [translate]="'TICKET_FEEDBACK_EMAIL'" (click)="selectFeedBack('email')"></label>
          </li>
          <li class="list__item">
            <input id="radioTelephone" type="radio" class="radio radio--inline" formControlName="feedBack" value="phone">
            <label for="radioTelephone" [translate]="'TICKET_FEEDBACK_TELEPHONE'" (click)="selectFeedBack('phone')"></label>
          </li>
          @if (otherFeedbackOption) {
            <li class="list__item">
              <input id="radioOther" type="radio" class="radio radio--inline" formControlName="feedBack" [value]="otherFeedbackValue">
              <label for="radioOther" (click)="selectOtherFeedBack(otherFeedbackValue, otherFeedbackOption)">{{ otherFeedbackOption }}</label>
            </li>
          }
        </ul>
      </div>
    </div>
  }

  @if (showTicketOwnIncidentNumber && showPOField) {
    <div class="row" hlHelperTextForms>
      <div class="col-12 col-lg-8">
        <hl-textfield label="TICKET_CREATE_FEEDBACK_OWNID" name="ownIncidentNumber" placeholder="GENERIC_LABEL_TICKET_INPUT_FIELD_OPTIONAL"
          [formControl]="requestForm.controls['ownIncidentNumber']" ngDefaultControl></hl-textfield>
      </div>
      <div class="col-12 col-lg-4">
        <div class="form__helper-container">
          <p class="form__helper-text" [innerHtml]="'TICKET_FEEDBACK_OWNID_EXPLANATION' | translate"></p>
        </div>
      </div>
    </div>
  }

</div>
