import { translationLanguagePrefix } from '../../../core/utils/impersonation-utils.service';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UploadComponent } from '../../form-group/upload/upload.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AdvisoryUploadFileValidator } from '../create-advisory-modal/advisory-upload-file.validator';
import { SecurityAdvisoriesService } from '../../../core/services/security-advisories/security-advisories-service';
import { SelectOption } from '../../../core/models/select-option';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { EnvironmentConfigRestService } from 'app/core/rest-services/environment-config-rest.service';
import { exhaustMap, finalize, map } from 'rxjs/operators';
import { Attachment } from '../../../core/models/attachment';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { AdvisoryAttachmentUploadRequest } from '../../../core/models/securityAdvisories/advisory-attachment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-advisory-upload-files-modal',
  templateUrl: './advisory-upload-files-modal.component.html'
})
export class AdvisoryUploadFilesModalComponent extends BaseModalPopup implements OnInit {

  @ViewChild('filePackageInfoForm')
  pdfUpload: UploadComponent;
  @ViewChild('filePackageInfoForm2')
  txtUpload: UploadComponent;

  FILE_PDF = 'pdfFiles';
  FILE_TXT = 'txtFiles';

  advisoryUploadFilesForm: UntypedFormGroup;
  isFormSubmitted = false;
  disablePDFButton: boolean;
  disableTXTButton: boolean;
  formChangesSubscription: Subscription;
  languageOptions$: Observable<SelectOption[]>;
  selectedAdvisoryId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toasterService: ToasterService,
    private securityAdvisoriesService: SecurityAdvisoriesService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.createForm();
    this.showSpinner = false;
    this.environmentConfigRestService.getEnvironmentConfig().subscribe(envConfigResponse => {
      const languageCodes = envConfigResponse.ADVISORY_UPLOAD_ALLOWED_LANGUAGES?.split(',') ?? [];
      const languageOptions = languageCodes.map(languageCode => {
        return {
          title: translationLanguagePrefix + languageCode,
          value: languageCode
        };
      });

      this.languageOptions$ = this.translate.get(languageOptions.map(language => language.title))
        .pipe(map(resp => languageOptions.map(language => ({
          ...language, title: resp[language.title] || language.title
        })).sort((a, b) => a.title.localeCompare(b.title))));
    });

    this.securityAdvisoriesService.showAdvisoryUploadFilesModalEmitter
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(advisory => {
        this.selectedAdvisoryId = advisory.id;
      });
  }

  private createForm() {
    this.advisoryUploadFilesForm = this.fb.group({
        language: [''],
        pdfFiles: [[], AdvisoryUploadFileValidator.pdfFileValidator],
        txtFiles: [[], AdvisoryUploadFileValidator.txtFileValidator]
    });
    this.disablePDFButton = true;
    this.disableTXTButton = true;
    this.onChanges();
  }

  onChanges() {
    this.formChangesSubscription = this.advisoryUploadFilesForm.valueChanges.subscribe(val => {
      if (val.language) {
        this.disablePDFButton = false;
        this.disableTXTButton = false;
        const addLanguage = (file: any, language: string) => {
          if (!file.languageCode) {
            file.languageCode = language;
          }
        };

        val.pdfFiles.forEach(file => addLanguage(file, val.language));
        val.txtFiles.forEach(file => addLanguage(file, val.language));
        this.disablePDFButton = val.pdfFiles.some(file => file.languageCode === val.language);
        this.disableTXTButton = val.txtFiles.some(file => file.languageCode === val.language);
      }
    });
  }

  ok() {
    this.isFormSubmitted = true;
    if (this.advisoryUploadFilesForm.valid) {
      this.showSpinner = true;
      this.securityAdvisoriesService.getUploadHeaders({id: this.selectedAdvisoryId, files: this.getAttachmentsInfo()})
        .pipe(
          exhaustMap((requests: AdvisoryAttachmentUploadRequest[]) => {
            const files = this.advisoryUploadFilesForm.get('pdfFiles').value.concat(this.advisoryUploadFilesForm.get('txtFiles').value);
            return forkJoin(requests.map(request => {
              const file = files.filter(f => f.filename === request.filename && f.languageCode === request.languageCode)[0].file;
              return this.securityAdvisoriesService.uploadAttachment(request.uploadRequest.url, file);
            }));
          }),
        finalize(() => {
          this.resetAndHide();
        })).subscribe(() => {
          this.toasterService.showTranslatedSuccessToaster('ADVISORY_ATTACHMENT_UPLOAD_SUCCESS');
          this.securityAdvisoriesService.emitAdvisoryUpdated();
      });
    }
  }

  private getAttachmentsInfo() {
    const fun = (files: Attachment []) => {
      return files.map(file => {
        return { filename: file.filename, languageCode: file.languageCode };
      });
    };

    const attachmentsInfo = fun(this.advisoryUploadFilesForm.get('pdfFiles').value);
    return attachmentsInfo.concat(fun(this.advisoryUploadFilesForm.get('txtFiles').value));
  }

  show() {
    super.show();
    this.onChanges();
  }

  resetAndHide() {
    this.isFormSubmitted = false;
    this.formChangesSubscription.unsubscribe();
    this.removeFiles();
    this.advisoryUploadFilesForm.reset({ language: '', pdfFiles: [], txtFiles: [] });
    this.advisoryUploadFilesForm.markAsUntouched();
    this.showSpinner = false;
    this.disablePDFButton = true;
    this.disableTXTButton = true;
    this.hide();
  }

  removeFiles() {
    if (this.pdfUpload) {
      this.pdfUpload.removeFiles();
    }
    if (this.txtUpload) {
      this.txtUpload.removeFiles();
    }
  }

  isSubmitFormDisabled() {
    return this.advisoryUploadFilesForm.invalid ||
      (!this.advisoryUploadFilesForm.get('pdfFiles').value.length && !this.advisoryUploadFilesForm.get('txtFiles').value.length);
  }
}
