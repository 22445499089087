import { Component, OnDestroy, OnInit } from '@angular/core';
import { includes, isEqual } from 'lodash-es';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import { EMPTY, merge, of, Subject } from 'rxjs';
import { VirtualAgentRestService } from '../../core/rest-services/virtual-agent-rest.service';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { virtualAgentHiddenUrls, virtualAgentLoginTimeout, virtualAgentLoginUrlFeatures } from './chat-bot-config';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sn-chat-bot',
  templateUrl: './chat-bot.component.html'
})
export class ChatBotComponent implements OnInit, OnDestroy {
  virtualAgentUrl: string;
  virtualAgentLoginUrl: string;
  virtualAgentLoginTimeout: number;
  hidden = true;
  open = false;
  authenticated = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private configService: CountryConfigRestService,
    private router: Router,
    private virtualAgentRestService: VirtualAgentRestService,
    private impersonationCommunicationService: ImpersonationCommunicationService) {
  }

  ngOnInit() {
    merge(
      of(''),
      this.impersonationCommunicationService.onCountryLanguageChange$
    ).pipe(
      switchMap(() => this.configService.getConfig()),
      switchMap(config => {
        return isEqual(config.FEATURE_TOGGLE_VIRTUAL_AGENT, 'true') ? this.virtualAgentRestService.getVirtualAgentInfo() : EMPTY;
      }),
      catchError(() => EMPTY),
      switchMap(result => {
        if (result.enabled && result.url && result.loginUrl) {
          this.virtualAgentUrl = result.url;
          this.virtualAgentLoginUrl = result.loginUrl;
          this.virtualAgentLoginTimeout = !!result.loginTimeout ? result.loginTimeout : virtualAgentLoginTimeout;
          this.enable(this.router.url);
          return this.router.events;
        } else {
          this.hidden = true;
        }
        return EMPTY;
      }),
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe((event: NavigationEnd) => this.enable(event.url));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  enable(currentUrl: string) {
    this.hidden = includes(virtualAgentHiddenUrls, currentUrl);
  }

  toggle() {
    this.open = !this.open;

    if (this.authenticated) {
      return;
    }

    const tab = window.open(this.virtualAgentLoginUrl, '_blank', virtualAgentLoginUrlFeatures);

    setTimeout(() => {
      this.authenticated = true;
      tab.close();
    }, this.virtualAgentLoginTimeout);
  }
}
