<div>
  <ul class="collection collection--compact">
    @for (favorite of favorites; track favorite; let i = $index) {
      <li class="collection-item align-items-center" [ngClass]="{'collection-item--my-institutions': isMyInstitutions}"
        [attr.data-cy]="'favorite-' + (i+1)">
        <div class="collection__content">
          <span class="filter-panel__saved-item">{{ favorite.name }}</span>
        </div>
        <div class="collection__additional-content">
          <div class="dropdown dropdown--right" [class.is-open]="selectedDropDown === favorite">
            <button class="button button--control margin-bottom-0 three-dots-icon-style" (click)="openDropDown(favorite)" hlDropdownOpener
              [selectedItem]="selectedDropDown" [itemRef]="favorite" [attr.data-cy]="'favorite-open-dropdown-' + (i+1)"
              (onDropDownClosed)="selectedDropDown = null"><i class="icon-dots-ellipsis-vertical"></i></button>
            <div class="menu dropdown__panel">
              <div class="menu__item" (click)="loadFavorite(favorite)" data-cy="apply-favorite" [translate]="'GENERIC_LABEL_APPLY_FILTER'"></div>
              <div class="menu__item" (click)="renameFavorite(favorite)" data-cy="rename-favorite" [translate]="'GENERIC_LABEL_RENAME_FILTER'"></div>
              <div class="menu__item" (click)="deleteFavorite(favorite)" [attr.data-cy]="'delete-favorite-' + (i+1)"
                [translate]="'GENERIC_LABEL_DELETE_FILTER'"></div>
            </div>
          </div>
        </div>
      </li>
    }
  </ul>
</div>
