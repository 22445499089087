import { customersImpersonateRestName } from './customer-groups-rest.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ImpersonatedCustomers } from '../models/impersonation/impersonated-customers';
import { ClearCacheUtilService } from '../utils/clear-cache-util.service';
import { tap } from 'rxjs/operators';
import { ImpersonationInput } from '../models/impersonation/impersonation-input';

@Injectable({providedIn: 'root'})
export class ImpersonationRestService {

  constructor(
    private http: CacheableHttpClient,
    private clearCacheUtilService: ClearCacheUtilService) {
  }

  getImpersonatedCustomers(): Observable<ImpersonatedCustomers> {
    return this.http.get<ImpersonatedCustomers>(customersImpersonateRestName);
  }

  postStartImpersonation(body: ImpersonationInput): Observable<any> {
    return this.http.post(customersImpersonateRestName, body).pipe(
      tap(() => this.clearCacheUtilService.clear())
    );
  }

  stopImpersonation(): Observable<any> {
    return this.http.delete(customersImpersonateRestName).pipe(
      tap(() => this.clearCacheUtilService.clear())
    );
  }

}
