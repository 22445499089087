// professional service request
import { restEndPoint } from '../core-constants.service';

export const psrRestName = restEndPoint + 'psr';
export const psrRwfRestName = restEndPoint + 'psr/rwf';
export const rwfAvailableTimeSlots = psrRwfRestName + '/get-available-slots';
export const rwfBookingRequestSlots = psrRwfRestName + '/get-booking-request-slots';
export const rwfCancelBookedSlot = psrRwfRestName + '/cancel-booking-request-slot';
export const rwfContactsRestName = psrRwfRestName + '/contacts';
export const rwfContactRestName = psrRwfRestName + '/contact';
export const psrRestTypes = restEndPoint + 'psr/types';
export const cancelActionRestName = restEndPoint + 'psr/cancel';
export const approveActionRestName = restEndPoint + 'psr/approve';
export const extendActionRestName = restEndPoint + 'psr/extend';
export const psrTemplateRestName = restEndPoint + 'psr/templates';
