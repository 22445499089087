import { Injectable } from '@angular/core';
import { roles } from '../core/core-constants.service';
import { isEqual } from 'lodash-es';
import { UserUtilService } from '../core/services/user/user-util.service';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SprWidget } from '../core/models/spr/configuration-spr';
import moment from 'moment';
import { chartDateFormat, graphConfigurationTypes } from './configuration/spr-configuration-constants';
import { SimplifiedVirtualKpi } from '../core/models/spr/basic-input-configuration/virtual-kpis/simplified-virtual-kpi';
import { sprGraphFunctions } from './overview/details/spr-graph-functions';

export enum KPI_GROUP_TYPE {
  DURATION, PERCENTAGE, QUANTITY, RATIO
}

@Injectable({providedIn: 'root'})
export class SprService {

  isAdminRoleLogin: boolean;
  sprBasicConfigurationToggle = false;
  sprRemoteActivityWidgetToggle = false;
  sprTubeguardWidgetToggle = false;
  sprGuardianWidgetToggle = false;
  sprHeliumLevelWidgetToggle = false;
  sprSparePartsWidgetToggle = false;
  sprCoilCareWidgetToggle = false;
  envDataWidgetToggle = false;
  setVisibilityForWidget: BehaviorSubject<SprWidget> = new BehaviorSubject<SprWidget>(null);

  isAdminMode = false;

  constructor(
    private userUtilService: UserUtilService,
    private configService: CountryConfigRestService
  ) {
  }

  static isVKpiInPercent(kpi: SimplifiedVirtualKpi): boolean {
    return sprGraphFunctions.isVKpiInPercent(kpi);
  }

  isAdminLogin(): Observable<boolean> {
    return combineLatest([this.userUtilService.checkUserRoles({
      countryAdminRole: roles.countryAdminRole,
      masterAdminRole: roles.masterAdminRole
    }), this.configService.getConfig()]).pipe(
      take(1),
      map(([userRoles, config]) =>
        userRoles && (userRoles.countryAdminRole || userRoles.masterAdminRole) && isEqual(config.TOGGLE_SPR_ADMIN_MODE, 'true')),
      tap(isAdminRoleLogin => this.isAdminRoleLogin = isAdminRoleLogin));
  }

  setConfigProperties(config: Record<string, string>) {
    this.sprBasicConfigurationToggle = isEqual(config.TOGGLE_SPR_WIDGET_CONFIGURATION, 'true');
    this.sprRemoteActivityWidgetToggle = isEqual(config.TOGGLE_SPR_REMOTE_ACTIVITY_WIDGET_ALLOWED, 'true');
    this.sprTubeguardWidgetToggle = isEqual(config.FEATURE_TOGGLE_TUBE_GUARD_WIDGET, 'true');
    this.sprGuardianWidgetToggle = isEqual(config.FEATURE_TOGGLE_GUARDIAN_WIDGET, 'true');
    this.sprHeliumLevelWidgetToggle = isEqual(config.FEATURE_TOGGLE_HELIUM_LEVEL_WIDGET, 'true');
    this.sprSparePartsWidgetToggle = isEqual(config.FEATURE_TOGGLE_SPARE_PARTS_WIDGET, 'true');
    this.sprCoilCareWidgetToggle = isEqual(config.FEATURE_TOGGLE_COILCARE_WIDGET, 'true');
    this.envDataWidgetToggle = isEqual(config.FEATURE_TOGGLE_SPR_ENVIRONMENTAL_DATA, 'true');
  }

  convertWidgetLineChartData(widget: SprWidget) {
    if (this.hasGraphTwoKpiTypes(widget)) {
      sprGraphFunctions.setSecondAxisOnSeries(widget);
    }

    this.convertSeriesDataNamesToDates(widget);
  }

  convertWidgetBarChartData(widget: SprWidget) {
    if (this.hasGraphTwoKpiTypes(widget)) {
      sprGraphFunctions.setSecondAxisOnSeriesData(widget);
    }

    const mainAxisTypeConfiguration =
      widget.graphConfigurations.find(conf => conf.key === graphConfigurationTypes.MAIN_AXIS_TYPE);

    if (mainAxisTypeConfiguration && mainAxisTypeConfiguration.value === 'months') {
      sprGraphFunctions.convertSeriesNamesToDates(widget);
    }
  }
  convertWidgetDonutChartData(widget: SprWidget, oppositeTranslation: string) {
    if (widget.calculatedData && widget.calculatedData.length && widget.calculatedData[0].series[1].name === 'LABEL_OPPOSITE'
      && oppositeTranslation) {
      widget.calculatedData[0].series[1].name = oppositeTranslation;
    }
  }

  private hasGraphTwoKpiTypes(sprWidget: SprWidget): boolean {
    const kpisByType = sprGraphFunctions.getKpisByType(sprWidget.kpis);
    return Object.keys(kpisByType)
      .map(key => kpisByType[key].length ? 1 : 0)
      .reduce((v, acc) => acc + v, 0) >= 2;
  }

  convertSeriesDataNamesToDates(sprWidget: SprWidget) {
    if (sprWidget.calculatedData) {
      sprWidget.calculatedData.forEach(data =>
        data.series.forEach(seriesData => seriesData.name = moment(seriesData.name, chartDateFormat).toDate()));
    }
  }

  isInternalOnly = (sprWidget: SprWidget) => sprWidget?.graphConfigurations?.find(config => config.key === graphConfigurationTypes.INTERNAL_ONLY)?.value === 'true'
}
