import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Favorite } from '../../../core/models/favorite';
import { DeleteFavoriteModalComponent } from '../../modal-popup/delete-favorite-modal/delete-favorite-modal.component';
import { FavoriteRestService } from '../../../core/rest-services/favorite-rest.service';
import { AddRemoveFavoriteEventService } from '../../../core/component-communication-services/add-remove-favorite-event/add-remove-favorite-event.service';
import { filter } from 'lodash-es';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-saved-filters',
  templateUrl: './saved-filters.component.html'
})
export class SavedFiltersComponent implements OnInit {
  @ViewChild('deleteFavoriteModal') deleteFavoriteModal: DeleteFavoriteModalComponent;

  @Input() isMyInstitutions = false;
  @Input() filterObject: any;
  @Input() listType: string;

  @Output() onFavoriteSelect = new EventEmitter<Favorite>();
  @Output() onFavoriteDelete = new EventEmitter<Favorite>();
  @Output() onFavoriteRename = new EventEmitter<Favorite>();

  favorites = [];
  selectedFavorite: Favorite;

  selectedDropDown = null;
  private destroyRef = inject(DestroyRef);

  constructor(private favoriteRestService: FavoriteRestService,
    private addRemoveFavoriteEventService: AddRemoveFavoriteEventService) {
  }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  init() {
    this.selectedFavorite = null;
    this.getFavorites();
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.addRemoveFavoriteEventService.addRemoveFavoriteSource$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.init();
      });
  }

  getFavorites() {

    this.favoriteRestService.getFavorites().subscribe((favoritesResponse) => {
      this.getFavoritesBasedOnType(favoritesResponse, this.listType);
    });
  }

  getFavoritesBasedOnType(dataset, favoriteType) {
    if (dataset) {
      this.favorites = filter(dataset, {type: favoriteType});
    }
  }

  loadFavorite(favorite) {
    this.selectedDropDown = null;
    this.onFavoriteSelect.emit(favorite);
  }

  renameFavorite(favorite) {
    this.selectedDropDown = null;
    this.onFavoriteRename.emit(favorite);
  }

  deleteFavorite(favorite) {
    this.selectedDropDown = null;
    this.onFavoriteDelete.emit(favorite);
  }

  openDropDown(favorite: any) {
    if (this.selectedDropDown === favorite) {
      this.selectedDropDown = null;
    } else {
      this.selectedDropDown = favorite;
    }
  }
}
