import { Injectable } from '@angular/core';
import { NotifStatus } from '../../core-constants.service';

@Injectable({providedIn: 'root'})
export class ActivitiesCacheService {
  private openClosedStatus: NotifStatus = NotifStatus.OPEN;
  private isCalendarVisible = false;
  private _isCalendarMonthView = true;
  private isCalendarYearView = false;
  private _isCalendarWeekView = false;
  private country: string;
  private customerId: string;

  setSelectedOpenClosedStatus(openClosedStatus: NotifStatus) {
    this.openClosedStatus = openClosedStatus;
  }

  getSelectedOpenClosedStatus(): NotifStatus {
    return this.openClosedStatus;
  }

  setCalendarVisible(isCalendarVisible: boolean) {
    this.isCalendarVisible = isCalendarVisible;
  }

  getCalendarVisible(): boolean {
    return this.isCalendarVisible;
  }

  getCalendarMonthView(): boolean {
    return this._isCalendarMonthView;
  }

  setCalendarMonthView() {
    this._isCalendarMonthView = true;
    this._isCalendarWeekView = false;
    this.isCalendarYearView = false;
  }

  setCalendarYearView() {
    this.isCalendarYearView = true;
    this._isCalendarWeekView = false;
    this._isCalendarMonthView = false;
  }

  getCalendarYearView(): boolean {
    return this.isCalendarYearView;
  }

  setCurrentCountry(country: string) {
    this.country = country;
  }

  getCurrentCountry(): string {
    return this.country;
  }

  getCalendarWeekView(): boolean {
    return this._isCalendarWeekView;
  }

  setCalendarWeekView() {
    this._isCalendarWeekView = true;
    this._isCalendarMonthView = false;
    this.isCalendarYearView = false;
  }

  getCustomerId(): string {
    return this.customerId;
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }
}
