<div>
  <hl-search-field searchFieldClasses="textfield--large margin-bottom-0"
    [(searchInput)]="searchInput" (searchInputChange)="onAdvancedFilterChange()"></hl-search-field>

  <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollContainer]="'.update-scroll-container'" 
    [fromRoot]="true" [scrollWindow]="false" [infiniteScrollThrottle]="10" (scrolled)="loadMore()" #panelList>
    <hl-list (onSortOptionChange)="onSortChange($event)" [additionalCollectionClass]="'overview-list--equipment'" [isLoaded]="isLoaded"
      dataCyInput="equipments" [filteredLengthWithoutPagination]="filteredLengthWithoutPagination"
      [items]="viewModelList" [label]="filteredLengthWithoutPagination | plural : 'GENERIC_LABEL_SIEMENS_EQUIPMENTS_SINGULAR' : 'GENERIC_LABEL_SIEMENS_EQUIPMENTS' | async" [sortConfig]="sortSkeleton" 
      empty="EQUIPMENT_NO_EQUIPMENTS_FOUND">
      <ng-template let-item let-index="index">
        <hl-new-system-update-equipment-list-item [item]="item" [showPlusButton]="true" (onEquipmentClicked)="removeEquipmentFromList(item)">
        </hl-new-system-update-equipment-list-item>
      </ng-template>
    </hl-list>
  </div>


</div>
