import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hl-user-name',
  templateUrl: './user-name.component.html'
})
export class UserNameComponent implements OnInit {

  formattedUserName$: Observable<string>;
  constructor(
    private configService: CountryConfigRestService,
    private userRestService: UserRestService) {
  }

  ngOnInit() {
    const config$ = this.configService.getConfig();
    const user$ = this.userRestService.getUser();
    
    this.formattedUserName$ = combineLatest([config$, user$]).pipe(
      map(([config, {firstName, lastName}]) => config.HEADER_NAME_ORDER
        .replace(/#FIRSTNAME#/g, firstName).replace(/#LASTNAME#/g, lastName)
      )
    );
  }
}
