import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InvoiceViewModel } from '../../../core/view-models/invoice-view-model';
import { InvoicesUtilService } from '../../../core/services/invoices-util.service';

@Component({
  selector: 'hl-invoices-status-map',
  templateUrl: './invoices-status-map.component.html'
})
export class InvoicesStatusMapComponent implements OnInit, OnChanges {

  label: string;
  color: string;
  @Input() item: InvoiceViewModel;

  constructor(private invoicesUtilService: InvoicesUtilService) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] && !changes['item'].firstChange) {
      this.init();
    }
  }

  init() {
    if (this.item) {
      const statusMap = this.invoicesUtilService.getInvoicesStatusColorBinding(this.item);
      this.label = statusMap.label;
      this.color = statusMap.colorClass;
    }
  }
}
