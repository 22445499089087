<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [innerHTML]="'CREATE_NEW_WHITELIST_ENTRY' | translate"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc">

        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

        @if (!showSpinner) {
          <form [formGroup]="addEntryForm" class="form" novalidate>
            <div class="row">
              <div class="col-12">
                <hl-textfield label="LABEL_MATERIAL_NUMBER"
                  [isRequired]="addEntryForm.controls['materialNumber'].errors && addEntryForm.controls['materialNumber'].errors['required']"
                  invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !addEntryForm.controls['materialNumber'].valid"
                  [formControl]="addEntryForm.controls['materialNumber']" ngDefaultControl></hl-textfield>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hl-textfield label="GENERIC_LABEL_SERIAL_NUMBER"
                  [isRequired]="addEntryForm.controls['serialNumber'].errors && addEntryForm.controls['serialNumber'].errors['required']"
                  invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !addEntryForm.controls['serialNumber'].valid"
                  [formControl]="addEntryForm.controls['serialNumber']" ngDefaultControl></hl-textfield>
              </div>
            </div>
          </form>
        }

      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'btn btn-sm btn-highlight'"
          [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isFormValid]="addEntryForm.valid" [isModalType]="true"
          label="LABEL_CREATE_NEW_ENTRY" name="isFormSubmitted"></hl-simulator-check>
      }
    </div>
  </div>

  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
