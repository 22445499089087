import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { OptionsRecommendModalComponent } from '../options-recommend-modal/options-recommend-modal.component';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { EquipmentConstantsService } from '../../../core/services/equipment/equipment-constants.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { forEach, sortBy } from 'lodash-es';
import { OptionsUpgradesViewModel } from '../../../core/view-models/options-upgrades-view-model';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'hl-options-get-quote-modal',
  templateUrl: './options-get-quote-modal.component.html'
})
export class OptionsGetQuoteModalComponent extends OptionsRecommendModalComponent implements OnInit, OnChanges {

  /** the name of the option, for which the modal is opened */
  _option: OptionsUpgradesViewModel;
  _optionUpgradesList: OptionsUpgradesViewModel[];

  @Input()
  set option(option: OptionsUpgradesViewModel) {
    this._option = option;
    this.updatePreselection();
  }

  @Input()
  set optionUpgradesList(optionUpgradesList: OptionsUpgradesViewModel[]) {
    this._optionUpgradesList = optionUpgradesList;
    this.initDropDownList();
  }

  get option(): OptionsUpgradesViewModel {
    return this._option;
  }

  options: DropdownOptions[];

  constructor(
    fb: UntypedFormBuilder,
    userRestService: UserRestService,
    equipmentRestService: EquipmentRestService,
    equipmentConstantsService: EquipmentConstantsService,
    toasterService: ToasterService,
    renderer: Renderer2,
    protected equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService) {
    super(
      fb,
      userRestService,
      equipmentRestService,
      equipmentConstantsService,
      toasterService,
      configService,
      renderer);
  }

  ngOnInit() {
    super.init();
    this.options = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getOptionsForm?.get('equipmentKeys').setValue([this.equipmentItem.key]);
    this.updatePreselection();
  }

  initDropDownList() {
    forEach(this._optionUpgradesList, (optionUpgrade) => {
      if (!this.options.some(o => o.title === optionUpgrade.name)) {
        this.options.push({value: optionUpgrade.name, title: optionUpgrade.name});
      }
    });

    this.options = sortBy(this.options, (i) => i.title.toLowerCase());

    // has also to be done here - otherwise, if the modal is opened again after it has been sent
    // for the same option - this option is no longer preselected
    this.updatePreselection();
  }

  postFormData() {
    this.resolveObservable(this.equipmentRestService.postGetQuote(this.getPreparedPostBody()));
  }

  resolveObservable(observable: Observable<any>) {
    observable.pipe(finalize(() => {
      this.isFormSubmitted = false;
      this.hide();
      this.ngOnInit();
      this.initDropDownList();
    })).subscribe(() => {
      this.toasterService.showTranslatedSuccessToaster('GET_QUOTE_EMAIL_SUCCESS');
    });
  }

  createForm(childContactForm: UntypedFormGroup): UntypedFormGroup {
    return this.fb.group({
      application: [[], Validators.required],
      contact: childContactForm,
      additionalInformation: [''],
      equipmentKeys: [[this.equipmentItem.key], Validators.required]
    });
  }

  getPreparedPostBody(): object {
    return { ...this.getOptionsForm.value };
  }

  updatePreselection() {
    if (this.option) {
      if (!this.getFormProperty('application').includes(this.option.name)) {
        this.getFormProperty('application').push(this.option.name);
        this.getOptionsForm.controls['application'].updateValueAndValidity();
      }
    }
  }
}
