import { Inject, Injectable } from '@angular/core';
import { defer, mergeMap, Observable, of, throwError } from 'rxjs';
import { restEndPoint } from '../../../../core/core-constants.service';
import { EquipmentChecksheetModel } from '../models/equipment-checksheet-model';
import { EquipmentChecksheetDates, EquipmentChecksheetDetailModel, EquipmentChecksheetExport, EquipmentChecksheetIdentifier } from '../models/equipment-checksheet-detail-model';
import { CacheableHttpClient } from '../../../../core/services/cache/cacheable-http-client';
import { HttpParams } from '@angular/common/http';
import { EquipmentChecksheetLockResult } from '../models/equipment-checksheet-lock-result-model';
import { DOCUMENT } from '@angular/common';

export const checksheetListEndpoint = restEndPoint + 'daily-check-sheets';
export const checksheetDetailEndpoint = checksheetListEndpoint + '/details';
export const checksheetEditLockEndpoint = checksheetListEndpoint + '/lock';
export const checksheetEditUnlockEndpoint = checksheetListEndpoint + '/unlock';
export const checksheetEditRenewEndpoint = checksheetListEndpoint + '/renew';
export const checksheetSetDatesToNAEndpoint = checksheetListEndpoint + '/set-dates-to-na';
export const checksheetApproveAllEndpoint = checksheetListEndpoint + '/approve-all';
export const checksheetUpdateEndpoint = checksheetListEndpoint + '/update';
export const checksheetApproveEndpoint = checksheetListEndpoint + '/approve';
export const checksheetExportEndpoint = checksheetListEndpoint + '/generate-excel';
export const checksheetNumberOfLockedCheckSheetsEndpoint = checksheetListEndpoint + '/number-of-locked';
export const customerWhitelistedForCheckSheetsEndpoint = checksheetListEndpoint + '/is-customer-whitelisted';
export const equipmentWhitelistedForCheckSheetsEndpoint = checksheetListEndpoint + '/is-equipment-whitelisted';
export const checksheetIsWhitelistedForUserModalitiesEndpoint = checksheetListEndpoint + '/is-whitelisted-for-user';
export const equipmentWidgetChecksheetEndpoint = checksheetListEndpoint + '/widget/equipment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentChecksheetRestService {

  constructor(
    private http: CacheableHttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  getChecksheets(serialNumber: string, materialNumber: string, dateFrom: string, dateTo: string): Observable<EquipmentChecksheetModel[]> {
    const params = new HttpParams()
      .set('serialNumber', serialNumber)
      .set('materialNumber', materialNumber)
      .set('dateFrom', dateFrom)
      .set('dateTo', dateTo);

    return this.http.get<EquipmentChecksheetModel[]>(checksheetListEndpoint, {params});
  }

  getChecksheetDetail(materialNumber: string, serialNumber: string, date: string): Observable<EquipmentChecksheetDetailModel> {
    const params = new HttpParams()
      .set('serialNumber', serialNumber)
      .set('materialNumber', materialNumber)
      .set('date', date);

    return this.http.get<EquipmentChecksheetDetailModel>(checksheetDetailEndpoint, {params});
  }

  getEquipmentWidgetChecksheet(serialNumber: string, materialNumber: string, date: string): Observable<EquipmentChecksheetDetailModel> {
    const params = new HttpParams()
      .set('serialNumber', serialNumber)
      .set('materialNumber', materialNumber)
      .set('date', date);

    return this.http.get<EquipmentChecksheetDetailModel>(equipmentWidgetChecksheetEndpoint, {params});
  }

  acquireEditLock(checksheetIdentifier: EquipmentChecksheetIdentifier): Observable<EquipmentChecksheetLockResult> {
    return this.http.post<EquipmentChecksheetLockResult>(checksheetEditLockEndpoint, null, {params: this.buildRequestParams(checksheetIdentifier)});
  }

  // This request needs to be sent when user closes the tpF tab in browser. Normally, browser
  // discards requests to be sent after the tab is closed, but provides the API to let requests
  // outlive the page. (navigator.sendBeacon and fetch with keepAlive)

  // Workaround:
  // Unfortunately, Angular HttpClient is not currently capable of making these requests as it
  // uses XMLHttpRequest under the hood. Official support for fetch HttpClient backend is planned,
  // but not released as stable yet. When this support is added, and we switch to the fetch backend,
  // this hack can be removed.
  releaseEditLock(checksheetIdentifier: EquipmentChecksheetIdentifier): Observable<boolean> {
    const getCsrfTokenFromCookie = (): string | null => {
      const value = this.document.cookie.match(/(?:^|;\s*)*XSRF-TOKEN=([^;]*)/i)?.at(1) ?? null;
      return value ? decodeURIComponent(value) : null;
    };

    return defer(() => {
      const url = `${checksheetEditUnlockEndpoint}?checkSheetID=${encodeURIComponent(checksheetIdentifier.checkSheetID)}` +
        `&materialNumber=${checksheetIdentifier.materialNumber}&serialNumber=${checksheetIdentifier.serialNumber}&date=${checksheetIdentifier.date}`;
      const csrfToken = getCsrfTokenFromCookie();

      return fetch(url, {
        method: 'POST',
        keepalive: true,
        headers: csrfToken ? {'X-XSRF-Token': csrfToken} : {}
      });
    }).pipe(mergeMap(res => res.ok ? of(true) : throwError(() => res)));
  }

  renewEditLock(checksheetIdentifier: EquipmentChecksheetIdentifier): Observable<EquipmentChecksheetLockResult> {
    return this.http.post<EquipmentChecksheetLockResult>(checksheetEditRenewEndpoint, null, {params: this.buildRequestParams(checksheetIdentifier)});
  }

  updateChecksheet(checksheet: EquipmentChecksheetDetailModel): Observable<EquipmentChecksheetDetailModel> {
    return this.http.post<EquipmentChecksheetDetailModel>(checksheetUpdateEndpoint, checksheet);
  }

  approveChecksheet(checksheetIdentifier: EquipmentChecksheetIdentifier): Observable<EquipmentChecksheetDetailModel> {
    return this.http.post<EquipmentChecksheetDetailModel>(checksheetApproveEndpoint, null, {params: this.buildRequestParams(checksheetIdentifier)});
  }

  getNumberOfLockedCheckSheets(filteredSheets: EquipmentChecksheetDates): Observable<number> {
    return this.http.post<number>(checksheetNumberOfLockedCheckSheetsEndpoint, filteredSheets);
  }

  setDatesAsNA(filteredSheets: EquipmentChecksheetDates) {
    return this.http.post<void>(checksheetSetDatesToNAEndpoint, filteredSheets);
  }

  approveAll(filteredSheets: EquipmentChecksheetDates) {
    return this.http.post<void>(checksheetApproveAllEndpoint, filteredSheets);
  }

  exportExcel(checksheetExport: EquipmentChecksheetExport): Observable<ArrayBuffer> {
    return this.http.post(checksheetExportEndpoint, checksheetExport, {responseType: 'arraybuffer'});
  }

  checkCustomerWhitelistedForChecksheets(customerId: string): Observable<boolean> {
    return this.http.get<boolean>(customerWhitelistedForCheckSheetsEndpoint,
      {params: new HttpParams().set('customerId', customerId)});
  }

  checkEquipmentWhitelistedForChecksheets(materialNumber: string): Observable<boolean> {
    return this.http.get<boolean>(equipmentWhitelistedForCheckSheetsEndpoint,
      {params: new HttpParams().set('materialNumber', materialNumber)});
  }

  isChecksheetEnabledForUserModalities(): Observable<boolean> {
    return this.http.get(checksheetIsWhitelistedForUserModalitiesEndpoint);
  }

  private buildRequestParams(checksheetIdentifier: EquipmentChecksheetIdentifier): HttpParams {
    return new HttpParams()
      .set('checkSheetID', checksheetIdentifier.checkSheetID)
      .set('serialNumber', checksheetIdentifier.serialNumber)
      .set('materialNumber', checksheetIdentifier.materialNumber)
      .set('date', checksheetIdentifier.date);
  }

  clearCache() {
    this.http.clearCache(checksheetListEndpoint);
    this.http.clearCache(checksheetDetailEndpoint);
    this.http.clearCache(equipmentWidgetChecksheetEndpoint);
  }
}
