<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--full-content is-open" tabindex="-1" role="dialog" aria-modal="true">
    @if (loaded) {
      <div class="modal-dialog__container" role="document">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title">{{ introContent[currentIndex].title }}</h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="modal1_desc">
          <hl-intro-slider-gallery [data]="introContent" [currentIndex]="currentIndex"></hl-intro-slider-gallery>
        </div>
        <div class="modal-dialog__footer">
          <button class="button" (click)="back()" [translate]="'BUTTON_BACK'"></button>
          @if (!lastSlide()) {
            <button class="button button--primary" (click)="next()" [translate]="'BUTTON_NEXT'"></button>
          } @else {
            <button class="button button--primary" (click)="hide()" [translate]="'GENERIC_LABEL_CLOSE'"></button>
          }
        </div>
      </div>
    }
  </div>
</ng-template>
