<hl-info-modal #infoModal [title]="'HEADER_KONTAKT'" dataCyHeader="contact-dialog-header" closeLabel="GENERIC_LABEL_CLOSE">
  <div [innerHTML]="'CONTACT_HEADER' | translate | safeDom: 'html'" data-cy="contact-dialog-body"></div>

  @if (additionalContacts.length > 0) {
    <div>
      <ul class="list list--bullet">
        @for (contactItem of additionalContacts; track contactItem) {
          <li class="list__item">
            <div class="row additional-item">
              <div class="col-6">
                <span>{{ contactItem.function }}</span>
              </div>
              <div class="col-6 ">
                {{ contactItem.phoneNumber }}
              </div>
            </div>
            <div class="row additional-item">
              <div class="col-6">
                <span>{{ contactItem.fullName }}</span>
              </div>
              <div class="col-6">
                <a class="link" [href]="'mailto:' + contactItem.email">{{ contactItem.email }}</a>
              </div>
            </div>
          </li>
        }
      </ul>
    </div>
  }
</hl-info-modal>
