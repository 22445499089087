import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { ListsCardModel } from '../../../core/models/lists-card';

@Component({
  selector: 'hl-lists-card',
  templateUrl: './lists-card.component.html'
})
export class ListsCardComponent {

  @Input()
  list: ListsCardModel[];

  @ContentChild('valueIntro', {static: false}) valueIntroTemplateRef: TemplateRef<any>;
}
