<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--full-content is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'LABEL_HEADLINE_MULTI_COUNTRY_IMPERSONATION'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div id="modal2_desc" class="modal-dialog__body">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

        @if (!showSpinner) {
          <form class="form" #modalForm="ngForm">
            <p [translate]="'LABEL_TEXT_MULTI_COUNTRY_IMPERSONATION'"></p>
            <div class="row">
              <div class="col-12">
                <hl-selectbox [options]="countries" label="LABEL_COUNTRY" [(value)]="selectedCountry" (valueChange)="updateLanguageOnCountryChange()"
                  name="country"></hl-selectbox>
              </div>
              <div class="col-12">
                <hl-selectbox [options]="languages" label="LABEL_LANGUAGE" [(value)]="selectedLocale" name="language"
                  [translateKeys]="true"></hl-selectbox>
              </div>
            </div>
          </form>
        }
      </div>
      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button type="button" class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_APPLY'"></button>
          <button type="button" class="button button" data-dismiss="modal-dialog" (click)="hide()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
        </div>
      }
    </div>
  </div>

</ng-template>
