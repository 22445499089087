import { Component, Input} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-ticket-confirm-patient-data',
  templateUrl: './create-ticket-confirm-patient-data.component.html'
})
export class CreateTicketConfirmPatientDataComponent {

  @Input()
  confirmNoPatientData: UntypedFormGroup;
  @Input()
  isFormSubmitted: boolean;

}
