<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    @if (activitiesItem) {
      <div class="modal-dialog__container">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title">
            {{ 'HEADLINE_SCHEDULE_ACTIVITY' | translate }}
            @if (activitiesItem) {
              <span>&nbsp;&ndash;&nbsp;{{ activitiesItem.ticketNumber }}</span>
            }
          </h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="modal1_desc">
          @if (showValidationMessage) {
            <p class="message message-error">
              <span>{{ translationErrorMessage | translate }}</span>
            </p>
          }
          <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
          @if (!showSpinner) {
            <form [formGroup]="modalForm" class="form" novalidate>
              <!-- cancel Form -->
              <p [innerHTML]="scheduleActivityDateText"></p>
              <div>
                <hl-date-picker [labelText]="'LABEL_SCHEDULE_ACTIVITY_DATE1'" [(ngModel)]="modalForm.controls['firstDate'].value"
                  [isRequired]="modalForm.controls['firstDate'].errors && modalForm.controls['firstDate'].errors['required']"
                  [formControl]="modalForm.controls['firstDate']" [convertModelValue]="true" ngDefaultControl
                  [isInvalid]="isFormSubmitted && !modalForm.controls.firstDate.valid"
                  [invalidLabel]="'ERROR_VALIDATION_SCHEDULE_ACTIVITY'"></hl-date-picker>
                <hl-time-picker [labelText]="'LABEL_SCHEDULE_ACTIVITY_TIME'" [(ngModel)]="modalForm.controls['firstTime'].value"
                  [formControl]="modalForm.controls['firstTime']" [convertModelValue]="true" ngDefaultControl></hl-time-picker>
              </div>
              <div>
                <hl-date-picker [labelText]="'LABEL_SCHEDULE_ACTIVITY_DATE2'" [(ngModel)]="modalForm.controls['secondDate'].value"
                  [isRequired]="modalForm.controls['secondDate'].errors && modalForm.controls['secondDate'].errors['required']"
                  [formControl]="modalForm.controls['secondDate']" [convertModelValue]="true" ngDefaultControl
                  [isInvalid]="isFormSubmitted && !modalForm.controls.secondDate.valid"
                  [invalidLabel]="'ERROR_VALIDATION_SCHEDULE_ACTIVITY'"></hl-date-picker>
                <hl-time-picker [labelText]="'LABEL_SCHEDULE_ACTIVITY_TIME'" [(ngModel)]="modalForm.controls['secondTime'].value"
                  [formControl]="modalForm.controls['secondTime']" [convertModelValue]="true" ngDefaultControl></hl-time-picker>
              </div>
              <div>
                <input class="checkbox" type="checkbox" [formControl]="modalForm.controls['overTimeAuthorization']">
                <label for="overTimeAuthorization" [translate]="'LABEL_OVERTIME_AUTHORIZATION'"></label>
                <p class="font-size-small" [translate]="'TEXT_OVERTIME_AUTHORIZATION'"></p>
              </div>
              <div>
                <p class="margin-bottom-0" [translate]="'TICKET_FEEDBACK_REQUESTED_VIA'"></p>
                <ul class="list list--inline bare-list">
                  <li class="list__item">
                    <input class="radio radio--inline" type="radio" formControlName="preferredCommunication" value="email" id="email"
                      (click)="togglePreferredCommunication('email')">
                    <label for="email" [translate]="'TICKET_FEEDBACK_EMAIL'" (click)="togglePreferredCommunication('email')"></label>
                  </li>
                  <li class="list__item">
                    <input class="radio radio--inline" type="radio" formControlName="preferredCommunication" value="phone" id="phone"
                      (click)="togglePreferredCommunication('phone')">
                    <label for="phone" [translate]="'TICKET_FEEDBACK_TELEPHONE'" (click)="togglePreferredCommunication('phone')"></label>
                  </li>
                </ul>
              </div>
              <hl-textfield [name]="'poNumber'" label="LABEL_PURCHASE_ORDER_NUMBER" [formControl]="modalForm.controls['poNumber']"
                ngDefaultControl></hl-textfield>
              <hl-contact-area [contactForm]="modalForm.controls['contact']" [isFormSubmitted]="isFormSubmitted"></hl-contact-area>
            </form>
          }
        </div>
        @if (!showSpinner) {
          <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'btn btn-sm btn-highlight'"
            [isFormValid]="modalForm.valid" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true" [label]="'SEND_LABEL'"
            name="isFormSubmitted"></hl-simulator-check>
        }
      </div>
    }
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
