<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" data-cy="ticket-update-modal" role="document">

      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title">
          {{ headerLabel | translate }} </h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>

      <div class="modal-dialog__body" id="modal1_desc">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          <div class="row">
            <div class="col-12">
              <form [formGroup]="updateTicketForm">
                @if (ticketUpdateAllowed) {
                  <div class="row">
                    <div class="col-12">
                      <div class="select" [ngClass]="{'invalid': isFormSubmitted && !updateTicketForm.controls['typeID'].valid}">
                        <hl-selectbox [formControl]="updateTicketForm.controls['typeID']" [options]="ticketTypesOptions"
                          [isRequired]="updateTicketForm.controls['typeID'].errors && updateTicketForm.controls['typeID'].errors['required']"
                          label="LABEL_TICKET_TYPE_UPDATE" ngDefaultControl></hl-selectbox>
                        @if (!updateTicketForm.controls.typeID.valid) {
                          <small class="selectbox__hint selectbox__hint--validation" [translate]="'ERROR_VALIDATION_TICKET_TYPE_UPDATE'"></small>
                        }
                      </div>
                    </div>
                  </div>
                }
                <hl-textarea [parentForm]="updateTicketForm" [name]="'longText'" [labelText]="'ADDITIONAL_INFORMATION'" [isLarge]="true"
                  [isInvalid]="isFormSubmitted && !updateTicketForm.controls['longText'].valid" [isRequired]="true"></hl-textarea>
                @if (isFormSubmitted && !updateTicketForm.controls['longText'].valid) {
                  <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
                }
                @if (showTicketAttachmentByEquipment && showTicketAttachmentByTicketType) {
                  <div>
                    <hl-attachment [parentForm]="updateTicketForm" [isFormSubmitted]="isFormSubmitted"></hl-attachment>
                  </div>
                }
                @if (showRequestArea) {
                  <hl-textfield placeholder="LABEL_OPTIONAL" label="TICKET_CREATE_FEEDBACK_OWNID"
                    [formControl]="updateTicketForm.controls['poNumber']" ngDefaultControl></hl-textfield>
                }
                <hl-contact-area [contactForm]="updateTicketForm.controls['contact']" [showPhoneNumberExtension]="showPhoneNumberExtension"
                  [isFormSubmitted]="isFormSubmitted"></hl-contact-area>
                @if (showConfidentialDataConfirmation) {
                  <fieldset>
                    <hl-create-ticket-confirm-patient-data [isFormSubmitted]="isFormSubmitted"
                      [confirmNoPatientData]="updateTicketForm.controls['confirmNoPatientData']"></hl-create-ticket-confirm-patient-data>
                  </fieldset>
                }
              </form>
            </div>
          </div>
        }
      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="''"
          [isFormValid]="updateTicketForm.valid" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
          [label]="'SEND_LABEL'" name="isFormSubmitted"></hl-simulator-check>
      }
    </div>
  </div>

  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
