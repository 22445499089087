import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SecurityApplianceOrderUtilService } from 'app/core/services/security-appliance-order/security-appliance-order-util.service';
import { ActivatedRoute } from '@angular/router';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { parseInt } from 'lodash-es';
import { green, red, yellow } from '../../../core/core-constants.service';
import { CENTRICARE_EQUIPMENT_STATUS_CRITICAL } from '../../../core/services/equipment/equipment-constants.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-create-sa-modal',
  templateUrl: './create-sa-modal.component.html',
  styles: []
})
export class CreateSaModalComponent extends BaseModalPopup implements OnInit {
  @Input()
  equipmentId: string;
  statusColorMap: any = {};
  createSaoForm: UntypedFormGroup;
  showValidationMessage = false;
  isFormSubmitted = false;
  equipmentKey: string;
  @Output()
  close = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private saoUtilService: SecurityApplianceOrderUtilService,
    private route: ActivatedRoute,
    private configService: CountryConfigRestService,
    private toasterService: ToasterService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ok() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.hide();
    this.close.emit(true);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.initFormGroup();
    this.initFromQueryParams();
  }

  private initFormGroup() {
    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(configResponse => {
      this.statusColorMap[configResponse.EQUIPMENT_STATUS_GREEN] = green;
      this.statusColorMap[configResponse.EQUIPMENT_STATUS_RED] = red;
      this.statusColorMap[configResponse.EQUIPMENT_STATUS_YELLOW] = yellow;
      this.statusColorMap[CENTRICARE_EQUIPMENT_STATUS_CRITICAL] = red;
      this.createForm(configResponse);
    });
  }

  createForm(config) {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = parseInt(config.EMAIL_VALIDATION_LENGTH);

    this.createSaoForm = this.fb.group({
      product: this.fb.group({
        equipmentKey: [
          this.equipmentId ? this.equipmentId : '',
          Validators.required
        ],
        customerId: [''],
        ipAddress: ['', Validators.required],
        ipAddressFirewall: ['', Validators.required],
        srsConnection: ['', Validators.required],
        targetPorts: ['', Validators.required]
      }),
      order: this.fb.group({
        additionalOption: ['false']
      }),
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required]],
        contactLastName: ['', [Validators.required]],
        contactPhone: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      contactIT: this.fb.group({
        itAdministratorName: ['', Validators.required],
        itAdministratorPhone: ['', Validators.required],
        itAdministratorEmail: [
          '',
          [
            Validators.required,
            Validators.maxLength(emailLength),
            Validators.pattern(emailRegEx)
          ]
        ],
        siemensContact: ['']
      }),
      shipping: this.fb.group({
        receiverName: ['', Validators.required],
        customerName: ['', Validators.required],
        department: ['', Validators.required],
        building: ['', Validators.required],
        street: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required]
      }),
      billing: this.fb.group({
        receiverName: ['', Validators.required],
        customerName: ['', Validators.required],
        department: ['', Validators.required],
        building: ['', Validators.required],
        street: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        isActive: ['false', Validators.required]
      })
    });
  }

  createSao() {
    this.isFormSubmitted = true;
    if (this.createSaoForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;

      this.saoUtilService.createSao(this.createSaoForm.value).subscribe({
        next: () => {
          this.toasterService.showTranslatedSuccessToaster('TEXT_SAO_SEND_ORDER_SUCCESS');
          this.ok();
        },
        error: () => {
          this.showSpinner = false;
          this.toasterService.showTranslatedErrorToaster('TEXT_SAO_ERROR_EMAIL_SENDING');
        }
      });
    } else {
      this.showValidationMessage = true;
    }
  }

  cancelCreateSao() {
    this.ok();
  }

  private initFromQueryParams() {
    if (this.equipmentId) {
      this.createSaoForm.patchValue({
        product: {equipmentKey: this.equipmentId}
      });
    }
  }
}
