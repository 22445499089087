import { Component, Input } from '@angular/core';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { RescheduleActivityModalComponent } from '../reschedule-activity-modal/reschedule-activity-modal.component';
import { ValidatorFn, Validators } from '@angular/forms';
import { AppointmentsFilterStatus } from '../../../activities-common/appointments-constants';

@Component({
  selector: 'hl-reschedule-appointment-modal',
  templateUrl: './reschedule-appointment-modal.component.html'
})
export class RescheduleAppointmentModalComponent extends RescheduleActivityModalComponent {
  private _activityAppointments: ActivitiesViewModel[];
  private appointmentNumberToPlannedStartMap: Map<string, string>;

  @Input()
  set activityAppointments(activityAppointments: ActivitiesViewModel[]) {
    this._activityAppointments = activityAppointments;
    if (activityAppointments) {
      this.appointmentNumberToPlannedStartMap = new Map<string, string>(
        activityAppointments.map(appointment => ([appointment.appointmentNumber, appointment.plannedStart])));
    }
  }

  createForm() {
    this.form = this.fb.group({
      rescheduleEmailText: ['', [Validators.required]],
      rescheduleAppointments: [[], [this.rescheduleAppointmentsValidator()]]
    });
  }

  getAppointmentPlannedStart(appointment: { appointmentNumber?: string }): string {
    return this.appointmentNumberToPlannedStartMap.get(appointment.appointmentNumber);
  }

  onCheck(appointmentNumber: string) {
    const appointment = this.form.get('rescheduleAppointments').value.find(app => app.appointmentNumber === appointmentNumber);

    if (appointment) {
      appointment.checked = !appointment.checked;
      this.form.get('rescheduleAppointments').updateValueAndValidity();
    }
  }

  openRescheduleModal() {
    this.medalliaUtilService.setPopupsAllowed(false);
    this.createForm();
    this.form.get('rescheduleAppointments').patchValue(this._activityAppointments
      .filter(appointment => appointment.filterStatus !== AppointmentsFilterStatus.CLOSED)
      .map(appointment => ({
        appointmentNumber: appointment.appointmentNumber,
        checked: appointment.appointmentKey === this.activitiesItem.appointmentKey
      })));

    this.show();
  }

  openRescheduleModalForActivityAppointments(activityAppointments: ActivitiesViewModel[]) {
    this.medalliaUtilService.setPopupsAllowed(false);
    this.createForm();
    this.form.get('rescheduleAppointments').patchValue(activityAppointments
      .filter(appointment => appointment.filterStatus !== AppointmentsFilterStatus.CLOSED)
      .map(appointment => ({
        appointmentNumber: appointment.appointmentNumber,
        checked: appointment.appointmentKey === this.activitiesItem?.appointmentKey
      })));

    this.show();
  }

  rescheduleAppointmentsValidator(): ValidatorFn {
    return rescheduleAppointmentsFormControl => {
      if (!rescheduleAppointmentsFormControl) {
        return {status: 'appointments form control does not exist'};
      }

      const isAppointmentsSelectionValid = rescheduleAppointmentsFormControl.value.some(appointment => appointment.checked);
      return !isAppointmentsSelectionValid ? {status: 'appointments selection is not valid'} : null;
    };
  }
}
