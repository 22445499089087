import { Injectable } from '@angular/core';
import { DotWeekendDays } from '../models/dot-weekend-days';
import { DotTimeframe, DotTimeframeConfig } from '../models/dot-timeframe';
import { DateRange } from '../../../core/models/date-range';
import moment from 'moment';

const DAY_TO_INDEX = {
  'SU': 0,
  'MO': 1,
  'TU': 2,
  'WE': 3,
  'TH': 4,
  'FR': 5,
  'SA': 6
};

@Injectable({
  providedIn: 'root'
})
export class DotDateService {

  calculateTimeframeDates(dueDate: Date, weekendDays: DotWeekendDays): DotTimeframeConfig {
    const minDate = this.addWorkingDaysToDate(this.getTodayDate(), 3, weekendDays);
    const maxDate = this.findFirstWorkingDateBeforeOrEqual(dueDate, weekendDays);
    return { minDate, maxDate };
  }

  parseWeekendDaysFromConfig(rawWeekendDays: string): DotWeekendDays {
    return rawWeekendDays.split(',').map(day => DAY_TO_INDEX[day]);
  }

  isDateWeekend(date: Date, weekendDays: DotWeekendDays): boolean {
    return weekendDays.includes(date.getDay());
  }

  splitTimeframeIntoIntervals({ earliestDate, latestDate }: DotTimeframe, intervalLengthInDays: number): DateRange[] {
    const intervals: DateRange[] = [];

    let fromDate = earliestDate;
    let toDate = this.moveTimeToEndOfDay(this.addDaysToDate(fromDate, intervalLengthInDays - 1));

    while (toDate < latestDate) {
      intervals.push({ fromDate, toDate });
      fromDate = this.moveTimeToStartOfDay(this.addDaysToDate(toDate, 1));
      toDate = this.moveTimeToEndOfDay(this.addDaysToDate(fromDate, intervalLengthInDays - 1));
    }

    intervals.push({ fromDate, toDate: latestDate });
    return intervals;
  }

  formatDateInLocalTimeZoneWithoutTimeZoneInformation(date: Date): string {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  addDaysToDate(startDate: Date, daysToAdd: number): Date {
    const date = new Date(startDate);
    date.setDate(date.getDate() + daysToAdd);
    return date;
  }

  private addWorkingDaysToDate(startDate: Date, workingDaysToAdd: number, weekendDays: DotWeekendDays): Date {
    const date = new Date(startDate);
    let workingDaysAdded = 0;

    while (workingDaysAdded < workingDaysToAdd) {
      date.setDate(date.getDate() + 1);
      if (!this.isDateWeekend(date, weekendDays)) {
        ++workingDaysAdded;
      }
    }

    return date;
  }

  private findFirstWorkingDateBeforeOrEqual(startDate: Date, weekendDays: DotWeekendDays): Date {
    const date = new Date(startDate);

    while (this.isDateWeekend(date, weekendDays)) {
      date.setDate(date.getDate() - 1);
    }

    return date;
  }

  private getTodayDate(): Date {
    return this.moveTimeToStartOfDay(new Date());
  }

  private moveTimeToStartOfDay(date: Date): Date {
    const copy = new Date(date);
    copy.setHours(0, 0, 0, 0);
    return copy
  }

  private moveTimeToEndOfDay(date: Date): Date {
    const copy = new Date(date);
    copy.setHours(23, 59, 59, 999);
    return copy;
  }
}
