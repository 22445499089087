<div [formGroup]="detailsForm">
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-progress-indicator class="spinner-top-none" [loading]="loadingEquipmentDetails"></hl-progress-indicator>
      @if (!loadingEquipmentDetails && components && components.length > 0) {
        <div class="select">
          <hl-selectbox (valueChange)="onComponentChange()" [formControl]="detailsForm.controls['componentID']" [options]="components"
            [tooltip]="false" [documentOverlay]="true" label="COMPONENTS_TITLE" ngDefaultControl></hl-selectbox>
        </div>
      }
    </div>
  </div>

  @if (isTeamplayEquipmentSelected) {
    <div class="row">
      <div class="col-12 col-lg-8">
        <hl-progress-indicator class="spinner-top-none" [loading]="loadingTeamplayApplicationOptions"></hl-progress-indicator>
        @if (!loadingTeamplayApplicationOptions) {
          <div class="select" [ngClass]="{'invalid': isFormSubmitted && !detailsForm.controls['teamplayApplication'].valid}">
            <hl-selectbox [formControl]="detailsForm.controls['teamplayApplication']" [options]="teamplayApplicationOptions" [isRequired]="true"
              label="LABEL_TICKET_CREATE_TEAMPLAY_APPLICATION" [(ngModel)]="selectedTeamplayApplication" ngDefaultControl></hl-selectbox>
            @if (isFormSubmitted && !detailsForm.controls['teamplayApplication'].valid) {
              <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
            }
          </div>
        }
      </div>
    </div>
  }

  <div class="row">
    <div class="col-12 col-lg-8">
      <div class="select" [ngClass]="{'invalid': isFormSubmitted && !detailsForm.controls['typeID'].valid}">
        <hl-selectbox id="ticket-type-select-box" [formControl]="detailsForm.controls['typeID']" [options]="dropdownOptions" [isDisabled]="!equipment"
          [isRequired]="detailsForm.controls['typeID'].errors && detailsForm.controls['typeID'].errors['required']" label="TICKET_TYPE"
          (valueChange)="onTicketTypeChange()" ngDefaultControl></hl-selectbox>
        @if (isFormSubmitted && !detailsForm.controls['typeID'].valid) {
          <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
        }
      </div>
    </div>
  </div>

  @if (showMgRequestFromTicketTypes) {
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="select" [ngClass]="{'invalid': isFormSubmitted && !detailsForm.controls['mgRequest'].valid}">
          <hl-selectbox [formControl]="detailsForm.controls['mgRequest']" [options]="mgRequestOptions" [isDisabled]="!equipment"
            [isRequired]="detailsForm.controls['mgRequest'].errors && detailsForm.controls['mgRequest'].errors['required']" label="LABEL_REQUEST_TYPE"
            ngDefaultControl></hl-selectbox>
          @if (isFormSubmitted && !detailsForm.controls['mgRequest'].valid) {
            <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
          }
        </div>
      </div>
    </div>
  }

  @if (showDangerForPatient()) {
    <div class="row">
      <div class="col-12 col-lg-8">
        <label class="control-label" [translate]="'PATIENT_SITUATION'">
        </label>
        <ul formGroupName="dangerForPatientGroup" class="list list--inline bare-list">
          <ul class="list list--inline bare-list">
            @for (dfpOption of dfpOptions; track dfpOption) {
              <li class="list__item">
                <input type="radio" class="radio radio--inline" [id]="dfpOption.value" formControlName="dangerForPatient" [value]="dfpOption.value"
                  (change)="handleDfpChange()"/>
                <label [for]="dfpOption.value" [translate]="dfpOption.title" (click)="selectDfpOption(dfpOption.value)"></label>
              </li>
            }
          </ul>
          @if (showDfpYesQuestions()) {
            <label class="control-label" [translate]="'LABEL_DANGER_FOR_PATIENT_QUESTION_ONE'">
            </label>
            <ul class="list list--inline bare-list">
              <li class="list__item">
                <input type="radio" class="radio radio--inline" [id]="'dfp_question1_yes'" formControlName="dangerForPatientQuestion1"
                  [value]="dfpYesOptions[0].value" (change)="handleDfpChange()"/>
                <label [for]="'dfp_question1_yes'" [translate]="dfpYesOptions[0].title" (click)="selectDfpOption1(dfpYesOptions[0].value)"></label>
              </li>
              <li class="list__item">
                <input type="radio" class="radio radio--inline" [id]="'dfp_question1_no'" formControlName="dangerForPatientQuestion1"
                  [value]="dfpYesOptions[1].value" (change)="handleDfpChange()"/>
                <label [for]="'dfp_question1_no'" [translate]="dfpYesOptions[1].title" (click)="selectDfpOption1(dfpYesOptions[1].value)"></label>
              </li>
            </ul>
            @if (this.isFormSubmitted && !this.detailsForm.controls['dangerForPatientGroup'].get('dangerForPatientQuestion1').valid) {
              <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
            }
            <label class="control-label" [translate]="'LABEL_DANGER_FOR_PATIENT_QUESTION_TWO'">
            </label>
            <ul class="list list--inline bare-list">
              <li class="list__item">
                <input type="radio" class="radio radio--inline" [id]="'dfp_question2_yes'" formControlName="dangerForPatientQuestion2"
                  [value]="dfpYesOptions[0].value" (change)="handleDfpChange()"/>
                <label [for]="'dfp_question2_yes'" [translate]="dfpYesOptions[0].title" (click)="selectDfpOption2(dfpYesOptions[0].value)"></label>
              </li>
              <li class="list__item">
                <input type="radio" class="radio radio--inline" [id]="'dfp_question2_no'" formControlName="dangerForPatientQuestion2"
                  [value]="dfpYesOptions[1].value" (change)="handleDfpChange()"/>
                <label [for]="'dfp_question2_no'" [translate]="dfpYesOptions[1].title" (click)="selectDfpOption2(dfpYesOptions[1].value)"></label>
              </li>
            </ul>
            @if (this.isFormSubmitted && !this.detailsForm.controls['dangerForPatientGroup'].get('dangerForPatientQuestion2').valid) {
              <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
            }
            <div class="row">
              <div class="col-lg-12">
                <hl-textfield label="LABEL_DANGER_FOR_PATIENT_QUESTION_THREE" name="injurySeverity"
                  [formControl]="this.detailsForm.controls['dangerForPatientGroup'].get('dangerForPatientQuestion3')"
                  [maxlength]="maxLengthTicketDfpInjurySeverity"></hl-textfield>
              </div>
            </div>
          }
        </ul>
        @if (isDfpInfoActive) {
          <div class="message message-warn">
            <span [innerHTML]="'TEXT_DANGER_PATIENT_INFORMATION' | translate"></span>
          </div>
        }
      </div>
    </div>
  }

  <div class="row">
    <div class="col-12 col-lg-8">
      <div class="select" [ngClass]="{'invalid': isFormSubmitted && !detailsForm.controls['problemSeverityID'].valid}">
        <hl-selectbox id="operational-state-select-box" [formControl]="detailsForm.controls['problemSeverityID']" [options]="operationalStateTickets"
          [isRequired]="detailsForm.controls['problemSeverityID'].errors && detailsForm.controls['problemSeverityID'].errors['required']"
          [isDisabled]="!equipment" label="GENERIC_LABEL_OPERATIONAL_STATE" (valueChange)="handleStateDownChange()" ngDefaultControl></hl-selectbox>
        @if (isFormSubmitted && !detailsForm.controls['problemSeverityID'].valid) {
          <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
        }
        @if (isStateDownInfoActive) {
          <div class="message message-warn info-margin-top">
            <span [translate]="'TEXT_TICKET_STATE_DOWN_INFORMATION'"></span>
          </div>
        }
      </div>
    </div>
    @if (showSeverityHelperText) {
      <div class="col-12 col-lg-4">
        @if (detailsForm.controls['problemSeverityID']) {
          <div id="operational-state-form-helper-container" class="form__helper-container">
            <p class="form__helper-text" [innerHTML]="operationalStateHelperText | translate"></p>
          </div>
        }
      </div>
    }
  </div>

  <div class="row" hlHelperTextForms>
    <div class="col-12 col-lg-8">
      <hl-textfield label="SHORT_DESCRIPTION" name="description" invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isDisabled]="!equipment"
        [isRequired]="detailsForm.controls['description'].errors && detailsForm.controls['description'].errors['required']"
        [isInvalid]="isFormSubmitted && !detailsForm.controls['description'].valid" [maxlength]="maxLengthTicketShortDescription"
        [formControl]="detailsForm.controls['description']" ngDefaultControl></hl-textfield>
    </div>
    <div class="col-12 col-lg-4">
      @if (equipment) {
        <div class="form__helper-container">
          <p class="form__helper-text" [innerHtml]="'DEFAULT_TEXT_SHORT_DESCRIPTION' | translate"></p>
        </div>
      }
    </div>
  </div>

  <div class="row" hlHelperTextForms [selector]="'textarea__area'">
    <div class="col-12 col-lg-8">
      <hl-textarea [parentForm]="detailsForm" [name]="'longText'" [labelText]="'DETAILED_DESCRIPTION'" [isDisabled]="!equipment"
        [isInvalid]="isFormSubmitted && !detailsForm.controls['longText'].valid"
        [isRequired]="detailsForm.controls['longText'].errors && detailsForm.controls['longText'].errors['required']" [isLarge]="true"
        [maxlength]="maxLengthTicketDescription"></hl-textarea>
      @if (isFormSubmitted && !detailsForm.controls['longText'].valid) {
        <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
      }
    </div>
    <div class="col-12 col-lg-4">
      @if (equipment) {
        <div class="form__helper-container">
          <p class="form__helper-text" [innerHtml]="'DEFAULT_TEXT_DETAILED_DESCRIPTION' | translate"></p>
        </div>
      }
    </div>
  </div>

  @if (showPOField) {
    <div class="row" hlHelperTextForms>
      <div class="col-12 col-lg-8">
        <hl-textfield label="TICKET_FEEDBACK_OWNID" name="ownIncidentNumber" [isDisabled]="!equipment"
          [isRequired]="ownIncidentNumber.errors && ownIncidentNumber.errors['required']" [formControl]="ownIncidentNumber"
          [isInvalid]="isFormSubmitted && !ownIncidentNumber.valid" ngDefaultControl></hl-textfield>
        @if (isFormSubmitted && !ownIncidentNumber.valid) {
          <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
        }
      </div>
      <div class="col-12 col-lg-4">
        <div class="form__helper-container">
          <p class="form__helper-text" [innerHtml]="'MANDATORY_FIELD' | translate"></p>
        </div>
      </div>
    </div>
  }

  <div id="create-ticket-attachment-fieldset"></div>

  @if (showOvertimeAuthorization()) {
    <div class="row">
      <div class="col-12 col-lg-8">
        <label class="additional-text" [translate]="'LABEL_OVERTIME_AUTHORIZATION'"></label>
        <input type="checkbox" class="checkbox" id="optionalAuthorizeOvertime" formControlName="optionalAuthorizeOvertime">
        <label for="optionalAuthorizeOvertime" [translate]="'TEXT_OVERTIME_AUTHORIZATION'"></label>
      </div>
    </div>
  }

  @if (showProtectedCareHours()) {
    <div class="row">
      <div class="col-12 col-lg-8">
        <label class="additional-text" [translate]="'LABEL_CENTRICARE_PROTECTED_HOURS'"></label>
        <input type="checkbox" class="checkbox" id="protectedCareHours" formControlName="protectedCareHours">
        <label for="protectedCareHours" [translate]="'TEXT_CENTRICARE_PROTECTED_HOURS'"></label>
      </div>
    </div>
  }

</div>

<hl-danger-for-patient-modal #dfpModal (onClickOk)="setDfpInfo()"></hl-danger-for-patient-modal>
<hl-state-down-modal #stateDownModal (onClickOk)="setStateDownInfo()"></hl-state-down-modal>
