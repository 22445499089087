import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentsSourceOption } from '../models/equipment/documents/documents-source-option';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { EquipmentDocuments } from '../models/equipment/equipment-documents';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { BatchDownloadParams } from '../models/equipment/documents/batch-download-params';
import { catchError } from 'rxjs/operators';
import { ToasterService } from '../component-communication-services/toaster/toaster.service';
import { WebHttpUrlEncodingCodecService } from '../utils/web-http-url-encoding-codec.service';
import { DownloadUrl } from '../models/download-url';

@Injectable({providedIn: 'root'})
export class DocumentSourceRestService {
  sourceListRestName = restEndPoint + 'source/list';
  docBoxDocumentRestName = restEndPoint + 'source/docbox';
  doxBoxDocumentDownloadRestName = restEndPoint + 'source/docbox/downloadUrl';

  constructor(private http: CacheableHttpClient,
    private toasterService: ToasterService) {
  }

  getDocumentsSourceOptions(equipmentKey: string): Observable<DocumentsSourceOption[]> {
    return this.http.get<DocumentsSourceOption[]>(this.sourceListRestName, {
      params: new HttpParams().set('equipmentKey', equipmentKey)
    });
  }

  getDocumentsFromSpecificSource(sourceUrl: string, key: string, searchQuery?: string): Observable<EquipmentDocuments> {
    const params = new HttpParams({encoder: new WebHttpUrlEncodingCodecService()}).set('equipmentKey', key);
    return this.http.get<EquipmentDocuments>(sourceUrl, {
      params: searchQuery ? params.set('searchQuery', searchQuery) : params
    });
  }

  getResponseFromUrl(url: string): Observable<any> {
    return this.http.get<any>(url, {cache: false});
  }

  headResponseFromUrl(url: string): Observable<HttpResponse<object>> {
    return this.http.head<HttpResponse<object>>(url, {observe: 'response'});
  }

  getAttachmentIdentifier(url: string, params: BatchDownloadParams): Observable<number> {
    return this.http.post<number>(url, params);
  }

  getDocumentsIdentifier(url: string, params: any): Observable<number> {
    return this.http.post<number>(url, params);
  }

  downloadFromUrl(url: string): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(url, {responseType: 'arraybuffer'})
      .pipe(catchError((err) => {
        this.toasterService.emitFrontendGenericErrorMessage();
        this.http.clearCache(url);
        throw err;
      }));
  }

  getDocBoxFileDownloadUrl(identifier: string): Observable<DownloadUrl> {
    return this.http.post<DownloadUrl>(this.doxBoxDocumentDownloadRestName, identifier);
  }

}
