import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SystemUpdatesEquipmentViewModel } from '../../../../core/view-models/system-updates-equipment-view-model';
import { isEqual } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { CountryConfigRestService } from '../../../../core/rest-services/country-config-rest.service';
import { Subject } from 'rxjs';
import { green, red, yellow } from '../../../../core/core-constants.service';
import { CENTRICARE_EQUIPMENT_STATUS_CRITICAL } from '../../../../core/services/equipment/equipment-constants.service';

@Component({
  selector: 'hl-new-system-update-equipment-list-item',
  templateUrl: './new-system-update-equipment-list-item.component.html'
})
export class NewSystemUpdateEquipmentListItemComponent implements OnInit, OnDestroy {
  @Input() item: SystemUpdatesEquipmentViewModel;
  @Input() showPlusButton: boolean;
  @Output() onEquipmentClicked = new EventEmitter<SystemUpdatesEquipmentViewModel>();
  toggleCustomerName = false;
  customerNameLength: number;
  statusColorMap: any = {};
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private countryConfigRestService: CountryConfigRestService) {
  }

  ngOnInit(): void {
    this.countryConfigRestService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.toggleCustomerName = isEqual(config.TOGGLE_CUSTOMER_NAME_TICKET_LIST, 'true');
      this.customerNameLength = parseInt(config.LENGTH_CUSTOMER_NAME_DISPLAY, 10) > 0 ? +config.LENGTH_CUSTOMER_NAME_DISPLAY : undefined;
      this.statusColorMap[config.EQUIPMENT_STATUS_GREEN] = green;
      this.statusColorMap[config.EQUIPMENT_STATUS_RED] = red;
      this.statusColorMap[config.EQUIPMENT_STATUS_YELLOW] = yellow;
      this.statusColorMap[CENTRICARE_EQUIPMENT_STATUS_CRITICAL] = red;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  equipmentClicked(item: SystemUpdatesEquipmentViewModel): void {
    this.onEquipmentClicked.emit(item);
  }
}
