<div class="modal-dialog is-open" [class.modal-dialog--wide]="wide()" tabindex="-1" role="dialog" aria-modal="true">
  <div class="modal-dialog__container">
    @if (title() || hideButtonVisible()) {
      <div class="modal-dialog__header">
        @if (title()) {
          <h3 class="modal-dialog__title">{{title() | translate}}</h3>
        }
        @if (hideButtonVisible()) {
          <button type="button" class="modal-dialog__close" (click)="hideButtonClicked.emit()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        }
      </div>
    }
    <div class="modal-dialog__body">
      <ng-content></ng-content>
    </div>
    @if (footerVisible()) {
      <div class="modal-dialog__footer">
        <ng-content select="modal-footer"></ng-content>
      </div>
    }
  </div>
</div>
