import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../../core/services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { SystemUpdatesRuhBaseModel } from './ruh-base-model';
import { restEndPoint } from '../../core/core-constants.service';
import { DownloadUrl } from "../../core/models/download-url";
import { HttpParams } from "@angular/common/http";
import { DateUtilService } from "../../core/utils/date-util.service";
import { WebHttpUrlEncodingCodecService } from "../../core/utils/web-http-url-encoding-codec.service";

export const ruh = 'ruh/';
export const systemUpdatesRestName = restEndPoint + ruh + 'system-updates-ruh';
export const systemUpdatesDownloadUrlRestName = restEndPoint + ruh + 'download-url';

@Injectable({providedIn: 'root'})
export class SystemUpdatesRuhRestService {

  constructor(private http: CacheableHttpClient,
  private dateUtilService: DateUtilService
) {
  }

  public getSystemUpdates(): Observable<SystemUpdatesRuhBaseModel> {
    const timeZone = this.dateUtilService.getTimeZone();
    const httpParams = new HttpParams({encoder: new WebHttpUrlEncodingCodecService()}).set('tz', timeZone);

    return this.http.get<SystemUpdatesRuhBaseModel>(systemUpdatesRestName, {cache: false, params: httpParams});
  }

  public getUpdateDownloadUrl(updateNumber: string): Observable<DownloadUrl> {
    const httpParams = new HttpParams().set('updateNumber', updateNumber);
    return this.http.get<DownloadUrl>(systemUpdatesDownloadUrlRestName, {
      cache: false,
      params: httpParams
    });
  }
}
