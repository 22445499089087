<p class="color-black-60">{{'DOT_SCHEDULE_TIMEFRAME_SPECIFICATION_DESCRIPTION' | translate}}</p>

<div class="row">
  <div class="col-12 col-md-6">
    <hl-date-picker [formControl]="formGroup().controls.earliestDate" [convertModelValue]="true"
      data-cy="reschedule-date-from" [labelText]="'DOT_SCHEDULE_TIMEFRAME_EARLIEST_DATE'" [minDate]="minDate()"
      [maxDate]="maxDate()" [disableDays]="disableDays()" [clearButtonVisible]="false"
      [isInvalid]="showErrors() && earliestDateError() !== null" [invalidLabel]="earliestDateError()"></hl-date-picker>
  </div>
  <div class="col-12 col-md-6">
    <hl-date-picker [formControl]="formGroup().controls.latestDate" [convertModelValue]="true"
      data-cy="reschedule-date-to" [labelText]="'DOT_SCHEDULE_TIMEFRAME_LATEST_DATE'" [minDate]="minDate()"
      [maxDate]="maxDate()" [disableDays]="disableDays()" [clearButtonVisible]="false"
      [isInvalid]="showErrors() && latestDateError() !== null" [invalidLabel]="latestDateError()"></hl-date-picker>
  </div>
</div>

<span class="color-black-60">{{'DOT_SCHEDULE_TIMEFRAME_SPECIFICATION_ACTIVITIES_TITLE' | translate}}</span>
@if (affectedActivities().length > 1) {
  <hl-alert [indicator]="'info'" [hideButtonVisible]="false">
    {{'DOT_SCHEDULE_TIMEFRAME_SPECIFICATION_ACTIVITIES_MULTIPLE_INFO' | translate}}
  </hl-alert>
}
@for (item of affectedActivities(); track item; let idx = $index) {
  <hl-activity-list
    [item]="item"
    [index]="idx"
    [datePattern]="config().GENERIC_DATE_PATTERN"
    [dateTimePattern]="config().GENERIC_DATE_TIME_PATTERN"
    [toggleCustomerName]="true"
    [customerNameLength]="+config().LENGTH_CUSTOMER_NAME_DISPLAY"
    [showMainListProperties]="true"
    [showActivityStatus]="showActivityStatus(item)"
    [showAppointments]="true"
    [isListWithoutAction]="true"
  ></hl-activity-list>
}
