import { Component, OnInit, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToHtmlPipe } from '../../pipes/to-html/to-html.pipe';

@Component({
  selector: 'hl-equipment-missing-modal',
  templateUrl: './equipment-missing-modal.component.html',
  providers: [ToHtmlPipe]
})
export class EquipmentMissingModalComponent extends BaseFormModalPopup implements OnInit {

  emailText: string;
  attachments: [];
  uploadForm: UntypedFormGroup;

  constructor(
    private equipmentRestService: EquipmentRestService,
    private toasterService: ToasterService,
    private fb: UntypedFormBuilder,
    private toHtmlPipe: ToHtmlPipe,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.uploadForm = this.fb.group({
      attachments: [[]]
    });
  }

  postFormData() {
    this.uploadForm.value.text = this.toHtmlPipe.transform(this.emailText);

    this.equipmentRestService.reportMissingEquipment(this.uploadForm.value).pipe(
      finalize(() => {
        this.isFormSubmitted = false;
        this.emailText = null;
        // close modal
        this.hide();
      }))
      .subscribe({
        next: () => this.toasterService.showTranslatedSuccessToaster('REPORT_MISSING_EQUIPMENT_EMAIL_SUCCESS'),
        error: () => this.toasterService.emitFrontendGenericErrorMessage()
      });
  }
}
