import { TicketActionsComponent } from '../ticket-actions/ticket-actions.component';
import { UpdateTicketEventService } from '../../../core/component-communication-services/update-ticket-event/update-ticket-event.service';
import { Ticket } from '../../../core/models/tickets/ticket';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { TicketViewModel } from '../../../core/view-models/ticket-view-model';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TicketsCacheService } from '../../../core/services/cache/tickets-cache.service';
import { LogService } from '../../../core/services/log/log.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { User } from '../../../core/models/user';
import { StringUtilService } from '../../../core/utils/string-util.service';
import { BaseTicketDetailView } from '../../../core/base-class/base-ticket-detail-view';
import { ticketServiceReportsRestName, ticketsIndividualRestName } from '../../../core/rest-services/ticket-rest-constants.service';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { LogisticRestService } from '../../../core/rest-services/logistic-rest.service';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { TicketsDetailViewModel } from '../../../core/view-models/tickets-detail-view-model';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'hl-tickets-overview',
  templateUrl: './tickets-overview.component.html'
})
export class TicketsOverviewComponent extends BaseTicketDetailView implements OnInit, OnChanges, OnDestroy {

  @ViewChild('actions') actions: TicketActionsComponent;

  // Note:- This is not mandatory and is required only for showing closed ticket in modal/popup
  @Input() closedTicketInput: Ticket;

  currentUser: User;

  // item for ticket actions (update, close, handover)
  ticketItemForActions: TicketViewModel;

  // subscriptions (http & events)
  updateTicketEventSubscription: Subscription;
  refreshItemEventSubscription: Subscription;
  ticketKey: string;

  ticketDetailFields: TicketsDetailViewModel[];
  isTicketStatusClosed: boolean;
  showSurveyLink: boolean;
  surveyLink$: Observable<string>;

  constructor(configService: CountryConfigRestService,
    ticketsUtilService: TicketsUtilService,
    activatedRoute: ActivatedRoute,
    lifeNetUtilService: LifeNetUtilService,
    private updateTicketEventService: UpdateTicketEventService,
    private refreshItemEventService: RefreshItemEventService,
    private userUtilService: UserUtilService,
    ticketsCacheService: TicketsCacheService,
    logService: LogService,
    stringUtilService: StringUtilService,
    private ticketsRestService: TicketsRestService,
    private servicePartRestService: LogisticRestService) {

    super(configService, ticketsUtilService, activatedRoute, lifeNetUtilService, ticketsCacheService,
      logService, stringUtilService);
  }

  ngOnInit() {
    this.init();
    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    if (this.updateTicketEventSubscription) {
      this.updateTicketEventSubscription.unsubscribe();
    }
    if (this.refreshItemEventSubscription) {
      this.refreshItemEventSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init() -> only for closed tickets
    if (changes['closedTicketInput'] && !changes['closedTicketInput'].firstChange) {
      this.init();
    }
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.updateTicketEventSubscription = this.updateTicketEventService.updateTicketSource$.subscribe(() => {
      this.init();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
    this.refreshItemEventSubscription = this.refreshItemEventService.refreshItemSource$.subscribe(() => {
      this.refreshTicket();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
  }

  afterInitProperties(): void {
    this.ticketItemForActions = null;
    this.isTicketStatusClosed = false;
  }

  afterConfigProperties(config): void {
    this.userUtilService.getUser().subscribe(user => {
      this.currentUser = user;
    });
    this.showSurveyLink = isEqual(config.TOGGLE_BATCH_SURVEY_TICKETS, 'true');
  }

  afterLoadViewModelItem(config): void {
    this.isTicketStatusClosed = this.ticketsUtilService.isTicketStatusClosed(this.viewModelTicket.ticketStatus, config);

    this.addTicketAddressToModel(this.ticketsUtilService.selectedTicketAddress);

    this.ticketDetailFields = this.ticketsUtilService.getTicketDetailFields(config, this.viewModelTicket);

    this.surveyLink$ = this.showSurveyLink && this.isTicketStatusClosed
      ? this.ticketsRestService.getTicketSurveyLink(this.viewModelTicket.ticketNumber).pipe(
        map(r => r.surveyLink)
      )
      : of(null);
  }

  action(ticketItem, action) {
    this.ticketItemForActions = ticketItem;
    this.actions.openTicketActionModal(action);
  }

  setViewModelItems(config: any, id: string) {
    this.ticketKey = id;

    if (isEqual(config.SHOW_HPORTAL_ATTACHMENTS, 'true')) {
      const keySeparated = !!id && id.split('_');

      if (keySeparated && keySeparated[1]) {
        this.ticketsRestService.getServiceReportsForTicket(keySeparated[1]).subscribe();
      }
    }

    super.setViewModelItems(config, id);
  }

  /**
   *
   * @description
   * Refresh ticket overview after clearing cache
   */
  refreshTicket() {
    if (this.updateTicketEventSubscription) {
      this.updateTicketEventSubscription.unsubscribe();
    }
    if (this.refreshItemEventSubscription) {
      this.refreshItemEventSubscription.unsubscribe();
    }
    this.isLoaded = false;
    const url = ticketsIndividualRestName.replace(/:id/, this.ticketKey);
    this.ticketsRestService.clearCache(url);
    if (this.viewModelTicket && this.viewModelTicket.ticketNumber) {
      this.servicePartRestService.clearCache(this.viewModelTicket.ticketNumber);

      const urlServiceReports = ticketServiceReportsRestName.replace(/:id/, this.viewModelTicket.ticketNumber);
      this.ticketsRestService.clearCache(urlServiceReports);
    }
    this.ngOnInit();
  }

  private addTicketAddressToModel(address) {
    if (!address) {
      return;
    }
    this.viewModelTicket.locationName = address.locationName;
    this.viewModelTicket.city = address.city;
    this.viewModelTicket.street = address.street;
    this.viewModelTicket.state = address.state;
    this.viewModelTicket.zipCode = address.zipCode;
  }
}
