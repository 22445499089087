<div [formGroup]="shippingForm">
  <!--sao shipping consignee-->
  <div class="row" hlHelperTextForms>
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_SHIPPING_CONSIGNEE" name="receiverName" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        [isRequired]="shippingForm.controls['receiverName'].errors && shippingForm.controls['receiverName'].errors['required']"
        [isInvalid]="isFormSubmitted && !shippingForm.controls['receiverName'].valid" [formControl]="shippingForm.controls['receiverName']"
        ngDefaultControl></hl-textfield>
    </div>
    <div class="col-12 col-lg-4">
      <div class="form__helper-container">
        <p class="form__helper-text" [innerHtml]="'TEXT_SAO_SHIPPING_CONSIGNEE' | translate"></p>
      </div>
    </div>
  </div>
  <!--sao shipping customer name-->
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_SHIPPING_CUSTOMER_NAME" name="customerName" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        [isRequired]="shippingForm.controls['customerName'].errors && shippingForm.controls['customerName'].errors['required']"
        [isInvalid]="isFormSubmitted && !shippingForm.controls['customerName'].valid" [formControl]="shippingForm.controls['customerName']"
        ngDefaultControl></hl-textfield>
    </div>
  </div>
  <!--sao shipping building-->
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_SHIPPING_BUILDING" name="building" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        [isRequired]="shippingForm.controls['building'].errors && shippingForm.controls['building'].errors['required']"
        [isInvalid]="isFormSubmitted && !shippingForm.controls['building'].valid" [formControl]="shippingForm.controls['building']"
        ngDefaultControl></hl-textfield>
    </div>
  </div>
  <!--sao shipping department-->
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_SHIPPING_DEPARTMENT" name="department" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        [isRequired]="shippingForm.controls['department'].errors && shippingForm.controls['department'].errors['required']"
        [isInvalid]="isFormSubmitted && !shippingForm.controls['department'].valid" [formControl]="shippingForm.controls['department']"
        ngDefaultControl></hl-textfield>
    </div>
  </div>
  <!--sao shipping street-->
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_SHIPPING_STREET" name="street" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        [isRequired]="shippingForm.controls['street'].errors && shippingForm.controls['street'].errors['required']"
        [isInvalid]="isFormSubmitted && !shippingForm.controls['street'].valid" [formControl]="shippingForm.controls['street']"
        ngDefaultControl></hl-textfield>
    </div>
  </div>
  <!--sao postal code-->
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_SHIPPING_POSTAL_CODE" name="postalCode" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        [isRequired]="shippingForm.controls['postalCode'].errors && shippingForm.controls['postalCode'].errors['required']"
        [isInvalid]="isFormSubmitted && !shippingForm.controls['postalCode'].valid" [formControl]="shippingForm.controls['postalCode']"
        ngDefaultControl></hl-textfield>
    </div>
  </div>
  <!--sao shipping city-->
  <div class="row">
    <div class="col-12 col-lg-8">
      <hl-textfield label="LABEL_SAO_SHIPPING_CITY" name="city" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
        [isInvalid]="isFormSubmitted && !shippingForm.controls['city'].valid"
        [isRequired]="shippingForm.controls['city'].errors && shippingForm.controls['city'].errors['required']"
        [formControl]="shippingForm.controls['city']" ngDefaultControl></hl-textfield>
    </div>
  </div>
</div>
