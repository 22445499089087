<div class="dispatch-of-tomorrow__schedule-result">
  <i [class]="iconClass()" aria-hidden="true"></i>

  <h5 [innerHTML]="header() | translate | safeDom: 'html'"></h5>
  <p [innerHTML]="message() | translate | safeDom: 'html'"></p>

  @if (status() === 'failure' || status() === 'timeout') {
    <button class="button" (click)="retryButtonClicked.emit()">
      {{retryButtonText() | translate}}
    </button>
  }
</div>
