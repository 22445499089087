<ng-template #customItemTemplate let-model="item">
  <div class="collection__content">
    <div class="media-object">
      <div class="media-object__image list-item__image">
        <hl-equipment-image [equipment]="model" [large]="false"></hl-equipment-image>
      </div>
      <div class="media-object__body">
        <p class="list-item__title margin-bottom-0">{{ model.productName }}</p>
        <ul class="bare-list info-list">
          <li class="info-list__item label-status-{{equipmentStatusColorMap[model.status]}}">{{ model.siemensId }}</li>
          @if (model.myEquipmentName) {
            <li class="info-list__item">
              <i class="icon-equipment-name" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i>
              {{ model.myEquipmentName }}
            </li>
          }
          @if (model.department) {
            <li class="info-list__item">
              <i class="icon-department" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i>
              {{ model.department }}
            </li>
          }
          <li class="info-list__item" *hlItemShowHideHandler="'role';role:'viewPlannedActivityRole'">
            <i class="icon-calendar" hlTooltip [documentOverlay]="true" [title]="'LABEL_ALL_PLANNED_ACTIVITIES' | translate"></i>
            <hl-equipment-actv-count [equipment]="model"></hl-equipment-actv-count>
          </li>
          @if (model.city) {
            <li class="info-list__item">
              <i class="icon-location-pin" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ model.city }}
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
</ng-template>


<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  @if (createTicketForm) {
    <div tabindex="-1" role="dialog" (keydown.esc)="hide()" aria-modal="true" aria-describedby="create-ticket-modal-body">
      <div class="modal-dialog modal-dialog--form is-open">
        <div class="modal-dialog__container" role="document">
          <div class="modal-dialog__header">
            <h3 class="modal-dialog__title" id="modal1_title" [innerHTML]="'CREATE_TICKET_LABEL' | translate" data-cy="create-ticket-title"></h3>
            <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="cancelCreateTicketModal.show()">
              <i aria-hidden="true" class="icon-close"></i>
            </button>
          </div>
          <div class="modal-dialog__body" id="create-ticket-modal-body">
            @if (showValidationMessage) {
              <p class="message message-error">
                <span>{{ translationErrorMessage | translate }}</span>
              </p>
            }
            <hl-progress-indicator [loading]="showSpinner" [showLabel]="true" [label]="ticketLabelInProgress | translate"></hl-progress-indicator>
            @if (!showSpinner) {
              <form [formGroup]="createTicketForm" class="form" novalidate>
                <div class="row">
                  <div class="col-12 col-lg-8">
                    <h5 class="margin-top-0" [translate]="'LABEL_TICKET_CREATE_EQUIPMENT'"></h5>
                  </div>
                </div>
                <!-- Siemens equipment autocomplete select -->
                <div class="row" hlHelperTextForms>
                  <div class="col-12 col-lg-8">
                    <hl-search-field [ngClass]="selectedEquipment ? 'hidden' : ''" searchFieldClasses="textfield--large search-field--taller"
                      [(searchInput)]="searchInput" [autocomplete]="true" [autocompleteItems$]="states"
                      (searchInputChange)="onSearchInputChange($event)" [autocompleteItemTemplate]="customItemTemplate"
                      (autocompleteOnSelect)="onSelect($event)" [useCollection]="true" [dataCy]="'create-ticket'"></hl-search-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    @if (!selectedEquipment) {
                      <div class="form__helper-container">
                        <p class="form__helper-text" [innerHTML]="'TICKET_CREATE_EQUIPMENT_SEARCH_TERM_REQUIRED' | translate"></p>
                      </div>
                    }
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    @if (selectedEquipment) {
                      <div class="collection margin-bottom-1">
                        <div class="collection-item list-item">
                          <div class="media-object width-100">
                            <div class="media-object__image list-item__image">
                              <hl-equipment-image [equipmentKey]="selectedEquipment.key" [large]="false"></hl-equipment-image>
                            </div>
                            <div class="media-object__body">
                              @if (selectedEquipment.productName) {
                                <p class="list-item__title margin-bottom-0">
                                  {{ selectedEquipment.productName }}</p>
                              }
                              <ul class="bare-list info-list margin-bottom-0">
                                <li
                                  class="info-list__item label-status-{{equipmentStatusColorMap[selectedEquipment.status]}}">{{ selectedEquipment.siemensId }}
                                </li>
                                @if (selectedEquipment.myEquipmentName) {
                                  <li class="info-list__item">
                                    <i class="icon-equipment-name" hlTooltip [documentOverlay]="true"
                                      [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i> {{ selectedEquipment.myEquipmentName }}
                                  </li>
                                }
                                @if (selectedEquipment.department) {
                                  <li class="info-list__item">
                                    <i class="icon-department" hlTooltip [documentOverlay]="true"
                                      [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i>
                                    {{ selectedEquipment.department }}
                                  </li>
                                }
                                <li class="info-list__item" *hlItemShowHideHandler="'role';role:'viewPlannedActivityRole'">
                                  <i class="icon-calendar" hlTooltip [documentOverlay]="true"
                                    [title]="'LABEL_ALL_PLANNED_ACTIVITIES' | translate"></i>
                                  <hl-equipment-actv-count [equipment]="selectedEquipment"></hl-equipment-actv-count>
                                </li>
                                @if (selectedEquipment.city) {
                                  <li class="info-list__item">
                                    <i class="icon-location-pin" hlTooltip [documentOverlay]="true"
                                      [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ selectedEquipment.city }}
                                  </li>
                                }
                                <li class="info-list__item" *hlItemShowHideHandler="'config';config:'TOGGLE_CUSTOMER_NAME_EQUIPMENT_LIST'">
                                  <i class="icon-user" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_CUSTOMER_NAME' | translate"></i>
                                  {{ selectedEquipment.customerName }}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="collection__additional-content list-item__toolbar">
                            <div class="button button--link" (click)="clearInput()"><i class="icon-close"></i></div>
                          </div>
                        </div>
                      </div>
                    }
                    @if (showTeamplayEquipmentDescription()) {
                      <div class="row">
                        <div class="col-12 margin-bottom-1">
                          <span [innerHTML]="'LABEL_CREATE_TICKET_TEAMPLAY_EQUIPMENT' | translate "></span>
                        </div>
                      </div>
                    }
                    <!-- open tickets for current selected equipment -->
                    @if (selectedEquipment) {
                      <dl hlCollapsable class="accordion" role="presentation">
                        @if (equipmentTicketsList?.length > 0) {
                          <dt aria-level="3" class="accordion__header">
                            <button id="panel1-button" class="accordion__title icon-arrow-down-large" aria-controls="panel1" aria-expanded="false">
                              <span [translate]="'GENERIC_LABEL_OPEN_TICKETS'"></span>
                            </button>
                          </dt>
                          <dd id="panel1" class="accordion__panel" aria-labelledby="panel1-button" role="region" aria-hidden="true">
                            <hl-create-ticket-list-of-tickets class="margin-bottom-1 display-block"
                              [equipment]="selectedEquipment"></hl-create-ticket-list-of-tickets>
                          </dd>
                        }
                        @if (equipmentContractsList?.length > 0) {
                          <dt aria-level="3" class="accordion__header">
                            <button id="panel2-button" class="accordion__title icon-arrow-down-large" aria-controls="panel2" aria-expanded="false">
                              <span [translate]="'GENERIC_LABEL_CONTRACT_SINGULAR'"></span>
                            </button>
                          </dt>
                          <dd id="panel2" class="accordion__panel" aria-labelledby="panel2-button" role="region" aria-hidden="true">
                            @for (item of equipmentContractsList; track item.contractId) {
                              <div class="collection bg-color-white margin-bottom-1">
                                <hl-contract-list-item [extendClass]="'multi-column-sm-2'" class=" display-block collection-item list-item"
                                  [item]="item"></hl-contract-list-item>
                              </div>
                            }
                          </dd>
                        }
                      </dl>
                    }
                  </div>
                </div>
                <!-- Mobile adress -->
                @if (selectedEquipment && selectedEquipment.mobile) {
                  <div class="row">
                    <div class="col-12 col-lg-8">
                      <h5 class="margin-top-0" [translate]="'LABEL_EQUIPMENT_MOBILE_ADDRESS'"></h5>
                    </div>
                  </div>
                  <hl-create-ticket-mobile-address-area [mobileAddressForm]="createTicketForm.controls['mobileAddress']"
                    [isFormSubmitted]="isFormSubmitted" [equipment]="selectedEquipment"></hl-create-ticket-mobile-address-area>
                }
                <fieldset [disabled]="!selectedEquipment">
                  <!-- Ticket details -->
                  <div class="row">
                    <div class="col-12 col-lg-8">
                      <h5 class="margin-top-0" [translate]="'TICKET_DETAILS'"></h5>
                    </div>
                  </div>
                  <hl-create-ticket-detail-area [equipment]="selectedEquipment" [detailsForm]="createTicketForm.controls['details']"
                    [isFormSubmitted]="isFormSubmitted" [isCentriCareActive]="isCentriCareActive"
                    (operationalStateChanged)="onOperationalStateChange()" [equipmentDetails]="equipmentDetails"
                    [loadingEquipmentDetails]="loadingEquipmentDetails"
                    [ownIncidentNumber]="createTicketForm.controls['request'].get('ownIncidentNumber')"
                    [showPOField]="isPOFieldMandatory"></hl-create-ticket-detail-area>
                  <!-- Availability -->
                  @if (selectedEquipment) {
                    <fieldset
                      *hlItemShowHideHandler="'equipmentAndAvailability';modality:selectedEquipment.modality;ticketTypeCreate:this.getSelectedNotifType()">
                      <div class="row">
                        <div class="col-12 col-lg-8">
                          <hl-create-ticket-availability-area [isFormSubmitted]="isFormSubmitted" [isCentriCareActive]="isCentriCareActive"
                            [selectedProblemSeverityId]="createTicketForm.controls.details.get('problemSeverityID').value"
                            [availabilityForm]="createTicketForm.controls['availability']"></hl-create-ticket-availability-area>
                        </div>
                      </div>
                    </fieldset>
                  }
                  <!-- Contact details-->
                  <hl-contact-area [isDisabled]="!selectedEquipment" [contactForm]="createTicketForm.controls['contact']"
                    [showPhoneNumberExtension]="showPhoneNumberExtension" [isFormSubmitted]="isFormSubmitted"
                    [mandatoryFields]="mandatoryContactFields" [dataCy]="'create-ticket'"></hl-contact-area>
                </fieldset>
                <!-- Ticket Attachment -->
                <ng-container hlDomPortal [hostSelector]="'#create-ticket-attachment-fieldset'" [templateToRender]="templateToRender"></ng-container>
                <ng-template #templateToRender>
                  @if (showTicketAttachmentByEquipment && showTicketAttachmentByType) {
                    <fieldset [disabled]="!selectedEquipment">
                      <div class="row" hlHelperTextForms [selector]="'file-input__button'">
                        <div class="col-12 col-lg-8">
                          <hl-attachment [parentForm]="createTicketForm" [isFormSubmitted]="isFormSubmitted"></hl-attachment>
                        </div>
                        <div class="col-12 col-lg-4">
                          <div class="form__helper-container">
                            <p class="form__helper-text" [innerHTML]="'TEXT_VALIDATION_CHECK_ATTACHMENT' | translate"></p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  }
                </ng-template>
                <!-- Response -->
                @if (showRequestArea) {
                  <fieldset [disabled]="!selectedEquipment">
                    <hl-create-ticket-request-area [showPOField]="!isPOFieldMandatory"
                      [requestForm]="createTicketForm.controls['request']"></hl-create-ticket-request-area>
                  </fieldset>
                }
                <fieldset [disabled]="!selectedEquipment">
                  @if (showEmailToMeOption) {
                    <div class="row">
                      <div class="col-12 col-lg-8">
                        <input id="checkbox-create-ticket-email-me" type="checkbox" class="checkbox" formControlName="emailMe"/>
                        <label for="checkbox-create-ticket-email-me">{{ 'LABEL_CREATE_TICKET_EMAIL_ME' | translate }}</label>
                      </div>
                    </div>
                  }
                  @if (furtherContactsForEmail$ | async; as furtherContacts) {
                    <div class="row">
                      <div class="col-12 col-lg-8">
                        <input id="checkbox-create-ticket-email-further-contacts" type="checkbox" class="checkbox"
                          formControlName="emailFurtherContacts"/>
                        <label
                          for="checkbox-create-ticket-email-further-contacts">{{ 'LABEL_CREATE_TICKET_EMAIL_FURTHER_CONTACTS' | translate }}</label>
                        @if (createTicketForm.controls['emailFurtherContacts'].value) {
                          <div>
                            <small class="margin-bottom-0" [translate]="'LABEL_SELECT_FURTHER_CONTACTS'"></small>
                            <hl-multi-selectbox [options]="furtherContacts" formControlName="furtherContacts" [label]="'LABEL_RECIPIENTS'"
                              [delimiter]="'; '"></hl-multi-selectbox>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </fieldset>
                <!-- show confidential patient data confirmation section -->
                @if (this.showConfidentialDataConfirmation) {
                  <fieldset [disabled]="!selectedEquipment">
                    <hl-create-ticket-confirm-patient-data [isFormSubmitted]="isFormSubmitted"
                      [confirmNoPatientData]="createTicketForm.controls['confirmNoPatientData']"></hl-create-ticket-confirm-patient-data>
                  </fieldset>
                }
              </form>
            }
          </div>
          @if (!showSpinner) {
            <hl-simulator-check [ngModelOptions]="{standalone: true}" [(ngModel)]="isFormSubmitted" (onClickOk)="createTicket()"
              (onClickClose)="cancelCreateTicketModal.show()" [isFormValid]="createTicketForm.valid" [modalRef]="simulatorModal"
              [isFormValidationRequired]="true" [isModalType]="true" [label]="'CREATE_NEW_TICKET'" [isDisabled]="isSubmitFormDisabled()"
              name="isFormSubmitted" (hideParent)="hide()" [parentHasCancelModal]="true" [dataCyCancelButton]="'ticket-create'"
              [dataCySaveButton]="'ticket-create'"></hl-simulator-check>
          }
        </div>
      </div>
    </div>
  }
</ng-template>

<hl-contract-detail-modal #contractDetailModal [equipmentItem]="selectedEquipment"></hl-contract-detail-modal>
<hl-cancel-modal (yesClick)="hide()" [descriptionMessage]="'TICKET_CREATE_CONFIRM_CANCELLATION'" #cancelCreateTicketModal></hl-cancel-modal>
<hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
<hl-create-ticket-disclaimer-popup #disclaimerPopup></hl-create-ticket-disclaimer-popup>
