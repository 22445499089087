<ng-container hlOverlay #overlayPopup="overlay"[overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="simulator-modal">

      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'SIMULATOR_HEADER'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close"
          data-cy="simulator-modal-close-icon" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>

      <div class="modal-dialog__body" id="modal1_desc">
        <div [innerHTML]="'SIMULATOR_POPUP_TEXT' | translate"></div>
      </div>

      <div class="modal-dialog__footer" data-cy="simulator-modal-footer">
        <button class="button" data-cy="simulator-modal-close-btn" (click)="ok()" [translate]="'SIMULATOR_POPUP_BUTTON'"></button>
      </div>

    </div>
  </div>
</ng-template>
