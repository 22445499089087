import { Injectable } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Injectable({
  providedIn: 'root'
})
export class InfiniteScrollerService {
  static resetInfiniteScroller(infiniteScroll: InfiniteScrollDirective) {
    if (infiniteScroll) {
      infiniteScroll.destroyScroller();
      infiniteScroll.setup();
    }
  }
}
