@if (modalities$ | async; as modalities) {
  <ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
  <ng-template #overlayTemplate>
    <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
      <div class="modal-dialog__container" role="document">
        <div class="modal-dialog__header">
          <h3 class="modal-dialog__title" id="modal1_title"
            [translate]="isEditAdvisory? 'LABEL_ADVISORIES_EDIT_ADVISORY':'LABEL_ADVISORIES_CREATE_ADVISORY'"></h3>
          <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="cancelCreateAdvisoryModal.show()">
            <i aria-hidden="true" class="icon-close"></i>
          </button>
        </div>
        <div class="modal-dialog__body" id="modal1_desc">
          @if (!showSpinner) {
            <form [formGroup]="createAdvisoryForm">
              <hl-textfield label="SECURITY_ADVISORY_ID" name="advisoryId" formControlName="advisoryId" [maxlength]="100"
                [isRequired]="advisoryIdControl.errors && advisoryIdControl.errors['required']"
                [isInvalid]="(advisoryIdControl.touched || isFormSubmitted) && !advisoryIdControl.valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
                ngDefaultControl></hl-textfield>
              <hl-textfield label="SECURITY_CVSS_SCORE" name="cvssScore" [formControl]="cvssScoreControl" [maxlength]="10"
                [isRequired]="cvssScoreControl.errors && cvssScoreControl.errors['required']"
                [isInvalid]="(cvssScoreControl.touched || isFormSubmitted) && !cvssScoreControl.valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
                ngDefaultControl></hl-textfield>
              <hl-textfield label="SECURITY_ADVISORY_VERSION" name="version" [formControl]="createAdvisoryForm.controls['version']" [maxlength]="100"
                ngDefaultControl></hl-textfield>
              <hl-multi-selectbox [formControl]="createAdvisoryForm.controls['modality']" [options]="modalities" label="LABEL_MODALITY"
                ngDefaultControl></hl-multi-selectbox>
              <div [ngClass]="{'invalid': isFormSubmitted && !lastUpdatedControl.valid}">
                <hl-date-picker formControlName="lastUpdated" [isRequired]="lastUpdatedControl.errors && lastUpdatedControl.errors['required']"
                  ngDefaultControl [labelText]="'LAST_UPDATED'" required
                  [isInvalid]="(lastUpdatedControl.touched || isFormSubmitted) && (lastUpdatedControl.errors && lastUpdatedControl.errors['required'])"
                  [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"></hl-date-picker>
                @if ((lastUpdatedControl.touched || isFormSubmitted) && (lastUpdatedControl.errors && lastUpdatedControl.errors['wrongDate'])) {
                  <small class="textfield__hint textfield__hint--validation" [translate]="'LABEL_DATE_MUST_BE_TODAY_OR_EARLIER'"></small>
                }
              </div>
              <hl-textarea [parentForm]="createAdvisoryForm" [name]="'cveIds'" [labelText]="'SECURITY_REFERENCE_LINKS'"
                [isLarge]="true"></hl-textarea>
              <hl-textfield label="SECURITY_ADVISORY_TAG" name="tag" [formControl]="createAdvisoryForm.controls['tag']" [maxlength]="100"
                ngDefaultControl></hl-textfield>
              <hl-textfield label="GENERIC_LABEL_TITLE" name="advisoryTitle" [formControl]="titleControl" [maxlength]="100"
                [isRequired]="titleControl.errors && titleControl.errors['required']"
                [isInvalid]="(titleControl.touched || isFormSubmitted) && !titleControl.valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
                ngDefaultControl></hl-textfield>
              <hl-textarea [parentForm]="createAdvisoryForm" [name]="'productsAffected'" [labelText]="'SECURITY_ADVISORIES_PRODUCTS_AFFECTED'"
                [isInvalid]="(productsAffectedControl.touched || isFormSubmitted) && !productsAffectedControl.valid"
                [isRequired]="productsAffectedControl.errors && productsAffectedControl.errors['required']" [isLarge]="true"></hl-textarea>
              @if ((productsAffectedControl.touched || isFormSubmitted) && !productsAffectedControl.valid) {
                <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
              }
              @if (!advisoryLocalizationFeatureToggle) {
                <hl-progress-indicator [loading]="isEditAdvisory && !isPackageInfoLoaded"></hl-progress-indicator>
                @if (isEditAdvisory && isPackageInfoLoaded && attachmentList.length) {
                  <label class="form__label" translate="LABEL_EXISTING_FILES"></label>
                  <div class="collection collection--compact margin-bottom-1">
                    @for (item of attachmentList; track item) {
                      <div class="collection-item collection-item--padding-sides">
                        <div class="collection__content overflow-hidden">
                          <p class="list-item__title list-item__title--break-word">{{ item.name }}</p>
                        </div>
                      </div>
                    }
                  </div>
                }
                <hl-upload #filePackageInfoForm [parentForm]="createAdvisoryForm" [isFormSubmitted]="isFormSubmitted" [checkAttachmentDisable]="true"
                  [hidden]="isEditAdvisory && !isPackageInfoLoaded" [disableAttachmentCount]="1" [parentFormFileControlKey]="FILE_PDF"
                  genericLabelName="LABEL_PDF_FILE"></hl-upload>
                @if (pdfFileControl.errors && pdfFileControl.errors['invalidName']) {
                  <small class="textfield__hint textfield__hint--validation"
                    [translate]="'SYSTEM_UPDATES_UPLOAD_PACKAGE_FILE_NAME_NOT_VALID'"></small>
                }
                <hl-upload #filePackageInfoForm2 [parentForm]="createAdvisoryForm" [isFormSubmitted]="isFormSubmitted" [checkAttachmentDisable]="true"
                  [hidden]="isEditAdvisory && !isPackageInfoLoaded" [disableAttachmentCount]="1" [parentFormFileControlKey]="FILE_TXT"
                  genericLabelName="LABEL_TXT_FILE"></hl-upload>
                @if (txtFileControl.errors && txtFileControl.errors['invalidName']) {
                  <small class="textfield__hint textfield__hint--validation"
                    [translate]="'SYSTEM_UPDATES_UPLOAD_PACKAGE_FILE_NAME_NOT_VALID'"></small>
                }
              }
              <div [ngClass]="{'invalid': (isFormSubmitted || activationDateControl.touched) && !activationDateControl.valid}">
                <hl-date-picker formControlName="activationDate"
                  [isRequired]="activationDateControl.errors && activationDateControl.errors['required']" ngDefaultControl
                  [labelText]="'SECURITY_ADVISORY_ACTIVATION_DATE'" required
                  [isInvalid]="(activationDateControl.touched || isFormSubmitted) && !activationDateControl.valid"
                  [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"></hl-date-picker>
              </div>
              @if (isEditAdvisory) {
                <input id="enable-mail" type="checkbox" formControlName="enableMail" class="checkbox"/>
                <label for="enable-mail" translate="SECURITY_ADVISORY_ENABLE_MAIL"></label>
              }
            </form>
          } @else {
            <hl-progress-indicator [showLabel]="true" [label]="advisoryLabelInProgress | translate"></hl-progress-indicator>
          }
        </div>
        @if (!showSpinner) {
          <div class="modal-dialog__footer">
            <button class="button" (click)="cancelCreateAdvisoryModal.show()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
            <button class="button button--primary" (click)="createOrEditAdvisory()" [disabled]="isSaveDisabled$ | async"
              [translate]="isEditAdvisory? 'GENERIC_LABEL_SAVE':'BUTTON_ADVISORY_CREATE'"></button>
          </div>
        }
      </div>
    </div>
    <hl-cancel-modal (yesClick)="resetAndHide()"
      [descriptionMessage]="isEditAdvisory? 'ADVISORY_EDIT_CONFIRM_CANCELLATION':'ADVISORY_CREATE_CONFIRM_CANCELLATION'"
      #cancelCreateAdvisoryModal></hl-cancel-modal>
  </ng-template>
}
