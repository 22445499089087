import { Component, OnInit } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { SelectOption } from '../../../core/models/select-option';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hl-create-sao-download',
  templateUrl: './create-sao-download.component.html'
})
export class CreateSaoDownloadComponent implements OnInit {
  links: Observable<SelectOption[]>;

  constructor(private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.links = this.configService.getConfig().pipe(
      map(config =>
        config?.SAO_DOWNLOAD_LINKS?.split(',').map(item => {
          const linkOption = item.split('#');
          return { title: linkOption[0], value: linkOption[1] };
        }) ?? []
      )
    );
  }
}
