import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { ActivatedRoute } from '@angular/router';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogService } from '../../../core/services/log/log.service';
import { StringUtilService } from '../../../core/utils/string-util.service';
import { BaseItemDetailView } from '../../../core/base-class/base-item-detail-view';
import { finalize } from 'rxjs/operators';
import { NotifStatus } from '../../../core/core-constants.service';
import { ActivitiesDetail } from '../../../core/models/activities/activities-detail';
import { ActivitiesUtilService } from '../../../core/services/activities/activities-util.service';
import { ActivitiesCacheService } from '../../../core/services/cache/activities-cache.service';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';
import { Subscription } from 'rxjs';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';

@Component({
  selector: 'hl-activities-event-logs',
  templateUrl: './activities-event-logs.component.html'
})
export class ActivitiesEventLogsComponent extends BaseItemDetailView<ActivitiesDetail> implements OnInit, OnDestroy {

  activitiesDetail: ActivitiesDetail;

  activitiesUtilService: ActivitiesUtilService;
  activitiesCacheService: ActivitiesCacheService;
  activitiesRestService: ActivitiesRestService;
  id: string;
  refreshItemEventSubscription: Subscription;

  constructor(configService: CountryConfigRestService,
    activitiesUtilService: ActivitiesUtilService,
    activatedRoute: ActivatedRoute,
    lifeNetUtilService: LifeNetUtilService,
    activitiesCacheService: ActivitiesCacheService,
    logService: LogService,
    stringUtilService: StringUtilService,
    activitiesRestService: ActivitiesRestService,
    private refreshItemEventService: RefreshItemEventService) {
    super(configService, lifeNetUtilService, activatedRoute, logService, stringUtilService);
    this.activitiesUtilService = activitiesUtilService;
    this.activitiesCacheService = activitiesCacheService;
    this.activitiesRestService = activitiesRestService;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.refreshItemEventSubscription = this.refreshItemEventService.refreshItemSource$.subscribe(() => {
      this.refreshItem();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
    super.init();
  }

  ngOnDestroy(): void {
    if (this.refreshItemEventSubscription) {
      this.refreshItemEventSubscription.unsubscribe();
    }
  }

  afterInitProperties(): void {
    this.activitiesDetail = null;
  }

  afterConfigProperties(config: any) {
    // this is empty as this is implementation of abstract method, but there is no need to do anything here
  }

  afterLoadViewModelItem(config: any): void {
    // this is empty as this is implementation of abstract method, but there is no need to do anything here
  }

  /**
   * @param config - country specific configs as object
   * @param id - ticket key
   *
   * @description sets the view model for list view
   * i.e. sub tabs in activities main navigation
   */
  setViewModelItems(config: any, id: string) {
    this.activitiesUtilService.getSingleActivity(id, this.getNotifStatus())
      .subscribe((response) => {
        let customerId: string;
        if (response && response.customerId) {
          customerId = response.customerId;
        } else {
          customerId = this.activitiesCacheService.getCustomerId();
        }
        this.id = id;
        this.activitiesUtilService.getActivityDetails(id, customerId)
          .pipe(finalize(() => this.isLoaded = true))
          .subscribe((activityResponse) => {
            if (activityResponse) {
              this.activitiesDetail = activityResponse;
              this.setViewModelProperties(config, activityResponse);
            } else {
              // do nothing - show empty details...
              this.logService.debug('no matching activity found for id: ' + id);
            }
          }, (some) => {
            this.logService.debug('what is the problem? ' + some);
          });
      });
  }

  getNotifStatus(): NotifStatus {
    return this.activitiesCacheService.getSelectedOpenClosedStatus();
  }

  /**
   *
   * @description
   * Refresh activity event logs after clearing cache
   */
  refreshItem() {
    if (this.refreshItemEventSubscription) {
      this.refreshItemEventSubscription.unsubscribe();
    }
    this.isLoaded = false;
    const url = this.activitiesRestService.activitiesDetailsRestName.replace(/:id/g, this.id);
    this.activitiesRestService.clearCache(url);
    this.init();
  }
}
