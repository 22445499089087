<div class="">
  <p class="lead" [translate]="'TITLE_SAO_OVERLAY'"></p>
</div>
<div data-cy="sao-entry-page">
  <h5 class="margin-bottom-0" [translate]="'LABEL_SAO_ORDER_TITLE'"></h5>
  <hl-read-more [maxLength]="250" [textTranslation]="'TEXT_SAO_ORDER_TITLE'"></hl-read-more>

  <h5 class="margin-bottom-0" [translate]="'LABEL_SAO_ORDER_DESCRIPTION'"></h5>
  <hl-read-more [maxLength]="250" [textTranslation]="'TEXT_SAO_DESCRIPTION'"></hl-read-more>

  <h5 class="margin-bottom-0" [translate]="'LABEL_SAO_ADDITIONAL_OPTION'"></h5>
  <hl-read-more [maxLength]="250" [textTranslation]="'TEXT_SAO_ADDITIONAL_OPTION'"></hl-read-more>

  <h5 class="margin-top-0" [translate]="'LABEL_SAO_DOWNLOAD'"></h5>
  <hl-create-sao-download></hl-create-sao-download>

  <button class="button button--primary margin-top-1" type="button" (click)="createSaModal.show()" [translate]="'BUTTON_SAO_REQUEST'" data-cy="sao-request-button"></button>
</div>

<hl-create-sa-modal [equipmentId]="equipmentKey" #createSaModal></hl-create-sa-modal>

