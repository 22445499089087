import { TicketViewModel } from '../../../core/view-models/ticket-view-model';
import { TicketCloseModalComponent } from '../../modal-popup/ticket-close-modal/ticket-close-modal.component';
import { TicketUpdateModalComponent } from '../../modal-popup/ticket-update-modal/ticket-update-modal.component';
import { Component, Input, ViewChild } from '@angular/core';

import { isEqual } from 'lodash-es';
import { TicketSurveyModalComponent } from '../../modal-popup/ticket-survey-modal/ticket-survey-modal.component';

@Component({
  selector: 'hl-ticket-actions',
  templateUrl: './ticket-actions.component.html'
})
export class TicketActionsComponent {

  @ViewChild('updateModal') updateModal: TicketUpdateModalComponent;
  @ViewChild('handoverModal') handoverModal: TicketUpdateModalComponent;
  @ViewChild('closeModal') closeModal: TicketCloseModalComponent;
  @ViewChild('surveyModal') surveyModal: TicketSurveyModalComponent;

  @Input() viewModelTicket: TicketViewModel;

  openTicketActionModal(actionType) {
    setTimeout(() => {

      if (isEqual(actionType, 'convert')) {
        this.handoverModal.show();
      } else if (isEqual(actionType, 'update')) {
        this.updateModal.show();
      } else if (isEqual(actionType, 'close')) {
        this.closeModal.show();
      } else if (isEqual(actionType, 'openSurvey')) {
        this.surveyModal.show();
      }
    }, 100);
  }
}
