import { Injectable } from '@angular/core';
import { DocumentSourceRestService } from 'app/core/rest-services/document-source-rest.service';
import { Observable } from 'rxjs';
import { exhaustMap, filter, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentDetailService {

  constructor(private documentSourceRestService: DocumentSourceRestService,) { }

  getDocboxDownloadUrl(identifiers: string): Observable<string> {
    return this.documentSourceRestService.getDocBoxFileDownloadUrl(identifiers).pipe(
      exhaustMap(response => 
        this.documentSourceRestService.headResponseFromUrl(response.url).pipe(
          filter(resp => resp.status >= 200 && resp.status < 400),
          mapTo(response.url)))
    );
  }
}
