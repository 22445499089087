import { DestroyRef, Directive, inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { OverlayDirective } from '../../shared/directives/overlay-popup/overlay.directive';

@Directive()
export abstract class BaseModalPopup implements OnDestroy {
  showSpinner = false;
  protected bodyId: string;
  protected readonly destroyRef = inject(DestroyRef);

  @ViewChild('overlayPopup')
  overlayDirective: OverlayDirective;

  protected constructor(protected renderer: Renderer2) {
  }

  // this method need to be implemented by derived class
  abstract ok(): void;

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy() {
    this.overlayDirective?.hide();
  }

  show(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.renderer.addClass(document.body, 'overflow-hidden');
    this.overlayDirective.show();
  }

  hide() {
    this.overlayDirective?.hide();
    this.showSpinner = false;
    this.renderer.removeClass(document.body, 'overflow-hidden');
    if (this.bodyId) {
      Array.from(document.querySelectorAll(`#${this.bodyId}`)).forEach(e => {
        if (typeof e.scrollTo !== 'undefined') {
          e.scrollTo(0, 0);
        }
      });
    }
  }
}
