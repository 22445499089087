import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isEqual } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hl-create-ticket-request-area',
  templateUrl: './create-ticket-request-area.component.html'
})
export class CreateTicketRequestAreaComponent implements OnInit, OnDestroy {

  @Input() requestForm: UntypedFormGroup;
  @Input() showPOField = true;

  showTicketOwnIncidentNumber = false;
  showRequestArea = false;
  private readonly unsubscribe$ = new Subject<void>();
  otherFeedbackOption = undefined;
  otherFeedbackValue = 'other';

  constructor(private configService: CountryConfigRestService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.configurePropBasedOnCountryConfig();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  configurePropBasedOnCountryConfig() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      // feedback requested
      this.requestForm.get('feedBack').reset();
      this.requestForm.patchValue({feedBack: configResponse.FEEDBACK_REQUESTED_PRESELECTED});

      // check for render ticket own incident number
      const ticketOwnIncidentNumber =
        configResponse.TICKET_SHOW_OWN_INCIDENT_NUMBER_CREATE;
      if (isEqual(ticketOwnIncidentNumber, 'true')) {
        // show ticket own incident number in request area
        this.showTicketOwnIncidentNumber = true;
      }

      // check to show the Request Feedback via fields
      this.showRequestArea = isEqual(configResponse['TICKET_SHOW_RESPONSE_AREA'], 'true');
    });
    this.translateService.get('REQUEST_FEEDBACK_ADDITIONAL_OPTION').subscribe(translateResponse => {
      this.otherFeedbackOption = translateResponse;
    });
  }

  selectFeedBack(value: string) {
    this.requestForm.get('feedBack').patchValue(value);
  }

  selectOtherFeedBack(otherFeedbackValue: string, otherFeedbackOption: any) {
    this.requestForm.get('feedBack').patchValue(otherFeedbackValue);
    this.translateService.get('TICKET_FEEDBACK_REQUESTED_VIA').subscribe(translateResponse => {
      this.requestForm.get('feedBackOtherText').patchValue(translateResponse + ' ' + otherFeedbackOption);
    });
  }
}
