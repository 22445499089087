<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--wide modal-dialog--form is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">

      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="isEditUpdate? 'EDIT_SYSTEM_UPDATE':'CREATE_NEW_SYSTEM_UPDATE'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="cancelUploadUpdateModal.show()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body update-scroll-container" id="create-edit-update-modal-body">
        <hl-progress-indicator [loading]="showSpinner" showlabel="false"></hl-progress-indicator>
        <div [hidden]="showSpinner">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h5 class="margin-top-0" [translate]="'SYSTEM_UPDATE_CREATE_NEW_EQUIPMENT'"></h5>
              <hl-create-new-equipment-for-update #createNewEquipmentForUpdateComponent
                (onNewEquipmentAdded)="addEquipmentToUpdate($event)"></hl-create-new-equipment-for-update>
            </div>
            <div class="col-12 col-lg-6">
              <h5 class="margin-top-0" [translate]="'SYSTEM_UPDATE_INFORMATION'"></h5>
              <hl-system-update-information-form [form]="updateInformationForm" [isFormSubmitted]="isFormSubmitted" [isEditUpdate]="isEditUpdate"
                [asuStatusOptions]="asuStatusOptions"></hl-system-update-information-form>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6">
              <h5 class="margin-top-0" [translate]="'SYSTEM_UPDATE_EQUIPMENT_SELECTION'"></h5>
              <hl-equipment-selection-for-new-system-update #equipmentSelectionComponent
                (equipmentAddedToTheUpdate)="addEquipmentToUpdate($event)"></hl-equipment-selection-for-new-system-update>
            </div>
            <div class="col-12 col-lg-6">
              <h5 class="margin-top-0" [translate]="'SYSTEM_UPDATE_EQUIPMENT_ADDED'"></h5>
              <hl-equipment-added-to-new-system-update #addedEquipmentsComponent [equipmentAddedToUpdate]="equipmentAddedToUpdate"
                (equipmentRemovedFromUpdate)="addEquipmentToEquipmentSelection($event)"></hl-equipment-added-to-new-system-update>
            </div>
          </div>
        </div>
      </div>

      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button" (click)="cancelUploadUpdateModal.show()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
          <button class="button button--primary" (click)="postFormData()" [translate]="isEditUpdate? 'EDIT_SYSTEM_UPDATE':'CREATE_NEW_SYSTEM_UPDATE'"
            [disabled]="equipmentAddedToUpdate.length === 0"></button>
        </div>
      }
    </div>
  </div>

  <hl-cancel-modal (yesClick)="hide()" #cancelUploadUpdateModal
    [descriptionMessage]="isEditUpdate? 'SYSTEM_UPDATES_EDIT_CONFIRM_CANCELLATION':'SYSTEM_UPDATES_CREATE_NEW_CONFIRM_CANCELLATION'"></hl-cancel-modal>
</ng-template>
