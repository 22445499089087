import { ImpersonationCommunicationService } from '../../../core/component-communication-services/impersonation/impersonation-communication.service';
import { ContactRestService } from '../../../core/rest-services/contact-rest.service';
import { AdditionalContacts } from '../../../core/models/additional-contacts';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isEqual } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InfoModalComponent } from 'app/shared/modal-popup/info-modal/info-modal.component';

@Component({
  selector: 'hl-additional-contacts',
  templateUrl: './additional-contacts.component.html'
})
export class AdditionalContactsComponent implements OnInit, OnDestroy {

  @ViewChild('infoModal')
  infoModal: InfoModalComponent;

  isContactCollapsed: boolean;
  additionalContacts: AdditionalContacts[];
  private readonly unsubscribe$ = new Subject<void>();

  public isShown = false;

  constructor(
    private configService: CountryConfigRestService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private contactsRestService: ContactRestService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @description Initialize of call stack
   */
  init() {
    this.emitUnsubscribe();
    this.isContactCollapsed = true;
    this.additionalContacts = [];
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      // get the additional contacts
      if (
        isEqual(configResponse.SHOW_CUSTOMER_CONTACT_IN_CONTACT_BOX, 'true')
      ) {
        this.contactsRestService
          .getAdditionalContacts()
          .subscribe(contactsResponse => {
            this.additionalContacts = contactsResponse;
          });
      }
    });
    this.registerEventListeners();
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.init();
      }
    );
  }

  show() {
    this.infoModal.show();
  }

  private emitUnsubscribe() {
    if (this.unsubscribe$.observed) {
      this.unsubscribe$.next();
    }
  }
}
