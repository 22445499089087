<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--wide is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title">
          @if (!isEditGroup) {
            <span [translate]="'HEADLINE_CREATE_CUSTOMER_GROUP'"></span>
          } @else {
            <span [translate]="'HEADLINE_EDIT_CUSTOMER_GROUP'"></span>
          }
        </h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body" id="modal1_desc" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10"
        [scrollWindow]="false" (scrolled)="loadMore()" [immediateCheck]="true" [alwaysCallback]="true">
        <hl-progress-indicator [loading]="showSpinner" [showLabel]="true" [label]="groupCreationInProgressLabel | translate"></hl-progress-indicator>
        @if (isCustomerListLoaded && !showSpinner) {
          <form [formGroup]="createGroupForm">
            <fieldset>
              <p class="font-weight-bold" [translate]="'LABEL_GROUP_DETAILS'" style="margin-bottom: 0.5rem;"></p>
              <div class="row">
                <hl-textfield class="col-12 display-block" [ngClass]="isUserAdmin ? 'col-lg-6' : ''"
                  [isRequired]="createGroupForm.controls['name'].errors && createGroupForm.controls['name'].errors['required']"
                  label="LABEL_GROUP_NAME" name="name" invalidLabel="ERROR_VALIDATION_GROUP_NAME"
                  [isInvalid]="createGroupForm.controls['name'].touched && !createGroupForm.controls['name'].valid"
                  [formControl]="createGroupForm.controls['name']" ngDefaultControl></hl-textfield>
                @if (isUserAdmin) {
                  <div class="col-12 col-lg-6">
                    <p class="form__label" [translate]="'LABEL_GROUP_VISIBILITY'"></p>
                    <div class="button-group">
                      <button class="button toggle-button" [class.is-active]="createGroupForm.controls['type'].value === 'PRIVATE'"
                        (click)="clickGroupTypeButton('PRIVATE')" [disabled]="isRadioBtnActive === 1"
                        [translate]="'LABEL_GROUP_VISIBILITY_PRIVATE'"></button>
                      <button class="button toggle-button" [class.is-active]="createGroupForm.controls['type'].value === 'PUBLIC'"
                        (click)="clickGroupTypeButton('PUBLIC')" [translate]="'LABEL_GROUP_VISIBILITY_PUBLIC'"></button>
                    </div>
                  </div>
                }
              </div>
              @if (showGroupHint && isRadioBtnActive === 0) {
                <div class="alert">
                  <div class="alert__body" [translate]="'WARNING_GROUP_SWITCH_TO_PUBLIC'"></div>
                </div>
              }
            </fieldset>
            <div class="row">
              <fieldset class="col-12 col-lg-6 order-lg-2">
                <p class="font-weight-bold" [translate]="'LABEL_GROUP_ADDED_CUSTOMERS'" style="margin-bottom: 0.5rem;"></p>
                <hl-search-field [(searchInput)]="searchInputCustomer" (searchInputChange)="onCustomerSearchInput()"
                  searchFieldClasses="textfield--large" placeholder="{{'SEARCH_TERM' | translate}}"></hl-search-field>
                <hl-list [additionalCollectionClass]="'collection--compact'" [items]="listOfAddedGroupCustomers"
                  [isLoaded]="impersonatedCustomersLoaded" [sortConfig]="impersonatedCustomerSortSkeleton"
                  (onSortOptionChange)="onCustomerSortChange($event)" label="LABEL_GROUP_COUNT_CUSTOMERS" empty="NO_CUSTOMERS_TO_IMPERSONATE">
                  <ng-template let-item>
                    <div class="collection-item">
                      <div class="collection__content">
                        <p class="list-item__title">{{ item.customerName }}
                          <hl-is-ld-customer [customerId]="item.customerId"></hl-is-ld-customer>
                        </p>
                        <ul class="bare-list info-list">
                          <li class="info-list__item">
                            <i class="icon-qr-bar-code" hlTooltip [title]="'USER_IMPERSONATION_SORTING_CUSTOMER_NUMBER' | translate"></i>
                            {{ item.customerId | numberFromIdPipe }}
                          </li>
                          <li class="info-list__item">
                            <i class=" icon-department" hlTooltip [title]="'GENERIC_LABEL_CITY' | translate"></i>
                            {{ item.city }}
                          </li>
                          <li class="info-list__item">
                            <i class="icon-world" hlTooltip [title]="'LABEL_COUNTRY' | translate"></i>
                            {{ item.countryLabel }}
                          </li>
                        </ul>
                      </div>
                      <div class="collection__additional-content list-item__toolbar">
                        <button class="button button--control button--small" (click)="removeCustomerFromGroup(item)"><i class="icon-minus"></i>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </hl-list>
              </fieldset>
              <fieldset class="col-12 col-lg-6 order-lg-1">
                <p class="font-weight-bold" [translate]="'LABEL_GROUP_CUSTOMERS'" style="margin-bottom: 0.5rem;"></p>
                <hl-search-field [(searchInput)]="searchInput" (searchInputChange)="onAdvancedFilterChange()" searchFieldClasses="textfield--large"
                  placeholder="{{'SEARCH_TERM' | translate}}"></hl-search-field>
                <hl-list [items]="customerVMList" [additionalCollectionClass]="'collection--compact'" [isLoaded]="isCustomerListLoaded"
                  [sortConfig]="impersonatedCustomerSortSkeleton" (onSortOptionChange)="onSortChange($event)" label="GENERIC_LABEL_CUSTOMERS"
                  empty="NO_CUSTOMERS_TO_IMPERSONATE" [filteredLengthWithoutPagination]="listWithoutPaginationLength">
                  <ng-template let-item>
                    <div class="collection-item">
                      <div class="collection__content">
                        <p class="list-item__title">{{ item.customerName }}
                          <hl-is-ld-customer [customerId]="item.customerId"></hl-is-ld-customer>
                        </p>
                        <ul class="bare-list info-list">
                          <li class="info-list__item">
                            <i class="icon-qr-bar-code" hlTooltip [title]="'USER_IMPERSONATION_SORTING_CUSTOMER_NUMBER' | translate"></i>
                            {{ item.customerId | numberFromIdPipe }}
                          </li>
                          <li class="info-list__item">
                            <i class=" icon-department" hlTooltip [title]="'GENERIC_LABEL_CITY' | translate"></i>
                            {{ item.city }}
                          </li>
                          <li class="info-list__item">
                            <i class="icon-world" hlTooltip [title]="'LABEL_COUNTRY' | translate"></i>
                            {{ item.countryLabel }}
                          </li>
                        </ul>
                      </div>
                      <div class="collection__additional-content list-item__toolbar">
                        <button class="button button--control button--small" (click)="addCustomerToGroup(item)"><i class="icon-plus"></i></button>
                      </div>
                    </div>
                  </ng-template>
                </hl-list>
              </fieldset>
            </div>
          </form>
        }

      </div>

      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button button--primary" id="button-group-change" (click)="ok()"
            [disabled]="rawListAddedGroupCustomers.length === 0 || isFormSubmitted || !createGroupForm.controls['name'].valid">
            @if (!isEditGroup) {
              <span [translate]="'BUTTON_GROUP_CREATE'"></span>
            } @else {
              <span [translate]="'BUTTON_GROUP_UPDATE'"></span>
            }
          </button>
          <button class="button" (click)="hide()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
        </div>
      }

    </div>
  </div>
</ng-template>
<hl-verify-public-group-modal #verifyPublicModal [isEditGroup]="isEditGroup" (onOk)="callCreateOrEditGroupRequest()"></hl-verify-public-group-modal>
