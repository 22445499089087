import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    if (args.some(arg => !arg?.includes('/'))) {
      throw new Error(`Invalid args: ${JSON.stringify(args)}`);
    }
    return args.map(replacement => {
      const parts = replacement.split('/');
      return {search: parts[0], replace: parts[1]};
    }).reduce((acc, {search, replace}) => acc.replaceAll(search, replace), value ?? '');
  }

}
