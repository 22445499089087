<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title">{{ title }}</h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide(true)">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div id="modal1_desc" class="modal-dialog__body">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          <form #modalForm="ngForm" novalidate>
            <div class="modal-body">
              <p class="message message-confirm">{{ text }}</p>
            </div>
          </form>
        }
      </div>
      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button type="submit" class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_YES'"
            data-cy="delete-favorite-confirm"></button>
          <button type="button" class="button button" data-dismiss="modal-dialog" (click)="hide(true)" [translate]="'GENERIC_LABEL_NO'"></button>
        </div>
      }
    </div>
  </div>
</ng-template>
