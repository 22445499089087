<p class="color-black-60" hlDotLegacyRescheduleNav [innerHTML]="'DOT_SCHEDULE_APPOINTMENT_SELECTION_LEGACY_TEXT' | translate">
</p>

<hl-alert [indicator]="'info'" [hideButtonVisible]="false">
  {{'DOT_SCHEDULE_APPOINTMENT_SELECTION_BUFFER_TIME' | translate}}
</hl-alert>

<div class="margin-top-1 padding-left-1 padding-right-1">
  <hl-sequential-item-picker [formControl]="selectedInterval" [items]="availableIntervals()">
    <ng-template let-range>
      {{range.fromDate | datePipeWrapper: datePattern()}} - {{range.toDate | datePipeWrapper: datePattern()}}
    </ng-template>
  </hl-sequential-item-picker>

  <div class="dispatch-of-tomorrow__time-slot-wrapper margin-top-1">
    @if (timeSlots() !== null) {
      <h5>{{'DOT_SCHEDULE_APPOINTMENT_SELECTION_TIME_SLOTS_HEADER' | translate}}</h5>

      @for (timeSlot of timeSlots(); track timeSlot; let index = $index) {
        <div class="time-slot">
          <input type="radio" class="radio" name="time-slot" [id]="'time-slot-' + index" [value]="timeSlot" [formControl]="control()">
          <label [for]="'time-slot-' + index">
            <hl-dot-time-slot-label [timeSlot]="timeSlot" [config]="config()"></hl-dot-time-slot-label>
          </label>
        </div>
      } @empty {
        <hl-alert [hideButtonVisible]="false">
          {{'DOT_SCHEDULE_APPOINTMENT_SELECTION_TIME_SLOTS_EMPTY' | translate}}
        </hl-alert>
      }

      @if (showErrors() && isControlInvalid()) {
        <small class="selectbox__hint selectbox__hint--validation margin-top-0 margin-bottom-0">
          {{'GENERIC_LABEL_SELECT_FIELD' | translate}}
        </small>
      }
    } @else {
      <div class="padding-top-2">
        <hl-progress-indicator [includeMargin]="false"></hl-progress-indicator>
      </div>
    }
  </div>
</div>
