import { StateDownModalComponent } from '../../modal-popup/state-down-modal/state-down-modal.component';
import { DangerForPatientModalComponent } from '../../modal-popup/danger-for-patient-modal/danger-for-patient-modal.component';
import { TicketTypes } from '../../../core/models/tickets/ticket-types';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { find, forEach, includes, isEmpty, isEqual, split } from 'lodash-es';
import { TicketTypeProblemSeverityRel } from '../../../core/models/tickets/modality-type-ticket-type-rel';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';
import { CentriCareSeverities, DangerForPatients, EquipmentModalityType, mgRequestUndefined } from '../../../core/core-constants.service';
import { CreateTicketEventService } from '../../../core/component-communication-services/create-ticket-event/create-ticket-event.service';
import { SelectOption } from '../../../core/models/select-option';
import { LogService } from '../../../core/services/log/log.service';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EquipmentDetails } from '../../../core/models/equipment/equipment-details';
import { TeamplayRestService } from '../../../core/rest-services/teamplay-rest.service';
import { TeamplayApplicationOptions } from '../../../core/models/teamplay-application-options';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';
import { RemoveLeadingZeroPipe } from '../../pipes/removeLeadingZero/remove-leading-zero.pipe';

@Component({
  selector: 'hl-create-ticket-detail-area',
  templateUrl: './create-ticket-detail-area.component.html'
})
export class CreateTicketDetailAreaComponent implements OnInit, OnChanges, OnDestroy {
  // we will pass in detail from Create component
  @Input()
  detailsForm: UntypedFormGroup;
  @Input()
  ownIncidentNumber: UntypedFormControl;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  isCentriCareActive = false;
  @Input()
  equipmentDetails: EquipmentDetails;
  @Input()
  loadingEquipmentDetails = false;
  @Input()
  showPOField = false;
  @Output()
  operationalStateChanged = new EventEmitter();

  @ViewChild('dfpModal')
  dfpModal: DangerForPatientModalComponent;
  @ViewChild('stateDownModal')
  stateDownModal: StateDownModalComponent;

  private _equipment: EquipmentViewModel;
  private initCalled = false;
  private isEnvConfigLoaded = false;

  get equipment(): EquipmentViewModel {
    return this._equipment;
  }

  @Input()
  set equipment(equipmentViewModel: EquipmentViewModel) {
    this._equipment = equipmentViewModel;
    this.loadTeamplayApplicationOptions(equipmentViewModel);
    this.loadTicketTypes();
    if (this.initCalled) {
      this.changeDetector.detectChanges();
    }
  }

  showDangerForPatientsFromConfig = false;
  showDangerForPatientsFromEquipmentType = false;
  showDangerForPatientsFromTicketType = false;
  showMgRequestFromTicketTypes = false;
  showSeverityHelperText = false;
  operationalStateHelperText: string;
  overtimeAuthorizationEnabled = false;
  isDfpInfoActive = false;
  isDfpYesAllowed = false;
  isStateDownInfoActive = false;
  showStateDownWarning = false;
  commaSeparatedAllowedDfpTicketTypes: string[] = [];
  selectedTeamplayApplication: string;

  ticketTypes: TicketTypes[] = [];
  dropdownOptions: DropdownOptions[] = [];
  teamplayApplicationOptions: DropdownOptions[] = [];
  components = [];
  operationalStateTickets = [];
  dfpOptions: SelectOption[] = [];
  dfpYesOptions: SelectOption[] = [];
  mgRequestOptions: SelectOption[] = [];
  mgRequestTicketTypes: string[] = [];
  equipmentModalityType: string;
  selectedTicketTypeSeverityRelations: TicketTypeProblemSeverityRel[];
  maxLengthTicketDescription: string;
  maxLengthTicketShortDescription: string;
  maxLengthTicketDfpInjurySeverity: number;
  teamplayEquipmentMaterialNumber: string;
  isTeamplayEquipmentSelected = false;
  loadingTeamplayApplicationOptions = false;
  private readonly unsubscribe$ = new Subject<void>();
  private readonly inVitroEquipmentType = 'INVITRO';

  constructor(
    private renderer: Renderer2,
    private configService: CountryConfigRestService,
    private environmentConfigService: EnvironmentConfigRestService,
    private ticketsUtilService: TicketsUtilService,
    private ticketsRestService: TicketsRestService,
    private createTicketEventService: CreateTicketEventService,
    private logService: LogService,
    private equipmentUtilService: EquipmentUtilService,
    private teamplayRestService: TeamplayRestService,
    private removeLeadingZeroPipe: RemoveLeadingZeroPipe,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.init();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: Event): void {
    const helperTextContainer = document.getElementById('operational-state-form-helper-container');
    if (!helperTextContainer) {
      return;
    }
    if (event.composedPath().filter(p => p instanceof HTMLElement && p.id === 'operational-state-select-box').length > 0) {
      if (this.detailsForm.get('problemSeverityID').value) {
        this.renderer.addClass(helperTextContainer, 'is-visible');
      }
    } else if (this.stateDownModal.elementRef.nativeElement.firstChild.ariaHidden === 'true') {
      this.renderer.removeClass(helperTextContainer, 'is-visible');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['equipment'] && !changes['equipment'].firstChange) {
      this.init();
    }
    this.mapComponents();
  }

  mapComponents() {
    if (this._equipment && this.equipmentDetails && this.equipmentDetails.components) {
      this.components = this.equipmentDetails.components.map(component => ({
        value: component.componentID,
        title: `${component.componentName}<br/>${component.componentSerialNumber}, ${component.componentOwnID}`
      }));
      this.components.sort((a, b) => a.title.localeCompare(b.title));
      if (this.components.length > 0) {
        this.components.push({value: null, title: '&nbsp;<br>&nbsp;'});
      }
    } else {
      this.components = null;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.initCalled = true;
    this.detailsForm.get('typeID').patchValue(null);
    this.selectedTeamplayApplication = null;
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.setConfigProperties(configResponse);
    });
    this.environmentConfigService.getEnvironmentConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.teamplayEquipmentMaterialNumber = configResponse.TEAMPLAY_TENANT_MATERIAL_NUMBER;
      this.isEnvConfigLoaded = true;
      this.equipment = this._equipment;
      this.changeDetector.detectChanges();
    });
  }

  setConfigProperties(config: any) {
    this.maxLengthTicketDescription =
      config.MAX_LENGTH_TICKET_DETAIL_DESCRIPTION;
    this.maxLengthTicketShortDescription =
      config.MAX_LENGTH_TICKET_SHORT_DESCRIPTION;
    this.maxLengthTicketDfpInjurySeverity =
      parseInt(config.MAX_LENGTH_TICKET_DANGER_FOR_PATIENT_INJURY_SEVERITY);

    // check for render danger for patient
    const dangerForPatient = config.TICKET_CREATION_DANGER_FOR_PATIENT_RENDER;
    if (isEqual(dangerForPatient, 'true')) {
      // show the dangerForPatients -> 1st criteria
      this.showDangerForPatientsFromConfig = true;
    }

    // check the default value for danger for patient
    const defaultDanger = config.TICKET_DANGER_FOR_PATIENT_DEFAULT_VALUE;
    if (isEqual(defaultDanger, 'true')) {
      // set in form data correct value
      this.detailsForm.patchValue({dangerForPatient: DangerForPatients.TRUE});
    }

    this.commaSeparatedAllowedDfpTicketTypes = split(
      config.DANGER_FOR_PATIENT_ALLOWED_TICKET_TYPES,
      ','
    );

    this.showStateDownWarning = isEqual(
      config.SHOW_TICKET_STATE_DOWN_WARNING,
      'true'
    );
    this.overtimeAuthorizationEnabled = isEqual(
      config.SHOW_OVERTIME_AUTHORIZATION,
      'true'
    );
    this.showSeverityHelperText = isEqual(
      config.FEATURE_TOGGLE_SEVERITY_GHOST_TEXT,
      'true'
    );

    this.isDfpYesAllowed = isEqual(config.TICKET_CREATION_DANGER_FOR_PATIENT_QUESTIONS_RENDER, 'true');

    this.handleDfpBasedOnEquipmentType();
    this.handleOnSelectedTicketType();

    // should be initialized before loading the ticket types
    this.ticketTypes = [];
    this.dropdownOptions = [];
    if (this._equipment) {
      this.equipmentModalityType = this.ticketsUtilService.getEquipmentModalityType(
        this._equipment.modality,
        config
      );
    }

    this.setDfpOptions(config);
    this.setDfpYesOptions();

    // loading operational state (problem severity)
    this.operationalStateTickets = [];
    this.loadOperationalState();

    this.setMgRequestTicketTypesAndOptions(config);
  }

  /**
   * Sets the ticket types(e.g. MS, MS6) options based upon Selected modality type (e.g. DEFAULT, INVITRO) of equipment.
   * On select of ticket type it sets the ticketType and problemSeverity relations to global variable (selectedTicketTypeSeverityRelations),
   * which is later used to set problem severity options on selection of ticket type.
   *
   * @description
   * Get the ticket types mapping
   */
  loadTicketTypes() {
    if (this._equipment && this.isEnvConfigLoaded) {
      this.ticketsRestService
        .getTicketTypesAndSeverityOptions(this.isTeamplayEquipmentSelected ? 'teamplay' : 'create')
        .subscribe(options => {
          let ticketTypesAndSeverityOptions = find(options, {
            modalityType: this.equipmentModalityType
          });
          if (isEmpty(ticketTypesAndSeverityOptions)) {
            ticketTypesAndSeverityOptions = find(options, {
              modalityType: this.isTeamplayEquipmentSelected ? EquipmentModalityType.TEAMPLAY : EquipmentModalityType.DEFAULT
            });
          }
          if (isEmpty(ticketTypesAndSeverityOptions)) {
            this.logService.error('Possibly modality type DEFAULT not in DB');
            return;
          }
          this.selectedTicketTypeSeverityRelations =
            ticketTypesAndSeverityOptions?.ticketTypesSevRel;
          this.ticketTypes = [];
          forEach(this.selectedTicketTypeSeverityRelations, ticketTypeRel => {
            if (ticketTypeRel.ticketType) {
              this.ticketTypes.push(ticketTypeRel.ticketType);
            }
          });
          this.loadDropdownOptions();
        });
    }
  }

  loadDropdownOptions() {
    this.dropdownOptions = this.ticketTypes
      .map(type => ({
        value: type.typeId,
        title: type.typeDescription
      }));
  }

  loadOperationalState() {
    if (this._equipment) {
      this.ticketsUtilService
        .getOperationalStatesAsOption(
          this.selectedTicketTypeSeverityRelations,
          this.detailsForm.get('typeID').value
        )
        .subscribe(problemSeverityResponse => {
          this.operationalStateTickets =
            problemSeverityResponse
              .filter(o => this.isCentriCareActive ? o.value !== CentriCareSeverities.PARTIALLY_OPERATIONAL
                : ![CentriCareSeverities.PARTIALLY_OPERATIONAL_CRITICAL,
                  CentriCareSeverities.PARTIALLY_OPERATIONAL_NON_CRITICAL].includes(o.value));
        });
    }
  }

  /**
   * @description
   * Show/Hide danger for patient based on equipment type
   * i.e. LdEquipment
   */
  handleDfpBasedOnEquipmentType() {
    if (this._equipment) {
      const isLdEquipment = this.equipmentUtilService.checkIsLdEquipment(
        this._equipment.key
      );
      this.showDangerForPatientsFromEquipmentType = !isLdEquipment;

      // set the form value to false, it is required for BE.
      if (isLdEquipment) {
        this.detailsForm.patchValue({
          dangerForPatient: DangerForPatients.FALSE
        });
      }

      // if dfp is preselected directly handle modal opening
      if (this.isDfpYesSelected()) {
        this.handleDfpChange();
      }
    }
  }

  onTicketTypeChange() {
    this.handleOnSelectedTicketType();
    this.loadOperationalState();
    this.detailsForm.get('problemSeverityID').reset();
    this.createTicketEventService.emitTicketTypeChanged(
      this.detailsForm.get('typeID').value
    );
  }

  onComponentChange() {
    if (!this.detailsForm.get('componentID').value) {
      this.detailsForm['componentText'] = null;
      return;
    }
    const selectedComponent = this.components.filter(component => component.value === this.detailsForm.get('componentID').value);
    if (selectedComponent && selectedComponent.length > 0) {
      this.detailsForm['componentText'] = selectedComponent[0].title.replace('<br/>', ', ');
    }
  }

  /**
   * called initially when the config is set and called, when the user changes the value
   * in the ticketType dropDown.
   * This method displays or hides the danger for patients depending on the selected ticket type.
   */
  handleOnSelectedTicketType() {
    this.showDangerForPatientsFromTicketType = includes(
      this.commaSeparatedAllowedDfpTicketTypes,
      this.detailsForm.get('typeID').value
    );
    if (!this.showDangerForPatientsFromTicketType) {
      this.detailsForm.patchValue({
        dangerForPatient: DangerForPatients.FALSE
      });
      this.handleDfpChange();
    }

    this.showMgRequestFromTicketTypes = includes(
      this.mgRequestTicketTypes,
      this.detailsForm.get('typeID').value
    );
    // validators required for mgRequestDropdown,
    // thus need to set with some value in some cases where it is not shown
    if (!this.showMgRequestFromTicketTypes) {
      this.detailsForm.patchValue({mgRequest: mgRequestUndefined});
    } else {
      this.detailsForm.patchValue({mgRequest: null});
    }
  }

  handleDfpChange() {
    // if dfp, open modal
    if (
      this.isDfpYesSelected() && !this.isDfpYesAllowed
    ) {
      this.dfpModal.show();
    } else {
      // reset dfp info
      this.isDfpInfoActive = false;
    }

    if (this.showDfpYesQuestions()) {
      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion1').patchValue(null);
      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion2').patchValue(null);
      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion3').patchValue('');

      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion1').enable();
      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion2').enable();
    } else {
      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion1').disable();
      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion2').disable();
    }
  }

  setDfpInfo() {
    this.isDfpInfoActive = true;
  }

  isDfpYesSelected(): boolean {
    return isEqual(
      this.detailsForm.get('dangerForPatientGroup').get('dangerForPatient').value,
      DangerForPatients.TRUE
    );
  }

  showDfpYesQuestions(): boolean {
    return this.isDfpYesSelected() && this.isDfpYesAllowed;
  }

  handleStateDownChange() {
    this.setSeverityHelperText();
    // if state down and state down warning configured, open modal
    if (isEqual(this.detailsForm.get('problemSeverityID').value, '1') &&
      this.showStateDownWarning
    ) {
      this.stateDownModal.show();
    } else {
      // reset state down info
      this.isStateDownInfoActive = false;
    }
    this.operationalStateChanged.emit();
  }

  private setSeverityHelperText() {
    const selectedSeverity = this.detailsForm.get('problemSeverityID').value;
    this.operationalStateHelperText = selectedSeverity ? `LABEL_GHOST_TEXT_ID_${selectedSeverity}` : '';
  }

  setStateDownInfo() {
    this.isStateDownInfoActive = true;
  }

  showDangerForPatient() {
    return (
      this.showDangerForPatientsFromConfig &&
      this.showDangerForPatientsFromEquipmentType &&
      this.showDangerForPatientsFromTicketType
    );
  }

  setDfpOptions(config: any) {
    const dfpOptions = [{
      value: DangerForPatients.TRUE,
      title: 'TICKET_FILTER_PATIENTSITUATIONCRITICAL_TRUE'
    }, {
      value: DangerForPatients.FALSE,
      title: 'TICKET_FILTER_PATIENTSITUATIONCRITICAL_FALSE'
    }];
    if (isEqual(config.DANGER_FOR_PATIENT_UNKNOWN_OPTION, 'true')) {
      dfpOptions.push({
        value: DangerForPatients.UNKNOWN,
        title: 'LABEL_DANGER_FOR_PATIENT_UNKNOWN'
      });
    }
    this.dfpOptions = dfpOptions;
  }

  setDfpYesOptions() {
    this.dfpYesOptions = [{
      value: DangerForPatients.TRUE,
      title: 'GENERIC_LABEL_YES'
    }, {
      value: DangerForPatients.FALSE,
      title: 'GENERIC_LABEL_NO'
    }];
  }

  setMgRequestTicketTypesAndOptions(config: any) {
    this.mgRequestOptions = [];
    const optionsAndValues = config.MG_REQUEST_TYPE_OPTION_VALUES.split(',');
    if (this._equipment && this.equipmentModalityType === this.inVitroEquipmentType) {
      const indexOfOther = optionsAndValues.findIndex(item => item === 'Other:#Other');
      if (indexOfOther > -1) {
        optionsAndValues.splice(indexOfOther, 1);
      }
    }
    forEach(optionsAndValues, option => {
      const optionAndValue = option.split('#');
      this.mgRequestOptions.push({
        value: optionAndValue[0],
        title: optionAndValue[1]
      });
    });

    this.mgRequestTicketTypes = config.MG_REQUEST_TICKET_TYPES.split(',');
  }

  selectDfpOption(value: any) {
    this.detailsForm.get('dangerForPatientGroup').get('dangerForPatient').patchValue(value);
    this.handleDfpChange();
  }

  selectDfpOption1(value: any) {
    this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion1').patchValue(value);
  }

  selectDfpOption2(value: any) {
    this.detailsForm.get('dangerForPatientGroup').get('dangerForPatientQuestion2').patchValue(value);
  }

  showOvertimeAuthorization(): boolean {
    return this.overtimeAuthorizationEnabled && !this.isCentriCareActive;
  }

  showProtectedCareHours(): boolean {
    return this.isCentriCareActive
      && isEqual(this.detailsForm.get('problemSeverityID').value, CentriCareSeverities.PARTIALLY_OPERATIONAL_NON_CRITICAL);
  }

  loadTeamplayApplicationOptions(equipment: EquipmentViewModel) {
    if (equipment) {
      this.isTeamplayEquipmentSelected =
        this.removeLeadingZeroPipe.transform(equipment.materialNumber) === this.teamplayEquipmentMaterialNumber;

      if (this.isTeamplayEquipmentSelected) {
        this.detailsForm.get('teamplayApplication').addValidators(Validators.required);
        this.detailsForm.get('teamplayApplication').updateValueAndValidity();
        this.loadingTeamplayApplicationOptions = true;
        this.teamplayRestService.getTeamplayApplication(equipment.serialNumber)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (response: TeamplayApplicationOptions) => this.setTeamplayApplicationOptions(response.options),
            () => this.setTeamplayApplicationOptions([])
          );
        return;
      }
    }

    this.isTeamplayEquipmentSelected = false;
    this.detailsForm?.get('teamplayApplication').removeValidators(Validators.required);
    this.detailsForm?.get('teamplayApplication').updateValueAndValidity();
  }

  private setTeamplayApplicationOptions(options: string[]) {
    this.teamplayApplicationOptions = options.concat('Other').map(option => ({
      title: option,
      value: option
    }));
    if (this.teamplayApplicationOptions.length === 1) {
      this.detailsForm.patchValue({teamplayApplication: this.teamplayApplicationOptions[0].value});
    }
    this.loadingTeamplayApplicationOptions = false;
    this.changeDetector.detectChanges();
  }
}
