import { Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { AdvisoryAttachment } from 'app/core/models/securityAdvisories/advisory-attachment';
import { UploadComponent } from 'app/shared/form-group/upload/upload.component';
import { forkJoin, Observable, of } from 'rxjs';
import { exhaustMap, finalize, map } from 'rxjs/operators';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { SecurityAdvisory } from '../../../core/models/security-advisory';
import { SelectOption } from '../../../core/models/select-option';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { SecurityAdvisoriesRestService } from '../../../core/rest-services/security-advisories-rest.service';
import { SecurityAdvisoriesService } from '../../../core/services/security-advisories/security-advisories-service';
import { WindowService } from '../../../core/window.service';
import { AdvisoryUploadFileValidator } from './advisory-upload-file.validator';
import { isEqual, sortBy } from 'lodash-es';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { MessagingCenterService } from '../../../core/services/messaging-center/messaging-center.service';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { dateIsTodayOrInThePast } from '../../validators/no-future-date.validator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-create-advisory-modal',
  templateUrl: './create-advisory-modal.component.html'
})
export class CreateAdvisoryModalComponent extends BaseModalPopup
  implements OnInit, OnDestroy {

  @ViewChild('filePackageInfoForm')
  pdfUpload: UploadComponent;
  @ViewChild('filePackageInfoForm2')
  txtUpload: UploadComponent;

  FILE_PDF = 'pdfFile';
  FILE_TXT = 'txtFile';
  DATE_FORMAT = 'DD-MM-YYYY';

  createAdvisoryForm: UntypedFormGroup;
  showValidationMessage = false;
  isFormSubmitted = false;
  isEditAdvisory = false;
  id = undefined;
  isPackageInfoLoaded = false;
  advisoryLocalizationFeatureToggle = false;
  window = this.windowService.nativeWindow;
  attachmentList: AdvisoryAttachment[] = [];

  isSaveDisabled$: Observable<boolean>;

  originalFormValues: {
    advisoryId: string,
    cvssScore: string
    version: string,
    modality: string,
    lastUpdated: string,
    cveIds: string,
    tag: string,
    title: string,
    productsAffected: string,
    pdfFile: string [],
    txtFile: string [],
    activationDate: string
  };

  advisoryLabelInProgress = 'ADVISORY_CREATION_IN_PROGRESS';
  @Output()
  close = new EventEmitter();
  modalities$: Observable<SelectOption[]>;

  private advisoryCreationMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private advisoriesRestService: SecurityAdvisoriesRestService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private windowService: WindowService,
    private equipmentRestService: EquipmentRestService,
    private messagingCenterService: MessagingCenterService,
    private securityAdvisoriesService: SecurityAdvisoriesService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private configService: CountryConfigRestService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.loadModalities();
    this.createForm();
    this.configService.getConfig().subscribe((configResponse: any) => {
      this.advisoryLocalizationFeatureToggle = isEqual(configResponse.FEATURE_TOGGLE_SECURITY_ADVISORY_LOCALIZATION_ATT, 'true');
    });
    this.translateService.get('LABEL_ADVISORY_SUCCESS').subscribe(advisoryCreationMessage => {
      this.advisoryCreationMessage = advisoryCreationMessage;
    });

    this.securityAdvisoriesService.showCreateAdvisoryModalEmitter
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        response => {
          if (response) {
            this.loadAttachments(response);
            this.createAdvisoryForm.patchValue(response);
            this.isEditAdvisory = true;
            this.originalFormValues = {...this.createAdvisoryForm.getRawValue()};
            this.originalFormValues.lastUpdated = this.datePipeWrapperPipe
              .transform(this.createAdvisoryForm.controls['lastUpdated'].value, this.DATE_FORMAT);
            this.originalFormValues.activationDate = this.datePipeWrapperPipe
              .transform(this.createAdvisoryForm.controls['activationDate'].value, this.DATE_FORMAT);
            this.originalFormValues.cvssScore = '' + this.createAdvisoryForm.controls['cvssScore'].value;
            this.originalFormValues.modality = '' + this.createAdvisoryForm.controls['modality'].value.sort();
            this.id = response.id;
          } else {
            this.createAdvisoryForm.reset();
            this.isEditAdvisory = false;
          }
        }
      );

    this.isSaveDisabled$ = this.createAdvisoryForm.valueChanges.pipe(
      map(v => !!this.originalFormValues && this.isSaveDisabled(v))
    );
  }

  ngOnDestroy() {
    super.destroy();
  }

  ok() {
    // implement abstract method
  }

  resetAndHide() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.removeFiles();
    this.createAdvisoryForm.reset();
    this.createAdvisoryForm.markAsUntouched();
    this.isPackageInfoLoaded = false;
    this.attachmentList = [];
    this.hide();
  }

  show() {
    super.show();
    this.createAdvisoryForm.markAsUntouched();
  }

  removeFiles() {
    if (this.pdfUpload) {
      this.pdfUpload.removeFiles();
    }
    if (this.txtUpload) {
      this.txtUpload.removeFiles();
    }
  }

  private createForm() {
    this.createAdvisoryForm = this.fb.group({
        advisoryId: ['', [Validators.required]],
        cvssScore: ['', [Validators.required, Validators.min(0.0), Validators.max(10.0),
          Validators.pattern(/^\d\d?(\.\d)?$/)]],
        version: [''],
        modality: [[]],
        lastUpdated: [null, [Validators.required, dateIsTodayOrInThePast]],
        cveIds: [''],
        tag: [''],
        title: ['', [Validators.required]],
        productsAffected: ['', [Validators.required]],
        pdfFile: [[], AdvisoryUploadFileValidator.pdfFileValidator],
        txtFile: [[], AdvisoryUploadFileValidator.txtFileValidator],
        activationDate: [null, [Validators.required]],
        enableMail: [false]
      }
    );
  }

  loadModalities() {
    this.modalities$ = this.equipmentRestService
      .getModalityGBCmdbDescriptions()
      .pipe(
        map((modalityResponse) =>
          sortBy(modalityResponse)
            .filter(descriptionCmdb => descriptionCmdb)
            .map(descriptionCmdb => ({
              title: descriptionCmdb,
              value: descriptionCmdb
            }))
        )
      );
  }

  loadAttachments(advisory: SecurityAdvisory) {
    this.securityAdvisoriesService.getAttachmentsList(advisory.id, 'en').subscribe(response => {
      this.attachmentList = response;
      this.isPackageInfoLoaded = true;
    });
  }

  isSaveDisabled(formValue) {
    const actualFormValues = {...formValue};
    actualFormValues.lastUpdated = this.datePipeWrapperPipe
      .transform(actualFormValues.lastUpdated, this.DATE_FORMAT);
    actualFormValues.activationDate = this.datePipeWrapperPipe
      .transform(actualFormValues.activationDate, this.DATE_FORMAT);
    actualFormValues.cvssScore = '' + actualFormValues.cvssScore;
    actualFormValues.modality = '' + actualFormValues.modality?.sort();

    return isEqual(this.originalFormValues, actualFormValues);
  }

  createOrEditAdvisory() {
    this.isFormSubmitted = true;
    if (this.createAdvisoryForm.invalid) {
      this.showValidationMessage = true;
    } else {
      this.advisoriesRestService
        .createOrEditAdvisory(this.createRequestBody(), this.isEditAdvisory)
        .pipe(
          exhaustMap((response: any[]) => {
              response.push({url: ''});
              return forkJoin(response.map(resp => {
                return this.uploadFileToAzureStorage(resp);
              }));
            }
          ),
          finalize(() => {
            this.resetAndHide();
            this.messagingCenterService.emitRefreshMessagingCenter();
          }),
          takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.toasterService.showNotTranslatedSuccessToaster(this.advisoryCreationMessage);
          this.securityAdvisoriesService.emitAdvisoryUpdated();
        });
    }
  }

  createRequestBody() {
    let value = Object.assign({}, this.createAdvisoryForm.value);
    delete value.pdfFile;
    delete value.txtFile;
    this.showValidationMessage = false;
    this.showSpinner = true;
    const pdfFile = this.createAdvisoryForm.value.pdfFile?.[0];
    if (pdfFile?.filetype) {
      value = {...value, pdfFileName: pdfFile.filename};
    }
    const txtFile = this.createAdvisoryForm.value.txtFile?.[0];
    if (txtFile?.filetype) {
      value = {...value, txtFileName: txtFile.filename};
    }
    const lastUpdated = this.datePipeWrapperPipe.transform(value.lastUpdated, this.DATE_FORMAT);
    const activationDate = this.datePipeWrapperPipe.transform(value.activationDate, this.DATE_FORMAT);
    value = {...value, lastUpdated, activationDate};
    if (this.isEditAdvisory) {
      value.id = this.id;
    } else {
      value.enableMail = true;
    }
    return value;
  }

  uploadFileToAzureStorage(file) {
    if (file.url.includes('pdf')) {
      return this.securityAdvisoriesService.uploadAttachment(file.url, this.createAdvisoryForm.value.pdfFile[0].file);
    }

    if (file.url.includes('txt')) {
      return this.securityAdvisoriesService.uploadAttachment(file.url, this.createAdvisoryForm.value.txtFile[0].file);
    }
    return of({});
  }

  get advisoryIdControl() {
    return this.createAdvisoryForm.controls['advisoryId'];
  }

  get cvssScoreControl() {
    return this.createAdvisoryForm.controls['cvssScore'];
  }

  get titleControl() {
    return this.createAdvisoryForm.controls['title'];
  }

  get productsAffectedControl() {
    return this.createAdvisoryForm.controls['productsAffected'];
  }

  get pdfFileControl() {
    return this.createAdvisoryForm.controls['pdfFile'];
  }

  get txtFileControl() {
    return this.createAdvisoryForm.controls['txtFile'];
  }

  get lastUpdatedControl() {
    return this.createAdvisoryForm.controls['lastUpdated'];
  }

  get activationDateControl() {
    return this.createAdvisoryForm.controls['activationDate'];
  }
}
