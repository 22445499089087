<div class="form-horizontal" [formGroup]="mobileAddressForm">

  @if (mobileAddresses.length > 0) {
    <div class="form-group row">
      <div class="col-12 col-lg-8">
        <ul class="list list--inline bare-list">
          <li class="list__item">
            <input id="existingMobileAddress" type="radio" formControlName="type" class="radio radio--inline" value="existing" (change)="onChangeRadioBtn()">
            <label for="existingMobileAddress" [translate]="'BUTTON_MOBILE_EXISTING_ADDRESS'"></label>
          </li>
          <li class="list__item">
            <input id="newMobileAddress" type="radio" formControlName="type" class="radio radio--inline" value="new" (change)="onChangeRadioBtn()">
            <label for="newMobileAddress" [translate]="'BUTTON_MOBILE_NEW_ADDRESS'"></label>
          </li>
        </ul>
      </div>
    </div>
  }

  @if (isInputNewAddress) {
    <div class="form-group row">
      <div class="col-12 col-lg-8">
        <hl-textfield
          label="LABEL_LOCATION_NAME"
          name="locationName"
          [formControl]="mobileAddressForm.controls['locationName']"
          invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
          [isInvalid]="isFormSubmitted && !mobileAddressForm.controls['locationName'].valid"
          [maxlength]="35"
          ngDefaultControl>
        </hl-textfield>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-8">
        <hl-textfield
          label="GENERIC_LABEL_STREET"
          name="street"
          [formControl]="mobileAddressForm.controls['street']"
          invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
          [isInvalid]="isFormSubmitted && !mobileAddressForm.controls['street'].valid"
          [maxlength]="35"
          ngDefaultControl>
        </hl-textfield>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-8">
        <hl-textfield
          label="GENERIC_LABEL_CITY"
          name="city"
          [formControl]="mobileAddressForm.controls['city']"
          invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
          [isInvalid]="isFormSubmitted && !mobileAddressForm.controls['city'].valid"
          [maxlength]="35"
          ngDefaultControl>
        </hl-textfield>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-8">
        <div class="select" [ngClass]="{'invalid': isFormSubmitted && !mobileAddressForm.controls['state'].valid}">
          <hl-selectbox [formControl]="mobileAddressForm.controls['state']" [options]="states" label="GENERIC_LABEL_STATE" ngDefaultControl></hl-selectbox>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-8">
        <hl-textfield
          label="GENERIC_LABEL_POSTAL_CODE"
          name="zip"
          [formControl]="mobileAddressForm.controls['zip']"
          invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
          [isInvalid]="isFormSubmitted && !mobileAddressForm.controls['zip'].valid"
          [maxlength]="10"
          ngDefaultControl>
        </hl-textfield>
      </div>
    </div>
  } @else {
    <div class="row">
      <div class="col-12 col-lg-8 select" [ngClass]="{'invalid': isFormSubmitted && !mobileAddressForm.controls['addressId'].valid}">
        <hl-selectbox [options]="mobileAddressesOptions" label="LABEL_EQUIPMENT_MOBILE_ADDRESS" [(value)]="selectedDropdownAddressValue"
        (valueChange)="onChangeDropdownSelect()"></hl-selectbox>
        @if (isFormSubmitted && !mobileAddressForm.controls['addressId'].valid) {
          <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
        }
      </div>
    </div>
  }
</div>
