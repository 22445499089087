import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { SprGraphConfiguration, SprWidget } from '../../../core/models/spr/configuration-spr';
import { SprService } from '../../../spr/spr.service';
import { graphConfigurationTypes } from '../../../spr/configuration/spr-configuration-constants';

@Component({
  selector: 'hl-visibility-confirmation-modal',
  templateUrl: './visibility-confirmation-modal.component.html'
})
export class VisibilityConfirmationModalComponent  extends BaseModalPopup implements OnInit{

  @Output() noClick = new EventEmitter();
  @Output() yesClick = new EventEmitter();
  @Output() hideClick = new EventEmitter();
  @Input() title;
  @Input() text;
  widget: SprWidget;
  sprService: SprService;
  internalOnly: boolean;
  constructor(renderer: Renderer2, sprService: SprService) {
    super(renderer);
    this.sprService = sprService;
  }
  ok() {
    this.showSpinner = true;
    if(this.widget) {
      const graphConfigurations: SprGraphConfiguration[] = this.widget?.graphConfigurations;
      graphConfigurations?.filter(config => {
        if(config.key === graphConfigurationTypes.INTERNAL_ONLY) {
          config.value = this.internalOnly.toString()
        }
      });
    }
    this.yesClick.emit();
  }

  close() {
    super.hide();
    this.noClick.emit();
  }

  hide() {
    super.hide();
    this.hideClick.emit();
  }

  handleChangeVisibilityOption(event: Event) {
    this.internalOnly = (event && (event.target as HTMLInputElement).checked);
  }

  ngOnInit(): void {
    this.sprService.setVisibilityForWidget.subscribe(widget => {
      this.widget = widget;
      this.internalOnly = this.sprService.isInternalOnly(widget)
    });
  }
}
