@if (milestonesUtilService.isTicketTypeAllowed(ticketType)) {
  <div>
    <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
    @if (deliveryMilestones?.length > 0) {
      <div>
        @if (hasMoreItemsThanOne()) {
          @if (showCollapseAll()) {
            <button class="button button--small" (click)="collapseAll()" [translate]="'LABEL_CALENDAR_CLOSE_ALL'"></button>
          }
          @if (showExpandAll()) {
            <button class="button button--small" (click)="expandAll()" [translate]="'LABEL_CALENDAR_OPEN_ALL'"></button>
          }
        }
        <section class="datalist overflow-hidden">
          <dl hlCollapsable [isCollapsible]="hasMoreItemsThanOne()" [title]="'.collapsable__title'" class="accordion" role="presentation">
            @for (logisticMilestone of deliveryMilestones; track logisticMilestone) {
              <dt aria-level="3" class="accordion__header">
                <button type="button" class="accordion__title collapsable__title align-items-start" aria-controls="panel1"
                  [attr.aria-expanded]="!hasMoreItemsThanOne()" [class.icon-arrow-down-large]="hasMoreItemsThanOne()"
                  (click)="logisticMilestone.isExpanded = !logisticMilestone.isExpanded">
                  <span
                    class="white-space-pre padding-right-tiny-5">{{ 'LABEL_LOGISTIC_MILESTONE_DELIVERY' | translate }} {{ logisticMilestone.deliveryNumber + ': ' }}</span>
                  @if (logisticMilestone.items) {
                    <div id="milestone_items">{{ getItemsName(logisticMilestone.items) }}</div>
                  }
                </button>
              </dt>
              <dd class="accordion__panel" aria-labelledby="panel1-button" role="region" [attr.aria-hidden]="hasMoreItemsThanOne()">
                <hl-timeline [timelineMilestones]="logisticMilestone.timelineList"></hl-timeline>
                <p>
                  @for (milestone of logisticMilestone.milestones; track milestone) {
                    <div>
                      <span class="padding-right-tiny-5" id="milestone_date">{{ milestone.dateTime | datePipeWrapper : dateTimePattern }}</span>
                      <span id="milestone_desc">{{ milestone.codeDescription }}</span>
                    </div>
                  }
                  @if (logisticMilestone.trackingLinks?.length > 0) {
                    <br>
                  }
                  @for (link of logisticMilestone.trackingLinks; track link; let i = $index) {
                    <span>
                        <div>
                          <a [href]="link" target="_blank" rel="noopener noreferrer" class="button--link">
                            <span class="legal-links" id="tracking_link">
                              {{ (logisticMilestone.trackingLinks.length === 1) ? '' : (i + 1) + '.' }}
                              {{ 'LABEL_LOGISTIC_MILESTONES_PROVIDER_LINK' | translate }}
                              <i class="icon-arrow"></i>
                            </span>
                          </a>
                        </div>
                      </span>
                  }
                </p>
              </dd>
            }
          </dl>
        </section>
      </div>
    }
  </div>
}
