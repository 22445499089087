<hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>

@if (isLoaded && viewModelTicket) {
  <section class="dataview overflow-hidden" data-cy="ticket-details">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-6 col-md-12">
            <div class="aspect-ratio-4x3 margin-bottom-1">
              <hl-equipment-image [equipmentKey]="viewModelTicket.equipmentKey" [large]="true"></hl-equipment-image>
            </div>
          </div>
          <div class="col-6 col-md-12">
            @if (!isTicketStatusClosed) {
              <div>
                <button *hlItemShowHideHandler="'tickets';action:'save';ticketType:'update'" type="button" class="button button--small"
                  (click)="action(viewModelTicket, 'update')" [translate]="'GENERIC_LABEL_UPDATE'" data-cy="overlay-ticket-update"></button>
              </div>
              <div>
                <button *hlItemShowHideHandler="'tickets';action:'save';ticketType:'close'" type="button" class="button button--small"
                  (click)="action(viewModelTicket, 'close')" [translate]="'CLOSE_TICKET'" data-cy="overlay-ticket-close"></button>
              </div>
            }
            @if (surveyLink$ | async) {
              <div>
                <button type="button" class="button button--small" (click)="action(viewModelTicket, 'openSurvey')"
                  [translate]="'TICKET_SURVEY_BUTTON'" data-cy="overlay-ticket-survey"></button>
              </div>
            }
          </div>
        </div>
      </div>
      @if (ticketDetailFields?.length > 0) {
        <div class="col-12 col-md-8">
          <div class="multi-column multi-column-sm-2 multi-column-lg-3" data-cy="ticket-overview-text">
            @for (field of ticketDetailFields; track field; let i = $index) {
              @if (field.label && field.value) {
                <div class="multi-column__item">
                  <p class="font-size-xsmall margin-bottom-0 color-black-60">{{ field.label }}</p>
                  <p attr.data-cy="ticket-details-field-{{i+1}}">{{
                      field.datePattern !== undefined ? (field.value |
                        datePipeWrapper: field.datePattern) : field.value
                    }}</p>
                </div>
              }
            }
          </div>
        </div>
      }
      @if (!isTicketStatusClosed) {
        <div class="row action-buttons no-margin">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <p *hlItemShowHideHandler="'tickets';action:'convert';ticketId:viewModelTicket.typeID" class="button-wrap">
              <button class="btn btn-sm btn-primary" (click)="action(viewModelTicket, 'convert')">
                <span [translate]="'CONVERT_TICKET'"></span>
              </button>
            </p>
          </div>
        </div>
      }
    </div>
    <!-- For modal popup (closed tickets) long text is not to be shown -->
    <hl-tickets-history></hl-tickets-history>
    <hl-notification-attachments [viewModel]="viewModelTicket" [attachmentType]="'ticket'"></hl-notification-attachments>
  </section>
}

<hl-ticket-actions [viewModelTicket]="ticketItemForActions" #actions></hl-ticket-actions>
