import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, signal, Signal, ViewChild } from '@angular/core';

import { LifeNetUtilService } from 'app/core/utils/life-net-util.service';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { ActivitiesUtilService } from 'app/core/services/activities/activities-util.service';
import { ActivitiesCacheService } from 'app/core/services/cache/activities-cache.service';
import { ActivitiesRestService } from 'app/core/rest-services/activities-rest.service';
import { RefreshItemEventService } from 'app/core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { LogService } from 'app/core/services/log/log.service';
import { Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TicketViewModel } from 'app/core/view-models/ticket-view-model';
import { isEqual } from 'lodash-es';
import { MedalliaUtilService } from 'app/core/utils/medallia-util.service';
import { SystemUpdateRuhDetail } from 'app/core/models/systemUpdates/system-update-ruh-detail';
import { SystemUpdatesRestService } from 'app/core/rest-services/system-updates-rest.service';
import { EquipmentUtilService } from 'app/core/services/equipment/equipment-util.service';
import { Equipment } from 'app/core/models/equipment/equipment';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-activities-overview',
  templateUrl: './activities-overview.component.html'
})
export class ActivitiesOverviewComponent implements OnInit, OnDestroy {

  @ViewChild('rescheduleModal') rescheduleModal;
  @ViewChild('scheduleModal') scheduleModal;
  @ViewChild('updateModal') updateModal;
  @ViewChild('scheduleTrainingModal') scheduleTrainingModal;
  @ViewChild('dotRequestReschedulingModal') dotRequestReschedulingModal;

  datePattern = '';
  dateTimePattern = '';
  viewModelActivity = signal<ActivitiesViewModel>(null)
  addressFields: any;
  private readonly unsubscribe$ = new Subject<void>();
  isLoaded = false;
  updateActivity: TicketViewModel;
  removeUnknownPmStatusFeatureToggle = false;
  appointmentsOverview: ActivitiesViewModel[];
  showSystemUpdatesRuh = false;
  isLoadedSystemUpdatesRuhDetails = false;
  isDotEnabled: Signal<boolean> = signal(false);
  configSignal = toSignal(this.configService.getConfig(), { initialValue: {} });
  requestRescheduleP58Enabled = false;

  constructor(protected activatedRoute: ActivatedRoute,
    protected activitiesUtilService: ActivitiesUtilService,
    protected configService: CountryConfigRestService,
    protected lifeNetUtilService: LifeNetUtilService,
    protected activitiesCacheService: ActivitiesCacheService,
    private activitiesRestService: ActivitiesRestService,
    private refreshItemEventService: RefreshItemEventService,
    private logService: LogService,
    private medalliaUtilService: MedalliaUtilService,
    private equipmentUtilService: EquipmentUtilService,
    private systemUpdatesRestService: SystemUpdatesRestService,
    private router: Router) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.addressFields = [];
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
      this.getActivityViewModel(configResponse);
    });
    this.refreshItemEventService.refreshItemSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.refreshActivity();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
  }

  setConfigProperties(config) {
    // get the locale date pattern
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
    this.removeUnknownPmStatusFeatureToggle = isEqual(config.REMOVE_UNKNOWN_PMSTATUS_FEATURE_TOGGLE, 'true');
    this.showSystemUpdatesRuh = isEqual(config.FEATURE_TOGGLE_RUH_UPDATES_CACHE, 'true');
    this.requestRescheduleP58Enabled = isEqual(config.FEATURE_TOGGLE_DOT_P58, 'true');
  }

  getActivityViewModel(config) {
    this.updateActivity = null;
    this.activatedRoute.params.subscribe(
      (params: any) => {
        const id = params['ticketKey'] || params['id'];
        if (this.activitiesUtilService.selectedActivity) {
          this.isLoaded = true;
          this.viewModelActivity.set(this.activitiesUtilService.selectedActivity)
          this.addressFields =
            this.lifeNetUtilService.getAddressFields(this.viewModelActivity, config.ADDRESS_FIELD_ORDER, false);
        } else {
          this.activitiesUtilService.getSingleActivity(id, this.activitiesCacheService.getSelectedOpenClosedStatus())
            .subscribe((activityVMResponse) => {
              this.isLoaded = true;
              this.viewModelActivity.set(activityVMResponse)
              if (activityVMResponse) {
                this.addressFields =
                  this.lifeNetUtilService.getAddressFields(activityVMResponse, config.ADDRESS_FIELD_ORDER, false);
              }
            });
        }
        this.createUpdateActivityItem();
        this.loadSystemUpdatesRuhDetailsData();
      }
    );
  }

  loadSystemUpdatesRuhDetailsData() {
    if (!this.viewModelActivity()) {
      return;
    }

    if (!this.viewModelActivity()?.ruhSystemUpdate || !this.viewModelActivity()?.ruhSystemUpdate?.updateNumber) {
      this.isLoadedSystemUpdatesRuhDetails = true;
      this.viewModelActivity().systemUpdatesRuhDetails = null;
      return;
    }
    this.equipmentUtilService.getEquipment(this.viewModelActivity().equipmentKey).pipe(
      switchMap((equipment: Equipment) => this.systemUpdatesRestService.getRuhDetails(equipment.materialNumber,
        equipment.serialNumber, this.viewModelActivity().ruhSystemUpdate?.updateNumber)),
      takeUntil(this.unsubscribe$)
    ).subscribe((updateDetails: SystemUpdateRuhDetail[]) => {
      this.viewModelActivity().systemUpdatesRuhDetails = updateDetails;
      this.isLoadedSystemUpdatesRuhDetails = true;
    });
  }

  getRuhSystemUpdateCategory(category: string): string {
    return category ? `LABEL_EVENT_CATEGORY_${category}` : '';
  }

  /**
   *
   * @description
   * Refresh activity overview after clearing cache
   */
  refreshActivity() {
    this.unsubscribe$.next();

    this.isLoaded = false;
    const url = this.activitiesRestService.activitiesDetailsRestName.replace(/:id/g, this.viewModelActivity().ticketKey);
    this.activitiesRestService.clearCache(url);

    this.init();
  }

  createUpdateActivityItem() {
    this.updateActivity = {
      ticketKey: this.viewModelActivity().ticketKey,
      ticketNumber: this.viewModelActivity().ticketNumber,
      customerId: this.viewModelActivity().customerId,
      equipmentKey: this.viewModelActivity().equipmentKey,
      typeID: this.viewModelActivity().type
    } as TicketViewModel;
  }

  showActivityStatus(activity: ActivitiesViewModel) {
    return !(this.removeUnknownPmStatusFeatureToggle && activity.pmStatus === '-1');
  }

  schedule() {
    this.medalliaUtilService.setPopupsAllowed(false);
    this.rescheduleModal.show();
  }

  update() {
    this.medalliaUtilService.setPopupsAllowed(false);
    this.updateModal.show();
  }

  notScheduledForP58Country() {
    return this.activitiesUtilService.isNotScheduledForP58Country(this.viewModelActivity());
  }

  redirectToSystemUpdateOverview () {
    this.router.navigate(['/updates', this.viewModelActivity().ruhSystemUpdate.updateNumber]);
  }
}
