import { Injectable } from '@angular/core';
import { MdmRestService } from '../rest-services/mdm-rest.service';
import { MdmData, UserGroup } from '../models/mdm';
import { combineLatest, mergeMap, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { filter, isEqual } from 'lodash-es';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { MdmRegisterData } from '../models/mdm-register';
import { Equipment } from '../models/equipment/equipment';
import { RemoveLeadingZeroPipe } from '../../shared/pipes/removeLeadingZero/remove-leading-zero.pipe';
import { DropdownOptions } from '../models/dropdown-options';
import { EquipmentInformation } from '../models/equipment/equipment-information';

@Injectable({providedIn: 'root'})
export class MdmService {

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(
    private mdmRestService: MdmRestService,
    private removeLeadingZeroPipe: RemoveLeadingZeroPipe,
    private configService: CountryConfigRestService) {
  }

  getMdmData(): Observable<MdmData> {
    return this.mdmRestService.getMdmData();
  }

  registerOrReplaceTablet(body: MdmRegisterData) {
    return this.mdmRestService.registerOrReplaceTablet(body);
  }

  getMdmNullPrefillData(equipment: Equipment) {
   return {
     businessLine: null,
     systemSerialNumber: this.removeLeadingZeroPipe.transform(equipment.serialNumber),
     systemMaterialNumber: this.removeLeadingZeroPipe.transform(equipment.materialNumber),
     swPlatformVersion: null,
     deviceSpaceId: null
   };
  }
  getMdmPrefilledValues(equipment: Equipment): Observable<any> {
    const modality = this.getEquipmentModality(equipment);
    if (!modality || !equipment.softwareVersion) {
      return of(this.getMdmNullPrefillData(equipment));
    }

    return this.transformMdmPrefilledValues(modality, equipment);
  }

  private transformMdmPrefilledValues(modality: string, equipment: Equipment) {
    const nullPrefillData = this.getMdmNullPrefillData(equipment);
    return combineLatest([this.configService.getConfig(), this.mdmRestService.getMdmData()])
      .pipe(
        mergeMap(([config, mdmData]) => {
          const eqBl = this.getMdmBusinessLineForModality(config, mdmData, modality);
          return this.mdmRestService.getWhitelistedMaterialNumbers(eqBl).pipe(
            map(matNumbersList => [mdmData, matNumbersList, eqBl] as const)
          );
        }),
        map(([mdmData, whitelistedMatNumbers, eqBl]) => {
          if (!whitelistedMatNumbers.includes(nullPrefillData.systemMaterialNumber) || !eqBl) {
            return nullPrefillData;
          }
          const deviceSpaceId = mdmData[eqBl]?.id ?? null;
          return {
            businessLine: eqBl,
            systemSerialNumber: nullPrefillData.systemSerialNumber,
            systemMaterialNumber: nullPrefillData.systemMaterialNumber,
            swPlatformVersion: this.getValidVersionId(mdmData, eqBl, equipment.softwareVersion),
            deviceSpaceId
          };
        }),
        switchMap(data => data.deviceSpaceId ? this.prefillRemovedSerialNumbersData(nullPrefillData.systemMaterialNumber,
          nullPrefillData.systemSerialNumber, data.deviceSpaceId, data) : of(nullPrefillData)
        ),
        takeUntil(this.unsubscribe$)
      );
  }

  prefillRemovedSerialNumbersData(matNum: string, serNum: string, deviceSpace: number, data: any) {
    return this.mdmRestService.getAssignedSerialNumbers(matNum, serNum, deviceSpace).pipe(
      map(assignedData => {
        return assignedData.length > 0 ? {
          ...data,
          removedTabletSerialNumber: assignedData[0],
          assignedSerialNumbers: assignedData.map(d => d)
        } : data;
      })
    );
  }

  isAvailable(modality: string, swVersion: string, materialNumber: string, serialNumber: string): Observable<boolean> {
    return this.getMdmPrefilledValues({
      modality,
      softwareVersion: swVersion,
      materialNumber,
      serialNumber
    } as Equipment).pipe(map(data => data.swPlatformVersion != null && data.removedTabletSerialNumber != null));
  }

  cleanSubscription() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getMdmBusinessLineForModality(config: any, mdmData: MdmData, modality: string): string {
    if (mdmData) {
      for (const bl of Object.keys(mdmData)) {
        if (config[`MDM_BL_${bl}`] && config[`MDM_BL_${bl}`].split(',').includes(modality)) {
          return bl;
        }
      }
    }
    return null;
  }

  private getValidVersions(mdmData: MdmData, eqBusinessLine: string, eqSwVersion): UserGroup[] {
    return filter(mdmData[eqBusinessLine].versions, version => this.isVersionPresent(version.name, eqSwVersion));
  }

  private getValidVersionId(mdmData: MdmData, eqBusinessLine: string, eqSwVersion): string {
    const versions = this.getValidVersions(mdmData, eqBusinessLine, eqSwVersion);
    return versions.length === 1 ? versions[0].id.toString() : null;
  }

  private isVersionPresent(versionGroup: string, eqVersion: string): boolean {
    const versionGroupSplit = versionGroup.split(' ');
    const eqVersionSplit = eqVersion.split(' ');
    const i = versionGroupSplit.indexOf(eqVersionSplit[0]);
    const result = versionGroupSplit.slice(i, i + eqVersionSplit.length);

    return isEqual(eqVersionSplit, result);
  }

  clearCache(materialNumber: string, serialNumber: string, businessLineId: number) {
    this.mdmRestService.clearCacheAssignedSerialNumbers(materialNumber, serialNumber, businessLineId);
  }

  getWhitelistedMaterialNumbersDropdownOptions(businessLineKey: string): Observable<DropdownOptions[]> {
    return this.mdmRestService.getWhitelistedMaterialNumbers(businessLineKey)
      .pipe(map(materialNumbers => materialNumbers.map((matNum) => (
          {
            value: matNum,
            title: matNum
          }))));
  }

  private getEquipmentModality(equipment: EquipmentInformation): string {
    return equipment.cmdbEquipment?.modalityCode ?? equipment.modality;
  }
}
