import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EquipmentAddedToNewSystemUpdateComponent } from './equipment-added-to-new-system-update/equipment-added-to-new-system-update.component';
import { differenceBy, filter, indexOf, without } from 'lodash-es';
import { EquipmentSelectionForNewSystemUpdateComponent } from './equipment-selection-for-new-system-update/equipment-selection-for-new-system-update.component';
import { SystemUpdateInput } from '../../../core/models/systemUpdates/system-update-input';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { SystemUpdatesRestService } from '../../../core/rest-services/system-updates-rest.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { finalize } from 'rxjs/operators';
import { SystemUpdatesService } from '../../../core/services/system-updates/system-updates-service';
import { CreateNewEquipmentForUpdateComponent } from './create-new-equipment-for-update/create-new-equipment-for-update.component';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { SystemUpdatesConstantsService } from '../../../core/services/system-updates/system-updates-constants.service';
import { SelectOption } from '../../../core/models/select-option';
import { SystemUpdatesEquipmentViewModel } from '../../../core/view-models/system-updates-equipment-view-model';
import { CreateSystemUpdatesService } from '../../../core/services/system-updates/create-system-updates-service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-create-new-system-update-modal',
  templateUrl: './create-new-system-update-modal.component.html'
})
export class CreateNewSystemUpdateModalComponent extends BaseFormModalPopup implements OnInit, OnDestroy {
  @ViewChild('addedEquipmentsComponent')
  addedEquipmentsComponent: EquipmentAddedToNewSystemUpdateComponent;
  @ViewChild('equipmentSelectionComponent')
  equipmentSelectionComponent: EquipmentSelectionForNewSystemUpdateComponent;
  @ViewChild('createNewEquipmentForUpdateComponent')
  createNewEquipmentForUpdateComponent: CreateNewEquipmentForUpdateComponent;

  updateInformationForm: UntypedFormGroup;
  equipmentAddedToUpdate: (SystemUpdatesEquipmentViewModel)[];
  asuStatusOptions: SelectOption[] = [];
  isEditUpdate = false;

  constructor(
    renderer: Renderer2,
    private fb: UntypedFormBuilder,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private systemUpdatesRestService: SystemUpdatesRestService,
    private systemUpdatesService: SystemUpdatesService,
    private equipmentUtilService: EquipmentUtilService,
    private systemUpdatesConstantsService: SystemUpdatesConstantsService,
    private toasterService: ToasterService,
    private createSystemUpdatesService: CreateSystemUpdatesService) {
    super(renderer);
    this.bodyId = 'create-edit-update-modal-body';
  }

  ngOnInit() {
    this.init();
    this.systemUpdatesService.showCreateNewSystemUpdateModalEmitter
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        response => {
          if (response) {
            this.isEditUpdate = true;
            this.showSpinner = true;
            const formData = {
              ...response,
              updateNumber: response.updateNumber.replace(/\//g, '-'),
              dueDate: new Date(response.dueDate)
            };
            this.updateInformationForm.patchValue(formData);
            this.loadAffectedEquipments(response.updateNumber);
          } else {
            this.updateInformationForm.reset();
            this.isEditUpdate = false;
            this.showSpinner = false;
          }
        }
      );
  }

  init() {
    this.isFormSubmitted = false;
    this.equipmentAddedToUpdate = [];
    this.asuStatusOptions = this.systemUpdatesConstantsService.getAsuStatusSkeleton();
    this.createForm();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  hide() {
    this.resetForms();
    super.hide();
  }

  public resetForms() {
    this.init();
    this.equipmentAddedToUpdate = [];
    this.isEditUpdate = false;
    this.equipmentSelectionComponent.resetForm();
    this.addedEquipmentsComponent.resetForm();
    this.createNewEquipmentForUpdateComponent.init();
  }

  createForm(): void {
    const updateNumberRegEx = new RegExp('^[A-Z]{2}\\d{3}\-\\d{2}\-[A-Z]$');

    this.updateInformationForm = this.fb.group({
      updateNumber: ['', [
        Validators.required, Validators.maxLength(10), Validators.pattern(updateNumberRegEx)
      ]],
      title: ['', [
        Validators.required, Validators.maxLength(50)
      ]],
      dueDate: [null, Validators.required],
      cyberSecurity: [false, Validators.required],
      asuStatus: [this.asuStatusOptions[0].value, Validators.required]
    });
  }

  postFormData() {
    this.isFormSubmitted = true;
    const isEdit = this.isEditUpdate;

    if (!this.updateInformationForm.valid || this.equipmentAddedToUpdate.length === 0) {
      return;
    }

    const systemUpdateInput: SystemUpdateInput = {
      updateNumber: this.getUpdateNumber(),
      title: this.updateInformationForm.get('title').value.trim(),
      dueDate: this.formatDate(this.updateInformationForm.get('dueDate').value),
      cyberSecurity: this.updateInformationForm.get('cyberSecurity').value,
      asuStatus: this.updateInformationForm.get('asuStatus').value,
      equipmentKeys: this.equipmentAddedToUpdate.map(equipment => equipment.key)
    };

    this.hide();

    this.systemUpdatesRestService.createOrEditSystemUpdate(systemUpdateInput, isEdit)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.systemUpdatesService.onStatusUpdateEmit();
          this.toasterService.showTranslatedSuccessToaster(isEdit ? 'SYSTEM_UPDATE_EDIT_SUCCESSFUL' : 'SYSTEM_UPDATE_CREATE_SUCCESSFUL');
          },
        error: (err) => this.toasterService.showTranslatedErrorToaster(err.error && err.error.code === 409 ?
          'SYSTEM_UPDATE_NUMBER_CONFLICT_ERROR_MESSAGE' : 'FRONTEND_GENERIC_ERROR_MESSAGE')
      });
  }

  addEquipmentToUpdate(equipment: SystemUpdatesEquipmentViewModel) {
    this.equipmentAddedToUpdate = [...this.equipmentAddedToUpdate, equipment];
    this.addedEquipmentsComponent.addEquipmentToList(equipment);
  }

  addEquipmentToEquipmentSelection(equipment: SystemUpdatesEquipmentViewModel) {
    this.equipmentSelectionComponent.addEquipmentToList(equipment);
    this.equipmentAddedToUpdate = without(this.equipmentAddedToUpdate, equipment);
  }

  private getUpdateNumber(): string {
    const updateNumber = this.updateInformationForm.get('updateNumber').value;
    return updateNumber ? updateNumber.replace(/-/gi, '/') : '';
  }

  private formatDate(date: Date | null): string {
    return date instanceof Date
      ? this.datePipeWrapperPipe.transform(date.toISOString(), 'YYYY-MM-DD')
      : '1979-01-01';
  }

  private setAffectedEquipments(affectedEquipmentKeys: string[]) {
    this.createSystemUpdatesService.getAllEquipmentForNewSystemUpdateCreation().subscribe(allEquipment => {
      this.equipmentAddedToUpdate = filter(allEquipment, e => {
        return indexOf(affectedEquipmentKeys, e.key) > -1;
      });
      this.addedEquipmentsComponent.addEquipmentArrayToList(this.equipmentAddedToUpdate);
      const equipmentNotAdded = differenceBy(allEquipment, this.equipmentAddedToUpdate, 'key');
      this.equipmentSelectionComponent.setEquipmentSelection(equipmentNotAdded);
    });
  }

  private loadAffectedEquipments(updateNumber: string) {
    this.systemUpdatesRestService.getSystemUpdateAffectedEquipmentKeys(updateNumber).pipe(
      finalize(() => {
      this.showSpinner = false;
    })).subscribe(equipmentKeys => {
      this.setAffectedEquipments(equipmentKeys);
    });
  }
}
