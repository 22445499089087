import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { DeliveryMilestones, Item } from '../../../core/models/tickets/delivery-milestones';
import { ActivatedRoute } from '@angular/router';
import { CollapsableDirective } from '../../directives/collapsable/collapsable.directive';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { LogService } from '../../../core/services/log/log.service';
import { LogisticMilestonesUtilService } from '../../../core/services/logistic-milestones/logistic-milestones-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateUtilService } from '../../../core/utils/date-util.service';

@Component({
  selector: 'hl-tickets-delivery-milestones',
  templateUrl: './tickets-delivery-milestones.component.html'
})
export class TicketsDeliveryMilestonesComponent implements OnDestroy, OnInit {

  deliveryMilestones: DeliveryMilestones[];
  dateTimePattern: string;
  ticketNumber: string;
  ticketType: string;
  showSpinner = true;

  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild(CollapsableDirective) collapsable: CollapsableDirective;

  constructor(public configService: CountryConfigRestService,
    private route: ActivatedRoute,
    private refreshItemEventService: RefreshItemEventService,
    private logService: LogService,
    public milestonesUtilService: LogisticMilestonesUtilService,
    private dateUtilService: DateUtilService) {
  }

  showExpandAll(): boolean {
    return this.deliveryMilestones.some(milestone => !milestone.isExpanded);
  }

  showCollapseAll(): boolean {
    return !this.deliveryMilestones.some(milestone => !milestone.isExpanded);
  }

  ngOnInit(): void {
    this.registerEventListeners();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadDeliveryMilestones() {
    if (this.milestonesUtilService.isTicketTypeAllowed(this.ticketType)) {
      this.milestonesUtilService.getDeliveryMilestones(this.ticketNumber).subscribe(response => {
        this.deliveryMilestones = response;
        this.showSpinner = false;
        this.expandDeliveryMilestoneItem(false);
      });
    } else {
      this.showSpinner = false;
    }
  }

  getItemsName(items: Item[]): string {
    return items.map(i => i.description).join(', ');
  }

  hasMoreItemsThanOne(): boolean {
    return (this.deliveryMilestones && this.deliveryMilestones.length > 1);
  }

  registerConfigListener(): void {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
    });
  }

  registerRouteListener(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.showSpinner = true;
      this.deliveryMilestones = [];
      this.ticketNumber = params['ticketNumber'];
      this.ticketType = params['ticketType'];

      this.loadDeliveryMilestones();
    });
  }

  registerRefreshButtonListener() {
    this.refreshItemEventService.refreshItemSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.refreshDeliveryMilestones();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
  }

  collapseAll() {
    this.collapsable.toggleAllPanels(false);
    this.expandDeliveryMilestoneItem(false);
  }

  expandAll() {
    this.collapsable.toggleAllPanels(true);
    this.expandDeliveryMilestoneItem(true);
  }

  expandDeliveryMilestoneItem(status: boolean) {
    if (this.deliveryMilestones) {
      this.deliveryMilestones.forEach(milestone => milestone.isExpanded = status);
    }
  }

  private registerEventListeners() {
    this.registerConfigListener();
    this.registerRouteListener();
    this.registerRefreshButtonListener();
  }

  private refreshDeliveryMilestones() {
    this.unsubscribe$.next();

    this.milestonesUtilService.clearDeliveryMilestonesCache(this.ticketNumber);

    this.ngOnInit();
  }
}
