@for (item of currentArray; track item) {
  @if (item.link) {
    @if (isInternalLink) {
      <a [href]="item.link" target="_blank" rel="noopener noreferrer">
        <i class="icon-arrow-right-medium" aria-hidden="true"></i>{{item.text}}
      </a>
    } @else {
      <a [href]="item.link" target="_blank" rel="noopener noreferrer">
        {{item.text}}<i class="icon-arrow" aria-hidden="true"></i>
      </a>
    }
  } @else {
    <a>{{item.text}}</a>
  }
}
@if (showToggleButton) {
  <button class="button button--text button--small margin-bottom-0" [class.icon-plus]="isCollapsed" [class.icon-minus]="!isCollapsed"
          (click)="toggleView($event)" [translate]="isCollapsed ? 'GENERIC_LABEL_MORE' : 'GENERIC_LABEL_LESS'" #toggle></button>
}
