import { finalize, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReportingUtilService } from '../../../core/services/reporting/reporting-util.service';
import { excelContentType } from '../../../shared/components/excel-export/excel-export-from-be.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { isEqual } from 'lodash-es';
import { DownloadUtilService } from '../../../core/utils/download-util.service';
import { combineLatest, Subject } from 'rxjs';

@Component({
  selector: 'hl-report-export',
  templateUrl: './report-export.component.html'
})
export class ReportExportComponent implements OnInit, OnDestroy {
  @Input()
  reportType: string;
  isCollapsed: boolean;
  generatingReport: boolean;
  private datePattern: string;
  pdfContentType = 'application/pdf';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private reportingUtilService: ReportingUtilService,
    private translateService: TranslateService,
    private downloadUtilService: DownloadUtilService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private configService: CountryConfigRestService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.isCollapsed = true;
    this.generatingReport = false;
    this.initConfigProperties();
  }

  initConfigProperties() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.datePattern = config.GENERIC_DATE_PATTERN;
    });
  }

  export(fileType: string) {
    this.generatingReport = true;
    combineLatest([
      this.reportingUtilService.exportFiles(this.reportType, fileType),
      this.translateService.get('LABEL_' + this.reportType)
    ]).pipe(
      finalize(() => {
        this.generatingReport = false;
      })
    ).subscribe(([result, filename]) => {
      const dateName = this.datePipeWrapperPipe.transform(
        new Date(),
        this.datePattern
      );
      const contentType = isEqual(fileType, 'xls')
        ? excelContentType
        : this.pdfContentType;
      this.downloadUtilService.generateBlobAndDownload(
        result,
        contentType,
        filename + '_' + dateName
      );
    });
  }
}
