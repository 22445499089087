import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
// modules for translation
import { TranslateModule } from '@ngx-translate/core';
// Components
import { UserNameComponent } from './components/user-name/user-name.component';
import { IsMyEquipmentComponent } from './components/is-my-equipment/is-my-equipment.component';
import { ActivitiesStatusMapComponent } from './components/activities-status-map/activities-status-map.component';
import { ContactAreaComponent } from './form-group/contact-area/contact-area.component';
import { IsLdCustomerComponent } from './components/is-ld-customer/is-ld-customer.component';
import { EquipmentActvCountComponent } from './components/equipment-actv-count/equipment-actv-count.component';
import { AdditionalContactsComponent } from './components/additional-contacts/additional-contacts.component';
import { TicketsHistoryComponent } from './components/tickets-history/tickets-history.component';
import { TicketsOverviewComponent } from './components/tickets-overview/tickets-overview.component';
import { TicketActionsComponent } from './components/ticket-actions/ticket-actions.component';
import { SimulatorCheckComponent } from './components/simulator-check/simulator-check.component';
import { TicketListItemsComponent } from './components/ticket-list-items/ticket-list-items.component';
import { AppointmentsOverviewComponent } from './components/appointments-overview/appointments-overview.component';
import { AppointmentsEventLogsComponent } from './components/appointments-event-logs/appointments-event-logs.component';

// Popups/Modals
import { DeactivateEquipmentModalComponent } from './modal-popup/deactivate-equipment-modal/deactivate-equipment-modal.component';
import { EditComponentModalComponent } from './modal-popup/edit-component-modal/edit-component-modal.component';
import { EditEquipmentInfoModalComponent } from './modal-popup/edit-equipment-info-modal/edit-equipment-info-modal.component';
import { RescheduleActivityModalComponent } from './modal-popup/reschedule-activity-modal/reschedule-activity-modal.component';
import { OptionsRecommendModalComponent } from './modal-popup/options-recommend-modal/options-recommend-modal.component';
import { OptionsGetQuoteModalComponent } from './modal-popup/options-get-quote-modal/options-get-quote-modal.component';
import { OptionsGetTrialModalComponent } from './modal-popup/options-get-trial-modal/options-get-trial-modal.component';
import { DangerForPatientModalComponent } from './modal-popup/danger-for-patient-modal/danger-for-patient-modal.component';
import { StateDownModalComponent } from './modal-popup/state-down-modal/state-down-modal.component';
import { MultiCountryImpersonationModalComponent } from './modal-popup/multi-country-impersonation-modal/multi-country-impersonation-modal.component';
import { ContractDetailModalComponent } from './modal-popup/contract-detail-modal/contract-detail-modal.component';
import { ReportingModalComponent } from './modal-popup/reporting-modal/reporting-modal.component';
import { TicketUpdateModalComponent } from './modal-popup/ticket-update-modal/ticket-update-modal.component';
import { TicketCloseModalComponent } from './modal-popup/ticket-close-modal/ticket-close-modal.component';
import { TicketSurveyModalComponent } from './modal-popup/ticket-survey-modal/ticket-survey-modal.component';
import { TermsAndConditionModalComponent } from './modal-popup/terms-and-condition-modal/terms-and-condition-modal.component';
import { IcCodeModalComponent } from './modal-popup/ic-code-modal/ic-code-modal.component';
import { SimulatorModalComponent } from './modal-popup/simulator-modal/simulator-modal.component';
import { ScheduleActivityModalComponent } from './modal-popup/schedule-activity-modal/schedule-activity-modal.component';
import { HowToModalComponent } from '../system-updates/how-to-modal/how-to-modal.component';
import { ReportExportComponent } from '../generate-reports/components/report-export/report-export.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { SavedFiltersComponent } from './components/saved-filters/saved-filters.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NotificationAttachmentsComponent } from './components/notification-attachments/notification-attachments.component';
import { SwitchLanguageModalComponent } from './modal-popup/switch-language-modal/switch-language-modal.component';
import { ActivitiesOverviewComponent } from './components/activities-overview/activities-overview.component';
import { ContractsOverviewComponent } from './components/contracts-overview/contracts-overview.component';
import { ContractListItemComponent } from './components/contract-list-item/contract-list-item.component';
import { DiagramModule } from '../diagram/diagram.module';
import { CreatePsrModalComponent } from './modal-popup/create-psr-modal/create-psr-modal.component';
import { CreatePsrProductAreaComponent } from './form-group/create-psr-product-area/create-psr-product-area.component';
import { CreatePsrServiceSummaryComponent } from './form-group/create-psr-service-summary/create-psr-service-summary.component';
import { CreatePsrAcceptanceAuthorizationComponent } from './form-group/create-psr-acceptance-authorization/create-psr-acceptance-authorization.component';
import { CreatePsrTemplatesComponent } from './form-group/create-psr-templates/create-psr-templates.component';
import { CreateSaModalComponent } from './modal-popup/create-sa-modal/create-sa-modal.component';
import { CreateSaoProductInformationComponent } from './form-group/create-sao-product-information/create-sao-product-information.component';
import { CreateSaoOrderSummaryComponent } from './form-group/create-sao-order-summary/create-sao-order-summary.component';
import { CreateSaoContactInformationComponent } from './form-group/create-sao-contact-information/create-sao-contact-information.component';
import { CreateSaoShippingAddressComponent } from './form-group/create-sao-shipping-address/create-sao-shipping-address.component';
import { CreateSaoBillingAddressComponent } from './form-group/create-sao-billing-address/create-sao-billing-address.component';
import { CreateSaoDownloadComponent } from './form-group/create-sao-download/create-sao-download.component';
import { CreateGroupModalComponent } from './modal-popup/create-group-modal/create-group-modal.component';
import { VerifyPublicGroupModalComponent } from './modal-popup/verify-public-group-modal/verify-public-group-modal.component';
import { CreateSaoEntryPageComponent } from './overlays/create-sao-entry-page/create-sao-entry-page.component';
import { IntroFeatureModalComponent } from './modal-popup/intro-feature-modal/intro-feature-modal.component';
import { IntroSliderGalleryComponent } from './components/intro-slider-gallery/intro-slider-gallery.component';
import { AccordionWithEquipmentsComponent } from './components/accordion-with-equipments/accordion-with-equipments.component';
import { SecurityDetailComponent } from './components/security-detail/security-detail.component';
import { ContractEquipmentUsageComponent } from './components/contract-equipment-usage/contract-equipment-usage.component';
import { EditCustomerNameModalComponent } from './modal-popup/edit-customer-name-modal/edit-customer-name-modal.component';
import { CreateUserNotificationsPageComponent } from './overlays/create-user-notifications-page/create-user-notifications-page.component';
import { NotificationsSubscriptionModalComponent } from './modal-popup/notifications-subscription-modal/notifications-subscription-modal.component';
import { EventLogsComponent } from './components/event-logs/event-logs.component';
import { ActivitiesEventLogsComponent } from './components/activities-event-logs/activities-event-logs.component';
import { TicketsEventLogsComponent } from './components/tickets-event-logs/tickets-event-logs.component';
import { TicketsDeliveryMilestonesComponent } from './components/tickets-delivery-milestones/tickets-delivery-milestones.component';
import { ActivitiesDeliveryMilestonesComponent } from './components/activities-delivery-milestones/activities-delivery-milestones.component';
import { SelectEquipmentModalComponent } from './modal-popup/select-equipment-modal/select-equipment-modal.component';
import { RegistrationModalComponent } from './modal-popup/registration-modal/registration-modal-component';
import { CreateAdvisoryModalComponent } from './modal-popup/create-advisory-modal/create-advisory-modal.component';
import { ConfirmationModalComponent } from './modal-popup/confirmation-modal/confirmation-modal.component';
import { EquipmentMissingModalComponent } from './modal-popup/equipment-missing-modal/equipment-missing-modal.component';
import { MetricsExportComponent } from '../service-metrics/metrics-export/metrics-export.component';
import { CreateAdvisoryNotificationModalComponent } from './modal-popup/create-advisory-notification-modal/create-advisory-notification-modal.component';
import { SyngoVoucherRequestModalComponent } from './modal-popup/syngo-voucher-request-modal/syngo-voucher-request-modal.component';
import { CreateNewSystemUpdateModalComponent } from './modal-popup/create-new-system-update-modal/create-new-system-update-modal.component';
import { EquipmentSelectionForNewSystemUpdateComponent } from './modal-popup/create-new-system-update-modal/equipment-selection-for-new-system-update/equipment-selection-for-new-system-update.component';
import { EquipmentAddedToNewSystemUpdateComponent } from './modal-popup/create-new-system-update-modal/equipment-added-to-new-system-update/equipment-added-to-new-system-update.component';
import { SystemUpdateInformationFormComponent } from './modal-popup/create-new-system-update-modal/system-update-information-form/system-update-information-form.component';
import { CreateNewEquipmentForUpdateComponent } from './modal-popup/create-new-system-update-modal/create-new-equipment-for-update/create-new-equipment-for-update.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ScheduleTrainingModalComponent } from './modal-popup/schedule-training-modal/schedule-training-modal.component';
import { AsuAddWhitelistEntryModalComponent } from './modal-popup/asu-add-whitelist-entry-modal/asu-add-whitelist-entry-modal.component';
import { BasicComponentsSharedModule } from '../core/basic-components-shared.module';
import { NewSystemUpdateEquipmentListItemComponent } from './modal-popup/create-new-system-update-modal/new-system-update-equipment-list-item/new-system-update-equipment-list-item.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { InfoModalComponent } from './modal-popup/info-modal/info-modal.component';
import { CreateTicketDisclaimerPopupComponent } from 'app/tickets/create-ticket-disclaimer-popup/create-ticket-disclaimer-popup.component';
import { EquipmentImageSharedModule } from '../shared-modules/equipment-image-shared.module';
import { ReadMoreComponentsSharedModule } from '../shared-modules/read-more-components-shared.module';
import { CreateTicketConfirmPatientDataComponent } from './form-group/create-ticket-confirm-patient-data/create-ticket-confirm-patient-data.component';
import { CreateTicketComponent } from '../tickets/create-ticket/create-ticket.component';
import { CreateTicketDetailAreaComponent } from './form-group/create-ticket-detail-area/create-ticket-detail-area.component';
import { CreateTicketRequestAreaComponent } from './form-group/create-ticket-request-area/create-ticket-request-area.component';
import { CreateTicketListOfTicketsComponent } from './form-group/create-ticket-list-of-tickets/create-ticket-list-of-tickets.component';
import { CreateTicketAvailabilityAreaComponent } from './form-group/create-ticket-availability-area/create-ticket-availability-area.component';
import { CreateTicketMobileAddressAreaComponent } from './form-group/create-ticket-mobile-address-area/create-ticket-mobile-address-area.component';
import { UploadFilesSharedModule } from '../shared-modules/upload-files-shared.module';
import { EquipmentListItemTemplateMapPipe } from './pipes/equipment-list-item-template-map/equipment-list-item-template-map.pipe';
import { MdmManageTabletModalComponent } from './modal-popup/mdm-manage-tablet-modal/mdm-manage-tablet-modal.component';
import { RescheduleAppointmentModalComponent } from './modal-popup/reschedule-appointment-modal/reschedule-appointment-modal.component';
import { NonEquipmentTemplateDirective } from './directives/non-equipment-template/non-equipment-template.directive';
import { LongClickDirective } from './directives/long-click/long-click.directive';
import { ListsCardComponent } from './components/lists-card/lists-card.component';
import { AddEquipmentLinkParamsPipe } from './pipes/add-equipment-link-params/add-equipment-link-params.pipe';
import { PortalsModule } from '../shared-modules/portals.module';
import { SubmitPoNumberPopupComponent } from './modal-popup/submit-po-number-popup/submit-po-number-popup.component';
import { TranslatePropertyPipe } from './pipes/translate-property/translate-property.pipe';
import { ReplacePipe } from './pipes/replace/replace.pipe';
import { VisibilityConfirmationModalComponent } from './modal-popup/visibility-confirmation-modal/visibility-confirmation-modal.component';
import { MarketingConsentRequiredComponent } from './components/marketing-consent-required/marketing-consent-required.component';
import { InvoicesStatusMapComponent } from './components/invoices-status-map/invoices-status-map.component';
import { DotScheduleButtonComponent } from '../appointments/dot/components/dot-schedule-button/dot-schedule-button.component';
import { DotRequestReschedulingModalComponent } from 'app/shared/modal-popup/dot-request-rescheduling-modal/dot-request-rescheduling-modal.component';
import { ProgressIndicatorComponent } from 'app/shared/components/progress-indicator/progress-indicator.component';
import {
    NumberFromIdPipe
} from "app/shared/pipes/number-from-id/number-from-id.pipe";

// Reactive forms

const createTicketComponents: any[] = [
  CreateTicketComponent,
  CreateTicketDetailAreaComponent,
  CreateTicketRequestAreaComponent,
  CreateTicketListOfTicketsComponent,
  CreateTicketConfirmPatientDataComponent,
  CreateTicketAvailabilityAreaComponent,
  CreateTicketMobileAddressAreaComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FlatpickrModule.forRoot(),
    DiagramModule,
    InfiniteScrollModule,
    TooltipModule,
    BasicComponentsSharedModule,
    EquipmentImageSharedModule,
    ReadMoreComponentsSharedModule,
    UploadFilesSharedModule,
    PortalsModule,
    DotScheduleButtonComponent,
    ActivitiesStatusMapComponent,
    ProgressIndicatorComponent,
    NumberFromIdPipe
  ],
  declarations: [
    UserNameComponent,
    CreateTicketDisclaimerPopupComponent,
    IsMyEquipmentComponent,
    IsLdCustomerComponent,
    ContactAreaComponent,
    EquipmentActvCountComponent,
    AdditionalContactsComponent,
    TicketsOverviewComponent,
    TicketsHistoryComponent,
    TicketActionsComponent,
    TicketListItemsComponent,
    DeactivateEquipmentModalComponent,
    SyngoVoucherRequestModalComponent,
    EditComponentModalComponent,
    EditEquipmentInfoModalComponent,
    RescheduleActivityModalComponent,
    AsuAddWhitelistEntryModalComponent,
    DangerForPatientModalComponent,
    StateDownModalComponent,
    ContractDetailModalComponent,
    OptionsRecommendModalComponent,
    OptionsGetQuoteModalComponent,
    OptionsGetTrialModalComponent,
    MultiCountryImpersonationModalComponent,
    SwitchLanguageModalComponent,
    SelectEquipmentModalComponent,
    OptionsGetTrialModalComponent,
    ReportingModalComponent,
    TicketUpdateModalComponent,
    TicketCloseModalComponent,
    TicketSurveyModalComponent,
    TermsAndConditionModalComponent,
    IcCodeModalComponent,
    SimulatorCheckComponent,
    SimulatorModalComponent,
    ScheduleActivityModalComponent,
    ScheduleTrainingModalComponent,
    EquipmentMissingModalComponent,
    HowToModalComponent,
    ReportExportComponent,
    OverlayComponent,
    SavedFiltersComponent,
    ActivitiesOverviewComponent,
    AppointmentsOverviewComponent,
    AppointmentsEventLogsComponent,
    ContractsOverviewComponent,
    ContractListItemComponent,
    NotificationAttachmentsComponent,
    CreatePsrModalComponent,
    CreatePsrProductAreaComponent,
    CreatePsrServiceSummaryComponent,
    CreatePsrAcceptanceAuthorizationComponent,
    CreatePsrTemplatesComponent,
    CreateSaModalComponent,
    CreateSaoProductInformationComponent,
    CreateSaoOrderSummaryComponent,
    CreateSaoContactInformationComponent,
    CreateSaoShippingAddressComponent,
    CreateSaoBillingAddressComponent,
    CreateSaoDownloadComponent,
    CreateGroupModalComponent,
    EditCustomerNameModalComponent,
    VerifyPublicGroupModalComponent,
    CreateSaoEntryPageComponent,
    IntroFeatureModalComponent,
    IntroSliderGalleryComponent,
    AccordionWithEquipmentsComponent,
    SecurityDetailComponent,
    ContractEquipmentUsageComponent,
    InfoModalComponent,
    CreateUserNotificationsPageComponent,
    NotificationsSubscriptionModalComponent,
    EventLogsComponent,
    ActivitiesEventLogsComponent,
    TicketsEventLogsComponent,
    TicketsDeliveryMilestonesComponent,
    ActivitiesDeliveryMilestonesComponent,
    RegistrationModalComponent,
    CreateAdvisoryModalComponent,
    ConfirmationModalComponent,
    VisibilityConfirmationModalComponent,
    MetricsExportComponent,
    CreateAdvisoryNotificationModalComponent,
    CreateNewSystemUpdateModalComponent,
    CreateNewEquipmentForUpdateComponent,
    SystemUpdateInformationFormComponent,
    EquipmentSelectionForNewSystemUpdateComponent,
    EquipmentAddedToNewSystemUpdateComponent,
    NewSystemUpdateEquipmentListItemComponent,
    LongClickDirective,
    ChatBotComponent,
    ...createTicketComponents,
    EquipmentListItemTemplateMapPipe,
    MdmManageTabletModalComponent,
    RescheduleAppointmentModalComponent,
    NonEquipmentTemplateDirective,
    ListsCardComponent,
    AddEquipmentLinkParamsPipe,
    SubmitPoNumberPopupComponent,
    TranslatePropertyPipe,
    ReplacePipe,
    MarketingConsentRequiredComponent,
    InvoicesStatusMapComponent,
    DotRequestReschedulingModalComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BasicComponentsSharedModule,
    UserNameComponent,
    CreateTicketDisclaimerPopupComponent,
    IsMyEquipmentComponent,
    ActivitiesStatusMapComponent,
    IsLdCustomerComponent,
    DeactivateEquipmentModalComponent,
    SyngoVoucherRequestModalComponent,
    EditComponentModalComponent,
    EditEquipmentInfoModalComponent,
    RescheduleActivityModalComponent,
    AsuAddWhitelistEntryModalComponent,
    OptionsRecommendModalComponent,
    OptionsGetQuoteModalComponent,
    OptionsGetTrialModalComponent,
    MultiCountryImpersonationModalComponent,
    SwitchLanguageModalComponent,
    SelectEquipmentModalComponent,
    DangerForPatientModalComponent,
    StateDownModalComponent,
    ContractDetailModalComponent,
    ReportingModalComponent,
    TicketUpdateModalComponent,
    TicketCloseModalComponent,
    TicketSurveyModalComponent,
    TermsAndConditionModalComponent,
    IcCodeModalComponent,
    SimulatorModalComponent,
    ScheduleActivityModalComponent,
    ScheduleTrainingModalComponent,
    EquipmentMissingModalComponent,
    ContactAreaComponent,
    EquipmentActvCountComponent,
    AdditionalContactsComponent,
    TicketsOverviewComponent,
    TicketsHistoryComponent,
    TicketActionsComponent,
    TicketListItemsComponent,
    SimulatorCheckComponent,
    HowToModalComponent,
    ReportExportComponent,
    NotificationAttachmentsComponent,
    OverlayComponent,
    SavedFiltersComponent,
    ActivitiesOverviewComponent,
    AppointmentsOverviewComponent,
    AppointmentsEventLogsComponent,
    ContractsOverviewComponent,
    ContractListItemComponent,
    DiagramModule,
    CreatePsrModalComponent,
    CreateSaModalComponent,
    CreateGroupModalComponent,
    EditCustomerNameModalComponent,
    VerifyPublicGroupModalComponent,
    CreateSaoEntryPageComponent,
    IntroFeatureModalComponent,
    IntroSliderGalleryComponent,
    AccordionWithEquipmentsComponent,
    SecurityDetailComponent,
    ContractEquipmentUsageComponent,
    InfoModalComponent,
    CreateUserNotificationsPageComponent,
    NotificationsSubscriptionModalComponent,
    EventLogsComponent,
    ActivitiesEventLogsComponent,
    TicketsEventLogsComponent,
    TicketsDeliveryMilestonesComponent,
    ActivitiesDeliveryMilestonesComponent,
    RegistrationModalComponent,
    CreateAdvisoryModalComponent,
    ConfirmationModalComponent,
    VisibilityConfirmationModalComponent,
    MetricsExportComponent,
    CreateAdvisoryNotificationModalComponent,
    CreateNewSystemUpdateModalComponent,
    CreateNewEquipmentForUpdateComponent,
    SystemUpdateInformationFormComponent,
    EquipmentSelectionForNewSystemUpdateComponent,
    EquipmentAddedToNewSystemUpdateComponent,
    LongClickDirective,
    ChatBotComponent,
    ...createTicketComponents,
    EquipmentListItemTemplateMapPipe,
    MdmManageTabletModalComponent,
    RescheduleAppointmentModalComponent,
    NonEquipmentTemplateDirective,
    ListsCardComponent,
    AddEquipmentLinkParamsPipe,
    PortalsModule,
    SubmitPoNumberPopupComponent,
    TranslatePropertyPipe,
    ReplacePipe,
    MarketingConsentRequiredComponent,
    InvoicesStatusMapComponent,
    DotRequestReschedulingModalComponent
  ],
  providers: [FormGroupDirective, NgForm]
})
export class SharedModule {}
