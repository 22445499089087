import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../core/services/log/log.service';
import { CollapsableDirective } from '../../directives/collapsable/collapsable.directive';
import { includes, isEmpty } from 'lodash-es';
import { EventLog } from '../../../core/models/event-log';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum ServiceEventLogTimeStamp {
  SERVICE_EVENT_LOG_CREATE_DATE = 'ERDAT',
  SERVICE_EVENT_LOG_PLANNED_START_DATE = 'PSTER'
}

@Component({
  selector: 'hl-event-logs',
  templateUrl: './event-logs.component.html'
})
export class EventLogsComponent implements OnInit, OnDestroy {

  @Input() eventLogs: EventLog[];

  @ViewChild(CollapsableDirective, {static: true}) collapsable: CollapsableDirective;

  dateTimePattern = '';
  supportedEventLogTaskTypes: string[];
  serviceEventLogTimestamp: string;

  private readonly unsubscribe$ = new Subject<void>();

  logService: LogService;
  configService: CountryConfigRestService;

  constructor(configService: CountryConfigRestService,
    logService: LogService) {
    this.logService = logService;
    this.configService = configService;
  }

  /**
   * @description Initialize of call stack
   */
  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
      this.collapseAll();
    }, (some) => {
      this.logService.debug('what is the problem?13 ' + some);
    });
  }

  get collapsibleEventLogs(): EventLog[] {
    return this.eventLogs
      .filter(eventLog => !!(eventLog.serviceEngineer || eventLog.longText));
  }

  get showExpandAll(): boolean {
    return this.collapsibleEventLogs.length &&
      this.collapsibleEventLogs
        .some(eventLog => !eventLog.toggleStatus);
  }

  get showCollapseAll(): boolean {
    return this.collapsibleEventLogs.length &&
      !this.collapsibleEventLogs
        .some(eventLog => !eventLog.toggleStatus);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.init();
  }

  setConfigProperties(config: any): void {
    if (config.SHOW_EVENT_LOG_TASK_STARTTIME) {
      this.supportedEventLogTaskTypes = config.SHOW_EVENT_LOG_TASK_STARTTIME.split(',');
    }
    this.serviceEventLogTimestamp = config.TICKET_SERVICE_EVENT_LOG_TIME;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
  }

  collapseAll() {
    this.collapsable.toggleAllPanels(false);
    this.collapsibleEventLogs
      .forEach(eventLog => eventLog.toggleStatus = false);
  }

  expandAll() {
    this.collapsable.toggleAllPanels(true);
    this.collapsibleEventLogs
      .forEach(eventLog => eventLog.toggleStatus = true);
  }

  toggleStatus(eventLog: EventLog) {
    eventLog.toggleStatus = !eventLog.toggleStatus;
  }

  isTaskTypeSupported(taskType: string): boolean {
    return !isEmpty(this.supportedEventLogTaskTypes) && includes(this.supportedEventLogTaskTypes, taskType);
  }

  getServiceEventLogTimestamp(eventLog: EventLog): string {
    if (this.serviceEventLogTimestamp === ServiceEventLogTimeStamp.SERVICE_EVENT_LOG_PLANNED_START_DATE) {
      return eventLog.endDateTime;
    }
    return eventLog.creationDateTime;
  }

}
