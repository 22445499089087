import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class RefreshItemEventService {

  private refreshItemSource = new Subject<void>();

  refreshItemSource$ = this.refreshItemSource.asObservable();

  emitRefreshItem() {
    this.refreshItemSource.next();
  }

}
