import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { User } from '../../../core/models/user';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';
import { WindowService } from '../../../core/window.service';
import { ImpersonationUtilsService } from '../../../core/utils/impersonation-utils.service';
import { SelectOption } from '../../../core/models/select-option';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NumberFromIdPipe } from 'app/shared/pipes/number-from-id/number-from-id.pipe';

@Component({
  selector: 'hl-select-equipment-modal',
  templateUrl: './select-equipment-modal.component.html'
})
export class SelectEquipmentModalComponent extends BaseModalPopup implements OnInit {
  @Input() user: User;
  equipmentItems: EquipmentViewModel[];
  isSearchingFinished = true;
  selectedEquipment: EquipmentViewModel;
  selectedCountry: string;
  countries: SelectOption[];
  searchString: string;
  replace = false;
  customerNameLength = undefined;
  showSearchSpinner = false;
  searchWithResults: boolean;
  numberPipe = new NumberFromIdPipe();

  constructor(renderer: Renderer2,
    private equipmentRestService: EquipmentRestService,
    private windowService: WindowService,
    private impersonationUtils: ImpersonationUtilsService,
    private configService: CountryConfigRestService) {
    super(renderer);
  }

  ngOnInit(): void {
    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(config => {
      const lengthCustomerNamedDisplay = parseInt(config.LENGTH_CUSTOMER_NAME_DISPLAY, 10);
      this.customerNameLength = lengthCustomerNamedDisplay > 0 ? lengthCustomerNamedDisplay : undefined;
    });
  }

  ok() {
    this.showSpinner = true;
    this.hide();
  }

  show() {
    this.reset();
    super.show();
  }

  reset() {
    this.clearResults();
    this.searchWithResults = false;
    this.replace = false;
    this.searchString = '';
    this.selectedCountry = this.user.country;
    this.countryTranslation();
  }

  private clearResults() {
    this.showList(undefined);
  }

  private countryTranslation() {
    if (this.user.country.length > 1) {
      this.countries = this.impersonationUtils.generateCountryTranslationMapping(
        this.user.countries,
        true
      );
      this.countries.sort((a, b) => a.title.localeCompare(b.title));
    }
  }

  search() {
    this.clearResults();
    this.showSearchSpinner = true;
    this.isSearchingFinished = false;
    return this.equipmentRestService.getAllSelectableEquipments(this.selectedCountry, this.searchString)
      .subscribe(list => {
        this.equipmentItems = list;
        this.showList(list);
        this.showSearchSpinner = false;
      });
  }

  showList(list: EquipmentViewModel[]) {
    this.equipmentItems = list;
    if (this.hasSomeItems()) {
      this.searchWithResults = true;
    }
    this.selectedEquipment = undefined;
    this.isSearchingFinished = true;
  }

  select(item: EquipmentViewModel) {
    this.selectedEquipment = item;
  }

  goToSelected() {
    if (this.isSomethingSelected()) {
      this.windowService.nativeWindow.location.replace(
        '/rest/v1/deepLink'
        + `?customer=${this.numberPipe.transform(this.selectedEquipment.customerId)}`
        + `&siemensEquipmentId=${this.selectedEquipment.siemensId}`
        + `&country=${this.selectedCountry}`
        + `&deepLinkType=equipment_self`
        + (this.replace ? `&replace=${this.replace}` : ``));
    }
    this.ok();
  }

  isSomethingSelected() {
    return !!this.selectedEquipment;
  }

  showCountrySelection(): boolean {
    return this.user.countries.length > 1;
  }

  isSearchText(): boolean {
    return this.searchString && this.searchString.length > 0;
  }

  hasSomeItems(): boolean {
    return this.equipmentItems && this.equipmentItems.length > 0;
  }
}
