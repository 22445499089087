import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-sao-order-summary',
  templateUrl: './create-sao-order-summary.component.html'
})
export class CreateSaoOrderSummaryComponent {
  @Input() orderForm: UntypedFormGroup;
}
