<div class="form-horizontal" [formGroup]="productForm">
  <!--Invoice description-->
  <div class="form-group">
    <hl-textfield [isRequired]="productForm.controls['requestDescription'].errors && productForm.controls['requestDescription'].errors['required']"
      [formControl]="productForm.controls['requestDescription']" [name]="'requestDescription'" [label]="'LABEL_PSR_SHORT_DESCRIPTION'"
      invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !productForm.controls['requestDescription'].valid"
      ngDefaultControl></hl-textfield>
  </div>

  <!--detailed description-->
  <div class="form-group">
    <hl-textarea [isRequired]="productForm.controls['expectedOutcome'].errors && productForm.controls['expectedOutcome'].errors['required']"
      [parentForm]="productForm" [name]="'expectedOutcome'" [labelText]="'LABEL_PSR_DETAILED_DESCRIPTION'" [isLarge]="true" [maxlength]="4000"
      [isInvalid]="isFormSubmitted && !productForm.controls['expectedOutcome'].valid"></hl-textarea>
    @if (isFormSubmitted && !productForm.controls['expectedOutcome'].valid) {
      <small class="textfield__hint textfield__hint--validation" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
    }
  </div>

  <!--additional information-->
  <div class="form-group">
    <hl-textarea [parentForm]="productForm" [name]="'additionalDescription'" [labelText]="'LABEL_PSR_ADDITIONAL_INFORMATION'" [isLarge]="true"
      [maxlength]="1000"></hl-textarea>
  </div>

  <!--Priority-->
  <div class="form-group">
    <hl-selectbox [formControl]="productForm.controls['priority']" [options]="priorities" label="LABEL_PSR_PRIORITY" ngDefaultControl></hl-selectbox>
  </div>

  <!--Impact to site-->
  <div class="form-group">
    <hl-textfield label="LABEL_PSR_IMPACT" name="siteImpact" [formControl]="productForm.controls['siteImpact']" placeholder="LABEL_OPTIONAL"
      ngDefaultControl></hl-textfield>
  </div>

  <!--Purchase order number-->
  <div class="form-group">
    <hl-textfield label="LABEL_PSR_ORDER_NUMBER" name="purchaseOrderNumber" [formControl]="productForm.controls['purchaseOrderNumber']"
      placeholder="LABEL_OPTIONAL" ngDefaultControl></hl-textfield>
  </div>
</div>
