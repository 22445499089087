<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'LABEL_ADVISORY_UPLOAD_ATTACHMENT'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="cancelUploadModal.show()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div [formGroup]="subprocessorUploadForm" class="modal-dialog__body" id="modal1_desc">
        @if (!showSpinner) {
          <hl-attachment [parentForm]="subprocessorUploadForm" [isFormSubmitted]="isFormSubmitted" [ignoreFileLengthConfig]="true"
            [checkAttachmentDisable]="true" [disableAttachmentCount]="1" #filePackageInfoForm></hl-attachment>
        } @else {
          <hl-progress-indicator></hl-progress-indicator>
        }
      </div>

      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button" translate="GENERIC_LABEL_CANCEL" (click)="cancelUploadModal.show()"></button>
          <button class="button button--primary" (click)="ok()" [disabled]="isSubmitFormDisabled()" translate="GENERIC_LABEL_UPLOAD"></button>
        </div>
      }
    </div>
  </div>

  <hl-cancel-modal (yesClick)="resetAndHide(pdfUpload)" [descriptionMessage]="'SYSTEM_UPDATES_UPLOAD_CONFIRM_CANCELLATION'"
    #cancelUploadModal></hl-cancel-modal>
</ng-template>
