<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="manage-tablet-modal">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'LABEL_MDM_REGISTRATION'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="cancelMdmManageTabletModal.show()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>

      <div class="modal-dialog__body" id="modal1_desc">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          @if (!equipment) {
            <div>
              <hl-selectbox [options]="dropdownActions" label="LABEL_CUSTOMER_TYPE" [(value)]="selectedAction" (valueChange)="onChangeAction()"
                [label]="'LABEL_MDM_MANAGE_TABLET_ACTION'" [translateKeys]="true"></hl-selectbox>
            </div>
          }
          <form [formGroup]="mdmForm">
            <h5 class="margin-top-0" [translate]="'LABEL_MDM_BUSINESS_LINE'"></h5>
            <hl-selectbox label="LABEL_MDM_BUSINESS_LINE" [formControl]="mdmForm.controls['businessLine']" [options]="businessLines"
              [translateKeys]="true" [isDisabled]="!!equipment" (valueChange)="onSelectBusinessLine()"
              [isRequired]="mdmForm.controls['businessLine'].errors && mdmForm.controls['businessLine'].errors['required']"
              ngDefaultControl></hl-selectbox>
            @if (isFormSubmitted && !mdmForm.controls['businessLine'].valid) {
              <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
            }
            <h5 class="margin-top-0" [translate]="'LABEL_MDM_SYSTEM_INFORMATION'"></h5>
            <hl-textfield label="GENERIC_LABEL_SERIAL_NUMBER" name="systemSerialNumber" [formControl]="mdmForm.controls['systemSerialNumber']"
              [maxlength]="6"
              [isRequired]="mdmForm.controls['systemSerialNumber'].errors && mdmForm.controls['systemSerialNumber'].errors['required']"
              [isInvalid]="(mdmForm.controls['systemSerialNumber'].touched || isFormSubmitted) && !mdmForm.controls['systemSerialNumber'].valid"
              invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isDisabled]="!!equipment" ngDefaultControl></hl-textfield>
            @if (equipment !== null) {
              <hl-textfield label="LABEL_MATERIAL_NUMBER" name="systemMaterialNumber" [formControl]="mdmForm.controls['systemMaterialNumber']"
                [maxlength]="18"
                [isRequired]="mdmForm.controls['systemMaterialNumber'].errors && mdmForm.controls['systemMaterialNumber'].errors['required']"
                [isInvalid]="(mdmForm.controls['systemMaterialNumber'].touched || isFormSubmitted) && !mdmForm.controls['systemMaterialNumber'].valid"
                invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isDisabled]="!!equipment" ngDefaultControl></hl-textfield>
            } @else {
              <hl-nested-selectbox [options]="whitelistedMaterialNumbers$ | async" [enableSearch]="true" [(valueObject)]="selectedMaterialNumber"
                (valueObjectChange)="onMaterialNumberChange()" [isRequired]="true" [label]="'LABEL_MATERIAL_NUMBER'" [setCssDisplayBlock]="true"
                [enableCollapsing]="false" [collapseOnOpen]="false" [resetOnSelect]="true" [resetOnOpen]="true"
                [isDisabled]="mdmForm.controls['businessLine'].errors && mdmForm.controls['businessLine'].errors['required']"></hl-nested-selectbox>
            }
            <hl-selectbox label="LABEL_SW_PLATFORM_VERSION" [formControl]="mdmForm.controls['swPlatformVersion']" [options]="swVersions"
              [isRequired]="mdmForm.controls['swPlatformVersion'].errors && mdmForm.controls['swPlatformVersion'].errors['required']"
              [isDisabled]="!!equipment" [isDisabled]="mdmForm.controls['businessLine'].errors && mdmForm.controls['businessLine'].errors['required']"
              ngDefaultControl></hl-selectbox>
            @if (isFormSubmitted && !mdmForm.controls['swPlatformVersion'].valid) {
              <small class="selectbox__hint selectbox__hint--validation" [translate]="'GENERIC_LABEL_SELECT_FIELD'"></small>
            }
            <h5 class="margin-top-0" [translate]="'LABEL_MDM_TABLET_INFORMATION'"></h5>
            @if (selectedAction === '1' && assignedDevicesSerialNumbers.length === 0) {
              <hl-textfield label="LABEL_MDM_REMOVED_SERIAL_NUMBER" name="removedTabletSerialNumber"
                [formControl]="mdmForm.controls['removedTabletSerialNumber']" [maxlength]="30"
                [isRequired]="mdmForm.controls['removedTabletSerialNumber'].errors && mdmForm.controls['removedTabletSerialNumber'].errors['required']"
                [isInvalid]="(mdmForm.controls['removedTabletSerialNumber'].touched || isFormSubmitted) && !mdmForm.controls['removedTabletSerialNumber'].valid"
                invalidLabel="GENERIC_LABEL_FILL_INFORMATION" ngDefaultControl></hl-textfield>
            }
            @if (selectedAction === '1' && assignedDevicesSerialNumbers.length > 0) {
              <hl-selectbox label="LABEL_MDM_REMOVED_SERIAL_NUMBER" [formControl]="mdmForm.controls['removedTabletSerialNumber']"
                [options]="assignedDevicesSerialNumbers"
                [isRequired]="mdmForm.controls['removedTabletSerialNumber'].errors && mdmForm.controls['removedTabletSerialNumber'].errors['required']"
                ngDefaultControl></hl-selectbox>
            }
            <hl-textfield [label]="(selectedAction === '0' ? 'LABEL_MDM_TABLET_SERIAL_NUMBER' : 'LABEL_MDM_NEW_SERIAL_NUMBER') | translate"
              name="newTabletSerialNumber" [formControl]="mdmForm.controls['newTabletSerialNumber']" [maxlength]="30"
              [isRequired]="mdmForm.controls['newTabletSerialNumber'].errors && mdmForm.controls['newTabletSerialNumber'].errors['required']"
              [isInvalid]="(mdmForm.controls['newTabletSerialNumber'].touched || isFormSubmitted) && !mdmForm.controls['newTabletSerialNumber'].valid"
              invalidLabel="GENERIC_LABEL_FILL_INFORMATION" ngDefaultControl></hl-textfield>
            @if (selectedAction === '0' && options.length > 0) {
              <span>
                <h5 class="margin-top-0" [translate]="'LABEL_MDM_SECTION_FOR_FACTORY'"></h5>
                <span [translate]="'LABEL_MDM_OPTION_AVAILABILITY'"></span>
                @for (option of options; track option) {
                  <div>
                    <fieldset>
                      <span>{{ option.name }}</span>
                      <ul class="list list--inline bare-list">
                        <li class="list__item">
                          <input class="radio radio--inline" id="yes{{option.id}}" type="radio" formControlName="option{{option.id}}" value="true"/>
                          <label for="yes{{option.id}}" [translate]="'GENERIC_LABEL_YES'" (click)="toggleOptionTrue(option)"></label>
                        </li>
                        <li class="list__item">
                          <input class="radio radio--inline" id="no{{option.id}}" type="radio" formControlName="option{{option.id}}" value="false"/>
                          <label for="no{{option.id}}" [translate]="'GENERIC_LABEL_NO'" (click)="toggleOptionFalse(option)"></label>
                        </li>
                      </ul>
                    </fieldset>
                  </div>
                }
              </span>
            }
          </form>
        }
      </div>

      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button class="button" (click)="cancelMdmManageTabletModal.show()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
          <button class="button button--primary" (click)="replaceOrRegisterTablet()" [disabled]="!mdmForm.valid"
            [translate]="'BUTTON_MDM_REGISTER_TABLET'" data-cy="mdm-register-button"></button>
        </div>
      }

    </div>
  </div>
  <hl-cancel-modal (yesClick)="resetAndHide()" [descriptionMessage]="'MDM_REGISTRATION_CONFIRM_CANCELLATION'"
    #cancelMdmManageTabletModal></hl-cancel-modal>
</ng-template>
