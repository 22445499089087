import { Component, Input, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { ContractsUtilService } from '../../../core/services/contracts/contracts-util.service';
import { ItemDetailViewModel } from '../../../core/view-models/item-detail-view-model';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';

@Component({
  selector: 'hl-contract-detail-modal',
  templateUrl: './contract-detail-modal.component.html'
})
export class ContractDetailModalComponent extends BaseModalPopup {

  @Input() equipmentItem: EquipmentViewModel;

  isLoaded = false;
  contractDetailList: ItemDetailViewModel[] = [];

  constructor(private contractsUtilService: ContractsUtilService,
              renderer: Renderer2) {
    super(renderer);
  }

  init() {
    this.contractsUtilService.getContractsDetails(this.equipmentItem.contractNumber, this.equipmentItem.key).pipe(
      finalize(() => {
        this.isLoaded = true;
      }))
      .subscribe((contractDetailsResponse) => {
        this.contractDetailList = contractDetailsResponse;
      });
  }

  show() {
    super.show();
    if (this.equipmentItem) {
      this.isLoaded = false;
      this.init();
    }
  }

  hide() {
    // reset not to see template values being cached
    this.contractDetailList = [];
    super.hide();
  }

  ok() {
    // intentionally left empty
  }
}
