import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadMoreComponent } from '../shared/components/read-more/read-more.component';
import { ReadMoreArraysComponent } from '../shared/components/read-more/read-more-arrays/read-more-arrays.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ReadMoreComponent,
    ReadMoreArraysComponent
  ],
  exports: [
    ReadMoreComponent,
    ReadMoreArraysComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class ReadMoreComponentsSharedModule { }
