import { Component, Input, ViewChild } from '@angular/core';
import { OverlayDirective } from '../../directives/overlay-popup/overlay.directive';

@Component({
  selector: 'hl-info-modal',
  templateUrl: './info-modal.component.html'
})
export class InfoModalComponent {

  @Input() dataCyHeader: string;
  @Input() title: string;
  @Input() closeLabel: string;

  @ViewChild('overlayPopup')
  overlayDirective: OverlayDirective;

  show() {
    this.overlayDirective.show();
  }

  hide() {
    this.overlayDirective.hide();
  }
}
