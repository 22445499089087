<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">

      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'HEADER_REQUEST_TRIAL_LICENSE_FORM'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>

      <div class="modal-dialog__body" id="modal1_desc">

        @if (showValidationMessage && !showSpinner) {
          <div class="alert">
            <div class="alert__body">
              {{ translationErrorMessage | translate }}
            </div>
          </div>
        }

        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

        <form class="form" #modalForm="ngForm" [formGroup]="getOptionsForm">
          @if (!showSpinner) {
            <div>
              <p>
                <span [translate]="'REQUEST_TRIAL_LICENSE_TIME_SPECIFICATION'"></span>&shy; <span [translate]="'GENERIC_LABEL_RESPONSE_TIME'"></span>
              </p>
              <fieldset>
                <span [translate]="'REMOTE_INSTALLATION_AGREE'"></span>
                <ul class="list list--inline bare-list">
                  <li class="list__item">
                    <input type="radio" name="remoteInstallation" formControlName="remoteInstallation" id="remoteInstallationAgree"
                      class="radio radio--inline" [value]="true">
                    <label for="remoteInstallationAgree" [translate]="'I_AGREE_LABEL'"></label>
                  </li>
                  <li class="list__item">
                    <input type="radio" name="remoteInstallation" formControlName="remoteInstallation" id="remoteInstallationDisagree"
                      class="radio radio--inline" [value]="false">
                    <label for="remoteInstallationDisagree" [translate]="'I_DO_NOT_AGREE'"></label>
                  </li>
                </ul>
              </fieldset>
              <fieldset>
                <span [translate]="'FROM_WHEN_TEST_VERSION_USE_LABEL'"></span>
                <div class="last-child">
                  <div [ngClass]="{'invalid': isFormSubmitted && !getOptionsForm.controls['testStartDate'].valid}">
                    <hl-date-picker name="testStartDate" id="testStartDate" [disabled]="equipmentItem === null"
                      [formControl]="getOptionsForm.controls['testStartDate']" ngDefaultControl
                      [isRequired]="getOptionsForm.controls['testStartDate'].errors && getOptionsForm.controls['testStartDate'].errors['required']"
                      [minDate]="minDate" [labelText]="'PLANNED_START_DATE'" required
                      [isInvalid]="isFormSubmitted && !getOptionsForm.controls['testStartDate'].valid"
                      [invalidLabel]="'GENERIC_LABEL_FILL_INFORMATION'"></hl-date-picker>
                  </div>
                </div>
              </fieldset>
              <!-- contact -->
              <fieldset>
                <hl-contact-area #contactArea [useSalutationTitleValue]="true" [showContactTitles]="false" [showAdditionalContacts]="false"
                  [labelContact]="''" [alwaysFullWidth]="true" [isDisabled]="equipmentItem === null"
                  [contactForm]="getOptionsForm.controls['contact']" [isFormSubmitted]="isFormSubmitted"></hl-contact-area>
              </fieldset>
              <fieldset>
                <span [translate]="'ADDITIONAL_INFO_LABEL'"></span>
                <div class="form-group">
                  <hl-textarea [name]="'additionalInformation'" [labelText]="'ADDITIONAL_INFORMATION'" [isLarge]="true" [maxlength]="2000"
                    [isDisabled]="getFormProperty('equipmentKeys').length === 0" [parentForm]="getOptionsForm"></hl-textarea>
                </div>
              </fieldset>
              <fieldset>
                <div>
                  <input type="checkbox" name="contactMe" id="contactMe" class="checkbox" formControlName="contactMe"/>
                  <label for="contactMe" [translate]="'YES_CONTACT_ME'"></label>
                </div>
                <div class="last-child">
                  <div [ngClass]="{'invalid': isFormSubmitted && !getOptionsForm.controls['termsAndCondition'].valid}">
                    <input type="checkbox" name="termsAndCondition" id="termsAndCondition" class="checkbox" formControlName="termsAndCondition"
                      required/>
                    <label for="termsAndCondition">
                      <span [translate]="'AGREEMENT_LABEL1'"></span>
                      <span>&nbsp;</span>
                      <a class="termsAndC color-orange" [href]="termsAndConditionsLink | safeDom : 'resourceUrl'"
                        [translate]="'LABEL_AGREEMENT_TRIAL_USE_LINK'" target="_blank" rel="noopener noreferrer">
                      </a>
                      <span>&nbsp;</span>
                      <span [translate]="'AGREEMENT_LABEL2'">&nbsp;</span>
                    </label>
                    @if (isFormSubmitted && !getOptionsForm.controls['termsAndCondition'].valid) {
                      <small class="checkbox__hint" [translate]="'GENERIC_LABEL_FILL_INFORMATION'"></small>
                    }
                  </div>
                </div>
              </fieldset>
            </div>
          }
        </form>

      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [isFormValid]="modalForm.valid"
          [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true" label="SEND_LABEL" name="isFormSubmitted"
          ngDefaultControl></hl-simulator-check>
      }
    </div>
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
