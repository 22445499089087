import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class OverlayCommunicationService {

  private onOverlaySource: Subject<Event> = new Subject();
  onOverlayChange$ = this.onOverlaySource.asObservable();

  emitCloseOverlayAfterSave(event: Event) {
    this.onOverlaySource.next(event);
  }
}
