import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PsrRestService } from '../rest-services/psr-rest.service';
import { LifeNetUtilService } from './life-net-util.service';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { ProfessionalServiceRequestViewModel } from '../view-models/professional-service-request-view-model';
import { PsrTypeEnum } from '../core-constants.service';
import { PsrType } from 'app/core/models/psr/psr-status-type';
import { filter, find, forEach, isEmpty, map as lodashMap } from 'lodash-es';
import { SelectOption } from 'app/core/models/select-option';
import { LineItem } from '../models/psr/line-item';
import { TranslateService } from '@ngx-translate/core';
import { PsrTemplateByModality } from '../models/psr/psr-template-by-modality';
import { map, tap } from 'rxjs/operators';
import { RemoteWorkForceViewModel } from '../models/psr/remote-work-force-view-model';
import { RemoteWorkForceRequest } from '../models/psr/remote-work-force-request';
import { DateUtilService } from './date-util.service';
import { RwfAppointmentStateViewModel } from '../models/psr/rwf-time-slots';
import { RwfSlotCancellationStatus } from '../models/psr/rwf-slot-cancellation-status';

/**
 * Available in core as it is used in two modules, CreateProfessionalServiceRequest and ProfessionalServiceRequest
 */
@Injectable({providedIn: 'root'})
export class PsrUtilService {

  private psrCreatedSource = new Subject<void>();
  psrCreatedSource$ = this.psrCreatedSource.asObservable();

  private rwfCreatedSource = new Subject<void>();
  rwfCreatedSource$ = this.rwfCreatedSource.asObservable();

  private wescanCreatedSource = new Subject<void>();
  wescanCreatedSource$ = this.wescanCreatedSource.asObservable();

  canceledTimeSlots: Map<string, RwfAppointmentStateViewModel> = new Map<string, RwfAppointmentStateViewModel>();

  constructor(private psrRestService: PsrRestService,
    private lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private translateService: TranslateService,
    private dateUtilService: DateUtilService) {
  }

  getPsrViewModelList(): Observable<ProfessionalServiceRequestViewModel[]> {
    const findModalityObject = {
      findKey: 'modalityCode',
      findValue: 'modality',
      propertiesToMerge: ['modalityDescription']
    };

    const findPsrTypeObject = {
      findKey: 'type',
      findValue: 'quoteStatus',
      propertiesToMerge: ['psrTypeDescription']
    };

    const psrViewModel = this.lifeNetUtilService.createViewModels(this.equipmentRestService.getModalities(),
      this.psrRestService.getPsrList(), findModalityObject);

    return this.lifeNetUtilService.createViewModels(this.getPsrDropDownValuesByType(PsrTypeEnum.STAT), psrViewModel, findPsrTypeObject);
  }

  getPsrDropDownValuesByType(type: PsrTypeEnum): Observable<PsrType[]> {
    return this.psrRestService.getPsrTypeCode(type);
  }

  convertPsrTypesToSelectOptions(type: PsrTypeEnum): Observable<SelectOption[]> {
    return this.getPsrDropDownValuesByType(type).pipe(map((psrStatusTypes: PsrType[]) => {
      return lodashMap(psrStatusTypes, (psrStatusType) => {
        return {
          value: psrStatusType.type,
          title: psrStatusType.psrTypeDescription
        };
      });
    }));
  }

  convertLineItemsToSelectOptions(lineItem: LineItem[]): SelectOption[] {

    const lineItemOption: SelectOption[] = [];
    forEach(lineItem, (item) => {
      lineItemOption.push({
        // tslint:disable-next-line:ban
        title: `${this.translateService.instant('LABEL_PSR_LINE_ITEM')} ${item.line}`,
        value: item.line
      });
    });

    return lineItemOption;
  }

  getSinglePsr(param: string) {
    return this.getPsrViewModelList()
      .pipe(map(psrViewModelList => psrViewModelList.filter(psr => psr.quoteNumber.toString() === param)[0]));
  }

  filterPsrTemplates(filteredModalities: SelectOption[]): Observable<PsrTemplateByModality[]> {
    return this.psrRestService.getPsrTemplates().pipe(map(psrTemplateGroup => {
      return filter(psrTemplateGroup, group => {
        const findModality = find(filteredModalities, {value: group.modality});
        return !isEmpty(findModality);
      });
    }));
  }

  emitPsrCreated() {
    this.psrCreatedSource.next();
  }

  getRemoteWorkForceRequests(): Observable<RemoteWorkForceViewModel[]> {
    return this.psrRestService.getRemoteWorkForceRequests().pipe(map((r: RemoteWorkForceRequest[]) => r.map(rwf => ({
      ...rwf,
      creationDate: this.dateUtilService.convertUtcToLocal(rwf.creationDate)
    }))));
  }

  emitRwfCreated() {
    this.rwfCreatedSource.next();
  }

  emitWescanCreated() {
    this.wescanCreatedSource.next();
  }

  getSingleRwf(param: string): Observable<RemoteWorkForceViewModel> {
    return this.getRemoteWorkForceRequests()
      .pipe(map(rwfList => rwfList.find(rwf => rwf.id === param)));
  }

  getBookingRequestTimeSlot(id: string): Observable<RwfAppointmentStateViewModel[]> {
    return this.psrRestService.getBookingRequestTimeSlots(id).pipe(map(bookingTimeSlots => {
      bookingTimeSlots.forEach(timeSlot => {
        if (this.canceledTimeSlots.has(timeSlot.id)) {
          timeSlot.cancellationInProgress = true;
          this.canceledTimeSlots.set(timeSlot.id, timeSlot);
        }
      });
      return bookingTimeSlots;
    }));
  }

  cancelBookedTimeSlot(appointmentToCancel: RwfAppointmentStateViewModel, requestId: string): Observable<RwfSlotCancellationStatus> {
    this.canceledTimeSlots.set(appointmentToCancel.id, appointmentToCancel);
    return this.psrRestService.cancelBookedTimeSlot(appointmentToCancel.id).pipe(
      tap(response => {
        this.psrRestService.clearBookingRequestSlotsByIdCache(requestId);
        if (response?.status === 200) {
          this.canceledTimeSlots.get(appointmentToCancel.id).state = '7';
        }
        this.canceledTimeSlots.get(appointmentToCancel.id).cancellationInProgress = false;
        this.canceledTimeSlots.delete(appointmentToCancel.id);
      })
    );
  }
}
