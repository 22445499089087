import { Injectable } from '@angular/core';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ModalityUtilService {

  // RxJS operator that, when applied to an observable of an array, maps each items modalityTranslation to modality
  translateModality<T extends { modality: string, modalityTranslation: string }>(): OperatorFunction<T[], T[]>  {
    return map((vmList: { modality: string, modalityTranslation: string }[]) =>
      (vmList || []).map(vm => ({ ...vm, modality: vm.modalityTranslation} as T))
    );
  }
}
