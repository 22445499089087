@if (open) {
  <div class="sn-chat-bot__spinner-background">
    <hl-progress-indicator class="sn-chat-bot__spinner" [loading]="!authenticated"></hl-progress-indicator>
  </div>
}
@if (!hidden) {
  <div id="sn_va_client_wrapper" class="sn-chat-bot__wrapper" [class.hidden]="!open">
    <div class="sn-chat-bot__wrapper__header">
      <span (click)="toggle()"></span>
    </div>
    @if (authenticated) {
      <iframe id="sn_va_web_client" title="ServiceNow Virtual Agent Client" [src]="virtualAgentUrl | safeDom: 'resourceUrl'"></iframe>
    }
  </div>
  <button class="sn-chat-bot__launcher" (click)="toggle()" [class.as-close]="open"></button>
}
