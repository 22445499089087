import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { forEach, isEqual } from 'lodash-es';

@Component({
  selector: 'hl-create-sao-billing-address',
  templateUrl: './create-sao-billing-address.component.html'
})
export class CreateSaoBillingAddressComponent implements OnInit {

  @Input() billingForm: UntypedFormGroup;
  @Input() shippingForm: UntypedFormGroup;
  @Input() isFormSubmitted: boolean;
  showBillingAddressFields: boolean;

  ngOnInit() {
    this.init();
  }

  init() {
    this.onActiveBillingAddressChange();
  }

  onActiveBillingAddressChange() {
    this.showBillingAddressFields = isEqual(this.billingForm.get('isActive').value, 'true');
    if (!this.showBillingAddressFields) {
      forEach(this.billingForm.value, (key, value) => {
        this.billingForm.get(value).clearValidators();
        this.billingForm.get(value).updateValueAndValidity();
      });
    } else {
      forEach(this.billingForm.value, (key, value) => {
        this.billingForm.get(value).setValidators([Validators.required]);
        this.billingForm.get(value).updateValueAndValidity();
      });
    }
  }
}
