import { Injectable } from '@angular/core';
import { SecurityApplianceOrderRestService } from '../../rest-services/security-appliance-order-rest.service';
import { SecurityApplianceOrder } from '../../models/securityApplianceOrders/security-appliance-order';
import { SecurityApplianceOrderAddress } from '../../models/securityApplianceOrders/security-appliance-order-address';
import { isEqual } from 'lodash-es';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SecurityApplianceOrderUtilService {
  constructor(
    private saoRestService: SecurityApplianceOrderRestService,
    private router: Router
  ) {
  }

  createSao(formValue: any): Observable<any> {
    const shippingAddress: SecurityApplianceOrderAddress = {
      receiverName: formValue.shipping.receiverName,
      customerName: formValue.shipping.customerName,
      department: formValue.shipping.department,
      building: formValue.shipping.building,
      street: formValue.shipping.street,
      postalCode: formValue.shipping.postalCode,
      city: formValue.shipping.city
    };

    let billingAddress: SecurityApplianceOrderAddress = null;
    if (isEqual(formValue.billing.isActive, 'true')) {
      billingAddress = {
        receiverName: formValue.billing.receiverName,
        customerName: formValue.billing.customerName,
        department: formValue.billing.department,
        building: formValue.billing.building,
        street: formValue.billing.street,
        postalCode: formValue.billing.postalCode,
        city: formValue.billing.city
      };
    }

    const saoRequest: SecurityApplianceOrder = {
      equipmentKey: formValue.product.equipmentKey,
      customerId: formValue.product.customerId,
      ipAddress: formValue.product.ipAddress,
      ipAddressFirewall: formValue.product.ipAddressFirewall,
      srsConnectionReference: formValue.product.srsConnection,
      targetPorts: formValue.product.targetPorts,
      managedSecurityService: formValue.order.additionalOption,
      itAdminName: formValue.contactIT.itAdministratorName,
      itAdminSiemensContact: formValue.contactIT.siemensContact,
      itAdminEmail: formValue.contactIT.itAdministratorEmail,
      itAdminPhone: formValue.contactIT.itAdministratorPhone,
      billingAddress,
      shippingAddress,
      contact: formValue.contact
    };

    return this.saoRestService.createSAO(saoRequest);
  }

  back(equipmentKey: string) {
    this.router.navigate(['/equipment', equipmentKey, 'info']);
  }

  getAllowedOrders() {
    return this.saoRestService.getAllowedOrders();
  }
}
