import { Component, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';

@Component({
  selector: 'hl-reporting-modal',
  templateUrl: './reporting-modal.component.html'
})
export class ReportingModalComponent extends BaseModalPopup {

  constructor( renderer: Renderer2 ) {
    super(renderer);
  }

  ok() {
    // intentionally left empty
  }
}
