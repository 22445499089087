<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'IMPERSONATE_TO_EQUIPMENT_TITLE'">Select equipment</h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div id="modal2_desc" class="modal-dialog__body overflow-inherit reduce-max-body-height" [class.overflow-inherit]="!hasSomeItems()">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

        @if (!showSpinner) {
          <div>
            @if (showCountrySelection()) {
              <div class="row">
                <div class="col-sm-9">
                  <hl-selectbox [options]="countries" label="LABEL_COUNTRY" [(value)]="selectedCountry" [translateKeys]=true
                    id="userCountries"></hl-selectbox>
                </div>
              </div>
            }
            <div class="row align-items-center">
              <div class="col-sm-9">
                <hl-search-field searchFieldClasses="textfield--large" [(searchInput)]="searchString"></hl-search-field>
              </div>
              <div class="col-sm-3 margin-top-auto text-align-right">
                <button type="button" class="button" (click)="search()" [translate]="'GENERIC_BUTTON_SEARCH'" [disabled]="!isSearchText()"></button>
              </div>
            </div>
            <hl-progress-indicator [loading]="showSearchSpinner"></hl-progress-indicator>
            @if (!showSearchSpinner) {
              <div class="row">
                <div class="col-12">
                  @if (equipmentItems) {
                    <hl-list [items]="equipmentItems" [isLoaded]="isSearchingFinished" [sortConfig]="" empty="EQUIPMENT_NO_EQUIPMENTS_FOUND">
                      <ng-template let-item>
                        <div class="collection-item list-item" [ngClass]="{'is-active': selectedEquipment === item}" (click)="select(item)">
                          <div class="collection__content">
                            <div class="media-object">
                              <div class="media-object__image">
                                <hl-equipment-image class="widget-item-visual hidden-xs" [equipment]="item" [large]="false"></hl-equipment-image>
                              </div>
                              <div class="media-object__body">
                                <div class="display-flex align-items-center">
                                  @if (item.productName) {
                                    <p class="list-item__title margin-bottom-0">{{ item.productName }}</p>
                                  }
                                </div>
                                <div class="font-size-small color-black-60">{{ item.siemensId }}</div>
                                <ul class="bare-list info-list margin-bottom-0">
                                  @if (item.myEquipmentName) {
                                    <li class="info-list__item">
                                      <i class="icon-equipment-name" hlTooltip
                                        [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i> {{ item.myEquipmentName }}
                                    </li>
                                  }
                                  @if (item.serialNumber) {
                                    <li class="info-list__item">
                                      <i class="icon-id-card" hlTooltip
                                        [title]="'GENERIC_LABEL_SERIAL_NUMBER' | translate"></i> {{ item.serialNumber }}
                                    </li>
                                  }
                                  @if (item.customerName) {
                                    <li class="info-list__item">
                                      <i class="icon-user" hlTooltip [title]="'GENERIC_LABEL_CUSTOMER_NAME' | translate"></i>
                                      <span hlTooltip [title]="item.customerName" [isActive]="item.customerName.length > this.customerNameLength">
                                      {{ item.customerName | truncate:[this.customerNameLength] }}</span>
                                    </li>
                                  }
                                  @if (item.street) {
                                    <li class="info-list__item">
                                      <i class="icon-location-pin" hlTooltip [title]="'GENERIC_LABEL_STREET' | translate"></i> {{ item.street }}
                                    </li>
                                  }
                                  @if (item.zip) {
                                    <li class="info-list__item">
                                      <i class="icon-email" hlTooltip [title]="'GENERIC_LABEL_POSTAL_CODE' | translate"></i> {{ item.zip }}
                                    </li>
                                  }
                                  @if (item.city) {
                                    <li class="info-list__item">
                                      <i class="icon-department" hlTooltip [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ item.city }}
                                    </li>
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </hl-list>
                  }
                </div>
              </div>
            }
          </div>
        }
      </div>
      @if (searchWithResults && !showSpinner) {
        <div class="modal-dialog__footer flex-column">
          <div class="margin-bottom-tiny-5 align-items-start">
            <input type="checkbox" class="checkbox " id="replace" [(ngModel)]="replace">
            <label class="align-items-center display-flex checkbox-vertical_align" for="replace"
              [translate]="'IMPERSONATE_TO_EQUIPMENT_REPLACE_LABEL'"></label>
          </div>
          <div class="display-flex margin-0 webkit-only-flex-basis-full justify-content-end">
            <button type="button" class="button button--primary margin-0 margin-left-tiny-5" [disabled]="!isSomethingSelected()"
              (click)="goToSelected()" [translate]="'IMPERSONATE_TO_EQUIPMENT_BUTTON_GO_TO'"></button>
            <button type="button" class="button margin-0 margin-left-tiny-5" data-dismiss="modal-dialog" (click)="hide()"
              [translate]="'GENERIC_LABEL_CANCEL'"></button>
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>
