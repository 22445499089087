import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CheckoutStep } from './checkout-step';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-checkout-steps',
  templateUrl: './checkout-steps.component.html',
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutStepsComponent {
  steps = input.required<CheckoutStep[]>();
  currentIndex = input.required<number>();
  disabled = input<boolean>(false);

  isActive(index: number): boolean {
    return index === this.currentIndex();
  }

  isCompleted(index: number): boolean {
    return index < this.currentIndex();
  }
}
