import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { Contact } from '../../../core/models/contact';
import { Equipment } from '../../../core/models/equipment/equipment';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { EquipmentConstantsService } from '../../../core/services/equipment/equipment-constants.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SpaceValidator } from '../../validators/space.validator';
import { User } from '../../../core/models/user';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-options-recommend-modal',
  templateUrl: './options-recommend-modal.component.html'
})
export class OptionsRecommendModalComponent extends BaseFormModalPopup implements OnInit, OnChanges {

  @Input() equipmentItem: Equipment;

  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  statusColorMap: any;

  contact: Contact = {
    contactSalutation: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: ''
  };

  getOptionsForm: UntypedFormGroup;

  clinicalSpecialities: any[];
  /** array of numbers */

  private config: any;

  constructor(
    protected fb: UntypedFormBuilder,
    protected userRestService: UserRestService,
    protected equipmentRestService: EquipmentRestService,
    protected equipmentConstantsService: EquipmentConstantsService,
    protected toasterService: ToasterService,
    protected configService: CountryConfigRestService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.initProperties();
  }

  initProperties() {
    this.clinicalSpecialities = this.equipmentConstantsService.getClinicalFieldSkeleton();
    this.statusColorMap = {};

    const config$ = this.configService.getConfig();
    const user$ = this.userRestService.getUser();

    combineLatest([config$, user$]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([configResponse, userResponse]) => {
        const greenStatus = configResponse.EQUIPMENT_STATUS_GREEN;
        const redStatus = configResponse.EQUIPMENT_STATUS_RED;
        const yellowStatus = configResponse.EQUIPMENT_STATUS_YELLOW;

        // compute the status color mapping from country config
        this.statusColorMap[greenStatus] = 'green';
        this.statusColorMap[redStatus] = 'red';
        this.statusColorMap[yellowStatus] = 'yellow';
        this.config = configResponse;

        const childContactForm = this.getContactForm(configResponse, userResponse);
        this.getOptionsForm = this.createForm(childContactForm);
      });
  }

  createForm(childContactForm: UntypedFormGroup): UntypedFormGroup {
    return this.fb.group({
      selectedClinicalFields: [[], Validators.required],
      contact: childContactForm,
      additionalInformation: ['']
    });
  }

  private getContactForm(config, user: User): UntypedFormGroup {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = parseInt(config.EMAIL_VALIDATION_LENGTH, 10);
    const phoneRegEx = new RegExp(config.PHONE_VALIDATION_REGEX);
    const phoneLength = parseInt(config.PHONE_VALIDATION_LENGTH, 10);
    return this.fb.group({
      contactSalutation: [user.salutation, [Validators.maxLength(35)]],
      contactFirstName: [user.firstName, [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
      contactLastName: [user.lastName, [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
      contactEmail: [user.email, [Validators.required, Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]],
      contactPhone: [user.phone,
        [Validators.required, Validators.maxLength(phoneLength),
          Validators.pattern(phoneRegEx), SpaceValidator.noWhiteSpace]]
    });
  }

  getFormProperty(propertyName: string): any {
    return this.getOptionsForm.get(propertyName).value;
  }

  /**
   * for the selected ones, move the translation into the formData
   */
  translateClinicalFields(): string[] {
    return this.getFormProperty('selectedClinicalFields').map(clinicalFieldId => {
      return this.clinicalSpecialities.find(i => i.value === clinicalFieldId).title;
    });
  }

  postFormData() {
    this.resolveObservable(this.equipmentRestService.postRecommendation(this.equipmentItem.key, this.getPreparedPostBody()));
  }

  resolveObservable(observable: Observable<any>) {
    observable.pipe(finalize(() => {
      this.isFormSubmitted = false;
      this.hide();
      this.init();
    })).subscribe(() => {
      this.toasterService.showTranslatedSuccessToaster('GET_RECOMMENDATION_EMAIL_SUCCESS');
    });
  }

  getPreparedPostBody(): object {
    const formData = { ...this.getOptionsForm.value };

    // translate selected clinical fields and add it to new field
    formData['clinicalFields'] = this.translateClinicalFields();
    delete formData['selectedClinicalFields'];

    return formData;
  }

  validateSelectedClinicalFields() {
    this.getOptionsForm.controls['selectedClinicalFields'].updateValueAndValidity();
  }
}
