export enum EmailFrequency {
  NEVER = 'NEVER',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum EquipmentSelection {
  ALL = 'ALL',
  FAV = 'FAV',
  NONE = 'NONE'
}

export interface UserSettings {
  subprocessorNotificationSubscribed: boolean;
  asuEmailFrequency: EmailFrequency;
  checkSheetEmailSubscribed: boolean;
  securityAdvisoriesSubscribed: boolean;
  sprReportEquipmentSelection: EquipmentSelection;
  securityAdvisoriesViewRole: boolean;
  securityAssessmentEmailsSubscribed: boolean;
  plannedActivitiesEmailFrequency: EmailFrequency;
  preliminaryInvoiceEmailSubscribed: boolean;
  preliminaryInvoiceEmailFrequency: EmailFrequency;
  marketingConsent: boolean;
}
