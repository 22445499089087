import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { Equipment } from '../../../core/models/equipment/equipment';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { AddOmnitureAndRouterStateNameDirective } from '../../directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class DeactivateFormModel {
  constructor(
    public deactivationCallback: boolean,
    public deactivationEmailText: string,
    public deactivationState: string
  ) {
  }
}

@Component({
  selector: 'hl-deactivate-equipment-modal',
  templateUrl: './deactivate-equipment-modal.component.html'
})
export class DeactivateEquipmentModalComponent extends BaseModalPopup
  implements OnInit {

  @Input()
  equipment: Equipment;

  // only for simulator check
  isFormSubmitted = false;

  // store form data
  formData: DeactivateFormModel;

  // validation/error message
  translationErrorMessage =
    'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  deactivationStates: DropdownOptions[];
  deactivationSoldState: string;

  constructor(
    private equipmentRestService: EquipmentRestService,
    private toasterService: ToasterService,
    private configService: CountryConfigRestService,
    private translateService: TranslateService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.init();
  }

  hide() {
    this.reset();
    super.hide();
  }

  reset() {
    this.isFormSubmitted = false;
    this.formData = new DeactivateFormModel(false, '', '');
  }

  init() {
    this.reset();
    const translation$ = this.translateService.get(
      'EQUIPMENT_DEACTIVATION_STATE'
    );
    const config$ = this.configService.getConfig();

    combineLatest([translation$, config$]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([stateString, config]) => {
      this.deactivationStates = stateString
        .split(',')
        .map(state => state.trim())
        .map(state => ({title: state, value: state}));
      this.deactivationSoldState = config.EQUIPMENT_DEACTIVATION_STATE_SOLD;
    });
  }

  ok() {
    AddOmnitureAndRouterStateNameDirective.trackGeneral(
      AddOmnitureAndRouterStateNameDirective.formStates.FORM_SENT,
      AddOmnitureAndRouterStateNameDirective.formTypes.EQUIPMENT_DEACTIVATE_TYPE);
    this.showSpinner = true;
    // call the backend rest end point
    this.equipmentRestService
      .deactivateEquipment(this.equipment.key, this.formData).pipe(
      finalize(() => {
        this.hide();
      }))
      .subscribe(() => {
        this.toasterService.showTranslatedSuccessToaster('EQUIPMENT_DEACTIVATION_EMAIL_SUCCESS');
      });
  }

  validationCheck(): boolean {
    const stateValid = this.showDeactivateState()
      ? this.formData.deactivationState !== ''
      : true;
    const ownerValid = this.isReasonMandatory()
      ? this.formData.deactivationEmailText !== ''
      : true;
    return stateValid && ownerValid;
  }

  showDeactivateState(): boolean {
    return (
      this.deactivationStates != null &&
      this.deactivationStates.length > 0 &&
      this.deactivationStates[0].title !== ''
    );
  }

  isReasonMandatory(): boolean {
    return (
      !this.showDeactivateState() ||
      (this.formData.deactivationState === this.deactivationSoldState &&
        this.deactivationSoldState !== '')
    );
  }

  show() {
    AddOmnitureAndRouterStateNameDirective.trackGeneral(
      AddOmnitureAndRouterStateNameDirective.formStates.FORM_OPEN,
      AddOmnitureAndRouterStateNameDirective.formTypes.EQUIPMENT_DEACTIVATE_TYPE);
    super.show();
  }
}
