import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { clone, parseInt } from 'lodash-es';
import { finalize, takeUntil } from 'rxjs/operators';
import { TicketsUtilService } from 'app/core/services/tickets/tickets-util.service';
import { FilterUtilService } from 'app/core/utils/filter-util.service';
import { EquipmentViewModel } from 'app/core/view-models/equipment-view-model';
import { TicketViewModel } from 'app/core/view-models/ticket-view-model';
import { SortDirection } from '../../sorting/sort-object';
import { Subject } from 'rxjs';

@Component({
  selector: 'hl-create-ticket-list-of-tickets',
  templateUrl: './create-ticket-list-of-tickets.component.html'
})
export class CreateTicketListOfTicketsComponent implements OnInit, OnDestroy {

  @Input() equipment: EquipmentViewModel;
  @Input() materialNumber: string;
  @Input() serialNumber: string;

  rawList: TicketViewModel[] = [];
  viewModelList: TicketViewModel[] = [];
  listWithoutPagination: TicketViewModel[] = [];

  dateTimePattern = '';

  // set a default value, but later defined from country
  numberPagination: number;
  paginationItems: number;

  isLoaded: boolean;

  ticketStatusYellow: string[];
  ticketStatusRed: string[];
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private configService: CountryConfigRestService,
    private filterUtilService: FilterUtilService,
    private ticketsUtilService: TicketsUtilService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.numberPagination = 3;
    this.paginationItems = 3;
    this.isLoaded = false;
    this.viewModelList = [];
    this.rawList = [];
    this.listWithoutPagination = [];

    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
      this.initLoadViewModelList();
    });

  }

  setConfigProperties(config) {
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;

    // set the pagination size for create ticket screen
    this.numberPagination = parseInt(config.OPEN_TICKETS_CREATE_LIST_PAGE_SIZE);
    this.paginationItems = this.numberPagination;
    this.ticketStatusYellow = config.TICKET_STATUS_YELLOW.split(',');
    this.ticketStatusRed = config.TICKET_STATUS_RED.split(',');
  }

  initLoadViewModelList() {
    if (this.equipment) {
      this.setTicketByFilter(this.equipment.key);
    }
  }

  setTicketByFilter(equipmentKey) {
    this.ticketsUtilService.getTicketsByEquipmentKey(equipmentKey).pipe(
      finalize(() => {
        this.isLoaded = true;
        this.onAdvancedFilterChange();
      }))
      .subscribe((ticketsResponse) => {
        this.rawList = clone(ticketsResponse);
      });
  }

  onAdvancedFilterChange() {
    const filterObject = {
      orderBy: {
        sortBy: 'ticketCreationTimestamp',
        sortDir: SortDirection.DESC
      }
    };
    this.listWithoutPagination =
      this.filterUtilService.getListAfterApplyingFilterPipes(this.rawList, filterObject);

    // view model list including all filter, pagination as well
    this.viewModelList =
      this.filterUtilService.applyIndividualFilter(this.listWithoutPagination, this.paginationItems, 'limitTo');
  }

  loadMore() {
    this.paginationItems += this.numberPagination;
    this.onAdvancedFilterChange();
  }
}
