<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal2_title" [translate]="'SYNGO_VOUCHER_TITLE'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div id="modal3_desc" class="modal-dialog__body">

        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>


        @if (!showSpinner) {
          <form novalidate class="form form-horizontal" [formGroup]="syngoVoucherForm">
            <div class="row">
              <div class="col-12 col-lg-8">
                <h5 class="margin-top-0" [translate]="'GENERIC_LABEL_EQUIPMENT'"></h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                @if (equipment) {
                  <div class="collection margin-bottom-1">
                    <div class="collection-item list-item">
                      <div class="media-object width-100">
                        <div class="media-object__image list-item__image">
                          <hl-equipment-image [equipmentKey]="equipment.key" [large]="false"></hl-equipment-image>
                        </div>
                        <div class="media-object__body">
                          @if (equipment.productName) {
                            <p class="list-item__title margin-bottom-0">
                              {{ equipment.productName }}</p>
                          }
                          <ul class="bare-list info-list margin-bottom-0">
                            <li
                              class="info-list__item label-status-{{equipmentStatusColorMap[equipment.key | equipmentStatus | async]}}">{{ equipment.siemensId }}
                            </li>
                            @if (equipment.myEquipmentName) {
                              <li class="info-list__item">
                                <i class="icon-equipment-name" hlTooltip [documentOverlay]="true"
                                  [title]="'GENERIC_LABEL_MY_EQUIPMENT_NAME' | translate"></i> {{ equipment.myEquipmentName }}
                              </li>
                            }
                            @if (equipment.department) {
                              <li class="info-list__item">
                                <i class="icon-department" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_DEPARTMENT' | translate"></i>
                                {{ equipment.department }}
                              </li>
                            }
                            <li class="info-list__item" *hlItemShowHideHandler="'role';role:'viewPlannedActivityRole'">
                              <i class="icon-calendar" hlTooltip [documentOverlay]="true" [title]="'LABEL_ALL_PLANNED_ACTIVITIES' | translate"></i>
                              <hl-equipment-actv-count [equipment]="equipment"></hl-equipment-actv-count>
                            </li>
                            @if (equipment.city) {
                              <li class="info-list__item">
                                <i class="icon-location-pin" hlTooltip [documentOverlay]="true"
                                  [title]="'GENERIC_LABEL_CITY' | translate"></i> {{ equipment.city }}
                              </li>
                            }
                            <li class="info-list__item" *hlItemShowHideHandler="'config';config:'TOGGLE_CUSTOMER_NAME_EQUIPMENT_LIST'">
                              <i class="icon-user" hlTooltip [documentOverlay]="true" [title]="'GENERIC_LABEL_CUSTOMER_NAME' | translate"></i>
                              {{ equipment.customerName }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <hl-textfield label="GENERIC_LABEL_INSTITUTION" name="institution" formControlName="institution" [maxlength]="30"
              [isRequired]="syngoVoucherForm.controls['institution'].errors && syngoVoucherForm.controls['institution'].errors['required']"
              [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['institution'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
              ngDefaultControl></hl-textfield>
            @if (showExtendedFields) {
              <hl-textfield label="LABEL_ATTN_NAME_OF_SHIPTO" name="nameOfShipto" formControlName="nameOfShipto" [maxlength]="30"
                [isRequired]="syngoVoucherForm.controls['nameOfShipto'].errors && syngoVoucherForm.controls['nameOfShipto'].errors['required']"
                [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['nameOfShipto'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
                ngDefaultControl></hl-textfield>
              <hl-textfield label="GENERIC_LABEL_PHONE" name="phone" formControlName="phone" [maxlength]="30"
                [isRequired]="syngoVoucherForm.controls['phone'].errors && syngoVoucherForm.controls['phone'].errors['required']"
                [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['phone'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
                ngDefaultControl></hl-textfield>
              <hl-textfield label="DEPARTMENT" name="department" formControlName="department" [maxlength]="40"
                [isRequired]="syngoVoucherForm.controls['department'].errors && syngoVoucherForm.controls['department'].errors['required']"
                [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['department'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
                ngDefaultControl></hl-textfield>
              <hl-textfield label="ADDITIONAL_INFORMATION" name="additionalInformation" formControlName="additionalInformation" [maxlength]="40"
                [isRequired]="syngoVoucherForm.controls['additionalInformation'].errors && syngoVoucherForm.controls['additionalInformation'].errors['required']"
                [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['additionalInformation'].valid"
                invalidLabel="GENERIC_LABEL_FILL_INFORMATION" ngDefaultControl></hl-textfield>
            }
            <hl-textfield label="GENERIC_LABEL_STREET" name="street" formControlName="street" [maxlength]="30"
              [isRequired]="syngoVoucherForm.controls['street'].errors && syngoVoucherForm.controls['street'].errors['required']"
              [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['street'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
              ngDefaultControl></hl-textfield>
            <hl-textfield label="GENERIC_LABEL_HOUSE_NUMBER" name="houseNumber" formControlName="houseNumber" [maxlength]="10"
              ngDefaultControl></hl-textfield>
            <hl-textfield label="POSTAL_CODE" name="zip" formControlName="zip" [maxlength]="10"
              [isRequired]="syngoVoucherForm.controls['zip'].errors && syngoVoucherForm.controls['zip'].errors['required']"
              [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['zip'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
              ngDefaultControl></hl-textfield>
            <hl-textfield label="GENERIC_LABEL_CITY" name="city" formControlName="city" [maxlength]="40"
              [isRequired]="syngoVoucherForm.controls['city'].errors && syngoVoucherForm.controls['city'].errors['required']"
              [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['city'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
              ngDefaultControl></hl-textfield>
            <hl-textfield label="LABEL_COUNTRY" name="country" formControlName="country" [isDisabled]="true" [maxlength]="50"
              [isRequired]="syngoVoucherForm.controls['country'].errors && syngoVoucherForm.controls['country'].errors['required']"
              [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['country'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
              ngDefaultControl></hl-textfield>
            @if (showExtendedFields) {
              <hl-textfield label="GENERIC_LABEL_EMAIL" name="email" formControlName="email" [maxlength]="200"
                [isRequired]="syngoVoucherForm.controls['email'].errors && syngoVoucherForm.controls['email'].errors['required']"
                [isInvalid]="isFormSubmitted && !syngoVoucherForm.controls['email'].valid" invalidLabel="GENERIC_LABEL_FILL_INFORMATION"
                ngDefaultControl></hl-textfield>
            }
          </form>
        }


      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'button button--primary'"
          [isFormValid]="syngoVoucherForm.valid" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
          [label]="'BUTTON_SYNGO_VOUCHER_SUBMIT'" name="isFormSubmitted" ngDefaultControl></hl-simulator-check>
      }
    </div>
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
