import { EventEmitter, inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { SystemUpdatesRestService } from '../../rest-services/system-updates-rest.service';
import { SystemUpdateDetailViewModel } from '../../view-models/system-update-detail-view-model';
import { SystemUpdateBaseViewModel } from '../../view-models/system-update-base-view-model';
import { map, take } from 'rxjs/operators';
import { DownloadUtilService } from '../../utils/download-util.service';
import { SystemUpdateRuhBaseViewModel } from '../../view-models/system-update-ruh-base-view-model';
import { SystemUpdateRuhBase } from '../../models/systemUpdates/system-update-ruh-base';
import { SystemUpdatesRuhBaseModel } from '../../../system-updates/ruh/ruh-base-model';
import { SystemUpdatesRuhRestService } from '../../../system-updates/ruh/system-updates-ruh-rest.service';
import { SystemUpdatesCves } from '../../models/systemUpdates/system-update-cves';
import { isEqual } from 'lodash-es';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { EnvironmentConfigRestService } from '../../rest-services/environment-config-rest.service';
import { ReleaseNotes } from '../../../system-updates/system-updates-release-notes/release-notes';

@Injectable({providedIn: 'root'})
export class SystemUpdatesService {
  private configService = inject(CountryConfigRestService);
  private environmentConfigRestService = inject(EnvironmentConfigRestService);
  private systemUpdatesRestService = inject(SystemUpdatesRestService);
  private systemUpdatesRuhRestService = inject(SystemUpdatesRuhRestService);
  private downloadUtilService = inject(DownloadUtilService);
  onStatusUpdate = new EventEmitter();
  onUpdateRedirect = new EventEmitter();
  onEquipmentLinkClick = new EventEmitter();
  showCreateNewSystemUpdateModalEmitter = new EventEmitter<SystemUpdateBaseViewModel>();

  getSystemUpdates(): Observable<SystemUpdateBaseViewModel[]> {
    return this.systemUpdatesRestService.getUpdates().pipe(map(response => response.map(systemUpdate =>
      new SystemUpdateBaseViewModel(systemUpdate))));
  }

  getSystemUpdatesForEquipment(equipmentKey: string): Observable<SystemUpdateBaseViewModel[]> {
    return this.systemUpdatesRestService.getSystemUpdatesForEquipment(equipmentKey).pipe(map(response =>
      response.map(systemUpdate => new SystemUpdateBaseViewModel(systemUpdate))));
  }

  getSystemUpdatesRuhForEquipment(equipmentKey: string): Observable<SystemUpdateRuhBaseViewModel[]> {
    return this.systemUpdatesRestService.getSystemUpdatesRuhForEquipment(equipmentKey).pipe(map(response =>
      response.map(systemUpdate => new SystemUpdateRuhBaseViewModel(systemUpdate))));
  }

  getLatestSystemUpdatesRuh(equipmentKey: string): Observable<SystemUpdateRuhBase> {
    return this.systemUpdatesRestService.getLatestSystemUpdatesRuh(equipmentKey);
  }

  getSystemUpdateDetail(updateNumber: string): Observable<SystemUpdateDetailViewModel> {
    if (updateNumber) {
      return this.systemUpdatesRestService.getSystemUpdateDetail(updateNumber).pipe(map(systemUpdate =>
        new SystemUpdateDetailViewModel(systemUpdate)));
    }
  }

  getSystemUpdatesDetailsCves(updateNumber: string): Observable<SystemUpdatesCves> {
    return this.systemUpdatesRestService.getSystemUpdateCves(updateNumber);
  }

  downloadPackage(updateNumber: string, packageName: string): void {
    this.systemUpdatesRestService.getDownloadUrl(updateNumber)
      .subscribe(({url}) => {
        this.downloadUtilService.downloadFileByUrl(packageName, url);
      });
  }

  getReleaseNotes(updateNumber: string): Observable<ReleaseNotes> {
    return this.configService.getConfig().pipe(switchMap(config => {
      if (isEqual(config.FEATURE_TOGGLE_CFRN, 'true')) {
        return combineLatest(([
          this.systemUpdatesRestService.getReleaseNotesId(updateNumber),
          this.environmentConfigRestService.getEnvironmentConfig().pipe(map(envConfig => envConfig['DOCLIB_DOWNLOAD_URL']))
        ])).pipe(map(([id, downloadUrl]) => ({id, downloadUrl})));
      }
      return of(null);
    }), take(1));
  }

  onStatusUpdateEmit() {
    this.onStatusUpdate.emit();
  }

  onUpdateRedirectEmit() {
    this.onUpdateRedirect.emit();
  }

  onEquipmentLinkClickEmit() {
    this.onEquipmentLinkClick.emit();
  }

  showCreateUpdateModal(update: SystemUpdateBaseViewModel) {
    this.showCreateNewSystemUpdateModalEmitter.emit(update);
  }

  getSystemUpdatesRuh(): Observable<SystemUpdatesRuhBaseModel> {
    return this.systemUpdatesRuhRestService.getSystemUpdates()
  }

  downloadReleaseNotes(url: string, releasNotesId: string) {
    this.downloadUtilService.downloadFileByUrl('', url + `?document-ids=` + releasNotesId);
  }
}
