<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--wide is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container make-full-height display-flex" role="document">
      <div class="modal-dialog__header">
        <h3 class="modal-dialog__title" id="modal1_title">
          <span [translate]="'SURVEY_TICKET_TITLE'"></span>
        </h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div class="modal-dialog__body flex-column display-flex flex-grow-1" id="modal1_desc">
        @if (surveyLink$ | async; as surveyLink) {
          <iframe title="Survey" class="width-100 flex-grow-1" [src]="surveyLink | safeDom: 'resourceUrl'"></iframe>
        }
      </div>
    </div>
  </div>
</ng-template>
