import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EquipmentRestService } from '../../../../core/rest-services/equipment-rest.service';
import { ToasterService } from '../../../../core/component-communication-services/toaster/toaster.service';
import { Equipment } from '../../../../core/models/equipment/equipment';
import { finalize } from 'rxjs/operators';
import { SystemUpdatesRestService } from '../../../../core/rest-services/system-updates-rest.service';

@Component({
  selector: 'hl-create-new-equipment-for-update',
  templateUrl: './create-new-equipment-for-update.component.html'
})
export class CreateNewEquipmentForUpdateComponent implements OnInit {
  @Output()
  onNewEquipmentAdded = new EventEmitter<Equipment>();
  newEquipmentForm: UntypedFormGroup;
  isFormSubmitted: boolean;
  matAndSerialNumberConflict: boolean;
  isAwaitingResponse = false;
  constructor(
    private fb: UntypedFormBuilder,
    private toasterService: ToasterService,
    private systemUpdatesRestService: SystemUpdatesRestService,
    private equipmentRestService: EquipmentRestService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.createForm();
    this.isFormSubmitted = false;
    this.matAndSerialNumberConflict = false;
  }

  createForm() {
    this.newEquipmentForm = this.fb.group(
      {
        productName: ['', Validators.compose([Validators.maxLength(50), Validators.required])],
        materialNumber: ['', Validators.compose([Validators.maxLength(18), Validators.required])],
        serialNumber: ['', Validators.compose([Validators.maxLength(18), Validators.required])],
        secretKey: ['', Validators.compose([Validators.maxLength(30)])]
      }
    );
  }

  createNewSimulatorEquipment() {
    this.isFormSubmitted = true;
    this.matAndSerialNumberConflict = false;
    if (!this.newEquipmentForm.valid) {
      return;
    }
    this.sendCreateNewEquipmentCall();
  }

  sendCreateNewEquipmentCall() {
    const equipment = this.newEquipmentForm.value;
    if (!this.isAwaitingResponse) {
      this.isAwaitingResponse = true;
      this.equipmentRestService.postCreateSimulatorEquipment(this.trimWhiteSpaces(equipment)).pipe(
        finalize(() => this.isAwaitingResponse = false)
      )
      .subscribe({
        next: (createdEquipment) => {
          this.systemUpdatesRestService.clearGetSecretKeysForEquipment();
          this.onNewEquipmentAdded.emit(createdEquipment);
          this.toasterService.showTranslatedSuccessToaster('NEW_SYSTEM_UPDATE_EQUIPMENT_SUCCESSFULLY_CREATED');
          },
        error: err => this.handleErrorWhileCreatingNewEquipment(err)
      });
    }
  }

  trimWhiteSpaces(equipment) {
    equipment.productName = equipment.productName.trim();
    equipment.materialNumber = equipment.materialNumber.trim();
    equipment.serialNumber = equipment.serialNumber.trim();
    equipment.secretKey = equipment.secretKey.trim();
    return equipment;
  }

  private handleErrorWhileCreatingNewEquipment(err: any) {
    this.matAndSerialNumberConflict = err.error && err.error.code === 409;
    if (!this.matAndSerialNumberConflict) {
      this.toasterService.emitFrontendGenericErrorMessage();
    }
  }
}
