import { TicketViewModel } from '../../../core/view-models/ticket-view-model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'hl-ticket-list-items',
  templateUrl: './ticket-list-items.component.html'
})
export class TicketListItemsComponent {
  @Input() item: TicketViewModel;
  @Input() ticketListItemOrder: string[];
  @Input() index: number;
  @Input() toggleCustomerName: boolean;
  @Input() allowedTicketTypesForOriginalEffectCode: string[];
  @Input() dateTimePattern: string;
  @Input() customerNameLength: number;

  get showOriginalEffectCode() {
    return this.allowedTicketTypesForOriginalEffectCode?.includes(this.item?.typeID);
  }
}
