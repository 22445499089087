<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header" data-cy="switch-country-dialog-header">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'LABEL_HEADLINE_MULTI_COUNTRY_IMPERSONATION'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div id="modal2_desc" class="modal-dialog__body overflow-inherit" data-cy="switch-country-dialog-body">
        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>
        @if (!showSpinner) {
          <form class="form">
            <p [translate]="'LABEL_TEXT_MULTI_COUNTRY_IMPERSONATION'"></p>
            <div class="row">
              @if (countries?.length > 1) {
                <div class="col-12" data-cy="switch-country-select">
                  <hl-selectbox [options]="countries" [(value)]="selectedCountry" (valueChange)="updateLanguageOnCountryChange()"
                    label="LABEL_COUNTRY" [translateKeys]=true></hl-selectbox>
                </div>
              }
              @if (languages?.length > 1) {
                <div class="col-12" data-cy="switch-country-language-select">
                  <hl-selectbox [options]="languages" [(value)]="selectedLocale" label="LABEL_LANGUAGE" [translateKeys]=true></hl-selectbox>
                </div>
              }
            </div>
          </form>
        }
      </div>
      @if (!showSpinner) {
        <div class="modal-dialog__footer">
          <button type="button" class="button button--primary" (click)="ok()" [translate]="'GENERIC_LABEL_APPLY'"
            data-cy="switch-country-apply-btn"></button>
          <button type="button" class="button" data-dismiss="modal-dialog" (click)="hide()" [translate]="'GENERIC_LABEL_CANCEL'"></button>
        </div>
      }
    </div>
  </div>
</ng-template>
