<ng-container hlOverlay #overlayPopup="overlay" [overlayTemplate]="overlayTemplate"></ng-container>
<ng-template #overlayTemplate>
  <div class="modal-dialog modal-dialog--full-content is-open" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog__container" role="document" data-cy="deactivate-equipment-modal">
      <div class="modal-dialog__header" data-cy="deactivate-dialog-modal">
        <h3 class="modal-dialog__title" id="modal1_title" [translate]="'EQUIPMENT_DEACTIVATION_TITLE'"></h3>
        <button type="button" class="modal-dialog__close" data-dismiss="modal-dialog" aria-label="Close" (click)="hide()">
          <i aria-hidden="true" class="icon-close"></i>
        </button>
      </div>
      <div id="modal2_desc" class="modal-dialog__body" data-cy="deactivate-dialog-modal">

        <hl-progress-indicator [loading]="showSpinner"></hl-progress-indicator>

        @if (!showSpinner) {
          <form #modalForm="ngForm" novalidate class="form form-horizontal">
            <div class="row">
              <div class="col-12">
                <div class="multi-column multi-column-sm-2 multi-column-lg-3">
                  <div class="multi-column__item">
                    <div class="font-size-small color-black-60" [translate]="'GENERIC_LABEL_PRODUCT_NAME'"></div>
                    <div>{{ equipment.productName }}</div>
                  </div>
                  <div class="multi-column__item">
                    <div class="font-size-small color-black-60" [translate]="'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID'"></div>
                    <div>{{ equipment.siemensId }}</div>
                  </div>
                  <div class="multi-column__item">
                    <div class="font-size-small color-black-60" [translate]="'GENERIC_LABEL_MY_EQUIPMENT_NAME'"></div>
                    <div>{{ equipment.myEquipmentName }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <label class="control-label"></label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <p>
                  <input id="checkbox" type="checkbox" name="deactivationCallback" class="checkbox" [(ngModel)]="formData.deactivationCallback"/>
                  <label for="checkbox">{{ 'EQUIPMENT_DEACTIVATION_CALLBACK' | translate }}</label>
                </p>
              </div>
            </div>
            @if (showDeactivateState()) {
              <fieldset class="row">
                <div class="col-12">
                  <hl-selectbox [ngClass]="{'invalid': isFormSubmitted && formData.deactivationState === ''}" [options]="deactivationStates"
                    [(value)]="formData.deactivationState" label="EQUIPMENT_DEACTIVATION_STATE_LABEL"></hl-selectbox>
                  @if (isFormSubmitted && formData.deactivationState === '') {
                    <small class="selectbox__hint selectbox__hint--validation" [translate]="'EQUIPMENT_DEACTIVATION_EMAIL_ERROR_STATE_NOT_SELECTED'"
                      data-cy="deactivation-email-error"></small>
                  }
                </div>
              </fieldset>
            }
            <fieldset class="row">
              <div class="col-lg-12" data-cy="equipment-deactivation-text-area">
                <hl-textarea #reason [name]="'reason'" [labelText]="'EQUIPMENT_DEACTIVATION_EMAIL_TEXT'"
                  [(textareaContent)]="formData.deactivationEmailText" [isRequired]="isReasonMandatory()" [isLarge]="true"
                  [isInvalid]="isFormSubmitted && !reason.model.valid && isReasonMandatory()" [maxlength]="2000"></hl-textarea>
                @if (isFormSubmitted && !reason.model.valid && isReasonMandatory()) {
                  <small class="textfield__hint textfield__hint--validation" [translate]="'EQUIPMENT_DEACTIVATION_EMAIL_ERROR_NO_TEXT'"></small>
                }
              </div>
            </fieldset>
          </form>
        }

      </div>
      @if (!showSpinner) {
        <hl-simulator-check [(ngModel)]="isFormSubmitted" (onClickOk)="ok()" (onClickClose)="hide()" [btnCSS]="'button button--primary'"
          [isFormValid]="validationCheck()" [modalRef]="simulatorModal" [isFormValidationRequired]="true" [isModalType]="true"
          [label]="'EQUIPMENT_DEACTIVATION_LABEL_SEND'" name="isFormSubmitted" ngDefaultControl dataCyCancelButton="deactivate-equipment"
          dataCySaveButton="send-deactivation-request"></hl-simulator-check>
      }
    </div>
  </div>
  <hl-simulator-modal #simulatorModal [isRouteBackRequired]="false"></hl-simulator-modal>
</ng-template>
