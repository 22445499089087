import { finalize, map } from 'rxjs/operators';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { VerifyPublicGroupModalComponent } from 'app/shared/modal-popup/verify-public-group-modal/verify-public-group-modal.component';
import { CustomerGroup } from 'app/core/models/customer/customer-group';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerViewModel } from 'app/core/view-models/customer-view-model';
import { combineLatest, Subscription } from 'rxjs';
import { LifeNetUtilService } from 'app/core/utils/life-net-util.service';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { UserUtilService } from 'app/core/services/user/user-util.service';
import { CustomerUtilService } from 'app/core/services/customer/customer-util.service';
import { CustomerGroupsRestService } from 'app/core/rest-services/customer-groups-rest.service';
import { CustomerConstantsService } from 'app/core/services/customer/customer-constants.service';
import { ImpersonationCacheService } from 'app/core/services/cache/impersonation-cache.service';
import { ImpersonationUtilsService } from 'app/core/utils/impersonation-utils.service';
import { groupPrivate, groupPublic, roles } from 'app/core/core-constants.service';
import { CustomerGroupViewModel } from 'app/core/view-models/customer-group-view-model';
import { clone, forEach, includes, isEqual, orderBy, without } from 'lodash-es';
import { User } from 'app/core/models/user';
import { SortSkeleton } from '../../sorting/sort-skeleton';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { SpaceValidator } from '../../validators/space.validator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styles: []
})
export class CreateGroupModalComponent extends BaseModalPopup
  implements OnInit, OnDestroy {

  @Input()
  countryId: string;
  @Input()
  groupId: string;
  @Output()
  close = new EventEmitter();

  @ViewChild('verifyPublicModal')
  verifyPublicModal: VerifyPublicGroupModalComponent;

  showValidationMessage = false;
  isFormSubmitted = false;
  isEditGroup = false;
  showGroupHint = false;
  isUserAdmin = false;
  isCustomerListLoaded = false;

  isRadioBtnActive = 0; // false
  groupPassedFromURLParams: CustomerGroup;

  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  // Label text for spinner
  groupCreationInProgressLabel = 'GROUP_CREATION_IN_PROGRESS';

  searchInput: string;
  searchInputCustomer: string;
  createGroupForm: UntypedFormGroup;
  customerVMList: CustomerViewModel[];
  rawList: CustomerViewModel[];
  rawListAddedGroupCustomers = [];

  listOfAddedGroupCustomers = [];

  // Subscriptions to be destroyed
  showCreateGroupSubscription: Subscription;
  impersonatedCustomerSortSkeleton: SortSkeleton;
  impersonatedCustomersLoaded: boolean;
  listWithoutPaginationLength: number;
  numberPagination: number;
  paginationItems: number;

  constructor(
    private fb: UntypedFormBuilder,
    private lifeNetUtilService: LifeNetUtilService,
    private configService: CountryConfigRestService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private userUtilService: UserUtilService,
    public customerUtilService: CustomerUtilService,
    private router: Router,
    private customerGroupsRestService: CustomerGroupsRestService,
    private customerConstantsService: CustomerConstantsService,
    private impersonationCacheService: ImpersonationCacheService,
    private impersonationUtils: ImpersonationUtilsService,
    private filterUtilService: FilterUtilService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.showCreateGroupSubscription = this.customerUtilService.showCreateGroupModalEmitter.subscribe(
      res => {
        this.isEditGroup = res.groupId !== null;
        this.init();
      }
    );
  }

  ngOnDestroy() {
    if (this.createGroupForm) {
      this.createGroupForm.reset();
    }
    if (this.showCreateGroupSubscription) {
      this.showCreateGroupSubscription.unsubscribe();
    }
    super.destroy();
  }

  ok() {
    this.createOrEditCustomerGroup();
  }

  hide() {
    super.hide();
    this.reset();
  }

  reset() {
    this.rawListAddedGroupCustomers = [];
    this.searchInput = '';
    this.searchInputCustomer = '';
    this.isFormSubmitted = false;
  }

  init() {
    this.initProperties();
    this.initFormControl();
  }

  initProperties() {
    // reset previous vars
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.isRadioBtnActive = 0;
    this.showGroupHint = false;

    this.impersonatedCustomersLoaded = false;
    this.impersonatedCustomerSortSkeleton = this.customerConstantsService.getImpersonatedGroupCustomersSortSkeleton();
    this.listWithoutPaginationLength = 0;
    this.numberPagination = 20;
    this.paginationItems = 20;
    this.listOfAddedGroupCustomers = [];
  }

  initConfigProperties(config) {
    this.numberPagination = parseInt(config.IMPERSONATE_CUSTOMER_LIST_PAGE_SIZE);
  }

  onAdvancedFilterChange() {
    this.paginationItems = this.numberPagination;
    const filterObject = {
      search: {
        searchValue: this.searchInput,
        searchColumns: ['customerId', 'customerName', 'city']
      },
      orderBy: this.impersonatedCustomerSortSkeleton.sortObject
    };

    // initial value for filtered result without pagination applied
    const filteredRawList = this.getFilteredRawList();

    const listWithoutPagination = this.filterUtilService.getListAfterApplyingFilterPipes(
      filteredRawList,
      filterObject
    );

    this.listWithoutPaginationLength = listWithoutPagination.length;

    // view model list including all filter, pagination as well
    this.customerVMList = this.filterUtilService.applyIndividualFilter(
      listWithoutPagination,
      this.paginationItems,
      'limitTo'
    );
    this.onCustomerSearchInput();
  }

  onLoadMore() {
    const filterObject = {
      search: {
        searchValue: this.searchInput,
        searchColumns: ['customerId', 'customerName', 'city']
      },
      orderBy: this.impersonatedCustomerSortSkeleton.sortObject
    };

    // initial value for filtered result without pagination applied
    const filteredRawList = this.getFilteredRawList();

    const listWithoutPagination = this.filterUtilService.getListAfterApplyingFilterPipes(
      filteredRawList,
      filterObject
    );

    this.listWithoutPaginationLength = listWithoutPagination.length;

    // view model list including all filter, pagination as well
    this.customerVMList = this.filterUtilService.applyIndividualFilter(
      listWithoutPagination,
      this.paginationItems,
      'limitTo'
    );
    this.onCustomerSearchInput();
  }

  onCustomerSearchInput() {
    const filterObject = {
      search: {
        searchValue: this.searchInputCustomer,
        searchColumns: ['customerId', 'customerName', 'city']
      },
      orderBy: this.impersonatedCustomerSortSkeleton.sortObject
    };

    // initial value for filtered result without pagination applied
    const filteredRawList = clone(this.rawListAddedGroupCustomers);

    this.listOfAddedGroupCustomers = this.filterUtilService.getListAfterApplyingFilterPipes(
      filteredRawList,
      filterObject
    );
  }

  loadMore() {
    this.paginationItems += this.numberPagination;
    this.onLoadMore();
  }

  onCustomerSortChange(event) {
    this.impersonatedCustomerSortSkeleton = event;
    this.onImpersonatedCustomersSortChange();
  }

  onImpersonatedCustomersSortChange() {
    const filterObject = {
      orderBy: this.impersonatedCustomerSortSkeleton.sortObject
    };

    // initial value for filtered result without pagination applied
    this.listOfAddedGroupCustomers = this.filterUtilService.getListAfterApplyingFilterPipes(
      this.listOfAddedGroupCustomers,
      filterObject
    );
  }

  onSortChange(event) {
    this.impersonatedCustomerSortSkeleton = event;
    this.onAdvancedFilterChange();
  }

  isAlreadyAddedCustomer(customerId: string): boolean {
    return includes(
      this.listOfAddedGroupCustomers.map(i => {
        return i.customerId;
      }),
      customerId
    );
  }

  checkUserRoles() {
    const rolesToCheck = {
      checkCountryAdminRole: roles.countryAdminRole,
      checkMasterAdminRole: roles.masterAdminRole
    };

    this.userUtilService
      .checkUserRoles(rolesToCheck)
      .subscribe(checkRolesResponse => {
        if (
          checkRolesResponse.checkCountryAdminRole ||
          checkRolesResponse.checkMasterAdminRole
        ) {
          this.isUserAdmin = true;
        }
      });
  }

  clickGroupTypeButton(value: string) {
    this.createGroupForm.get('type').patchValue(value);
    this.handleGroupTypeChange();
  }

  handleGroupTypeChange() {
    this.showGroupHint = isEqual(
      this.createGroupForm.get('type').value,
      groupPublic
    );
  }

  /**
   *
   * @description
   * based on initial form type = 'PUBLIC', then private radio
   * button is disabled
   * Note:- Only during initial load
   */
  setVisibilityDisabled() {
    if (
      this.isEditGroup &&
      isEqual(this.createGroupForm.get('type').value, groupPublic)
    ) {
      this.isRadioBtnActive = 1;
    }
  }

  public addCustomerToGroup(listItem: CustomerViewModel): void {
    const customersInFormControl: string[] = this.createGroupForm.get(
      'customers'
    ).value;

    // check if customer id already included
    if (!includes(customersInFormControl, listItem.customerId)) {
      if (!includes(this.listOfAddedGroupCustomers, listItem)) {
        this.listOfAddedGroupCustomers.push(listItem);
      }
      if (!includes(this.rawListAddedGroupCustomers, listItem)) {
        this.rawListAddedGroupCustomers.push(listItem);
      }
      customersInFormControl.push(listItem.customerId);

      // add to form data the customer ids
      this.createGroupForm.patchValue({
        customers: customersInFormControl
      });
      this.customerVMList = without(this.customerVMList, listItem);
      if (this.rawListAddedGroupCustomers.length >= this.customerVMList.length) {
        this.onAdvancedFilterChange();
        setTimeout(() => {
          this.loadMore();
        }, 100);
      }
    }
  }

  removeCustomerFromGroup(customer: CustomerViewModel) {
    // update the list in the view
    this.listOfAddedGroupCustomers = without(
      this.listOfAddedGroupCustomers,
      customer
    );
    this.rawListAddedGroupCustomers = without(
      this.rawListAddedGroupCustomers,
      customer
    );

    // add to form data the customer ids
    this.createGroupForm.patchValue({
      customers: without(
        this.createGroupForm.get('customers').value,
        customer.customerId
      )
    });

    this.customerVMList.push(customer);
  }

  createOrEditCustomerGroup() {
    this.isFormSubmitted = true;

    if (this.createGroupForm.valid) {
      this.showValidationMessage = false;
      if (isEqual(this.createGroupForm.get('type').value, groupPublic)) {
        this.verifyPublicGroupCreate();
      } else {
        this.callCreateOrEditGroupRequest();
        this.hide();
      }
    } else {
      this.showValidationMessage = true;
    }
  }

  verifyPublicGroupCreate() {
    this.verifyPublicModal.show();
  }

  callCreateOrEditGroupRequest() {
    this.showSpinner = true;

    let groupId = null;
    if (this.isEditGroup) {
      groupId = this.groupPassedFromURLParams.groupId.toString();
    }
    this.customerGroupsRestService
      .postGroup(this.createGroupForm.value, groupId)
      .pipe(
        finalize(() => {
          this.onFinally();
        })
      )
      .subscribe(
        () => {
          this.impersonationCacheService.clearCacheByCountry(this.createGroupForm.get('country').value);
          this.close.emit();
          this.toasterService.showTranslatedSuccessToaster('SUCCESS_GROUP_CREATED');
        }
      );
  }

  /** called, when a post to the server returned (sucessfull or not) */
  onFinally(): void {
    this.hide();
    this.isFormSubmitted = false;
  }

  setGroupPropertiesForEdit(group: CustomerGroupViewModel) {
    this.groupPassedFromURLParams = group;

    const impersonatedGroupCustomerIds = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      group.customers,
      'customerId'
    );

    this.createGroupForm.patchValue({
      customers: impersonatedGroupCustomerIds,
      type: group.type,
      name: group.name
    });

    this.impersonationCacheService.getCustomerListByCountry(group.country).subscribe(() => {
      const impersonatedGroupCustomers = this.impersonationCacheService.getCustomerListByIds(
        impersonatedGroupCustomerIds,
        group.country
      );

      this.listOfAddedGroupCustomers = orderBy(
        impersonatedGroupCustomers,
        ['customerName'],
        ['asc']
      );
      this.rawListAddedGroupCustomers = clone(this.listOfAddedGroupCustomers);
      this.setVisibilityDisabled();
    });
  }

  private getFilteredRawList(): CustomerViewModel[] {
    const filteredRawList = [];

    forEach(this.rawList, item => {
      if (
        !includes(
          this.listOfAddedGroupCustomers.map(i => {
            return i.customerId;
          }),
          item.customerId
        )
      ) {
        filteredRawList.push(item);
      }
    });
    return filteredRawList;
  }

  /**
   * @description
   * set the state params when navigating to create ticket screen from another state
   */
  private initFromQueryParams() {
    if (this.countryId) {
      // if a country is passed then load customers of that country
      this.loadCustomerViewModelList(this.countryId);
      this.createGroupForm.patchValue({country: this.countryId});
    }

    if (this.groupId) {
      this.customerUtilService
        .getCustomerGroupByGroupId(this.groupId, this.countryId)
        .subscribe((customerGroup: CustomerGroupViewModel) => {
          this.setGroupPropertiesForEdit(customerGroup);
          this.loadCustomerViewModelList(this.countryId);
          this.createGroupForm.patchValue({country: this.countryId});
        });
    } else {
      this.listOfAddedGroupCustomers = [];
    }

    this.impersonatedCustomersLoaded = true;
  }

  private initFormControl() {
    const config$ = this.configService.getConfig();
    const user$ = this.userUtilService.getUser();

    combineLatest([config$, user$]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(responses => {
      this.initConfigProperties(responses[0]);
      this.createForm(responses[0], responses[1]);
      this.checkUserRoles();
    });
  }

  private createForm(config: any, user: User) {
    // Group Name Validation
    const groupNameRegEx = new RegExp(config.GROUP_NAME_VALIDATION_REGEX);

    this.createGroupForm = this.fb.group({
      country: [user.country], // default
      name: ['', [Validators.required, Validators.pattern(groupNameRegEx), SpaceValidator.noWhiteSpace]],
      type: [groupPrivate], // default
      customers: [[]]
    });

    // set the form props with value from query params
    this.initFromQueryParams();
  }

  private loadCustomerViewModelList(country: string) {
    this.impersonationCacheService
      .getCustomerListByCountry(country)
      .pipe(
        map(this.impersonationUtils.mapCustomersToViewModel)
      )
      .subscribe(customerResponse => {
        this.rawList = customerResponse;
        this.customerVMList = clone(this.rawList);
        this.isCustomerListLoaded = true;
        this.onAdvancedFilterChange();
      });
  }

  show() {
    super.show();
    setTimeout(() => {
      this.loadMore();
    }, 1000);
  }
}
