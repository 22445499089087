<div class="form-horizontal" [formGroup]="billingForm">
  <!--sao billing consignee-->
  <div class="form-group">
    <p [translate]="'TEXT_SAO_BILLING_ADDRESS_DIFFERENT'"></p>
    <ul class="list list--inline bare-list">
      <li class="list__item">
        <input type="radio" class="radio radio--inline" id="yes_rg" formControlName="isActive" value="true"
          (change)="onActiveBillingAddressChange()"/>
        <label for="yes_rg" [translate]="'GENERIC_LABEL_YES'"></label>
      </li>
      <li class="list__item">
        <input type="radio" class="radio radion--inline" id="no_rg" formControlName="isActive" value="false"
          (change)="onActiveBillingAddressChange()"/>
        <label for="no_rg" [translate]="'GENERIC_LABEL_NO'"></label>
      </li>
    </ul>
  </div>
  <!--sao billing consignee-->
  @if (showBillingAddressFields) {
    <div class="form-group">
      <hl-textfield label="LABEL_SAO_BILLING_CONTACT" name="receiverName"
        [isRequired]="billingForm.controls['receiverName'].errors && billingForm.controls['receiverName'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" placeholder="TEXT_SAO_BILLING_CONTACT"
        [isInvalid]="isFormSubmitted && !billingForm.controls['receiverName'].valid" [formControl]="billingForm.controls['receiverName']"
        ngDefaultControl></hl-textfield>
    </div><!--sao billing customer name-->
    <div class="form-group">
      <hl-textfield label="LABEL_SAO_BILLING_CUSTOMER_NAME" name="customerName"
        [isRequired]="billingForm.controls['customerName'].errors && billingForm.controls['customerName'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !billingForm.controls['customerName'].valid"
        [formControl]="billingForm.controls['customerName']" ngDefaultControl></hl-textfield>
    </div><!--sao billing building department-->
    <div class="form-group">
      <hl-textfield label="LABEL_SAO_BILLING_BUILDING" name="building"
        [isRequired]="billingForm.controls['building'].errors && billingForm.controls['building'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !billingForm.controls['building'].valid"
        [formControl]="billingForm.controls['building']" ngDefaultControl></hl-textfield>
    </div>
    <div class="form-group">
      <hl-textfield label="LABEL_SAO_BILLING_DEPARTMENT" name="department"
        [isRequired]="billingForm.controls['department'].errors && billingForm.controls['department'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !billingForm.controls['department'].valid"
        [formControl]="billingForm.controls['department']" ngDefaultControl></hl-textfield>
    </div><!--sao billing street-->
    <div class="form-group">
      <hl-textfield label="LABEL_SAO_BILLING_STREET" name="street"
        [isRequired]="billingForm.controls['street'].errors && billingForm.controls['street'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !billingForm.controls['street'].valid"
        [formControl]="billingForm.controls['street']" ngDefaultControl></hl-textfield>
    </div><!--sao billing postal code-->
    <div class="form-group">
      <hl-textfield label="LABEL_SAO_BILLING_POSTAL_CODE" name="postalCode"
        [isRequired]="billingForm.controls['postalCode'].errors && billingForm.controls['postalCode'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !billingForm.controls['postalCode'].valid"
        [formControl]="billingForm.controls['postalCode']" ngDefaultControl></hl-textfield>
    </div><!--sao billing city-->
    <div class="form-group">
      <hl-textfield label="LABEL_SAO_BILLING_CITY" name="city"
        [isRequired]="billingForm.controls['city'].errors && billingForm.controls['city'].errors['required']"
        invalidLabel="GENERIC_LABEL_FILL_INFORMATION" [isInvalid]="isFormSubmitted && !billingForm.controls['city'].valid"
        [formControl]="billingForm.controls['city']" ngDefaultControl></hl-textfield>
    </div>
  }
</div>
