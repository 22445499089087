import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { Equipment } from '../../../core/models/equipment/equipment';

@Component({
  selector: 'hl-create-sao-product-information',
  templateUrl: './create-sao-product-information.component.html'
})
export class CreateSaoProductInformationComponent implements OnInit {
  @Input()
  productForm: UntypedFormGroup;
  @Input()
  isFormSubmitted: boolean;
  equipment: Equipment;
  @Input()
  statusColorMap: any = {};

  constructor(private equipmentUtilService: EquipmentUtilService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getEquipment();
  }

  getEquipment() {
    this.equipmentUtilService
      .getEquipment(this.productForm.get('equipmentKey').value)
      .subscribe(equipment => {
        this.equipment = equipment;
        this.productForm.patchValue({
          customerId: this.equipment?.customerId
        });
      });
  }
}
